import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import { type ElementRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { FormItem } from "@/components/Form";
import { Input } from "@/components/Input";

declare namespace Api {
  export type OtpCodeRef = ElementRef<typeof Input>;
  export interface OtpCodeProps extends ComponentProps<typeof Input> {}
}

function useFormItemDefaultProps() {
  const { t } = useTranslation();
  const defaultProps = useMemo(
    () =>
      define<ComponentProps<typeof FormItem>>()({
        label: t("page.login.2fa.field.otp.label"),
        validateTrigger: "onBlur",
        rules: [
          {
            required: true,
            message: t("resource.2fa.error.otpRequired"),
          },
          {
            min: 6,
            message: t("resource.2fa.error.otpInvalidFormat"),
          },
        ],
      }),
    [t],
  );
  return defaultProps;
}

const OtpCodeInput = forwardRef<Api.OtpCodeRef, Api.OtpCodeProps>(
  function OtpCodeInput({ ...props }, ref) {
    const { t } = useTranslation();
    return (
      <Input
        inputMode="numeric"
        pattern="[0-9]*"
        maxLength={6}
        autoComplete="off"
        placeholder={t("page.login.2fa.field.otp.helperText")}
        {...props}
        ref={ref}
      />
    );
  },
);

/**
 * ```tsx
 * const formItemDefaultProps = OtpCodeInput.useFormItemDefaultProps();
 * return (
 *   <Form.Item {...formItemDefaultProps}>
 *     <OtpCodeInput />
 *   </Form.Item>;
 * );
 * ```
 */
const api = Object.assign(OtpCodeInput, {
  useFormItemDefaultProps,
});

export { api as OtpCodeInput };
