import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC, ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import type { NarrowIconButtonProps } from "@/components/Button/NarrowIconButton";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { useFormatDateTime } from "@/resources/datetime";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { AvatarAndName } from "@/resources/message/AvatarAndName";
import { isDeleted } from "@/resources/message/isDeleted";
import { MessageAudioPreview } from "@/resources/message/MessageAudioPreview";
import { MessageImagePreview } from "@/resources/message/MessageImagePreview";
import { MessageVideoPreview } from "@/resources/message/MessageVideoPreview";
import { isFileMetadata } from "@/resources/message/utils";
import { Attachment } from "@/routes/Chat/ui/ChatPanel/Pin/Content/Attachment";
import { useDropDownSwitch } from "@/routes/Chat/ui/ChatPanel/Pin/useDropDownSwitch";
import { useJumpToMessageController } from "@/routes/Chat/ui/jumpToMessage";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "stretch",
    listStyle: "none",
    gap: 8,
    marginBottom: 0,
  }),
  listItem: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "stretch",
    padding: 12,
    border: "none",
    borderRadius: 4,
    backgroundColor: theme.colors.neutral001,
    fontSize: "0.75rem",
    gap: "inherit",
    "&:hover": {
      backgroundColor: theme.colors.neutral002,
    },
    "&:focus, &:active": {
      backgroundColor: theme.colors.neutral003,
    },
  }),
  header: css({
    display: "flex",
    gap: "inherit",
    alignItems: "center",
    fontWeight: 500,
  }),
  deletedMessageFallback: css({
    display: "flex",
    alignItems: "center",
    gap: 4,
    color: theme.colors.neutral007,
  }),
  sender: css({
    flex: 1,
  }),
  unpinButton: css({
    color: theme.colors.neutral009,
    cursor: "default",
  }),
  content: css({
    textAlign: "left",
  }),
  date: css({
    color: theme.colors.neutral007,
  }),
});

const DeleteMessageFallback: FC = () => (
  <div css={styles.deletedMessageFallback}>
    <MotifIcon un-i-motif="circle_caution" />
    <div>
      <Trans i18nKey="resource.message.deleted.fallbackMessage" />
    </div>
  </div>
);

const MessageListItem: FC<{ message: CantataTypes["MessagePinned"] }> = ({
  message,
}) => {
  const showDeletedMessageFallback = useFeatureFlag("deletedMessageFallback");
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const memberId = memberIdUtils.useGet();
  const unpinMutation = cantata.message.useUnpin({
    params: {
      orgId,
      memberId,
      messageId: message.id,
    },
  });
  const unpinButtonClickHandler = useHandler<NarrowIconButtonProps["onClick"]>(
    function unpin(e) {
      e.stopPropagation();
      unpinMutation.mutate(undefined);
    },
  );
  const formatMessageTimestamp = useFormatDateTime();

  const date = formatMessageTimestamp(new Date(message.createdAt));
  const member = memberQueriesContext.useMember();

  const [, dropDownSwitch] = useDropDownSwitch();
  const jumpToMessageController = useJumpToMessageController();

  const jump = useHandler(() => {
    dropDownSwitch.off();
    jumpToMessageController.setup({
      cursor: message.cursor,
      message: {
        ...message,
        /**
         * TODO: Ask backend if they can provide the uuid.
         */
        uuid: random(),
        read: true,
        channelId: member.channelId,
        pinned: true,
      },
    });
  });

  const content = useMemo<ReactNode>(() => {
    if (showDeletedMessageFallback && isDeleted(message)) {
      return (
        <div css={styles.content}>
          <DeleteMessageFallback />
        </div>
      );
    }
    switch (message.type) {
      case "text":
        return <div css={styles.content}>{message.text}</div>;
      case "image":
        return <MessageImagePreview src={message.previewUrl} />;
      case "video":
        return <MessageVideoPreview src={message.originUrl} />;
      case "audio":
        return <MessageAudioPreview src={message.originUrl} />;
      case "file":
        return (
          <div>
            {!isFileMetadata(message.metadata) ? null : (
              <Attachment metadata={message.metadata} />
            )}
          </div>
        );
      default:
        message.type satisfies never;
        throw new Error(
          inspectMessage`unexpected message:${message.id} type: ${message.type}`,
        );
    }
  }, [message, showDeletedMessageFallback]);

  return (
    <li css={styles.listItem} tabIndex={0} onClick={jump}>
      <div css={styles.header}>
        <AvatarAndName
          member={member}
          message={message}
          avatarProps={{
            size: 24,
          }}
          css={styles.sender}
        />
        <Tooltip
          title={t("chat.pinMessage.action.unpin.tooltip")}
          placement="bottom"
        >
          <NarrowIconButton
            iconSize="small"
            css={styles.unpinButton}
            onClick={unpinButtonClickHandler}
            disabled={unpinMutation.isLoading}
            icon={<MotifIcon un-i-motif="bookmark_fill" />}
          />
        </Tooltip>
      </div>
      {content}
      <div css={styles.date}>{date}</div>
    </li>
  );
};

const Some: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const queryMyPinnedMessages = cantata.message.useListPinnedMessages(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      useErrorBoundary: true,
      suspense: true,
    },
  );

  if (!queryMyPinnedMessages.isSuccess) return null;

  return (
    <ul css={styles.root}>
      {queryMyPinnedMessages.data.messages.map((message) => (
        <MessageListItem key={message.id} message={message} />
      ))}
    </ul>
  );
};

const Wrapped: FC = () => {
  return (
    <ErrorBoundary.Alert>
      <Some />
    </ErrorBoundary.Alert>
  );
};

assignDisplayName(Wrapped, "Some");

export { Wrapped as Some };
