import { useUserPermission } from "@/shared/application/user";

/**
 * Owner / Primary Admin / Admin can assign member
 */
export function useCanAssignMember() {
  const { hasPermission } = useUserPermission();

  return (
    hasPermission("manualAssignmentAll") ||
    hasPermission("canAssignMembersInMyTeams")
  );
}
