import { checkKey } from "@chatbotgang/etude/event/keycode";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { safeString } from "@chatbotgang/etude/string/safeString";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { KeyboardEventHandler } from "react";

import type { InputRef } from "@/components/Input";
import { Input } from "@/components/Input";
import { useInputComposition } from "@/shared/hooks/useInputComposition";

const CategoryNameInputWrapper = styled.div<{ $hasError?: boolean }>`
  position: relative;

  ${(props) =>
    props.$hasError &&
    css`
      padding-bottom: 16px;
    `}
`;

const CategoryNameInputError = styled.div`
  position: absolute;
  z-index: 2;
  top: 40px;
  color: ${theme.colors.red005};
  line-height: 1;
`;

type CategoryNameInputProps = {
  value?: string;
  placeholder?: string;
  originalValue?: string;
  error?: string;
  onChange?: (value: string) => void;
  onCancel?: () => void;
  onFinish?: (value: string) => void;
};

export const CategoryNameInput = forwardRef<InputRef, CategoryNameInputProps>(
  function (props, ref) {
    const {
      value = "",
      originalValue,
      placeholder,
      error,
      onChange,
      onCancel,
      onFinish,
    } = props;

    const categoryInputComposition = useInputComposition();

    const isValueChange = (draft: string) => {
      return draft === "" || draft === originalValue;
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
      const draft = safeString(value, { trim: true });

      if (checkKey(e, "Escape")) onCancel?.();

      if (categoryInputComposition.isComposition || !checkKey(e, "Enter"))
        return;

      if (isValueChange(draft)) {
        onCancel?.();
        return;
      }
      onChange?.(draft);
      onFinish?.(draft);
    };

    const handleBlur = () => {
      const draft = safeString(value, { trim: true });
      if (isValueChange(draft)) {
        onCancel?.();
        return;
      }
      onChange?.(draft);
      onFinish?.(draft);
    };

    return (
      <CategoryNameInputWrapper $hasError={Boolean(error)}>
        <Input
          type="text"
          status={error ? "error" : undefined}
          ref={ref}
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChange?.(event.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...categoryInputComposition.bind}
        />
        {error && <CategoryNameInputError>{error}</CategoryNameInputError>}
      </CategoryNameInputWrapper>
    );
  },
);
