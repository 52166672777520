import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { flow, uniq } from "lodash-es";
import { type FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { createWithEqualityFn } from "zustand/traditional";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { Input } from "@/components/Input";
import { Modal } from "@/components/Modal";
import { MotifIcon } from "@/components/MotifIcon";
import { Select } from "@/components/Select";
import { SubText, Text } from "@/components/Typography";
import { UsersCount } from "@/resources/organization/UsersCount";
import {
  BatchInviteReport,
  type Task,
} from "@/routes/Settings/Users/People/List/BatchInviteReport";
import { getApiErrorBodyFromUnknownError } from "@/shared/domains/error";
import { isDefaultRoleType } from "@/shared/domains/role";
import { defaultRoleTypeTranslationKeyMap } from "@/shared/utils/translation/defaultRoleTypeTranslationKeyMap";

const StyledRestSeatCount = styled(SubText)`
  display: block;
  margin-bottom: 24px;
`;

type BatchAddUserFormValues = {
  emails: string;
  roleId: CantataTypes["Role"]["id"];
};

const EasyForm = createEasyForm<BatchAddUserFormValues>();

type AddUserModalBatchProps = {
  onVisibleChange: (visible: boolean) => void;
};

const selectEligibleRoles = ({
  roles,
}: {
  roles: Array<CantataTypes["Role"]>;
}) => {
  return roles.filter((role) => role.type !== "owner");
};

function isEmailValid(email: string) {
  return z.string().email().safeParse(email).success;
}

function setupTasks() {
  type State = {
    tasks: Array<Task>;
  };
  const useStore = createWithEqualityFn<State>(() => ({
    tasks: [],
  }));
  function setupTasks(emails: string[]) {
    useStore.setState(() => ({
      tasks: emails.map((email) => ({
        email,
        state: "pending",
      })),
    }));
  }
  function updateTask(email: string, invitingState: Task["state"]) {
    const items = useStore.getState().tasks;
    if (!items.some((item) => item.email === email)) {
      return;
    }
    useStore.setState((state) => ({
      tasks: state.tasks.map((item) =>
        item.email === email ? { ...item, state: invitingState } : item,
      ),
    }));
  }
  return {
    useStore,
    setupTasks,
    updateTask,
  };
}

const AddUserModalBatch: FC<AddUserModalBatchProps> = ({ onVisibleChange }) => {
  const { t } = useTranslation();
  const [easyForm, form] = EasyForm.useForm();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const queriesData = QueriesContext.useData();
  const usersCount = queriesData.usersCount;
  const orgPlan = queriesData.orgPlan;
  const roles = queriesData.roles;
  const [verifiedEmailsCount, setVerifiedEmailsCount] = useState<number | null>(
    null,
  );
  const emailsVerified = Boolean(verifiedEmailsCount);
  const verifyEmails = useHandler(() => {
    const emails: string = easyForm.controller.getFieldValue("emails");
    // can be separated by comma, space, tab, or newline
    const emailArray = flow(
      () =>
        emails.split(/,|\s|\t|\n/).flatMap((email) => {
          const trimmedEmail = email.trim();
          return !trimmedEmail || !isEmailValid(trimmedEmail)
            ? []
            : [trimmedEmail];
        }),
      uniq,
    )();
    easyForm.controller.setFieldsValue({
      emails: emailArray.join("\n"),
    });
    setVerifiedEmailsCount(emailArray.length);
  });

  const initialValues = useMemo<
    ComponentProps<typeof EasyForm>["initialValues"]
  >(
    () => ({
      roleId: roles[0]?.id || Number.NaN,
      emails: "",
    }),
    [roles],
  );

  const inviteUserMutation = cantata.user.useInviteUser({
    params: {
      orgId,
    },
  });
  const isRequesting = inviteUserMutation.isLoading;

  const restSeatCount = useMemo(() => {
    const restSeats = orgPlan.seatNum - usersCount;
    return restSeats <= 0 ? 0 : restSeats;
  }, [orgPlan.seatNum, usersCount]);

  const overSeat =
    restSeatCount <= 0 ||
    (!verifiedEmailsCount || verifiedEmailsCount === 0
      ? false
      : verifiedEmailsCount > restSeatCount);

  const handleCloseModal = useCallback(() => {
    onVisibleChange(false);
    easyForm.controller.resetFields();
  }, [easyForm.controller, onVisibleChange]);

  const [tasksUtils] = useState(setupTasks);
  const tasks = tasksUtils.useStore((state) => state.tasks);
  const tasksSettled = useMemo(
    () => tasks.length > 0 && tasks.every((task) => task.state !== "pending"),
    [tasks],
  );

  const handleFinish = useHandler<ComponentProps<typeof EasyForm>["onFinish"]>(
    async (values) => {
      if (!emailsVerified) {
        verifyEmails();
        return;
      }
      const emails = values.emails.split("\n");
      tasksUtils.setupTasks(emails);
      for (const email of emails) {
        const result = await safePromise(async () =>
          inviteUserMutation.mutateAsync({ email, roleId: values.roleId }),
        );
        if (result.error) {
          const errorApiBody = getApiErrorBodyFromUnknownError(result.error);
          if (errorApiBody?.name === "PARAMETER_USER_EXIST") {
            tasksUtils.updateTask(email, "exist");
            continue;
          }
          tasksUtils.updateTask(email, "error");
          continue;
        }
        tasksUtils.updateTask(email, "success");
      }
    },
  );

  return (
    <DisabledContextProvider disabled={isRequesting}>
      <Modal
        title={t("organization.addUser")}
        open
        {...(tasksSettled
          ? {
              cancelButtonProps: {
                style: {
                  display: "none",
                },
              },
              okButtonProps: {
                style: {
                  display: "none",
                },
              },
            }
          : emailsVerified
            ? {
                onOk: form.submit,
                okText: t("page.settings.users.invite.batch.action.invite"),
                okButtonProps: {
                  loading: inviteUserMutation.isLoading,
                  ...(!overSeat
                    ? null
                    : {
                        disabled: true,
                      }),
                },
              } // initial state
            : {
                onOk: verifyEmails,
                okText: t("page.settings.users.invite.batch.action.verify"),
              })}
        closable={!isRequesting}
        onCancel={isRequesting ? undefined : handleCloseModal}
        cancelText={t("common.cancel")}
      >
        {tasks.length === 0 ? (
          <EasyForm
            initialValues={initialValues}
            requiredMark={false}
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            disabled={isRequesting}
          >
            <EasyForm.Item
              name="emails"
              label={t("organization.pleaseInputEmail")}
              rules={[
                {
                  required: true,
                  message: t("organization.pleaseInputEmail"),
                },
              ]}
              style={{ marginBottom: 8 }}
              {...(!overSeat
                ? null
                : {
                    validateStatus: "error",
                  })}
            >
              <Input.TextArea
                placeholder={["foo@mail.com", "bar@mail.com"].join("\n")}
                onChange={() => {
                  setVerifiedEmailsCount(null);
                }}
              />
            </EasyForm.Item>
            <StyledRestSeatCount
              style={{
                ...(restSeatCount > 0
                  ? null
                  : {
                      color: theme.colors.error,
                    }),
              }}
            >
              {t("organization.restPosition", { count: restSeatCount })}
              {!verifiedEmailsCount ? null : (
                <>
                  {` - ${verifiedEmailsCount} = `}
                  <span
                    style={{
                      color: overSeat
                        ? theme.colors.red006
                        : theme.colors.green006,
                    }}
                  >
                    {restSeatCount - verifiedEmailsCount}
                  </span>{" "}
                  {overSeat ? (
                    <MotifIcon
                      style={{
                        color: theme.colors.red006,
                      }}
                      un-i-motif="circle_cross"
                    />
                  ) : (
                    <MotifIcon
                      style={{
                        color: theme.colors.green006,
                      }}
                      un-i-motif="check"
                    />
                  )}
                </>
              )}
            </StyledRestSeatCount>
            <EasyForm.Item
              name="roleId"
              label={t("organization.pleaseSelectRole")}
              wrapperCol={{
                span: 8,
              }}
              rules={[
                {
                  required: true,
                  message: t("organization.pleaseSelectRole.rule.required"),
                },
              ]}
            >
              {
                <Select
                  options={roles.map((role) => ({
                    key: role.id,
                    value: role.id,
                    label: isDefaultRoleType(role.type) ? (
                      <Text>
                        <Trans>
                          {defaultRoleTypeTranslationKeyMap[role.type]}
                        </Trans>
                      </Text>
                    ) : (
                      <Text>{role.name}</Text>
                    ),
                  }))}
                />
              }
            </EasyForm.Item>
          </EasyForm>
        ) : (
          <BatchInviteReport tasks={tasks} />
        )}
      </Modal>
    </DisabledContextProvider>
  );
};

function useSetupQueries() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const rolesQuery = cantata.role.useListRoles(
    {
      params: {
        orgId,
      },
    },
    {
      select: selectEligibleRoles,
      useErrorBoundary: true,
      suspense: true,
    },
  );
  const usersCountQuery = UsersCount.useQuery({
    orgId,
    /**
     * Internal users are not counted for seat.
     */
    excludeInternal: true,
    suspense: true,
    useErrorBoundary: true,
  });
  const orgPlanQuery = cantata.orgPlan.useGetById(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const queries = useMemo(
    () => ({
      roles: rolesQuery,
      usersCount: usersCountQuery,
      orgPlan: orgPlanQuery,
    }),
    [rolesQuery, usersCountQuery, orgPlanQuery],
  );
  return queries;
}

const QueriesContext =
  createQueriesContext<ReturnType<typeof useSetupQueries>>();

const Wrapped: FC<AddUserModalBatchProps> = ({ onVisibleChange }) => {
  const queries = useSetupQueries();
  return (
    <ErrorBoundary.Modal
      ModalProps={{
        onCancel: () => onVisibleChange(false),
      }}
    >
      <QueriesContext.Provider queries={queries}>
        <AddUserModalBatch onVisibleChange={onVisibleChange} />
      </QueriesContext.Provider>
    </ErrorBoundary.Modal>
  );
};

export { Wrapped as AddUserModalBatch };
