import type { Locale as DateFnsLocale } from "date-fns";
import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  format as formatDate,
  getDate,
  getDay,
  getHours,
  getMilliseconds,
  getMinutes,
  getMonth,
  getSeconds,
  getWeek,
  getYear,
  isAfter,
  isValid,
  parse as parseDate,
  setDate,
  setHours,
  setMilliseconds,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
  startOfWeek,
} from "date-fns";
import { enUS, ja, th, zhTW } from "date-fns/locale";
import type { GenerateConfig } from "rc-picker/es/generate";

import { getOrgData } from "@/router/components/Protected/InOrg/useOrgStore";

const locales: Record<string, DateFnsLocale> = {
  enUS,
  jaJP: ja,
  thTH: th,
  zhTW,
};

const getLocale = (localeCode: string): Locale => {
  const locale = locales[dealLocal(localeCode)];
  if (locale === undefined) return enUS;

  return locale;
};

const dealLocal = (str: string) => {
  return str.replace(/_/g, "");
};

const localeParse = (format: string) => {
  return format
    .replace(/Y/g, "y")
    .replace(/D/g, "d")
    .replace(/gggg/, "yyyy")
    .replace(/g/g, "G")
    .replace(/([Ww])o/g, "wo");
};

const generateDateFnsConfig: GenerateConfig<Date> = {
  // get
  getNow: () => new Date(),
  getFixedDate: (string) => new Date(string),
  getEndDate: (date) => endOfMonth(date),
  getWeekDay: (date) => getDay(date),
  getYear: (date) => getYear(date),
  getMonth: (date) => getMonth(date),
  getDate: (date) => getDate(date),
  getHour: (date) => getHours(date),
  getMinute: (date) => getMinutes(date),
  getSecond: (date) => getSeconds(date),
  getMillisecond: (date) => getMilliseconds(date),

  // set
  addYear: (date, diff) => addYears(date, diff),
  addMonth: (date, diff) => addMonths(date, diff),
  addDate: (date, diff) => addDays(date, diff),
  setYear: (date, year) => setYear(date, year),
  setMonth: (date, month) => setMonth(date, month),
  setDate: (date, num) => setDate(date, num),
  setHour: (date, hour) => setHours(date, hour),
  setMinute: (date, minute) => setMinutes(date, minute),
  setSecond: (date, second) => setSeconds(date, second),
  setMillisecond: (date, ms) => setMilliseconds(date, ms),

  // Compare
  isAfter: (date1, date2) => isAfter(date1, date2),
  isValidate: (date) => isValid(date),

  locale: {
    getWeekFirstDay: (locale) => {
      const orgData = getOrgData();
      if (orgData) {
        return orgData.startWeekday;
      }
      const clone = getLocale(locale);
      const weekStartsOn = clone.options?.weekStartsOn;
      if (weekStartsOn === undefined) return 0;

      return weekStartsOn;
    },
    getWeekFirstDate: (locale, date) => {
      return startOfWeek(date, {
        locale: getLocale(locale),
        ...() => {
          const orgData = getOrgData();
          if (!orgData) return null;
          return {
            weekStartsOn: orgData.startWeekday,
          };
        },
      });
    },
    getWeek: (locale, date) => {
      return getWeek(date, {
        locale: getLocale(locale),
        ...() => {
          const orgData = getOrgData();
          if (!orgData) return null;
          return {
            weekStartsOn: orgData.startWeekday,
          };
        },
      });
    },
    getShortWeekDays: (locale) => {
      const clone = getLocale(locale);
      return Array.from({ length: 7 }).map((_, i) =>
        clone.localize?.day(i, { width: "short" }),
      );
    },
    getShortMonths: (locale) => {
      const clone = getLocale(locale);
      return Array.from({ length: 12 }).map((_, i) =>
        clone.localize?.month(i, { width: "abbreviated" }),
      );
    },
    format: (locale, date, format) => {
      if (!isValid(date)) return "";

      return formatDate(date, localeParse(format), {
        locale: getLocale(locale),
        ...() => {
          const orgData = getOrgData();
          if (!orgData) return null;
          return {
            weekStartsOn: orgData.startWeekday,
          };
        },
      });
    },
    parse: (locale, text, formats) => {
      for (const format of formats) {
        const formatString = localeParse(format);
        const date = parseDate(text, formatString, new Date(), {
          locale: getLocale(locale),
          ...() => {
            const orgData = getOrgData();
            if (!orgData) return null;
            return {
              weekStartsOn: orgData.startWeekday,
            };
          },
        });
        if (isValid(date)) return date;
      }
      return null;
    },
  },
};

export { generateDateFnsConfig };
