import { literalUnion } from "./literalUnion";

const Sunday = 0;
const Monday = 1;
const Tuesday = 2;
const Wednesday = 3;
const Thursday = 4;
const Friday = 5;
const Saturday = 6;

const WeekdayNumberSchema = literalUnion([
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
] as const);

const WeekdayEnumLike = {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
} as const;

export { WeekdayEnumLike, WeekdayNumberSchema };
