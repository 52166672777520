import type { ReactNode } from "react";

import type { CantataTypes } from "@/cantata/types";
import { useUserPermission } from "@/shared/application/user";

/**
 * Given the provided permission key, display the fallback if the user is not permitted to access it. Render the children otherwise.
 *
 * @param param0
 * @param param0.to - The permission key to check
 * @param param0.fallback - The fallback to render if the user is not permitted
 * @param param0.children - The children to render if the user is permitted
 * @returns fallback children or the available children
 */
export const PermissionChecker = ({
  to,
  fallback,
  children,
}: {
  to: keyof CantataTypes["Permissions"];
  fallback: ReactNode;
  children: ReactNode;
}): JSX.Element => {
  const { hasPermission } = useUserPermission();

  // If the user has that permission, render the children
  if (hasPermission(to)) return <>{children}</>;

  // Otherwise, render the fallback
  return <>{fallback}</>;
};
