import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext } from "react";

type Values = {
  assignee:
    | {
        type: "unassigned";
      }
    /**
     * Assigned to a user
     */
    | {
        type: "user";
        userId: number;
      }
    | {
        type: "team";
        teamId: number;
      }
    /**
     * Assigned to a user under a team
     */
    | {
        type: "user-in-team";
        userId: number;
        teamId: number;
      };
};

type AssigneeSelectorContextState = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  assignee: Values["assignee"] | undefined;
  onAssigneeChange: (value: Values["assignee"]) => void;
};

const AssigneeSelectorContext = createContext<
  AssigneeSelectorContextState | undefined
>(undefined);
function useAssigneeSelectorContext(): AssigneeSelectorContextState {
  const context = useContext(AssigneeSelectorContext);
  if (!context) throw new Error("useSearch must be used within SearchContext");
  return context;
}

export { AssigneeSelectorContext, useAssigneeSelectorContext };
export type { AssigneeSelectorContextState, Values };
