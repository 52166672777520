import type { FlexIcon } from "@line/bot-sdk";

import { classNames } from "@/components/LINEFlexMessage/styled";
import { sizeClassName } from "@/components/LINEFlexMessage/utils";

interface FlexIconComponentProps {
  // parentLayout: 'baseline';
  data: FlexIcon;
}

export const FlexIconComponent = ({
  data,
}: FlexIconComponentProps): JSX.Element => {
  /**
   * The icon's `flex` property is fixed to 0.
   */
  const { size, url } = data;
  return (
    <div className={classNames("icon", sizeClassName(size), "flex0")}>
      <div>
        <span style={{ width: "1em", backgroundImage: `url(${url})` }}></span>
      </div>
    </div>
  );
};
