import { makeApi } from "@zodios/core";

import { NotificationSettingSchema } from "../models";

const api = makeApi([
  {
    alias: "getSettings",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/me/notification-settings",
    response: NotificationSettingSchema,
  },
  {
    alias: "update",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/me/notification-settings",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: NotificationSettingSchema,
      },
    ],
    response: NotificationSettingSchema,
  },
]);
export { api };
