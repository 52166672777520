import { makeApi } from "@zodios/core";
import { z } from "zod";

import { IgAttachmentTypeSchema } from "../models";

const api = makeApi([
  /**
   * Directs Instagram to prepare the attachment from the provided URL in
   * readiness for sending.
   *
   * Spec:
   * [Swagger](https://swagger.cresclab.site/#/default/post_api_v1_orgs__org_id__chat_channels__channel_id__ig_attachment)
   */
  {
    alias: "create",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/ig-attachment",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          type: IgAttachmentTypeSchema,
          url: z.string(),
        }),
      },
    ],
    response: z.object({
      attachmentId: z.string(),
    }),
  },
]);

export { api };
