import { injectAffectedApis } from "@zeffiroso/zodios/react";
import { Zodios, type ZodiosEndpointDefinitions } from "@zodios/core";
import type { Narrow } from "@zodios/core/lib/utils.types";
import { ZodiosHooks } from "@zodios/react";

import { cantataAxios } from "@/lib/httpClient";

function createApi<Api extends ZodiosEndpointDefinitions>(
  name: string,
  api: Narrow<Api>,
) {
  const client = new Zodios(api, {
    axiosInstance: cantataAxios,
  });
  const hooks = new ZodiosHooks(name, client);
  injectAffectedApis(hooks, [name]);

  const hooksFree = new ZodiosHooks(name, client);

  return {
    client,
    hooks,
    hooksFree,
  };
}

export { createApi };
