import type { Value } from "@chatbotgang/etude/event/keycode";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { expandHotkeyToEdges } from "@zeffiroso/utils/github/hotkey/utils";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  useMemo,
} from "react";

import { Key } from "@/components/Kbd/Key";
import { defineStyles } from "@/shared/emotion";

const defaultComponent = "ul";

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface HotKeysOwnProps {
  /**
   * The component used for the root node.
   */
  component?: ElementType;
  /**
   * The [@github/hotkey](https://github.com/github/hotkey) hotkey to display.
   */
  hotkey: string;
}

interface HotKeysTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & HotKeysOwnProps;
  defaultComponent: RootComponent;
}

type HotKeysProps<
  RootComponent extends ElementType = HotKeysTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = HotKeysOwnProps &
  OverrideProps<
    HotKeysTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: css({
    margin: 0,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 4,
  }),
  sequence: css({
    "&:has(+&)::after": {
      content: '","',
      marginInlineEnd: "0.5em",
    },
    display: "flex",
    flexDirection: "inherit",
    flexWrap: "inherit",
    gap: "inherit",
  }),
  hotkey: css({
    "&:has(+&)::after": {
      content: '" "',
      marginInlineEnd: "0.5em",
    },
    display: "flex",
    flexDirection: "inherit",
    flexWrap: "inherit",
    gap: "inherit",
  }),
});

/**
 * The HotKeys component.
 *
 * It converts a [@github/hotkey](https://github.com/github/hotkey) hotkey
 * string to a list of key components.
 *
 * ```tsx
 * <HotKeys hotkey="Mod+Shift+Z" />
 * ```
 */
const HotKeys: OverridableComponent<HotKeysTypeMap> = forwardRef(
  function HotKeys(
    { component: Component = defaultComponent, hotkey, ...props }: HotKeysProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const edges = useMemo(() => expandHotkeyToEdges(hotkey), [hotkey]);
    return (
      <Component css={styles.root} {...props} ref={ref}>
        {edges.map((sequence, index) => (
          <li key={index} css={styles.sequence}>
            {sequence.map((hotkey, index) => (
              <div key={index} css={styles.hotkey}>
                {hotkey.split("+").map((key, i) => (
                  <Key key={i} keyValue={key as Value} />
                ))}
              </div>
            ))}
          </li>
        ))}
      </Component>
    );
  },
) as OverridableComponent<HotKeysTypeMap>;

export { defaultComponent, HotKeys };

export type { HotKeysOwnProps, HotKeysProps, HotKeysTypeMap };
