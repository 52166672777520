import { emit2Sw as emit } from "@zeffiroso/sw-events/client2Sw";
import type { eventsSchema } from "@zeffiroso/sw-events/client2Sw/events";

import { isSupported } from "@/app/messaging/isSupported";
import { getServiceWorkerRegistration } from "@/app/sw/getServiceWorkerRegistration";
import { logError } from "@/shared/application/logger/sentry";

async function emit2Sw<EventKey extends keyof typeof eventsSchema>(
  eventKey: EventKey,
  ...args: Parameters<typeof emit<EventKey>>[2] extends void
    ? []
    : [body: Parameters<typeof emit<EventKey>>[2]]
): Promise<void> {
  if (!(await isSupported())) return;
  const sw = await getServiceWorkerRegistration();
  if (!sw) {
    logError("Service worker is not registered");
    return;
  }
  emit(sw, eventKey, ...args);
}

export { emit2Sw };
