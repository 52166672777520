import { DownOutlined } from "@ant-design/icons";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { isAxiosError } from "axios";
import { pick } from "lodash-es";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Flex } from "@/components/Box";
import { Dropdown } from "@/components/Dropdown";
import {
  DisabledContextProvider,
  useMergeFormDisabled,
} from "@/components/Form/DisabledContext";
import { defineItems } from "@/components/Menu/util";
import { useMessage } from "@/components/message";
import { Modal } from "@/components/Modal";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { UserStatusLabel } from "@/resources/user/UserStatus";
import { SuspenseModal } from "@/routes/Settings/Users/People/List/UpdateUserStatus/SuspenseModal";
import { handleNonGlobalApiError } from "@/shared/domains/error";

namespace UpdateUserStatus {
  export interface Props {
    userId: CantataTypes["User"]["id"];
    status: CantataTypes["User"]["status"];
  }
}

const UpdateUserStatus: FC<UpdateUserStatus.Props> = ({ userId, status }) => {
  const { t } = useTranslation();
  const [activateModalOpened, toggleActivateModal] = useSwitch(false);
  const [suspenseModalOpened, toggleSuspenseModal] = useSwitch(false);

  const ensureAllModalsClosed = useHandler(function ensureAllModalsClosed() {
    toggleActivateModal.off();
    toggleSuspenseModal.off();
  });

  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { me } = orgQueriesContext.useData();
  const meId = me.id;
  const userQuery = cantata.user.useGetById(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      enabled: status === "invited" || userId !== meId || activateModalOpened,
    },
  );

  const updateMutation = cantata.user.useUpdate(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      onSuccess: ensureAllModalsClosed,
      onError: async (err) => {
        const handled = await handleNonGlobalApiError(err, {
          AUTO_ASSIGNMENT_RULE_ASSIGNEE_AN_AGENT_EXISTED: () => {
            message.error(t("organization.suspendUserFailed.modal.content"));
          },
        });
        if (handled || (isAxiosError(err) && err.status === 404)) {
          ensureAllModalsClosed();
        }
      },
    },
  );

  const handleUpdateStatus = useHandler(
    (status: CantataTypes["User"]["status"]) => {
      if (!userQuery.data) return;

      const draft = {
        ...pick(userQuery.data, [
          "roleId",
          "name",
          "chatName",
          "mobile",
          "avatar",
          "enableSoundNotification",
          "enablePopupNotification",
          "languageCode",
          "externalUserId",
        ]),
        status,
      };

      updateMutation.mutate(draft);
    },
  );

  const operationNorPermitted = status === "invited" || userId === meId;

  const mergeFormDisabled = useMergeFormDisabled();
  const disabled = useMemo(
    () => mergeFormDisabled(operationNorPermitted),
    [mergeFormDisabled, operationNorPermitted],
  );

  return (
    <DisabledContextProvider disabled={disabled}>
      <Dropdown
        menu={{
          items: defineItems([
            status === "active"
              ? {
                  key: "suspend",
                  label: <UserStatusLabel status="suspend" />,
                  onClick: toggleSuspenseModal.on,
                  "data-test": "user-status-suspend-item",
                }
              : {
                  key: "active",
                  label: <UserStatusLabel status="active" />,
                  onClick: toggleActivateModal.on,
                  "data-test": "user-status-active-item",
                },
          ]),
        }}
        disabled={mergeFormDisabled(operationNorPermitted)}
      >
        <Flex
          css={css`
            align-items: center;
            gap: 8px;
          `}
        >
          <UserStatusLabel status={status} />
          {!operationNorPermitted && (
            <DownOutlined style={{ color: theme.colors.neutral007 }} />
          )}
        </Flex>
      </Dropdown>
      {status === "active" ? (
        <SuspenseModal
          userId={userId}
          open={suspenseModalOpened}
          onCancel={ensureAllModalsClosed}
          onOk={() => {
            handleUpdateStatus("suspend");
          }}
          okButtonProps={{
            loading: updateMutation.isLoading,
          }}
        />
      ) : (
        <Modal
          open={activateModalOpened}
          onCancel={ensureAllModalsClosed}
          cancelText={t("common.cancel")}
          okText={t("common.confirm")}
          title={t("common.enable")}
          onOk={() => handleUpdateStatus("active")}
          okButtonProps={{
            loading: updateMutation.isLoading,
          }}
        >
          {t("organization.activateUser.confirmText")}
        </Modal>
      )}
    </DisabledContextProvider>
  );
};

export { UpdateUserStatus };
