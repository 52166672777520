import { makeApi } from "@zodios/core";

import { FeatureControlsSchema, OrgSchema } from "../models";

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/feature-controls",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
    ],
    response: FeatureControlsSchema,
  },
]);

export { api };
