import { css } from "@emotion/react";
import type { ReactNode } from "react";

import { emotionMedia } from "@/shared/utils/style/emotionMedia";

const cssFormFieldWrap = css`
  display: flex;
  max-width: 40%;
  gap: 8px;

  > :nth-child(1) {
    width: 30%;
  }

  > :nth-child(2) {
    width: calc(70% - 8px);
  }

  ${emotionMedia(
    String.raw,
    "<=mobile",
    (css) => css`
      max-width: none;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      > :nth-child(1) {
        width: 100%;
      }

      > :nth-child(2) {
        width: 100%;
      }
    `,
  )}
`;

export const FormField = ({
  title,
  content,
}: {
  title: ReactNode;
  content: ReactNode;
}): JSX.Element => {
  return (
    <div css={cssFormFieldWrap}>
      <div>{title}</div>
      <div>{content}</div>
    </div>
  );
};
