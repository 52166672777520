/**
 * Steps of adding new locales:
 * 1. Import new locale file
 * 2. Add other language setting in `availableLocales`
 * 3. Go to `src/utils/LangsLocalesUtils.ts` and add new strategy in `langStrategies`
 * 4. Use strategy in `formatLangCode`, and `makeFallbackLangs`
 */
import { define } from "@chatbotgang/etude/util/define";
import type { ReactNode } from "react";

type BaseAvailableLocaleType = {
  code: string;
  displayName: ReactNode;
};

const defineLocales = define<Array<BaseAvailableLocaleType>>();

const availableLocales = defineLocales([
  {
    code: "zh-hant",
    displayName: "繁體中文",
  },
  {
    code: "en",
    displayName: "English",
  },
  {
    code: "th",
    displayName: "ภาษาไทย",
  },
  {
    code: "ja",
    displayName: "日本語",
  },
]);

export { availableLocales };
