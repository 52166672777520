import type { FlexButton } from "@line/bot-sdk";

import { classNames } from "@/components/LINEFlexMessage/styled";
import { buttonStyleClassName } from "@/components/LINEFlexMessage/utils";

interface FlexButtonComponentProps {
  parentLayout: "horizontal" | "vertical";
  data: FlexButton;
}

const smallButtonClassName = (height: FlexButton["height"]) => {
  return { button_height_sm: height === "sm" };
};

export const FlexButtonComponent = ({
  data,
}: FlexButtonComponentProps): JSX.Element => {
  const { style, height, color } = data;
  return (
    <div
      className={classNames(
        "button",
        buttonStyleClassName(style),
        smallButtonClassName(height),
      )}
    >
      {/**
       * TODO: Check if this is a bug in the original code.
       * Not sure why the original code has this anchor tag.
       */}
      <a>
        <div style={{ color }}>{data.action.label}</div>
      </a>
    </div>
  );
};
