import { memo } from "@chatbotgang/etude/react/memo";
import { ConfigProvider } from "antd";
/* eslint-disable no-restricted-imports -- allow antd locale which is not exported */
import antdEnUS from "antd/es/locale/en_US";
import antdJaJP from "antd/es/locale/ja_JP";
import antdThTH from "antd/es/locale/th_TH";
import antdZhTW from "antd/es/locale/zh_TW";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

const loadAntdLocale = (language: string) => {
  switch (true) {
    case language.startsWith("zh"):
      return antdZhTW;
    case language.startsWith("th"):
      return antdThTH;
    case language.startsWith("ja"):
      return antdJaJP;
    case language.startsWith("en"):
    default:
      return antdEnUS;
  }
};

const LocaleProvider = memo(function LocaleProvider({
  children,
}: {
  children: ReactNode;
}) {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <ConfigProvider locale={loadAntdLocale(language)}>
      {children}
    </ConfigProvider>
  );
});

export { LocaleProvider };
