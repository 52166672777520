import { define } from "@chatbotgang/etude/util/define";
import type { F, O } from "ts-toolbelt";

type ErrConfig<T> = T extends undefined
  ? {
      enabled?: false;
      message: string | (() => string);
    }
  : {
      enabled?: false;
      message: (params: T) => string;
    };

type BaseConfig<T> = {
  [name: string]: ErrConfig<T>;
};

function defineError<T>(error: F.Narrow<ErrConfig<T>>): ErrConfig<T> {
  const defaultConfig: O.Partial<ErrConfig<T>, "deep"> = {
    enabled: true,
  };
  return Object.assign(defaultConfig, error) as ErrConfig<T>;
}

/**
 * To define a config object.
 *
 * @example
 *
 * ```ts
 * const config = defineConfig({
 *   lag: defineConfig.defineError<{
 *     delay: number;
 *     count: number;
 *     timestamp: Date;
 *   }>({
 *     enabled: false, // default: true
 *     message: (params) =>
 *       inspectMessage`Lag detected: ${params.delay}ms, ${params.count} packets, ${formatDate(
 *         params.timestamp,
 *       )}`,
 *   }),
 * });
 * ```
 */
const defineConfigs = Object.assign(define<BaseConfig<any>>(), { defineError });

export { defineConfigs };
export type { BaseConfig, ErrConfig };
