import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";
import useSwitch from "@react-hook/switch";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { isEmpty, pick } from "lodash-es";
import type { FC, ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import type { FormProps } from "@/components/Form";
import { Form, FormItem } from "@/components/Form";
import { Input } from "@/components/Input";
import { BarLoading } from "@/components/Loading/BarLoading";
import { useMessage } from "@/components/message";
import { Select } from "@/components/Select";
import { BoldText, SubText } from "@/components/Typography";
import { FormLayout } from "@/layout/FormLayout";
import { parseMobile } from "@/lib/mobile";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { UserAssignmentQrcodeModal } from "@/resources/assignmentQrcode/UserAssignmentQrcodeModal";
import { useFormatDateTime } from "@/resources/datetime";
import { TeamsSelector } from "@/resources/team/TeamsSelector";
import { AvatarFormItem } from "@/routes/Settings/Profile/ui/AvatarFormItem";
import { CountryCodesItem } from "@/routes/Settings/Profile/ui/CountryCodesItem";
import { isDefaultRoleType } from "@/shared/domains/role";
import { availableLocales } from "@/shared/g11n/config";
import { defaultRoleTypeTranslationKeyMap } from "@/shared/utils/translation/defaultRoleTypeTranslationKeyMap";

const StyledRoleWrap = styled(Flex)`
  flex-direction: column;
  margin-bottom: 16px;
  gap: 8px;
`;

const pickUserMutationPayload = (user: CantataTypes["UserMe"]) => {
  return pick(user, [
    "name",
    "chatName",
    "mobile",
    "avatar",
    "languageCode",
    "externalUserId",
  ]);
};

function selectFormData(user: CantataTypes["UserMe"]) {
  const parseResult = parseMobile(user.mobile);
  return {
    ...user,
    mobile: parseResult.formattedNationalNumber,
    countryCode: parseResult.formattedCountryCode,
  };
}

type FormValues = Pick<
  CantataTypes["UserDetail"],
  "avatar" | "chatName" | "mobile" | "name" | "externalUserId"
> & {
  countryCode: string;
};

function useSetupMyProfileQueriesContext() {
  const orgId = useActiveOrgIdStore((state) => state.value);

  const usersQuery = cantata.user.useList(
    {
      params: {
        orgId,
      },
    },
    {
      select: (data) => data.users,
      useErrorBoundary: true,
      suspense: true,
    },
  );

  const queriesContextValue = useMemo(
    () => ({
      users: usersQuery,
    }),
    [usersQuery],
  );
  return queriesContextValue;
}

const myProfileQueriesContext =
  createQueriesContext<ReturnType<typeof useSetupMyProfileQueriesContext>>();

const MyProfileQueriesContextProvider: FC<{
  children: ReactNode;
}> = (props) => {
  const queries = useSetupMyProfileQueriesContext();
  return (
    <myProfileQueriesContext.Provider queries={queries}>
      {props.children}
    </myProfileQueriesContext.Provider>
  );
};

const MyProfileForm: FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const orgData = orgQueriesContext.useData();
  const me = orgData.me;
  const myProfileQueries = myProfileQueriesContext.useData();
  const enabledSalesBinding = useFeatureFlag("salesBinding");
  const [form] = Form.useForm<FormValues>();
  const formatDateTime = useFormatDateTime();
  const [userAssignmentQrcodeModalOpened, toggleUserAssignmentQrcodeModal] =
    useSwitch(false);
  const message = useMessage();
  const userQuery = cantata.user.useGetMe(
    {
      params: {
        orgId,
      },
    },
    {
      select: selectFormData,
      // TODO:callbacks for queries are going to be deprecated.
      onSuccess: (data) => {
        form.setFieldsValue(data);
      },
    },
  );

  const rolesQuery = cantata.role.useListRoles({
    params: {
      orgId,
    },
  });

  const inviterQuery = cantata.user.useGetById(
    {
      params: {
        orgId,
        userId: userQuery.data?.inviterId ?? Number.NaN,
      },
    },
    {
      enabled: !!userQuery.data?.inviterId,
    },
  );

  const updateMutation = cantata.user.useUpdateMe(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess: () => {
        message.success(t("common.updatedSuccessfully"));
        form.resetFields();
      },
    },
  );

  const onFinish = useHandler((values: FormValues) => {
    if (!userQuery.data) return;

    const { countryCode, mobile, avatar, externalUserId, ...restValues } =
      values;

    let formattedNumber = "";
    if (!isEmpty(mobile)) {
      const parsed = parseMobile(`${countryCode}${mobile}`);

      if (!parsed.isNumberValid) {
        form.setFields([
          {
            name: "mobile",
            errors: [t("validation.invalidPhoneNumber")],
          },
        ]);

        return;
      }

      formattedNumber = parsed.formattedFullNumber;
    }

    // reset mobile field error
    form.setFields([
      {
        name: "mobile",
        errors: [],
      },
    ]);

    const payload = pickUserMutationPayload(userQuery.data);
    const nextValues = {
      ...payload,
      ...restValues,
      avatar: !avatar ? null : avatar,
      mobile: formattedNumber,
      ...(!enabledSalesBinding
        ? { externalUserId: null }
        : { externalUserId: !externalUserId ? null : externalUserId }),
    };

    updateMutation.mutate(nextValues);
  });

  const onValuesChange = useHandler<FormProps<FormValues>["onValuesChange"]>(
    function onValuesChange() {
      updateMutation.reset();
    },
  );

  if (userQuery.isLoading) return <BarLoading />;

  if (userQuery.isError)
    return <Alert type="error" message={inspectMessage`${userQuery.error}`} />;

  return (
    <Form<FormValues>
      form={form}
      initialValues={userQuery.data}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      routerPromptOptions={{
        promptIfAnyFieldsError: true,
      }}
      scrollToFirstError
    >
      <Form.ValidateOnMount />
      <FormLayout.Container
        submit={
          <FormLayout.SubmitButton loading={updateMutation.isLoading}>
            {t("common.update")}
          </FormLayout.SubmitButton>
        }
      >
        <FormLayout.RowFieldItem
          width="wide"
          title={<BoldText>{t("myProfile.personalAvatar")}</BoldText>}
          content={
            <FormItem name="avatar">
              <AvatarFormItem
                type="user"
                orgId={orgId}
                userId={me?.id ?? Number.NaN}
              />
            </FormItem>
          }
        />
        <FormLayout.RowFieldItem
          title={<BoldText>{t("common.name")}</BoldText>}
          content={
            <FormItem
              name="name"
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey="validation.pleaseInputName" />,
                },
                {
                  max: 20,
                  message: t("validation.maxCharLength", { count: 20 }),
                },
              ]}
            >
              <Input />
            </FormItem>
          }
        />
        <FormLayout.RowFieldItem
          title={<BoldText>{t("common.displayName")}</BoldText>}
          content={
            <FormItem
              name="chatName"
              rules={[
                {
                  required: true,
                  message: (
                    <Trans i18nKey="validation.pleaseInputDisplayName" />
                  ),
                },
                {
                  max: 20,
                  message: t("validation.maxCharLength", { count: 20 }),
                },
              ]}
            >
              <Input />
            </FormItem>
          }
        />
        {!enabledSalesBinding ? null : (
          <FormLayout.RowFieldItem
            title={<BoldText>{t("user.form.externalUserId.label")}</BoldText>}
            content={
              <FormItem
                name="externalUserId"
                rules={[
                  {
                    max: 255,
                    message: t("validation.maxCharLength", { count: 255 }),
                  },
                  {
                    validator: async (_rule, value) => {
                      if (!value) return;
                      const targetUserName = myProfileQueries.users.find(
                        (user) =>
                          user.id !== me.id && user.externalUserId === value,
                      )?.name;
                      if (!targetUserName) return;
                      throw new Error(
                        t("user.form.externalUserId.existed", {
                          userName: targetUserName,
                        }),
                      );
                    },
                  },
                ]}
                extra={<Trans i18nKey="user.form.externalUserId.description" />}
              >
                <Input disabled />
              </FormItem>
            }
          />
        )}
        <FormLayout.RowFieldItem
          title={<BoldText>{t("chat.mobile")}</BoldText>}
          content={
            <FormItem name="mobile">
              <Input
                $noSpinner={true}
                addonBefore={
                  <FormItem name="countryCode" noStyle>
                    <CountryCodesItem />
                  </FormItem>
                }
                type="number"
              />
            </FormItem>
          }
        />
        <FormLayout.RowFieldItem
          title={<BoldText>{t("glossary.email")}</BoldText>}
          content={
            <FormItem name="email">
              <Input disabled />
            </FormItem>
          }
        />
        <FormLayout.RowFieldItem
          title={<BoldText>{t("myProfile.roles")}</BoldText>}
          content={
            <StyledRoleWrap>
              <FormItem noStyle>
                {rolesQuery.isLoading ? (
                  <Select loading disabled />
                ) : rolesQuery.isError ? (
                  <Alert
                    type="error"
                    message={inspectMessage`${rolesQuery.error}`}
                  />
                ) : (
                  <Select
                    disabled={true}
                    value={userQuery.data.roleId}
                    options={rolesQuery.data.roles.map((role) => ({
                      key: role.id,
                      value: role.id,
                      disabled: role.type === "owner",
                      label: isDefaultRoleType(role.type) ? (
                        <SubText>
                          {t(defaultRoleTypeTranslationKeyMap[role.type])}
                        </SubText>
                      ) : (
                        <SubText>{role.name}</SubText>
                      ),
                    }))}
                  />
                )}
              </FormItem>
              <SubText>
                {t("myProfile.createTimeHint", {
                  date: formatDateTime(userQuery.data.createdAt),
                })}
              </SubText>
              <SubText>
                {t("myProfile.inviterHint", {
                  name: inviterQuery.isSuccess
                    ? inviterQuery.data.name
                    : EMPTY_STRING_PLACEHOLDER,
                })}
              </SubText>
            </StyledRoleWrap>
          }
        />
        <FormLayout.RowFieldItem
          title={<BoldText>{t("myProfile.team")}</BoldText>}
          content={
            <FormItem>
              <TeamsSelector
                value={userQuery.data.teams.map((team) => team.id)}
                disabled
              />
            </FormItem>
          }
        />
        <FormLayout.RowFieldItem
          title={<BoldText>{t("myProfile.qrCode")}</BoldText>}
          content={
            <FormItem>
              <Button
                type="primary"
                onClick={toggleUserAssignmentQrcodeModal.on}
              >
                {t("myProfile.createQRCode")}
              </Button>
              {!userAssignmentQrcodeModalOpened ? null : (
                <UserAssignmentQrcodeModal
                  orgId={orgId}
                  userId={me.id}
                  onCancel={toggleUserAssignmentQrcodeModal.off}
                />
              )}
            </FormItem>
          }
        />
        <FormLayout.RowFieldItem
          title={<BoldText>{t("glossary.language")}</BoldText>}
          content={
            <FormItem name="languageCode">
              <Select>
                {availableLocales.map((locale) => (
                  <Select.Option
                    key={locale.code}
                    value={locale.code}
                    label={locale.displayName}
                  >
                    {locale.displayName}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          }
        />
      </FormLayout.Container>
    </Form>
  );
};

const MyProfile: FC = () => {
  return (
    <ErrorBoundary.Alert>
      <MyProfileQueriesContextProvider>
        <MyProfileForm />
      </MyProfileQueriesContextProvider>
    </ErrorBoundary.Alert>
  );
};

export { MyProfile };
