import { useStore } from "@/internal/zendesk/zustandStore";
import { logError } from "@/shared/application/logger/sentry";

/**
 * Zendesk Web Widget SDK
 *
 *
 * @see https://developer.zendesk.com/api-reference/widget-messaging/web/core/
 */
type Ze = {
  (arg0: "messenger", arg1: "show"): void;
  (arg0: "messenger", arg1: "hide"): void;
  (arg0: "messenger", arg1: "open"): void;
  (arg0: "messenger", arg1: "close"): void;
  (arg0: "messenger:on", arg1: "open", callback: () => void): void;
  (arg0: "messenger:on", arg1: "close", callback: () => void): void;
  (
    arg0: "messenger:on",
    arg1: "unreadMessages",
    callback: (count: number) => void,
  ): void;
  (arg0: "messenger:set", arg1: "locale", locale: string): void;
  (arg0: "messenger:set", arg1: "zIndex", zIndex: number): void;
  (arg0: "messenger:set", arg1: "cookies", isEnabled: boolean): void;
  (
    arg0: "messenger:set",
    arg1: "conversationFields",
    conversationFields: Array<{ id: string; value: string | number | boolean }>,
  ): void;
  (
    arg0: "messenger:set",
    arg1: "conversationTags",
    conversationTags: Array<string>,
  ): void;

  /**
   * @see https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/
   */
  (
    arg0: "messenger",
    arg1: "loginUser",
    callback: (callback: (newJwtForUser: string) => void) => void,
  ): void;
  (arg0: "messenger", arg1: "logoutUser"): void;
};

const zE: Ze = function zE(...args: any) {
  if (!("zE" in window) || !window.zE) return;
  const zE = window.zE;
  if (typeof zE !== "function") return;
  try {
    return zE(...args);
  } catch (e) {
    const zeError = new Error("Zendesk Web Widget SDK error");
    zeError.name = "ZendeskWebWidgetSDKError";
    zeError.cause = e;
    logError(zeError);
  }
};

function getState() {
  return useStore.getState().state;
}

function isLoaded() {
  return getState() === "loaded";
}

function useState() {
  return useStore(({ state }) => state);
}

function useLoaded() {
  const state = useState();
  return state === "loaded";
}

async function open() {
  if (!isLoaded()) return;
  zE("messenger", "open");
}

const zendesk = {
  getState,
  isLoaded,
  useState,
  useLoaded,
  zE,
  open,
};

export { zendesk };
