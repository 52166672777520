import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { ChannelTypeIcon } from "@/resources/channel/ChannelTypeIcon";
import { cssOneLine, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    alignItems: "center",
    gap: 4,
  }),
  icon: css({
    fontSize: "1rem",
  }),
  name: css([
    cssOneLine,
    css`
      color: ${theme.colors.neutral007};
      font-size: 0.75rem;
    `,
  ]),
});

const Channel: FC = () => {
  const channel = memberQueriesContext.useMemberChannel();
  return (
    <div css={styles.root}>
      <ChannelTypeIcon css={styles.icon} channel={channel} />
      <div css={styles.name}>
        {channel.name || <i>{EMPTY_STRING_PLACEHOLDER}</i>}
      </div>
    </div>
  );
};

export { Channel };
