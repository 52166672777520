import { makeApi } from "@zodios/core";
import { format } from "date-fns";
import { z } from "zod";

import {
  AssignSchema,
  DirectChatSearchMemberSchema,
  MemberAssignmentFilterSchema,
  MemberDetailSchema,
  MemberProcessingStateSchema,
  MemberSchema,
  MemberSearchActionSchema,
} from "../models";

const BirthdaySchema = z.coerce
  .date()
  .nullable()
  .transform((value) =>
    value === null ? "9999-12-31" : format(value, "yyyy-MM-dd"),
  );

const api = makeApi([
  {
    alias: "getById",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId",
    response: MemberDetailSchema,
  },
  {
    alias: "partialUpdate",
    method: "patch",
    path: "api/v1/orgs/:orgId/chat/members/:memberId",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: MemberDetailSchema.pick({
          name: true,
          displayName: true,
          displayEmail: true,
          displayMobile: true,
          gender: true,
          location: true,
          country: true,
          city: true,
          company: true,
          accountManager: true,
          engagementLevel: true,
          customId: true,
          memberLevel: true,
          starMark: true,
          connectId: true,
        })
          .extend({
            birthday: BirthdaySchema,
          })
          .partial(),
      },
    ],
    response: z.void(),
  },
  {
    alias: "pin",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/pin",
    response: z.object({
      userId: z.number().int(),
      channelId: z.number().int(),
      memberId: z.number().int(),
      createdAt: z.coerce.date(),
    }),
  },
  {
    alias: "unpin",
    method: "delete",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/pin",
    response: z.object({
      userId: z.number().int(),
      channelId: z.number().int(),
      memberId: z.number().int(),
      createdAt: z.coerce.date(),
    }),
  },
  {
    alias: "markRead",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/mark-read",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          senderSession: z.literal("no use"),
        }),
      },
    ],
    response: z.object({
      senderSession: z.literal("no use"),
    }),
  },
  /**
   * Spec: [Notion](https://www.notion.so/cresclab/202402-Mark-Unread-6e1b1094f30a43c985e3a2a4a1fc0c49?pvs=4#c7938481fc06461498070db36c06430e)
   */
  {
    alias: "markUnread",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/mark-unread",
    response: z.void(),
  },
  {
    alias: "updateProcessingState",
    method: "put",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/processing-state",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          processingState: MemberProcessingStateSchema,
          senderSession: z.literal("no use"),
        }),
      },
    ],
    response: z.void(),
  },
  {
    alias: "assign",
    method: "put",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/assignment",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.union([
          z.object({
            assignType: z.literal("unassigned"),
            assignmentRelationship: z.object({
              userId: z.null(),
              teamId: z.null(),
            }),
          }),
          z.object({
            assignType: z.null(),
            assignmentRelationship: z.object({
              userId: z.number().int().nullable(),
              teamId: z.number().int().nullable(),
            }),
          }),
        ]),
      },
    ],
    response: AssignSchema,
  },
  {
    alias: "directChatSearch",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/members/search-maac-direct-chat",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          channelType: z.literal("line"),
          externalChannelId: z.string(),
          externalMemberId: z.string(),
        }),
      },
    ],
    response: z.object({
      members: z.array(DirectChatSearchMemberSchema),
    }),
  },
  {
    alias: "unread",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/unread",
    parameters: [
      {
        type: "Query",
        name: "channelId",
        schema: z.number().int().optional(),
      },
      {
        type: "Query",
        name: "assignmentFilter",
        schema: MemberAssignmentFilterSchema,
      },
      {
        type: "Query",
        name: "processingState",
        schema: z.enum(["new", "follow-up"]),
      },
      {
        type: "Query",
        name: "assigneeId",
        schema: z.number().optional(),
      },
    ],
    response: z.object({
      unread: z.boolean(),
    }),
  },
  {
    alias: "bulkReassign",
    method: "put",
    path: "api/v1/orgs/:orgId/chat/members/assignment",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          assignType: z.literal("unassigned").nullable(),
          assignmentRelationship: z.object({
            userId: z.number().int().nullable(),
            teamId: z.number().int().nullable(),
          }),
          memberIds: z.array(z.number().int()),
        }),
      },
    ],
    response: z.object({
      total: z.number().int(),
      success: z.number().int(),
      failed: z.number().int(),
    }),
  },
  {
    alias: "bulkResolve",
    method: "put",
    path: "api/v1/orgs/:orgId/chat/members/processing-state",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          processingState: z.literal("resolved"),
          memberIds: z.array(z.number().int()),
        }),
      },
    ],
    response: z.object({
      total: z.number().int(),
      success: z.number().int(),
      failed: z.number().int(),
    }),
  },
  {
    alias: "list",
    method: "get",
    path: "api/v2/orgs/:orgId/chat/members",
    parameters: [
      /**
       * Filter
       */

      {
        type: "Query",
        name: "channelId",
        schema: z.number().int().optional(),
      },
      {
        type: "Query",
        name: "processingState",
        /** required when no search action  */
        schema: MemberProcessingStateSchema.optional(),
      },
      {
        type: "Query",
        name: "pinned",
        /** required when no search action */
        schema: z.boolean().optional(),
      },
      {
        type: "Query",
        name: "assignmentFilter",
        schema: MemberAssignmentFilterSchema,
      },
      {
        type: "Query",
        name: "assigneeId",
        /** required when assignmentFilter is 'assignee' */
        schema: z.number().int().optional(),
      },
      {
        type: "Query",
        name: "unread",
        /**
         * Omitted for disable filter.
         */
        schema: z.boolean().optional(),
      },

      /**
       * Search
       */

      {
        type: "Query",
        name: "searchAction",
        schema: MemberSearchActionSchema.optional(),
      },
      {
        type: "Query",
        name: "searchQuery",
        schema: z
          .union([z.string(), z.number(), z.array(z.number())])
          .optional(),
      },

      /**
       * Pagination
       */

      {
        type: "Query",
        name: "limit",
        schema: z.number().int(),
      },
      {
        type: "Query",
        name: "cursor",
        schema: z.string().optional(),
      },
    ],
    response: z.object({
      members: z.array(MemberSchema),
      cursor: z.object({
        before: z.string().nullable(),
        after: z.string().nullable(),
      }),
    }),
  },
  {
    alias: "count",
    method: "get",
    path: "api/v2/orgs/:orgId/chat/members/count",
    parameters: [
      /**
       * Filter
       */

      {
        type: "Query",
        name: "channelId",
        schema: z.number().int().optional(),
      },
      {
        type: "Query",
        name: "assignmentFilter",
        schema: MemberAssignmentFilterSchema,
      },
      {
        type: "Query",
        name: "assigneeId",
        /** used only when assignment_filter is assignee */
        schema: z.number().optional(),
      },
      {
        type: "Query",
        name: "processingState",
        /** required when no search action  */
        schema: MemberProcessingStateSchema.optional(),
      },
      {
        type: "Query",
        name: "pinned",
        /** required when no search action */
        schema: z.boolean().optional(),
      },
      {
        type: "Query",
        name: "unread",
        /**
         * Omitted for disable filter.
         */
        schema: z.boolean().optional(),
      },

      /**
       * Search
       */

      {
        type: "Query",
        name: "searchAction",
        schema: MemberSearchActionSchema.optional(),
      },
      {
        type: "Query",
        name: "searchQuery",
        schema: z
          .union([z.string(), z.number(), z.array(z.number())])
          .optional(),
      },
    ],
    response: z.object({
      count: z.number().int(),
    }),
  },
  {
    /**
     * Leave a chat group.
     * Spec:
     * [Notion](https://www.notion.so/cresclab/202407-Group-Chat-e12e33d62eff486ba48d7a8f10cef134?pvs=4#375fcac7601240149454adce131cd825)
     */
    alias: "leaveGroup",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/groups/:groupId/leave",
    response: z.unknown(),
  },
]);

export { api };
