import { LockOutlined } from "@ant-design/icons";
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { delay } from "@chatbotgang/etude/timer/delay";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { AxiosError } from "axios";
import { secondsToMilliseconds } from "date-fns/fp";
import { lowerFirst, noop } from "lodash-es";
import type { FC, ReactNode } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  authProviderStore,
  authSdk,
  newSsoProvider,
  useSignInUtils,
} from "@/app/auth";
import { Trans } from "@/app/i18n/Trans";
import type { cantataClient } from "@/cantata";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import { InputPassword } from "@/components/Input";
import { Modal } from "@/components/Modal";
import { useTwoFaFormUtils } from "@/resources/auth/twoFa/useTwoFaFormUtils";
import { useLogoutMutation } from "@/shared/application/authenticate";
import { cssFlexInheritAndFill } from "@/shared/emotion";
import { useLastUserEmailStore } from "@/shared/utils/createZustandStorageStore";

const SignInUtilsContext = createContext<ReturnType<typeof useSignInUtils>>({
  name: "SignInUtilsContext",
});

const TwoFaModal = (() => {
  const TwoFaModal: FC = () => {
    const { t } = useTranslation();
    const signInUtils = SignInUtilsContext.useContext();
    const twoFaFormUtils = useTwoFaFormUtils({
      signInUtils,
    });
    const logoutMutation = useLogoutMutation();
    const twoFaState = signInUtils.twoFa.utils.useState();
    const twoFaMutations = signInUtils.twoFa.mutations;
    const formDisabled = twoFaMutations.isLoading || logoutMutation.isLoading;

    const logout = useHandler<ComponentProps<typeof Button>["onClick"]>(
      function logout(e) {
        e.preventDefault();
        logoutMutation.mutate();
      },
    );

    const submit = useHandler<ComponentProps<typeof Button>["onClick"]>(
      function submit(e) {
        e.preventDefault();
        twoFaFormUtils.form.submit();
      },
    );

    if (!twoFaState) return null;
    return (
      <twoFaFormUtils.EasyForm
        {...twoFaFormUtils.easyFormProps}
        {
          // If the user is logging out, disable the form.
          ...(!logoutMutation.isLoading
            ? null
            : {
                disabled: true,
              })
        }
      >
        <Modal
          open
          title={t("tokenExpire.modal.title")}
          closable={false}
          onCancel={noop}
          footer={
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: 8,
              }}
            >
              {twoFaFormUtils.resendButton}
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  gap: "inherit",
                  flexWrap: "inherit",
                }}
              >
                <Button onClick={logout} loading={logoutMutation.isLoading}>
                  {t("common.logout")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={submit}
                  disabled={formDisabled || twoFaFormUtils.expired}
                  loading={twoFaMutations.verifyTwoFaMutation.isLoading}
                >
                  {t("common.login")}
                </Button>
              </div>
            </div>
          }
        >
          {twoFaFormUtils.content}
        </Modal>
      </twoFaFormUtils.EasyForm>
    );
  };
  return TwoFaModal;
})();

/**
 * Figma: [MAAC / SSO](https://www.figma.com/file/nrpXHzEyYadRfRN61bs2zm/SSO?type=design&node-id=3058%3A12913&mode=dev)
 */
const ExpiredModal: FC = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
  const [conflictedLogin, setConflictedLogin] = useState<
    | null
    | (Parameters<typeof cantataClient.auth.firebaseSignIn>[0] & {
        email: string;
      })
  >(null);
  const clearErrorMessage = useHandler(function clearErrorMessage() {
    setErrorMessage(null);
  });
  const passwordRef = useRef<HTMLInputElement>();
  const logoutMutation = useLogoutMutation({
    onError: () => {
      (async function focusPassword() {
        await delay(secondsToMilliseconds(1));
        passwordRef.current?.focus();
      })();
    },
  });
  const logout = useHandler(function refresh() {
    logoutMutation.mutate();
  });

  const signInUtils = useSignInUtils({
    onError(error) {
      if (!(error instanceof AxiosError)) return;
      const statusCode = error.response?.status;
      if (!statusCode) return;
      if (statusCode < 400 || statusCode >= 500) return;
      setErrorMessage(<Trans i18nKey="login.wrongEmailOrPassword" />);
    },
  });
  const mutation = signInUtils.mutation;
  const twFaState = signInUtils.twoFa.utils.useState();
  const [form] = Form.useForm<{
    password: string;
  }>();
  const email = useLastUserEmailStore(({ value }) => value);
  const authProvider = authProviderStore.useStore((state) => state.value);
  const ssoProvider = useMemo(
    () =>
      !authProvider?.providerId
        ? null
        : newSsoProvider(authProvider.providerId),
    [authProvider?.providerId],
  );
  const useAbortControllerStore = useUseAbortControllerStore();
  useEffect(
    function abortOnUnmount() {
      return function cleanup() {
        useAbortControllerStore.getState().abort();
      };
    },
    [useAbortControllerStore],
  );
  const onFinish = useHandler<
    FormProps<{
      password: string;
    }>["onFinish"]
  >(async function onFinish(values) {
    clearErrorMessage();
    if (!ssoProvider) {
      mutation.mutate({
        email,
        password: values.password,
      });
      return;
    }
    if (!authProvider?.domain) {
      throw new Error(
        inspectMessage`authProvider.?domain is falsy, authProvider: ${authProvider}`,
      );
    }
    const signal = useAbortControllerStore.getState().signal;
    const result = await authSdk.signInPopup({
      provider: ssoProvider,
      signal,
    });
    if (signal.aborted) return;
    if (!result.idToken) {
      setErrorMessage(
        inspectMessage`idToken is not found, idToken: ${result.idToken}`,
      );
      return;
    }
    if (result.email !== email) {
      setConflictedLogin(result);
      return;
    }
    if (!ssoProvider) {
      setErrorMessage(
        inspectMessage`ssoProvider is not found, ssoProvider: ${ssoProvider}`,
      );
      return;
    }
    mutation.mutate({
      domain: authProvider.domain,
      uid: result.uid,
      idToken: result.idToken,
      provider: ssoProvider,
    });
  });
  const twoFaModal = useMemo<ReactNode>(() => {
    return (
      <SignInUtilsContext.Provider value={signInUtils}>
        <TwoFaModal />
      </SignInUtilsContext.Provider>
    );
  }, [signInUtils]);
  const modal = useMemo<ReactNode>(() => {
    if (conflictedLogin) {
      const styles = {
        footer: css`
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;
          justify-content: space-between;
          gap: 12px;
        `,
        confirmActions: css`
          display: flex;
          flex: 1;
          flex-direction: inherit;
          flex-wrap: wrap;
          align-items: inherit;
          justify-content: flex-end;
          gap: inherit;
        `,
        content: css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `,
        signInButton:
          /**
           * Figma: [MAAC / SSO](https://www.figma.com/file/nrpXHzEyYadRfRN61bs2zm/SSO?type=design&node-id=3096%3A13859&mode=dev)
           */
          css`
            display: flex;
            overflow: hidden;
            max-width: 220px;
            gap: 0.5em;
            text-overflow: ellipsis;
          `,
        emailDisplay: css`
          display: inline;
          overflow: hidden;
          text-overflow: ellipsis;
        `,
      } satisfies Record<string, SerializedStyles>;
      const emailDisplay = (
        <div css={styles.emailDisplay}>{conflictedLogin.email}</div>
      );
      const footer = (
        <div css={styles.footer}>
          <div>
            <Button onClick={logout} loading={logoutMutation.isLoading}>
              {t("tokenExpired.action.signOut.label")}
            </Button>
          </div>
          <div css={styles.confirmActions}>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
              loading={mutation.isLoading}
            >
              {t("tokenExpired.action.reAuthenticate.label")}
            </Button>
            <Button
              type="primary"
              css={styles.signInButton}
              onClick={() => {
                if (!ssoProvider) {
                  setErrorMessage(
                    inspectMessage`ssoProvider is not found, ssoProvider: ${ssoProvider}`,
                  );
                  return;
                }
                if (!authProvider?.domain) {
                  setErrorMessage(
                    inspectMessage`authProvider.domain is falsy, authProvider: ${authProvider}`,
                  );
                  return;
                }
                clearErrorMessage();
                mutation.mutate({
                  domain: authProvider.domain,
                  uid: conflictedLogin.uid,
                  idToken: conflictedLogin.idToken,
                  provider: ssoProvider,
                });
              }}
              loading={mutation.isLoading}
            >
              <Trans
                i18nKey="tokenExpired.action.newSignIn.label"
                components={{
                  Email: emailDisplay,
                }}
              />
            </Button>
          </div>
        </div>
      );
      return (
        <Modal
          open
          closable={false}
          maskClosable={false}
          title={t("tokenExpired.error.mailConflict.title")}
          footer={footer}
        >
          <div css={styles.content}>
            {!errorMessage ? null : (
              <Alert type="error" message={errorMessage} />
            )}
            <div>
              {t("tokenExpired.error.mailConflict.content", {
                email: conflictedLogin.email,
              })}
            </div>
          </div>
        </Modal>
      );
    }
    return (
      <Modal
        open
        closable={false}
        maskClosable={false}
        onCancel={logout}
        cancelText={t("common.logout")}
        cancelButtonProps={{
          loading: logoutMutation.isLoading,
        }}
        okText={t("common.login")}
        onOk={() => form.submit()}
        okButtonProps={{
          loading: mutation.isLoading,
        }}
        title={t("tokenExpire.modal.title")}
      >
        <div
          css={css([
            cssFlexInheritAndFill,
            css`
              flex-direction: column;
              gap: 16px;

              & .ant-form-item {
                margin-bottom: 0;
              }
            `,
          ])}
        >
          {errorMessage ? (
            <Alert type="error" message={errorMessage} />
          ) : (
            <Trans i18nKey="tokenExpired.error.regularError.content" />
          )}
          {ssoProvider ? null : (
            <Form.Item
              name="password"
              label={<Trans i18nKey="glossary.password" />}
              rules={[
                {
                  required: true,
                  message: t("validation.pleaseInputField", {
                    field: lowerFirst(t`glossary.password`),
                  }),
                },
              ]}
            >
              <InputPassword
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                autoComplete="current-password"
                placeholder={t("common.inputFieldName", {
                  fieldName: lowerFirst(t`glossary.password`),
                })}
                autoFocus
              />
            </Form.Item>
          )}
        </div>
      </Modal>
    );
  }, [
    authProvider,
    clearErrorMessage,
    conflictedLogin,
    errorMessage,
    form,
    logout,
    logoutMutation.isLoading,
    mutation,
    ssoProvider,
    t,
  ]);
  return (
    <Form<{
      password: string;
    }>
      onFinish={onFinish}
      form={form}
      routerPromptOptions={{
        disabled: true,
      }}
      disabled={mutation.isLoading || logoutMutation.isLoading}
    >
      {twFaState ? twoFaModal : modal}
    </Form>
  );
};

export { ExpiredModal };
