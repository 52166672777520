import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import classNames from "classnames";
import { type ElementRef, type ReactNode, type Ref, useMemo } from "react";

import { useConfigContext } from "@/components/antd/useConfigContext";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";
import { useGetErrorMessage } from "@/shared/application/error/handleError";
import { defineStyles } from "@/shared/emotion";

import { Table } from ".";

const styles = defineStyles({
  ExpandError: css({
    borderColor: "transparent",
    color: theme.colors.error,
    "&:before,&:after": {
      content: "none",
    },
  }),
});

type ExpandErrorRef = ElementRef<"button">;

interface ExpandErrorProps<RecordType extends object>
  extends Omit<ComponentProps<"button">, "children"> {
  prefixCls?: string;
  locale?: Table.TableLocale;
  onExpand: (record: RecordType, e: React.MouseEvent<HTMLElement>) => void;
  record: RecordType;
  error: unknown;
}

interface ExpandErrorType {
  <RecordType extends object>(
    props: ExpandErrorProps<RecordType>,
    ref?: Ref<ExpandErrorRef>,
  ): ReactNode;
}

const ExpandError: ExpandErrorType = forwardRef(function ExpandError(
  {
    prefixCls: customizePrefixCls,
    locale: customizeLocale,
    onExpand,
    record,
    error,
    ...props
  },
  ref,
) {
  const configContext = useConfigContext();
  const { getPrefixCls, locale: contextLocale = Table.defaultLocale } =
    configContext;
  const prefixCls = getPrefixCls("table", customizePrefixCls);
  const iconPrefix = `${prefixCls}-row-expand-icon`;
  const locale: Table.TableLocale = useMemo(
    () => ({ ...contextLocale.Table, ...customizeLocale }),
    [contextLocale.Table, customizeLocale],
  );
  const getErrorMessage = useGetErrorMessage();
  const errorMessage = useMemo(
    () => getErrorMessage(error),
    [error, getErrorMessage],
  );
  return (
    <Tooltip title={errorMessage}>
      <button
        type="button"
        onClick={(e) => {
          onExpand(record, e);
          e.stopPropagation();
        }}
        css={styles.ExpandError}
        className={classNames(iconPrefix, `${iconPrefix}-expanded`)}
        aria-label={locale.collapse}
        aria-expanded
        ref={ref}
        {...props}
      >
        <MotifIcon un-i-motif="warning" />
      </button>
    </Tooltip>
  );
});

assignDisplayName(ExpandError, "Table.ExpandError");

export { ExpandError };
export type { ExpandErrorProps, ExpandErrorRef };
