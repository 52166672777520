import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { ComponentPropsWithRef } from "react";

type BadgeVariant = "info" | "plain" | "error";

type BadgeProps = {
  $variant: BadgeVariant;
} & ComponentPropsWithRef<"span">;

const BadgeVariantStyles: Record<BadgeVariant, SerializedStyles> = {
  info: css`
    background-color: ${theme.colors.blue005};
  `,
  plain: css`
    background-color: ${theme.colors.neutral002};
    color: ${theme.colors.neutral009};
  `,
  error: css`
    background-color: ${theme.colors.red005};
  `,
};

export const Badge = memo(
  forwardRef<HTMLSpanElement, BadgeProps>(function Badge(
    { $variant, ...props },
    ref,
  ) {
    return (
      <span
        {...props}
        ref={ref}
        css={css`
          --height: 16px;

          display: inline-block;
          height: var(--height);
          padding: 0 6px;
          border-radius: var(--height);
          color: #fff;
          font-size: 12px;
          line-height: var(--height);
          ${BadgeVariantStyles[$variant]};
        `}
      />
    );
  }),
);
