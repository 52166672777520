import type { FC } from "react";
import { useEffect, useMemo } from "react";
import ReactGA4 from "react-ga4";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { rootQueriesContext } from "@/queriesContext/rootQueriesContext";

type UserProperties = {
  org_id: number | null;
  org_user_id: number | null;
};

const initialUserProperties: UserProperties = {
  org_id: null,
  org_user_id: null,
};

const BindGa4: FC = (function declareBindGa4() {
  const BindGa4: FC = () => {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const rootQueriesData = rootQueriesContext.useData();
    const orgQueriesData = orgQueriesContext.useData();
    const userId = useMemo(
      () => rootQueriesData.account.accountId,
      [rootQueriesData.account.accountId],
    );
    const userProperties = useMemo<UserProperties>(
      () => ({
        org_id: orgId,
        org_user_id: orgQueriesData.me.id,
      }),
      [orgId, orgQueriesData.me.id],
    );

    useEffect(
      function setGaUserProperties() {
        ReactGA4.set({
          user_id: userId,
          user_properties: userProperties,
        });

        return function cleanup() {
          ReactGA4.set({
            user_id: null,
            user_properties: initialUserProperties,
          });
        };
      },
      [userId, userProperties],
    );
    return null;
  };

  return BindGa4;
})();

export { BindGa4 };
