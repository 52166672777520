import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { ComponentPropsWithRef, ReactNode } from "react";
import { useMemo } from "react";

import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { MotifIcon } from "@/components/MotifIcon";
import { cssOneLine } from "@/shared/emotion";

type ExternalLinkProps = ComponentPropsWithRef<"a"> & {
  href: string;
  trailingIcon?: ReactNode | true;
  prefixIcon?: ReactNode;
  noWrap?: boolean;
  disabled?: boolean;
};

type ExternalLinkType = (props: ExternalLinkProps) => ReactNode;

const cssBase = css`
  @layer emotion-component {
    & {
      display: inline;
      color: ${theme.colors.blue006};
      vertical-align: baseline;

      :not(:hover, :active) {
        text-decoration-line: none;
      }

      &:focus {
        outline: ${theme.colors.blue006} auto 1px;
      }

      &:hover {
        color: ${theme.colors.blue005};
      }

      &:active {
        color: ${theme.colors.blue007};
      }
    }
  }
`;

const cssDisabled = css`
  @layer emotion-component {
    & {
      color: ${theme.colors.neutral006};
      pointer-events: none;
    }
  }
`;

const cssIconBlock = css`
  @layer emotion-component {
    & {
      margin-left: 0.5em;
    }
  }
`;

const cssPrefixIconBlock = css`
  @layer emotion-component {
    & {
      margin-right: 0.5em;
    }
  }
`;

const cssNoWrap = css`
  @layer emotion-component {
    & {
      ${cssOneLine}
    }
  }
`;

/**
 * This component is under TBD for overflow handling.
 */
const ExternalLink: ExternalLinkType = memo(
  forwardRef<HTMLAnchorElement, ExternalLinkProps>(function ExternalLink(
    { children, trailingIcon, prefixIcon, noWrap, disabled, ...props },
    ref,
  ) {
    const mergeFormDisabled = useMergeFormDisabled();
    const mergedDisabled = mergeFormDisabled(disabled);
    const mergedCss = useMemo(
      function mergedCss() {
        return css([
          cssBase,
          noWrap && cssNoWrap,
          mergedDisabled && cssDisabled,
        ]);
      },
      [mergedDisabled, noWrap],
    );
    const tabIndex =
      "tabIndex" in props ? props.tabIndex : mergedDisabled ? -1 : 0;
    return (
      <a
        css={mergedCss}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
        tabIndex={tabIndex}
        ref={ref}
      >
        {!prefixIcon ? null : (
          <span css={cssPrefixIconBlock}>
            {prefixIcon === undefined ? null : prefixIcon}
          </span>
        )}
        <span>{children}</span>
        {!trailingIcon ? null : (
          <span css={cssIconBlock}>
            {trailingIcon === true ? (
              <MotifIcon un-i-motif="new_window" />
            ) : (
              trailingIcon
            )}
          </span>
        )}
      </a>
    );
  }),
);

assignDisplayName(ExternalLink, "ExternalLink");

export { ExternalLink };
export type { ExternalLinkProps, ExternalLinkType };
