import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { Skeleton } from "antd";
import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { createWithEqualityFn } from "zustand/traditional";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";

type Assignee = {
  teamName: string;
  name: string;
};

type ContextValue = ReturnType<typeof setupAssignErrorUtils>;

const Context = createContext<ContextValue>({
  name: "AssignErrorUtilsContext",
});

function setupAssignErrorUtils() {
  const useStore = createWithEqualityFn<{
    error: null | {
      type: "assignedToSomeoneElse";
      assignee: Assignee;
    };
  }>()(
    () => ({
      error: null,
    }),
    shallow,
  );
  function clearError() {
    useStore.setState({ error: null });
  }
  function setAssignedToSomeoneElseError(assignee: Assignee) {
    useStore.setState({ error: { type: "assignedToSomeoneElse", assignee } });
  }
  return {
    useStore,
    clearError,
    setAssignedToSomeoneElseError,
  };
}

function Provider({ children }: { children: ReactNode }) {
  const assignErrorUtils = setupAssignErrorUtils();
  return (
    <Context.Provider value={assignErrorUtils}>
      {children}
      <CannotAssignDueToAssignedToSomeoneElseModal />
    </Context.Provider>
  );
}

const assignErrorUtilsContext = {
  Provider,
  useContext: Context.useContext,
};

const CannotAssignDueToAssignedToSomeoneElseModal: FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.user.useList({
    params: {
      orgId,
    },
  });
  const assignErrorUtils = assignErrorUtilsContext.useContext();
  const assignError = assignErrorUtils.useStore((state) => state.error);
  if (!assignError) return null;
  return (
    <Modal
      title={t("chat.manualAssignment.fail.modal.title")}
      open
      onOk={assignErrorUtils.clearError}
      onCancel={assignErrorUtils.clearError}
      footer={
        <Button type="primary" onClick={assignErrorUtils.clearError}>
          {t("common.confirm")}
        </Button>
      }
    >
      {query.isLoading ? (
        <Skeleton.Input />
      ) : query.isError ? (
        <Alert type="error" message={inspectMessage`${query.error}`} />
      ) : assignError.type === "assignedToSomeoneElse" ? (
        <p>
          {t("chat.manualAssignment.fail.modal.context", {
            team: assignError.assignee.teamName || EMPTY_STRING_PLACEHOLDER,
            agent: assignError.assignee.name || EMPTY_STRING_PLACEHOLDER,
          })}
        </p>
      ) : (
        (() => {
          assignError.type satisfies never;
          throw new Error(
            inspectMessage`Unexpected assignError type: ${assignError.type}`,
          );
        })()
      )}
    </Modal>
  );
};

export { assignErrorUtilsContext };
