import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 * This icon is called `Magic` originally.
 */
export const AiCompletionIcon = generateIcon(function AiCompletionIcon() {
  return (
    <svg height="1em" width="1em" viewBox="0 0 18 18" fill="currentColor">
      <path
        /**
         * This is required. Do not remove me or the icon will be broken.
         */
        fillRule="evenodd"
        d="M6.3711 4.41812L4.72402 5.30904L5.67426 3.65447L4.72402 1.99991L6.3711 2.95446L8.01818 1.99991L7.13129 3.65447L8.08153 5.30904C8.01818 5.30904 6.3711 4.41812 6.3711 4.41812ZM14.3532 10.909L16.0002 9.9544L15.05 11.609L16.0002 13.2635L14.3532 12.309L12.7061 13.2635L13.6563 11.609L12.7061 9.9544L14.3532 10.909ZM15.05 3.65447L16.0002 1.99991L14.3532 2.95446L12.7061 1.99991L13.6563 3.65447L12.7061 5.30904L14.3532 4.35448L16.0002 5.30904L15.05 3.65447ZM10.2353 9.19086L11.8824 7.53629L10.4887 6.13628L8.84162 7.79084L10.2353 9.19086ZM12.516 7.0908L10.9322 5.49988C10.6788 5.24533 10.2354 5.24533 9.98199 5.49988L2.19005 13.2636C1.93665 13.5181 1.93665 13.9636 2.19005 14.2182L3.77377 15.8091C4.02717 16.0636 4.47061 16.0636 4.72401 15.8091L12.516 8.04536C12.7693 7.79081 12.7693 7.34535 12.516 7.0908Z"
      />
    </svg>
  );
});
