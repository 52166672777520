import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import { type ElementRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "@/components/Checkbox";
import type { FormItem } from "@/components/Form";

declare namespace Api {
  export type RememberDeviceCheckboxRef = ElementRef<typeof Checkbox>;
  export interface RememberDeviceCheckboxProps
    extends ComponentProps<typeof Checkbox> {}
}

function useFormItemDefaultProps() {
  const defaultProps = useMemo(
    () =>
      define<ComponentProps<typeof FormItem>>()({
        valuePropName: "checked",
      }),
    [],
  );
  return defaultProps;
}

const RememberDeviceCheckbox = forwardRef<
  Api.RememberDeviceCheckboxRef,
  Api.RememberDeviceCheckboxProps
>(function RememberDeviceCheckbox({ ...props }, ref) {
  const { t } = useTranslation();
  return (
    <Checkbox {...props} ref={ref}>
      {"children" in props
        ? props.children
        : t("page.login.2fa.field.rememberDevice.label")}
    </Checkbox>
  );
});

const api = Object.assign(RememberDeviceCheckbox, {
  useFormItemDefaultProps,
});

export { api as RememberDeviceCheckbox };
