import type { i18n } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function loadFont(i18n: i18n) {
  async function loadFont() {
    import(`./locale/${i18n.language}.css`);
  }
  i18n.on("languageChanged", loadFont);
  return function destory() {
    i18n.off("languageChanged", loadFont);
  };
}

const MotifFontLoader = Object.assign(
  function MotifFontLoader() {
    const { i18n } = useTranslation();
    useEffect(() => loadFont(i18n), [i18n]);
    return null;
  },
  {
    loadFont,
  },
);

export { MotifFontLoader };
