import { useCallback, useMemo } from "react";

import type { CantataTypes } from "@/cantata/types";
import type { RolePermission } from "@/config/rolePermissionMap";
import { rolePermissionMap } from "@/config/rolePermissionMap";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { usePermissionOverrideUtils } from "@/router/components/Protected/InOrg/Debug/Permission";
import type { PermissionName } from "@/shared/domains/role";
import { isPermissionName } from "@/shared/domains/role";

/**
 * @deprecated Use `orgQueriesContext.useData().me` instead.
 *
 * @see {@link orgQueriesContext}
 */
const useUserInfo = () => {
  const orgQueriesData = orgQueriesContext.useData();
  const me = orgQueriesData.me;
  return me;
};

function hasPermission({
  user,
  permissionName,
}: {
  user: Pick<CantataTypes["UserMe"], "permission" | "roleType">;
  permissionName: PermissionName | RolePermission;
}): boolean {
  if (isPermissionName(permissionName)) return user.permission[permissionName];
  return rolePermissionMap[permissionName].includes(user.roleType);
}

function useUserPermission() {
  const orgQueriesData = orgQueriesContext.useData();
  const me = orgQueriesData.me;
  const permissionOverrideUtils = usePermissionOverrideUtils();
  const overriddenPermission = permissionOverrideUtils.useStore();
  const roles = orgQueriesData.roles;
  const overriddenRole = useMemo(
    () =>
      !overriddenPermission.roleId
        ? undefined
        : roles.find((r) => r.id === overriddenPermission.roleId),
    [overriddenPermission.roleId, roles],
  );
  const overriddenMe = useMemo<typeof me>(() => {
    return {
      ...me,
      ...(!overriddenRole
        ? null
        : {
            roleId: overriddenRole.id,
            roleType: overriddenRole.type,
            roleName: overriddenRole.name,
          }),
      ...(overriddenPermission.permissions.length === 0
        ? null
        : {
            permission: {
              ...me.permission,
              ...Object.fromEntries(
                overriddenPermission.permissions.map((p) => [
                  p.permission,
                  p.value,
                ]),
              ),
            },
          }),
    };
  }, [me, overriddenPermission.permissions, overriddenRole]);

  const checkUserHasPermission = useCallback(
    function checkUserHasPermission(
      permissionName: PermissionName | RolePermission,
    ): boolean {
      return hasPermission({
        user: overriddenMe,
        permissionName,
      });
    },
    [overriddenMe],
  );

  const ret = useMemo(
    () => ({
      roleId: overriddenMe.roleId,
      permission: overriddenMe.permission,
      hasPermission: checkUserHasPermission,
    }),
    [checkUserHasPermission, overriddenMe.permission, overriddenMe.roleId],
  );

  return ret;
}

export { hasPermission, useUserInfo, useUserPermission };
