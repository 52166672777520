import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { css } from "@emotion/react";
import { Typography } from "antd";
import { type FC, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import type { cantataClient } from "@/cantata";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Form } from "@/components/Form";
import { CopyInput } from "@/components/Input";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { MotifIcon } from "@/components/MotifIcon";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import {
  createExternalDirectChatUrl,
  createInternalDirectChatUrl,
} from "@/routes/Chat/ui/directChat";
import { cssFlexInheritAndFill } from "@/shared/emotion";

const cssContainer = css`
  ${cssFlexInheritAndFill}
  flex-direction: column;
  gap: 0.5rem;
`;

const cssLink = css`
  ${cssFlexInheritAndFill}
  flex-direction: row;
  align-items: center;
  gap: inherit;

  > * {
    margin-bottom: 0;
  }
`;

const cssFormItem = css`
  ${cssFlexInheritAndFill}
  flex: 1;

  & .ant-row {
    ${cssFlexInheritAndFill}
  }
`;

const Link: FC<{
  title: ComponentProps<typeof CopyInput>["title"];
  url: string;
}> = ({ title, url }) => {
  return (
    <div css={cssLink}>
      <Form.Item label={title} css={cssFormItem}>
        <CopyInput value={url} />
      </Form.Item>
      <NarrowIconButton
        iconSize="small"
        href={url}
        external
        icon={<MotifIcon un-i-motif="new_window" />}
      />
    </div>
  );
};

type LinkProps = {
  org: Awaited<
    ReturnType<typeof cantataClient.org.list>
  >["organizations"][number];
  channel: Awaited<ReturnType<typeof cantataClient.channel.getById>>;
  member: Awaited<ReturnType<typeof cantataClient.member.getById>>;
};

const InternalDirectChatLink: FC<LinkProps> = ({ org, member }) => {
  const url = useMemo(
    function getUrl() {
      return createInternalDirectChatUrl({
        full: true,
        orgId: org.id,
        channelId: member.channelId,
        memberId: member.id,
      });
    },
    [member.channelId, member.id, org.id],
  );
  return <Link title="Internal" url={url} />;
};

const ExternalDirectChatLink: FC<LinkProps> = ({ org, channel, member }) => {
  const url = useMemo(
    function getUrl() {
      return createExternalDirectChatUrl({
        full: true,
        orgUuid: org.uuid,
        externalChannelId: channel.externalChannelId,
        externalMemberId: member.externalMemberId,
      });
    },
    [channel.externalChannelId, member.externalMemberId, org.uuid],
  );
  return <Link title="External" url={url} />;
};

const DirectChatDebugger: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const member = memberQueriesContext.useMember();
  const channel = memberQueriesContext.useMemberChannel();
  const orgsQuery = cantata.org.useList();
  if (orgsQuery.isLoading) return <BarLoadingBlock />;
  if (orgsQuery.isError)
    return <Alert type="error" message={orgsQuery.error.message} />;
  const org = orgsQuery.data.organizations.find((org) => org.id === orgId);
  if (!org) {
    return (
      <Alert
        type="error"
        message={inspectMessage`org not found, orgId: ${orgId}`}
      />
    );
  }
  return (
    <div css={cssContainer}>
      <Typography.Title level={4}>Direct Chat Debugger</Typography.Title>
      <ExternalDirectChatLink org={org} channel={channel} member={member} />
      <InternalDirectChatLink org={org} channel={channel} member={member} />
    </div>
  );
};

export { DirectChatDebugger };
