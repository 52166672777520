import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import type { FC, ReactNode } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { memberIdUtils } from "@/resources/member/memberIdUtils";

function useMemberQueries({
  orgId,
  memberId,
}: {
  orgId: CantataTypes["Org"]["id"];
  memberId: CantataTypes["Member"]["id"];
}) {
  if (memberIdUtils.isNonActiveMemberId(memberId))
    throw new Error("Member ID is NaN");
  const memberQuery = cantata.member.useGetById(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      enabled: !memberIdUtils.isNonActiveMemberId(memberId),
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const channelId = memberQuery.data?.channelId ?? Number.NaN;
  if (memberQuery.isSuccess && Number.isNaN(channelId)) {
    throw new Error("Member does not have a channel");
  }
  const channelQuery = cantata.channel.useGetById(
    {
      params: {
        orgId,
        channelId,
      },
    },
    {
      enabled: !memberIdUtils.isNonActiveMemberId(channelId),
      suspense: true,
      useErrorBoundary: true,
    },
  );
  return {
    member: memberQuery,
    channel: channelQuery,
  };
}

const MemberQueriesContext =
  createQueriesContext<ReturnType<typeof useMemberQueries>>();

function MemberQueriesProvider({ children }: { children: ReactNode }) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const activeMemberId = memberIdUtils.useGet();
  const memberQueries = useMemberQueries({
    orgId,
    memberId: activeMemberId,
  });
  return (
    <MemberQueriesContext.Provider queries={memberQueries}>
      {children}
    </MemberQueriesContext.Provider>
  );
}

const MemberProviderWrapper: FC<
  ComponentProps<typeof MemberQueriesProvider>
> = (props) => {
  const activeMemberId = memberIdUtils.useGet();
  if (memberIdUtils.isNonActiveMemberId(activeMemberId)) return null;
  return <MemberQueriesProvider {...props} />;
};

/**
 * Retrieve active member data, ensuring it is never null.
 *
 * This should only be used within the `MemberProvider`.
 */
function useMember() {
  return MemberQueriesContext.useData().member;
}

function useMemberChannel() {
  return MemberQueriesContext.useData().channel;
}

const memberQueriesContext = {
  Provider: MemberProviderWrapper,
  useMember,
  useMemberChannel,
};

export { memberQueriesContext };
