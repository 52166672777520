import { makeApi } from "@zodios/core";
import { z } from "zod";

import { GroupMemberSchema } from "../models";

const api = makeApi([
  {
    /**
     * Get list members of a chat group.
     * Spec:
     * [Notion](https://www.notion.so/cresclab/202407-Group-Chat-e12e33d62eff486ba48d7a8f10cef134?pvs=4#9ea6e94350634154a633b6c3db9e1921)
     */
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/groups/:groupId/members",
    parameters: [
      {
        type: "Query",
        name: "limit",
        schema: z.number().int(),
      },
      {
        type: "Query",
        name: "cursor",
        schema: z.string().optional(),
      },
    ],
    response: z.object({
      members: GroupMemberSchema.array(),
      cursor: z.object({
        after: z.string().nullable(),
        before: z.string().nullable(),
      }),
    }),
  },
  {
    /**
     * Get list members of a chat group without pagination.
     * Spec:
     * [Notion](https://www.notion.so/cresclab/202407-Group-Chat-e12e33d62eff486ba48d7a8f10cef134?pvs=4#3c03b6a707c5439f97940ba8c05212da)
     */
    alias: "listAll",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/groups/:groupId/members-no-page",
    response: z.object({
      members: GroupMemberSchema.array(),
      count: z.number().int(),
    }),
  },
  // Disable this endpoint because we use count in listAll api.
  // {
  //   /**
  //    * Get the number of members in a chat group.
  //    * Spec:
  //    * [Notion](https://www.notion.so/cresclab/202407-Group-Chat-e12e33d62eff486ba48d7a8f10cef134?pvs=4#6a49b17dc2ad4a849f566d67b5697d7b)
  //    */
  //   alias: "count",
  //   method: "get",
  //   path: "api/v1/orgs/:orgId/chat/groups/:groupId/members/count",
  //   response: z.object({
  //     count: z.number().int(),
  //   }),
  // },
]);

export { api };
