import { css, keyframes } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { defineStyles } from "@/shared/emotion";

const rightKeyFrames = keyframes({
  "0%": { right: "100%" },
  "100%": { right: "0%" },
});
const leftKeyFrames = keyframes({
  "0%": { left: "0" },
  "100%": { left: "100%" },
});
const styles = defineStyles({
  bar: css({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: 4,
    zIndex: 2147483647,
  }),
  progress: css({
    position: "absolute",
    inset: 0,
    backgroundColor: theme.colors.primary,
    animation: `${rightKeyFrames} 2s ease-out infinite, ${leftKeyFrames} 2s ease-in infinite`,
  }),
});
const LoadingBar: FC = () => {
  return (
    <div css={styles.bar}>
      <div css={styles.progress} />
    </div>
  );
};

export { LoadingBar };
