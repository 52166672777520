import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";

function setupConversationHistoryMessagesController() {
  type State = {
    target: null | {
      memberId: CantataTypes["Member"]["id"];
      conversationId: CantataTypes["Conversation"]["conversationId"];
    };
  };
  const useStore = createWithEqualityFn<State>()(
    () => ({
      target: null,
    }),
    shallow,
  );
  function close() {
    useStore.setState({ target: null });
  }
  function open(target: NonNullable<State["target"]>) {
    useStore.setState({ target });
  }
  function useTarget() {
    return useStore((state) => state.target);
  }
  function useMemberQuery() {
    const target = useTarget();
    const orgId = useActiveOrgIdStore((state) => state.value);
    return cantata.member.useGetById(
      {
        params: {
          orgId,
          memberId: target?.memberId ?? Number.NaN,
        },
      },
      {
        enabled: Boolean(target),
      },
    );
  }
  return {
    close,
    open,
    useTarget,
    useMemberQuery,
  };
}

export { setupConversationHistoryMessagesController };
