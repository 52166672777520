import { useHandler } from "@chatbotgang/etude/react/useHandler";
import useSwitch from "@react-hook/switch";
import type { CompositionEventHandler } from "react";
import { useMemo } from "react";

/**
 * A hook to inject event props to detect IME composition.
 * @example
 * ```tsx
 * function MyComponent() {
 *   const { isComposition, props } = useInputComposition<HTMLTextAreaElement, TextAreaProps>({
 *     props: {
 *       onKeyDown: (e) => {
 *         if (isComposition) return;
 *         if (e.key === Key.Enter && !e.shiftKey) {
 *           e.preventDefault();
 *           form.submit();
 *         }
 *       },
 *     },
 *   });
 *   return <TextArea {...props} />;
 * }
 * ```
 */
export function useInputComposition<
  /**
   * The html element type
   */
  E extends Element,
  /**
   * The component props type
   */
  P extends {
    onCompositionStart?: CompositionEventHandler<E>;
    onCompositionUpdate?: CompositionEventHandler<E>;
    onCompositionEnd?: CompositionEventHandler<E>;
  } = {
    onCompositionStart?: CompositionEventHandler<E>;
    onCompositionUpdate?: CompositionEventHandler<E>;
    onCompositionEnd?: CompositionEventHandler<E>;
  },
>({
  props,
}: {
  props?: P;
}): {
  /**
   * `true` if is in IME composition
   */
  isComposition: boolean;
  props: P;
} {
  const [isComposition, setIsComposition] = useSwitch(false);

  const onCompositionStart: P["onCompositionStart"] = useHandler<
    P["onCompositionStart"]
  >((...args) => {
    type Ret = ReturnType<NonNullable<P["onCompositionStart"]>>;
    const ret: Ret = props?.onCompositionStart?.apply(undefined, args) as Ret;
    const e = args[0];
    if (e.isDefaultPrevented()) return ret;

    setIsComposition.on();
    return ret;
  });

  const onCompositionUpdate: P["onCompositionUpdate"] = useHandler<
    P["onCompositionUpdate"]
  >((...args) => {
    type Ret = ReturnType<NonNullable<P["onCompositionUpdate"]>>;
    const ret: Ret = props?.onCompositionUpdate?.apply(undefined, args) as Ret;
    const e = args[0];
    if (e.isDefaultPrevented()) return ret;

    setIsComposition.on();
    return ret;
  });

  const onCompositionEnd: P["onCompositionEnd"] = useHandler<
    P["onCompositionEnd"]
  >((...args) => {
    type Ret = ReturnType<NonNullable<P["onCompositionEnd"]>>;
    const ret: Ret = props?.onCompositionEnd?.apply(undefined, args) as Ret;
    const e = args[0];
    if (e.isDefaultPrevented()) return ret;

    setIsComposition.off();
    return ret;
  });
  const injectedProps = useMemo<P>(
    () =>
      ({
        ...props,
        onCompositionStart,
        onCompositionUpdate,
        onCompositionEnd,
      }) as P,
    [onCompositionEnd, onCompositionStart, onCompositionUpdate, props],
  );

  const ret = useMemo<{
    isComposition: boolean;
    props: P;
  }>(
    () => ({
      isComposition,
      props: injectedProps,
    }),
    [injectedProps, isComposition],
  );
  return ret;
}
