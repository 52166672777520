import { merge } from "lodash-es";
import { type FC, useMemo } from "react";

import { Image } from "@/components/Image";
import { Message } from "@/routes/Chat/ui/ChatPanel/History/Messages/Message";
import { messageUtil } from "@/routes/Chat/ui/ChatPanel/History/Messages/messageUtil";

type MessagesProps = ReturnType<typeof messageUtil.useMessages>;

const defaultMessagesProps: Partial<MessagesProps> = {
  showMoreButton: false,
};

const Messages: FC<MessagesProps> = (props) => {
  const mergedProps = useMemo<MessagesProps>(
    () => merge({}, defaultMessagesProps, props),
    [props],
  );
  const messages = props.messages;
  return (
    <Image.PreviewGroup>
      <messageUtil.MessagesProvider value={mergedProps}>
        {messages.map((message) => (
          <Message key={message.uuid} message={message} />
        ))}
      </messageUtil.MessagesProvider>
    </Image.PreviewGroup>
  );
};

export { Messages };
