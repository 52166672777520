import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { NavigateOptions, RelativeRoutingType } from "react-router-dom";
import {
  Navigate as UnsafeNavigate,
  useNavigate as useNavigate_unsafe,
} from "react-router-dom";

import type { BasePath, To } from "@/router/types";
import { compileTo } from "@/router/utils/compileTo";

/**
 * Alternative to `Navigate` component with type checking.
 */
function Navigate<TPath extends BasePath>(props: {
  to: To<TPath>;
  replace?: boolean;
  state?: any;
  relative?: RelativeRoutingType;
}) {
  const to = compileTo(props.to);
  return <UnsafeNavigate {...props} to={to} />;
}

/**
 * Similar to `UnsafeNavigateFunction` but the `to` is type checked.
 *
 * @see {@link UnsafeNavigateFunction}
 */
interface NavigateFunction {
  <TPath extends BasePath>(to: To<TPath>, options?: NavigateOptions): void;
  (delta: number): void;
}

/**
 * Similar to `useNavigate_unsafe` but the `to` is type checked.
 *
 * @see {@link useNavigate_unsafe}
 */
function useNavigate(): NavigateFunction {
  const unsafe_navigate = useNavigate_unsafe();
  const navigate = useHandler<NavigateFunction>(function navigate(...args) {
    const [arg0, ...restArgs] = args as Array<any>;
    const compiledArg0 = compileTo(arg0);
    return unsafe_navigate(compiledArg0, ...restArgs);
  });
  return navigate;
}

export { Navigate, useNavigate };
