import { basename, extname } from "pathe";

const VIDEO_LOAD_ERROR = "Video error on loading";
const CAN_NOT_GET_BLOB_ERROR = "Blob is null";
const CAN_NOT_GET_CANVAS_CONTEXT = "Canvas getContext returned null";

/**
 * See: <https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob>
 */
const fileTypes = ["image/png", "image/jpeg", "image/webp"] as const;
type FileType = (typeof fileTypes)[number];

const fileTypesToExtensions: Record<FileType, string> = {
  "image/png": "png",
  "image/jpeg": "jpg",
  "image/webp": "webp",
};

function getVideoThumbnail(
  videoFile: File,
  {
    second = 0,
    quality = 0.8,
    fileType = "image/jpeg",
  }: {
    second?: number;
    quality?: number;
    fileType?: FileType;
  } = {},
) {
  return new Promise<File>((resolve, reject) => {
    const objectURL = window.URL.createObjectURL(videoFile);
    function revokeObjectURL() {
      window.URL.revokeObjectURL(objectURL);
    }
    const videoElement = document.createElement("video");

    videoElement.setAttribute("crossorigin", "anonymous");

    videoElement.onloadedmetadata = () => {
      videoElement.currentTime = second;
    };

    videoElement.onseeked = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.height = videoElement.videoHeight;
      canvas.width = videoElement.videoWidth;

      if (ctx === null) {
        reject(new Error(CAN_NOT_GET_CANVAS_CONTEXT));
        return;
      }

      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(
        (blob) => {
          if (blob === null) {
            reject(new Error(CAN_NOT_GET_BLOB_ERROR));
            return;
          }
          const ext = extname(videoFile.name);
          const base = basename(videoFile.name, ext);
          const thumbExt = fileTypesToExtensions[fileType];
          const thumbFileName = thumbExt ? `${base}.${thumbExt}` : base;
          const file = new File([blob], thumbFileName, {
            type: fileType,
          });
          resolve(file);
          revokeObjectURL();
        },
        fileType,
        quality,
      );
    };

    videoElement.onerror = () => {
      reject(new Error(VIDEO_LOAD_ERROR));
      revokeObjectURL();
    };

    videoElement.src = objectURL;
  });
}

export { getVideoThumbnail };
