import { makeApi } from "@zodios/core";
import { z } from "zod";

const api = makeApi([
  {
    alias: "me",
    method: "get",
    path: "api/v1/auth/credentials/me",
    response: z.object({
      accountId: z.number().int(),
      superuser: z.boolean(),
      email: z.string().email(),
      users: z.array(
        z.object({
          orgId: z.number().int(),
          userId: z.number().int(),
          roleId: z.number().int(),
        }),
      ),
    }),
  },
]);

export { api };
