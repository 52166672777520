import { makeApi } from "@zodios/core";
import { z } from "zod";

import { CrescendoLabProductSchema } from "../models";

const api = makeApi([
  {
    alias: "info",
    method: "get",
    path: "api/v1/invitation/info",
    parameters: [
      {
        type: "Header",
        name: "Authorization",
        schema: z.string(),
      },
      {
        type: "Query",
        name: "token",
        schema: z.string(),
      },
    ],
    response: z.object({
      orgName: z.string(),
      inviterName: z.string(),
      roleName: z.string(),
      email: z.string(),
      /**
       * Asana: [Error handling for the incorrect password in the invitation flow](https://app.asana.com/0/0/1205864420002773/1205880635983348/f)
       */
      sources: z.array(CrescendoLabProductSchema),
    }),
  },
]);

export { api };
