import { makeApi } from "@zodios/core";
import { z } from "zod";

import { RoleSchema } from "../models";

const api = makeApi([
  {
    alias: "listRoles",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/roles",
    response: z.object({
      roles: z.array(RoleSchema),
    }),
  },
]);

export { api };
