import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import type { ElementRef } from "react";

import { Step } from "@/components/StaticVerticalSteps/Step";
import { defineStyles } from "@/shared/emotion";

const seed = random();
const counterName = `StaticVerticalSteps-${seed}`;

const styles = defineStyles({
  StaticVerticalSteps: css({
    listStyleType: "none",
    counterReset: counterName,
    "&>li": {
      "&::before": {
        content: `counter(${CSS.escape(counterName)})`,
        counterIncrement: counterName,
      },
    },
    display: "flex",
    flexDirection: "column",
    gap: 24,
    alignItems: "stretch",
    margin: 0,
  }),
});

namespace StaticVerticalSteps {
  export type Ref = ElementRef<"ol">;
  export interface OwnProps {
    // props here
  }
  export type Props = Overwrite<ComponentProps<"ol">, OwnProps>;
}

const StaticVerticalStepsInternal = forwardRef<
  StaticVerticalSteps.Ref,
  StaticVerticalSteps.Props
>(function StaticVerticalStepsInternal(props, ref) {
  return <ol css={styles.StaticVerticalSteps} ref={ref} {...props} />;
});

/**
 * StaticVerticalSteps is a list of steps that are displayed vertically.
 *
 * Design: [Figma](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=6922-26673&m=dev)
 */
const StaticVerticalSteps = Object.assign(StaticVerticalStepsInternal, {
  Step,
  styles,
});

assignDisplayName(StaticVerticalSteps, "StaticVerticalSteps");

export { StaticVerticalSteps };
