import {
  PermissionSchema,
  type PermissionsSchema,
} from "@zeffiroso/cantata/models";
import { z } from "zod";

type PermissionName = keyof typeof PermissionsSchema.shape;

function isPermissionName(input: unknown): input is PermissionName {
  return PermissionSchema.safeParse(input).success;
}

const DefaultRoleTypeSchema = z.enum([
  "owner",
  "primary_admin",
  "admin",
  "primary_agent",
  "agent",
]);

type DefaultRoleType = z.infer<typeof DefaultRoleTypeSchema>;

const isDefaultRoleType = (roleType: string): roleType is DefaultRoleType => {
  return DefaultRoleTypeSchema.safeParse(roleType).success;
};

export type { DefaultRoleType, PermissionName };

export { isDefaultRoleType, isPermissionName };
