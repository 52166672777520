import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const ArrowUpIcon = generateIcon(function ArrowUpIcon() {
  return (
    <svg viewBox="0 0 10 6" height="1em" width="1em" fill="currentColor">
      <path d="M4.37.267.261 4.449a.92.92 0 0 0 0 1.284.88.88 0 0 0 1.261 0l3.481-3.535 3.473 3.535a.88.88 0 0 0 1.261 0 .92.92 0 0 0 0-1.284L5.631.267a.88.88 0 0 0-1.262 0Z" />
    </svg>
  );
});
