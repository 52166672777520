import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { MotifIcon } from "@/components/MotifIcon";
import { useEasyForm } from "@/routes/Chat/ui/ChatPanel/Editor/Old/EasyForm";

declare namespace Submit {
  export type Props = ComponentProps<typeof Button>;
}

const Submit: FC<Submit.Props> = (props) => {
  const { t } = useTranslation();
  const [easyForm, form] = useEasyForm();
  const mergeDisabled = useMergeFormDisabled();
  const invalid = easyForm.hooks.useInvalid();
  const disabled = mergeDisabled(invalid);
  const handleClick = useHandler<ComponentProps<typeof Button>["onClick"]>(
    function handleClick(e) {
      e.preventDefault();
      form.submit();
      props.onClick?.(e);
    },
  );
  return (
    <Button
      htmlType="submit"
      type="primary"
      disabled={disabled}
      icon={<MotifIcon un-i-motif="paper_plane" />}
      iconPosition="end"
      {...props}
      onClick={handleClick}
    >
      {t("chat.send")}
    </Button>
  );
};

export { Submit };
