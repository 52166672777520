import { createContext, useContext } from "react";

import type { FlexPreviewProps } from "@/components/LINEFlexMessage/types";

const FlexPreviewContext = createContext<FlexPreviewProps | undefined>(
  undefined,
);

const PropsProvider = FlexPreviewContext.Provider;

const useProps = (): FlexPreviewProps => {
  const context = useContext(FlexPreviewContext);
  if (context === undefined)
    throw new Error("useProps must be used within a FlexPreviewProvider");

  return context;
};

export { PropsProvider, useProps };
