import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding: 10px;
`;

const MessagesLimitCount = ({
  count,
  limit,
}: {
  count: number;
  limit: number;
}): JSX.Element => {
  const { t } = useTranslation();
  return <Wrapper>{t("message.addModule", { count, limit })}</Wrapper>;
};

export { MessagesLimitCount };
