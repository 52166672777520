import { LoadingOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { API_DEFAULT_LIMIT } from "@zeffiroso/env";
import { secondsToMilliseconds } from "date-fns";
import type { ElementRef } from "react";
import { useInView } from "react-intersection-observer";
import { mergeRefs } from "react-merge-refs";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    fontVariantNumeric: "tabular-nums",
  }),
});

type CountRef = ElementRef<"span">;
type CountProps = Omit<ComponentProps<"span">, "children"> & {
  channelId?: CantataTypes["Channel"]["id"];
  assignmentFilter: Exclude<CantataTypes["MemberAssignmentFilter"], "assignee">;
};

const countRefetchInterval = secondsToMilliseconds(60);

const DefaultCount = forwardRef<CountRef, CountProps>(function DefaultCount(
  { channelId, assignmentFilter, ...props },
  ref,
) {
  const inView = useInView();
  const mergedRef = mergeRefs([ref, inView.ref]);
  const orgId = useActiveOrgIdStore((state) => state.value);

  const newPinnedQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        ...(!channelId ? null : { channelId }),
        assignmentFilter,
        processingState: "new",
        pinned: true,
      },
    },
    {
      enabled: inView.inView,
      refetchInterval: countRefetchInterval,
    },
  );

  const newUnPinnedQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        ...(!channelId ? null : { channelId }),
        assignmentFilter,
        processingState: "new",
        pinned: false,
      },
    },
    {
      enabled: inView.inView,
      refetchInterval: countRefetchInterval,
    },
  );

  const followUpPinnedQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        ...(!channelId ? null : { channelId }),
        assignmentFilter,
        processingState: "follow-up",
        pinned: true,
      },
    },
    {
      enabled: inView.inView,
      refetchInterval: countRefetchInterval,
    },
  );

  const followUpUnPinnedQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        ...(!channelId ? null : { channelId }),
        assignmentFilter,
        processingState: "follow-up",
        pinned: false,
      },
    },
    {
      enabled: inView.inView,
      refetchInterval: countRefetchInterval,
    },
  );

  return (
    <span ref={mergedRef} {...props}>
      {!newPinnedQuery.isSuccess ||
      !newUnPinnedQuery.isSuccess ||
      !followUpPinnedQuery.isSuccess ||
      !followUpUnPinnedQuery.isSuccess ? (
        <LoadingOutlined />
      ) : (
        newPinnedQuery.data.count +
        newUnPinnedQuery.data.count +
        followUpPinnedQuery.data.count +
        followUpUnPinnedQuery.data.count
      )}
    </span>
  );
});

const SearchCount = forwardRef<CountRef, CountProps>(function SearchCount(
  { channelId, assignmentFilter, ...props },
  ref,
) {
  const inView = useInView();
  const mergedRef = mergeRefs([ref, inView.ref]);
  const orgId = useActiveOrgIdStore((state) => state.value);
  const search = searchController.useStore((state) => state.search);
  const query = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        ...(!channelId ? null : { channelId }),
        assignmentFilter,
        ...(() => {
          const searchQuery = search.query.toString();
          if (!searchQuery) return {};
          return {
            searchAction: search.action,
            searchQuery,
          };
        })(),
      },
    },
    {
      enabled: inView.inView,
      refetchInterval: countRefetchInterval,
    },
  );

  return (
    <span ref={mergedRef} {...props}>
      {!query.isSuccess ? <LoadingOutlined /> : query.data.count}
    </span>
  );
});

const SearchByNotesCount = forwardRef<CountRef, CountProps>(
  function SearchByNotesCount({ channelId, assignmentFilter, ...props }, ref) {
    const inView = useInView();
    const mergedRef = mergeRefs([ref, inView.ref]);
    const orgId = useActiveOrgIdStore((state) => state.value);
    const search = searchController.useStore((state) => state.search);
    /**
     * No pagination for notes search.
     */
    const query = cantata.member.useList(
      {
        params: {
          orgId,
        },
        queries: {
          ...(!channelId ? null : { channelId }),
          assignmentFilter,
          ...(() => {
            const searchQuery = search.query.toString();
            if (!searchQuery) return {};
            return { searchAction: search.action, searchQuery };
          })(),
          /* This actually doesn't work, but we need to provide a limit */
          limit: API_DEFAULT_LIMIT,
        },
      },
      {
        enabled: inView.inView,
        refetchInterval: countRefetchInterval,
      },
    );

    return (
      <span ref={mergedRef} {...props}>
        {!query.isSuccess ? <LoadingOutlined /> : query.data.members.length}
      </span>
    );
  },
);

const Count = forwardRef<CountRef, Partial<CountProps>>(function Count(
  { channelId, assignmentFilter = "all", ...props },
  ref,
) {
  const isSearching = searchController.useStore(
    searchController.selectors.isSearching,
  );
  const isSearchingNotes = searchController.useStore(
    (state) =>
      searchController.selectors.isSearching(state) &&
      state.search.action === "notes",
  );
  const Count = isSearchingNotes
    ? SearchByNotesCount
    : isSearching
      ? SearchCount
      : DefaultCount;
  return (
    <Count
      {...props}
      css={styles.root}
      {...(!channelId ? null : { channelId })}
      assignmentFilter={assignmentFilter}
      ref={ref}
    />
  );
});

export { Count };
