import { makeApi } from "@zodios/core";
import { z } from "zod";

const api = makeApi([
  /**
   * Spec: [Notion -
   * 2FA](https://www.notion.so/cresclab/2FA-21e3c4454185423ebb5a07c526615e7c?pvs=4#a26a7d1e54cd49338b93749a603ad0ae)
   */
  {
    alias: "signIn",
    method: "post",
    path: "api/v1/auth/accounts/sign-in",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          email: z.string(),
          password: z.string(),
          rememberToken: z.string().nullable(),
        }),
      },
    ],
    response: z.object({
      // Will get null if 2FA is required.
      accountId: z.number().int().nullable(),
      // Will get empty string if 2FA is required.
      token: z.string(),

      // 2FA
      requiresTwoFactor: z.boolean(),
      twoFactorMaxAttempts: z.number().int(),
    }),
  },
  /**
   * Spec: [Notion -
   * 2FA](https://www.notion.so/cresclab/2FA-21e3c4454185423ebb5a07c526615e7c?pvs=4#d7db61bc8dfb498185a94fb75d6a534f)
   */
  {
    alias: "verifyTwoFa",
    method: "post",
    path: "/api/v1/auth/2fa",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          email: z.string(),
          otpCode: z.string(),
          rememberDevice: z.boolean(),
        }),
      },
    ],
    response: z.object({
      accountId: z.number().int(),
      token: z.string(),
      rememberToken: z.string().nullable(),
    }),
  },
  {
    alias: "authProvider",
    method: "get",
    path: "api/v1/auth/provider",
    parameters: [
      {
        type: "Query",
        name: "domain",
        schema: z.string(),
      },
    ],
    response: z.object({
      allowPassword: z.boolean(),
      domain: z.string(),
      firebaseAuthProviders: z.string().array(),
    }),
  },
  /**
   * [Spec](https://www.notion.so/cresclab/202402-SSO-API-Doc-b02e44c0aa194eb7bde1448ce2c3cb60?pvs=4#e1c6095635664cc18ac30d4d97a530a5)
   */
  {
    alias: "firebaseSignIn",
    method: "post",
    path: "api/v1/auth/sso/sign-in",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          /**
           * This is presently necessary for BE due to some alignment issues.
           *
           * [Slack:
           * #proj-sso](https://chatbotgang.slack.com/archives/C06F4CL5CCS/p1708673916918249?thread_ts=1708570803.711169&cid=C06F4CL5CCS)
           */
          uid: z.string(),
          idToken: z.string(),
        }),
      },
    ],
    response: z.object({
      accountId: z.number().int(),
      token: z.string(),
    }),
  },
  {
    alias: "activateNew",
    method: "post",
    path: "api/v1/invitation/activate/new",
    parameters: [
      {
        type: "Header",
        name: "Authorization",
        schema: z.string(),
      },
      {
        type: "Body",
        name: "body",
        schema: z.object({
          email: z.string(),
          password: z.string(),
          passwordConfirmation: z.string(),
        }),
      },
    ],
    response: z.object({
      accountId: z.number().int(),
      token: z.string(),
    }),
  },
  {
    alias: "activateExist",
    method: "post",
    path: "api/v1/invitation/activate/exist",
    parameters: [
      {
        type: "Header",
        name: "Authorization",
        schema: z.string(),
      },
      {
        type: "Body",
        name: "body",
        schema: z.object({
          email: z.string(),
          password: z.string(),
        }),
      },
    ],
    response: z.object({
      accountId: z.number().int(),
      token: z.string(),
    }),
  },
  /**
   * Notion: [202402 SSO API Doc](https://www.notion.so/cresclab/202402-SSO-API-Doc-b02e44c0aa194eb7bde1448ce2c3cb60?pvs=4#989f2013d5e147ec84a8c88396d7566f)
   */
  {
    alias: "activateWithFirebaseAuth",
    method: "post",
    path: "api/v1/invitation/sso/activate",
    parameters: [
      {
        type: "Header",
        name: "Authorization",
        schema: z.string(),
      },
      {
        type: "Body",
        name: "body",
        schema: z.object({
          /**
           * This is presently necessary for BE due to some alignment issues.
           *
           * [Slack:
           * #proj-sso](https://chatbotgang.slack.com/archives/C06F4CL5CCS/p1708673916918249?thread_ts=1708570803.711169&cid=C06F4CL5CCS)
           */
          uid: z.string(),
          idToken: z.string(),
        }),
      },
    ],
    response: z.object({
      accountId: z.number().int(),
      token: z.string(),
    }),
  },
]);

export { api };
