import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { Skeleton } from "antd";
import type { ComponentProps } from "react";
import { Suspense, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Avatar } from "@/components/Avatar";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { GroupMemberAvatar } from "@/resources/groupMember/GroupMemberAvatar";

declare namespace GroupMemberAvatarById {
  type Props = Omit<ComponentProps<typeof Avatar>, "children" | "src"> & {
    groupId: number;
    memberId: number;
  };
}

const GroupMemberAvatarById = forwardRef<
  GroupMemberAvatar.Ref,
  GroupMemberAvatarById.Props
>(function GroupMemberAvatarById({ groupId, memberId, ...props }, ref) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const groupMembersQuery = cantata.chatGroup.useListAll(
    {
      params: {
        orgId,
        groupId,
      },
    },
    {
      select: (data) => data.members,
      suspense: true,
      useErrorBoundary: true,
    },
  );

  const member = useMemo(
    function findMemberFromList() {
      return groupMembersQuery.data?.find((member) => member.id === memberId);
    },
    [groupMembersQuery.data, memberId],
  );

  const memberQuery = cantata.member.useGetById(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      enabled: groupMembersQuery.isSuccess && member === undefined,
      suspense: true,
      useErrorBoundary: true,
    },
  );

  if (!groupMembersQuery.isSuccess) return <Avatar {...props} />;

  if (member) return <GroupMemberAvatar member={member} ref={ref} {...props} />;

  if (!memberQuery.isSuccess) return <Avatar {...props} />;

  return <GroupMemberAvatar member={memberQuery.data} ref={ref} {...props} />;
});

const Wrapper = forwardRef<GroupMemberAvatar.Ref, GroupMemberAvatarById.Props>(
  (props, ref) => {
    return (
      <ErrorBoundary.Alert>
        <Suspense fallback={<Skeleton.Avatar size="small" shape="circle" />}>
          <GroupMemberAvatarById {...props} ref={ref} />
        </Suspense>
      </ErrorBoundary.Alert>
    );
  },
);

assignDisplayName(Wrapper, "GroupMemberAvatarById");

export { Wrapper as GroupMemberAvatarById };
