/**
 * Check if the container is scrolled to the bottom.
 */
function isScrolledToBottom(container: HTMLElement): boolean {
  return (
    container.scrollHeight -
      /**
       * This might not a 整數 when scrolled to the bottom, so we need to ceil it
       * to make sure it's considered as scrolled to the bottom.
       */
      Math.ceil(container.scrollTop) <=
    container.clientHeight
  );
}

export { isScrolledToBottom };
