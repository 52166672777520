import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";

import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  header: css({
    fontSize: "0.875rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
    "& .anticon": {
      fontSize: "1rem",
      color: theme.colors.neutral007,
    },
  }),
  body: css({
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "2.25rem",
  }),
  footer: css({
    fontSize: "0.75rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 10,
  }),
  note: css({
    color: theme.colors.neutral007,
  }),
});

type HeaderRef = ElementRef<"div">;
type HeaderProps = ComponentProps<"div">;

const Header = forwardRef<HeaderRef, HeaderProps>(function Header(props, ref) {
  return <div css={styles.header} ref={ref} {...props} />;
});

assignDisplayName(Header, "DashboardCardLayout.Header");

type BodyRef = ElementRef<"div">;
type BodyProps = ComponentProps<"div">;

const Body = forwardRef<BodyRef, BodyProps>(function Body(props, ref) {
  return <div css={styles.body} ref={ref} {...props} />;
});

assignDisplayName(Body, "DashboardCardLayout.Body");

type FooterRef = ElementRef<"div">;
type FooterProps = ComponentProps<"div">;

const Footer = forwardRef<FooterRef, FooterProps>(function Footer(props, ref) {
  return <div css={styles.footer} ref={ref} {...props} />;
});

assignDisplayName(Footer, "DashboardCardLayout.Footer");

type NoteRef = ElementRef<"div">;
type NoteProps = ComponentProps<"div">;

const Note = forwardRef<NoteRef, NoteProps>(function Note(props, ref) {
  return <div css={styles.note} ref={ref} {...props} />;
});

assignDisplayName(Note, "DashboardCardLayout.Note");

const DashboardCardLayout = {
  Body,
  Footer,
  Header,
  Note,
};

export { Body, DashboardCardLayout, Footer, Header, Note };
export type {
  BodyProps,
  BodyRef,
  FooterProps,
  FooterRef,
  HeaderProps,
  HeaderRef,
  NoteProps,
  NoteRef,
};
