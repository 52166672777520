import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import { define } from "@chatbotgang/etude/util/define";
import type { Ymd } from "@zeffiroso/utils/date/Ymd";
import { fillYmdRange } from "@zeffiroso/utils/date/Ymd/fillYmdRange";
import { useNow } from "@zeffiroso/utils/react/useNow";
import {
  addDays,
  isSameDay,
  isSameWeek,
  isSameYear,
  isToday,
  isYesterday,
  startOfDay,
} from "date-fns";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  DATE_FORMAT_OPTIONS,
  DATE_TIME_FORMAT_OPTIONS,
  EMPTY_STRING_PLACEHOLDER,
} from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";

const MONTH_DAY_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
};
const TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
};
const WEEK_DAY_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: "short",
};

const useFormatLastEngagement = (): ((dateTime: Date) => string) => {
  const getIntlDateTimeFormatter = useGetIntlDateTimeFormatter();
  const now = useNow();
  return useCallback(
    function formatLastEngagement(lastEngage: Date): string {
      if (!isSameYear(now, lastEngage))
        return getIntlDateTimeFormatter(DATE_FORMAT_OPTIONS).format(lastEngage);
      if (!isSameWeek(now, lastEngage)) {
        return getIntlDateTimeFormatter(MONTH_DAY_FORMAT_OPTIONS).format(
          lastEngage,
        );
      }
      if (!isSameDay(now, lastEngage)) {
        return getIntlDateTimeFormatter(WEEK_DAY_FORMAT_OPTIONS).format(
          lastEngage,
        );
      }
      return getIntlDateTimeFormatter(TIME_FORMAT_OPTIONS).format(lastEngage);
    },
    [getIntlDateTimeFormatter, now],
  );
};

const useTimeLabelFormat = (): ((datetime: Date) => string) => {
  const { t } = useTranslation();
  const getIntlDateTimeFormatter = useGetIntlDateTimeFormatter();
  return useCallback(
    function formatTimeLabel(datetime) {
      if (isInvalidDate(datetime)) return EMPTY_STRING_PLACEHOLDER;

      const today = startOfDay(Date.now());

      if (isToday(datetime)) return t("glossary.today");

      if (isYesterday(datetime)) return t("glossary.yesterday");

      if (isSameYear(today, datetime)) {
        return getIntlDateTimeFormatter(MONTH_DAY_FORMAT_OPTIONS).format(
          datetime,
        );
      }
      return getIntlDateTimeFormatter(DATE_FORMAT_OPTIONS).format(datetime);
    },
    [getIntlDateTimeFormatter, t],
  );
};

type FormatDateFn = (
  date: NonNullable<Parameters<typeof Intl.DateTimeFormat.prototype.format>[0]>,
  formatOptions?: Intl.DateTimeFormatOptions,
) => ReturnType<typeof Intl.DateTimeFormat.prototype.format>;

/**
 * This function returns a function with the same API as `format` from
 * `date-fns`, but it returns an empty string as a placeholder if the date is
 * invalid. It also uses the locale from `useLocale` by default.
 */
function useFormatDate(): FormatDateFn {
  const getIntlDateTimeFormatter = useGetIntlDateTimeFormatter();
  const formatDate = useCallback<FormatDateFn>(
    function formatDate(date, formatOptions = DATE_FORMAT_OPTIONS): string {
      if (typeof date === "number" ? Number.isNaN(date) : isInvalidDate(date))
        return EMPTY_STRING_PLACEHOLDER;

      return getIntlDateTimeFormatter(formatOptions).format(date);
    },
    [getIntlDateTimeFormatter],
  );
  return formatDate;
}

/**
 * Similar to `useFormatDate`, but it uses `DATE_TIME_FORMAT_OPTIONS` as the
 * default format template.
 */
function useFormatDateTime(): FormatDateFn {
  const formatDate = useFormatDate();
  const formatDateTime = useCallback<FormatDateFn>(
    function formatDateTime(...args) {
      const date = args[0];
      const formatOptions = args[1] ?? DATE_TIME_FORMAT_OPTIONS;
      return formatDate(date, formatOptions);
    },
    [formatDate],
  );
  return formatDateTime;
}

/**
 * Get a formatter for date and time.
 *
 * ```ts
 * const getIntlDateTimeFormatter = useGetIntlDateTimeFormatter();
 * const formatter = getIntlDateTimeFormatter({
 *   year: "numeric",
 *   month: "short",
 *   day: "numeric",
 * });
 * const formattedDate = formatter.format(new Date());
 * ```
 */
function useGetIntlDateTimeFormatter() {
  const { i18n } = useTranslation();

  const getIntlDateTimeFormatter = useCallback(
    function getIntlDateTimeFormatter(options: Intl.DateTimeFormatOptions) {
      const formatter = new Intl.DateTimeFormat(i18n.language, options);
      return formatter;
    },
    [i18n.language],
  );
  return getIntlDateTimeFormatter;
}

function getFileExpiresDate(from: Date, expireDays: number): Date {
  const date = addDays(from, expireDays);
  return date;
}

/**
 * Org scoped version of `fillYmdRange`.
 */
function useFillYmdRangeUtils() {
  type FillYmdRangeFn = <T extends object, TDateKey extends PropertyKey>(
    options: fillYmdRange.CommonOptions<T, TDateKey>,
  ) => Array<T & Record<TDateKey, Ymd>>;
  const orgQueriesData = orgQueriesContext.useData();
  const byDay = useMemo<FillYmdRangeFn>(() => fillYmdRange.byDay, []);
  const byWeek = useMemo<FillYmdRangeFn>(
    () => (options) =>
      fillYmdRange.byWeek({
        ...options,
        eachWeekOfIntervalOptions: {
          weekStartsOn: orgQueriesData.org.startWeekday,
        },
      }),
    [orgQueriesData.org.startWeekday],
  );
  const byMonth = useMemo<FillYmdRangeFn>(() => fillYmdRange.byMonth, []);
  const api = useMemo(
    () =>
      define<Record<CantataTypes["DashboardInterval"], FillYmdRangeFn>>()({
        daily: byDay,
        weekly: byWeek,
        monthly: byMonth,
      }),
    [byDay, byWeek, byMonth],
  );
  return api;
}

export {
  getFileExpiresDate,
  useFillYmdRangeUtils,
  useFormatDate,
  useFormatDateTime,
  useFormatLastEngagement,
  useGetIntlDateTimeFormatter,
  useTimeLabelFormat,
};
