import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import useSwitch from "@react-hook/switch";
import { merge } from "lodash-es";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type ReactNode,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { mergeRefs } from "react-merge-refs";

import { cssLineClamp, defineStyles } from "@/shared/emotion";

function useExpandableLineClampBoxUtilities() {
  const ref = useRef<HTMLElement>(null);
  const [expanded, toggleExpanded] = useSwitch(false);
  useEffect(() => {
    if (expanded || !ref.current) return;
    const rootElement = ref.current;
    function isOverflowing() {
      return rootElement.scrollHeight > rootElement.clientHeight;
    }
    const resizeObserver = new ResizeObserver(function () {
      if (!isOverflowing()) {
        toggleExpanded.on();
      }
    });
    resizeObserver.observe(rootElement);
    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [expanded, toggleExpanded]);
  const expandableLineClampUtilities = useMemo(
    () => ({ expanded, expand: toggleExpanded.on, ref }),
    [expanded, toggleExpanded.on],
  );
  return expandableLineClampUtilities;
}

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

interface ExpandableLineClampBoxOwnProps
  extends Pick<ComponentProps<DefaultComponent>, "className" | "style"> {
  /**
   * The maximum number of lines to display before truncating the text.
   */
  lineClamp: number;
  /**
   * The returned object from the `useExpandableLineClampBoxUtilities` hook.
   */
  expandableLineClampBoxUtilities: ReturnType<
    typeof useExpandableLineClampBoxUtilities
  >;
  /**
   * The component used for the root node.
   */
  component?: ElementType;
  /**
   * The content of the component.
   */
  children?: ReactNode;
}

interface ExpandableLineClampBoxTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & ExpandableLineClampBoxOwnProps;
  defaultComponent: RootComponent;
}

type ExpandableLineClampBoxProps<
  RootComponent extends
    ElementType = ExpandableLineClampBoxTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = ExpandableLineClampBoxOwnProps &
  OverrideProps<
    ExpandableLineClampBoxTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const randomSeed = random();
const lineClampCssVariable = `--line-clamp-${randomSeed}` as const;

const styles = defineStyles({
  root: css(cssLineClamp(`var(${lineClampCssVariable})`)),
});

/**
 * The `<ExpandableLineClampBox />` component enables the display of text with a
 * specified maximum number of lines. Should the text exceed this limit, a "show
 * more" button can be implemented to expand the text.
 *
 * Once expanded, whether through user interaction by clicking the "show more"
 * button, or if the container is sufficiently large, or if the content is small
 * enough to fit within the container, the status will transition to exp
 */
const ExpandableLineClampBox: OverridableComponent<ExpandableLineClampBoxTypeMap> =
  forwardRef(function ExpandableLineClampBox(
    {
      component: Component = defaultComponent,
      lineClamp,
      expandableLineClampBoxUtilities,
      ...props
    }: ExpandableLineClampBoxProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const mergedRef = mergeRefs([expandableLineClampBoxUtilities.ref, ref]);
    const mergedStyle = useMemo<ExpandableLineClampBoxProps["style"]>(() => {
      return merge(
        {
          [lineClampCssVariable]: expandableLineClampBoxUtilities.expanded
            ? Number.MAX_SAFE_INTEGER
            : lineClamp,
        },
        props.style,
      );
    }, [lineClamp, expandableLineClampBoxUtilities.expanded, props.style]);
    return (
      <Component
        css={styles.root}
        {...props}
        ref={mergedRef}
        style={mergedStyle}
      />
    );
  }) as OverridableComponent<ExpandableLineClampBoxTypeMap>;

const Api = Object.assign(ExpandableLineClampBox, {
  useExpandableLineClampBoxUtilities,
});

export { defaultComponent, Api as ExpandableLineClampBox };

export type {
  ExpandableLineClampBoxOwnProps,
  ExpandableLineClampBoxProps,
  ExpandableLineClampBoxTypeMap,
};
