import { makeApi } from "@zodios/core";
import { pick } from "lodash-es";
import { z } from "zod";

import { ChannelSchema, OrgSchema, TeamSchema, UserSchema } from "../models";

const ChannelWithIdSchema = z.object(pick(ChannelSchema.shape, "id"));
const QrCodeSchema = z.string(); // Should be an URL.

const api = makeApi([
  /**
   * List all assignment QR codes for a user (no team-specific).
   */
  {
    alias: "listUserAssignmentQrcodes",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/users/:userId/assignment-qrcodes",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
      {
        type: "Path",
        name: "userId",
        schema: UserSchema.shape.id,
      },
    ],
    response: z.object({
      assignmentQrcodes: z.array(
        z.object({
          channel: ChannelWithIdSchema,
          qrcodeUrl: QrCodeSchema,
        }),
      ),
    }),
  },
  /**
   * List all assignment QR codes for a team (no user-specific).
   *
   * Spec: [Notion](https://www.notion.so/cresclab/91-Sales-binding-a1ce40b5c8bb4786b8204f2d3a723ea3?pvs=4#942738ee769940bba650b4c594149163)
   */
  {
    alias: "listTeamAssignmentQrcodes",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/teams/:teamId/assignment-qrcodes",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
      {
        type: "Path",
        name: "teamId",
        schema: TeamSchema.shape.id,
      },
    ],
    response: z.object({
      assignmentQrcodes: z.array(
        z.object({
          channel: ChannelWithIdSchema,
          qrcodeUrl: QrCodeSchema,
        }),
      ),
    }),
  },
  /**
   * List all assignment QR codes for all users in a team.
   *
   * Spec: [Notion](https://www.notion.so/cresclab/91-Sales-binding-a1ce40b5c8bb4786b8204f2d3a723ea3?pvs=4#24798da193264309abae803687c6dfcd)
   */
  {
    alias: "listAllTeamUsersAssignmentQrcodes",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/teams/:teamId/users/assignment-qrcodes",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
      {
        type: "Path",
        name: "teamId",
        schema: TeamSchema.shape.id,
      },
    ],
    response: z.object({
      assignmentQrcodes: z.array(
        z.object({
          channel: ChannelWithIdSchema,
          userId: UserSchema.shape.id,
          qrcodeUrl: QrCodeSchema,
        }),
      ),
    }),
  },
  /**
   * List all assignment QR codes for a user in all teams.
   *
   * Spec: [Notion](https://www.notion.so/cresclab/91-Sales-binding-a1ce40b5c8bb4786b8204f2d3a723ea3?pvs=4#c82fc19943f345e5b685ceec9e9b4fbe)
   */
  {
    alias: "listAllUserTeamsAssignmentQrcodes",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/users/:userId/teams/assignment-qrcodes",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
      {
        type: "Path",
        name: "userId",
        schema: UserSchema.shape.id,
      },
    ],
    response: z.object({
      assignmentQrcodes: z.array(
        z.object({
          channel: ChannelWithIdSchema,
          teamId: TeamSchema.shape.id,
          qrcodeUrl: QrCodeSchema,
        }),
      ),
    }),
  },
]);

export { api };
