// eslint-disable-next-line no-restricted-imports -- We put the import here so they can be removed together.
import "antd/es/date-picker/style/index";

import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { Ymd } from "@zeffiroso/utils/date/Ymd";
// eslint-disable-next-line no-restricted-imports -- This is not exposed from `antd`.
import generatePicker from "antd/es/date-picker/generatePicker";
import { merge, omit } from "lodash-es";
import type { ElementRef } from "react";
import { useMemo } from "react";
import type { O } from "ts-toolbelt";

import { generateDateFnsConfig } from "@/components/DatePicker/generateDateFnsConfig";
import type { DateRange } from "@/components/DatePicker/utils";
import { useFormatDate } from "@/resources/datetime";

const DateFnsDatePicker = generatePicker<Date>(generateDateFnsConfig);

type DatePickerProps = ComponentProps<typeof DateFnsDatePicker>;

function useDatePickerProps<T extends Pick<DatePickerProps, "format">>(
  props: T,
) {
  const formatDate = useFormatDate();

  const defaultDatePickerProps = useMemo<O.Partial<T, "deep">>(
    () => ({
      format: formatDate,
    }),
    [formatDate],
  );

  const mergedProps = useMemo<T>(
    () => merge({}, defaultDatePickerProps, props),
    [defaultDatePickerProps, props],
  );
  return mergedProps;
}

const DatePicker = forwardRef<
  ElementRef<typeof DateFnsDatePicker>,
  DatePickerProps
>(function DatePicker(props, ref) {
  const mergedProps = useDatePickerProps(props);
  return <DateFnsDatePicker ref={ref} {...mergedProps} />;
});

type AntdRangePickerProps = ComponentProps<
  typeof DateFnsDatePicker.RangePicker
>;

type RangePickerProps = Omit<AntdRangePickerProps, "onChange"> & {
  /**
   * Antd type is wrong. 💩
   *
   * It's `Range | null` but it types as `[Date | null, Date | null]`.
   */
  onChange?: (value: DateRange | null) => void;
};

const RangePicker = forwardRef<
  ElementRef<typeof DateFnsDatePicker.RangePicker>,
  RangePickerProps
>(function DatePicker(props, ref) {
  const mergedProps = useDatePickerProps(props);
  const restProps = useMemo(() => omit(props, "onChange", "format"), [props]);
  return (
    <DateFnsDatePicker.RangePicker
      ref={ref}
      format={mergedProps.format}
      {...restProps}
      {...(!props.onChange
        ? null
        : {
            onChange: mergedProps.onChange as AntdRangePickerProps["onChange"],
          })}
    />
  );
});

type YmdRangePickerRef = ElementRef<typeof RangePicker>;
type YmdRangePickerProps = Omit<
  RangePickerProps,
  "showTime" | "onChange" | "value"
> & {
  onChange?: (value: [Ymd, Ymd] | null) => void;
  value?: [Ymd, Ymd] | null;
};

/**
 * Similar to `RangePicker` but with `Ymd` objects.
 *
 * This is more recommended to use than `RangePicker` because it's more strict
 * for dates without time.
 */
const YmdRangePicker = forwardRef<YmdRangePickerRef, YmdRangePickerProps>(
  function YmdRangePicker(props, ref) {
    const value = useMemo<ComponentProps<typeof RangePicker>["value"]>(() => {
      if (!props.value) return props.value;
      return [props.value[0].localStartOfDay, props.value[1].localStartOfDay];
    }, [props.value]);
    const onChange = useHandler<ComponentProps<typeof RangePicker>["onChange"]>(
      (value) => {
        if (!value) return props.onChange?.(null);
        return props.onChange?.(Ymd.dateToYmdDeep(value));
      },
    );
    const restProps = useMemo(
      () => omit(props, ["onChange", "value"]),
      [props],
    );
    return (
      <RangePicker
        ref={ref}
        {...restProps}
        /**
         * This is only for dates, so we don't need time.
         */
        showTime={false}
        {...(!("value" in props) ? null : { value })}
        {...(!("onChange" in props) ? null : { onChange })}
      />
    );
  },
);

const api = Object.assign(DatePicker, { RangePicker, YmdRangePicker });

export { api as DatePicker };
