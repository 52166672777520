import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import useSwitch from "@react-hook/switch";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Button } from "@/components/Button";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { Modal } from "@/components/Modal";
import { useMessage } from "@/internal/message";
import { fakeT } from "@/shared/g11n/fakeT";

const translationI18nKeyMap = (() => {
  const t = fakeT;
  return define<
    Record<
      Extract<CantataTypes["ChannelType"], "fb" | "ig">,
      Record<string, string>
    >
  >()({
    fb: {
      title: t("resource.channel.update.connection.disconnect.modal.fb.title"),
      successMessage: t(
        "resource.channel.update.disconnect.fb.success.message",
      ),
    },
    ig: {
      title: t("resource.channel.update.connection.disconnect.modal.ig.title"),
      successMessage: t(
        "resource.channel.update.disconnect.ig.success.message",
      ),
    },
  });
})();

const DisconnectFacebookChannel = fc<{
  channelId: CantataTypes["Channel"]["id"];
  channelType: Extract<CantataTypes["Channel"]["type"], "fb" | "ig">;
}>(function DisconnectFacebookChannel({ channelId, channelType }) {
  const [disconnectOpen, toggleDisconnectOpen] = useSwitch(false);
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const message = useMessage();
  const mutation = cantata.channel.useUpdateStatus(
    {
      params: {
        orgId,
        channelId,
      },
    },
    {
      onSuccess() {
        message.success(t(translationI18nKeyMap[channelType].successMessage));
        toggleDisconnectOpen.off();
      },
      onError(error) {
        if (!(error instanceof AxiosError)) return;
        const statusCode = error.response?.status;
        if (!statusCode) return;
        // keep modal open if server error
        if (statusCode >= 500) return;
        // close modal if client error
        toggleDisconnectOpen.off();
      },
    },
  );

  const handleConfirm = useHandler(function handleConfirm() {
    if (mutation.isLoading) return;

    mutation.mutate({ status: "disconnected" });
  });
  return (
    <DisabledContextProvider disabled={mutation.isLoading}>
      <Button onClick={toggleDisconnectOpen.on}>
        <Trans i18nKey="resource.channel.update.connection.disconnect.button.label" />
      </Button>
      <Modal
        title={t(translationI18nKeyMap[channelType].title)}
        open={disconnectOpen}
        okButtonProps={{
          danger: true,
          loading: mutation.isLoading,
        }}
        onCancel={toggleDisconnectOpen.off}
        onOk={handleConfirm}
        cancelText={t("common.cancel")}
        okText={t(
          "resource.channel.update.connection.disconnect.modal.confirm",
        )}
      >
        <Trans i18nKey="resource.channel.update.connection.disconnect.modal.description" />
      </Modal>
    </DisabledContextProvider>
  );
});

export { DisconnectFacebookChannel };
