import { makeApi } from "@zodios/core";
import { z } from "zod";

import { UnificationKeysSchema, UnifiedMemberSchema } from "../models";

const api = makeApi([
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#22f6a5e6097244b9bbe65b2019da010d)
     */
    alias: "getByMemberId",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/unification",
    response: z.array(UnifiedMemberSchema),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#4d1cff39a5da4566b6a0a180b7eccb6d)
     */
    alias: "changeUnification",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/change-unification",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          unifyKey: UnificationKeysSchema,
          value: z.string(),
        }),
      },
    ],
    response: z.void(),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#8162f802179b4ae182b6ec23fe83cab5)
     */
    alias: "cancelUnification",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/cancel-unification",
    response: z.void(),
  },
]);

export { api };
