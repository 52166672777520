import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
  AutoAssignmentRuleDetailSchema,
  AutoAssignmentRuleSchema,
} from "../models";

/**
 * Request body schema for create and update API.
 */
const CreateUpdateRequestBodySchema = z.object({
  name: z.string(),
  status: z.enum(["on", "off"]),
  assignee: z.discriminatedUnion("type", [
    z.object({
      type: z.literal("an-agent"),
      userId: z.number().int(),
    }),
    z.object({
      type: z.literal("by-queue"),
    }),
    z.object({
      type: z.literal("a-team"),
      teamId: z.number().int(),
    }),
  ]),
  conditions: z.array(
    z.union([
      z.object({
        category: z.literal("all-messages"),
        operator: z.literal("none"),
      }),
      z.object({
        category: z.literal("keywords"),
        operator: z.enum(["contain-one-of", "contain-all-of"]),
        keywords: z.array(
          z.object({
            id: z.number().int(),
          }),
        ),
      }),
      z.object({
        category: z.literal("tags"),
        operator: z.enum(["contain-one-of", "contain-all-of"]),
        tags: z.array(
          z.object({
            id: z.number().int(),
          }),
        ),
      }),
    ]),
  ),
});

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/assignment/auto-assignment-rules",
    response: z.object({
      autoAssignmentRules: z.array(AutoAssignmentRuleSchema),
    }),
  },
  {
    alias: "getById",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/assignment/auto-assignment-rules/:autoAssignmentRuleId",
    response: AutoAssignmentRuleDetailSchema,
  },
  {
    alias: "create",
    method: "post",
    path: "api/v1/orgs/:orgId/organization/assignment/auto-assignment-rules",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateUpdateRequestBodySchema,
      },
    ],
    response: AutoAssignmentRuleSchema,
  },
  {
    alias: "update",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/assignment/auto-assignment-rules/:autoAssignmentRuleId",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateUpdateRequestBodySchema,
      },
    ],
    response: AutoAssignmentRuleSchema.omit({
      status: true,
    }),
  },
  {
    alias: "deleteById",
    method: "delete",
    path: "api/v1/orgs/:orgId/organization/assignment/auto-assignment-rules/:autoAssignmentRuleId",
    response: z.void(),
  },
  {
    alias: "rePrioritize",
    method: "put",
    // cspell: disable-next-line -- Don't care API path
    path: "api/v1/orgs/:orgId/organization/assignment/auto-assignment-rules/reprioritize",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          rulePrioritization: z.array(z.number().int()),
        }),
      },
    ],
    response: z.object({
      rulePrioritization: z.array(z.number().int()),
    }),
  },
  {
    alias: "switchStatus",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/assignment/auto-assignment-rules/:autoAssignmentRuleId/status",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          status: z.enum(["on", "off"]),
        }),
      },
    ],
    response: z.object({
      status: z.enum(["on", "off"]),
    }),
  },
]);

export { api };
