import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { FlexSpan, FlexText } from "@line/bot-sdk";
import type { CSSProperties } from "react";
import { Children } from "react";

import { classNames } from "@/components/LINEFlexMessage/styled";
import {
  alignClassName,
  flexClassName,
  marginClassName,
  sizeClassName,
} from "@/components/LINEFlexMessage/utils";

interface FlexTextComponentProps {
  data: FlexText;
  parentLayout: "horizontal" | "vertical" | "baseline";
}

interface FlexSpanComponentProps {
  data: FlexSpan;
}

// Reference: https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp#CSS
const LineClampText = styled.p<{
  $styled: Pick<FlexText, "wrap" | "maxLines">;
}>`
  ${({ $styled: { wrap, maxLines } }) =>
    wrap &&
    maxLines &&
    maxLines > 0 &&
    css`
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${maxLines};
    `}
`;

const FlexSpanComponent = ({ data }: FlexSpanComponentProps): JSX.Element => {
  const {
    text,
    size,
    color,
    weight,
    // style,
    // decoration
  } = data;
  return (
    <span
      className={classNames("span", sizeClassName(size), weight)}
      style={{ color }}
    >
      {text}
    </span>
  );
};

const wrapClassName = (wrap: boolean | undefined) => ({ wrap: !!wrap });

// line simulator 裡面有放 spacing, 應該是 bug, 因為文件上面沒有
// https://developers.line.biz/en/reference/messaging-api/#f-text
export const FlexTextComponent = ({
  data,
  parentLayout,
}: FlexTextComponentProps): JSX.Element => {
  const { align, size, weight, color, wrap, maxLines, margin, contents, text } =
    data;
  const style: CSSProperties = { color, lineHeight: 1.2 };
  let flex = data.flex;
  if (parentLayout === "baseline" && flex === undefined) flex = 0;

  if (flex !== undefined && flex > 3) style.flex = `${flex} ${flex} auto`;

  return (
    <div
      className={classNames(
        "text",
        sizeClassName(size),
        weight,
        wrapClassName(wrap),
        marginClassName(margin, parentLayout === "baseline" ? "l" : "t"),
        flexClassName(flex),
        alignClassName(align),
      )}
      style={style}
    >
      {contents && contents.length ? (
        contents.map((content, index) => (
          <FlexSpanComponent key={`span_${index}`} data={content} />
        ))
      ) : (
        <>
          {text &&
            Children.toArray(
              text
                .split("\n")
                .map((t) => (
                  <LineClampText $styled={{ wrap, maxLines }}>
                    {t}
                  </LineClampText>
                )),
            )}
        </>
      )}
    </div>
  );
};
