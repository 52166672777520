import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { Skeleton } from "antd";
import type { FC } from "react";

import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";

namespace TeamNameById {
  export interface Params {
    orgId: CantataTypes["Org"]["id"];
    teamId: CantataTypes["Team"]["id"];
    queryOptions?: Omit<
      Parameters<typeof cantata.member.useUnread>[1],
      "select"
    >;
  }
  export interface Props extends Params {}
}

function useTeamNameById({ queryOptions, ...params }: TeamNameById.Params) {
  const query = cantata.team.useGetById(
    {
      params,
    },
    queryOptions,
  );
  if (!query.isSuccess) return null;
  return query.data.name;
}

const TeamNameByIdInternal: FC<TeamNameById.Props> = ({
  queryOptions,
  ...params
}) => {
  const query = cantata.team.useGetById(
    {
      params,
    },
    queryOptions,
  );
  if (query.isLoading) return <Skeleton.Input size="small" />;

  if (query.isError) {
    return (
      <Alert
        type="error"
        message={inspectMessage`query error: ${query.error}`}
      />
    );
  }

  return <>{query.data.name}</>;
};

const TeamNameById = Object.assign(TeamNameByIdInternal, {
  useTeamNameById,
});

export { TeamNameById };
