import { css } from "@emotion/react";
import type { FC } from "react";

import { Trans } from "@/app/i18n/Trans";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { Flex } from "@/components/Box";
import { DisplayDate } from "@/components/DisplayDate";
import { PermissionChecker } from "@/components/PermissionChecker";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { MemberGenderLabel } from "@/resources/member/MemberGenderLabel";
import { Field } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/Field";
import { AccountManagerField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/AccountManagerField";
import { BirthdayField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/BirthdayField";
import { CityField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/CityField";
import { DisplayMobileField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/DisplayMobileField";
import { GenderField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/GenderField";
import { MemberLevelField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/MemberLevelField";
import { FormProvider } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/FormProvider";
import { ReadOnly } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/ReadOnly";

const DefaultProfile: FC = () => {
  const profile = memberQueriesContext.useMember();

  return (
    <Flex
      css={css`
        flex-direction: column;
        gap: 4px;
      `}
    >
      <Field
        label={<Trans i18nKey="chat.gender" />}
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={
              <ReadOnly>
                <MemberGenderLabel gender={profile.gender} />
              </ReadOnly>
            }
          >
            <FormProvider fieldName="gender">
              <GenderField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={<Trans i18nKey="chat.customer.age" />}
        content={
          <ReadOnly>
            {profile.age === null ? EMPTY_STRING_PLACEHOLDER : profile.age}
          </ReadOnly>
        }
      />
      <Field
        label={<Trans i18nKey="chat.birthday" />}
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={
              <ReadOnly>
                {profile.birthday === null ? null : (
                  <DisplayDate value={profile.birthday} />
                )}
              </ReadOnly>
            }
          >
            <FormProvider fieldName="birthday">
              <BirthdayField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={<Trans i18nKey="chat.mobile" />}
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={
              <ReadOnly>
                {profile.displayMobile || profile.originalMobile}
              </ReadOnly>
            }
          >
            <FormProvider fieldName="displayMobile">
              <DisplayMobileField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={<Trans i18nKey="chat.customer.city" />}
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={<ReadOnly>{profile.city}</ReadOnly>}
          >
            <FormProvider fieldName="city">
              <CityField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={<Trans i18nKey="chat.customer.accountManager" />}
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={<ReadOnly>{profile.accountManager}</ReadOnly>}
          >
            <FormProvider fieldName="accountManager">
              <AccountManagerField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={<Trans i18nKey="chat.customer.memberLevel" />}
        content={
          <PermissionChecker
            to="editMemberProfile"
            fallback={<ReadOnly>{profile.memberLevel}</ReadOnly>}
          >
            <FormProvider fieldName="memberLevel">
              <MemberLevelField />
            </FormProvider>
          </PermissionChecker>
        }
      />
      <Field
        label={<Trans i18nKey="chat.customer.engagementLevel" />}
        content={<ReadOnly>{profile.engagementLevel}</ReadOnly>}
      />
    </Flex>
  );
};

export { DefaultProfile };
