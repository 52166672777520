import type { FormItemProps } from "antd";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";

const memberValidator = {
  name: {
    maxLength: 255,
  },
  displayMobile: {
    maxLength: 16,
  },
  displayEmail: {
    maxLength: 255,
  },
  city: {
    maxLength: 255,
  },
  location: {
    maxLength: 255,
  },
  company: {
    maxLength: 100,
  },
  accountManager: {
    maxLength: 255,
  },
  memberLevel: {
    maxLength: 255,
  },
  customId: {
    maxLength: 255,
  },
  connectId: {
    maxLength: 255,
  },
};

const memberFieldRules: Record<
  keyof Pick<
    CantataTypes["MemberDetail"],
    | "name"
    | "displayMobile"
    | "displayEmail"
    | "city"
    | "location"
    | "company"
    | "accountManager"
    | "memberLevel"
    | "customId"
    | "connectId"
  >,
  NonNullable<FormItemProps["rules"]>
> = {
  name: [
    {
      max: memberValidator.name.maxLength,
      message: (
        <Trans
          i18nKey="validation.maxCharLength"
          values={{ count: memberValidator.name.maxLength }}
        />
      ),
    },
  ],
  displayMobile: [
    {
      max: memberValidator.displayMobile.maxLength,
      message: (
        <Trans
          i18nKey="validation.maxCharLength"
          values={{ count: memberValidator.displayMobile.maxLength }}
        />
      ),
    },
  ],
  displayEmail: [
    {
      type: "email",
      message: <Trans i18nKey="chat.customer.enterValidEmail" />,
    },
    {
      max: memberValidator.displayEmail.maxLength,
      message: (
        <Trans
          i18nKey="validation.maxCharLength"
          values={{ count: memberValidator.displayEmail.maxLength }}
        />
      ),
    },
  ],
  city: [
    {
      max: memberValidator.city.maxLength,
      message: (
        <Trans
          i18nKey="validation.maxCharLength"
          values={{ count: memberValidator.city.maxLength }}
        />
      ),
    },
  ],
  location: [
    {
      max: memberValidator.location.maxLength,
      message: (
        <Trans
          i18nKey="validation.maxCharLength"
          values={{ count: memberValidator.city.maxLength }}
        />
      ),
    },
  ],
  company: [
    {
      max: memberValidator.company.maxLength,
      message: (
        <Trans
          i18nKey="validation.maxCharLength"
          values={{ count: memberValidator.company.maxLength }}
        />
      ),
    },
  ],
  accountManager: [
    {
      max: memberValidator.accountManager.maxLength,
      message: (
        <Trans
          i18nKey="validation.maxCharLength"
          values={{ count: memberValidator.accountManager.maxLength }}
        />
      ),
    },
  ],
  memberLevel: [
    {
      max: memberValidator.memberLevel.maxLength,
      message: (
        <Trans
          i18nKey="validation.maxCharLength"
          values={{ count: memberValidator.memberLevel.maxLength }}
        />
      ),
    },
  ],
  customId: [
    {
      max: memberValidator.customId.maxLength,
      message: (
        <Trans
          i18nKey="validation.maxCharLength"
          values={{ count: memberValidator.customId.maxLength }}
        />
      ),
    },
  ],
  connectId: [
    {
      max: memberValidator.connectId.maxLength,
      message: (
        <Trans
          i18nKey="validation.maxCharLength"
          values={{ count: memberValidator.connectId.maxLength }}
        />
      ),
    },
  ],
};

export { memberFieldRules, memberValidator };
