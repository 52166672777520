import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { secondsToMilliseconds } from "date-fns";
import { type FC, useEffect } from "react";

import { clearSessionOrgId } from "@/activeOrgId/store";
import { Alert } from "@/components/Alert";

const OrgNotFound: FC = () => {
  const resetOrg = useHandler(() => {
    clearSessionOrgId();
    window.location.reload();
  });

  useEffect(() => {
    const timeout = setTimeout(resetOrg, secondsToMilliseconds(5));
    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [resetOrg]);

  return <Alert type="error" message="Org not found." />;
};

export { OrgNotFound };
