import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";

export const FileFieldWrapper = styled.div<{
  $status?: "success" | "warning" | "error" | "validating" | "";
}>`
  position: relative;
  display: flex;
  width: var(--width);
  height: var(--height);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border: 2px solid
    ${(props) => {
      switch (props.$status) {
        case "warning":
          return theme.colors.yellow004;
        case "error":
          return theme.colors.red004;
        default:
          return theme.colors.neutral004;
      }
    }};
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  transition:
    border 0.25s ease-in-out,
    height 0.55s ease-in-out;
  user-select: none;

  &:hover {
    border-color: ${theme.colors.blue006};
  }

  &.is-error {
    border-color: ${theme.colors.red006};
  }
`;
