import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
  OrgDetailSchema,
  OrgSchema,
  UnificationKeysSchema,
  UnifyScopeSettingSchema,
  UtmSettingSchema,
} from "../models";

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs",
    response: z.object({
      organizations: z.array(OrgSchema),
    }),
  },
  {
    alias: "getById",
    method: "get",
    path: "api/v1/orgs/:orgId",
    response: OrgDetailSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#a337c382e071471abbfb7ceb0aba4e8c)
     */
    alias: "getUnifyScopeSetting",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/unify-scope-setting",
    response: UnifyScopeSettingSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/91-Sales-binding-a1ce40b5c8bb4786b8204f2d3a723ea3?pvs=4#7263872970cf401a8704911180126d85)
     */
    alias: "getUtmSetting",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/utm-setting",
    response: UtmSettingSchema,
  },
  {
    alias: "update",
    method: "put",
    path: "api/v1/orgs/:orgId",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          name: z.string(),
          logo: z.string().nullable(),
        }),
      },
    ],
    response: OrgSchema.pick({
      name: true,
      logo: true,
    }),
  },
  {
    alias: "partialUpdate",
    method: "patch",
    path: "api/v1/orgs/:orgId",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: OrgSchema.pick({
          name: true,
          logo: true,
          enableTwoFactor: true,
        }).partial(),
      },
    ],
    response: z.unknown(),
  },
  {
    alias: "updateUnifyKeys",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/unify-scope-setting/unify-keys",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          unifyKeys: z.array(UnificationKeysSchema),
        }),
      },
    ],
    // Don't care
    response: z.unknown(),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#6d4f626819d04d84b74767341cc363db)
     */
    alias: "getUnifyMergeState",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/unify-scope-setting/merge-state",
    response: z.object({
      orgId: z.number().int(),
      isFinished: z.boolean(),
    }),
  },
]);

export { api };
