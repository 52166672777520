import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { flow } from "lodash-es";
import { marked, type MarkedOptions } from "marked";
import sanitizeHtml from "sanitize-html";

namespace markdownToHtml {
  export type Options = {
    markedOptions?: MarkedOptions;
    sanitizeOptions?: sanitizeHtml.IOptions;
  };
}

function isAsyncMarkedOptions(
  options: MarkedOptions,
): options is MarkedOptions & { async: true } {
  return options.async === true;
}

function isSyncMarkedOptions(
  options: MarkedOptions,
): options is MarkedOptions & { async?: false } {
  return options.async === false || options.async === undefined;
}

function markdownToHtmlInternal(
  markdown: string,
  options?: {
    markedOptions?: Omit<MarkedOptions, "async"> & {
      async?: false;
    };
    sanitizeOptions?: sanitizeHtml.IOptions;
  },
): string;
function markdownToHtmlInternal(
  markdown: string,
  options: {
    markedOptions: MarkedOptions & {
      async: true;
    };
    sanitizeOptions?: sanitizeHtml.IOptions;
  },
): Promise<string>;

function markdownToHtmlInternal(
  markdown: string,
  options?: markdownToHtml.Options,
): string | Promise<string> {
  if (options?.markedOptions && isAsyncMarkedOptions(options.markedOptions)) {
    return marked(markdown, options.markedOptions).then((dirty) =>
      sanitizeHtml(dirty, options.sanitizeOptions),
    );
  }
  if (!options?.markedOptions || isSyncMarkedOptions(options.markedOptions)) {
    return flow(
      (input: typeof markdown) => marked(input, options?.markedOptions),
      (dirty: string) => sanitizeHtml(dirty, options?.sanitizeOptions),
    )(markdown);
  }
  throw new Error(
    inspectMessage`Unreachable code reached, options: ${options}`,
  );
}

const markdownToHtml = Object.assign(markdownToHtmlInternal, {
  isAsyncMarkedOptions,
  isSyncMarkedOptions,
});

export { markdownToHtml };
