import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { ElementRef } from "react";

import type { Divider } from "@/components/Divider";
import { defineStyles } from "@/shared/emotion";

import { Card } from "..";

const styles = defineStyles({
  row: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "stretch",
    gap: "inherit",
    [`& .${Card.classNameRecord.noPaddingCardContent}`]: {
      flex: 1,
    },
    "& .ant-divider-vertical": {
      marginInline: 0,
      marginBlock: `var(${Card.cssVarNameRecord.paddingBlock})`,
      height: "auto",
    },
  }),
});

type RowRef = ElementRef<"div">;
type RowProps = ComponentProps<"div">;

/**
 * Flex Layout in a Card
 *
 * Integrated with `Divider`.
 *
 * To use `Divider` within `FlexLayout`, use `Card.NoPadding` instead of `Card`
 * to ensure the divider spans the full width.
 *
 * @see {@link Divider}
 *
 * This layout can also be used with other layouts as its children. For example,
 * you can put `DashboardCardLayout` as a flex item.
 *
 */
const Row = forwardRef<RowRef, RowProps>(function Row(props, ref) {
  return <div css={styles.row} ref={ref} {...props} />;
});

assignDisplayName(Row, "FlexLayout.Row");

const FlexLayout = {
  Row,
};

export { FlexLayout, Row };
export type { RowProps, RowRef };
