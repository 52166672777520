import type { ReactNode } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import type { AntdMessageMethod } from "@/components/message";
import { message } from "@/components/message";
import { colorMap } from "@/resources/user/onDutyStatus/utils/colorMap";
import { iconMap } from "@/resources/user/onDutyStatus/utils/iconMap";

const mutatedSuccessMessageContentMap: Record<
  CantataTypes["UserOnDutyStatus"],
  ReactNode
> = {
  online: <Trans i18nKey="onDutyStatus.action.online.success.toast" />,
  away: <Trans i18nKey="onDutyStatus.action.away.success.toast" />,
  offline: <Trans i18nKey="onDutyStatus.action.offline.success.toast" />,
};

const methodMap: Record<CantataTypes["UserOnDutyStatus"], AntdMessageMethod> = {
  online: "success",
  away: "warning",
  offline: "error",
};

function mutateSuccessMessage(status: CantataTypes["UserOnDutyStatus"]) {
  const Icon = iconMap[status];
  const icon = (
    <Icon
      style={{
        color: colorMap[status],
      }}
    />
  );
  const content = mutatedSuccessMessageContentMap[status];
  message[methodMap[status]]({
    content,
    icon,
  });
}

export { mutateSuccessMessage };
