import type { FC } from "react";

import { memberFieldRules } from "@/resources/member/memberValidator";
import { TextField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/TextField";

const ConnectIdField: FC = () => {
  return (
    <TextField
      fieldName="connectId"
      copyable
      rules={memberFieldRules.connectId}
    />
  );
};

export { ConnectIdField };
