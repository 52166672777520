import { useCallback } from "react";

import type { CantataTypes } from "@/cantata/types";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

type Channel = CantataTypes["Channel"];

const typesOrder: CantataTypes["ChannelType"][] = ["line", "fb", "ig"];

export const useSortChannelByStatusAndTypeAndName = () => {
  const localeCompare = useLocaleCompare();
  return useCallback(
    function sortChannelByStatusAndPlatformAndName<
      T extends Pick<Channel, "type" | "status" | "name">,
    >(a: T, b: T): number {
      /**
       * Sort by connection status, then by platform, then by name.
       */
      if (a.status === "connected" && b.status !== "connected") return -1;
      if (a.status !== "connected" && b.status === "connected") return 1;

      const platformComparison =
        typesOrder.indexOf(a.type) - typesOrder.indexOf(b.type);
      if (platformComparison !== 0) {
        return platformComparison;
      }

      return localeCompare(a.name, b.name);
    },
    [localeCompare],
  );
};
