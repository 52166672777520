import { camelCase as _camelCase } from "lodash-es";
import type { CamelCase } from "type-fest";

/**
 * Type-safe version of `_.camelCase`
 */
function camelCase<TInput extends string>(input: TInput): CamelCase<TInput> {
  return _camelCase(input) as CamelCase<TInput>;
}

export { camelCase };
