import { breakpointApi } from "@zeffiroso/theme";
import type { FC } from "react";

import { AppLayoutGteTabletLanscape } from "@/layout/base/AuthenticatedAppOuter/GteTabletLanscape";
import { AppLayoutLteMobile } from "@/layout/base/AuthenticatedAppOuter/LteMobile";
import type { AuthenticatedAppOuterProps } from "@/layout/base/AuthenticatedAppOuter/types";

const AuthenticatedAppOuter: FC<AuthenticatedAppOuterProps> = (props) => {
  const isGteTabletLandscape = breakpointApi.useGte("tabletLandscape");
  const LayoutComponent = isGteTabletLandscape
    ? AppLayoutGteTabletLanscape
    : AppLayoutLteMobile;
  return <LayoutComponent {...props} />;
};

export { AuthenticatedAppOuter };
