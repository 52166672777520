import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { SideMenuLayout } from "@/layout/SideMenuLayout";

const useSidebarMenuItems = () => {
  const { t } = useTranslation();

  const sidebarMenuItems = useMemo(() => {
    return SideMenuLayout.defineSidebarMenuItems([
      {
        label: t("page.insights.contacts.title"),
        path: "/insights/contacts",
      },
      {
        label: t("page.insights.conversation.title"),
        path: "/insights/conversation",
      },
      {
        label: t("page.insights.efficiency.title"),
        path: "/insights/efficiency",
      },
      {
        label: t("page.insights.teams.title"),
        path: "/insights/teams",
      },
    ]);
  }, [t]);
  return sidebarMenuItems;
};

const Outer: FC = () => {
  const sidebarMenuItems = useSidebarMenuItems();
  return (
    <SideMenuLayout.Outer
      title={<Trans i18nKey="menu.insights.label" />}
      sidebarMenuItems={sidebarMenuItems}
    />
  );
};

export { Outer };
