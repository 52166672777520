import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

const useStore = createWithEqualityFn<{
  state: "init" | "loading" | "loaded" | "error";
}>()(
  () => ({
    state: "init",
  }),
  shallow,
);

export { useStore };
