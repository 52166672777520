import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { useMutation } from "@tanstack/react-query";
import { generateAbortError } from "@zeffiroso/utils/vanilla/generateAbortError";
import { useEffect, useMemo, useState } from "react";
import { createWithEqualityFn } from "zustand/traditional";

import { cantata, cantataClient } from "@/cantata";
import { getApiErrorBodyFromUnknownError } from "@/shared/domains/error";
import { rememberDeviceTokenUtils } from "@/shared/services/rememberDeviceToken";

interface StoreValue {
  state: null | {
    email: string;
    password: string;
    otpRequestTime: Date;
    otpResponseTime: Date;
    restAttempts: number;
    error: unknown;
  };
}

type VerifyTwoFaResult = Awaited<
  ReturnType<typeof cantataClient.auth.verifyTwoFa>
>;

function setupTwoFaUtils() {
  const promiseUtils = (() => {
    let promiseCallbacks: null | {
      resolve: (result: VerifyTwoFaResult) => void;
      reject: (err: unknown) => void;
    } = null;
    function clear() {
      promiseCallbacks = null;
    }
    function resolve(result: VerifyTwoFaResult) {
      if (!promiseCallbacks) return;
      promiseCallbacks.resolve(result);
      clear();
    }
    function reject(err: unknown) {
      if (!promiseCallbacks) return;
      promiseCallbacks.reject(err);
      clear();
    }
    function setup(options: NonNullable<typeof promiseCallbacks>) {
      const abortError = generateAbortError({
        cause: "The 2FA is canceled because a new 2FA is started.",
      });
      reject(abortError);
      promiseCallbacks = options;
    }
    return {
      resolve,
      reject,
      setup,
    };
  })();

  /**
   * The store for 2FA.
   */
  const useStore = createWithEqualityFn<StoreValue>(() => ({
    state: null,
  }));

  async function reSendOtp() {
    const currentState = useStore.getState().state;
    if (!currentState) return;
    const { email, password } = currentState;
    const requestTime = new Date();
    const rememberDeviceToken = rememberDeviceTokenUtils.getValue();
    const result = await safePromise(() =>
      cantataClient.auth.signIn({
        email,
        password,
        rememberToken: !rememberDeviceToken ? null : rememberDeviceToken,
      }),
    );
    const responseTime = new Date();
    {
      const currentState = useStore.getState().state;
      if (!currentState) return;
      if (result.isError) {
        useStore.setState({
          state: {
            ...currentState,
            error: result.error,
          },
        });
        return;
      }
      if (result.data.accountId && result.data.token) {
        const rememberDeviceToken = rememberDeviceTokenUtils.getValue();
        promiseUtils.resolve({
          accountId: result.data.accountId,
          token: result.data.token,
          // Fallback to the token stored.
          rememberToken: !rememberDeviceToken ? null : rememberDeviceToken,
        });
        return;
      }
      if (result.data.requiresTwoFactor) {
        useStore.setState({
          state: {
            ...currentState,
            otpRequestTime: requestTime,
            otpResponseTime: responseTime,
            restAttempts: result.data.twoFactorMaxAttempts,
            error: null,
          },
        });
      }
    }
  }

  /**
   * The mutation and its utils for verifying 2FA.
   */
  function useTwoFaUtilsMutations() {
    const verifyTwoFaMutation = cantata.auth.useVerifyTwoFa(
      {},
      {
        onSuccess: promiseUtils.resolve,
        onError(err) {
          const currentState = useStore.getState().state;
          if (!currentState) return;
          const definedError = getApiErrorBodyFromUnknownError(err);
          if (definedError) {
            if (definedError.name === "AUTH_OTP_MISMATCH") {
              useStore.setState({
                state: {
                  ...currentState,
                  restAttempts: definedError.detail.remainAttempts,
                  error: err,
                },
              });
              return;
            }
            if (
              definedError.name === "AUTH_OTP_MAX_ATTEMPTS_EXCEEDED" ||
              definedError.name === "AUTH_OTP_EXPIRED"
            ) {
              useStore.setState({
                state: {
                  ...currentState,
                  restAttempts: 0,
                  error: err,
                },
              });
              return;
            }
          }
          useStore.setState({
            state: {
              ...currentState,
              error: err,
            },
          });
        },
      },
    );
    const reSendOtpMutation = useMutation(reSendOtp);
    const state = useStore(({ state }) => state);
    const canVerify = useMemo(() => {
      if (!state) return false;
      return state.restAttempts > 0;
    }, [state]);

    const verify = useHandler(function verify(
      params: Parameters<typeof verifyTwoFaMutation.mutate>[0],
    ) {
      verifyTwoFaMutation.mutate(params);
    });

    const isLoading = useMemo(
      () => verifyTwoFaMutation.isLoading || reSendOtpMutation.isLoading,
      [reSendOtpMutation.isLoading, verifyTwoFaMutation.isLoading],
    );

    const api = useMemo(
      () => ({
        verify,
        canVerify,
        verifyTwoFaMutation,
        reSendOtpMutation,
        isLoading,
      }),
      [verify, canVerify, verifyTwoFaMutation, reSendOtpMutation, isLoading],
    );

    return api;
  }

  /**
   * Cancel 2FA.
   */
  function cancel(cause: unknown) {
    const currentState = useStore.getState().state;
    if (!currentState) return;
    const abortError = generateAbortError({
      cause,
    });
    promiseUtils.reject(abortError);
    useStore.setState({
      state: null,
    });
  }

  /**
   * Start 2FA.
   *
   * It will return a promise that will be resolved when the 2FA is verified.
   */
  function startTwoFa(
    params: Pick<
      NonNullable<StoreValue["state"]>,
      | "email"
      | "password"
      | "otpRequestTime"
      | "otpResponseTime"
      | "restAttempts"
    >,
  ): Promise<VerifyTwoFaResult> {
    cancel("The 2FA is canceled because a new 2FA is started.");
    useStore.setState({
      state: {
        email: params.email,
        password: params.password,
        otpRequestTime: params.otpRequestTime,
        otpResponseTime: params.otpResponseTime,
        restAttempts: params.restAttempts,
        error: null,
      },
    });
    return new Promise((resolve, reject) => {
      promiseUtils.setup({
        resolve,
        reject,
      });
    });
  }

  function useState() {
    return useStore(({ state }) => state);
  }

  /**
   * Setup effect for 2FA.
   */
  function useSetupEffect() {
    useEffect(() => {
      return function cleanup() {
        cancel("The 2FA is canceled because the component is unmounted.");
      };
    }, []);
  }

  const api = {
    useStore,
    useState,
    startTwoFa,
    cancel,
    useSetupEffect,
    useTwoFaUtilsMutations,
  };
  return api;
}

/**
 * Use 2FA utils.
 *
 * ```ts
 * const twoFaUtils = useTwoFaUtils();
 * const twoFaState = twoFaUtils.useStore();
 * const verifyTwoFa = twoFaUtils.useVerifyTwoFa();
 *
 * function startTwoFa() {
 *   twoFaUtils.startTwoFa(/* params * /);
 * }
 * ```
 */
function useTwoFaUtils() {
  const [api] = useState(setupTwoFaUtils);
  api.useSetupEffect();
  return api;
}

export { useTwoFaUtils };
