import { memo } from "@chatbotgang/etude/react/memo";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import type { PillProps } from "@/components/Pill";
import { Pill } from "@/components/Pill";

const channelStatusMap: Record<
  CantataTypes["Channel"]["status"],
  Pick<PillProps, "$variant" | "children">
> = {
  connected: {
    $variant: "success",
    children: <Trans i18nKey="resource.channel.connected.pill.label" />,
  },
  disconnected: {
    $variant: "disabled",
    children: <Trans i18nKey="resource.channel.disconnected.pill.label" />,
  },
  "token-expired": {
    $variant: "rejected",
    children: <Trans i18nKey="resource.channel.tokenExpired.pill.label" />,
  },
};

export const ConnectionStatus = memo<
  Omit<PillProps, "$variant" | "children"> & {
    status: CantataTypes["Channel"]["status"];
  }
>(function ConnectionStatus({ status, ...props }) {
  return <Pill {...props} {...channelStatusMap[status]} />;
});
