import type { CantataTypes } from "@/cantata/types";

const memberTypeToChannelTypeMap = {
  line: "line",
  line_group: "line",
  fb: "fb",
  ig: "ig",
} as const satisfies Record<
  CantataTypes["Member"]["type"],
  CantataTypes["ChannelType"]
>;

export { memberTypeToChannelTypeMap };
