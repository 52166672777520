import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { define } from "@chatbotgang/etude/util/define";
import type { UseQueryOptions } from "@tanstack/react-query";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
import { type FC, type ReactNode, useCallback, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { useFillYmdRangeUtils } from "@/resources/datetime";
import { usePageInfoUtil } from "@/routes/Insights/Efficiency/pageInfo";

type Args = Parameters<
  typeof cantata.dashboardEfficiency.useFirstResponseTimeDetail
>;
type ConfigOptions = Args[0];

const queryOptions = {
  suspense: true,
  useErrorBoundary: true,
} satisfies UseQueryOptions;

const useConfigOptions = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const configOptions = useMemo(
    () =>
      define<ConfigOptions>({
        params: { orgId },
        queries: {
          ...pageInfoUtil.computed.commonQueries,
          interval: pageInfoUtil.data.trendInterval,
        },
      }),
    [orgId, pageInfoUtil],
  );
  return configOptions;
};

const DEFAULT_VALUE = define<
  OverrideWith<
    Omit<CantataTypes["DashboardMetrics"], "date">,
    {
      value: CantataTypes["DashboardMetrics"]["value"] | null;
    }
  >
>({
  value: null,
});

const useFillData = () => {
  const pageInfoUtil = usePageInfoUtil();
  const fillYmdRangeUtils = useFillYmdRangeUtils();
  const fillYmdRange = useMemo(
    () => fillYmdRangeUtils[pageInfoUtil.data.trendInterval],
    [fillYmdRangeUtils, pageInfoUtil.data.trendInterval],
  );

  const fillData = useCallback(
    (metrics: CantataTypes["DashboardMetrics"][]) =>
      fillYmdRange({
        dateKey: "date",
        data: metrics,
        fillValue: DEFAULT_VALUE,
        start: pageInfoUtil.data.startTime,
        end: pageInfoUtil.data.endTime,
      }).map(function addDateRange(metric) {
        return {
          ...metric,
          dateRange: [
            metric.date,
            pageInfoUtil.data.trendInterval === "daily"
              ? metric.date
              : pageInfoUtil.data.trendInterval === "weekly"
                ? metric.date.addWeeks(1).addDays(-1)
                : pageInfoUtil.data.trendInterval === "monthly"
                  ? metric.date.addMonths(1).addDays(-1)
                  : (() => {
                      pageInfoUtil.data.trendInterval satisfies never;
                      throw new Error(
                        inspectMessage`Unexpected trendInterval: ${pageInfoUtil.data.trendInterval}`,
                      );
                    })(),
          ],
        };
      }),
    [
      fillYmdRange,
      pageInfoUtil.data.endTime,
      pageInfoUtil.data.startTime,
      pageInfoUtil.data.trendInterval,
    ],
  );
  return fillData;
};

const useWaitTimeQuery = () => {
  const configOptions = useConfigOptions();
  const fillData = useFillData();
  return cantata.dashboardEfficiency.useWaitTimeDetail(configOptions, {
    ...queryOptions,
    select(state) {
      return {
        ...state,
        timeMetrics: fillData(state.timeMetrics),
      };
    },
  });
};

const useFirstResponseQuery = () => {
  const configOptions = useConfigOptions();
  const fillData = useFillData();
  return cantata.dashboardEfficiency.useFirstResponseTimeDetail(configOptions, {
    ...queryOptions,
    select(state) {
      return {
        ...state,
        timeMetrics: fillData(state.timeMetrics),
      };
    },
  });
};

const useResolutionTimeQuery = () => {
  const configOptions = useConfigOptions();
  const fillData = useFillData();
  return cantata.dashboardEfficiency.useTtrDetail(configOptions, {
    ...queryOptions,
    select(state) {
      return {
        ...state,
        timeMetrics: fillData(state.timeMetrics),
      };
    },
  });
};

function useQueries() {
  const waitTimeQuery = useWaitTimeQuery();
  const firstResponseQuery = useFirstResponseQuery();
  const resolutionTimeQuery = useResolutionTimeQuery();
  const ret = useMemo(
    () => ({
      waitTime: waitTimeQuery,
      firstResponse: firstResponseQuery,
      resolutionTime: resolutionTimeQuery,
    }),
    [waitTimeQuery, firstResponseQuery, resolutionTimeQuery],
  );
  return ret;
}

const QueriesContext = createQueriesContext<ReturnType<typeof useQueries>>();

const QueryProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const queries = useQueries();
  return (
    <QueriesContext.Provider queries={queries}>
      {children}
    </QueriesContext.Provider>
  );
};

const queriesContext = {
  Provider: QueryProvider,
  useData: QueriesContext.useData,
};

export { queriesContext };
