import { makeApi } from "@zodios/core";
import { z } from "zod";

import { QuickTemplateSchema } from "../models";

const api = makeApi([
  {
    alias: "createRecommendQuickTemplate",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/ai/recommend-quick-template",
    immutable: true,
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          memberId: z.number().int(),
        }),
      },
    ],
    response: z.object({
      quickTemplates: z.array(QuickTemplateSchema),
    }),
  },
]);

export { api };
