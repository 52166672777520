import type { FC } from "react";

import { SideMenu } from "@/layout/SideMenu";
import { Assignment } from "@/routes/Chat/ui/SideMenu/Assignment";
import { Channels } from "@/routes/Chat/ui/SideMenu/Channels";
import { Filters } from "@/routes/Chat/ui/SideMenu/Filters";
import { Profile } from "@/routes/Chat/ui/SideMenu/Profile";

const Content: FC = () => {
  return (
    <SideMenu.Content>
      <Channels />
      <Assignment />
      <Filters />
    </SideMenu.Content>
  );
};

const api = Object.assign(Content, { Profile });

export { api as SideMenu };
