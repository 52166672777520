import { memo } from "@chatbotgang/etude/react/memo";

import type { NarrowIconButtonProps } from "@/components/Button/NarrowIconButton";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { DeleteOutlined } from "@/shared/icons/common/DeleteOutlined";

export const DeleteMessageButton = memo(function DeleteMessageButton(
  props: NarrowIconButtonProps,
) {
  return (
    <NarrowIconButton {...props} icon={<DeleteOutlined />} iconSize={20} />
  );
});
