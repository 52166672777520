import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";

import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import type { TagProps, TagRef } from "@/components/Tag";
import { Tag } from "@/components/Tag";
import { TagItems, type TagItemsProps } from "@/components/Tag/TagItems";

const appCss = {
  maac: css`
    border-color: ${theme.colors.blue003};
    background-color: ${theme.colors.blue001};
    color: ${theme.colors.blue006};
  `,
  caac: css`
    border-color: ${theme.colors.purple003};
    background-color: ${theme.colors.purple001};
    color: ${theme.colors.purple006};
  `,
} satisfies Record<CantataTypes["CdpTag"]["source"], ReturnType<typeof css>>;

type CdpTagProps = TagProps & {
  source: keyof typeof appCss;
};
type CdpTagRef = TagRef;

const CdpTag = forwardRef<CdpTagRef, CdpTagProps>(function CdpTag(
  { source, ...props },
  ref,
) {
  return <Tag {...props} ref={ref} css={appCss[source]} />;
});

type CdpTagsProps = Pick<TagItemsProps, "collapsedSize"> & {
  orgId: CantataTypes["Org"]["id"];
  memberId: CantataTypes["Member"]["id"];
};

const CdpTags: FC<CdpTagsProps> = ({ orgId, memberId, collapsedSize }) => {
  const memberQuery = cantata.member.useGetById(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      useErrorBoundary: true,
      suspense: true,
    },
  );
  const cdpSettingQuery = cantata.org.useGetUnifyScopeSetting(
    {
      params: {
        orgId,
        channelId: memberQuery.data?.channelId,
      },
    },
    {
      enabled: memberQuery.isSuccess,
      useErrorBoundary: true,
      suspense: true,
    },
  );
  const tagsQuery = cantata.memberTag.useGetCdpTagsByMemberId(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      enabled: cdpSettingQuery.isSuccess && cdpSettingQuery.data.isCdpConnected,
      useErrorBoundary: true,
      suspense: true,
    },
  );

  const tags = useMemo(
    () =>
      !tagsQuery.isSuccess
        ? []
        : tagsQuery.data.tags
            .filter((tag) => tag.source !== "caac")
            .map<NonNullable<TagItemsProps["value"]>[number]>((tag) => ({
              key: tag.id,
              children: tag.name,
              css: appCss[tag.source],
            })),
    [tagsQuery.data?.tags, tagsQuery.isSuccess],
  );
  return <TagItems value={tags} collapsedSize={collapsedSize} />;
};

const Wrapped: FC<CdpTagsProps> = (props) => {
  return (
    <ErrorBoundary.Alert>
      <CdpTags {...props} />
    </ErrorBoundary.Alert>
  );
};

export { CdpTag, Wrapped as CdpTags };
export type { CdpTagProps, CdpTagRef };
