import { memo } from "@chatbotgang/etude/react/memo";
import styled from "@emotion/styled";
import { Skeleton } from "antd";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { Avatar } from "@/components/Avatar";
import { Flex } from "@/components/Box";
import { Text } from "@/components/Typography";

const ManagerBox = styled(Flex)`
  align-items: center;
  gap: 24px;
`;
export const OwnerInfo = memo(function OwnerInfo({
  ownerId,
}: {
  ownerId: number;
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.user.useGetById({
    params: {
      orgId,
      userId: ownerId,
    },
  });

  if (query.isError)
    return <Alert type="error" message={query.error.message} />;

  if (query.isLoading) {
    return (
      <ManagerBox>
        <Skeleton.Avatar size={32} />
        <Skeleton.Input size="small" />
        <Skeleton.Input size="small" />
      </ManagerBox>
    );
  }

  return (
    <ManagerBox>
      <Avatar src={query.data.avatar || ""} />
      <Text>{query.data.name}</Text>
      <Text>{query.data.email}</Text>
    </ManagerBox>
  );
});
