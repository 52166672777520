import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { FormItem } from "@/components/Form";
import { Input } from "@/components/Input";
import { BoldText } from "@/components/Typography";
import { teamsQueriesContext } from "@/routes/Settings/Teams/pages/TeamForm/teamsQueryContext";
import { usePageInfo } from "@/routes/Settings/Teams/pages/TeamForm/usePageInfo";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  self: css({
    width: 336,
    maxWidth: "100%",
  }),
  desc: css({
    paddingBottom: 8,
    color: theme.colors.neutral007,
  }),
});

const LENGTH_LIMIT = 255;

const ExternalIdField: FC = () => {
  const { t } = useTranslation();
  const { isUpdating, teamId } = usePageInfo();
  const teams = teamsQueriesContext.useData().teams;

  return (
    <FormItem
      css={styles.self}
      label={
        <BoldText>
          <Trans i18nKey="team.form.externalTeamId.label" />
        </BoldText>
      }
    >
      <div css={styles.desc}>
        <Trans i18nKey="team.form.externalTeamId.description" />
      </div>
      <FormItem
        noStyle
        name="externalTeamId"
        rules={[
          {
            max: LENGTH_LIMIT,
            message: t("validation.maxCharLength", {
              count: LENGTH_LIMIT,
            }),
          },
          {
            validator: async (_rule, value) => {
              const duplicatedTeam = teams.find((team) =>
                isUpdating
                  ? team.id !== teamId && team.externalTeamId === value
                  : team.externalTeamId === value,
              );
              if (!duplicatedTeam) return;
              throw new Error(
                t("team.form.externalTeamId.existed", {
                  teamName: duplicatedTeam.name,
                }),
              );
            },
          },
        ]}
      >
        <Input />
      </FormItem>
    </FormItem>
  );
};

export { ExternalIdField };
