import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import {
  ChannelTypeSchema,
  DashboardIntervalSchema,
} from "@zeffiroso/cantata/models";
import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { addDays } from "date-fns/fp";
import {
  flow,
  isEqual,
  mapKeys,
  mapValues,
  omit,
  omitBy,
  uniq,
} from "lodash-es";
import queryString from "query-string";
import type { FC } from "react";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { objectEntries } from "tsafe";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import type { CantataTypes } from "@/cantata/types";
import {
  getPreviousYmdRange,
  type YmdRange,
} from "@/components/DatePicker/utils";
import {
  type FilterBy,
  FilterBySchema,
} from "@/resources/channel/ChannelPlatformSelect";
import { DateIntervalSelect } from "@/routes/Insights/components/DateIntervalSelect";
import { useSetupDateInfo } from "@/routes/Insights/hooks/useSetupDateInfo";

const searchParamKeys = define<Record<string, string>>()({
  startTime: "startTime",
  endTime: "endTime",
  teamIds: "teamId",
  channelIds: "channelId",
  channelTypes: "channelType",
  filterBy: "filterBy",
  membersInterval: "membersInterval",
  tagsInterval: "tagsInterval",
});

type PageInfo = {
  startTime: Ymd;
  endTime: Ymd;
  filterBy: FilterBy;
  teamIds: Array<CantataTypes["Team"]["id"]>;
  channelIds: Array<CantataTypes["Channel"]["id"]>;
  channelTypes: Array<CantataTypes["Channel"]["type"]>;
  membersInterval: CantataTypes["DashboardInterval"];
  tagsInterval: CantataTypes["DashboardInterval"];
};

/**
 * The main logic of the pageinfo.
 */
function useSetupPageInfoUtils() {
  const dateInfo = useSetupDateInfo();
  const defaultPageInfo = useMemo<PageInfo>(() => {
    const startTime = new Ymd(addDays(-30)(dateInfo.startOfToday));
    const endTime = new Ymd(dateInfo.startOfToday);
    const defaultInterval = DateIntervalSelect.getDefaultIntervalByRange({
      startTime,
      endTime,
    });
    return {
      startTime,
      endTime,
      filterBy: "platform",
      teamIds: [],
      channelIds: [],
      channelTypes: [],
      membersInterval: defaultInterval,
      tagsInterval: defaultInterval,
    } satisfies PageInfo;
  }, [dateInfo.startOfToday]);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageInfo = useMemo<PageInfo>(() => {
    const { startTime, endTime }: Pick<PageInfo, "startTime" | "endTime"> =
      (() => {
        const startTime: Ymd | null = flow(
          () => searchParams.get(searchParamKeys.startTime),
          (value) => {
            if (!value) return null;
            const ymdParsedResult = Ymd.YmdStringToYmdSchema.safeParse(value);
            if (!ymdParsedResult.success) return null;
            return ymdParsedResult.data;
          },
        )();
        const endTime: Ymd | null = flow(
          () => searchParams.get(searchParamKeys.endTime),
          (value) => {
            if (!value) return null;
            const ymdParsedResult = Ymd.YmdStringToYmdSchema.safeParse(value);
            if (!ymdParsedResult.success) return null;
            return ymdParsedResult.data;
          },
        )();
        if (!startTime || !endTime)
          return {
            startTime: defaultPageInfo.startTime,
            endTime: defaultPageInfo.endTime,
          };
        return { startTime, endTime };
      })();
    const teamIds: Array<CantataTypes["Team"]["id"]> = searchParams
      .getAll(searchParamKeys.teamIds)
      .flatMap((value) => {
        const parsedValue = Number.parseInt(value);
        if (Number.isNaN(parsedValue)) return defaultPageInfo.teamIds;
        return [parsedValue];
      });
    const channelIds: Array<CantataTypes["Channel"]["id"]> = searchParams
      .getAll(searchParamKeys.channelIds)
      .flatMap((value) => {
        const parsedValue = Number.parseInt(value);
        if (Number.isNaN(parsedValue)) return defaultPageInfo.channelIds;
        return [parsedValue];
      });
    const channelTypes: Array<CantataTypes["Channel"]["type"]> = flow(
      () => searchParams.getAll(searchParamKeys.channelTypes),
      (values) =>
        values.flatMap((value) => {
          const parsedValue = ChannelTypeSchema.safeParse(value);
          return parsedValue.success
            ? [parsedValue.data]
            : defaultPageInfo.channelTypes;
        }),
      uniq,
    )();
    const membersInterval: CantataTypes["DashboardInterval"] = flow(
      () => searchParams.get(searchParamKeys.membersInterval),
      (value) => {
        const parsedValue = DashboardIntervalSchema.safeParse(value);
        return parsedValue.success
          ? parsedValue.data
          : defaultPageInfo.membersInterval;
      },
    )();
    const tagsInterval: CantataTypes["DashboardInterval"] = flow(
      () => searchParams.get(searchParamKeys.tagsInterval),
      (value) => {
        const parsedValue = DashboardIntervalSchema.safeParse(value);
        return parsedValue.success
          ? parsedValue.data
          : defaultPageInfo.tagsInterval;
      },
    )();
    const filterBy: FilterBy = flow(
      () => searchParams.get(searchParamKeys.filterBy),
      (value) => {
        const parsedValue = FilterBySchema.safeParse(value);
        return parsedValue.success
          ? parsedValue.data
          : defaultPageInfo.filterBy;
      },
    )();

    const data = define<Record<keyof typeof searchParamKeys, unknown>>()({
      startTime,
      endTime,
      teamIds,
      filterBy,
      channelIds,
      channelTypes,
      membersInterval,
      tagsInterval,
    });

    return data;
  }, [
    defaultPageInfo.channelIds,
    defaultPageInfo.channelTypes,
    defaultPageInfo.endTime,
    defaultPageInfo.membersInterval,
    defaultPageInfo.startTime,
    defaultPageInfo.tagsInterval,
    defaultPageInfo.teamIds,
    defaultPageInfo.filterBy,
    searchParams,
  ]);

  const update = useHandler(function update(patch: Partial<typeof pageInfo>) {
    const nextPageInfo: typeof pageInfo = { ...pageInfo };
    (function handleTimeGranularity() {
      if (!("startTime" in patch) || !("endTime" in patch)) return;
      if (!patch.startTime || !patch.endTime) return;
      const defaultInterval = DateIntervalSelect.getDefaultIntervalByRange({
        startTime: patch.startTime,
        endTime: patch.endTime,
      });
      nextPageInfo.membersInterval = defaultInterval;
      nextPageInfo.tagsInterval = defaultInterval;
    })();
    (function handleTimeRange() {
      // Only handle when both startTime and endTime are provided
      if (!("startTime" in patch) && !("endTime" in patch)) return;

      if (patch.startTime && patch.endTime) {
        nextPageInfo.startTime = patch.startTime;
        nextPageInfo.endTime = patch.endTime;
        return;
      }

      // fallback to default range, it might be a reset
      nextPageInfo.startTime = defaultPageInfo.startTime;
      nextPageInfo.endTime = defaultPageInfo.endTime;
    })();
    objectEntries(patch).forEach((entry) => {
      if (entry[0] === "startTime" || entry[0] === "endTime") {
        return;
      }
      // @ts-expect-error -- hard to type
      nextPageInfo[entry[0]] = entry[1];
    });
    const qs = flow(
      () => nextPageInfo,
      function deleteDefaultTimeRange(obj) {
        if (
          isEqual(
            [obj.startTime, obj.endTime],
            [defaultPageInfo.startTime, defaultPageInfo.endTime],
          )
        ) {
          return omit(obj, ["startTime", "endTime"]);
        }
        return obj;
      },
      function deleteDefaultValues(obj) {
        return omitBy(obj, (value, key) => {
          // Do not handle time range
          if (key === "startTime" || key === "endTime") return false;
          // @ts-expect-error -- hard to type
          const defaultValue = defaultPageInfo[key];
          if (isEqual(value, defaultValue)) return true;
          return false;
        });
      },
      function mapKeysToSearchParam(obj) {
        return mapKeys(
          obj,
          // @ts-expect-error -- hard to type
          (_, key) => searchParamKeys[key],
        );
      },
      function mapDateToYmd(obj) {
        return mapValues(obj, (value) => {
          if (value instanceof Date) {
            return new Ymd(value);
          }
          return value;
        });
      },
      queryString.stringify,
    )();
    // Navigate
    setSearchParams(qs);
  });

  useEffect(
    function clearSearchParamsWhenOrgChanged() {
      const cleanup = useActiveOrgIdStore.subscribe(
        function clearSearchParams() {
          setSearchParams("", { replace: true });
        },
      );
      return cleanup;
    },
    [setSearchParams],
  );

  const currentRange: YmdRange = useMemo(
    () => [pageInfo.startTime, pageInfo.endTime],
    [pageInfo.endTime, pageInfo.startTime],
  );

  const previousRange: YmdRange = useMemo(
    () => getPreviousYmdRange(pageInfo.startTime, pageInfo.endTime),
    [pageInfo.endTime, pageInfo.startTime],
  );

  /**
   * Common queries for API requests.
   */
  const commonQueries = useMemo(
    () => ({
      startTime: pageInfo.startTime,
      endTime: pageInfo.endTime,
      ...(!pageInfo.teamIds.length ? null : { teamId: pageInfo.teamIds }),
      ...(!pageInfo.channelIds.length
        ? null
        : { channelId: pageInfo.channelIds }),
      ...(!pageInfo.channelTypes.length
        ? null
        : { channelType: pageInfo.channelTypes }),
    }),
    [
      pageInfo.channelIds,
      pageInfo.channelTypes,
      pageInfo.endTime,
      pageInfo.startTime,
      pageInfo.teamIds,
    ],
  );

  const pageInfoUtil = useMemo(() => {
    return {
      data: pageInfo,
      update,
      computed: {
        commonQueries,
        currentRange,
        previousRange,
      },
      dateInfo,
    };
  }, [pageInfo, update, commonQueries, currentRange, previousRange, dateInfo]);
  return pageInfoUtil;
}

const PageInfoContext = createContext<ReturnType<typeof useSetupPageInfoUtils>>(
  {
    name: "InsightsPageInfoContext",
  },
);

const PageInfoProvider: FC<
  Omit<ComponentProps<typeof PageInfoContext.Provider>, "value">
> = function PageInfoProvider(props) {
  const pageInfoUtils = useSetupPageInfoUtils();
  return <PageInfoContext.Provider value={pageInfoUtils} {...props} />;
};

const usePageInfoUtil = PageInfoContext.useContext;

export { PageInfoProvider, usePageInfoUtil };
