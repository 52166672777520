import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  useNotificationPermission,
  useRequestNotificationPermissionMutation,
} from "@/app/browser/notificationPermission";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { ExternalLink } from "@/components/ExternalLink";
import { fcm } from "@/internal/firebase/firebaseMessaging";

const LearnMore = memo(function LearnMore() {
  const { t } = useTranslation();
  return (
    <ExternalLink
      trailingIcon
      href={t("notification.setting.grant.learnMore.link")}
    >
      {t("notification.setting.grant.learnMore.label")}
    </ExternalLink>
  );
});

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: inherit;
  gap: inherit;

  & > p {
    margin-bottom: 0;
  }
`;

const Granted = memo(function Granted() {
  const { t } = useTranslation();
  return (
    <Alert
      message={t("notification.setting.grant.granted.title")}
      description={
        <Description>
          <p>{t("notification.setting.grant.granted.description")}</p>
          <p>
            <LearnMore />
          </p>
        </Description>
      }
    />
  );
});

const Default = memo(function Default({
  loading,
  error,
  onRequest,
}: {
  loading: boolean;
  error?: unknown;
  onRequest: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Flex
      css={css`
        flex-direction: column;
        align-items: flex-start;
        gap: 1em;
      `}
    >
      <Alert
        type="warning"
        message={
          <Description>
            <p>{t("notification.setting.grant.default.description")}</p>
            <p>
              <LearnMore />
            </p>
          </Description>
        }
      />
      {!error ? null : (
        <Alert type="error" message={inspectMessage`unknownError: ${error}`} />
      )}
      <Button
        type="primary"
        loading={loading}
        disabled={loading}
        onClick={onRequest}
      >
        {t("notification.setting.grant.action.grant")}
      </Button>
    </Flex>
  );
});

const Denied = memo(function Denied() {
  const { t } = useTranslation();
  return (
    <Flex
      css={css`
        flex-direction: column;
        align-items: flex-start;
        gap: 1em;
      `}
    >
      <Alert
        type="error"
        message={t("notification.setting.grant.denied.title")}
        description={
          <Description>
            <p>{t("notification.setting.grant.denied.description")}</p>
            <p>
              <LearnMore />
            </p>
          </Description>
        }
      />
    </Flex>
  );
});

const RequestPermission = memo(function RequestPermission() {
  const notificationPermission = useNotificationPermission();
  const requestNotificationPermissionMutation =
    useRequestNotificationPermissionMutation();
  const deviceNotificationEnabled = fcm.useDeviceNotificationEnabled();
  const deviceNotificationRequesting = fcm.useDeviceNotificationRequesting();
  return deviceNotificationEnabled ? (
    <Granted />
  ) : notificationPermission === "denied" ? (
    <Denied />
  ) : (
    <Default
      loading={deviceNotificationRequesting}
      error={requestNotificationPermissionMutation.error}
      onRequest={requestNotificationPermissionMutation.mutate}
    />
  );
});

export { RequestPermission };
