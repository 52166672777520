import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
  QuickTemplateCategorySchema,
  QuickTemplateDetailSchema,
  QuickTemplateSchema,
} from "../models";

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/quick-templates",
    response: z.object({
      quickTemplates: z.array(QuickTemplateSchema),
    }),
  },
  {
    alias: "getById",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/quick-templates/:quickTemplateId",
    response: QuickTemplateDetailSchema,
  },
  {
    alias: "create",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/quick-templates",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: QuickTemplateDetailSchema.omit({ id: true }),
      },
    ],
    response: QuickTemplateDetailSchema,
  },
  {
    alias: "update",
    method: "put",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/quick-templates/:quickTemplateId",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: QuickTemplateDetailSchema.omit({ id: true }),
      },
    ],
    response: QuickTemplateDetailSchema,
  },
  {
    alias: "deleteById",
    method: "delete",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/quick-templates/:quickTemplateId",
    response: z.void(),
  },
  {
    alias: "createCategory",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/quick-template-categories",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: QuickTemplateCategorySchema.pick({ name: true }),
      },
    ],
    response: QuickTemplateCategorySchema,
  },
  {
    alias: "updateCategory",
    method: "put",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/quick-template-categories/:quickTemplateCategoryId",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: QuickTemplateCategorySchema.pick({ name: true }),
      },
    ],
    response: QuickTemplateCategorySchema,
  },
  {
    alias: "deleteCategory",
    method: "delete",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/quick-template-categories/:quickTemplateCategoryId",
    response: z.void(),
  },
]);

export { api };
