import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import type { SafePromiseResult } from "@zeffiroso/utils/etude/SafePromiseResult";
import { type ElementRef, type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { Modal } from "@/components/Modal";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { AssigneeSelector } from "@/routes/Chat/components/Assignee/AssigneeSelector";
import type {
  AssigneeFormProps,
  Values,
} from "@/routes/Chat/components/Assignee/utils";
import { AssigneeEasyForm } from "@/routes/Chat/components/Assignee/utils";
import { mutationsController } from "@/routes/Chat/ui/ChatPanel/Header/mutationsController";
import { useCanAssignmentMember } from "@/routes/Chat/useCanAssignmentMember";

function memberProfilerToAssignee(
  memberProfile: CantataTypes["MemberDetail"],
): Values["assignee"] {
  if (
    memberProfile.assignmentRelationship.user &&
    memberProfile.assignmentRelationship.team
  ) {
    return {
      type: "user-in-team",
      teamId: memberProfile.assignmentRelationship.team.id,
      userId: memberProfile.assignmentRelationship.user.id,
    };
  }
  if (memberProfile.assignmentRelationship.user) {
    return {
      type: "user",
      userId: memberProfile.assignmentRelationship.user.id,
    };
  }
  if (memberProfile.assignmentRelationship.team) {
    return {
      type: "team",
      teamId: memberProfile.assignmentRelationship.team.id,
    };
  }
  return {
    type: "unassigned",
  };
}

const AssignForm = forwardRef<
  ElementRef<typeof AssigneeEasyForm>,
  Omit<
    ComponentProps<typeof AssigneeEasyForm>,
    | "form"
    | "initialValues"
    | "size"
    | "onFinish"
    | "onValuesChange"
    | "disabled"
  > & {
    onSettled?: (params: {
      assignee: Values["assignee"];
      result: Awaited<
        SafePromiseResult<
          Awaited<
            ReturnType<
              ReturnType<
                typeof mutationsController.useContext
              >["assignMutation"]["mutateAsync"]
            >
          >
        >
      >;
      easyForm: ReturnType<typeof AssigneeEasyForm.useForm>[0];
    }) => void;
  }
>(function AssignForm({ onSettled, ...props }, ref) {
  const [easyForm, form] = AssigneeEasyForm.useForm();
  const canAssignmentMember = useCanAssignmentMember();
  const member = memberQueriesContext.useMember();
  const mutations = mutationsController.useContext();
  const onFinish = useHandler<AssigneeFormProps["onFinish"]>(
    async function onFinish(values) {
      if (!values.assignee) return;
      const assignee = values.assignee;
      const result = await safePromise(() =>
        mutations.assignMutation.mutateAsync(
          assignee.type === "unassigned"
            ? {
                assignType: "unassigned",
                assignmentRelationship: {
                  userId: null,
                  teamId: null,
                },
              }
            : {
                assignType: null,
                assignmentRelationship: {
                  userId: !("userId" in assignee) ? null : assignee.userId,
                  teamId: !("teamId" in assignee) ? null : assignee.teamId,
                },
              },
        ),
      );
      onSettled?.({
        assignee,
        result,
        easyForm,
      });
    },
  );
  const mergeFormDisabled = useMergeFormDisabled();
  const disabled: AssigneeFormProps["disabled"] = mergeFormDisabled(
    mutations.isLoading || !canAssignmentMember.exec({ member }),
  );
  const assignee = useMemo<Values["assignee"]>(
    () => memberProfilerToAssignee(member),
    [member],
  );
  const initialValues: Values = useMemo<Values>(
    () => ({
      assignee,
    }),
    [assignee],
  );
  return (
    <AssigneeEasyForm
      {...props}
      initialValues={initialValues}
      form={form}
      size="small"
      onFinish={onFinish}
      disabled={disabled}
      ref={ref}
    />
  );
});

const AssigneeItem = forwardRef<
  ElementRef<typeof AssigneeSelector>,
  ComponentProps<typeof AssigneeSelector>
>(function Assignee(props, ref) {
  const member = memberQueriesContext.useMember();
  return (
    <AssigneeEasyForm.Item name="assignee" noStyle>
      <AssigneeSelector {...props} assignType={member.assignType} ref={ref} />
    </AssigneeEasyForm.Item>
  );
});

const AssigneeInstantSelect: FC = () => {
  return (
    <AssignForm onSettled={({ easyForm }) => easyForm.controller.resetFields()}>
      <AssigneeEasyForm.UseEasyForm
        render={(easyForm) => (
          <AssigneeItem onChange={easyForm.controller.submit} />
        )}
      />
    </AssignForm>
  );
};

const AssigneeModal: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <AssignForm
      onSettled={({ easyForm, result }) => {
        if (result.isSuccess) {
          easyForm.controller.resetFields();
          onClose();
          // TODO: success message
        }
      }}
    >
      <AssigneeEasyForm.UseEasyForm
        render={(easyForm) => (
          <Modal
            open
            destroyOnClose
            onCancel={onClose}
            onOk={easyForm.controller.submit}
            okText={t("common.confirm")}
            title={t("chat.action.assign.modal.title")}
          >
            <AssigneeItem />
          </Modal>
        )}
      />
    </AssignForm>
  );
};

const Assignee = {
  InstantSelect: AssigneeInstantSelect,
  Modal: AssigneeModal,
};

export { Assignee };
