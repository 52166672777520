import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";

import { Form } from "@/components/Form";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { GencSelect } from "@/resources/genc/GencSelect";
import { cssSelect } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/cssSelect";

const CountryField = () => {
  const profile = memberQueriesContext.useMember();
  const form = Form.useFormInstance();

  const onBlur = useHandler(function onBlur() {
    form.submit();
  });

  return (
    <Form.Item
      name="country"
      noStyle
      getValueProps={(v) => ({ value: v ?? profile.country })}
    >
      <GencSelect
        allowClear={false}
        variant="borderless"
        css={css([
          cssSelect,
          {
            width: "100%",
          },
        ])}
        onBlur={onBlur}
      />
    </Form.Item>
  );
};

export { CountryField };
