import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { DividerProps as AntDividerProps } from "antd";
import { Divider as AntDivider } from "antd";
import type { FC } from "react";

import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css`
    @layer emotion-component {
      & {
        border-color: ${theme.colors.neutral003};
        margin-block: 0;
      }
    }
  `,
});

type DividerProps = AntDividerProps;

const Divider: FC<DividerProps> = (props) => {
  return <AntDivider css={styles.root} {...props} />;
};

export { Divider };
export type { DividerProps };
