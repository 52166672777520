import { browserTracingIntegration } from "@sentry/browser";
import { init, replayIntegration } from "@sentry/react";
import { SENTRY_DSN, SENTRY_RELEASE, SITE } from "@zeffiroso/env";
import { escapeRegExp } from "lodash-es";

if (import.meta.env.PROD) {
  init({
    dsn: SENTRY_DSN,
    environment: SITE,
    release: SENTRY_RELEASE,
    ignoreErrors: [
      /**
       * This is a workaround for [the issue](https://sentry.io/organizations/crescendo-lab/issues/3330015257/?project=6298602)
       * See: https://stackoverflow.com/a/50387233, https://stackoverflow.com/a/50387233
       */
      "ResizeObserver loop limit exceeded",
      /**
       * It can be safely ignored as it comes from the safari extension
       * See: https://github.com/getsentry/relay/pull/2088, https://stackoverflow.com/a/74216043
       */
      /.*@webkit-masked-url.*/,

      // Axios cancel is not an unexpected error.
      /^CanceledError: /,

      // Axios known errors.
      /^AxiosError: Request failed with status code \d{3}$/,
      ...["AxiosError: Network Error", "AxiosError: timeout exceeded"].map(
        (message) => new RegExp(`^${escapeRegExp(message)}$`),
      ),

      // Firebase auth known errors.
      ...[
        "FirebaseError: Firebase: Error (auth/popup-closed-by-user).",
        "FirebaseError: Firebase: Error (auth/cancelled-popup-request).",
        "FirebaseError: Firebase: Error (auth/popup-blocked).",
        // https://crescendo-lab.sentry.io/issues/5347909036/?alert_rule_id=15184932&alert_type=issue&notification_uuid=838b605c-a91f-4cdc-97e5-e46ab4013487&project=6298602
        "FirebaseError: Firebase: Error (auth/network-request-failed).",
      ].map((message) => new RegExp(`^${escapeRegExp(message)}$`)),
    ],
    integrations: [browserTracingIntegration(), replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    /**
     * To disable truncation, set the `maxValueLength` to `Infinity`. The
     * default value is 250, which is often insufficient for ZodError.
     */
    maxValueLength: Number.POSITIVE_INFINITY,
  });
}
