import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import classNames from "classnames";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type ReactNode,
  useMemo,
} from "react";

import { defineStyles } from "@/shared/emotion";

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface CardOwnProps {
  /**
   * The component used for the root node.
   */
  component?: ElementType;
  /**
   * The content of the component.
   */
  children?: ReactNode;
}

interface CardTypeMap<
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & CardOwnProps;
  defaultComponent: RootComponent;
}

type CardProps<
  RootComponent extends ElementType = CardTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = CardOwnProps &
  OverrideProps<CardTypeMap<AdditionalProps, RootComponent>, RootComponent> & {
    component?: ElementType;
  };

const seed = random();
const classNamePrefix = `card-${seed}-`;
const cssVarNamePrefix = `--${classNamePrefix}`;
const cssVarNameRecord = {
  paddingBlock: `${cssVarNamePrefix}padding-block`,
} satisfies Record<string, string>;

const classNameRecord = {
  noPaddingCardContent: `${classNamePrefix}no-padding-content`,
} satisfies Record<string, string>;

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    border: `1px solid ${theme.colors.neutral003}`,
    borderRadius: "12px",
    background: theme.colors.white,
    color: theme.colors.neutral010,
    [cssVarNameRecord.paddingBlock]: "16px",
  }),
  content: css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "stretch",
    gap: 8,
    paddingBlock: `var(${cssVarNameRecord.paddingBlock})`,
    paddingInline: "24px",
  }),
});

const cssCard = css([styles.root, styles.content]);

/**
 * [Card
 * component](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek/Insights?node-id=2478-26709)
 *
 * If you want to use the card without padding to put dividers inside, use
 * `Card.NoPadding` instead.
 */
const Card: OverridableComponent<CardTypeMap> = forwardRef(function Card(
  { component: Component = defaultComponent, children, ...props }: CardProps,
  ref: ForwardedRef<ElementRef<typeof Component>>,
) {
  return (
    <Component css={cssCard} {...props} ref={ref}>
      {children}
    </Component>
  );
}) as OverridableComponent<CardTypeMap>;

assignDisplayName(Card, "Card");

const noPaddingCardContentDefaultComponent = "div";
type NoPaddingCardContentDefaultComponent =
  typeof noPaddingCardContentDefaultComponent;

interface NoPaddingCardContentOwnProps {
  /**
   * The content of the component.
   */
  children?: ReactNode;
  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface NoPaddingCardContentTypeMap<
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
  RootComponent extends ElementType = NoPaddingCardContentDefaultComponent,
> {
  props: AdditionalProps & NoPaddingCardContentOwnProps;
  defaultComponent: RootComponent;
}

type NoPaddingCardContentProps<
  RootComponent extends
    ElementType = NoPaddingCardContentTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = NoPaddingCardContentOwnProps &
  OverrideProps<
    NoPaddingCardContentTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

/**
 * A card without padding.
 *
 * Use this component when you want to put dividers inside the card.
 *
 * ```tsx
 * <Card.NoPadding>
 *   <Card.NoPadding.Content>
 *     ...
 *   </Card.NoPadding.Content>
 *   <Divider />
 *   <Card.NoPadding.Content>
 *     ...
 *   </Card.NoPadding.Content>
 * </Card.NoPadding>
 * ```
 */
const NoPaddingCard: OverridableComponent<CardTypeMap> = forwardRef(
  function NoPaddingCard(
    { component: Component = defaultComponent, children, ...props }: CardProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    return (
      <Component css={styles.root} {...props} ref={ref}>
        {children}
      </Component>
    );
  },
) as OverridableComponent<CardTypeMap>;

assignDisplayName(NoPaddingCard, "Card.NoPadding");

/**
 * The content of the card without padding.
 */
const NoPaddingCardContent: OverridableComponent<NoPaddingCardContentTypeMap> =
  forwardRef(function NoPaddingCardContent(
    {
      component: Component = noPaddingCardContentDefaultComponent,
      children,
      ...props
    }: NoPaddingCardContentProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const className = useMemo(
      () => classNames(classNameRecord.noPaddingCardContent, props.className),
      [props.className],
    );
    return (
      <Component
        css={styles.content}
        {...props}
        className={className}
        ref={ref}
      >
        {children}
      </Component>
    );
  }) as OverridableComponent<NoPaddingCardContentTypeMap>;

assignDisplayName(NoPaddingCardContent, "Card.NoPadding.Content");

const api = Object.assign(Card, {
  NoPadding: Object.assign(NoPaddingCard, { Content: NoPaddingCardContent }),
  classNameRecord,
  cssVarNameRecord,
});

export { api as Card, defaultComponent, NoPaddingCard, NoPaddingCardContent };

export type {
  CardOwnProps,
  CardProps,
  CardTypeMap,
  NoPaddingCardContentOwnProps,
  NoPaddingCardContentProps,
  NoPaddingCardContentTypeMap,
};
