import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { Markdown } from "@/components/Markdown";
import { useMessage } from "@/components/message";
import { Modal } from "@/components/Modal";
import { defineStyles } from "@/shared/emotion";
import { DeleteOutlined } from "@/shared/icons/common/DeleteOutlined";

const styles = defineStyles({
  teamDeleteModal: css({
    "& .ant-modal-body": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 16,
      "&>*": {
        width: "100%",
      },
    },
  }),
  desc: css({
    fontSize: "1rem",
  }),
  affectedAutoAssignmentRules: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 8,
    fontSize: "0.875rem",
    "&>*": {
      width: "100%",
      paddingLeft: 20,
      display: "flex",
      alignItems: "center",
      gap: 12,
      "&::before": {
        content: "''",
        width: 4,
        height: 20,
        backgroundColor: theme.colors.yellow006,
      },
    },
  }),
});

namespace TeamDeleteModal {
  export interface Props {
    open: boolean;
    onClose?: () => void;
    teamId: number;
    teamName: string;
  }
}

const TeamDeleteModal: FC<TeamDeleteModal.Props> = ({
  open,
  onClose,
  teamId,
  teamName,
}) => {
  const { t } = useTranslation();
  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const { isLoading, mutate } = cantata.team.useDeleteById(
    {
      params: {
        orgId,
        teamId,
      },
    },
    {
      onSuccess() {
        message.success(t("team.listPage.deleteTeam.success", { teamName }));
        onClose?.();
      },
    },
  );

  const assignmentRulesQuery = cantata.autoAssignment.useList(
    { params: { orgId } },
    {
      enabled: open,
    },
  );

  const affectedAutoAssignmentRules = useMemo(() => {
    return !assignmentRulesQuery.isSuccess
      ? []
      : assignmentRulesQuery.data.autoAssignmentRules.filter(
          (rule) =>
            rule.assignee.type === "a-team" && rule.assignee.teamId === teamId,
        );
  }, [
    assignmentRulesQuery.data?.autoAssignmentRules,
    assignmentRulesQuery.isSuccess,
    teamId,
  ]);

  return (
    <Modal
      css={styles.teamDeleteModal}
      title={t("team.listPage.deleteTeam.title", { teamName })}
      open={open}
      onCancel={onClose}
      cancelText={t("common.cancel")}
      okButtonProps={{
        danger: true,
        type: "primary",
        loading: isLoading,
        disabled: isLoading,
        ...{
          "data-test": "confirm-delete-team-button",
        },
      }}
      onOk={() => mutate(undefined)}
    >
      <Markdown
        css={styles.desc}
        markdown={t("team.listPage.deleteTeam.desc", { teamName })}
      />
      <div>
        {assignmentRulesQuery.isLoading ? (
          <BarLoadingBlock />
        ) : assignmentRulesQuery.isError ? (
          <Alert
            type="error"
            message={inspectMessage`query error: ${assignmentRulesQuery.error}`}
          />
        ) : affectedAutoAssignmentRules.length === 0 ? null : (
          <ul css={styles.affectedAutoAssignmentRules}>
            {affectedAutoAssignmentRules.map((rule) => (
              <li key={rule.id}>
                {t(
                  "team.listPage.deleteTeam.affectedRoutingRule.list.item.desc",
                  {
                    routingRuleName: rule.name,
                  },
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Modal>
  );
};

namespace DeleteButton {
  export interface Props {
    teamId: number;
    teamName: string;
  }
}

export const DeleteButton: FC<DeleteButton.Props> = ({ teamId, teamName }) => {
  const [open, toggle] = useSwitch(false);

  return (
    <>
      <NarrowIconButton
        data-test={`delete-team-button-${teamId}`}
        disabled={open}
        onClick={toggle.on}
        size={20}
        icon={<DeleteOutlined />}
      />
      <TeamDeleteModal
        open={open}
        onClose={toggle.off}
        teamId={teamId}
        teamName={teamName}
      />
    </>
  );
};
