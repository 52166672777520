import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { downloadFile } from "@zeffiroso/utils/vanilla/downloadFile";
import filenamify from "filenamify/browser";
import { flow, uniqBy } from "lodash-es";
import { unparse } from "papaparse";
import {
  type FC,
  type ReactNode,
  type Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useFeatureFlag } from "@/app/featureFlag";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { HighlightedCopyInput } from "@/components/Input";
import { Modal } from "@/components/Modal";
import type { QrCode } from "@/components/QrCode";
import { downloadZip } from "@/components/QrCode/downloadZip";
import { QrCodeBlock } from "@/components/QrCode/QrCodeBlock";
import { Select, type SelectProps, type SelectRef } from "@/components/Select";
import { ChannelSelect } from "@/components/Select/ChannelSelect";
import { TeamNameById } from "@/resources/team/TeamNameById";
import { UserNameById } from "@/resources/user/UserNameById";
import { defineStyles } from "@/shared/emotion";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

function downloadCsv({
  fileName,
  assignments,
}: {
  fileName: string;
  assignments: Array<{
    name: string;
    email: string;
    link: string;
  }>;
}) {
  const csv = unparse({
    data: assignments.map((assignment) => ({
      Assignee: assignment.name,
      Email: assignment.email,
      "Assignment link": assignment.link,
    })),
    fields: ["Assignee", "Email", "Assignment link"],
  });
  const blob = new Blob([csv], {
    type: "text/csv",
  });

  downloadFile(blob, fileName);
}

namespace ParamsContext {
  export interface Props {
    orgId: CantataTypes["Org"]["id"];
    teamId: CantataTypes["Team"]["id"];
  }
}

const ParamsContext = createContext<ParamsContext.Props>({
  name: "ParamsContext",
});

function useUserAssignmentQrcodeQueries() {
  const isSalesBindingEnabled = useFeatureFlag("salesBinding");
  const options = ParamsContext.useContext();
  const channelsQuery = cantata.channel.useList(
    {
      params: {
        orgId: options.orgId,
      },
    },
    {
      select: (data) => data.channels,
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const usersQuery = cantata.user.useList(
    {
      params: {
        orgId: options.orgId,
      },
    },
    {
      select: (data) => data.users,
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const teamQrcodesQuery =
    cantata.assignmentQrcode.useListTeamAssignmentQrcodes(
      {
        params: {
          orgId: options.orgId,
          teamId: options.teamId,
        },
      },
      {
        select: (data) => data.assignmentQrcodes,
        suspense: true,
        useErrorBoundary: true,
      },
    );
  const userInTeamQrcodesQuery =
    cantata.assignmentQrcode.useListAllTeamUsersAssignmentQrcodes(
      {
        params: {
          orgId: options.orgId,
          teamId: options.teamId,
        },
      },
      {
        select: (data) => data.assignmentQrcodes,
        suspense: isSalesBindingEnabled,
        useErrorBoundary: isSalesBindingEnabled,
        enabled: isSalesBindingEnabled,
      },
    );
  const queries = useMemo(
    () => ({
      users: usersQuery,
      channels: channelsQuery,
      teamQrcodes: teamQrcodesQuery,
      userInTeamQrcodes: userInTeamQrcodesQuery,
    }),
    [usersQuery, channelsQuery, teamQrcodesQuery, userInTeamQrcodesQuery],
  );
  return queries;
}

const UserAssignmentQrcodeQueries =
  createQueriesContext<ReturnType<typeof useUserAssignmentQrcodeQueries>>();

const QueriesContextProvider: FC<{
  children: ReactNode;
}> = (props) => {
  const queries = useUserAssignmentQrcodeQueries();
  return (
    <UserAssignmentQrcodeQueries.Provider queries={queries}>
      {props.children}
    </UserAssignmentQrcodeQueries.Provider>
  );
};

const styles = defineStyles({
  modalContent: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 16,
    "&>*": {
      width: "100%",
    },
  }),
  descriptionBlock: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "&>*": {
      width: "100%",
    },
  }),
  title: css({
    marginBottom: 8,
    color: theme.colors.neutral009,
    fontSize: "0.875rem",
    fontWeight: 500,
  }),
  desc: css({
    marginBottom: 16,
    color: theme.colors.neutral007,
    fontSize: "0.875rem",
    fontWeight: 400,
  }),
});

const assigneeTeam = "team";
const assigneeTeamUsers = "teamUser";

namespace AssigneeSelector {
  export type ValueType = typeof assigneeTeam | typeof assigneeTeamUsers;
  export interface Props
    extends Omit<SelectProps<ValueType>, "mode" | "options"> {}
  export interface Ref extends SelectRef {}
}

const AssigneeSelector = forwardRef<
  AssigneeSelector.Ref,
  AssigneeSelector.Props
>(function AssigneeSelector(props, ref) {
  const params = ParamsContext.useContext();
  const { t } = useTranslation();
  const nullableTeamName = TeamNameById.useTeamNameById({
    orgId: params.orgId,
    teamId: params.teamId,
    queryOptions: {
      suspense: true,
      useErrorBoundary: true,
    },
  });
  const teamName = nullableTeamName ?? EMPTY_STRING_PLACEHOLDER;
  const options = useMemo<
    NonNullable<SelectProps<AssigneeSelector.ValueType>["options"]>
  >(
    () => [
      {
        label: teamName,
        value: assigneeTeam,
      },
      {
        label: t(
          "resource.assignmentQrcode.assigneeSelector.option.allTeamUsers.label",
          {
            teamName,
          },
        ),
        value: assigneeTeamUsers,
      },
    ],
    [t, teamName],
  );
  const value = useMemo(
    () =>
      options.some((option) => option.value === props.value)
        ? props.value
        : assigneeTeam,
    [options, props.value],
  );
  return (
    <Select<AssigneeSelector.ValueType>
      {...props}
      options={options}
      value={value}
      ref={ref}
    />
  );
});

const UserAssignmentQrcodeStyles = defineStyles({
  UserAssignmentQrcode: css({
    display: "flex",
    gap: 10,
    alignItems: "stretch",
  }),
  info: css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
    gap: 4,
  }),
  qrcode: css({
    width: 80,
  }),
  assignee: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral008,
  }),
  email: css({
    fontSize: "0.75rem",
    color: theme.colors.neutral007,
  }),
});

namespace UserAssignmentQrcode {
  export interface Props {
    userId: CantataTypes["User"]["id"];
    email: string;
    assignmentLink: string;
  }
}

const UserAssignmentQrcode: FC<UserAssignmentQrcode.Props> = ({
  userId,
  email,
  assignmentLink,
}) => {
  const params = ParamsContext.useContext();
  return (
    <div css={UserAssignmentQrcodeStyles.UserAssignmentQrcode}>
      <QrCodeBlock
        css={UserAssignmentQrcodeStyles.qrcode}
        text={assignmentLink}
      />
      <div css={UserAssignmentQrcodeStyles.info}>
        <div css={UserAssignmentQrcodeStyles.assignee}>
          <TeamNameById orgId={params.orgId} teamId={params.teamId} />
          {" - "}
          <UserNameById userId={userId} />
        </div>
        <div css={UserAssignmentQrcodeStyles.email}>{email}</div>
        <HighlightedCopyInput value={assignmentLink} />
      </div>
    </div>
  );
};

namespace Content {
  export type ImperativeHandle =
    | {
        downloadSingle: null | (() => void);
      }
    | {
        downloadZip: null | (() => Promise<void>);
        downloadCsv: null | (() => Promise<void>);
      };
  export interface Props
    extends Omit<ComponentProps<typeof Modal>, "children" | "footer"> {
    imperativeHandleRef?: Ref<ImperativeHandle | null>;
  }
}

const Content: FC<Content.Props> = ({ imperativeHandleRef }) => {
  const isSalesBindingEnabled = useFeatureFlag("salesBinding");
  const { t } = useTranslation();
  const params = ParamsContext.useContext();
  const nullableTeamName = TeamNameById.useTeamNameById({
    orgId: params.orgId,
    teamId: params.teamId,
    queryOptions: {
      suspense: true,
      useErrorBoundary: true,
    },
  });
  const teamName = nullableTeamName ?? EMPTY_STRING_PLACEHOLDER;
  const { users, channels, teamQrcodes, userInTeamQrcodes } =
    UserAssignmentQrcodeQueries.useData();
  const localeCompare = useLocaleCompare();
  const [assignee, setAssignee] =
    useState<AssigneeSelector.ValueType>(assigneeTeam);

  /**
   * Filters out channels that don't have a corresponding QR code.
   */
  const getAvailableChannels = useCallback(
    function getAvailableChannels(assignee: AssigneeSelector.ValueType) {
      return channels.filter((channel) =>
        assignee === assigneeTeam
          ? teamQrcodes.some(
              (qrcode) => qrcode.channel.id === channel.id && qrcode.qrcodeUrl,
            )
          : userInTeamQrcodes.some(
              (qrcode) => qrcode.channel.id === channel.id && qrcode.qrcodeUrl,
            ),
      );
    },
    [channels, userInTeamQrcodes, teamQrcodes],
  );

  const onAssigneeChange = useHandler<AssigneeSelector.Props["onChange"]>(
    (value) => {
      setAssignee(value);

      const nextChannels = getAvailableChannels(value);
      // If the current channel is in the team, keep it.
      if (nextChannels.some((channel) => channel.id === channelId)) return;

      // Select the first channel with QR code in the team by default.
      setChannelId(nextChannels[0]?.id ?? Number.NaN);
    },
  );

  const channelsWithQrCode = useMemo(
    () =>
      getAvailableChannels(assignee).toSorted((a, b) =>
        localeCompare(a.name, b.name),
      ),
    [assignee, getAvailableChannels, localeCompare],
  );

  const [channelId, setChannelId] = useState<CantataTypes["Channel"]["id"]>(
    /**
     * Select the first channel with QR code by default.
     */
    channelsWithQrCode[0]?.id ?? Number.NaN,
  );
  const channel = useMemo<
    undefined | NonNullable<typeof channels>[number]
  >(() => {
    if (!channelId) return undefined;
    return channelsWithQrCode.find((channel) => channel.id === channelId);
  }, [channelId, channelsWithQrCode]);
  const showQRCode = !Number.isNaN(channelId);
  const [qrCodeImperativeHandle, setQrCodeImperativeHandle] =
    useState<QrCode.ImperativeHandle | null>(null);
  const teamAssignmentUrl = useMemo(() => {
    if (!channelId) return [];
    return teamQrcodes.find((qrCode) => qrCode.channel.id === channelId)
      ?.qrcodeUrl;
  }, [channelId, teamQrcodes]);
  const teamUserAssignments = useMemo(() => {
    if (!channelId) return [];
    return flow(
      () =>
        userInTeamQrcodes.flatMap((userQrcode) => {
          if (userQrcode.channel.id !== channelId) return [];
          if (!userQrcode.qrcodeUrl) return [];
          const user = users.find((user) => user.id === userQrcode.userId);
          if (!user) return [];
          if (!user.email) return [];
          return {
            userId: user.id,
            email: user.email,
            qrcodeUrl: userQrcode.qrcodeUrl,
          };
        }),
      (items) =>
        uniqBy(items, (item) => item.email).toSorted((a, b) =>
          localeCompare(a.email, b.email),
        ),
    )();
  }, [channelId, localeCompare, userInTeamQrcodes, users]);

  /**
   * useMemo is required to prevent infinite loop.
   */
  const imperativeHandle = useMemo<Content.ImperativeHandle>(
    () =>
      assignee === assigneeTeam
        ? {
            downloadSingle:
              !qrCodeImperativeHandle || !channel
                ? null
                : () => qrCodeImperativeHandle.download(channel.name),
          }
        : {
            downloadZip:
              teamUserAssignments.length === 0
                ? null
                : async () => {
                    await downloadZip({
                      inputs: teamUserAssignments.map((userQrcode) => ({
                        text: userQrcode.qrcodeUrl,
                        path: `${
                          filenamify(userQrcode.email) ||
                          EMPTY_STRING_PLACEHOLDER
                        }.png`,
                      })),
                      output: {
                        fileName: `${teamName}_teamMembers_QR_codes.zip`,
                      },
                    });
                  },
            downloadCsv:
              teamUserAssignments.length === 0
                ? null
                : async () => {
                    downloadCsv({
                      fileName: `${teamName}_teamMembers_QR_urls.csv`,
                      assignments: teamUserAssignments.map((userQrcode) => ({
                        name: userQrcode.email,
                        email: userQrcode.email,
                        link: userQrcode.qrcodeUrl,
                      })),
                    });
                  },
          },
    [assignee, channel, qrCodeImperativeHandle, teamName, teamUserAssignments],
  );

  useImperativeHandle(imperativeHandleRef, () => imperativeHandle, [
    imperativeHandle,
  ]);

  return (
    <div css={styles.modalContent}>
      {!isSalesBindingEnabled ? null : (
        <div css={styles.descriptionBlock}>
          <div css={styles.title}>
            {t("resource.assignmentQrcode.assigneeSelector.label")}
          </div>
          <AssigneeSelector value={assignee} onChange={onAssigneeChange} />
        </div>
      )}
      <div css={styles.descriptionBlock}>
        <div css={styles.title}>{t("myProfile.qrCodeChannel")}</div>
        <div css={styles.desc}>{t("myProfile.qrCodeModal.desc")}</div>
        <ChannelSelect
          channels={channelsWithQrCode}
          value={channel?.id ?? Number.NaN}
          onChange={setChannelId}
          placeholder={t("myProfile.selectChannel")}
        />
      </div>
      {!showQRCode ? null : assignee === assigneeTeam ? (
        !teamAssignmentUrl ? (
          <Alert
            type="error"
            message="LINE channel's line id is not provide."
          />
        ) : (
          <>
            <QrCodeBlock
              style={{ width: 160, margin: "auto" }}
              text={teamAssignmentUrl}
              imperativeHandleRef={setQrCodeImperativeHandle}
            />
            <HighlightedCopyInput value={teamAssignmentUrl} />
          </>
        )
      ) : (
        teamUserAssignments.map((userQrcode) => (
          <UserAssignmentQrcode
            key={userQrcode.email}
            userId={userQrcode.userId}
            email={userQrcode.email}
            assignmentLink={userQrcode.qrcodeUrl}
          />
        ))
      )}
    </div>
  );
};

namespace TeamAssignmentQrcodeModal {
  export interface Props
    extends Overwrite<
      Omit<ComponentProps<typeof Modal>, "children" | "footer">,
      ParamsContext.Props
    > {}
}

type InferRef<T> = T extends Ref<infer R> ? R : never;

const TeamAssignmentQrcodeModal: FC<TeamAssignmentQrcodeModal.Props> = ({
  orgId,
  teamId,
  ...props
}) => {
  const params = useMemo(
    () => ({
      orgId,
      teamId,
    }),
    [orgId, teamId],
  );
  const { t } = useTranslation();
  const [contentImperativeHandle, setContentImperativeHandle] =
    useState<InferRef<NonNullable<Content.Props["imperativeHandleRef"]>>>(null);

  const open = useMemo(
    () =>
      !teamId
        ? /**
           * If `userId` is falsy, modal is closed.
           */
          false
        : "open" in props
          ? /**
             * Props `open` overrides default behavior.
             */
            props.open
          : /**
             * Default behavior is to open the modal if `userId` is truthy.
             */
            Boolean(teamId),
    [props, teamId],
  );

  return (
    <ParamsContext.Provider value={params}>
      <Modal
        title={t("myProfile.createQRCode")}
        destroyOnClose
        {...props}
        open={open}
        onOk={
          !contentImperativeHandle
            ? undefined
            : !("downloadSingle" in contentImperativeHandle)
              ? undefined
              : contentImperativeHandle.downloadSingle ?? undefined
        }
        okText={t("myProfile.downloadQRCode")}
        okButtonProps={
          contentImperativeHandle
            ? {
                disabled:
                  !contentImperativeHandle ||
                  ("downloadSingle" in contentImperativeHandle &&
                    !contentImperativeHandle.downloadSingle),
              }
            : {
                style: {
                  display: "none",
                },
              }
        }
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        {...(!contentImperativeHandle ||
        !("downloadZip" in contentImperativeHandle)
          ? null
          : {
              footer: (
                <>
                  <Button
                    onClick={contentImperativeHandle.downloadCsv ?? undefined}
                    disabled={!contentImperativeHandle.downloadCsv}
                  >
                    {t("resource.assignmentQrcode.downloadCsv.button.label")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={contentImperativeHandle.downloadZip ?? undefined}
                    disabled={!contentImperativeHandle.downloadZip}
                  >
                    {t("resource.assignmentQrcode.downloadZip.button.label")}
                  </Button>
                </>
              ),
            })}
      >
        <ErrorBoundary.Alert>
          <QueriesContextProvider>
            <Content imperativeHandleRef={setContentImperativeHandle} />
          </QueriesContextProvider>
        </ErrorBoundary.Alert>
      </Modal>
    </ParamsContext.Provider>
  );
};

export { TeamAssignmentQrcodeModal };
