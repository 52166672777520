import { LoadingOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { useMutation } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
import type { TagProps as AntTagProps } from "antd";
import { Tag as AntTag } from "antd";
import { type ElementRef, useMemo } from "react";

import { cssWrap, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css([
    cssWrap,
    css`
      display: inline-flex;
      height: min-content;
      min-height: 20px;
      align-items: center;
      padding: 0 8px;
      border-color: ${theme.colors.neutral003};
      border-radius: ${theme.shape.borderRadius};
      margin: 0;
      color: ${theme.colors.neutral009};
      font-size: 0.75rem;
      line-height: normal;

      .ant-tag-close-icon {
        color: ${theme.colors.neutral006};
        font-size: 1rem;
        line-height: 1;
        transform: translateY(1px);

        &:hover {
          color: ${theme.colors.neutral009};
        }
      }
    `,
  ]),
  closeable: css({
    padding: "0 6px 0 4px",
  }),
});

type TagProps = AntTagProps;
type TagRef = ElementRef<typeof AntTag>;

/**
 * Crescendo Lab styled Tag component.
 *
 * If `loading` is `true`, the close icon will be replaced with a loading
 * spinner.
 */
const Tag = forwardRef<TagRef, TagProps>(function Tag({ ...props }, ref) {
  const mutation = useMutation({
    mutationFn: async function mutationFn(
      ...args: Parameters<NonNullable<typeof onClose>>
    ) {
      return props.onClose?.(...args);
    },
  });
  const onClose = useHandler<ComponentProps<typeof AntTag>["onClose"]>(
    function onClick(...args) {
      if (mutation.isLoading) return;
      mutation.mutateAsync(...args);
    },
  );
  const closeIcon = useMemo(() => {
    if ("closeIcon" in props) {
      return props.closeIcon;
    }
    if (mutation.isLoading) {
      return <LoadingOutlined />;
    }
    return undefined;
  }, [mutation.isLoading, props]);
  return (
    <AntTag
      css={css([styles.root, props.closable && styles.closeable])}
      {...props}
      closeIcon={closeIcon}
      onClose={onClose}
      ref={ref}
    />
  );
});

export { Tag };
export type { TagProps, TagRef };
