import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import type { FC } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { ExternalLink } from "@/components/ExternalLink";
import { MotifIcon } from "@/components/MotifIcon";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { ConversationCount } from "@/routes/Insights/Conversation/ConversationCount";
import { ConversationTrend } from "@/routes/Insights/Conversation/ConversationTrend";
import { ExportDistribution } from "@/routes/Insights/Conversation/ExportDistribution";
import { ExportTrend } from "@/routes/Insights/Conversation/ExportTrend";
import { Filters } from "@/routes/Insights/Conversation/filters";
import { NewConversationHeatmap } from "@/routes/Insights/Conversation/NewConversationHeatmap";
import { PageInfoProvider } from "@/routes/Insights/Conversation/pageInfo";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: 24,
  }),
  title: css({
    fontSize: "1.25rem",
    fontWeight: 500,
    color: theme.colors.neutral009,
  }),
  head: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 24,
  }),
  exports: css({
    display: "flex",
    alignItems: "center",
    gap: 12,
  }),
});

const OverviewHead: FC = () => {
  const { t } = useTranslation();
  return (
    <div css={styles.head}>
      <div css={styles.title}>
        <Trans i18nKey="dashboard.conversation.overview.title" />
      </div>
      <div css={styles.exports}>
        <ExternalLink
          href={t("dashboard.conversation.learnMore.link")}
          prefixIcon={<MotifIcon un-i-motif="guide_book" />}
        >
          <Trans i18nKey="dashboard.conversation.learnMore.label" />
        </ExternalLink>
        <ExportTrend />
      </div>
    </div>
  );
};

const DistributionHead: FC = () => {
  return (
    <div css={styles.head}>
      <div css={styles.title}>
        <Trans i18nKey="dashboard.conversation.distribution.title" />
      </div>
      <ExportDistribution />
    </div>
  );
};

const Conversation: FC = () => {
  return (
    <MainLayout.Paper>
      <MainLayout.Header>
        <Trans i18nKey="page.insights.conversation.title" />
      </MainLayout.Header>
      <MainLayout.Body>
        <PageInfoProvider>
          <MainLayout.Sticky>
            <Filters />
          </MainLayout.Sticky>
          <MainLayout.Content>
            <div css={styles.root}>
              <OverviewHead />
              <ConversationCount />
              <ConversationTrend />
              <DistributionHead />
              <NewConversationHeatmap />
            </div>
          </MainLayout.Content>
        </PageInfoProvider>
      </MainLayout.Body>
    </MainLayout.Paper>
  );
};

const WrappedConversation: FC = () => {
  const portal = usePortal();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Outer mainRef={portal.setOuterEl} />
      {createPortal(<Conversation />, portal.innerEl)}
    </SideMenuLayout.Layout>
  );
};

export { WrappedConversation as Conversation };
