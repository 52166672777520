import { useHandler } from "@chatbotgang/etude/react/useHandler";
import useChange from "@react-hook/change";
import { MemberSchema, MessageSchema } from "@zeffiroso/cantata/models";
import { isEqual } from "lodash-es";
import { type FC, useEffect } from "react";
import { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { LOCAL_STORAGE_CHAT_MESSAGE_EDITOR_DRAFT } from "@/appConstant";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import {
  useEasyForm,
  useMessageFieldValue,
} from "@/routes/Chat/ui/ChatPanel/Editor/Old/EasyForm";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

/**
 * Distinct schema for selecting editor draft parameters.
 */
const selectEditorDraftParametersSchema = z.object({
  memberId: MemberSchema.shape.id,
});

const EditorDraftSchema = z
  .object({
    content: MessageSchema.shape.text,
  })
  .and(selectEditorDraftParametersSchema);
type EditorDraft = z.infer<typeof EditorDraftSchema>;
type SelectEditorDraftParameters = z.infer<
  typeof selectEditorDraftParametersSchema
>;
type StoreValue = Array<EditorDraft>;

const { useStore: useDraftStore } = createZustandStorageStore<StoreValue>(
  LOCAL_STORAGE_CHAT_MESSAGE_EDITOR_DRAFT,
  function parser(input) {
    return !Array.isArray(input)
      ? []
      : input.flatMap(function parseItem(input) {
          const parsedResult = EditorDraftSchema.safeParse(input);
          return parsedResult.success ? [parsedResult.data] : [];
        });
  },
  {
    storage: sessionStorage,
  },
);

const MAX_DRAFT_SIZE = 5;

function getEditorDraftFromStoreValue({
  storeValue,
  selectEditorDraftParameters,
}: {
  storeValue: StoreValue;
  selectEditorDraftParameters: SelectEditorDraftParameters;
}): EditorDraft | undefined {
  return storeValue.find(
    (item) => selectEditorDraftParameters.memberId === item.memberId,
  );
}

function getEditorDraft(
  selectEditorDraftParameters: SelectEditorDraftParameters,
): EditorDraft | undefined {
  const state = useDraftStore.getState();
  return getEditorDraftFromStoreValue({
    storeValue: state.value,
    selectEditorDraftParameters,
  });
}

function getEditorDraftContent(
  ...args: Parameters<typeof getEditorDraft>
): undefined | EditorDraft["content"] {
  const editorDraft = getEditorDraft(...args);
  return editorDraft?.content;
}

/**
 * Clear the draft.
 */
function clear(selectEditorDraftParameters: SelectEditorDraftParameters) {
  const editorDraft = getEditorDraft(selectEditorDraftParameters);
  if (!editorDraft) return;
  useDraftStore.setState({
    value: useDraftStore
      .getState()
      .value.filter((current) => current !== editorDraft),
  });
}

useActiveOrgIdStore.subscribe(
  function clearDraftIfOrgIdChanged(current, previous) {
    if (previous.value === current.value) return;
    if (!current.value) return;
    useDraftStore.getState().clear();
  },
);

/**
 * Set the content of the draft item. The content will be saved to the local
 * storage. The newer draft item will be at the top. The size of the draft items
 * will be limited. If the size exceeds the limit, the older draft items will be
 * removed.
 */
function setContent({
  content,
  selectEditorDraftParameters,
}: {
  content: string | ((prev: string) => string);
  selectEditorDraftParameters: SelectEditorDraftParameters;
}) {
  const currentState = useDraftStore.getState();
  const currentEditorDraft = getEditorDraftFromStoreValue({
    storeValue: currentState.value,
    selectEditorDraftParameters,
  });
  const newContent =
    typeof content === "function"
      ? content(currentEditorDraft?.content ?? "")
      : content;
  const newEditorDraft: EditorDraft = {
    content: newContent,
    ...selectEditorDraftParameters,
  };
  if (isEqual(currentEditorDraft, newEditorDraft)) return;
  const nextStateValue = [
    // Newer draft item should be at the top
    newEditorDraft,
    ...currentState.value.filter(
      (item) => !(item.memberId === selectEditorDraftParameters.memberId),
    ),
  ];
  // Limit the size of draft items
  while (nextStateValue.length > MAX_DRAFT_SIZE && nextStateValue.length > 0)
    nextStateValue.pop();
  useDraftStore.setState({
    value: nextStateValue,
  });
}

const Setup: FC = () => {
  const memberId = memberIdUtils.useGet();
  const [, form] = useEasyForm();
  const messageFieldValue = useMessageFieldValue();

  /**
   * Make the callback non-reactive to prevent it from executing multiple times.
   */
  const setFieldValue = useHandler(function setFieldValue(message: string) {
    form.setFieldValue("message", message);
  });

  useEffect(
    function loadFieldValueFromStorage() {
      const editorDraft = getEditorDraft({ memberId });
      if (!editorDraft || !editorDraft.content) return;
      setFieldValue(editorDraft.content);
    },
    [memberId, setFieldValue],
  );

  useChange(
    messageFieldValue,
    function syncToStorageStoreIfFieldValueChanged(current) {
      if (typeof current !== "string") return;
      if (current.trim().length === 0) {
        clear({ memberId });
        return;
      }
      setContent({
        content: current,
        selectEditorDraftParameters: { memberId },
      });
    },
  );

  return null;
};

/**
 * Draft utilities.
 */
const editorDraftUtils = {
  clear,
  setContent,
  getEditorDraftContent,
  Setup,
};

export { editorDraftUtils };
