import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { DefaultComponentProps, OverrideProps } from "@mui/types";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
import objectInspect from "object-inspect";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type Key,
  type ReactNode,
  useMemo,
} from "react";

import {
  DisabledContextProvider,
  useMergeFormDisabled,
} from "@/components/Form/DisabledContext";
import { defineStyles } from "@/shared/emotion";

import { Chip, type ChipProps } from ".";

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

type BaseOption<TValue> = {
  display?: ReactNode;
} & (
  | {
      value: TValue & Key;
    }
  | {
      key: Key;
      value: TValue;
    }
);

/**
 * List the own props of the component.
 */
interface ChipsOwnProps<TValue = unknown, TMulti extends boolean = false> {
  /**
   * The value of the selected chip. If `multi` is `true`, it should be an
   * array.
   */
  value?: TMulti extends true ? Array<TValue> : TValue;

  /**
   * Callback when the chip is toggled. If `multi` is `true`, the value will be
   * an array. If `required` is not `true`, the value will be the
   * `unSelectedValue` when the chip is deselected.
   */
  onChange?: (value: TMulti extends true ? Array<TValue> : TValue) => void;

  /**
   * Options to display.
   */
  options: Array<BaseOption<TValue>>;

  /**
   * Multi-select mode.
   *
   * If `true`, the chips can be selected multiple times like checkboxes; if
   * `false`, the chips can be selected only once like radio buttons.
   */
  multi?: TMulti;

  /**
   * Once the amount of selected chips is more than or equal to `minSelected`,
   * the user can't deselect any chip. You can also set `minSelected` to `1` to
   * make the selection required in the single-select mode.
   */
  minSelected?: number;

  /**
   * No chip can be selected if the amount of selected chips is more than or
   * equal to `maxSelected`.
   */
  maxSelected?: number;

  /**
   * The value passed to the `onChange` callback when the chip is deselected in
   * single-select mode. Only works when `required` is not `true`.
   */
  unSelectedValue?: TValue;

  /**
   * The disabled all chips.
   */
  disabled?: boolean;

  /**
   * Props for the `Chip` component.
   */
  chipProps?: Omit<ChipProps, "checked">;

  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface ChipsTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
  TValue = unknown,
  TMulti extends boolean = false,
> {
  props: AdditionalProps & ChipsOwnProps<TValue, TMulti>;
  defaultComponent: RootComponent;
}

type ChipsProps<
  RootComponent extends ElementType = ChipsTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
  TValue = unknown,
  TMulti extends boolean = false,
> = ChipsOwnProps<TValue, TMulti> &
  OverrideProps<
    ChipsTypeMap<AdditionalProps, RootComponent, TValue, TMulti>,
    RootComponent
  > & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 8,
  }),
});

interface ChipsType {
  <C extends ElementType, TValue = unknown, TMulti extends boolean = false>(
    props: {
      /**
       * The component used for the root node.
       * Either a string to use a HTML element or a component.
       */
      component: C;
    } & OverrideWith<
      OverrideProps<ChipsTypeMap, C>,
      ChipsOwnProps<TValue, TMulti>
    >,
  ): ReactNode;
  <TValue = unknown, TMulti extends boolean = false>(
    props: OverrideWith<
      DefaultComponentProps<ChipsTypeMap>,
      ChipsOwnProps<TValue, TMulti>
    >,
  ): ReactNode;
}

/**
 * A group of chips. The chips can be single or multi-select.
 *
 * Spec: [Figma](https://www.figma.com/design/o61i5symd25csJUyfXETzv/Design-System-Motif?m=dev&node-id=3960-6099)
 */
const Chips: ChipsType = forwardRef(function Chips<
  RootComponent extends ElementType = ChipsTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
  TValue = unknown,
  TMulti extends boolean = false,
>(
  {
    value,
    multi,
    minSelected = 0,
    maxSelected = Number.POSITIVE_INFINITY,
    unSelectedValue = undefined,
    options,
    onChange,
    disabled,
    chipProps,
    component: Component = defaultComponent,
    ...props
  }: ChipsProps<RootComponent, AdditionalProps, TValue, TMulti>,
  ref: ForwardedRef<ElementRef<typeof Component>>,
) {
  type Value = TMulti extends true ? TValue[] : TValue;
  const mergeFormDisabled = useMergeFormDisabled();
  const mergedDisabled = mergeFormDisabled(disabled);
  /**
   * Value always in array form.
   */
  const arrayValue = useMemo<Array<TValue>>(() => {
    if (!Array.isArray(value)) return [value] as Array<TValue>;
    return value as Array<TValue>;
  }, [value]);
  return (
    <Component css={styles.root} {...props} ref={ref}>
      <DisabledContextProvider disabled={mergedDisabled}>
        {options.map((option) => (
          <Chip
            key={"key" in option ? option.key : option.value}
            checked={
              multi ? arrayValue.includes(option.value) : value === option.value
            }
            {...chipProps}
            onClick={(...args) => {
              chipProps?.onClick?.(...args);
              const [e] = args;
              if (e.defaultPrevented) return;
              const checked = multi
                ? arrayValue.includes(option.value)
                : value === option.value;
              if (multi) {
                if (checked) {
                  if (arrayValue.length <= minSelected) return;
                  onChange?.(
                    arrayValue.filter((item) => item !== option.value) as Value,
                  );
                  return;
                }
                if (arrayValue.length >= maxSelected) return;
                onChange?.([...arrayValue, option.value] as Value);
                return;
              }
              if (checked) {
                if (minSelected >= 1) return;
                onChange?.(unSelectedValue as Value);
                return;
              }
              onChange?.(option.value as Value);
            }}
          >
            {"display" in option
              ? option.display
              : typeof option.value === "string" ||
                  typeof option.value === "number"
                ? option.value
                : objectInspect(option.value)}
          </Chip>
        ))}
      </DisabledContextProvider>
    </Component>
  );
}) as ChipsType;

assignDisplayName(Chips, "Chips");

export { Chips, defaultComponent };

export type { BaseOption, ChipsOwnProps, ChipsProps, ChipsTypeMap };
