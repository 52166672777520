function generateAbortError(options?: { cause?: unknown }) {
  try {
    const abortController = new AbortController();
    abortController.abort();
    abortController.signal.throwIfAborted();
  } catch (error) {
    if (error instanceof Error) {
      if (options?.cause) {
        error.cause = options.cause;
      }
    }
    return error;
  }
  throw new Error("Unreachable");
}

export { generateAbortError };
