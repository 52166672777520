import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import type { ElementRef, ElementType, ForwardedRef, ReactNode } from "react";

import { ResultListItem } from "@/components/ResultList/ResultListItem";
import { defineStyles } from "@/shared/emotion";

const defaultRootElement = "ul";

const styles = defineStyles({
  ResultList: css({
    margin: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 8,
    fontSize: "0.875rem",
  }),
});

namespace ResultList {
  export type DefaultRootElement = typeof defaultRootElement;
  export interface BaseRootElementProps
    extends Pick<ComponentProps<DefaultRootElement>, "className"> {}
  export type BaseRootElementType = ElementType<BaseRootElementProps>;
  export interface OwnProps {
    component?: BaseRootElementType;
  }
  export type Props<
    TRootElement extends BaseRootElementType = DefaultRootElement,
  > = Overwrite<ComponentProps<TRootElement>, OwnProps>;
  export interface Type {
    <TRootElement extends BaseRootElementType = DefaultRootElement>(
      props: Overwrite<
        Props<TRootElement>,
        {
          component: TRootElement;
        }
      >,
      ref?: ElementRef<TRootElement>,
    ): ReactNode;
    (
      props: DistributiveOmit<Props<DefaultRootElement>, "component">,
      ref?: ElementRef<DefaultRootElement>,
    ): ReactNode;
  }
}

const ResultListInternal: ResultList.Type = forwardRef(
  function ResultListInternal(
    {
      component: Component = defaultRootElement,
      ...props
    }: ResultList.OwnProps,
    ref?: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    return (
      <Component
        css={styles.ResultList}
        {...props}
        {...(!ref ? null : ({ ref } as Record<never, unknown>))}
      />
    );
  },
) as unknown as ResultList.Type;

/**
 * A styled list component that displays results.
 *
 * Since this component has not yet been standardized in our design system, you
 * can refer to the designs
 * [here](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=6962-28194&m=dev)
 * and
 * [here](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=6947-27694&m=dev).
 */
const ResultList = Object.assign(ResultListInternal, {
  defaultRootElement,
  styles,
  Item: ResultListItem,
});

assignDisplayName(ResultList, "ResultList");

export { ResultList };
