import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { memo } from "@chatbotgang/etude/react/memo";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { merge, omit } from "lodash-es";
import type { CSSProperties } from "react";
import { useMemo } from "react";
import type { O } from "ts-toolbelt";

import type { CantataTypes } from "@/cantata/types";
import FacebookSvg from "@/shared/icons/logos/facebookLogo.svg";
import InstagramSvg from "@/shared/icons/logos/instagramLogo.svg";
import LineSvg from "@/shared/icons/logos/lineLogo.svg";
import MessengerSvg from "@/shared/icons/logos/messengerLogo.svg";
import WhatsAppSvg from "@/shared/icons/logos/whatsAppLogo.svg";

type Options = {
  /**
   * Some channel type like facebook has two different icons for chatting and
   * other features. This option is for switching between them.
   */
  chatting?: boolean;
};

const defaultOptions: Options = {
  chatting: false,
};

function getMap(options?: O.Partial<Options, "deep">) {
  const mergedOptions: Options = merge({}, defaultOptions, options);
  return {
    line: LineSvg,
    fb: mergedOptions.chatting ? MessengerSvg : FacebookSvg,
    ig: InstagramSvg,
    whatsApp: WhatsAppSvg,
  };
}

function withChannelLogo(
  channelType: CantataTypes["Channel"]["type"],
  options?: O.Partial<Options, "deep">,
) {
  const map = getMap(options);
  return map[channelType];
}

const cssStaticImageVariableName = `--logo-image-${random()}`;

const baseCss = css({
  aspectRatio: 1,
  height: "1em",
  width: "1em",
});

const defaultCss = css`
  background-image: var(${cssStaticImageVariableName});
  background-repeat: no-repeat;
  background-size: contain;
`;

const disconnectedCss = css`
  background-color: ${theme.colors.neutral006};
  mask-image: var(${cssStaticImageVariableName});
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
`;

type ChannelTypeIconProps = (
  | { channelType: CantataTypes["Channel"]["type"]; on?: boolean }
  | { channel: Pick<CantataTypes["Channel"], "status" | "type"> }
) &
  ComponentProps<"div"> & {
    options?: O.Partial<Options, "deep">;
  };

/**
 * Use this channel type logo for features other than chatting
 */

const ChannelTypeIcon = memo<ChannelTypeIconProps>(function ChannelTypeIcon({
  options,
  style,
  ...props
}) {
  const channelType = useMemo(
    function getChannelType() {
      return "channelType" in props ? props.channelType : props.channel.type;
    },
    [props],
  );
  const on = useMemo(
    function getOn() {
      return "channel" in props
        ? props.channel.status === "connected"
        : "on" in props
          ? props.on
          : true;
    },
    [props],
  );

  const staticImageStyle = useMemo(
    function getStaticImageStyle(): CSSProperties {
      const staticImage = withChannelLogo(channelType, options);
      return {
        [cssStaticImageVariableName]: `url("${CSS.escape(staticImage)}")`,
      };
    },
    [options, channelType],
  );

  const elementProps = omit(props, ["channel", "channelType", "on"]);
  return (
    <div
      role="img"
      style={{ ...style, ...staticImageStyle }}
      css={[baseCss, on ? defaultCss : disconnectedCss]}
      {...elementProps}
    />
  );
});

type ChannelTypeChattingIconProps = ChannelTypeIconProps;

/**
 * Use this channel type logo for chatting features
 */
const ChannelTypeChattingIcon = memo<ChannelTypeChattingIconProps>(
  function ChannelTypeChattingIcon(props) {
    const mergedOptions: Options = useMemo(
      function mergeOptions() {
        return merge(
          {},
          {
            chatting: true,
          },
          props.options,
        );
      },
      [props.options],
    );
    return <ChannelTypeIcon {...props} options={mergedOptions} />;
  },
);

export { ChannelTypeChattingIcon, ChannelTypeIcon };
export type { ChannelTypeChattingIconProps, ChannelTypeIconProps };
