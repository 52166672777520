import { StarFilled, StarOutlined } from "@ant-design/icons";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { memberIdUtils } from "@/resources/member/memberIdUtils";

const hasStarMark = css`
  svg {
    color: ${theme.colors.yellow006};

    &:hover {
      color: ${theme.colors.yellow005};
    }

    &:focus {
      color: ${theme.colors.yellow007};
    }
  }
`;

const noStarMark = css`
  svg {
    color: ${theme.colors.neutral007};

    &:hover {
      color: ${theme.colors.yellow005};
    }

    &:focus {
      color: ${theme.colors.yellow007};
    }
  }
`;

export const MemberStar = memo(function MemberStar() {
  const memberId = memberIdUtils.useGet();
  const member = memberQueriesContext.useMember();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const mutation = cantata.member.usePartialUpdate({
    params: {
      orgId,
      memberId,
    },
  });

  const toggleStarMark = useHandler(function toggleStarMark() {
    mutation.mutate({
      starMark: !member.starMark,
    });
  });

  return (
    <NarrowIconButton
      disabled={mutation.isLoading}
      icon={member.starMark ? <StarFilled /> : <StarOutlined />}
      iconSize="small"
      size="middle"
      css={member.starMark ? hasStarMark : noStarMark}
      onClick={toggleStarMark}
    />
  );
});
