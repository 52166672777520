import type { FC } from "react";

import type { CantataTypes } from "@/cantata/types";
import { Unknown } from "@/resources/groupMember/Unknown";
import { useMemberName } from "@/resources/groupMember/useMemberName";

const GroupMemberName: FC<{
  member: Pick<CantataTypes["GroupMember"], "displayName" | "originalName">;
}> = ({ member }) => {
  const memberName = useMemberName(member);
  return memberName || <Unknown />;
};

export { GroupMemberName };
