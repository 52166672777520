import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import copy from "copy-to-clipboard";
import type { ElementRef, ForwardedRef, ReactNode } from "react";
import { useEffect, useRef, useState } from "react";

import { Trans } from "@/app/i18n/Trans";
import type {
  NarrowIconButtonProps,
  StandardSize,
  ValidSize,
} from "@/components/Button/NarrowIconButton";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { message } from "@/components/message";
import { Tooltip } from "@/components/Tooltip";

const CheckIcon = styled(CheckOutlined)`
  color: ${theme.colors.green007};
`;

const CopyIcon = styled(CopyOutlined)`
  color: ${theme.colors.neutral007};

  &:hover {
    color: ${theme.colors.primaryHover};
  }
`;

type CopyButtonProps<
  Size extends ValidSize = StandardSize,
  IconSize extends ValidSize = Size,
> = Omit<NarrowIconButtonProps<Size, IconSize>, "href" | "onClick" | "icon"> & {
  text: string;
};

type CopyButtonType = <
  Size extends ValidSize = StandardSize,
  IconSize extends ValidSize = Size,
>(
  props: CopyButtonProps<Size, IconSize> & {
    ref?: ElementRef<typeof NarrowIconButton>;
  },
) => ReactNode;

const CopyButton: CopyButtonType = memo(
  forwardRef(function CopyButton<
    Size extends ValidSize = StandardSize,
    IconSize extends ValidSize = Size,
  >(
    { text, ...buttonProps }: CopyButtonProps<Size, IconSize>,
    ref?: ForwardedRef<ElementRef<typeof NarrowIconButton>>,
  ) {
    const timer = useRef<number>();
    const [copied, setCopied] = useState(false);

    const doCopy = useHandler<NarrowIconButtonProps["onClick"]>(
      async (event) => {
        event.stopPropagation();
        if (copied) return;

        copy(text);

        setCopied(true);
        message.success(
          <Trans i18nKey="common.copiedToClipboard">Copied to clipboard</Trans>,
        );
        timer.current = window.setTimeout(() => {
          setCopied(false);
        }, 2000);
      },
    );

    useEffect(() => {
      return () => {
        clearTimeout(timer.current);
      };
    }, []);

    return (
      <Tooltip title={<Trans i18nKey="common.copy" />}>
        <NarrowIconButton
          {...buttonProps}
          icon={copied ? <CheckIcon /> : <CopyIcon />}
          onClick={doCopy}
          ref={ref}
        />
      </Tooltip>
    );
  }),
) as CopyButtonType;

export { CopyButton };

export type { CopyButtonProps, CopyButtonType };
