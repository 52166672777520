import { memo } from "@chatbotgang/etude/react/memo";
import { ErrorBoundary } from "@sentry/react";
import { Fragment, Suspense } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import {
  EMPTY_STRING_PLACEHOLDER,
  HIERARCHY_STRING_JOINER,
} from "@/appConstant";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { BarLoading } from "@/components/Loading/BarLoading";
import { LockScreenLoading } from "@/components/Loading/LockScreenLoading";
import { Modal } from "@/components/Modal";
import { useNonGlobalMutationError } from "@/lib/react-query/useMutationError";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { useCheckMemberInMyScope } from "@/routes/Chat/useCheckMemberInMyScope";
/**
 * The Modal will be shown when the selected member is out of scope.
 */
const OutOfScope = memo(function OutOfScope() {
  const { t } = useTranslation();
  const checkInMyScope = useCheckMemberInMyScope();
  const activeMemberId = memberIdUtils.useGet();
  const member = memberQueriesContext.useMember();
  const clearActiveMember = memberIdUtils.useClear();

  useNonGlobalMutationError("SEND_MESSAGE_MEMBER_OUT_OF_YOUR_SCOPE", () => {
    checkInMyScope.invalidateQueries();
  });

  const inScope =
    activeMemberId &&
    checkInMyScope.exec({
      member,
    });

  if (!activeMemberId || inScope) return null;

  if (checkInMyScope.isLoading) return <LockScreenLoading />;

  // TODO: Fix me, bad UX
  if (checkInMyScope.errorDisplay) {
    return (
      <Modal
        open
        footer={
          <Button
            disabled={checkInMyScope.isFetching}
            onClick={checkInMyScope.invalidateQueries}
          >
            <Trans i18nKey="chat.cannotOpenConversation.retryGetMember" />
          </Button>
        }
        closable={false}
      >
        {checkInMyScope.errorDisplay}
      </Modal>
    );
  }

  return (
    <Modal
      open
      title={t("chat.cannotOpenConversation.title")}
      closable={false}
      footer={
        <Button type="primary" onClick={clearActiveMember}>
          {t("common.confirm")}
        </Button>
      }
    >
      <Content />
    </Modal>
  );
});

const Content = memo(function Content() {
  const { t } = useTranslation();
  const activeMember = memberQueriesContext.useMember();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const usersQuery = cantata.user.useList({
    params: {
      orgId,
    },
  });
  const teamsQuery = cantata.team.useList({
    params: {
      orgId,
    },
  });
  if (usersQuery.isLoading || teamsQuery.isLoading) return <BarLoading />;

  if (usersQuery.isError || teamsQuery.isError) {
    return (
      <Alert
        type="error"
        message={usersQuery.error?.message || teamsQuery.error?.message}
      />
    );
  }

  const teamName = !activeMember
    ? EMPTY_STRING_PLACEHOLDER
    : teamsQuery.data.teams.find(
        (team) =>
          activeMember.assignmentRelationship.team &&
          team.id === activeMember.assignmentRelationship.team.id,
      )?.name ?? "";
  const userName = !activeMember
    ? EMPTY_STRING_PLACEHOLDER
    : usersQuery.data.users.find(
        (user) =>
          activeMember.assignmentRelationship.user &&
          user.id === activeMember.assignmentRelationship.user.id,
      )?.name ?? "";

  const assigneeName = [teamName, userName]
    .filter(Boolean)
    .join(HIERARCHY_STRING_JOINER);

  return t("chat.cannotOpenConversation.desc", {
    name: assigneeName,
  });
});

const WrappedOutOfScope = memo(function WrappedOutOfScope() {
  return (
    <ErrorBoundary fallback={() => <Fragment />}>
      <Suspense fallback={<Modal.Loading open />}>
        <memberQueriesContext.Provider>
          <OutOfScope />
        </memberQueriesContext.Provider>
      </Suspense>
    </ErrorBoundary>
  );
});

export { WrappedOutOfScope as OutOfScope };
