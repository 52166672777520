import { string } from "@chatbotgang/etude/pitch-shifter/string";

import { LOCAL_STORAGE_REMEMBER_DEVICE_TOKEN_KEY } from "@/appConstant";
import {
  createZustandStorageStore,
  useLastUserEmailStore,
} from "@/shared/utils/createZustandStorageStore";

const { useStore: useRememberDeviceTokenStore } = createZustandStorageStore(
  LOCAL_STORAGE_REMEMBER_DEVICE_TOKEN_KEY,
  string(),
  {
    /**
     * Do not remove the token when a different account is signed in. We handle
     * this in `useLastUserEmailStore`.
     */
    forUser: false,
  },
);

useLastUserEmailStore.subscribe(
  function clearRememberDeviceTokenWhenSignedOut(current, previous) {
    const currentEmail = current.value;
    const previousEmail = previous.value;
    if (currentEmail === previousEmail) return;
    if (previousEmail && !currentEmail) {
      useRememberDeviceTokenStore.getState().clear();
    }
  },
);

function useRememberDeviceToken(): string {
  const rememberDeviceToken = useRememberDeviceTokenStore(
    (state) => state.value,
  );
  return rememberDeviceToken;
}

function getRememberDeviceToken(): string {
  return useRememberDeviceTokenStore.getState().value;
}

function setRememberDeviceToken(rememberDeviceToken: string): void {
  useRememberDeviceTokenStore.setState({ value: rememberDeviceToken });
}

const rememberDeviceTokenUtils = {
  useStore: useRememberDeviceTokenStore,
  useValue: useRememberDeviceToken,
  getValue: getRememberDeviceToken,
  setValue: setRememberDeviceToken,
  clear: useRememberDeviceTokenStore.getState().clear,
};

export { rememberDeviceTokenUtils };
