import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { type FC, type ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ExternalLink } from "@/components/ExternalLink";
import { FileDropZone } from "@/components/FileDropZone";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { Modal } from "@/components/Modal";
import { StaticVerticalSteps } from "@/components/StaticVerticalSteps";
import { DownloadErrorReportButton } from "@/routes/Settings/Users/People/List/importUsers/DownloadErrorReportButton";
import { ImportUsersResultModal } from "@/routes/Settings/Users/People/List/importUsers/ImportUsersResultModal";
import { handleNonGlobalApiError } from "@/shared/domains/error";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  ImportUsersModal: css({
    [`& .${StaticVerticalSteps.Step.classNameRecord.description}`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 8,
    },
  }),
  downloadTemplateButton: css({
    borderColor: "currentcolor",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    paddingBlock: 4,
    paddingInline: 7,
  }),
  csvInfo: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 4,
  }),
  csvError: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "inherit",
    color: theme.colors.error,
  }),
});

namespace ImportUsersModal {
  export type Props = Omit<ComponentProps<typeof Modal>, "children">;
}

type FormValues = {
  csv: null | File;
};

const initialValues: FormValues = {
  csv: null,
};

const EasyForm = createEasyForm<FormValues>();

const ImportUsersModalInternal: FC<ImportUsersModal.Props> = ({
  open = true,
  ...props
}) => {
  const { t } = useTranslation();
  const helpLink = useMemo(
    () =>
      t(
        "page.settings.users.importUsers.modal.step.prepareFile.content.learnMore",
      ),
    [t],
  );
  const templateLink = useMemo(
    () =>
      t(
        "page.settings.users.importUsers.modal.step.prepareFile.downloadTemplate.link",
      ),
    [t],
  );
  const orgId = useActiveOrgIdStore((state) => state.value);
  const planQuery = cantata.orgPlan.useGetById(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const usersQuery = cantata.user.useList(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const [, form] = EasyForm.useForm();
  const [errorRaw, setErrorRaw] = useState<{
    requiredSeat?: number;
    node: ReactNode;
  } | null>(null);
  const setErrorNode = useHandler((node: ReactNode) => {
    setErrorRaw({
      node,
    });
  });
  const mutation = cantata.user.useImportWithCsv(
    {
      params: {
        orgId,
      },
    },
    {
      async onError(err) {
        await handleNonGlobalApiError(err, {
          BULK_INVITE_USER_ERROR_PARSE_FAIL: () => {
            setErrorNode(
              t("page.settings.users.importUsers.modal.error.formatError"),
            );
          },
          BULK_INVITE_USER_ERROR_EXCEED_ROW_LIMIT: () => {
            setErrorNode(
              t("page.settings.users.importUsers.modal.error.exceedLimitRow"),
            );
          },
          BULK_INVITE_USER_ERROR_INSUFFICIENT_SEAT: (err) => {
            setErrorRaw({
              requiredSeat: err.detail.requireSeat,
              node: t(
                "page.settings.users.importUsers.modal.error.exceedSeatLimit",
              ),
            });
          },
          BULK_INVITE_USER_ERROR_INVALID_CONTENT: (err) => {
            setErrorNode(
              <>
                <div>
                  <Trans
                    i18nKey="page.settings.users.importUsers.modal.error.errorResult.content"
                    values={{
                      count: err.detail.invalidRowCount,
                    }}
                  />
                </div>
                <DownloadErrorReportButton content={err.detail.errorReport} />
              </>,
            );
          },
        });
      },
    },
  );
  const onFinish = useHandler<ComponentProps<typeof EasyForm>["onFinish"]>(
    (values) => {
      if (!values.csv) {
        setErrorNode(t("page.settings.users.importUsers.modal.error.required"));
        return;
      }
      mutation.mutate({
        bulkInviteUserCsv: values.csv,
      });
    },
  );
  const currentSeat: number = useMemo(() => {
    if (!usersQuery.isSuccess) {
      return 0;
    }
    return usersQuery.data.users.filter(
      (user) => user.status !== "deleted" && !user.isInternalUser,
    ).length;
  }, [usersQuery.data?.users, usersQuery.isSuccess]);
  const remainingSeat: number = useMemo(() => {
    if (!planQuery.isSuccess || !usersQuery.isSuccess) {
      return 0;
    }
    return Math.max(planQuery.data.seatNum - currentSeat, 0);
  }, [
    currentSeat,
    planQuery.data?.seatNum,
    planQuery.isSuccess,
    usersQuery.isSuccess,
  ]);
  return mutation.isSuccess ? (
    <ImportUsersResultModal
      open
      invitedUsersCount={mutation.data.invitedUserCount}
      updatedUsersCount={mutation.data.updatedUserCount}
      onOk={props.onCancel}
    />
  ) : (
    <EasyForm
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      disabled={mutation.isLoading}
    >
      <Modal
        css={styles.ImportUsersModal}
        title={t("page.settings.users.importUsers.modal.title")}
        okText={t("page.settings.users.importUsers.modal.action.import.label")}
        cancelText={t(
          "page.settings.users.importUsers.modal.action.cancel.label",
        )}
        onOk={form.submit}
        okButtonProps={{
          loading: mutation.isLoading,
        }}
        open={open}
        {...props}
      >
        <StaticVerticalSteps>
          <StaticVerticalSteps.Step
            title={t(
              "page.settings.users.importUsers.modal.step.prepareFile.title",
            )}
            description={
              <>
                <div>
                  <Trans
                    i18nKey="page.settings.users.importUsers.modal.step.prepareFile.content"
                    components={{
                      a: <ExternalLink href={helpLink} />,
                    }}
                  />
                </div>
                <div>
                  <ExternalLink
                    css={styles.downloadTemplateButton}
                    download
                    href={templateLink}
                  >
                    {t(
                      "page.settings.users.importUsers.modal.step.prepareFile.downloadTemplate.button.label",
                    )}
                  </ExternalLink>
                </div>
              </>
            }
          />
          <StaticVerticalSteps.Step
            title={t(
              "page.settings.users.importUsers.modal.step.selectFile.title",
            )}
            description={
              <>
                <div>
                  <Trans
                    i18nKey="page.settings.users.importUsers.modal.step.selectFile.content"
                    components={{
                      b: (
                        <span
                          style={{
                            fontWeight: 500,
                            color: theme.colors.neutral009,
                          }}
                        />
                      ),
                    }}
                  />
                </div>
                <EasyForm.Item name="csv" valuePropName="file" noStyle>
                  <FileDropZone
                    error={Boolean(errorRaw)}
                    accept={{
                      "text/csv": [".csv"],
                    }}
                    onDrop={(acceptedFiles) => {
                      const firstAcceptedFile = acceptedFiles[0];
                      if (!firstAcceptedFile) {
                        form.setFieldsValue({
                          csv: null,
                        });
                        setErrorNode(
                          t(
                            "page.settings.users.importUsers.modal.error.required",
                          ),
                        );
                        return;
                      }
                      form.setFieldsValue({
                        csv: firstAcceptedFile,
                      });
                      setErrorRaw(null);
                    }}
                  />
                </EasyForm.Item>
                <div css={styles.csvInfo}>
                  {errorRaw?.requiredSeat === undefined ? (
                    <div>
                      {t(
                        "page.settings.users.importUsers.modal.remainingSeat.content",
                        {
                          count: remainingSeat,
                        },
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        color: theme.colors.error,
                      }}
                    >
                      {t(
                        "page.settings.users.importUsers.modal.remainingSeat.content",
                        {
                          count: remainingSeat,
                        },
                      )}
                      {` - ${errorRaw.requiredSeat} = ${remainingSeat - errorRaw.requiredSeat}`}
                    </div>
                  )}
                  {!errorRaw?.node ? null : (
                    <div css={styles.csvError}>{errorRaw.node}</div>
                  )}
                </div>
              </>
            }
          />
        </StaticVerticalSteps>
      </Modal>
    </EasyForm>
  );
};

/**
 * Description of the component.
 */
const ImportUsersModal = Object.assign(ImportUsersModalInternal, {
  styles,
});

assignDisplayName(ImportUsersModal, "ImportUsersModal");

const Wrapped: FC<ImportUsersModal.Props> = (props) => {
  return (
    <ErrorBoundary.Modal
      ModalProps={{
        onCancel: props.onCancel,
      }}
    >
      <ImportUsersModal {...props} />
    </ErrorBoundary.Modal>
  );
};

export { Wrapped as ImportUsersModal };
