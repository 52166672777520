import { define } from "@chatbotgang/etude/util/define";
import type { MemberProcessingStateSchema } from "@zeffiroso/cantata/models";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Badge } from "@/components/Badge";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { MotifIcon } from "@/components/MotifIcon";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { BlockMemberModalProvider } from "@/routes/Chat/ui/ChatPanel/Header/components/BlockMemberModal";
import { LeaveGroupModalProvider } from "@/routes/Chat/ui/ChatPanel/Header/components/LeaveGroupModal";
import { mutationsController } from "@/routes/Chat/ui/ChatPanel/Header/mutationsController";
import type { Item } from "@/routes/Chat/ui/ChatPanel/Header/types";
import { useDropDownSwitch as usePinnedMessagesDropDownSwitch } from "@/routes/Chat/ui/ChatPanel/Pin/useDropDownSwitch";
import { Layout } from "@/routes/Chat/ui/Layout";
import { useSearchMessageFromAMember } from "@/routes/Chat/ui/searchMessageFromMember";
type ExtendedItem = Item & { ["data-hotkey"]?: string };

const processingStateHotKeys = define<
  Partial<Record<z.infer<typeof MemberProcessingStateSchema>, string>>
>()({
  new: "Mod+Shift+O",
  "follow-up": "Mod+Shift+F",
  resolved: "Mod+Shift+S",
});

const PinMessagesCount: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const query = cantata.message.useListPinnedMessages({
    params: { orgId, memberId },
  });
  if (!query.isSuccess) return null;
  return <Badge $variant="info">{query.data.messages.length}</Badge>;
};

function useMenuItems() {
  const { t } = useTranslation();

  const member = memberQueriesContext.useMember();
  const mutations = mutationsController.useContext();
  const mergeFormDisabled = useMergeFormDisabled();

  const profileDrawerOpened = Layout.profileDrawer.useOpened();
  const viewProfile = useMemo<ExtendedItem>(() => {
    return {
      key: "viewProfile",
      icon: <MotifIcon un-i-motif="user" />,
      label: t("chat.action.openProfileDrawer"),
      onClick: Layout.profileDrawer.open,
      disabled: mergeFormDisabled(profileDrawerOpened),
    };
  }, [mergeFormDisabled, profileDrawerOpened, t]);

  const searchMessageFromAMember = useSearchMessageFromAMember();
  const searchMessages = useMemo<ExtendedItem>(() => {
    return {
      key: "searchMessages",
      icon: <MotifIcon un-i-motif="magnifier" />,
      label: t("chat.action.openSearchMessagePanel"),
      onClick() {
        searchMessageFromAMember.openSearchInput();
      },
      disabled: mergeFormDisabled(searchMessageFromAMember.searchInputOpened),
    };
  }, [mergeFormDisabled, searchMessageFromAMember, t]);

  const [
    pinnedMessagesDropDownSwitchOpened,
    togglePinnedMessagesDropDownSwitch,
  ] = usePinnedMessagesDropDownSwitch();
  const pinnedMessages = useMemo<ExtendedItem>(() => {
    return {
      key: "pinnedMessages",
      icon: <MotifIcon un-i-motif="bookmark" />,
      label: (
        <ItemWithIcon endIcon={<PinMessagesCount />}>
          {t("chat.action.openPinMessagePopup")}
        </ItemWithIcon>
      ),
      onClick: togglePinnedMessagesDropDownSwitch.on,
      disabled: mergeFormDisabled(pinnedMessagesDropDownSwitchOpened),
    };
  }, [
    mergeFormDisabled,
    pinnedMessagesDropDownSwitchOpened,
    t,
    togglePinnedMessagesDropDownSwitch.on,
  ]);

  const pinMember = useMemo<ExtendedItem>(() => {
    if (member.pinned)
      return {
        key: "unpinMember",
        icon: <MotifIcon un-i-motif="pin_fill" />,
        label: t("resource.member.action.unpin.label"),
        onClick() {
          mutations.unpinMutation.mutate(undefined);
        },
      } satisfies ExtendedItem;
    return {
      key: "pinMember",
      icon: <MotifIcon un-i-motif="pin" />,
      label: t("resource.member.action.pin.label"),
      onClick() {
        mutations.pinMutation.mutate(undefined);
      },
    } satisfies ExtendedItem;
  }, [member.pinned, mutations.pinMutation, mutations.unpinMutation, t]);

  const [blockMemberModalOpened, toggleBlockMemberModal] =
    BlockMemberModalProvider.useSwitch();
  const [leaveGroupModalOpened, toggleLeaveGroupModal] =
    LeaveGroupModalProvider.useSwitch();
  const setProcessingStatus = useMemo(() => {
    return define<
      Record<
        Exclude<z.infer<typeof MemberProcessingStateSchema>, "none">,
        ExtendedItem
      >
    >({
      new:
        member.processingState === "blocked"
          ? {
              key: "new",
              icon: <MotifIcon un-i-motif="circle_block_fill" />,
              label: t(
                "resource.member.action.processingStatus.options.new.labelFromBlock",
              ),
              onClick: toggleBlockMemberModal.on,
              "data-hotkey": processingStateHotKeys.new,
              disabled: mergeFormDisabled(blockMemberModalOpened),
            }
          : {
              key: "new",
              icon: <MotifIcon un-i-motif="circle_check_fill" />,
              label: t(
                "resource.member.action.processingStatus.options.new.label",
              ),
              onClick() {
                return mutations.updateProcessingStateMutation.mutate({
                  senderSession: "no use",
                  processingState: "new",
                });
              },
              "data-hotkey": processingStateHotKeys.new,
            },
      "follow-up":
        member.processingState === "follow-up"
          ? {
              key: "follow-up",
              icon: (
                <MotifIcon
                  un-i-motif="flag_filled"
                  style={{ color: theme.colors.yellow006 }}
                />
              ),
              label: t(
                "resource.member.property.processingStatus.options.followUp.label",
              ),
              disabled: true,
            }
          : {
              key: "follow-up",
              icon: <MotifIcon un-i-motif="flag" />,
              label: t(
                "resource.member.action.processingStatus.options.followUp.label",
              ),
              onClick() {
                return mutations.updateProcessingStateMutation.mutate({
                  senderSession: "no use",
                  processingState: "follow-up",
                });
              },
              "data-hotkey": processingStateHotKeys["follow-up"],
            },
      resolved: {
        key: "resolve",
        icon: <MotifIcon un-i-motif="circle_check" />,
        label: t(
          "resource.member.action.processingStatus.options.resolve.label",
        ),
        onClick() {
          return mutations.updateProcessingStateMutation.mutate({
            senderSession: "no use",
            processingState: "resolved",
          });
        },
        "data-hotkey": processingStateHotKeys.resolved,
      },
      blocked: {
        key: "blocked",
        icon: <MotifIcon un-i-motif="circle_block" />,
        label: t("resource.member.action.processingStatus.options.block.label"),
        onClick: toggleBlockMemberModal.on,
        disabled: mergeFormDisabled(blockMemberModalOpened),
      },
    });
  }, [
    blockMemberModalOpened,
    member.processingState,
    mergeFormDisabled,
    mutations.updateProcessingStateMutation,
    t,
    toggleBlockMemberModal.on,
  ]);

  const leaveGroup = useMemo<ExtendedItem>(() => {
    const isInactive = member.status === "inactive";
    return {
      key: "leaveGroup",
      danger: true,
      disabled: mergeFormDisabled(isInactive || leaveGroupModalOpened),
      icon: <MotifIcon un-i-motif="arrow_exit" />,
      label: t("resource.member.action.leaveGroup.label"),
      onClick: toggleLeaveGroupModal.on,
    } satisfies ExtendedItem;
  }, [
    leaveGroupModalOpened,
    member.status,
    mergeFormDisabled,
    t,
    toggleLeaveGroupModal.on,
  ]);

  const menuItems = useMemo(() => {
    return {
      viewProfile,
      searchMessages,
      pinnedMessages,
      pinMember,
      leaveGroup,
      setProcessingStatus,
    };
  }, [
    pinMember,
    pinnedMessages,
    searchMessages,
    setProcessingStatus,
    leaveGroup,
    viewProfile,
  ]);

  return menuItems;
}

export { useMenuItems };
export type { ExtendedItem };
