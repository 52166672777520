import { css } from "@emotion/react";
import type { FC } from "react";

import type { AuthenticatedAppOuterProps } from "@/layout/base/AuthenticatedAppOuter/types";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    "&, & > *": cssFlexInheritAndFill,
  }),
});

const AppLayoutLteMobile: FC<AuthenticatedAppOuterProps> = ({ mainRef }) => {
  return <div css={styles.root} ref={mainRef} />;
};

export { AppLayoutLteMobile };
