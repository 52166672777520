import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import {
  type ElementRef,
  type ReactNode,
  useImperativeHandle,
  useMemo,
} from "react";
import {
  type DropzoneOptions,
  type DropzoneState,
  useDropzone,
} from "react-dropzone";

import { DropZone } from "@/components/FileDropZone/DropZone";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  FileDropZoneInternal: css({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 48,
    gap: 24,
    borderRadius: 12,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.colors.neutral003,
    overflow: "hidden",
    boxSizing: "border-box",
    minHeight: 182,
    cursor: "pointer",
  }),
  isDragActive: css({
    borderColor: theme.colors.primaryHover,
  }),
  error: css({
    borderColor: theme.colors.error,
  }),
  errorDragActive: css({
    borderColor: theme.colors.errorHover,
  }),
});

const emptyStyles = defineStyles({
  FileDropZoneInternal: css({
    borderStyle: "dashed",
  }),
});

const disabledStyles = defineStyles({
  FileDropZoneInternal: css({
    "&:not([tabindex])": {
      cursor: "not-allowed",
    },
  }),
});

namespace FileDropZone {
  export type Ref = ElementRef<"div">;
  export interface OwnProps {
    error?: boolean;
    children?: ReactNode | ((dropzone: DropzoneState) => ReactNode);
    file?: DropZone.Props["file"];
    imperativeHandleRef?: React.Ref<{
      open: DropzoneState["open"];
    }>;
  }
  export type Props = Overwrite<DropzoneOptions, OwnProps>;
}

const FileDropZoneInternal = forwardRef<FileDropZone.Ref, FileDropZone.Props>(
  function FileDropZoInternalInternal(
    { error, file, imperativeHandleRef, ...props },
    ref,
  ) {
    const onDrop = useHandler<DropzoneOptions["onDrop"]>((...args) =>
      props.onDrop?.(...args),
    );
    const dropZoneOptions: DropzoneOptions = useMemo(
      () => ({
        ...props,
        onDrop,
      }),
      [onDrop, props],
    );
    const dropzone = useDropzone(dropZoneOptions);

    const files: Array<File> = useMemo(
      () => (Array.isArray(file) ? file : !file ? [] : [file]),
      [file],
    );

    const mergedCss = useMemo(
      () =>
        css([
          styles.FileDropZoneInternal,
          files.length > 0 ? null : emptyStyles.FileDropZoneInternal,
          !props.disabled ? null : disabledStyles.FileDropZoneInternal,
          error
            ? dropzone.isDragActive
              ? styles.errorDragActive
              : styles.error
            : dropzone.isDragActive
              ? styles.isDragActive
              : null,
        ]),
      [dropzone.isDragActive, error, files.length, props.disabled],
    );

    const dropzoneOpen = useHandler(dropzone.open);

    const imperativeHandle = useMemo(
      () => ({
        open: dropzoneOpen,
      }),
      [dropzoneOpen],
    );

    useImperativeHandle(imperativeHandleRef, () => imperativeHandle, [
      imperativeHandle,
    ]);

    return (
      <div {...dropzone.getRootProps()} css={mergedCss} ref={ref}>
        <input {...dropzone.getInputProps()} />
        {props.children === undefined ? (
          <DropZone file={files} />
        ) : typeof props.children === "function" ? (
          props.children(dropzone)
        ) : (
          props.children
        )}
      </div>
    );
  },
);

/**
 * A component that allows users to drag and drop files to upload.
 *
 * Design: [Figma](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=6806-23046&m=dev)
 */
const FileDropZone = Object.assign(FileDropZoneInternal, {
  styles,
});

assignDisplayName(FileDropZoneInternal, "FileDropZone");

export { FileDropZone };
