import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { CompositionEvent, CompositionEventHandler } from "react";
import { useMemo, useState } from "react";

type EditableElement = HTMLTextAreaElement | HTMLInputElement | HTMLDivElement;

/**
 * To controlled input composition (輸入法)
 * @return isComposition 是否在輸入法中
 * @return bind bind to input or textarea
 * @example
 * ```tsx
 * const { isComposition, bind } = useInputComposition();
 * console.log(isComposition) // bool of current composition status
 * <input {...bind} />
 * ```
 *
 * @deprecated Use `useInputComposition2` for simpler props composition
 */
export function useInputComposition(): {
  isComposition: boolean;
  bind: {
    onCompositionStart: CompositionEventHandler<EditableElement>;
    onCompositionUpdate: CompositionEventHandler<EditableElement>;
    onCompositionEnd: CompositionEventHandler<EditableElement>;
  };
} {
  const [isComposition, setIsComposition] = useState(false);

  const handleComposition = useHandler(function handleComposition(
    e: CompositionEvent<EditableElement>,
  ) {
    if (e.type === "compositionend") setIsComposition(false);
    else setIsComposition(true);
  });

  const inputComposition = useMemo(
    function inputComposition() {
      return {
        isComposition,
        bind: {
          onCompositionStart: handleComposition,
          onCompositionUpdate: handleComposition,
          onCompositionEnd: handleComposition,
        },
      };
    },
    [handleComposition, isComposition],
  );
  return inputComposition;
}
