import { createContext } from "@chatbotgang/etude/react/createContext";
import { random } from "@chatbotgang/etude/string/random";
import { define } from "@chatbotgang/etude/util/define";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import type { ExtractDeepReadonlyObject } from "@zeffiroso/zodios/types";
import { isEqual } from "lodash-es";
import { useState } from "react";
import { createWithEqualityFn } from "zustand/traditional";

import type { cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";

type ExpectedSendingMessagesError = Extract<
  CantataTypes["MessageWithStatus"],
  {
    status: "failed";
  }
>["error"];

type SendingMessagesError = ExpectedSendingMessagesError | Error;

type BatchCreateParam = ExtractDeepReadonlyObject<
  Parameters<typeof cantataClient.message.batchCreate>[0]
>;

type BatchCreateMessage = BatchCreateParam["messages"][number];

type SendingMessagesReq = {
  sendingMessageId: string;
  params: Parameters<typeof cantataClient.message.batchCreate>[1]["params"];
  messages: Array<{
    body: BatchCreateMessage;
    error?: SendingMessagesError;
  }>;
  status: "pending" | "loading" | "error";
};

function createSendingMessagesController() {
  const useStore = createWithEqualityFn<{
    requests: Array<SendingMessagesReq>;
  }>()(
    () => ({
      requests: [],
    }),
    shallow,
  );
  function createRequest(
    params: SendingMessagesReq["params"],
    messages: Array<BatchCreateMessage>,
  ) {
    const id = random();
    useStore.setState((state) => ({
      requests: [
        ...state.requests,
        define<SendingMessagesReq>({
          sendingMessageId: id,
          params,
          messages: messages.map((message) => ({
            body: message,
          })),
          status: "pending",
        }),
      ],
    }));
    return id;
  }
  function updateRequest(
    id: ReturnType<typeof createRequest>,
    callback: (current: SendingMessagesReq) => SendingMessagesReq,
  ) {
    const requests = useStore.getState().requests;
    const request = requests.find((request) => request.sendingMessageId === id);
    if (!request) return;
    const newRequest = callback(request);
    if (isEqual(request, newRequest)) return;
    useStore.setState((state) => {
      const requests = state.requests;
      if (!requests.some((request) => request.sendingMessageId === id))
        return state;
      return {
        requests: requests.map((request) =>
          request.sendingMessageId === id ? newRequest : request,
        ),
      };
    });
  }
  function removeRequest(id: ReturnType<typeof createRequest>) {
    useStore.setState((state) => ({
      requests: state.requests.filter(
        (request) => request.sendingMessageId !== id,
      ),
    }));
  }
  function clearRequests() {
    useStore.setState(() => ({
      requests: [],
    }));
  }
  function useRequests() {
    return useStore((state) => state.requests);
  }
  const sendingMessagesController = {
    useStore,
    createRequest,
    updateRequest,
    removeRequest,
    clearRequests,
    useRequests,
  };
  return sendingMessagesController;
}

function useInitialContext() {
  const [sendingMessagesController] = useState(createSendingMessagesController);
  return sendingMessagesController;
}

const sendingMessagesContext = createContext<
  ReturnType<typeof useInitialContext>
>({
  name: "sendingMessages",
});

const useSendingMessagesController = sendingMessagesContext.useContext;

export {
  sendingMessagesContext,
  useInitialContext,
  useSendingMessagesController,
};
export type { ExpectedSendingMessagesError, SendingMessagesReq };
