import { date, isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import { string } from "@chatbotgang/etude/pitch-shifter/string";
import { hoursToMilliseconds, secondsToMilliseconds } from "date-fns";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { sentryDebug } from "@/app/sentryDebug";
import { LOCAL_STORAGE_LAST_ACTIVE_TIME } from "@/appConstant";
import { useTokenStore } from "@/shared/services/token";
import {
  createZustandStorageStore,
  useLastUserEmailStore,
} from "@/shared/utils/createZustandStorageStore";

const updateIntervalMs = secondsToMilliseconds(10);
const expireDurationMs = hoursToMilliseconds(6);

const lastActiveTimeStore = createZustandStorageStore<string>(
  LOCAL_STORAGE_LAST_ACTIVE_TIME,
  string(),
);

let updateInterval: ReturnType<typeof setInterval> | null = null;

function clearCheckInterval() {
  if (!updateInterval) return;
  clearInterval(updateInterval);
  updateInterval = null;
}

function updateLastActiveTime() {
  lastActiveTimeStore.useStore.getState().setValue(new Date().toISOString());
}

function setupUpdateInterval() {
  clearCheckInterval();
  updateInterval = setInterval(updateLastActiveTime, updateIntervalMs);
}

(function check() {
  const currentDate = new Date();
  const lastActiveTime = date()(lastActiveTimeStore.useStore.getState().value);
  const currentToken = useTokenStore.getState().value;
  if (!currentToken) return;
  if (isInvalidDate(lastActiveTime)) return;
  if (currentDate.getTime() - lastActiveTime.getTime() > expireDurationMs) {
    sentryDebug("logoutDueToUnauthenticated", {
      token: currentToken,
      orgId: useActiveOrgIdStore.getState().value,
      email: useLastUserEmailStore.getState().value,
      reason: "noActiveForLongTime",
    });
    useTokenStore.getState().clear();
  }
})();

useTokenStore.subscribe((current, prev) => {
  if (!current.value && prev.value) {
    clearCheckInterval();
    return;
  }
  if (current.value && !prev.value) {
    updateLastActiveTime();
    setupUpdateInterval();
  }
});

/**
 * Update last active time when the app is loaded.
 * This should be always called after `check()`.
 */
(function firstUpdateLastActiveTime() {
  if (!useTokenStore.getState().value) return;
  updateLastActiveTime();
  setupUpdateInterval();
})();

function useLastActiveTime() {
  const str = lastActiveTimeStore.useStore((state) => state.value);
  const lastActiveTime = useMemo(() => date()(str), [str]);
  return lastActiveTime;
}

export { expireDurationMs, updateIntervalMs, useLastActiveTime };
