import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { UseQueryOptions } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import { compareDesc } from "date-fns";
import PQueue from "p-queue";
import { useMemo } from "react";

import { cantata, cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { isUnifiedMemberType } from "@/resources/member/isUnifiedMemberType";

const concurrency = 3;

const pQueue = new PQueue({ concurrency });

type NoteQueryOptions = UseQueryOptions<
  Awaited<ReturnType<typeof cantataClient.memberNote.list>>
>;

function getQueryOptions(
  params: Parameters<typeof cantataClient.memberNote.list>[0]["params"],
): NoteQueryOptions {
  return {
    async queryFn({ signal }) {
      const result = await pQueue.add(() =>
        cantataClient.memberNote.list({
          params,
          signal,
        }),
      );
      if (!result) {
        throw new Error(
          inspectMessage`Failed to fetch notes for member: ${params}, result: ${result}`,
        );
      }
      return result;
    },
    queryKey: cantata.memberNote.getKeyByAlias("list", {
      params,
    }),
  };
}

function useUnifiedNotesQueries(
  params: {
    orgId: CantataTypes["Org"]["id"];
    memberId: CantataTypes["Member"]["id"];
  },
  queryOptions?: Pick<UseQueryOptions, "enabled" | "suspense"> & {
    useErrorBoundary?: Extract<UseQueryOptions["useErrorBoundary"], boolean>;
  },
) {
  const orgId = params.orgId;
  const member = memberQueriesContext.useMember();
  /**
   * Only using individual notes for line groups.
   *
   * Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1725616975991739?thread_ts=1725527279.250359&cid=C02R6ETJMEY)
   */
  const usingUnifiedNotes = isUnifiedMemberType(member);
  const membersQuery = cantata.memberUnification.useGetByMemberId(
    {
      params,
    },
    {
      ...queryOptions,
      ...(!usingUnifiedNotes
        ? {
            /**
             * Forcing to disable the query if not using unified notes.
             */
            enabled: false,
          }
        : null),
    },
  );
  type NoteQueryOptions = UseQueryOptions<
    Awaited<ReturnType<typeof cantataClient.memberNote.list>>
  >;
  const notesQueries = useQueries({
    queries: !usingUnifiedNotes
      ? [
          /**
           * If not using unified notes, only query the notes for the member.
           */
          {
            ...getQueryOptions({
              orgId,
              source: "caac",
              memberId: member.id,
            }),
            ...queryOptions,
          },
        ]
      : /**
         * If using unified notes, query the notes for all unified members.
         */
        membersQuery.data?.map<NoteQueryOptions>((unifiedMember) => ({
          ...getQueryOptions({
            orgId,
            source: unifiedMember.source,
            memberId: unifiedMember.memberId,
          }),
          ...queryOptions,
          ...(membersQuery.isSuccess ? null : { enabled: false }),
        })) ?? [],
  });
  const notes = notesQueries
    .flatMap((query) => query.data?.notes ?? [])
    .toSorted((a, b) => {
      const compareDescResult = compareDesc(a.createdAt, b.createdAt);
      if (compareDescResult === 0) {
        return a.id - b.id;
      }
      return compareDescResult;
    });
  const result = useMemo(
    () => ({
      membersQuery,
      notesQueries,
      notes,
    }),
    [membersQuery, notes, notesQueries],
  );
  return result;
}

export { useUnifiedNotesQueries };
