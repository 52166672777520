import { number } from "@chatbotgang/etude/pitch-shifter/number";

import { LOCAL_STORAGE_LAST_ORGANIZATION } from "@/appConstant";
import { isValidOrgId } from "@/resources/organization/isValidOrgId";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const local = createZustandStorageStore(
  LOCAL_STORAGE_LAST_ORGANIZATION,
  number(),
);
const session = createZustandStorageStore(
  LOCAL_STORAGE_LAST_ORGANIZATION,
  number(),
  {
    storage: sessionStorage,
  },
);

const useActiveOrgIdStore = session.useStore;

/**
 * Sync session storage with local storage for new tabs.
 */
if (local.useStore.getState().value && !session.useStore.getState().value)
  session.useStore.setState({ value: local.useStore.getState().value });

function setActiveOrgId(orgId: number) {
  session.useStore.setState({ value: orgId });
}

session.useStore.subscribe((state, prevState) => {
  const sessionValue = state.value;
  const prevSessionValue = prevState.value;
  const sessionChanged = sessionValue !== prevSessionValue;
  if (!sessionChanged) return;
  const localValue = local.useStore.getState().value;
  if (sessionValue === localValue) return;
  local.useStore.setState({ value: sessionValue });
});

function clearSessionOrgId() {
  session.useStore.getState().clear();
}

function useIsOrgIdActive() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  return isValidOrgId(orgId);
}

export {
  clearSessionOrgId,
  setActiveOrgId,
  useActiveOrgIdStore,
  useIsOrgIdActive,
};
