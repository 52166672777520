import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";

import { Avatar } from "@/components/Avatar";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";

const MemberAvatar = memo(function MemberAvatar() {
  const member = memberQueriesContext.useMember();

  return (
    <Avatar
      size={40}
      src={member.avatar}
      css={css`
        flex: 0 0 auto;
      `}
    />
  );
});

export { MemberAvatar };
