import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { CanceledError } from "axios";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useMessage } from "@/components/message";
import { mutationErrorEventEmitter } from "@/lib/react-query/mutationErrorEventEmitter";
import type { MutationErrorEmitter } from "@/lib/react-query/useMutationError";
import { logError } from "@/shared/application/logger/sentry";
import {
  apiErrorNameTranslationKeyMap,
  getApiErrorBodyFromUnknownError,
} from "@/shared/domains/error";
import { fakeT } from "@/shared/g11n/fakeT";

const t = fakeT;

/**
 * Handles application errors or logs them to Sentry. It will return a
 * translation key by different types of error. If the error is not an exact
 * error, or we don't want to handle it globally, it will return `null`.
 *
 * @example
 *
 *```ts
 *  const errorMessageTranslationKey = handleError(error);
 *  message.error(t(errorMessageTranslationKey));
 *```
 */
function handleError<T = Error>(
  /**
   * The error to handle.
   */
  error: T,
): string | null {
  /**
   * Cancel error is not an error, it's just a signal to cancel the request.
   */
  if (error instanceof CanceledError) return null;
  const errorApiBody = getApiErrorBodyFromUnknownError(error);
  if (errorApiBody) {
    mutationErrorEventEmitter.emit("knownError", errorApiBody);
    return apiErrorNameTranslationKeyMap[errorApiBody.name];
  }
  logError(error);
  return t("common.applicationError.unknownError");
}

type UseGetErrorMessageOptions = {
  unknownErrorMessage?: string;
};

/**
 * Hook to get error message from any error including cancellation error and
 * non-global error.
 */
function useGetErrorMessage() {
  const { t } = useTranslation();
  const getErrorMessage = useCallback<
    (e: unknown, options?: UseGetErrorMessageOptions) => string
  >(
    function getErrorMessage(e, options) {
      const errorApiBody = getApiErrorBodyFromUnknownError(e);
      const translationKey =
        (errorApiBody && errorApiBody?.name !== "UNKNOWN_ERROR"
          ? apiErrorNameTranslationKeyMap[errorApiBody.name]
          : apiErrorNameTranslationKeyMap.UNKNOWN_ERROR) ??
        apiErrorNameTranslationKeyMap.UNKNOWN_ERROR;
      return (
        (translationKey === apiErrorNameTranslationKeyMap.UNKNOWN_ERROR
          ? options?.unknownErrorMessage ||
            t(apiErrorNameTranslationKeyMap.UNKNOWN_ERROR)
          : t(translationKey)) +
        (translationKey !== apiErrorNameTranslationKeyMap.UNKNOWN_ERROR
          ? ""
          : `: ${
              e instanceof Error
                ? `[${e.name}]: ${e.message}`
                : inspectMessage`Non-error object thrown: ${e}`
            }`)
      );
    },
    [t],
  );
  /**
   * Get error message from any error including cancellation error and
   * non-global error.
   */
  return getErrorMessage;
}

/**
 * Shorthand for handling errors and showing error message.
 *
 * @example
 *
 * ```ts
 * const handleErrorAndShowMessage = useHandleErrorAndShowMessage();
 * const mutation = useMutation(...);
 *
 * const onFinish = useHandler<FormProps<FieldValues>(async onFinish (values) => {
 *   const result = await safePromise(() =>mutation.mutateAsync(values));
 *   if (result.error) {
 *     handleErrorAndShowMessage(result.error);
 *   return;
 * }
 * ```
 *
 * The code is currently set up to handle mutation errors globally.
 * It is recommended to avoid using this in components, and instead use
 * useMutation, which will always handle common errors appropriately.
 *
 * This will be moved to `<MutationErrorEmitter />` in the future.
 *
 * @see {@link MutationErrorEmitter}
 */
function useHandleErrorAndShowMessage() {
  const message = useMessage();
  const getErrorMessage = useGetErrorMessage();
  const handleErrorAndShowMessage = useHandler(
    function handleErrorAndShowMessage<E = Error>(err: E) {
      const errorMessageTranslationKey = handleError(err);
      if (!errorMessageTranslationKey) return;
      message.error(getErrorMessage(err));
    },
  );
  return handleErrorAndShowMessage;
}

export { handleError, useGetErrorMessage, useHandleErrorAndShowMessage };
