import { useCallback } from "react";

import type { AvailableLanguage } from "@/shared/hooks/formatLangCode";
import { useLanguage } from "@/shared/hooks/useLanguage";

const localeMap: Record<
  AvailableLanguage,
  NonNullable<Parameters<(typeof String)["prototype"]["localeCompare"]>[1]>
> = {
  en: "en-US",
  "zh-hant": "zh-Hant-u-co-zhuyin",
  th: "th-TH",
  ja: "ja-JP",
};

/**
 * @returns A function that compares two strings in the current locale.
 * @example
 * ```ts
 * const localeCompare = useLocaleCompare();
 * const sorted = ['a', 'b', 'c'].toSorted(localeCompare);
 * ```
 */
function useLocaleCompare() {
  const language = useLanguage();
  const localeCompare = useCallback(
    function localeCompare(a: string, b: string) {
      return a.localeCompare(b, localeMap[language]);
    },
    [language],
  );
  return localeCompare;
}

export { useLocaleCompare };
