import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import { dataUrlToBlob } from "@zeffiroso/utils/file/dataUrlToBlob";
import { download as downloadFile } from "@zeffiroso/utils/file/download";
import type { ComponentProps, ElementRef } from "react";
import { useImperativeHandle, useMemo, useRef } from "react";
import { mergeRefs } from "react-merge-refs";

import { useQrCodeDataUrlQuery } from "@/components/QrCode/hooks/useQrCodeDataUrlQuery";
import { QrCodeBlock } from "@/components/QrCode/QrCodeBlock";

namespace QrCode {
  export interface Props
    extends Overwrite<
      Overwrite<
        Omit<ComponentProps<"img">, "src">,
        Omit<useQrCodeDataUrlQuery.Options, "queryOptions">
      >,
      {
        queryOptions?: DistributiveOmit<
          useQrCodeDataUrlQuery.Options["queryOptions"],
          "select" | "suspense" | "useErrorBoundary"
        >;
        imperativeHandleRef?: React.Ref<ImperativeHandle>;
      }
    > {}
  export type ImperativeHandle = { download: (fileName: string) => void };
  export type Ref = ElementRef<"img">;
}

/**
 * The QRCode component is used to generate a QR code from a string.
 *
 * This component uses the `useQrCodeDataUrlQuery` hook to generate the QR code
 * and suspends until the data is available, wrapping the component in a
 * Suspense component with a loading fallback if necessary. Instead, you can
 * use `QrCodeBlock` to handle the suspense for you.
 *
 * @see {@link QrCodeBlock}
 */
const QrCode = forwardRef<QrCode.Ref, QrCode.Props>(function QrCode(
  { text, options, queryOptions, imperativeHandleRef, ...props },
  ref,
) {
  const query = useQrCodeDataUrlQuery({
    text,
    options,
    queryOptions: {
      ...queryOptions,
      suspense: true,
      useErrorBoundary: true,
    },
  });
  const localRef = useRef<HTMLImageElement>(null);
  const download = useHandler(async function download(fileName: string) {
    if (!query.isSuccess) return;
    const blob = await dataUrlToBlob(query.data);
    downloadFile({
      obj: blob,
      fileName,
    });
  });
  const imperativeHandle = useMemo<QrCode.ImperativeHandle>(
    () => ({
      download,
    }),
    [download],
  );
  useImperativeHandle(imperativeHandleRef, () => imperativeHandle, [
    imperativeHandle,
  ]);

  const mergedRef = useMemo(() => mergeRefs([localRef, ref]), [ref]);

  if (!query.isSuccess) return null;

  return <img {...props} ref={mergedRef} src={query.data} />;
});

export { QrCode };
