import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import { type FC, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import { DashboardCardLayout } from "@/components/Card/DashBoardLayout";
import { Duration } from "@/components/Card/DashBoardLayout/Duration";
import { RwdFlexCard_3_21 } from "@/components/Card/RwdFlexCard/RwdFlexCard_3_21";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { TooltipInfo } from "@/components/TooltipInfo";
import { Trend } from "@/components/Trend";
import { Diagram } from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram";
import { usePageInfoUtil } from "@/routes/Insights/Efficiency/pageInfo";
import { defineStyles } from "@/shared/emotion";

const TOOLTIP_MAX_WIDTH = 300;

const styles = defineStyles({
  title: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  diagram: css({
    margin: "20px 8px 12px 8px",
  }),
});

const calcPercentageChange = (current: number, previous: number) =>
  (current - previous) / previous;

/**
 * Currently, the `WaitTime` component is only displayed when there are no teams.
 */
const NoWaitTime: FC = () => {
  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.efficiency.waitTime.label" />
        <TooltipInfo
          title={
            <div css={styles.title}>
              <Diagram type="waitTime" css={styles.diagram} />
              <Trans i18nKey="dashboard.efficiency.waitTime.tooltip.title" />
            </div>
          }
          tooltipProps={{
            overlayStyle: { maxWidth: TOOLTIP_MAX_WIDTH },
          }}
        />
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        {EMPTY_STRING_PLACEHOLDER}
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.efficiency.waitTime.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={undefined}
          valueCurrent={undefined}
          valuePrevious={undefined}
          rangeCurrent={undefined}
          rangePrevious={undefined}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};

const WaitTime: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query = cantata.dashboardEfficiency.useWaitTimeAvg(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  if (!query.isSuccess) return null;
  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.efficiency.waitTime.label" />
        <TooltipInfo
          title={
            <div css={styles.title}>
              <Diagram type="waitTime" css={styles.diagram} />
              <Trans i18nKey="dashboard.efficiency.waitTime.tooltip.title" />
            </div>
          }
          tooltipProps={{
            overlayStyle: { maxWidth: TOOLTIP_MAX_WIDTH },
          }}
        />
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        {query.data.currentAvg === null ? (
          EMPTY_STRING_PLACEHOLDER
        ) : (
          <Duration seconds={query.data.currentAvg} />
        )}
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.efficiency.waitTime.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            query.data.previousAvg === null || query.data.currentAvg === null
              ? undefined
              : calcPercentageChange(
                  query.data.currentAvg,
                  query.data.previousAvg,
                )
          }
          valueCurrent={
            query.data.currentAvg === null ? undefined : query.data.currentAvg
          }
          valuePrevious={
            query.data.previousAvg === null ? undefined : query.data.previousAvg
          }
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
          renderTooltipValue={(value) => <Duration seconds={value} />}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};
const FirstResponseTime: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query = cantata.dashboardEfficiency.useFirstResponseTimeAvg(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  if (!query.isSuccess) return null;
  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.efficiency.firstResponseTime.label" />
        <TooltipInfo
          title={
            <div css={styles.title}>
              <Diagram type="firstResponseTime" css={styles.diagram} />
              <Trans i18nKey="dashboard.efficiency.firstResponseTime.tooltip.title" />
            </div>
          }
          tooltipProps={{
            overlayStyle: { maxWidth: TOOLTIP_MAX_WIDTH },
          }}
        />
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        {query.data.currentAvg === null ? (
          EMPTY_STRING_PLACEHOLDER
        ) : (
          <Duration seconds={query.data.currentAvg} />
        )}
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.efficiency.firstResponseTime.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            query.data.previousAvg === null || query.data.currentAvg === null
              ? undefined
              : calcPercentageChange(
                  query.data.currentAvg,
                  query.data.previousAvg,
                )
          }
          valueCurrent={
            query.data.currentAvg === null ? undefined : query.data.currentAvg
          }
          valuePrevious={
            query.data.previousAvg === null ? undefined : query.data.previousAvg
          }
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
          renderTooltipValue={(value) => <Duration seconds={value} />}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};

const ResolutionTime: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query = cantata.dashboardEfficiency.useTtrAvg(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  if (!query.isSuccess) return null;
  return (
    <>
      <DashboardCardLayout.Header>
        {pageInfoUtil.data.teamIds.length > 0 ? (
          <>
            <Trans i18nKey="dashboard.efficiency.resolutionTimeByTeam.label" />
            <TooltipInfo
              title={
                <div css={styles.title}>
                  <Diagram type="teamResolutionTime" css={styles.diagram} />
                  <Trans i18nKey="dashboard.efficiency.resolutionTimeByTeam.tooltip.title" />
                </div>
              }
              tooltipProps={{
                overlayStyle: { maxWidth: TOOLTIP_MAX_WIDTH },
              }}
            />
          </>
        ) : (
          <>
            <Trans i18nKey="dashboard.efficiency.resolutionTime.label" />
            <TooltipInfo
              title={
                <div css={styles.title}>
                  <Diagram type="resolutionTime" css={styles.diagram} />
                  <Trans i18nKey="dashboard.efficiency.resolutionTime.tooltip.title" />
                </div>
              }
              tooltipProps={{
                overlayStyle: { maxWidth: TOOLTIP_MAX_WIDTH },
              }}
            />
          </>
        )}
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        {query.data.currentAvg === null ? (
          EMPTY_STRING_PLACEHOLDER
        ) : (
          <Duration seconds={query.data.currentAvg} />
        )}
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.efficiency.resolutionTime.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            query.data.previousAvg === null || query.data.currentAvg === null
              ? undefined
              : calcPercentageChange(
                  query.data.currentAvg,
                  query.data.previousAvg,
                )
          }
          valueCurrent={
            query.data.currentAvg === null ? undefined : query.data.currentAvg
          }
          valuePrevious={
            query.data.previousAvg === null ? undefined : query.data.previousAvg
          }
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
          renderTooltipValue={(value) => <Duration seconds={value} />}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};

export const EfficiencyAverage: FC = () => {
  const pageInfoUtil = usePageInfoUtil();
  const items = useMemo<ComponentProps<typeof RwdFlexCard_3_21>["items"]>(
    () => [
      <ErrorBoundary.Alert key="waitTime">
        {pageInfoUtil.data.teamIds.length === 0 ? <WaitTime /> : <NoWaitTime />}
      </ErrorBoundary.Alert>,
      <ErrorBoundary.Alert key="firstResponseTime">
        <FirstResponseTime />
      </ErrorBoundary.Alert>,
      <ErrorBoundary.Alert key="resolutionTime">
        <ResolutionTime />
      </ErrorBoundary.Alert>,
    ],
    [pageInfoUtil.data.teamIds.length],
  );

  return <RwdFlexCard_3_21 items={items} />;
};
