import { LoadingOutlined } from "@ant-design/icons";
import { number } from "@chatbotgang/etude/pitch-shifter/number";
import { object } from "@chatbotgang/etude/pitch-shifter/object";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { random } from "@chatbotgang/etude/string/random";
import { css, Global } from "@emotion/react";
import { useDraggableFloatingElementController } from "@zeffiroso/utils/react-lib/useDraggableFloatingElementController";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { FloatButton } from "antd";
import { type FC, useMemo } from "react";
import { createWithEqualityFn } from "zustand/traditional";

import { LOCAL_STORAGE_ZENDESK_FAB_POSITION } from "@/appConstant";
import { LoadScriptOnMountIfOpenedRecently } from "@/internal/zendesk/LoadScriptOnMountIfOpenedRecently";
import { zendesk } from "@/internal/zendesk/sdk";
import { useOpenZendesk } from "@/internal/zendesk/useOpenZendesk";
import { defineStyles } from "@/shared/emotion";
import { generateIcon } from "@/shared/icons/_util/generateIcon";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const zendeskButtonContainerClass = `caac-zendesk-web-widget-button-container-${random()}`;

const positionInLocalStorage = createZustandStorageStore(
  LOCAL_STORAGE_ZENDESK_FAB_POSITION,
  object({
    right: number({
      defaultValue: 24,
    }),
    bottom: number({
      defaultValue: 48,
    }),
  }),
);

const position = createZustandStorageStore(
  LOCAL_STORAGE_ZENDESK_FAB_POSITION,
  object({
    right: number({
      defaultValue: positionInLocalStorage.useStore.getState().value.right,
    }),
    bottom: number({
      defaultValue: positionInLocalStorage.useStore.getState().value.bottom,
    }),
  }),
  {
    storage: sessionStorage,
  },
);

position.useStore.subscribe((store) => {
  positionInLocalStorage.useStore.setState({ value: store.value });
});

const useStore = createWithEqualityFn<{
  setup: boolean;
  opened: boolean;
  badgeCount: number;
}>()(
  () => ({
    setup: false,
    opened: false,
    badgeCount: 0,
  }),
  shallow,
);

function setup() {
  /**
   * Only setup once.
   */
  const storeState = useStore.getState();
  if (storeState.setup) return;
  zendesk.zE("messenger:on", "open", () => {
    useStore.setState({ opened: true });
  });
  zendesk.zE("messenger:on", "close", () => {
    useStore.setState({ opened: false });
  });
  zendesk.zE("messenger:on", "unreadMessages", function (count) {
    useStore.setState({ badgeCount: count });
  });
  useStore.setState({ setup: true });
}

const ZendeskIcon = generateIcon(function ZendeskIcon() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">
      <path
        d="M10,18 L6,22 L6,18 L10,18 Z M17,6 C19.7614237,6 22,8.23857625 22,11 C22,13.7614237 19.7614237,16 17,16 L17,16 L7,16 C4.23857625,16 2,13.7614237 2,11 C2,8.23857625 4.23857625,6 7,6 L7,6 Z"
        transform="translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000) "
      ></path>
    </svg>
  );
});

const Fab: FC = () => {
  const state = zendesk.useState();
  const { opened, badgeCount } = useStore(({ opened, badgeCount }) => ({
    opened,
    badgeCount,
  }));
  const openZendesk = useOpenZendesk();
  const draggableFloatingElementControllerOptions = useMemo<
    Parameters<typeof useDraggableFloatingElementController>[0]
  >(
    () => ({
      positionStore: position.useStore,
      onClick: openZendesk,
    }),
    [openZendesk],
  );
  const draggableController = useDraggableFloatingElementController(
    draggableFloatingElementControllerOptions,
  );
  const badge = useMemo(
    () => (badgeCount > 0 ? { count: badgeCount } : undefined),
    [badgeCount],
  );
  return (
    <FloatButton
      css={draggableController.css}
      type="primary"
      badge={badge}
      icon={state === "loading" ? <LoadingOutlined /> : <ZendeskIcon />}
      style={{
        ...(opened && state === "loaded"
          ? {
              display: "none",
            }
          : {
              display: "block",
            }),
        ...draggableController.style,
      }}
      ref={draggableController.targetRef}
    />
  );
};

const styles = defineStyles({
  hideZendeskButton: css({
    /**
     * Hide the button(iframe) and tip(div) of the Zendesk widget.
     */
    [`.${zendeskButtonContainerClass} > *`]: {
      display: "none !important",
    },
  }),
});

const HideZendeskButton: FC = () => {
  return <Global styles={styles.hideZendeskButton} />;
};

const Zendesk: FC = () => {
  const opened = useStore(({ setup, opened }) => setup && opened);
  return (
    <>
      {opened ? null : <HideZendeskButton />}
      <Fab />
      <LoadScriptOnMountIfOpenedRecently />
    </>
  );
};

assignDisplayName(Zendesk, "Zendesk");

export { setup as setupZendeskFab, Zendesk, zendeskButtonContainerClass };
