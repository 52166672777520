import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useTranslation } from "react-i18next";

import type { ButtonProps } from "@/components/Button";
import { Button } from "@/components/Button";
import { Form } from "@/components/Form";
import type {
  AutoAssignmentRuleRequest,
  FormValues,
} from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";

export const SubmitButton = memo(function SubmitButton({
  status,
}: {
  status: AutoAssignmentRuleRequest["status"];
}) {
  const { t } = useTranslation();
  const form = Form.useFormInstance<FormValues>();
  const onClick = useHandler<ButtonProps["onClick"]>(async (e) => {
    e.preventDefault();
    form.setFieldValue("status", status);
    await form.validateFields();
    form.submit();
  });
  return (
    <Button
      type={status === "on" ? "primary" : "default"}
      htmlType="submit"
      onClick={onClick}
    >
      {status === "on" ? t("assignment.save") : t("assignment.saveDraft")}
    </Button>
  );
});
