import type { FlexBox } from "@line/bot-sdk";
import { get } from "lodash-es";
import type { CSSProperties } from "react";

import type {
  FlexBoxClasses,
  FlexBoxStyles,
} from "@/components/LINEFlexMessage/types";

const absoluteClassName = (
  position: FlexBoxClasses["position"],
): { absolute: boolean } => {
  return { absolute: position === "absolute" };
};

const flexStyleMap = {
  background: "background",
  backgroundColor: "backgroundColor",
  borderColor: "borderColor",
  width: "width",
  height: "height",
  padding: "paddingAll",
  paddingTop: "paddingTop",
  paddingBottom: "paddingBottom",
  paddingLeft: "paddingStart",
  paddingRight: "paddingEnd",
  top: "offsetTop",
  bottom: "offsetBottom",
  left: "offsetStart",
  right: "offsetEnd",
};

const parseFlexBoxStyle = (boxProps: FlexBoxStyles): CSSProperties => {
  const style: CSSProperties = {};
  Object.entries(flexStyleMap)
    .filter(([, flexKey]) => get(boxProps, flexKey))
    .forEach(([cssKey, flexKey]) => {
      // @ts-expect-error ignore css key type
      style[cssKey] = get(boxProps, flexKey);
    });
  if (boxProps.cornerRadius && boxProps.cornerRadius.includes("px"))
    style.borderRadius = boxProps.cornerRadius;

  if (boxProps.background) {
    style.background = `linear-gradient(${boxProps.background.angle}, ${boxProps.background.startColor} 0%, ${boxProps.background.endColor} 100%)`;
  }
  return style;
};

const addPrefix = (input: string | undefined, prefix: string) =>
  input ? prefix + input : undefined;

const sizeClassName = (size: string | undefined): string | undefined =>
  addPrefix(size, "size_");

/**
 * type:
 * - a: all
 * - t: top
 * - b: bottom
 * - l: left
 * - r: right
 */
const marginClassName = (
  margin: string | undefined,
  type: "a" | "t" | "b" | "l" | "r",
): string | undefined => addPrefix(margin, `margin_${type}_`);

const spacingClassName = (spacing: string | undefined): string | undefined =>
  addPrefix(spacing, "spacing_");

const flexClassName = (flex: number | undefined): string | undefined => {
  if (flex !== undefined && flex >= 0 && flex <= 3) return `flex${flex}`;
  return undefined;
};

const flexJustifyContentsClassName = (
  justifyContent: FlexBox["justifyContent"],
): string | undefined => {
  switch (justifyContent) {
    case "flex-start":
      return addPrefix("start", "justify_contents_");
    case "flex-end":
      return addPrefix("end", "justify_contents_");
    case "center":
      return addPrefix("center", "justify_contents_");
    case "space-between":
      return addPrefix("SB", "justify_contents_");
    case "space-around":
      return addPrefix("SA", "justify_contents_");
    case "space-evenly":
      return addPrefix("SE", "justify_contents_");
    default:
      return "";
  }
};

const flexAlignItemsClassName = (
  alignItems: FlexBox["alignItems"],
): string | undefined => {
  switch (alignItems) {
    case "flex-start":
      return addPrefix("start", "align_items_");
    case "flex-end":
      return addPrefix("end", "align_items_");
    case "center":
      return addPrefix("center", "align_items_");
    default:
      return "";
  }
};

const alignClassName = (
  align: "start" | "end" | "center" | undefined,
): string | undefined => addPrefix(align, "align_");

const buttonStyleClassName = (
  style: "link" | "primary" | "secondary" | undefined,
): string | undefined => addPrefix(style, "button_style_");

const cornerRadiusClassName = (
  cornerRadius:
    | string
    | "none"
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "xxl"
    | undefined,
): string | undefined => {
  if (cornerRadius === undefined) return undefined;

  if (["none", "xs", "sm", "md", "lg", "xl", "xxl"].includes(cornerRadius))
    return addPrefix(cornerRadius, "corner_radius_");

  return undefined;
};

export {
  absoluteClassName,
  alignClassName,
  buttonStyleClassName,
  cornerRadiusClassName,
  flexAlignItemsClassName,
  flexClassName,
  flexJustifyContentsClassName,
  marginClassName,
  parseFlexBoxStyle,
  sizeClassName,
  spacingClassName,
};
