import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { makeApi, makeParameters } from "@zodios/core";
import { z } from "zod";

import {
  ChannelTypeSchema,
  DashboardIntervalSchema,
  DashboardMetricsSchema,
  DashboardTotalCountSchema,
} from "../models";

const DashboardDetailSchema = z.object({
  interval: DashboardIntervalSchema,
  countMetrics: DashboardMetricsSchema.array(),
});

const DefaultQueryParameters = makeParameters([
  {
    type: "Query",
    name: "startTime",
    schema: Ymd.YmdToYmdStringSchema,
  },
  {
    type: "Query",
    name: "endTime",
    schema: Ymd.YmdToYmdStringSchema,
  },
  {
    type: "Query",
    name: "channelId",
    schema: z.number().int().array().optional(),
  },
  {
    type: "Query",
    name: "channelType",
    schema: ChannelTypeSchema.array().optional(),
  },
  {
    type: "Query",
    name: "teamId",
    schema: z.number().int().array().optional(),
  },
  {
    type: "Query",
    name: "interval",
    schema: DashboardIntervalSchema.optional(),
  },
]);

const DashboardQueryParameters = makeParameters([
  ...DefaultQueryParameters,
  {
    type: "Query",
    name: "isGroup",
    schema: z.boolean().optional(),
  },
]);

const api = makeApi([
  /**
   * New channel member metrics
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#b225114269ff441b9067a4673c39413b)
   */
  {
    alias: "newChannelMemberDetail",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/member/new-member/detail",
    parameters: DashboardQueryParameters,
    response: DashboardDetailSchema,
  },
  /**
   * New channel member total count
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#89ab69cc76074244ae9230444f6726a4)
   */
  {
    alias: "newChannelMemberTotalCount",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/member/new-member/total-count",
    parameters: DashboardQueryParameters,
    response: DashboardTotalCountSchema,
  },
  /**
   * Handled channel member metrics
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#470a1aebe3d74ea6b509608127b138cb)
   */
  {
    alias: "handledChannelMemberetail",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/member/handled-member/detail",
    parameters: DashboardQueryParameters,
    response: DashboardDetailSchema,
  },
  /**
   * Handled channel member total count
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#5e4c51e386fb4b35b51cb7852c5b3e35)
   */
  {
    alias: "handledChannelMemberTotalCount",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/member/handled-member/total-count",
    parameters: DashboardQueryParameters,
    response: DashboardTotalCountSchema,
  },
  /**
   * Member added tag metrics
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#d4896d2b5deb4946ab4cd1e190f61ce2)
   */
  {
    alias: "addedTag",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/member/top5-tag/detail",
    parameters: DefaultQueryParameters,
    response: z.object({
      interval: DashboardIntervalSchema,
      tagMetrics: z
        .object({
          tagId: z.number().int(),
          tagName: z.string(),
          countMetrics: DashboardMetricsSchema.array(),
        })
        .array(),
    }),
  },
  /**
   * Export member metrics to csv
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#fee0e4fb64a44c77b428d9bb75db3257)
   */
  {
    alias: "exportCsv",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/member/export-csv",
    parameters: DefaultQueryParameters,
    response: z.string(),
    responseType: "blob",
  },
]);

export { api };
