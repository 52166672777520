import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import classNames from "classnames";
import { type FC, type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";
import { AccountPanel } from "@/layout/base/AuthenticatedAppOuter/components/AccountPanel";
import { routerUtils } from "@/router/routerUtils";
import type { BasePathName } from "@/router/types";
import { useMatchCurrentPath } from "@/router/utils/matchPath";
import { defineStyles } from "@/shared/emotion";

const seed = `app-outer-nav-bar-${random()}`;

const activeEntryClassName = `${seed}-active-entry`;
const iconClassName = `${seed}-icon`;
const activeIconClassName = `${seed}-active-icon`;

const styles = defineStyles({
  root: css({
    backgroundColor: theme.colors.blue005,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: theme.colors.blue006,
    color: theme.colors.white,
    ".anticon": {
      color: "inherit",
    },
    a: {
      color: theme.colors.blue003,
      transition: "color 0.3s ease-in-out",
    },
    [["a:hover", `.${activeEntryClassName} a`].join(",")]: {
      color: theme.colors.white,
    },
    paddingBottom: 24,
  }),
  crescendoLabLogo: css({
    fontSize: "2rem",
    paddingInline: 8,
    paddingTop: 24,
    paddingBottom: 12,
  }),
  entries: css({
    display: "flex",
    alignItems: "inherit",
    justifyContent: "inherit",
    flexDirection: "inherit",
  }),
  entry: css({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    fontSize: "1.5rem",
    [[`& .${iconClassName}`, `& .${activeIconClassName}`].join(",")]: {
      transition: "opacity 0.3s ease-in-out",
    },
    [`& .${activeIconClassName}`]: {
      position: "absolute",
      inset: 0,
    },
    [`&.${activeEntryClassName} .${iconClassName}`]: {
      opacity: 0,
    },
    [`&:not(.${activeEntryClassName}) .${activeIconClassName}`]: {
      opacity: 0,
    },
  }),
  space: css({
    flex: 1,
  }),
  helpingCenter: css({
    padding: 12,
  }),
  accountPanel: css({
    padding: 8,
  }),
});

type EntryProps = {
  label: ReactNode;
  icon: ReactNode;
  activeIcon: ReactNode;
  path: BasePathName;
};

const entriesProps: Array<EntryProps> = [
  {
    label: <Trans i18nKey="menu.insights.label" />,
    icon: <MotifIcon un-i-motif="chart" />,
    activeIcon: <MotifIcon un-i-motif="chart_fill" />,
    path: "/insights",
  },
  {
    label: <Trans i18nKey="page.chat.title" />,
    icon: <MotifIcon un-i-motif="bubble_chat" />,
    activeIcon: <MotifIcon un-i-motif="bubble_chat_fill" />,
    path: "/chat",
  },
  {
    label: <Trans i18nKey="menu.templates" />,
    icon: <MotifIcon un-i-motif="thunder" />,
    activeIcon: <MotifIcon un-i-motif="thunder_fill" />,
    path: "/quick-templates",
  },
  {
    label: <Trans i18nKey="menu.settings" />,
    icon: <MotifIcon un-i-motif="settings" />,
    activeIcon: <MotifIcon un-i-motif="settings_fill" />,
    path: "/settings",
  },
];

const Entry: FC<EntryProps> = ({ label, icon, activeIcon, path }) => {
  const matchCurrentPath = useMatchCurrentPath();
  const isActive = Boolean(
    matchCurrentPath({
      path,
      end: false,
    }),
  );
  return (
    <li
      css={styles.entry}
      className={classNames({
        [activeEntryClassName]: isActive,
      })}
    >
      <Tooltip title={label} placement="right">
        <NarrowIconButton
          icon={
            <>
              <div className={iconClassName}>{icon}</div>
              <div className={activeIconClassName}>{activeIcon}</div>
            </>
          }
          href={path}
        />
      </Tooltip>
    </li>
  );
};

const NavBar: FC = () => {
  const { t } = useTranslation();
  const checkRoutePermission = routerUtils.useCheckRoutePermission();
  const availableEntries = useMemo(
    () =>
      entriesProps.flatMap((item) => {
        if (!checkRoutePermission(item.path)) {
          return [];
        }
        return <Entry key={item.path} {...item} />;
      }),
    [checkRoutePermission],
  );
  return (
    <nav css={styles.root}>
      <div css={styles.crescendoLabLogo}>
        <MotifIcon un-i-motif="logo-cresclab_icon" />
      </div>
      <ul css={styles.entries}>{availableEntries}</ul>
      <div css={styles.space} />
      <div css={styles.helpingCenter}>
        <Tooltip title={t("layout.nav.hc.tooltip")} placement="right">
          <NarrowIconButton
            href={t("common.helpingLink")}
            icon={<MotifIcon un-i-motif="circle_question" />}
          />
        </Tooltip>
      </div>
      <div css={styles.accountPanel}>
        <AccountPanel
          popoverProps={{
            align: {
              offset: [16, 4],
            },
            placement: "rightBottom",
          }}
        />
      </div>
    </nav>
  );
};

export { NavBar };
