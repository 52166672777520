import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { type FC, useMemo } from "react";
import type { RequireAtLeastOne } from "type-fest";

import { HIERARCHY_STRING_JOINER } from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";
import { Unnamed } from "@/resources/user/Unnamed";

type AssigneeProps = RequireAtLeastOne<
  {
    team: Pick<CantataTypes["Team"], "name">;
    user: Pick<CantataTypes["User"], "name" | "email">;
  },
  "team" | "user"
>;

type Variant = "team" | "user-in-team" | "user";

const AssigneeName: FC<AssigneeProps> = (props) => {
  const variant = useMemo<Variant>(
    function getVariant() {
      if (props.team && !props.user) return "team";
      if (!props.team && props.user) return "user";
      if (props.team && props.user) return "user-in-team";
      throw new Error("Require at least one of team or user");
    },
    [props.team, props.user],
  );

  const fallback = <Unnamed />;
  const teamName = props.team?.name || fallback;
  const userName = props.user?.name || props.user?.email || fallback;
  switch (variant) {
    case "team":
      return teamName;
    case "user":
      return userName;
    case "user-in-team":
      return `${teamName}${HIERARCHY_STRING_JOINER}${userName}`;
    default:
      variant satisfies never;
      throw new Error(inspectMessage`Invalid variant: ${variant}`);
  }
};

export { AssigneeName };
