import { css } from "@emotion/react";
import type { FC } from "react";

import { Divider } from "@/components/Divider";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { ChatStateAlert } from "@/routes/Chat/ui/ChatPanel/ChatStateAlert";
import { DebugTools } from "@/routes/Chat/ui/ChatPanel/Debug";
import { Editor } from "@/routes/Chat/ui/ChatPanel/Editor";
import { QuickTemplateDrawer } from "@/routes/Chat/ui/ChatPanel/Editor/Old/QuickTemplates";
import { ChatPanelHeader } from "@/routes/Chat/ui/ChatPanel/Header";
import { ChatPanelHistory } from "@/routes/Chat/ui/ChatPanel/History";
import { RecommendQuickTemplate } from "@/routes/Chat/ui/ChatPanel/RecommendQuickTemplate";
import { SearchBar } from "@/routes/Chat/ui/ChatPanel/SearchBar";
import { ReplyMessageControllerProvider } from "@/routes/Chat/ui/replyMessage";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css([
    cssFlexInheritAndFill,
    {
      flexDirection: "column",
    },
  ]),
});

const ChatPanel: FC = () => {
  return (
    <div css={styles.root}>
      <memberQueriesContext.Provider>
        <ReplyMessageControllerProvider>
          <ChatPanelHeader />
          <Divider />
          <SearchBar />
          <ChatPanelHistory />
          <ChatStateAlert />
          <QuickTemplateDrawer>
            <RecommendQuickTemplate />
            <Editor />
          </QuickTemplateDrawer>
          <DebugTools />
        </ReplyMessageControllerProvider>
      </memberQueriesContext.Provider>
    </div>
  );
};

export { ChatPanel };
