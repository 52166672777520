import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { RouteTabs } from "@/components/Tabs/RouteTabs";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { Navigate } from "@/router/utils";
import { defineChildRouteObject } from "@/router/utils/defineChildRouteObject";
import { GeneralSetting } from "@/routes/Settings/Assignment/pages/GeneralSetting";
import { IndividualAssignmentSetting } from "@/routes/Settings/Assignment/pages/IndividualAssignmentSetting";
import { RoutingRules } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RoutingRules";
import { RuleForm } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm";

const Tabs: FC = () => {
  const { t } = useTranslation();
  const items = useMemo<ComponentProps<typeof RouteTabs>["items"]>(
    () => [
      {
        key: "/settings/assignment/tabs",
        label: t("assignment.generalSetting"),
        children: <Outlet />,
      },
      {
        key: "/settings/assignment/tabs/routing-rules",
        label: t("menu.assignmentRoutingRule"),
        children: <Outlet />,
      },
      {
        key: "/settings/assignment/tabs/individual-setting",
        label: t("menu.individualSetting"),
        children: <Outlet />,
      },
    ],
    [t],
  );
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header>{t("menu.assignmentSetting")}</MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <MainLayout.Section>
            <RouteTabs items={items} />
          </MainLayout.Section>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
};

const assignmentRouteObject = defineChildRouteObject({
  children: [
    {
      index: true,
      element: <Navigate to="/settings/assignment/tabs" replace />,
    },
    {
      path: "routing-rules",
      children: [
        {
          path: "edit/:ruleId",
          element: <RuleForm />,
        },
        {
          path: "view/:ruleId",
          element: <RuleForm />,
        },
        {
          path: "create",
          element: <RuleForm />,
        },
        {
          path: "*",
          element: (
            <Navigate to="/settings/assignment/tabs/routing-rules" replace />
          ),
        },
      ],
    },
    {
      path: "tabs",
      element: <Tabs />,
      children: [
        {
          index: true,
          element: <GeneralSetting />,
        },
        {
          path: "routing-rules",
          element: <RoutingRules />,
        },
        {
          path: "individual-setting",
          element: <IndividualAssignmentSetting />,
        },
        {
          path: "*",
          element: <Navigate to="/settings/assignment/tabs" replace />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/settings/assignment/tabs" replace />,
    },
  ],
});

export { assignmentRouteObject };
