import { makeApi } from "@zodios/core";
import { z } from "zod";

import { CdpTagSchema } from "../models";

const api = makeApi([
  {
    alias: "getByMemberId",
    method: "get",
    path: "api/v1/orgs/:orgId/tag/tags/member/:memberId",
    response: z.object({
      tagIds: z.array(z.number()),
    }),
  },
  {
    alias: "getCdpTagsByMemberId",
    method: "get",
    path: "api/v1/orgs/:orgId/cdp/members/:memberId/tags",
    response: z.object({
      tags: z.array(CdpTagSchema),
    }),
  },
  {
    alias: "add",
    method: "post",
    path: "api/v1/orgs/:orgId/tag/tags/member",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          memberId: z.number(),
          tagId: z.number(),
        }),
      },
    ],
    response: z.void(),
  },
  {
    alias: "remove",
    method: "delete",
    path: "api/v1/orgs/:orgId/tag/tags/member",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          memberId: z.number(),
          tagId: z.number(),
        }),
      },
    ],
    response: z.void(),
  },
]);

export { api };
