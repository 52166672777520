import { fc } from "@chatbotgang/etude/react/fc";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { Alert } from "@/components/Alert";
import { ExternalLink } from "@/components/ExternalLink";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { useChatState } from "@/resources/member/useChatState";

const cssWrapper = css`
  padding: 24px 16px 16px 12px;
`;

const ChatStateAlert = fc(function ChatStateAlert() {
  const member = memberQueriesContext.useMember();
  const chatState = useChatState(member);
  const { t } = useTranslation();

  if (chatState !== "AlmostCannotSendAnyContent") return null;

  return (
    <div css={cssWrapper}>
      <Alert
        type="warning"
        action={
          <ExternalLink href={t("chat.fb.almostExpired.learnMore.link")}>
            <Trans i18nKey="chat.fb.almostExpired.learnMore.text" />
          </ExternalLink>
        }
        message={<Trans i18nKey="chat.fb.almostExpired.message" />}
      />
    </div>
  );
});

export { ChatStateAlert };
