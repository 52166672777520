import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

type AccountPanelState = {
  page: "closed" | "home" | "onDutyStatus";
};

const useAccountPanelStore = createWithEqualityFn<AccountPanelState>(
  () => ({
    page: "closed",
  }),
  shallow,
);

const switchAccountPanelPage = (page: AccountPanelState["page"]) => {
  useAccountPanelStore.setState({ page });
};

const openAccountPanel = () => {
  switchAccountPanelPage("home");
};

const closeAccountPanel = () => {
  switchAccountPanelPage("closed");
};

export {
  closeAccountPanel,
  openAccountPanel,
  switchAccountPanelPage,
  useAccountPanelStore,
};

export type { AccountPanelState };
