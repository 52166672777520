import { isSupported } from "firebase/messaging";
import PQueue from "p-queue";

let cache: Awaited<ReturnType<typeof isSupported>> | undefined;
const queue = new PQueue({ concurrency: 1 });

const isSupportedCached = () =>
  queue.add(async function isSupportedCached(): ReturnType<typeof isSupported> {
    if (cache === undefined) cache = await isSupported();

    return cache;
  });

export { isSupportedCached as isSupported };
