import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { useMutation } from "@tanstack/react-query";
import { ADMIN_CENTER_HOST } from "@zeffiroso/env";
import { addMilliseconds, secondsToMilliseconds } from "date-fns";
import PQueue from "p-queue";
import type { SyntheticEvent } from "react";
import { useMemo } from "react";
import urlJoin from "url-join";
import { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { LOCAL_STORAGE_PLATFORM_TOKEN } from "@/appConstant";
import { cantataClient } from "@/cantata";
import { useHandleErrorAndShowMessage } from "@/shared/application/error/handleError";
import type { AvailableLanguage } from "@/shared/hooks/formatLangCode";
import { useLanguage } from "@/shared/hooks/useLanguage";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const APPLICATION_TOKEN_EXPIRATION_MS = secondsToMilliseconds(30);

/**
 * https://github.com/chatbotgang/Polifonia/blob/4fc1c4f/packages/app/src/config/availableLocales.ts
 */
const langMap = {
  "zh-hant": "zh-hant",
  en: "en",
  th: "th",
  ja: "ja",
} satisfies Record<AvailableLanguage, string>;

const TokenStoreSchema = z
  .null()
  .or(z.object({ token: z.string(), createdAt: z.string() }));

const platformTokenStore = createZustandStorageStore(
  LOCAL_STORAGE_PLATFORM_TOKEN,
  function parser(input) {
    const parseResult = TokenStoreSchema.safeParse(input);
    if (parseResult.success) {
      return parseResult.data;
    }
    return null satisfies z.infer<typeof TokenStoreSchema>;
  },
);

function clearTokenCache() {
  platformTokenStore.useStore.setState({ value: null });
}

function setupToken(token: string) {
  platformTokenStore.useStore.setState({
    value: {
      token,
      createdAt: new Date().toISOString(),
    },
  });
}

function getTokenCacheFromState(
  state: ReturnType<typeof platformTokenStore.useStore.getState>,
) {
  if (!state.value) return state.value;
  const createdAt = new Date(state.value.createdAt);
  const expiredAt = addMilliseconds(createdAt, APPLICATION_TOKEN_EXPIRATION_MS);
  if (expiredAt < new Date() || isInvalidDate(expiredAt)) return null;
  return {
    ...state.value,
    expiredAt,
  };
}

(function clearTokenIfExpired() {
  let localTimeout: ReturnType<typeof setTimeout> | null = null;
  function clearLocalTimeout() {
    if (!localTimeout) return;
    clearTimeout(localTimeout);
    localTimeout = null;
  }
  function setLocalTimeout() {
    clearLocalTimeout();
    const state = platformTokenStore.useStore.getState();
    const tokenCache = getTokenCacheFromState(state);
    if (!tokenCache) {
      /**
       * If the token is expired or invalid, clear it.
       */
      if (state.value) {
        clearTokenCache();
      }
      return;
    }
    localTimeout = setTimeout(
      clearTokenCache,
      tokenCache.expiredAt.getTime() - Date.now(),
    );
  }
  setLocalTimeout();
  platformTokenStore.useStore.subscribe(setLocalTimeout);
})();

(function clearIfOrgIdChanged() {
  useActiveOrgIdStore.subscribe(clearTokenCache);
})();

function useCache() {
  const cache = platformTokenStore.useStore(getTokenCacheFromState);
  return cache;
}

type RoutePath = "billing" | "applications" | "";

function generateLink({
  routePath = "",
  token = "",
  lang = "en",
}: {
  routePath?: RoutePath;
  token?: string;
  lang?: AvailableLanguage;
} = {}) {
  const baseUrlString = urlJoin(ADMIN_CENTER_HOST, routePath);
  const url = new URL(baseUrlString);
  if (langMap[lang]) {
    url.searchParams.set("lang", langMap[lang]);
  }
  if (token) url.searchParams.set("token", token);
  return url.href;
}

const pQueue = new PQueue({ concurrency: 1 });

function usePlatformLink({
  path = "",
}: {
  path?: "billing" | "applications" | "";
}) {
  const language = useLanguage();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const tokenCache = useCache();

  const handleErrorAndShowMessage = useHandleErrorAndShowMessage();

  const mutation = useMutation({
    async mutationFn(): Promise<{
      cache: boolean;
      token: string;
    }> {
      const result = await pQueue.add(async function handleClick() {
        const state = platformTokenStore.useStore.getState();
        const tokenCache = getTokenCacheFromState(state);
        if (tokenCache && tokenCache.token && tokenCache.expiredAt > new Date())
          return { cache: true, token: tokenCache.token };
        const result = await cantataClient.platform.token({ orgId });
        if (!result.token) {
          throw new Error(
            inspectMessage`No token returned from mutation: ${result}`,
          );
        }

        setupToken(result.token);
        return {
          cache: false,
          token: result.token,
        };
      });
      if (!result) {
        throw new Error(inspectMessage`result is falsy: ${result}`);
      }
      return result;
    },
    onError(error) {
      handleErrorAndShowMessage(error);
    },
  });

  const href = useMemo(() => {
    return generateLink({
      routePath: path,
      ...(!tokenCache ? null : { token: tokenCache.token }),
      lang: language,
    });
  }, [path, tokenCache, language]);

  const handleClick = useHandler<
    (e: Pick<SyntheticEvent, "preventDefault">) => void
  >(async function handleClick(e) {
    e.preventDefault();
    const result = await safePromise(async () => await mutation.mutateAsync());
    if (result.isError) {
      return;
    }
    const url = generateLink({
      routePath: path,
      token: result.data.token,
      lang: language,
    });
    window.open(url, "_blank");
  });

  return {
    href,
    isLoading: mutation.isLoading,
    handleClick,
  };
}

export { usePlatformLink };
