import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { type FC, type ReactNode, useMemo } from "react";

import { cantata } from "@/cantata";

function useRootQueries() {
  const organizationsQuery = cantata.org.useList(undefined, {
    suspense: true,
    useErrorBoundary: true,
    select: (data) => data.organizations,
  });
  const accountQuery = cantata.credential.useMe(undefined, {
    suspense: true,
    useErrorBoundary: true,
  });
  const queries = useMemo(
    () => ({ organizations: organizationsQuery, account: accountQuery }),
    [accountQuery, organizationsQuery],
  );
  return queries;
}

const RootQueriesContext =
  createQueriesContext<ReturnType<typeof useRootQueries>>();

const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const queries = useRootQueries();
  return (
    <RootQueriesContext.Provider queries={queries}>
      {children}
    </RootQueriesContext.Provider>
  );
};

const rootQueriesContext = {
  useData: RootQueriesContext.useData,
  Provider,
};

export { rootQueriesContext };
