import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { checkKey } from "@chatbotgang/etude/event/keycode";
import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import { API_BULK_ACTION_LIMIT } from "@zeffiroso/env";
import { theme } from "@zeffiroso/theme";
import { Badge, Skeleton } from "antd";
import type { ComponentProps, FC } from "react";
import { Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { Avatar } from "@/components/Avatar";
import type { CheckboxProps } from "@/components/Checkbox";
import { Checkbox } from "@/components/Checkbox";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { Highlight } from "@/components/Highlight";
import { MotifIcon } from "@/components/MotifIcon";
import { useMessage } from "@/internal/message";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { ChannelTypeChattingIcon } from "@/resources/channel/ChannelTypeIcon";
import { useFormatLastEngagement } from "@/resources/datetime";
import { GroupMemberNameById } from "@/resources/groupMember/GroupMemberNameById";
import { getMemberDisplayName } from "@/resources/member/displayName";
import { MemberAssignment } from "@/resources/member/MemberAssignment";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { MemberQueries } from "@/resources/member/MemberQueries";
import { useChatState } from "@/resources/member/useChatState";
import { isDeleted } from "@/resources/message/isDeleted";
import { UserNameById } from "@/resources/user/UserNameById";
import { useJumpToMessageController } from "@/routes/Chat/ui/jumpToMessage";
import { useGetChannel } from "@/routes/Chat/ui/MembersPanel/controllers/channelsQueryController";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { useSelectionController } from "@/routes/Chat/ui/MembersPanel/controllers/selectionController";
import { Actions } from "@/routes/Chat/ui/MembersPanel/MemberItem/Actions";
import { MemberItemContext } from "@/routes/Chat/ui/MembersPanel/MemberItem/MemberItemContext";
import { useSearchMessageFromAMember } from "@/routes/Chat/ui/searchMessageFromMember";
import {
  cssFlexInheritAndFill,
  cssLineClamp,
  cssOneLine,
  defineStyles,
} from "@/shared/emotion";

const seed = random();
const className = `member-item-${seed}`;
const cssStaticBackgroundVariableName = `--active-background-${random()}`;
/**
 * @see {@link https://www.figma.com/file/k0XwP83RAV16nVdyFx7crb/Omnichannel-inbox?type=design&node-id=2663-131995&mode=design&t=lwzi4fhULfwqRRac-0}
 */
const memberItemCss = defineStyles({
  root: css([
    cssFlexInheritAndFill,
    css`
      width: auto;
      align-items: center;
      justify-content: center;
    `,
  ]),

  self: css([
    cssFlexInheritAndFill,
    {
      flex: "0 0 100%",
      flexDirection: "row",
      padding: "16px 12px",
      borderBottom: `1px solid ${theme.colors.neutral003}`,
      background: `var(${cssStaticBackgroundVariableName})`,
      gap: 8,

      "&:hover": {
        background: theme.colors.blue002,
      },

      "&:focus": {
        background: theme.colors.blue002,
        outline: "none",
      },

      transition: "background 0.2s ease-in-out",
    },
  ]),

  infoAvatar: css({
    width: 44,
  }),

  infoAvatarChannel: css({
    padding: 2,
    borderRadius: "20px",
    background: "white",
    fontSize: "1rem",
  }),

  infoNameMessage: css([
    cssFlexInheritAndFill,
    {
      flexDirection: "column",
      gap: 2,
    },
  ]),

  infoName: css([
    cssFlexInheritAndFill,
    {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      color: theme.colors.neutral009,
      gap: 8,
      flexBasis: "28px",
    },
  ]),

  nameAndPin: css({
    display: "flex",
    overflow: "hidden",
    flex: 1,
    gap: 4,
  }),

  name: css([
    {
      color: theme.colors.neutral009,
      fontSize: "0.875rem",
      fontWeight: "500",
    },
    cssOneLine,
  ]),

  greyoutName: css({
    color: theme.colors.neutral006,
  }),

  actionButtons: css({
    display: "flex",
    flex: "0 0 auto",
    alignItems: "center",
    gap: 8,
  }),

  time: css({
    flex: "1 0 auto",
    color: theme.colors.neutral007,
    fontSize: "0.75rem",
    lineHeight: 1.33,
  }),

  infoMessage: css([
    cssFlexInheritAndFill,
    {
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 8,
      flexBasis: "40px",
    },
  ]),

  message: css([
    cssLineClamp(2),
    {
      color: theme.colors.neutral007,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    },
  ]),

  unreadWrapper: css({
    flex: "0 0 auto",
  }),

  unread: css({
    padding: "0.125rem 0.375rem",
    borderRadius: "1rem",
    background: theme.colors.blue005,
    color: theme.colors.white,
    fontSize: "0.75rem",
    lineHeight: "1rem",
  }),

  infoLine: css([
    cssFlexInheritAndFill,
    {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 8,
      flexBasis: "24px",
    },
  ]),

  star: css({
    display: "flex",
    padding: "0.25rem",
    borderRadius: "1.5rem",
    background: theme.colors.yellow002,
    color: theme.colors.yellow006,
  }),

  greyout: css({
    opacity: 0.3,
  }),

  deletedMessage: css({
    display: "flex",
    alignItems: "center",
    gap: "4px",
  }),
});

function withBackground(isActive: boolean): string {
  return isActive ? theme.colors.blue001 : theme.colors.white;
}

const SpeakerName: FC = (() => {
  /**
   * Render the speaker name when the message is sent by the user or the member of the group
   */
  const SpeakerName: FC = () => {
    const member = MemberItemContext.useMember();
    const orgQueriesData = orgQueriesContext.useData();
    const meId = orgQueriesData.me.id;
    const message = member.lastMessage?.message;

    if (
      !message ||
      !(message.senderType === "user" || message.senderType === "member")
    )
      return null;

    if (message.speakerId === null) {
      throw new Error(inspectMessage`speakerId in message ${message} is null`);
    }

    if (message.senderType === "user") {
      if (message.speakerId === meId)
        return <Trans i18nKey="resource.message.speaker.me" />;

      return <UserNameById userId={message.speakerId} />;
    }

    if (member.type === "line_group") {
      return (
        <GroupMemberNameById groupId={member.id} memberId={message.speakerId} />
      );
    }
    return null;
  };

  const Wrapper: FC = () => {
    return (
      <ErrorBoundary>
        <Suspense fallback={<Skeleton.Input size="small" />}>
          <SpeakerName />
        </Suspense>
      </ErrorBoundary>
    );
  };
  assignDisplayName(Wrapper, "SpeakerName");
  return Wrapper;
})();

const DeletedMessageFallback: FC = () => {
  return (
    <div css={memberItemCss.deletedMessage}>
      <MotifIcon un-i-motif="circle_caution" />
      <Trans i18nKey="resource.message.deleted.fallbackMessage" />
    </div>
  );
};

const MessageWithSpeaker: FC<
  Pick<NonNullable<CantataTypes["Member"]["lastMessage"]>, "message">
> = ({ message }) => {
  switch (message.type) {
    case "text":
      return (
        <Trans
          i18nKey="resource.message.text.speaker.message.alt"
          components={{
            speaker: <SpeakerName />,
          }}
          values={{
            text: message.text,
          }}
        />
      );
    case "image":
      return (
        <Trans
          i18nKey="resource.message.image.speaker.message.alt"
          components={{
            speaker: <SpeakerName />,
          }}
        />
      );
    case "video":
      return (
        <Trans
          i18nKey="resource.message.video.speaker.message.alt"
          components={{
            speaker: <SpeakerName />,
          }}
        />
      );
    case "audio":
      return (
        <Trans
          i18nKey="resource.message.audio.speaker.message.alt"
          components={{
            speaker: <SpeakerName />,
          }}
        />
      );
    case "file":
    case "line_flex":
      return (
        <Trans
          i18nKey="resource.message.file.speaker.message.alt"
          components={{
            speaker: <SpeakerName />,
          }}
        />
      );
    case "ig_story_mention":
      return (
        <Trans i18nKey="resource.message.igStoryMention.byMember.message.alt" />
      );
    default:
      message.type satisfies never;
      throw new Error(inspectMessage`Unsupported message type ${message.type}`);
  }
};

const MemberMessage: FC<
  Pick<NonNullable<CantataTypes["Member"]["lastMessage"]>, "message">
> = ({ message }) => {
  const { t } = useTranslation();
  switch (message.type) {
    case "text":
      return message.text;
    case "image":
      return t("chat.memberPanel.memberSentPhoto");
    case "video":
      return t("chat.memberPanel.memberSentVideo");
    case "audio":
      return t("chat.memberPanel.memberSentAudio");
    case "file":
    case "line_flex":
      return t("chat.memberPanel.memberSentFile");
    case "ig_story_mention":
      return t("resource.message.igStoryMention.byMember.message.alt");
    default:
      message.type satisfies never;
      throw new Error(inspectMessage`Unsupported message type ${message.type}`);
  }
};

/**
 * Fallback for displaying user message when speakerId is null.
 */
const UnknownUserMessage: FC<
  Pick<NonNullable<CantataTypes["Member"]["lastMessage"]>, "message">
> = ({ message }) => {
  const { t } = useTranslation();
  switch (message.type) {
    case "text":
      return message.text;
    case "image":
      return t("chat.oaSentPhoto");
    case "video":
      return t("chat.oaSentVideo");
    case "audio":
      return t("chat.oaSentAudio");
    case "file":
    case "line_flex":
      return t("chat.oaSentFile");
    case "ig_story_mention":
      // Do nothing, disabled for now.
      return "ig_story_mention";
    default:
      message.type satisfies never;
      throw new Error(inspectMessage`Unsupported message type ${message.type}`);
  }
};

const DisplayMessage: FC = () => {
  const member = MemberItemContext.useMember();
  const showDeletedMessageFallback = useFeatureFlag("deletedMessageFallback");

  if (member.lastMessage === null) return "";

  const message = member.lastMessage.message;

  if (!(message.senderType === "user" || message.senderType === "member"))
    return "";

  if (showDeletedMessageFallback && isDeleted(message)) {
    return <DeletedMessageFallback />;
  }

  if (message.senderType === "user" && message.speakerId === null) {
    /**
     * Edge case: some early messages imported from legacy system might not have speakerId.
     * - [#product-caac](https://chatbotgang.slack.com/archives/C0737N16Y65/p1726114426835969)
     */
    return <UnknownUserMessage message={message} />;
  }

  if (message.senderType === "user" || member.type === "line_group") {
    return <MessageWithSpeaker message={message} />;
  }

  return <MemberMessage message={message} />;
};

const ChannelIcon = fc<{ channelId: CantataTypes["Channel"]["id"] }>(
  function ChannelIcon({ channelId }) {
    const channel = useGetChannel(channelId);

    if (!channel) return null;

    return <ChannelTypeChattingIcon channel={channel} />;
  },
);

const LastEngagement: FC = () => {
  const member = MemberItemContext.useMember();
  const formatLastEngagement = useFormatLastEngagement();
  const search = searchController.useStore((state) => state.search);
  // display last engaged at
  const lastEngagement = useMemo(() => {
    if (
      search.action === "messages" &&
      member.matchedMessage?.lastMatchedMessageAt
    )
      return formatLastEngagement(member.matchedMessage.lastMatchedMessageAt);
    else if (member.lastMessage && !isInvalidDate(member.lastMessageAt))
      return formatLastEngagement(member.lastMessageAt);
    else if (!member.lastMessage && member.type === "line_group") {
      return formatLastEngagement(member.createdAt);
    } else return "";
  }, [
    formatLastEngagement,
    member.createdAt,
    member.lastMessage,
    member.lastMessageAt,
    member.matchedMessage?.lastMatchedMessageAt,
    member.type,
    search.action,
  ]);
  return lastEngagement;
};

const DisplayMessageBasedOnSearchAction: FC = () => {
  const { t } = useTranslation();
  const member = MemberItemContext.useMember();
  const search = searchController.useStore((state) => state.search);
  const isSearching = searchController.useStore(
    searchController.selectors.isSearching,
  );

  if (!isSearching) return null;

  const searchAction = search.action;
  switch (searchAction) {
    case "messages":
      if (member.matchedMessage === null) throw new Error("no matched message");
      return t("chat.messagesFound", {
        count: member.matchedMessage.matchedMessageCount,
      });
    case "notes":
      return t("chat.foundByNote");
    case "tags":
      return t("chat.foundByTag");
    case "external-member-id":
    case "names":
    case "emails":
    case "mobiles":
      return <DisplayMessage />;
    default:
      searchAction satisfies never;
      throw new Error("invalid search action");
  }
};

const MemberItem: FC = () => {
  const member = MemberItemContext.useMember();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const activeMemberId = memberIdUtils.useGet();
  const setActiveMemberId = memberIdUtils.useSet();
  const search = searchController.useStore((state) => state.search);
  const isSearching = searchController.useStore(
    searchController.selectors.isSearching,
  );
  const searchMessageFromAMember = useSearchMessageFromAMember();
  const selectionController = useSelectionController();
  const selectedMembers = selectionController.useSelectedMembers();
  const isSelecting = selectionController.useIsSelecting();
  const isReachSelectionLimit = selectionController.useIsReachedLimit();
  const { t } = useTranslation();
  const jumpToMessageController = useJumpToMessageController();
  const message = useMessage();

  /** highlight the name if the search query is not empty */
  const nameHighlight = useMemo(() => {
    if (search.action !== "names") return undefined;

    return search.query;
  }, [search]);

  const selectMember = useHandler(function selectMember() {
    searchMessageFromAMember.closeSearchInput();
    setActiveMemberId(member.id);
    if (isSearching && search.action === "messages") {
      searchMessageFromAMember.setSearchInputText(search.query);
      searchMessageFromAMember.setSearchText(search.query);
      searchMessageFromAMember.search(search.query);
      jumpToMessageController.setupSearch({
        orgId,
        memberId: member.id,
        query: search.query,
      });
    } else {
      jumpToMessageController.clear();
    }
  });

  const handleSelectChange = useHandler<CheckboxProps["onChange"]>(
    function handleSelectChange(event) {
      if (event.target.checked) {
        if (isReachSelectionLimit) {
          message.warning(
            t("chat.memberPanel.bulkAssign.assign.reachMaxLimit", {
              count: API_BULK_ACTION_LIMIT,
            }),
          );
          return;
        }
        selectionController.selectMembersByIds([member.id]);
      } else {
        selectionController.deselectMembersByIds([member.id]);
      }
    },
  );

  const selected = useMemo(
    () =>
      selectedMembers.some((selectedMember) => selectedMember.id === member.id),
    [member.id, selectedMembers],
  );

  const chatState = useChatState(member);

  const shouldGreyOut = chatState === "CannotSendAnyContent";

  const onKeyDown = useHandler<ComponentProps<"div">["onKeyDown"]>((e) => {
    /**
     * Navigate member item with arrow key
     */
    if (checkKey(e, "ArrowUp") || checkKey(e, "ArrowDown")) {
      (function switchMember() {
        const activeElement = document.activeElement;
        const memberItemNodeList = document.querySelectorAll(`.${className}`);
        const memberItemsElements = [...memberItemNodeList];
        const focusedMemberItemIndex = !activeElement
          ? -1
          : memberItemsElements.findIndex((element) =>
              element.contains(activeElement),
            );
        if (memberItemsElements.length <= 1 || focusedMemberItemIndex === -1)
          return;
        const nextIndex = (function getNextIndex() {
          const nextIndex = checkKey(e, "ArrowUp")
            ? focusedMemberItemIndex - 1
            : focusedMemberItemIndex + 1;
          return Math.max(
            0,
            Math.min(nextIndex, memberItemsElements.length - 1),
          );
        })();
        const nextElement = memberItemsElements[nextIndex];
        if (!nextElement || !(nextElement instanceof HTMLElement)) return;
        nextElement.focus();
        nextElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      })();
    }

    if (checkKey(e, "Enter") || checkKey(e, "ArrowRight") || checkKey(e, " ")) {
      e.preventDefault();
      e.stopPropagation();
      selectMember();
    }
  });

  const staticBackgroundStyle = useMemo<ComponentProps<"div">["style"]>(
    function getStaticBackgroundStyle() {
      return {
        [cssStaticBackgroundVariableName]: withBackground(
          activeMemberId === member.id,
        ),
      };
    },
    [activeMemberId, member.id],
  );

  return (
    <div
      key={`member_${member.id}`}
      role="button"
      tabIndex={0}
      css={memberItemCss.self}
      style={staticBackgroundStyle}
      onClick={selectMember}
      onKeyDown={onKeyDown}
      className={className}
    >
      {!isSelecting ? null : (
        <Checkbox
          checked={selected}
          onChange={handleSelectChange}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      )}
      <div css={memberItemCss.infoAvatar}>
        <Badge
          offset={[-4, 36]}
          count={
            <div css={memberItemCss.infoAvatarChannel}>
              <ChannelIcon channelId={member.channelId} />
            </div>
          }
        >
          <Avatar
            size={40}
            src={member.avatar}
            css={!shouldGreyOut ? undefined : memberItemCss.greyout}
          />
        </Badge>
      </div>
      <div css={memberItemCss.infoNameMessage}>
        <div
          css={css([
            memberItemCss.infoName,
            !shouldGreyOut ? undefined : memberItemCss.greyout,
          ])}
        >
          <div css={memberItemCss.nameAndPin}>
            {!member.pinned ? null : <MotifIcon un-i-motif="pin_fill" />}
            <span css={memberItemCss.name}>
              <Highlight
                input={getMemberDisplayName(member)}
                match={nameHighlight}
              />
            </span>
          </div>
          <div css={memberItemCss.actionButtons}>
            <span css={memberItemCss.time}>
              <LastEngagement />
            </span>
            {isSelecting ? null : <Actions />}
          </div>
        </div>
        <div css={memberItemCss.infoMessage}>
          <span css={memberItemCss.message}>
            {isSearching ? (
              <DisplayMessageBasedOnSearchAction />
            ) : (
              <DisplayMessage />
            )}
          </span>
          {member.unreadMessageCount <= 0 ? null : (
            <div css={memberItemCss.unreadWrapper}>
              <span css={memberItemCss.unread}>
                {member.unreadMessageCount}
              </span>
            </div>
          )}
        </div>
        <div css={memberItemCss.infoLine}>
          <div>
            {!member.starMark ? null : (
              <div css={memberItemCss.star}>
                <MotifIcon un-i-motif="star_fill" />
              </div>
            )}
          </div>
          <MemberAssignment
            assignmentRelationship={member.assignmentRelationship}
          />
        </div>
      </div>
    </div>
  );
};

const Wrapped: FC<
  NonNullable<ComponentProps<typeof MemberItemContext.Provider>["value"]>
> = function Wrapped(props) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  return (
    <ErrorBoundary.Alert css={memberItemCss.root}>
      <MemberQueries.Provider orgId={orgId} memberId={props.member.id}>
        <MemberItemContext.Provider value={props}>
          <MemberItem />
        </MemberItemContext.Provider>
      </MemberQueries.Provider>
    </ErrorBoundary.Alert>
  );
};

assignDisplayName(Wrapped, "MemberItem");

const Api = Object.assign(Wrapped, {
  /**
   * inner height with padding top and bottom
   */
  height: 128,
});

export { Api as MemberItem };
