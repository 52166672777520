import { random } from "@chatbotgang/etude/string/random";
import { extname } from "pathe";

/**
 * Randomizes the filename.
 *
 * This is used for media attachments to avoid errors from Meta caused by
 * filenames containing special characters.
 *
 * It is specifically used for media attachments because:
 *
 * - Filenames are not used for display purposes in this context.
 * - Meta supports only media attachments.
 *
 * Asana: [中文檔名圖片無法上
 * 傳](https://app.asana.com/0/1202106311174078/1207312110524882/f)
 */
function randomizeFilename(fileName: string): string {
  const ext = extname(fileName);
  const name = random() + ext;
  return name;
}

export { randomizeFilename };
