import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { Radio } from "antd";

const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    &:hover {
      border-color: ${theme.colors.blue004};
      background: ${theme.colors.blue001};
      color: ${theme.colors.blue004};
    }

    &:hover + .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: ${theme.colors.blue004};
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: ${theme.colors.blue006};
    background-color: ${theme.colors.blue002};
    color: ${theme.colors.blue006};

    &:first-child {
      border-color: ${theme.colors.blue006};
    }

    &::before {
      background-color: ${theme.colors.blue006};
    }
  }

  .ant-radio-button-wrapper-disabled {
    &:first-child,
    &:hover {
      border-color: ${theme.colors.neutral004};
      background-color: ${theme.colors.neutral002};
      color: ${theme.colors.neutral005};
    }
  }
`;

export { Radio, RadioGroup };
