import type { FC } from "react";

import { memberFieldRules } from "@/resources/member/memberValidator";
import { TextField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/TextField";

const MemberLevelField: FC = () => {
  return (
    <TextField fieldName="memberLevel" rules={memberFieldRules.memberLevel} />
  );
};

export { MemberLevelField };
