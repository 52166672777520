import type useSwitch from "@react-hook/switch";
import { createContext, useContext } from "react";

type Ret = ReturnType<typeof useSwitch>;

const DropDownSwitchContext = createContext<Ret | undefined>(undefined);

function useDropDownSwitch(): Ret {
  const context = useContext(DropDownSwitchContext);
  if (!context) {
    throw new Error(
      "useDropDownSwitch must be used within DropDownSwitchContext",
    );
  }
  return context;
}

export { DropDownSwitchContext, useDropDownSwitch };
