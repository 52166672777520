import type { WeekdayNumberSchema } from "@zeffiroso/utils/zod/WeekdayNumberSchema";
import type { FC } from "react";
import { useEffect } from "react";
import type { z } from "zod";
import { createWithEqualityFn } from "zustand/traditional";

import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";

/**
 * Org info but stored globally.
 */
const useGlobalOrgStore = createWithEqualityFn<{
  value: null | {
    startWeekday: z.infer<typeof WeekdayNumberSchema>;
  };
}>()(() => ({ value: null }));

function getOrgData() {
  return useGlobalOrgStore.getState().value;
}

/**
 * Sync org info to store and clear it when unmount.
 */
const SyncOrgInfoToStore: FC = () => {
  const orgQueriesData = orgQueriesContext.useData();
  useEffect(
    function syncOrgInfoToStore() {
      useGlobalOrgStore.setState({
        value: {
          startWeekday: orgQueriesData.org.startWeekday,
        },
      });
      return function cleanup() {
        useGlobalOrgStore.setState({ value: null });
      };
    },
    [orgQueriesData.org.startWeekday],
  );
  return null;
};

export { getOrgData, SyncOrgInfoToStore };
