import { makeApi } from "@zodios/core";
import { z } from "zod";

import { CdpSettingSchema } from "../models";

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/cdp/settings",
    response: z.object({
      settings: z.array(CdpSettingSchema),
    }),
  },
  {
    alias: "getById",
    method: "get",
    path: "api/v1/orgs/:orgId/cdp/settings/channels/:channelId",
    response: CdpSettingSchema,
  },
  {
    alias: "update",
    method: "put",
    path: "api/v1/orgs/:orgId/cdp/settings/channels/:channelId",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: CdpSettingSchema.pick({
          enable: true,
        }),
      },
    ],
    response: CdpSettingSchema,
  },
]);

export { api };
