import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
// eslint-disable-next-line no-restricted-imports -- Non-exported type.
import type { ItemType } from "antd/es/menu/hooks/useItems";
import { type FC, useCallback, useMemo } from "react";

import { useGetFeatureFlag } from "@/app/featureFlag";
import { Badge } from "@/components/Badge";
import { Tag } from "@/components/Tag";
import type { MenuListProps } from "@/layout/SideMenu";
import { LayoutContext } from "@/layout/SideMenuLayout/Outer/Layout/Context";
import type {
  Key,
  SidebarMenuItem,
} from "@/layout/SideMenuLayout/Outer/Layout/types";
import { routerUtils } from "@/router/routerUtils";
import { useNavigate } from "@/router/utils";
import { useMatchCurrentPath } from "@/router/utils/matchPath";
import { useUserPermission } from "@/shared/application/user";
import { cssOneLine, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  labelWrapper: css({
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
  }),
  label: cssOneLine,
  tag: css({
    fontWeight: 500,
    borderRadius: 2,
    border: "none",
    color: theme.colors.white,
    background: theme.colors.cerulean005,
  }),
  badge: css({
    display: "inline-flex",
    fontWeight: 500,
    borderRadius: 20,
    color: theme.colors.white,
    backgroundColor: theme.colors.blue006,
  }),
});

const ComingBadge: FC = () => {
  return (
    <Badge $variant="info" css={styles.badge}>
      Coming
    </Badge>
  );
};

const NewTag: FC = () => {
  return <Tag css={styles.tag}>New</Tag>;
};

function getAllKeysDeeply(items: MenuListProps["items"]): Array<Key> {
  if (!items) return [];
  return items.flatMap((item) =>
    !item
      ? []
      : [
          ...(!("key" in item) || typeof item.key !== "string"
            ? []
            : [item.key]),
          ...(!("children" in item) ? [] : getAllKeysDeeply(item.children)),
        ],
  );
}

/**
 * Convert sidebar menu items to menu items.
 *
 * Use `flatMap` with this function to convert the sidebar menu items to menu
 * items.
 */
function useSidebarItemToMenuItem() {
  const getFeatureFlag = useGetFeatureFlag();
  const { hasPermission } = useUserPermission();
  const checkRoutePermission = routerUtils.useCheckRoutePermission();
  const navigate = useNavigate();

  const sidebarItemToMenuItem = useCallback(
    function sidebarItemToMenuItem(
      item: SidebarMenuItem,
      /**
       * The level of the menu item. The top-level menu items have level 0.
       */
      level: number = 0,
    ): [ItemType] | [] {
      if (
        ("path" in item && !checkRoutePermission(item.path)) ||
        ("requirePermission" in item &&
          item.requirePermission &&
          !hasPermission(item.requirePermission)) ||
        ("excludeFeatureFlag" in item &&
          item.excludeFeatureFlag &&
          getFeatureFlag(item.excludeFeatureFlag)) ||
        ("requireFeatureFlag" in item &&
          item.requireFeatureFlag &&
          !getFeatureFlag(item.requireFeatureFlag))
      ) {
        return [];
      }
      const label = (
        <div css={styles.labelWrapper}>
          <div css={styles.label}>{item.label}</div>
          {!("tag" in item) ? null : item.tag === "new" ? (
            <NewTag />
          ) : item.tag === "coming" ? (
            <ComingBadge />
          ) : (
            item.tag
          )}
        </div>
      );
      if ("children" in item) {
        /**
         * Convert sub-menus to menu items.
         *
         * - `undefined` means the menu item doesn't have sub-menus.
         * - `[]` means the menu item should have sub-menus, but it doesn't have
         *   any sub-menus because of the permission or feature flag.
         */
        const childrenMenuItem: ItemType[] | undefined = (() => {
          if (!item.children) return undefined;
          return item.children.flatMap((subItem) =>
            sidebarItemToMenuItem(subItem, level + 1),
          );
        })();
        /**
         * Remove the menu item if it should have sub-menus, but it doesn't have
         * any sub-menus.
         */
        if (childrenMenuItem && childrenMenuItem.length === 0) return [];
        return [
          {
            key: item.key,
            label,
            disabled: true,
            children: childrenMenuItem,
          },
        ];
      }
      if ("path" in item) {
        return [
          {
            key: item.path,
            label,
            disabled: item.tag === "coming",
            onClick: () => navigate(item.path),
          },
        ];
      }
      return [
        {
          key: item.key,
          label,
          disabled: item.tag === "coming",
          ...(!("onClick" in item) ? null : { onClick: item.onClick }),
        },
      ];
    },
    [checkRoutePermission, getFeatureFlag, hasPermission, navigate],
  );

  return sidebarItemToMenuItem;
}

const useSideMenuListProps = () => {
  const layoutContextValue = LayoutContext.useContextValue();
  const sidebarItemToMenuItem = useSidebarItemToMenuItem();
  const matchCurrentPath = useMatchCurrentPath();
  const sidebarMenuItems = layoutContextValue.sidebarMenuItems;
  const items = useMemo<MenuListProps["items"]>(
    () => sidebarMenuItems.flatMap((item) => sidebarItemToMenuItem(item)),
    [sidebarItemToMenuItem, sidebarMenuItems],
  );
  const openKeys = useMemo<MenuListProps["openKeys"]>(
    () => getAllKeysDeeply(items),
    [items],
  );
  const selectedKeys = useMemo<MenuListProps["selectedKeys"]>(
    () =>
      sidebarMenuItems.flatMap<string>((item) => {
        function getSelectedKeys(item: SidebarMenuItem): Array<string> {
          if ("children" in item) {
            return item.children.flatMap(getSelectedKeys);
          }
          if ("path" in item) {
            return matchCurrentPath({
              path: item.path,
              end: false,
            })
              ? [item.path]
              : [];
          }
          return [];
        }
        return getSelectedKeys(item);
      }),
    [matchCurrentPath, sidebarMenuItems],
  );

  const props = useMemo<MenuListProps>(
    () => ({
      expandIcon: null,
      inlineIndent: 0,
      items,
      openKeys,
      selectedKeys,
    }),
    [items, openKeys, selectedKeys],
  );
  return props;
};

export { useSideMenuListProps };
