import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { Skeleton } from "antd";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Text } from "@/components/Typography";
import { isDefaultRoleType } from "@/shared/domains/role";
import { defaultRoleTypeTranslationKeyMap } from "@/shared/utils/translation/defaultRoleTypeTranslationKeyMap";

export const UserRoleById = memo(function UserRoleById({
  roleId,
}: {
  roleId: CantataTypes["User"]["roleId"];
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.role.useListRoles({
    params: {
      orgId,
    },
  });

  if (query.isLoading) return <Skeleton.Input size="small" />;

  if (query.isError) {
    return (
      <Alert
        type="error"
        message={inspectMessage`query error: ${query.error}`}
      />
    );
  }

  const role = query.data.roles.find((role) => role.id === roleId);

  if (!role) throw new Error("unknown role");
  return (
    <Text>
      {isDefaultRoleType(role.type) ? (
        <Trans i18nKey={defaultRoleTypeTranslationKeyMap[role.type]} />
      ) : (
        role.name
      )}
    </Text>
  );
});
