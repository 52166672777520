import type { Cause } from "@/internal/sentryDebug/types";

class SentryDebug extends Error {
  constructor(title: string, message: string, cause?: Cause) {
    super(message);
    this.name = `[SentryDebug] ${title}`;
    if (cause) this.cause = cause;
  }
}

export { SentryDebug };
