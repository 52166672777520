import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { ElementRef } from "react";

import type { CantataTypes } from "@/cantata/types";
import { Avatar } from "@/components/Avatar";

type AvatarType = typeof Avatar;
type UserAvatarRef = ElementRef<AvatarType>;
type UserAvatarProps = Omit<ComponentProps<AvatarType>, "children" | "src"> &
  Pick<CantataTypes["User"], "name" | "avatar">;

const UserAvatar = forwardRef<UserAvatarRef, UserAvatarProps>(
  function UserAvatar({ name, avatar, ...props }, ref) {
    return (
      <Avatar ref={ref} {...props} src={avatar ?? ""}>
        {name}
      </Avatar>
    );
  },
);

assignDisplayName(UserAvatar, "UserAvatar");

export { UserAvatar };
export type { UserAvatarProps, UserAvatarRef };
