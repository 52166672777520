import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { toFileSizeDisplayString } from "@zeffiroso/utils/file/toFileSizeDisplayString";
import { type ElementRef, type ReactNode, useMemo } from "react";

import { TruncatedText } from "@/components/TruncatedText";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  FileListItem: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "4px",
    fontSize: "0.875rem",
    minHeight: "1rem",
    lineHeight: "normal",
    color: theme.colors.neutral009,
    whiteSpace: "pre",
    fontWeight: 500,
    maxWidth: "100%",
  }),
  size: css({
    color: theme.colors.neutral006,
  }),
});

namespace FileListItem {
  export type Ref = ElementRef<"li">;
  export interface OwnProps {
    file: Pick<File, "name" | "size">;
  }
  export type Props = Overwrite<
    Omit<ComponentProps<"li">, "children">,
    OwnProps
  >;
}

const FileListItemInternal = forwardRef<FileListItem.Ref, FileListItem.Props>(
  function FileListItemInternal({ file, ...props }, ref) {
    const fileSizeDisplayString = useMemo(
      () => toFileSizeDisplayString(file.size),
      [file.size],
    );
    const children: ReactNode = useMemo(
      () => (
        <>
          <TruncatedText text={file.name} />
          <div css={styles.size}>{fileSizeDisplayString}</div>
        </>
      ),
      [fileSizeDisplayString, file.name],
    );
    return (
      <li css={styles.FileListItem} ref={ref} {...props}>
        {children}
      </li>
    );
  },
);

/**
 * A list item for a file.
 *
 * Design:
 * [Figma](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=6920-25270&m=dev)
 */
const FileListItem = Object.assign(FileListItemInternal, {
  styles,
});

assignDisplayName(FileListItem, "FileListItem");

export { FileListItem };
