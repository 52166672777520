import { makeApi } from "@zodios/core";
import { z } from "zod";

import { MemberSchema } from "../models";

const api = makeApi([
  /**
   * Spec: [Notion](https://www.notion.so/cresclab/202409-Workduo-AI-255a0d4262a44423b2e1c3f24f7ccca4?pvs=4#3cf072b9eadc4286bfe01e756fc323e6)
   */
  {
    alias: "enabledAiCopilot",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/ai/workduo",
    response: z.object({
      enabled: z.boolean(),
    }),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/202409-Workduo-AI-255a0d4262a44423b2e1c3f24f7ccca4?pvs=4#4c3bf6105a284b24a7747b10457610a0)
     */
    alias: "suggestedReply",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/ai/workduo/suggested-reply",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          memberId: MemberSchema.shape.id,
        }),
      },
    ],
    response: z.object({
      content: z.string(),
    }),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/202409-Workduo-AI-255a0d4262a44423b2e1c3f24f7ccca4?pvs=4#f5d8962f106c4d288bed6473842195a2)
     */
    alias: "rewrite",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/ai/workduo/rewrite",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          response: z.string(),
          command: z.string(),
        }),
      },
    ],
    response: z.object({
      content: z.string(),
    }),
  },
]);

export { api };
