import {
  EMPTY_STRING_PLACEHOLDER,
  HIERARCHY_STRING_JOINER,
} from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";

function getAssigneeString({
  assignmentRelationship,
  separator = HIERARCHY_STRING_JOINER,
}: {
  assignmentRelationship: CantataTypes["Member"]["assignmentRelationship"];
  separator?: string;
}): string {
  if (!assignmentRelationship) return EMPTY_STRING_PLACEHOLDER;

  const items = [
    ...(!assignmentRelationship.team
      ? []
      : [assignmentRelationship.team.name || EMPTY_STRING_PLACEHOLDER]),
    ...(!assignmentRelationship.user
      ? []
      : [assignmentRelationship.user.name || EMPTY_STRING_PLACEHOLDER]),
  ].join(separator);
  return items;
}

export { getAssigneeString };
