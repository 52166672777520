import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import { z } from "zod";

import { LOCAL_STORAGE_ZENDESK_LAST_OPENED_AT } from "@/appConstant";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const localStorageStore = createZustandStorageStore(
  LOCAL_STORAGE_ZENDESK_LAST_OPENED_AT,
  z
    .string()
    .datetime({ offset: true })
    .or(z.null())
    .catch(() => null).parse,
);

function getLastOpenedAt(): Date | null {
  const str = localStorageStore.useStore.getState().value;
  const date = !str ? null : new Date(str);
  if (!date || isInvalidDate(date)) return null;
  return date;
}

function setLastOpenedAt(date: Date) {
  localStorageStore.useStore.setState({ value: date.toISOString() });
}

export { getLastOpenedAt, setLastOpenedAt };
