import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type {
  ComponentProps,
  ComponentPropsWithRef,
  CSSProperties,
} from "react";

const bars = keyframes`
  0%    { transform: scale(1,1); }
  50%   { transform: scale(1,0.3); }
  100%  { transform: scale(1,1); }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  margin: 0 auto;

  > div {
    height: 100%;
    flex: 1;
    border-radius: 5px;
    margin-right: 4px;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-name: ${bars};
    animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);

    &:last-child {
      margin: 0;
    }
  }
`;

const Bar1 = styled.div`
  animation-delay: 0ms;
  background-color: ${theme.colors.blue006};
`;
const Bar2 = styled.div`
  animation-delay: 200ms;
  background-color: ${theme.colors.blue005};
`;
const Bar3 = styled.div`
  animation-delay: 400ms;
  background-color: ${theme.colors.blue004};
`;
const Bar4 = styled.div`
  animation-delay: 600ms;
  background-color: ${theme.colors.blue003};
`;

type BarLoadingProps = ComponentPropsWithRef<"div"> & {
  /**
   * TODO: refactor me. Forward all children props instead.
   */
  wrapperStyle?: CSSProperties;
};

/**
 * This is an absolute positioned loading component.
 *
 * If you want to use it as a inline loading component, you should use
 * `BarLoadingBlock` instead.
 *
 * @see {@link BarLoadingBlock}
 */
const BarLoading = memo(
  forwardRef<HTMLDivElement, BarLoadingProps>(function (
    { wrapperStyle, ...props },
    ref,
  ) {
    return (
      <div style={wrapperStyle} ref={ref}>
        <LoadingWrapper {...props}>
          <BarLoadingBlock />
        </LoadingWrapper>
      </div>
    );
  }),
);

assignDisplayName(BarLoading, "BarLoading");

const BarLoadingBlock = forwardRef<HTMLDivElement, ComponentProps<"div">>(
  function BarLoadingBlock(props, ref) {
    return (
      <div
        {...props}
        ref={ref}
        css={css`
          align-items: center;
          justify-content: center;
        `}
      >
        <Spinner data-chromatic="ignore">
          <Bar1 />
          <Bar2 />
          <Bar3 />
          <Bar4 />
        </Spinner>
      </div>
    );
  },
);

export { BarLoading, BarLoadingBlock };
