import { useHandler } from "@chatbotgang/etude/react/useHandler";
import queryString from "query-string";
import { useMemo } from "react";
import type { NavigateOptions } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import type { z } from "zod";

type FormattedParamsBase = Record<string, any>;

const queryStringOptions: queryString.ParseOptions = {
  arrayFormat: "bracket",
};

function useZodSearchParams<
  ParserSchema extends z.ZodType = z.ZodType,
  Params = z.infer<ParserSchema>,
>(
  parser: ParserSchema,
): {
  params: Params | undefined;
};
function useZodSearchParams<
  ParserSchema extends z.ZodType = z.ZodType,
  Params = z.infer<ParserSchema>,
  FormatterSchema extends z.ZodType<
    FormattedParamsBase,
    FormattedParamsBase,
    any
  > = z.ZodType<FormattedParamsBase, FormattedParamsBase, any>,
>(
  parser: ParserSchema,
  formatter: FormatterSchema,
  nevigationOptions?: NavigateOptions,
): {
  params: Params | undefined;
  setParams: (
    nextParams: z.input<FormatterSchema>,
    nextHash?: string,
    nevigationOptions?: NavigateOptions,
  ) => void;
};
function useZodSearchParams<
  ParserSchema extends z.ZodType = z.ZodType,
  Params = z.infer<ParserSchema>,
  FormatterSchema extends z.ZodType<
    FormattedParamsBase,
    FormattedParamsBase,
    any
  > = z.ZodType<FormattedParamsBase, FormattedParamsBase, any>,
>(
  parser: ParserSchema,
  formatter?: FormatterSchema,
): {
  params: Params | undefined;
  setParams?: (
    nextParams: z.input<FormatterSchema>,
    nextHash?: string,
    nevigationOptions?: NavigateOptions,
  ) => void;
} {
  const location = useLocation();
  const nevigate = useNavigate();
  const searchParams = useMemo(
    () => queryString.parse(location.search, queryStringOptions),
    [location.search],
  );
  const hash = location.hash;
  const params = useMemo(() => {
    const result = parser.safeParse(searchParams);
    if (!result.success) return undefined;
    return result.data;
  }, [parser, searchParams]);
  const setParams = useHandler(function setParams(
    nextParams: z.input<FormatterSchema>,
    nextHash: string = hash,
    nevigationOptions?: NavigateOptions,
  ) {
    if (!formatter) return;
    const result = formatter.safeParse(nextParams);
    const search = !result.success
      ? ""
      : queryString.stringify(result.data, queryStringOptions);
    nevigate({ search, hash: nextHash }, nevigationOptions);
  });
  const returnValue = useMemo(
    () => ({
      params,
      setParams,
    }),
    [params, setParams],
  );
  return returnValue;
}

export { useZodSearchParams };
