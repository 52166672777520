import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import type { FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";
import { useMessage } from "@/internal/message";
import { CloseCircleOutlined } from "@/shared/icons/common/CloseCircleOutlined";

const UnMergeProfile: FC<{ memberId: CantataTypes["Member"]["id"] }> = ({
  memberId,
}) => {
  const [open, toggleOpen] = useSwitch(false);
  const orgId = useActiveOrgIdStore((state) => state.value);
  const message = useMessage();
  const { t } = useTranslation();
  const unMergeProfileMutation = cantata.memberUnification.useCancelUnification(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      onSuccess() {
        message.success(t("chat.profile.cdp.syncing.label"));
        toggleOpen.off();
      },
    },
  );

  const handleUnMergeProfile = useCallback(
    function handleUnMergeProfile() {
      if (unMergeProfileMutation.isLoading) return;
      unMergeProfileMutation.mutate(undefined);
    },
    [unMergeProfileMutation],
  );

  return (
    <>
      <div css={css({ display: "flex", gap: "4px" })} onClick={toggleOpen.on}>
        <CloseCircleOutlined />
        <span>
          <Trans i18nKey="chat.profile.cdp.unMergeUnification.label" />
        </span>
      </div>
      <Modal
        open={open}
        title={
          <Trans i18nKey="chat.profile.cdp.unMergeUnification.modal.title" />
        }
        onCancel={toggleOpen.off}
        footer={[
          <Button key="cancel" type="default" onClick={toggleOpen.off}>
            <Trans i18nKey="common.cancel" />
          </Button>,
          <Button key="confirm" type="primary" onClick={handleUnMergeProfile}>
            <Trans i18nKey="common.confirm" />
          </Button>,
        ]}
      >
        <Trans i18nKey="chat.profile.cdp.unMergeUnification.modal.desc" />
      </Modal>
    </>
  );
};

export { UnMergeProfile };
