import { create } from "zustand";

const useVisibilityStateStore = create<typeof document.visibilityState>(
  () => document.visibilityState,
);

window.addEventListener("visibilitychange", () => {
  useVisibilityStateStore.setState(document.visibilityState);
});

export { useVisibilityStateStore };
