import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ElementType, ForwardedRef, ReactNode } from "react";

import { defineStyles } from "@/shared/emotion";

const defaultComponent = "kbd";

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface KbdOwnProps {
  /**
   * The component used for the root node.
   */
  component?: ElementType;
  /**
   * The content of the component.
   */
  children?: ReactNode;
}

interface KbdTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & KbdOwnProps;
  defaultComponent: RootComponent;
}

type KbdProps<
  RootComponent extends ElementType = KbdTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = KbdOwnProps &
  OverrideProps<KbdTypeMap<AdditionalProps, RootComponent>, RootComponent> & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: css({
    color: theme.colors.neutral009,
    paddingBlock: 2,
    paddingInline: 4,
    display: "inline-flex",
    alignItems: "center",
    gap: 2,
    borderStyle: "solid",
    borderRadius: 4,
    borderWidth: 1,
    borderBottomWidth: 2,
    borderColor: theme.colors.neutral003,
    backgroundColor: theme.colors.neutral002,
  }),
});

/**
 * The Kbd component.
 */
const Kbd: OverridableComponent<KbdTypeMap> = forwardRef(function Kbd(
  { component: Component = defaultComponent, children, ...props }: KbdProps,
  ref: ForwardedRef<ElementRef<typeof Component>>,
) {
  return (
    <Component css={styles.root} {...props} ref={ref}>
      {children}
    </Component>
  );
}) as OverridableComponent<KbdTypeMap>;

export { defaultComponent, Kbd };

export type { KbdOwnProps, KbdProps, KbdTypeMap };
