import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { BreadcrumbProps as AntdBreadcrumbProps } from "antd";
import { Breadcrumb as AntdBreadcrumb } from "antd";
// eslint-disable-next-line  no-restricted-imports -- inherited type.
import type { AnyObject } from "antd/es/_util/type";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { MotifIcon } from "@/components/MotifIcon";
import type { ValidPathString } from "@/router/types";
import { compileToString } from "@/router/utils/compileTo";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    fontSize: "1rem",
    color: theme.colors.neutral009,
    fontWeight: 400,
    "& .ant-breadcrumb-separator": {
      color: theme.colors.neutral010,
      marginInline: 16,
      display: "flex",
      alignItems: "center",
    },
    "& ol": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  }),
  link: css({
    color: theme.colors.neutral009,
  }),
  text: css({
    color: theme.colors.neutral007,
  }),
});

const breadCrumbItemRender: ComponentProps<typeof Breadcrumb>["itemRender"] = (
  options,
) =>
  options.path ? (
    <Link to={options.path} css={styles.link}>
      {options.title}
    </Link>
  ) : (
    <span css={styles.text}>{options.title}</span>
  );

type BreadcrumbProps<T extends AnyObject = AnyObject> = AntdBreadcrumbProps<T>;

/**
 * Styled and route aware breadcrumb component.
 */
const Breadcrumb = <T extends AnyObject = AnyObject>(
  props: BreadcrumbProps<T>,
) => {
  return (
    <AntdBreadcrumb<T>
      css={styles.root}
      separator={<MotifIcon un-i-motif="arrow_right" />}
      itemRender={breadCrumbItemRender}
      {...props}
    />
  );
};

type Item = NonNullable<BreadcrumbProps["items"]>[number];
type RouteBreadcrumbItem = Omit<Item, "href" | "path"> & {
  to?: ValidPathString | ((to: typeof compileToString) => string);
};
type RouteBreadcrumbProps<T extends AnyObject = AnyObject> = Omit<
  BreadcrumbProps<T>,
  "items"
> & {
  items: Array<RouteBreadcrumbItem>;
};

/**
 * Route aware breadcrumb component.
 *
 * Instead of using `href` or `path` to specify the link, use `to` to specify the route.
 */
const RouteBreadcrumb = <T extends AnyObject = AnyObject>({
  items,
  ...props
}: RouteBreadcrumbProps<T>) => {
  const compiledItems = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        ...(!("to" in item)
          ? {}
          : item.to === undefined
            ? {}
            : {
                path: compileTo(item.to),
              }),
      })),

    [items],
  );
  return <Breadcrumb<T> {...props} items={compiledItems} />;
};

function compileTo(to: NonNullable<RouteBreadcrumbItem["to"]>): string {
  return typeof to === "string" ? to : to(compileToString);
}

const RouteBreadcrumbApi = Object.assign(RouteBreadcrumb, {
  compileTo,
});

export { Breadcrumb, RouteBreadcrumbApi as RouteBreadcrumb };
export type { BreadcrumbProps, RouteBreadcrumbItem, RouteBreadcrumbProps };
