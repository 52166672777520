import {
  minutesToMilliseconds,
  secondsInDay,
  secondsToMilliseconds,
} from "date-fns/fp";

const APP_LOADED_DATE = new Date();

//#region LocaleStorage keys

const LOCAL_STORAGE_PREFIX = "caac" as const;

function createLocalStorageKey<T extends string>(
  key: T,
): `${typeof LOCAL_STORAGE_PREFIX}-${T}` {
  return `${LOCAL_STORAGE_PREFIX}-${key}`;
}

/* Authorization bearer token key in localStorage */
const LOCAL_STORAGE_AUTH_TOKEN_KEY = createLocalStorageKey("token");
/**
 * Check if the device has previously completed 2FA; if so, the backend won't
 * request 2FA again
 */
const LOCAL_STORAGE_REMEMBER_DEVICE_TOKEN_KEY = createLocalStorageKey(
  "rememberDeviceToken",
);
const LOCAL_STORAGE_LAST_USER_EMAIL = createLocalStorageKey("lastUserEmail");
const LOCAL_STORAGE_LOGIN_FROM_SSO_PAGE = createLocalStorageKey("fromSsoPage");
const LOCAL_STORAGE_LOGIN_AUTH_PROVIDER =
  createLocalStorageKey("loginAuthProvider");
/* cache organization id key in localStorage */
const LOCAL_STORAGE_LAST_ORGANIZATION = createLocalStorageKey("org_id");
/* cache channel id key in localStorage */
const LOCAL_STORAGE_LAST_CHANNEL = createLocalStorageKey("channel_id");
const LOCAL_STORAGE_FEATURE_FLAG = createLocalStorageKey("featureFlag");
const LOCAL_STORAGE_FEATURE_FLAG_FAB_POSITION = createLocalStorageKey(
  "featureFlagFabPosition",
);
const LOCAL_STORAGE_RECENT_ASSIGNEE = createLocalStorageKey("recentAssignee");
const LOCAL_STORAGE_ZENDESK_FAB_POSITION =
  createLocalStorageKey("zendeskFabPosition");
const LOCAL_STORAGE_ZENDESK_LAST_OPENED_AT = createLocalStorageKey(
  "zendeskLastOpenedAt",
);
const LOCAL_STORAGE_LAST_ACTIVE_TIME = createLocalStorageKey("lastActiveTime");
const LOCAL_STORAGE_CHAT_MEMBER_LIST_SEARCH = createLocalStorageKey(
  "chatMemberListSearch",
);
const LOCAL_STORAGE_DRAFT_NOTE = createLocalStorageKey("draftNote");
const LOCAL_STORAGE_CHAT_MESSAGE_EDITOR_DRAFT = createLocalStorageKey(
  "chatMessageEditorDraft",
);
const LOCAL_STORAGE_PLATFORM_TOKEN = createLocalStorageKey("platformToken");
/** Use this search params to redirect after signed in */
const SEARCH_PARAM_KEY_LOGIN_REDIRECT = "redirect";

const GLOBAL_SEARCH_PARAM_FEATURE_FLAG = "featureFlag";
//#endregion

/**
 * Placeholder for empty string.
 * Use this to replace empty string in the UI.
 */
const EMPTY_STRING_PLACEHOLDER = "－";

/**
 * Separator for range string.
 */
const RANGE_STRING_SEPARATOR = "-";

/**
 * Joiner for range string.
 *
 * ```ts
 * [start, end].join(RANGE_STRING_JOINER)
 * ```
 */
const RANGE_STRING_JOINER = ` ${RANGE_STRING_SEPARATOR} `;
/**
 * Separator for hierarchy string.
 */

const HIERARCHY_STRING_SEPARATOR = "-";
/**
 * Joiner for hierarchy string.
 *
 * ```ts
 * [parent, child].join(HIERARCHY_STRING_JOINER)
 * ```
 */
const HIERARCHY_STRING_JOINER = ` ${HIERARCHY_STRING_SEPARATOR} `;
const NBSP = "\u00A0";

// Magic number
const KB = 1024;
const MB = 1024 * KB;
const GB = 1024 * MB;

// Date related constants
const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};
const DATE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  ...DATE_FORMAT_OPTIONS,
  hour: "numeric",
  minute: "numeric",
};

// Upload related constants
const MAX_CHAT_AI_COMPLETION_REQUEST_MESSAGE_LENGTH = 500;
const MAX_CHAT_UPLOAD_IMAGE_WIDTH = 4096;
const MAX_CHAT_UPLOAD_IMAGE_HEIGHT = 4096;
const MAX_CHAT_UPDATE_IMAGE_DIMENSION = [
  MAX_CHAT_UPLOAD_IMAGE_WIDTH,
  "px × ",
  MAX_CHAT_UPLOAD_IMAGE_HEIGHT,
  "px",
].join("");

const MAX_PIN_MESSAGES_COUNT = 20;

const DEFAULT_NOTIFICATION_ICON = "/defaultNotificationIcon.png";

/**
 * The timeout for the next ping request.
 */
const LEGATO_PING_TIMEOUT = secondsToMilliseconds(5);
/**
 * Over this timeout, we consider the connection is lost.
 */
const LEGATO_PONG_TIMEOUT = secondsToMilliseconds(10);
const LEGATO_RECONNECT_INTERVAL = secondsToMilliseconds(5);
/**
 * Over this timeout, we consider the connection is lagging.
 * This should be shorter than `LEGATO_PONG_TIMEOUT`.
 */
const LEGATO_LAG_TIMEOUT = secondsToMilliseconds(1);

/**
 * Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1722845659232339?thread_ts=1722766431.375469&cid=C02R6ETJMEY)
 */
const AUTH_TWO_FA_OTP_RESEND_COOLDOWN = secondsToMilliseconds(60);
const AUTH_TWO_FA_OTP_EXPIRE_TIME = minutesToMilliseconds(10);

const ZENDESK_LAST_OPENED_AT_EXPIRE_TIME = secondsToMilliseconds(
  3 * secondsInDay,
);

export {
  APP_LOADED_DATE,
  AUTH_TWO_FA_OTP_EXPIRE_TIME,
  AUTH_TWO_FA_OTP_RESEND_COOLDOWN,
  DATE_FORMAT_OPTIONS,
  DATE_TIME_FORMAT_OPTIONS,
  DEFAULT_NOTIFICATION_ICON,
  EMPTY_STRING_PLACEHOLDER,
  GB,
  GLOBAL_SEARCH_PARAM_FEATURE_FLAG,
  HIERARCHY_STRING_JOINER,
  HIERARCHY_STRING_SEPARATOR,
  KB,
  LEGATO_LAG_TIMEOUT,
  LEGATO_PING_TIMEOUT,
  LEGATO_PONG_TIMEOUT,
  LEGATO_RECONNECT_INTERVAL,
  LOCAL_STORAGE_AUTH_TOKEN_KEY,
  LOCAL_STORAGE_CHAT_MEMBER_LIST_SEARCH,
  LOCAL_STORAGE_CHAT_MESSAGE_EDITOR_DRAFT,
  LOCAL_STORAGE_DRAFT_NOTE,
  LOCAL_STORAGE_FEATURE_FLAG,
  LOCAL_STORAGE_FEATURE_FLAG_FAB_POSITION,
  LOCAL_STORAGE_LAST_ACTIVE_TIME,
  LOCAL_STORAGE_LAST_CHANNEL,
  LOCAL_STORAGE_LAST_ORGANIZATION,
  LOCAL_STORAGE_LAST_USER_EMAIL,
  LOCAL_STORAGE_LOGIN_AUTH_PROVIDER,
  LOCAL_STORAGE_LOGIN_FROM_SSO_PAGE,
  LOCAL_STORAGE_PLATFORM_TOKEN,
  LOCAL_STORAGE_RECENT_ASSIGNEE,
  LOCAL_STORAGE_REMEMBER_DEVICE_TOKEN_KEY,
  LOCAL_STORAGE_ZENDESK_FAB_POSITION,
  LOCAL_STORAGE_ZENDESK_LAST_OPENED_AT,
  MAX_CHAT_AI_COMPLETION_REQUEST_MESSAGE_LENGTH,
  MAX_CHAT_UPDATE_IMAGE_DIMENSION,
  MAX_CHAT_UPLOAD_IMAGE_HEIGHT,
  MAX_CHAT_UPLOAD_IMAGE_WIDTH,
  MAX_PIN_MESSAGES_COUNT,
  MB,
  NBSP,
  RANGE_STRING_JOINER,
  RANGE_STRING_SEPARATOR,
  SEARCH_PARAM_KEY_LOGIN_REDIRECT,
  ZENDESK_LAST_OPENED_AT_EXPIRE_TIME,
};
