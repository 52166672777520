import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import useSwitch from "@react-hook/switch";
import { type FC, type ReactNode, useMemo } from "react";

import type { SidebarMenuItem } from "@/layout/SideMenuLayout/Outer/Layout/types";

type LayoutContextValue = {
  title: ReactNode;
  sidebarMenuItems: Array<SidebarMenuItem>;
  gteTabletLandscape: {
    sidebar: {
      expanded: boolean;
      toggle: ReturnType<typeof useSwitch>[1];
    };
  };
};

const LayoutContext = createContext<LayoutContextValue>({
  name: "LayoutContext",
});

type LayoutContextProviderProps = Omit<
  LayoutContextValue,
  "gteTabletLandscape"
> &
  Omit<ComponentProps<typeof LayoutContext.Provider>, "value">;

const LayoutContextProvider: FC<LayoutContextProviderProps> = ({
  children,
  ...restProps
}) => {
  const [sidebarExpanded, toggleSidebar] = useSwitch(true);
  const gteTabletLandscapeSidebar = useMemo<
    LayoutContextValue["gteTabletLandscape"]["sidebar"]
  >(
    () => ({
      expanded: sidebarExpanded,
      toggle: toggleSidebar,
    }),
    [sidebarExpanded, toggleSidebar],
  );
  const gteTabletLandscape = useMemo<LayoutContextValue["gteTabletLandscape"]>(
    () => ({
      sidebar: gteTabletLandscapeSidebar,
    }),
    [gteTabletLandscapeSidebar],
  );
  const value = useMemo<LayoutContextValue>(
    () => ({
      ...restProps,
      gteTabletLandscape,
    }),
    [gteTabletLandscape, restProps],
  );
  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

const api = {
  Provider: LayoutContextProvider,
  useContextValue: LayoutContext.useContext,
};

export { api as LayoutContext };
