/**
 * A mock `t` function that simply returns the key as-is.
 *
 * This is beneficial when creating a map of value-i18nKey pairs. It allows us
 * to preview the value in the IDE using i18n-ally.
 *
 * @example
 *
 * ```ts
 * const t = fakeT;
 *
 * const map = {
 *   foo: t("foo"), // 'foo' displayed as 'Foo' in the IDE with i18n-ally
 *   bar: t("bar"), // 'bar' displayed as 'Bar' in the IDE with i18n-ally
 * };
 *
 * const MyComponent = memo(function MyComponent({ type }: MyComponentProps) {
 *   const { t } = useTranslation();
 *   return <div>{t(map[type])}</div>;
 * });
 * ```
 */
function fakeT<Key extends string>(key: Key): Key {
  return key;
}

export { fakeT };
