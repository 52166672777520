import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { delay } from "@chatbotgang/etude/timer/delay";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useMemo, useState } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { useJumpToMessageController } from "@/routes/Chat/ui/jumpToMessage";

type SearchMessageFromAMemberContextValue = {
  /**
   * Start from 0.
   *
   * `-1` means no search result or do not query.
   */
  index: number;
  searchInputText: string | undefined;
  setSearchInputText: Dispatch<SetStateAction<string | undefined>>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  searchInputOpened: boolean;
  openSearchInput: (input?: string) => void;
  closeSearchInput: () => void;
  /**
   * Jump to the message at the given index in the search result.
   *
   * If the index is out of range, it will jump to the first or the last message.
   *
   * If the search result is empty, it will do nothing.
   *
   * If index is -1, it will do nothing.
   */
  jumpTo: (index: number) => void;
  search: (text: string) => Promise<void>;
  query: ReturnType<typeof cantata.message.useSearchFromAMember<any>>;
};

const SearchMessageFromAMemberContext = createContext<
  SearchMessageFromAMemberContextValue | undefined
>(undefined);

const SearchMessageFromAMemberProvider = memo(
  function SearchMessageFromAMemberProvider({
    children,
  }: {
    children: ReactNode;
  }) {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const activeMemberId = memberIdUtils.useGet();
    const jumpToMessageController = useJumpToMessageController();
    /**
     * searchInputText is the text that the user is typing in the search input.
     *
     * `undefined` means the search input is not opened.
     */
    const [searchInputText, setSearchInputText] = useState<string | undefined>(
      undefined,
    );
    /**
     * searchText is the text that is exactly used to search the messages.
     *
     * `""` means no search.
     */
    const [searchText, setSearchText] = useState<string>("");
    const [index, setIndex] = useState(-1);

    const searchInputOpened = searchInputText !== undefined;
    const openSearchInput = useHandler((input?: string) => {
      setSearchInputText(input || "");
    });
    const closeSearchInput = useHandler(() => {
      setSearchInputText(undefined);
      setSearchText("");
      setIndex(-1);
    });

    const query = cantata.message.useSearchFromAMember(
      {
        query: searchText,
      },
      {
        params: {
          orgId,
          memberId: activeMemberId,
        },
      },
      {
        enabled: false,
      },
    );

    const jumpTo = useHandler(function jumpTo(
      targetIndex: number,
      messages?: NonNullable<typeof query.data>["matchedMessages"],
    ) {
      if (messages && messages.length === 0)
        throw new Error("messages should not be empty");
      if (
        !messages &&
        (!query.isSuccess || query.data.matchedMessages.length === 0)
      ) {
        setIndex(-1);
        return;
      }
      const allMessages: NonNullable<typeof messages> =
        messages || (query.data?.matchedMessages ?? []);
      const validTargetIndex =
        targetIndex >= allMessages.length
          ? allMessages.length - 1
          : targetIndex < 0
            ? 0
            : targetIndex;
      if (validTargetIndex !== index) setIndex(validTargetIndex);

      /**
       * The response order by the time of the message is descending.
       *
       * But our index is ascending.
       */
      const reverseIndex = allMessages.length - 1 - validTargetIndex;
      const matchedMessage = allMessages[reverseIndex];
      if (!matchedMessage)
        throw new Error(inspectMessage`matchedMessage is ${matchedMessage}`);
      jumpToMessageController.setup(matchedMessage);
    });

    const search = useHandler(async function search(text: string) {
      if (!text) return;
      setSearchInputText(text);
      setSearchText(text);
      if (query.isSuccess) {
        /**
         * If the query is success before, set the index to the last message. But
         * is only for UI.
         */
        setIndex(query.data.matchedMessages.length - 1);
      }
      // Wait for the parameter and UI to be updated
      await delay(1);
      const response = await query.refetch();
      if (response.isSuccess) {
        if (response.data.matchedMessages.length === 0) {
          jumpTo(-1);
          return;
        }
        jumpTo(
          response.data.matchedMessages.length - 1,
          response.data.matchedMessages,
        );
        return;
      }
      jumpTo(-1);
    });

    const contextValue = useMemo<SearchMessageFromAMemberContextValue>(
      () => ({
        index,
        searchInputText,
        setSearchInputText,
        searchText,
        setSearchText,
        searchInputOpened,
        openSearchInput,
        closeSearchInput,
        query,
        jumpTo,
        search,
      }),
      [
        closeSearchInput,
        index,
        jumpTo,
        openSearchInput,
        query,
        search,
        searchInputOpened,
        searchInputText,
        searchText,
      ],
    );

    return (
      <SearchMessageFromAMemberContext.Provider value={contextValue}>
        {children}
      </SearchMessageFromAMemberContext.Provider>
    );
  },
);

function useSearchMessageFromAMember(): SearchMessageFromAMemberContextValue {
  const contextValue = useContext(SearchMessageFromAMemberContext);
  if (!contextValue) {
    throw new Error(
      "useSearchMessageFromAMember must be used within SearchMessageFromAMemberProvider",
    );
  }
  return contextValue;
}

export { SearchMessageFromAMemberProvider, useSearchMessageFromAMember };
