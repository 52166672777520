import { makeApi } from "@zodios/core";
import { z } from "zod";

import { ConversationSchema } from "../models";

const api = makeApi([
  /**
   * Doc: [Notion](https://www.notion.so/cresclab/202312-CAAC-Conversation-History-5ac758358bb74cfda2b994b7a54e4cf6?pvs=4#d6c6ffcbcd51425fb9380abc55731e21)
   */
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/chat/members/:memberId/history",
    response: z.object({
      conversations: z.array(ConversationSchema),
    }),
  },
]);

export { api };
