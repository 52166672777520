import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { CanceledError, isAxiosError } from "axios";
import {
  addMilliseconds,
  addSeconds,
  differenceInMilliseconds,
  differenceInSeconds,
  isAfter,
  isEqual as isEqualDate,
  startOfSecond,
} from "date-fns/fp";
import { type ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import type { useSignInUtils } from "@/app/auth";
import { Trans } from "@/app/i18n/Trans";
import {
  AUTH_TWO_FA_OTP_EXPIRE_TIME,
  EMPTY_STRING_PLACEHOLDER,
} from "@/appConstant";
import type { cantataClient } from "@/cantata";
import { Alert } from "@/components/Alert";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { OtpCodeInput } from "@/resources/auth/twoFa/field/OtpCodeInput";
import { RememberDeviceCheckbox } from "@/resources/auth/twoFa/field/RememberDeviceCheckbox";
import { ResendOtpButton } from "@/resources/auth/twoFa/ResendOtpButton";
import { getApiErrorBodyFromUnknownError } from "@/shared/domains/error";
import { defineStyles } from "@/shared/emotion";
import { rememberDeviceTokenUtils } from "@/shared/services/rememberDeviceToken";

const styles = defineStyles({
  content: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 24,
    "& p": {
      marginBottom: 0,
    },
    "& .ant-form-item": {
      marginBottom: 0,
    },
  }),
});

type FormValues = Omit<
  Parameters<typeof cantataClient.auth.verifyTwoFa>[0],
  /**
   * Email will be passed from the previous step.
   */
  "email"
>;

const EasyForm = createEasyForm<FormValues>();

namespace useTwoFaFormUtils {
  export interface UseTwoFaFormUtilsOptions {
    signInUtils: ReturnType<typeof useSignInUtils>;
  }
}

/**
 * A hook to provide utilities for the two-factor authentication form.
 */
function useTwoFaFormUtils(
  options: useTwoFaFormUtils.UseTwoFaFormUtilsOptions,
) {
  /**
   * Check if the component is unmounted.
   *
   * ```ts
   * <MyComponent onClick={async () => {
   *   await doSomething();
   *   if (unMountedRef.current) return;
   *   // continue if the component is still mounted
   * } />
   */
  const unMountedRef = useRef(false);
  useEffect(function updateUnMounted() {
    unMountedRef.current = false;
    return function cleanup() {
      unMountedRef.current = true;
    };
  }, []);

  const { t } = useTranslation();
  const [easyForm, form] = EasyForm.useForm();
  const signInUtils = options.signInUtils;
  const twoFa = signInUtils.twoFa;
  const verifyTwoFaMutations = twoFa.mutations;
  const twoFaState = signInUtils.twoFa.utils.useState();
  const twoFaMutations = signInUtils.twoFa.mutations;
  const formDisabled = twoFaMutations.isLoading;

  const rememberDeviceToken = rememberDeviceTokenUtils.useValue();

  const initialValues = useMemo<FormValues>(
    () => ({
      otpCode: "",
      rememberDevice: Boolean(rememberDeviceToken),
    }),
    [rememberDeviceToken],
  );

  /**
   * If the OTP code is incorrect, show the error message.
   */
  const [showMismatchError, toggleShowMismatchError] = useSwitch(false);

  /**
   * If the OTP code is expired(including reaching the maximum attempts and
   * expired time), show the error message.
   */
  const [expired, toggleExpired] = useSwitch(false);
  const onFinish = useHandler<ComponentProps<typeof EasyForm>["onFinish"]>(
    async function onFinish(values) {
      if (!twoFaState)
        throw new Error(inspectMessage`twoFaState is falsy: ${twoFaState}`);
      if (!values.rememberDevice && rememberDeviceTokenUtils.getValue()) {
        rememberDeviceTokenUtils.clear();
      }
      const result = await safePromise(() =>
        verifyTwoFaMutations.verifyTwoFaMutation.mutateAsync({
          email: twoFaState.email,
          ...values,
        }),
      );
      if (unMountedRef.current) return;
      if (result.isError) {
        if (isAxiosError(result.error)) {
          if (result.error instanceof CanceledError) return;
          const statusCode = result.error.response?.status;
          if (statusCode && statusCode >= 400 && statusCode < 500) {
            // Clear the OTP code when the error is 4xx.
            easyForm.controller.setFieldValue("otpCode", "");
          }
          const definedError = getApiErrorBodyFromUnknownError(result.error);
          if (definedError) {
            if (definedError.name === "AUTH_OTP_MISMATCH") {
              if (definedError.detail.remainAttempts === 0) {
                toggleExpired.on();
                return;
              }
              toggleShowMismatchError.on();
              return;
            }
            if (
              definedError.name === "AUTH_OTP_EXPIRED" ||
              definedError.name === "AUTH_OTP_MAX_ATTEMPTS_EXCEEDED"
            ) {
              toggleExpired.on();
            }
          }
        }
      }
    },
  );

  /**
   * Calculate the expired time of the OTP code.
   */
  const expiredTime = useMemo(() => {
    if (!twoFaState) return null;
    return addMilliseconds(AUTH_TWO_FA_OTP_EXPIRE_TIME)(
      twoFaState.otpRequestTime,
    );
  }, [twoFaState]);

  // Update the time to re-calculate the time related states.
  const [lastUpdateTime, setLastUpdateTime] = useState(() => new Date());
  // Update the time if needed.
  const reRenderTime = useHandler(function updateTime() {
    const now = new Date();
    // Update the last update time.
    setLastUpdateTime(now);
    if (
      !expiredTime ||
      isEqualDate(now, expiredTime) ||
      isAfter(expiredTime)(now)
    ) {
      // Expired time is reached.
      toggleExpired.on();
    }
  });
  useEffect(
    function execUpdateRenderTime() {
      // If the expired time is reached, stop the countdown.
      if (expired) return;
      const nextSecond = addSeconds(1)(startOfSecond(lastUpdateTime));
      const diffTime = differenceInMilliseconds(new Date())(nextSecond);
      const timeout = setTimeout(reRenderTime, diffTime);
      return function cleanup() {
        clearTimeout(timeout);
      };
    },
    [expired, lastUpdateTime, reRenderTime],
  );
  const countDown = useMemo<ReactNode>(() => {
    if (expiredTime && !expired) {
      const diff = differenceInSeconds(lastUpdateTime)(expiredTime);
      if (diff > 0) {
        const seconds = diff % 60;
        const minutes = Math.floor(diff / 60);
        return [minutes, seconds]
          .map((value) => value.toString().padStart(2, "0"))
          .join(":");
      }
    }
    return "00:00";
  }, [expired, expiredTime, lastUpdateTime]);

  const resetAll = useHandler(function resetAll() {
    easyForm.controller.resetFields();
    toggleExpired.off();
    toggleShowMismatchError.off();
  });

  const otpCodeFormItemDefaultProps = OtpCodeInput.useFormItemDefaultProps();
  const rememberDeviceFormItemDefaultProps =
    RememberDeviceCheckbox.useFormItemDefaultProps();
  const content = useMemo(
    () => (
      <div css={styles.content}>
        <p>
          <Trans
            i18nKey="page.login.2fa.verifyEmailSent.message"
            values={{
              email: twoFaState?.email || EMPTY_STRING_PLACEHOLDER,
            }}
          />
          {expired ? null : (
            <>
              <br />
              {t("page.login.2fa.expireCountDown.Message", {
                countDown,
              })}
            </>
          )}
        </p>
        {expired ? (
          <Alert type="error" message={t("resource.2fa.error.expired")} />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "inherit",
              alignItems: "inherit",
              gap: "8",
            }}
          >
            <div>
              <EasyForm.Item
                {...otpCodeFormItemDefaultProps}
                name="otpCode"
                {...(!showMismatchError
                  ? null
                  : {
                      help: t("resource.2fa.error.otpError", {
                        count: twoFaState?.restAttempts,
                      }),
                      validateStatus: "error",
                    })}
              >
                <OtpCodeInput
                  onChange={toggleShowMismatchError.off}
                  onBlur={(e) => {
                    const isPatternMismatch = e.target.validity.patternMismatch;
                    if (isPatternMismatch) {
                      // Remove invalid characters
                      easyForm.controller.setFieldValue(
                        "otpCode",
                        e.target.value.replace(/[^0-9]/g, ""),
                      );
                    }
                  }}
                />
              </EasyForm.Item>
            </div>
            <EasyForm.Item
              {...rememberDeviceFormItemDefaultProps}
              name="rememberDevice"
            >
              <RememberDeviceCheckbox />
            </EasyForm.Item>
          </div>
        )}
      </div>
    ),
    [
      countDown,
      easyForm.controller,
      expired,
      otpCodeFormItemDefaultProps,
      rememberDeviceFormItemDefaultProps,
      showMismatchError,
      t,
      toggleShowMismatchError.off,
      twoFaState?.email,
      twoFaState?.restAttempts,
    ],
  );
  const easyFormProps = useMemo<ComponentProps<typeof EasyForm>>(
    () => ({
      form,
      disabled: formDisabled,
      initialValues,
      onFinish,
      layout: "vertical",
    }),
    [form, formDisabled, initialValues, onFinish],
  );
  const resendButton = useMemo(
    () => <ResendOtpButton signInUtils={signInUtils} onReSent={resetAll} />,
    [resetAll, signInUtils],
  );

  const twoFaFormUtils = useMemo(
    () => ({
      form,
      easyForm,
      content,
      EasyForm,
      easyFormProps,
      resendButton,
      expired,
    }),
    [content, easyForm, easyFormProps, expired, form, resendButton],
  );

  return twoFaFormUtils;
}

export { useTwoFaFormUtils };
