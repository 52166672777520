import { LoadingOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import classNames from "classnames";
import { type ElementRef, type ReactNode, type Ref, useMemo } from "react";

import { useConfigContext } from "@/components/antd/useConfigContext";
import { defineStyles } from "@/shared/emotion";

import { Table } from ".";

const styles = defineStyles({
  expandLoading: css({
    borderColor: "transparent",
    "&:before,&:after": {
      content: "none",
    },
  }),
});

type ExpandLoadingRef = ElementRef<"button">;

interface ExpandLoadingProps<RecordType extends object>
  extends Omit<ComponentProps<"button">, "children"> {
  prefixCls?: string;
  locale?: Table.TableLocale;
  onExpand: (record: RecordType, e: React.MouseEvent<HTMLElement>) => void;
  record: RecordType;
}

interface ExpandLoadingType {
  <RecordType extends object>(
    props: ExpandLoadingProps<RecordType>,
    ref?: Ref<ExpandLoadingRef>,
  ): ReactNode;
}

const ExpandLoading: ExpandLoadingType = forwardRef(function ExpandLoading(
  {
    prefixCls: customizePrefixCls,
    locale: customizeLocale,
    onExpand,
    record,
    ...props
  },
  ref,
) {
  const configContext = useConfigContext();
  const { getPrefixCls, locale: contextLocale = Table.defaultLocale } =
    configContext;
  const prefixCls = getPrefixCls("table", customizePrefixCls);
  const iconPrefix = `${prefixCls}-row-expand-icon`;
  const locale: Table.TableLocale = useMemo(
    () => ({ ...contextLocale.Table, ...customizeLocale }),
    [contextLocale.Table, customizeLocale],
  );
  return (
    <button
      type="button"
      onClick={(e) => {
        onExpand(record, e);
        e.stopPropagation();
      }}
      css={styles.expandLoading}
      className={classNames(iconPrefix, `${iconPrefix}-expanded`)}
      aria-label={locale.collapse}
      aria-expanded
      ref={ref}
      {...props}
    >
      <LoadingOutlined />
    </button>
  );
});

assignDisplayName(ExpandLoading, "Table.ExpandLoading");

export { ExpandLoading };
export type { ExpandLoadingProps, ExpandLoadingRef };
