import { createContext } from "@chatbotgang/etude/react/createContext";

import type { CantataTypes } from "@/cantata/types";

const MemberItemContext = createContext<{
  member: CantataTypes["Member"];
}>({
  name: "ListMemberContext",
});

const useMember = () => {
  return MemberItemContext.useContext().member;
};

const api = Object.assign(MemberItemContext, { useMember });

export { api as MemberItemContext };
