import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { Avatar } from "@/components/Avatar";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { compileToString } from "@/router/utils/compileTo";
import { OnDutyStatus } from "@/routes/Chat/ui/SideMenu/Profile/OnDutyStatus";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 12,
    padding: 16,
  }),
  body: css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  }),
  name: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral009,
  }),
});

const Profile: FC = () => {
  const orgQueriesData = orgQueriesContext.useData();
  const name = orgQueriesData.me.name || EMPTY_STRING_PLACEHOLDER;
  return (
    <div css={styles.root}>
      <Avatar src={orgQueriesData.me.avatar || ""} alt={name} />
      <div css={styles.body}>
        <div css={styles.name}>{name}</div>
        <OnDutyStatus />
      </div>
      <NarrowIconButton
        iconSize={20}
        size={36}
        icon={<MotifIcon un-i-motif="settings" />}
        href={compileToString("/settings/profile")}
      />
    </div>
  );
};

export { Profile };
