/**
 * Filter function for ticks
 */
type TickFilter<T = unknown> = (
  tick: T,
  index: number,
  array: Array<T>,
) => boolean;

/**
 * Filter function to only allow ticks that are integers
 */
const intergerOnly: TickFilter = Number.isInteger;

export { intergerOnly, type TickFilter };
