import { define } from "@chatbotgang/etude/util/define";
import { theme } from "@zeffiroso/theme";
import type { Get } from "type-fest";

import type { HeatmapProps } from ".";

const defaultStartColor = define<
  NonNullable<Get<HeatmapProps<object>, ["scale", "color", "range", "0"]>>
>()(theme.colors.blue002);
const defaultEndColor = define<
  NonNullable<Get<HeatmapProps<object>, ["scale", "color", "range", "1"]>>
>()(theme.colors.blue006);

const defaultScaleColorRange = define<
  NonNullable<Get<HeatmapProps<object>, ["scale", "color", "range"]>>
>()([defaultStartColor, defaultEndColor]);

export { defaultEndColor, defaultScaleColorRange, defaultStartColor };
