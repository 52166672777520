import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { MotionProps } from "framer-motion";
import { motion } from "framer-motion";
import type { CSSProperties, MouseEventHandler, ReactNode } from "react";

import { getCssStr } from "@/shared/utils/style/getCssStr";
import { shouldNotForwardProps } from "@/shared/utils/style/shouldNotForwardProps";

//#region style
const ModuleButtonContainer = styled(motion.div, {
  shouldForwardProp: shouldNotForwardProps(["$disable"]),
})<{ $disable?: boolean }>`
  position: relative;
  height: 83px;
  box-sizing: border-box;
  flex: 0 0 99px;
  border: dashed 1px ${theme.colors.neutral006};
  border-radius: 13px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.neutral006};
  cursor: pointer;
  transition:
    0.3s color ease-in-out,
    0.3s border-color ease-in-out;
  user-select: none;
  vertical-align: top;

  ${(props) =>
    props.$disable &&
    getCssStr(
      (css) => css`
        border: dashed 1px ${theme.colors.neutral004};
        background-color: ${theme.colors.neutral002};
        pointer-events: none;
      `,
    )};

  &:hover {
    border-color: ${theme.colors.blue006};
    color: ${theme.colors.blue006};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const ModuleButtonText = styled.span`
  position: absolute;
  top: 47px;
  left: 0;
  width: 100%;
  font-size: 13px;
  line-height: 14px;
  text-align: center;

  ::before {
    display: inline-block;
    margin-right: 4px;
    content: "+";
  }
`;

const hoverMotion: MotionProps = {
  whileHover: "hover",
  whileTap: "tap",
  initial: "init",
  variants: {
    init: { scale: 1 },
    hover: { scale: 1.1 },
    tap: { scale: 0.9 },
  },
};
//#endregion

const ModuleButton = ({
  id,
  icon,
  onClick,
  disable,
  children,
  style,
}: {
  id?: string;
  icon: ReactNode;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  disable?: boolean;
  style?: CSSProperties;
}): JSX.Element => {
  return (
    <ModuleButtonContainer
      onClick={onClick}
      id={id}
      $disable={disable}
      style={style}
      {...hoverMotion}
    >
      <IconWrapper>{icon}</IconWrapper>
      <ModuleButtonText>{children}</ModuleButtonText>
    </ModuleButtonContainer>
  );
};

export { ModuleButton };
