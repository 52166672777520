import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const KeyOutlined = generateIcon(function KeyOutlined() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.59 2.177c.753.108 1.511.496 2.124 1.109.613.612 1 1.37 1.108 2.124.108.754-.064 1.546-.637 2.118-.572.573-1.365.745-2.118.637a3.669 3.669 0 01-1.616-.674L4.23 12.714a.667.667 0 11-.943-.943l-.472-.471a.667.667 0 11.943-.943l.472.471.942-.943-.47-.471a.667.667 0 11.942-.943l.471.472L8.51 6.548a3.669 3.669 0 01-.675-1.615c-.108-.754.065-1.546.637-2.119.573-.572 1.365-.745 2.119-.637zm-.19 1.32c-.44-.063-.776.05-.986.26-.21.21-.323.547-.26.987.063.44.302.941.732 1.37.429.43.93.668 1.37.731.44.063.777-.05.987-.26.21-.21.322-.546.26-.986-.064-.44-.303-.941-.732-1.37-.429-.43-.93-.669-1.37-.732z"
        fill="currentcolor"
      />
    </svg>
  );
});
