import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { Skeleton } from "antd";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { InlineList } from "@/components/InlineList";

export type UserTeamsInlineProps = {
  userId: number;
};

export const UserTeamsInline = memo(function UserTeamsInline({
  userId,
}: UserTeamsInlineProps) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.user.useGetById({
    params: {
      orgId,
      userId,
    },
  });

  if (query.isLoading) return <Skeleton.Input size="small" />;

  if (query.isError)
    return <Alert type="error" message={inspectMessage`${query.error}`} />;

  if (query.data.teams.length === 0) return <>{EMPTY_STRING_PLACEHOLDER}</>;

  return <InlineList items={query.data.teams.map((team) => team.name)} />;
});
