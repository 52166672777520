const seconds = 1000;
const minutes = seconds * 60;
const hours = minutes * 60;
const days = hours * 24;

/**
 * Transform a duration in milliseconds to a string
 *
 * ```ts
 * durationString(0) // '0s'
 * durationString(1) // '0.001s'
 * durationString(1000) // '1s'
 * durationString(1000 * 60) // '1m'
 * durationString(1000 * 60 * 60) // '1h'
 * durationString(1000 * 60 * 60 * 24) // '1d'
 * ```
 */
function durationString(ms: number) {
  if (ms === 0) return "0s";
  const d = Math.floor(ms / days);
  const h = Math.floor((ms % days) / hours);
  const m = Math.floor((ms % hours) / minutes);
  const s = (ms % minutes) / seconds;
  const parts = [
    d ? `${d}d` : "",
    h ? `${h}h` : "",
    m ? `${m}m` : "",
    s ? `${s}s` : "",
  ];
  return parts.join("");
}

export { days, durationString, hours, minutes, seconds };
