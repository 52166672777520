import { defineAffectedApis } from "@/cantata";

defineAffectedApis({
  channel: {
    assignmentQrcode: true,
  },
  team: {
    assignmentQrcode: true,
  },
  user: {
    assignmentQrcode: true,
  },
});
