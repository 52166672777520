import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Image as AntdImage } from "antd";
import { pick } from "lodash-es";

const Image = styled(AntdImage)`
  max-width: 100%;
  ${(props) =>
    props.height !== undefined &&
    props.width === undefined &&
    css`
      width: initial;
    `};
`;

const MergedImage = Object.assign(Image, pick(AntdImage, ["PreviewGroup"]));

export { MergedImage as Image };
