import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { Skeleton } from "antd";
import { type FC, Suspense } from "react";

import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useGetUserByIdQueries } from "@/resources/user/useGetUserByIdQueries";
import { UserName } from "@/resources/user/UserName";

const UserNameById: FC<{ userId: CantataTypes["User"]["id"] }> = ({
  userId,
}) => {
  const { user } = useGetUserByIdQueries(userId, {
    suspense: true,
    useErrorBoundary: true,
  });

  if (!user) return null;

  return <UserName user={user} />;
};

const Wrapper: FC<{ userId: number }> = ({ userId }) => {
  return (
    <ErrorBoundary.Alert>
      <Suspense fallback={<Skeleton.Input size="small" />}>
        <UserNameById userId={userId} />
      </Suspense>
    </ErrorBoundary.Alert>
  );
};

assignDisplayName(Wrapper, "UserNameById");

export { Wrapper as UserNameById };
