import { memo } from "@chatbotgang/etude/react/memo";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";

const translationMap: Record<CantataTypes["UserOnDutyStatus"], JSX.Element> = {
  online: <Trans i18nKey="onDutyStatus.status.online" />,
  offline: <Trans i18nKey="onDutyStatus.status.offline" />,
  away: <Trans i18nKey="onDutyStatus.status.away" />,
};

export const Name = memo(function Name({
  status,
}: {
  status: CantataTypes["UserOnDutyStatus"];
}) {
  return translationMap[status];
});
