import { useCallback } from "react";

import type { cantataClient } from "@/cantata";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

type Data = Awaited<ReturnType<typeof cantataClient.quickTemplate.list>>;
const useSelectSortQuickTemplates: () => (data: Data) => Data = () => {
  const localeCompare = useLocaleCompare();
  const select = useCallback<(data: Data) => Data>(
    (data) => ({
      quickTemplates: data.quickTemplates
        .toSorted((a, b) => {
          if (a.category.name && !b.category.name) return 1;
          if (!a.category.name && b.category.name) return -1;
          if (
            a.category.name &&
            b.category.name &&
            a.category.name !== b.category.name
          )
            return localeCompare(a.category.name, b.category.name);
          return 0;
        })
        .map((qt) => ({
          ...qt,
          templates: qt.templates.toSorted((a, b) =>
            localeCompare(a.name, b.name),
          ),
        })),
    }),
    [localeCompare],
  );

  return select;
};

export { useSelectSortQuickTemplates };
