import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { delay } from "@chatbotgang/etude/timer/delay";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useFeatureFlag } from "@/app/featureFlag";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { useMessage } from "@/components/message";
import { routerUtils } from "@/router/routerUtils";
import { AssignmentQrCodes } from "@/routes/Settings/Teams/pages/TeamForm/AssignmentQrCodes";
import { DescriptionField } from "@/routes/Settings/Teams/pages/TeamForm/form/fields/DescriptionField";
import { ExternalIdField } from "@/routes/Settings/Teams/pages/TeamForm/form/fields/ExternalIdField";
import { NameField } from "@/routes/Settings/Teams/pages/TeamForm/form/fields/NameField";
import { RoutingRuleField } from "@/routes/Settings/Teams/pages/TeamForm/form/fields/RoutingRuleField";
import { teamsQueriesContext } from "@/routes/Settings/Teams/pages/TeamForm/teamsQueryContext";
import {
  RenderIn,
  usePageInfo,
} from "@/routes/Settings/Teams/pages/TeamForm/usePageInfo";
import { defineStyles } from "@/shared/emotion";

type FormValues = Pick<
  CantataTypes["Team"],
  "name" | "description" | "externalTeamId" | "routingRule"
>;

const InitialValuesContext = createContext<FormValues | undefined>(undefined);

function useInitialValues(): FormValues {
  const initialValues = useContext(InitialValuesContext);
  if (initialValues === undefined) {
    throw new Error(
      "useInitialValues must be used within InitialValuesProvider",
    );
  }

  return initialValues;
}

const GetById = memo(function GetById({ children }: { children: ReactNode }) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { teamId } = usePageInfo();
  if (teamId === undefined) throw new Error("teamId is undefined");

  const [initialValues, setInitialValues] = useState<FormValues | undefined>(
    undefined,
  );
  const query = cantata.team.useGetById(
    {
      params: {
        orgId,
        teamId,
      },
    },
    {
      // TODO: remove me. Do not use query callbacks.
      onSuccess: (data) => {
        if (initialValues !== undefined) return;

        setInitialValues({
          name: data.name,
          description: data.description,
          routingRule: data.routingRule,
          externalTeamId: data.externalTeamId,
        });
      },
      // Workaround to execute onSuccess callback on initial load.
      staleTime: 0,
    },
  );

  if (initialValues === undefined) return <BarLoadingBlock />;

  if (query.isError) {
    return (
      <Alert
        type="error"
        message={inspectMessage`query error: ${query.error}`}
      />
    );
  }

  return (
    <InitialValuesContext.Provider value={initialValues}>
      {children}
    </InitialValuesContext.Provider>
  );
});

const FormProvider = memo(function FormProvider({
  children,
}: {
  children: ReactNode;
}) {
  const pageInfo = usePageInfo();
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const initialValues = useInitialValues();
  const message = useMessage();
  const navigate = routerUtils.useNavigate();
  const enabledSalesBinding = useFeatureFlag("salesBinding");

  const updateMutation = cantata.team.useUpdate({
    params: {
      orgId,
      teamId: pageInfo.teamId ?? Number.NaN,
    },
  });

  const routerPromptOptions = useMemo<
    FormProps<FormValues>["routerPromptOptions"]
  >(() => {
    return {
      disabled: updateMutation.isSuccess,
    };
  }, [updateMutation.isSuccess]);

  const reset = useHandler(() => {
    updateMutation.reset();
  });

  const onFinish = useHandler<FormProps<FormValues>["onFinish"]>(
    async (values) => {
      const { isSuccess } = await safePromise(() =>
        updateMutation.mutateAsync(
          !enabledSalesBinding
            ? {
                ...values,
                externalTeamId: initialValues.externalTeamId,
              }
            : values,
        ),
      );

      if (!isSuccess) return;

      message.success(t("common.updatedSuccessfully"));

      // wait for router prompt to be disabled
      await delay(1);
      navigate("/settings/teams");
    },
  );

  return (
    <Form<FormValues>
      requiredMark="optional"
      onFinish={onFinish}
      onValuesChange={() => {
        reset();
      }}
      form={form}
      layout="vertical"
      disabled={pageInfo.isView || updateMutation.isLoading}
      routerPromptOptions={routerPromptOptions}
      initialValues={initialValues}
    >
      {children}
    </Form>
  );
});

const styles = defineStyles({
  actions: css({
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
    gap: 16,
  }),
});

const TeamUpdatePage = memo(function TeamUpdate() {
  const { t } = useTranslation();
  const enabledSalesBinding = useFeatureFlag("salesBinding");
  return (
    <FormProvider>
      <RenderIn to="isUpdating">
        <div css={styles.actions}>
          <Button
            type="primary"
            htmlType="submit"
            data-test="update-team-button"
          >
            {t("team.form.save")}
          </Button>
        </div>
      </RenderIn>
      <NameField />
      {!enabledSalesBinding ? null : <ExternalIdField />}
      <DescriptionField />
      <RoutingRuleField />
      {!enabledSalesBinding ? null : <AssignmentQrCodes />}
    </FormProvider>
  );
});

const InitialValuesProvider = memo(function InitialValuesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { teamId } = usePageInfo();
  if (teamId === undefined) throw new Error("teamId is undefined");

  return <GetById key={teamId}>{children}</GetById>;
});

const TeamUpdateForm = memo(function TeamUpdateForm() {
  return (
    <teamsQueriesContext.Provider>
      <InitialValuesProvider>
        <TeamUpdatePage />
      </InitialValuesProvider>
    </teamsQueriesContext.Provider>
  );
});

export { TeamUpdateForm };
