import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { delay } from "@chatbotgang/etude/timer/delay";
import { define } from "@chatbotgang/etude/util/define";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { isEqual, merge, noop } from "lodash-es";
import { useEffect, useMemo } from "react";
import { createWithEqualityFn } from "zustand/traditional";

type Cursor = string | undefined;

type Fetch<Response> = ({
  cursor,
  signal,
}: {
  cursor?: Cursor;
  signal: AbortSignal;
}) => Promise<Response>;

type Direction = "previous" | "next";

type SetupInfiniteLoadControllerOptions<Response, Item> = {
  fetch: Fetch<Response>;
  initialData?: Array<Item>;
  getData: (response: Response) => Array<Item>;
  mergeDataFn: ({
    current,
    next,
    previous,
  }: {
    current: Array<Item>;
    next?: Array<Item>;
    previous?: Array<Item>;
  }) => Array<Item>;
  getPreviousCursor?: (response: Response) => Cursor;
  getNextCursor?: (response: Response) => Cursor;
  previousCursor?: string;
  nextCursor?: string;
  previousObserverOptions?: Omit<IntersectionObserverInit, "root">;
  nextObserverOptions?: Omit<IntersectionObserverInit, "root">;
  priorityDirection?: Direction;
};

const initialSetupInfiniteLoadControllerOptions = define<
  Partial<SetupInfiniteLoadControllerOptions<unknown, unknown>>
>()({
  priorityDirection: "next",
});

type StateBase<Item> = {
  firstFetch: boolean;
  isPreviousLoading: boolean;
  previousCursor: string | undefined;
  isNextLoading: boolean;
  nextCursor: string | undefined;
  data: Array<Item>;
  error: Error | undefined;
};

function setupInfiniteLoadController<Response, Item>() {
  type State = StateBase<Item>;
  const initialState: State = {
    firstFetch: true,
    isPreviousLoading: false,
    previousCursor: undefined,
    isNextLoading: false,
    nextCursor: undefined,
    data: [],
    error: undefined,
  };
  const useStore = createWithEqualityFn<State>()(() => initialState, shallow);
  const useElStore = createWithEqualityFn<{
    containerEl: HTMLElement | null;
    previousEl: HTMLElement | null;
    nextEl: HTMLElement | null;
  }>()(
    () => ({
      containerEl: null,
      previousEl: null,
      nextEl: null,
    }),
    shallow,
  );
  const useInViewStore = createWithEqualityFn<{
    isPreviousInView: boolean;
    isNextInView: boolean;
  }>()(
    () => ({
      isPreviousInView: false,
      isNextInView: false,
    }),
    shallow,
  );
  type MutationHook = {
    before: () => unknown;
    after: (args: any) => void;
  };
  const useMutationHooksStore = createWithEqualityFn<{
    hooks: Array<MutationHook>;
  }>()(() => {
    return {
      hooks: [],
    };
  });

  /**
   * This is a hook that can be used to run some code before and after the
   * mutation.
   *
   * The returned value from the `before` function will be passed to the `after`
   * function.
   *
   * ```ts
   * useMutationHook({
   *   before: () => {
   *     const scrollPosition = getScrollPosition();
   *     return scrollPosition;
   *   },
   *   after: (scrollPosition) => {
   *     fixScrollPosition(scrollPosition);
   *   },
   * });
   * ```
   */
  function useMutationHook<TArgs>(
    options:
      | {
          before: () => TArgs;
          after?: (prev: TArgs) => void;
        }
      | {
          after: () => void;
        },
  ) {
    const before = useHandler("before" in options ? options.before : noop);
    const after = useHandler(options.after ?? noop);
    const hook = useMemo<MutationHook>(
      () => ({
        before,
        after,
      }),
      [after, before],
    );
    useEffect(
      function setupHook() {
        useMutationHooksStore.setState(({ hooks }) => {
          return {
            hooks: [...hooks, hook],
          };
        });
        return function cleanup() {
          useMutationHooksStore.setState(({ hooks }) => {
            return {
              hooks: hooks.filter((h) => h !== hook),
            };
          });
        };
      },
      [hook],
    );
  }
  function clear() {
    useStore.setState(initialState);
  }
  function containerRef(el: HTMLElement | null) {
    useElStore.setState({ containerEl: el });
  }
  function previousRef(el: HTMLElement | null) {
    useElStore.setState({ previousEl: el });
  }
  function nextRef(el: HTMLElement | null) {
    useElStore.setState({ nextEl: el });
  }
  function useSetup(
    setupInfiniteLoadControllerOptions: SetupInfiniteLoadControllerOptions<
      Response,
      Item
    >,
  ) {
    useEffect(() => {
      const abortController = new AbortController();
      async function tryLoadMore() {
        const currentSignal = abortController.signal;
        if (currentSignal.aborted) return;

        /**
         * We want to avoid having these states trigger another useEffect call
         * reactively.
         */
        const state = useStore.getState();
        const inViewState = useInViewStore.getState();

        const isLoading = state.isPreviousLoading || state.isNextLoading;
        if (isLoading) return;
        const direction: Direction | undefined = (() => {
          if (state.firstFetch)
            return mergedSetupInfiniteLoadControllerOptions.priorityDirection;
          const shouldTryNext = state.nextCursor && inViewState.isNextInView;
          const shouldTryPrevious =
            state.previousCursor && inViewState.isPreviousInView;
          if (shouldTryNext && shouldTryPrevious)
            return mergedSetupInfiniteLoadControllerOptions.priorityDirection;
          if (shouldTryNext) return "next";
          if (shouldTryPrevious) return "previous";
          return undefined;
        })();
        if (!direction) return;
        useStore.setState(
          direction === "next"
            ? { isNextLoading: true }
            : { isPreviousLoading: true },
        );
        const fetchResult = await safePromise(async () =>
          mergedSetupInfiniteLoadControllerOptions.fetch({
            cursor:
              direction === "next" ? state.nextCursor : state.previousCursor,
            signal: currentSignal,
          }),
        );
        if (currentSignal.aborted) return;
        if (fetchResult.isError) {
          if (currentSignal.aborted) return;
          const err: Error =
            fetchResult.error instanceof Error
              ? fetchResult.error
              : (() => {
                  const err = new Error(
                    inspectMessage`tryLoadMore: fetchResult.error is not an Error`,
                  );
                  err.cause = fetchResult.error;
                  return err;
                })();
          useStore.setState({ error: err });
          return;
        }
        const newData = mergedSetupInfiniteLoadControllerOptions.getData(
          fetchResult.data,
        );
        const previousCursor =
          mergedSetupInfiniteLoadControllerOptions.getPreviousCursor?.(
            fetchResult.data,
          );
        const nextCursor =
          mergedSetupInfiniteLoadControllerOptions.getNextCursor?.(
            fetchResult.data,
          );
        const stateToUpdate: Partial<State> = {
          firstFetch: false,
          // data
          ...((): Partial<Pick<State, "data">> => {
            if (newData.length === 0) return {};
            const merged = mergedSetupInfiniteLoadControllerOptions.mergeDataFn(
              {
                current: state.data,
                [direction]: newData,
              },
            );
            if (isEqual(merged, state.data)) return {};
            return {
              data: merged,
            };
          })(),
          // previous
          ...((): Partial<Pick<State, "previousCursor">> => {
            if (!state.firstFetch && direction !== "previous") return {};
            const hasPrevious = previousCursor && newData.length > 0;
            if (!hasPrevious) {
              return {
                previousCursor: undefined,
              };
            }
            return {
              previousCursor,
            };
          })(),
          // next
          ...((): Partial<Pick<State, "nextCursor">> => {
            if (!state.firstFetch && direction !== "next") return {};
            const hasNext = nextCursor && newData.length > 0;
            if (!hasNext) {
              return {
                nextCursor: undefined,
              };
            }
            return {
              nextCursor,
            };
          })(),
          // loading
          isPreviousLoading: false,
          isNextLoading: false,
        };
        const hooks = useMutationHooksStore.getState().hooks;
        const args = hooks.map((hook) => hook.before());
        useStore.setState(stateToUpdate);
        await delay(1);
        if (currentSignal.aborted) return;
        hooks.forEach((hook, i) => {
          if (!(i in args)) return;
          hook.after(args[i]);
        });
        tryLoadMore();
      }
      const mergedSetupInfiniteLoadControllerOptions = merge(
        {},
        initialSetupInfiniteLoadControllerOptions,
        setupInfiniteLoadControllerOptions,
      );
      useStore.setState({
        previousCursor: mergedSetupInfiniteLoadControllerOptions.previousCursor,
        nextCursor: mergedSetupInfiniteLoadControllerOptions.nextCursor,
        ...(!mergedSetupInfiniteLoadControllerOptions.initialData ||
        mergedSetupInfiniteLoadControllerOptions.initialData.length === 0
          ? {
              firstFetch: true,
              data: [],
            }
          : {
              firstFetch: false,
              data: mergedSetupInfiniteLoadControllerOptions.initialData,
            }),
      });

      function setupPreviousObserverController() {
        const obsserver = new IntersectionObserver(
          (entries) => {
            if (entries.length === 0) return;
            const entry = entries[0];
            if (!entry) return;
            const inView = entry.isIntersecting;
            useInViewStore.setState({ isPreviousInView: inView });
          },
          {
            ...mergedSetupInfiniteLoadControllerOptions.previousObserverOptions,
            root: useElStore.getState().containerEl,
          },
        );
        const previousEl = useElStore.getState().previousEl;
        if (previousEl) obsserver.observe(previousEl);

        function disconnect() {
          obsserver.disconnect();
        }
        return {
          obsserver,
          disconnect,
        };
      }
      function setupNextObserverController() {
        const obsserver = new IntersectionObserver(
          (entries) => {
            if (entries.length === 0) return;
            const entry = entries[0];
            if (!entry) return;
            const inView = entry.isIntersecting;
            useInViewStore.setState({ isNextInView: inView });
          },
          {
            ...mergedSetupInfiniteLoadControllerOptions.nextObserverOptions,
            root: useElStore.getState().containerEl,
          },
        );
        const nextEl = useElStore.getState().nextEl;
        if (nextEl) obsserver.observe(nextEl);

        function disconnect() {
          obsserver.disconnect();
        }
        return {
          obsserver,
          disconnect,
        };
      }
      const previousObserverController = setupPreviousObserverController();
      const nextObserverController = setupNextObserverController();
      const unsubscribeEl = useElStore.subscribe((state, prevState) => {
        if (prevState.previousEl !== state.previousEl) {
          if (state.previousEl)
            previousObserverController.obsserver.observe(state.previousEl);

          if (prevState.previousEl) {
            previousObserverController.obsserver.unobserve(
              prevState.previousEl,
            );
          }
        }
        if (prevState.nextEl !== state.nextEl) {
          if (state.nextEl)
            nextObserverController.obsserver.observe(state.nextEl);

          if (prevState.nextEl)
            nextObserverController.obsserver.unobserve(prevState.nextEl);
        }
      });
      const unsubscribeInViewChange = useInViewStore.subscribe(
        async function handleInViewChange(current, prev) {
          if (
            (!prev.isPreviousInView && current.isPreviousInView) ||
            (!prev.isNextInView && current.isNextInView)
          )
            tryLoadMore();
        },
      );
      tryLoadMore();
      return function cleanup() {
        abortController.abort();
        clear();
        unsubscribeInViewChange();
        unsubscribeEl();
        previousObserverController.disconnect();
        nextObserverController.disconnect();
      };
    }, [setupInfiniteLoadControllerOptions]);
  }
  return {
    containerRef,
    previousRef,
    nextRef,
    useSetup,
    useStore,
    useMutationHook,
  };
}

function useSetupInfiniteLoadController<Response, Item>() {
  const infiniteLoadController = useMemo(
    () => setupInfiniteLoadController<Response, Item>(),
    [],
  );
  return infiniteLoadController;
}

export { useSetupInfiniteLoadController };
export type { SetupInfiniteLoadControllerOptions, StateBase };
