import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { type FC, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Line } from "@/components/antdPlots/Line";
import { tickFilters } from "@/components/antdPlots/utils";
import { Card } from "@/components/Card";
import { NoData } from "@/components/Empty/NoData";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useFillYmdRangeUtils, useFormatDate } from "@/resources/datetime";
import { DateIntervalSelect } from "@/routes/Insights/components/DateIntervalSelect";
import { usePageInfoUtil } from "@/routes/Insights/Contacts/pageInfo";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  title: css({
    fontSize: "1.25rem",
    fontWeight: 500,
    color: theme.colors.neutral009,
  }),
  name: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral009,
  }),
});

const defaultValue: Omit<CantataTypes["DashboardMetrics"], "date"> = {
  value: 0,
};

const AddedTagsLineChart: FC = () => {
  const formatDate = useFormatDate();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const tagsInterval = pageInfoUtil.data.tagsInterval;
  const fillYmdRangeUtils = useFillYmdRangeUtils();
  const fillYmdRange = useMemo(
    () => fillYmdRangeUtils[tagsInterval],
    [fillYmdRangeUtils, tagsInterval],
  );

  const query = cantata.dashboardMember.useAddedTag(
    {
      params: {
        orgId,
      },
      queries: {
        ...pageInfoUtil.computed.commonQueries,
        interval: tagsInterval,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: (data) => {
        const ret = {
          interval: data.interval,
          results: data.tagMetrics.flatMap(({ countMetrics, ...rest }) =>
            fillYmdRange({
              dateKey: "date",
              data: countMetrics,
              fillValue: defaultValue,
              start: pageInfoUtil.data.startTime,
              end: pageInfoUtil.data.endTime,
            }).map((countMetric) => ({
              ...countMetric,
              ...rest,
              dateRange: [
                countMetric.date,
                tagsInterval === "daily"
                  ? countMetric.date
                  : tagsInterval === "weekly"
                    ? countMetric.date.addWeeks(1).addDays(-1)
                    : tagsInterval === "monthly"
                      ? countMetric.date.addMonths(1).addDays(-1)
                      : (() => {
                          tagsInterval satisfies never;
                          throw new Error(
                            inspectMessage`Unexpected tagsInterval: ${tagsInterval}`,
                          );
                        })(),
              ],
            })),
          ),
        };

        return ret;
      },
    },
  );

  if (!query.isSuccess) return null;

  if (query.data.results.length === 0) {
    return <NoData />;
  }

  return (
    <Line
      data={Ymd.ymdToDateDeep(query.data.results)}
      colorField="tagName"
      xField={(item) => formatDate(item.date)}
      yField="value"
      axis={{
        y: {
          tickFilter: tickFilters.intergerOnly,
        },
      }}
      tooltip={{
        title: {
          field: "dateRange",
        },
      }}
    />
  );
};

export const Tags: FC = () => {
  const pageInfoUtil = usePageInfoUtil();
  const tagsInterval = pageInfoUtil.data.tagsInterval;
  const filterIntervalByRange = DateIntervalSelect.useFilterIntervalByRange({
    startTime: pageInfoUtil.data.startTime,
    endTime: pageInfoUtil.data.endTime,
  });
  const handleIntervalChange = useHandler<
    ComponentProps<typeof DateIntervalSelect>["onChange"]
  >(function handleTagsIntervalChanged(value) {
    pageInfoUtil.update({
      tagsInterval: value,
    });
  });

  return (
    <>
      <div css={styles.title}>
        <Trans i18nKey="dashboard.contacts.tag.title" />
      </div>
      <Card crescendolab-selector="insights-contacts-tag_line_card">
        <div css={styles.name}>
          <Trans
            i18nKey="dashboard.contacts.top5Tag.chart.selectRange"
            components={{
              SelectRange: (
                <DateIntervalSelect
                  value={tagsInterval}
                  onChange={handleIntervalChange}
                  filter={filterIntervalByRange}
                />
              ),
            }}
          />
        </div>
        <Line.Wrapper>
          <ErrorBoundary.Alert fullSize>
            <AddedTagsLineChart />
          </ErrorBoundary.Alert>
        </Line.Wrapper>
      </Card>
    </>
  );
};
