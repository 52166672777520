import { define } from "@chatbotgang/etude/util/define";
import {
  MemberAssignmentFilterSchema,
  MemberProcessingStateSchema,
  MemberSearchNumberArrayActionSchema,
  MemberSearchStringActionSchema,
} from "@zeffiroso/cantata/models";
import { setZodFallback } from "@zeffiroso/utils/zod/setZodFallback";
import { mapValues } from "lodash-es";
import { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { LOCAL_STORAGE_CHAT_MEMBER_LIST_SEARCH } from "@/appConstant";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const numberArrayActions = MemberSearchNumberArrayActionSchema.options;
type NumberArrayAction = z.infer<typeof MemberSearchNumberArrayActionSchema>;

const searchSchema = setZodFallback(
  z.union([
    z.object({
      action: MemberSearchStringActionSchema,
      query: z.string(),
    }),
    z.object({
      action: MemberSearchNumberArrayActionSchema,
      query: z.array(z.number()),
    }),
  ]),
  {
    action: "messages",
    query: "",
  },
);

const processingStateFilterSchema = setZodFallback(
  z.object({
    processingState: MemberProcessingStateSchema,
  }),
  {
    processingState: "new",
  },
);

const assignmentFilterSchema = setZodFallback(
  z.union([
    z.object({
      assignmentFilter: MemberAssignmentFilterSchema.exclude(["assignee"]),
    }),
    z.object({
      assignmentFilter: MemberAssignmentFilterSchema.extract(["assignee"]),
      assigneeId: z.number(),
    }),
  ]),
  {
    assignmentFilter: "all",
  },
);

const unreadFilterSchema = setZodFallback(
  z.object({
    unread: z.boolean().nullable(),
  }),
  {
    unread: null,
  },
);

const filterSchema = setZodFallback(
  processingStateFilterSchema
    .and(assignmentFilterSchema)
    .and(unreadFilterSchema),
  {
    ...processingStateFilterSchema.fallback,
    ...assignmentFilterSchema.fallback,
    ...unreadFilterSchema.fallback,
  },
);

const allChannelId = null;

const channelIdSchema = setZodFallback(
  z.number().transform((v) => (!v || v < 0 ? allChannelId : v)),
  allChannelId,
);

const storeValuesSchema = setZodFallback(
  z.object({
    channelId: channelIdSchema,
    search: searchSchema,
    filter: filterSchema,
  }),
  {
    channelId: null,
    search: searchSchema.fallback,
    filter: filterSchema.fallback,
  },
);

type StoreValues = z.infer<typeof storeValuesSchema>;

const initialState: StoreValues = storeValuesSchema.parse(null);

function isNumberArraySearchAction<
  SearchAction extends StoreValues["search"]["action"],
>(action: SearchAction): action is SearchAction & NumberArrayAction {
  return numberArrayActions.includes(
    action as (typeof numberArrayActions)[number],
  );
}

function isNumberArraySearch<Search extends StoreValues["search"]>(
  search: Search,
): search is Search & {
  action: NumberArrayAction;
} {
  return isNumberArraySearchAction(search.action);
}

const local = createZustandStorageStore(
  LOCAL_STORAGE_CHAT_MEMBER_LIST_SEARCH,
  storeValuesSchema.parse,
);

const session = createZustandStorageStore(
  LOCAL_STORAGE_CHAT_MEMBER_LIST_SEARCH,
  (input) => {
    /**
     * If the input is null, it means that the session storage has not been set
     * yet. In this case, we return the initial state.
     */
    if (input === null) return initialState;
    return storeValuesSchema.parse(input);
  },
  {
    storage: sessionStorage,
  },
);

/**
 * If the value is the instance of the initial state, it means that the session
 * storage has not been set yet. In this case, we set the value of the session
 * storage to the value of the local storage.
 */
if (session.useStore.getState().value === initialState)
  session.useStore.setState({ value: local.useStore.getState().value });

session.useStore.subscribe(({ value }) => {
  if (value === undefined) {
    local.useStore.getState().clear();
    return;
  }
  const state = local.useStore.getState();
  if (state.value === value) return;
  local.useStore.getState().setValue(value);
});

/**
 * If the active org id changes, we clear the local and session storage.
 */
useActiveOrgIdStore.subscribe((state, prevState) => {
  if (Object.is(state.value, prevState.value)) return;
  local.useStore.getState().clear();
  session.useStore.getState().clear();
});

const searchController = {
  useStore<T = StoreValues>(selector?: (state: StoreValues) => T): T {
    return session.useStore((state) =>
      !selector ? (state.value as T) : selector(state.value),
    );
  },
  ...(() => {
    function getState() {
      return session.useStore.getState().value;
    }
    function setState(
      next:
        | Partial<StoreValues>
        | ((state: StoreValues) => Partial<StoreValues>),
    ) {
      session.useStore.setState(() => {
        const state = getState();
        const nextState = {
          ...state,
          ...(typeof next === "function" ? next(state) : next),
        };
        return {
          value: nextState,
        };
      });
    }

    function changeAction(action: StoreValues["search"]["action"]) {
      setState({
        search: isNumberArraySearchAction(action)
          ? { action, query: [] }
          : { action, query: "" },
      });
    }

    function clearQuery() {
      const state = getState();
      setState({
        search: isNumberArraySearch(state.search)
          ? { ...state.search, query: [] }
          : { ...state.search, query: "" },
      });
    }

    function clearSearch() {
      setState({
        search: initialState.search,
      });
    }

    function clearChannelId() {
      setState({
        channelId: initialState.channelId,
      });
    }

    const selectors = define<Record<string, (state: StoreValues) => any>>()({
      isSearching: (state) => state.search.query.length > 0,
    });

    const computedValues = mapValues(
      selectors,
      (selector) => () => selector(getState()),
    );

    return {
      setState,
      allChannelId,
      changeAction,
      clearChannelId,
      clearQuery,
      clearSearch,
      getState,
      selectors,
      computedValues,
    };
  })(),
};

export { searchController };
