import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import {
  addMilliseconds,
  addSeconds,
  compareAsc,
  differenceInMilliseconds,
  differenceInSeconds,
  isBefore,
  startOfSecond,
} from "date-fns/fp";
import type { ElementRef } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import type { useSignInUtils } from "@/app/auth";
import { AUTH_TWO_FA_OTP_RESEND_COOLDOWN } from "@/appConstant";
import { Button } from "@/components/Button";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";

declare namespace ResendOtpButton {
  export type ResendOtpButtonRef = ElementRef<typeof Button>;

  export interface ResendOtpButtonProps extends ComponentProps<typeof Button> {
    signInUtils: ReturnType<typeof useSignInUtils>;
    onReSent?: () => void;
  }
}

const ResendOtpButton = forwardRef<
  ResendOtpButton.ResendOtpButtonRef,
  ResendOtpButton.ResendOtpButtonProps
>(function ResendOtpButton({ signInUtils, onReSent, ...props }, ref) {
  const mergeFormDisabled = useMergeFormDisabled();
  const { t } = useTranslation();
  const twoFaMutations = signInUtils.twoFa.mutations;
  const twoFaState = signInUtils.twoFa.utils.useState();
  const onClick = useHandler<ComponentProps<typeof Button>["onClick"]>(
    async function onClick(...args) {
      props.onClick?.(...args);
      const [e] = args;
      if (e.defaultPrevented) return;
      const result = await safePromise(() =>
        twoFaMutations.reSendOtpMutation.mutateAsync(),
      );
      if (result.isSuccess) onReSent?.();
    },
  );
  const [lastUpdateTime, setLastUpdateTime] = useState(() => new Date());
  const reRenderTime = useHandler(function reRenderTime() {
    setLastUpdateTime(new Date());
  });
  const resendEnableTime = useMemo(() => {
    if (!twoFaState) return null;
    return addMilliseconds(AUTH_TWO_FA_OTP_RESEND_COOLDOWN)(
      /**
       * Cooldown starts from the last time the user got the OTP.
       */
      twoFaState.otpResponseTime,
    );
  }, [twoFaState]);
  const disabledDueToCooldown = useMemo(() => {
    if (!resendEnableTime) return undefined;
    return isBefore(resendEnableTime)(lastUpdateTime);
  }, [lastUpdateTime, resendEnableTime]);
  useEffect(
    function execReRenderTime() {
      if (!disabledDueToCooldown || !resendEnableTime) return;
      const nextSecond = addSeconds(1)(startOfSecond(lastUpdateTime));
      const closerUpdateTime =
        compareAsc(nextSecond, resendEnableTime) === -1
          ? resendEnableTime
          : nextSecond;
      const diffTime = differenceInMilliseconds(new Date())(closerUpdateTime);
      const timeout = setTimeout(reRenderTime, diffTime);
      return function cleanup() {
        clearTimeout(timeout);
      };
    },
    [disabledDueToCooldown, lastUpdateTime, reRenderTime, resendEnableTime],
  );
  const countDown = useMemo(() => {
    if (!resendEnableTime) return undefined;
    const diff = differenceInSeconds(lastUpdateTime)(resendEnableTime);
    if (diff <= 0) return 0;
    return diff;
  }, [lastUpdateTime, resendEnableTime]);
  /**
   * Props first, then merge form disabled state and cooldown disabled state.
   */
  const disabled = useMemo(
    () => mergeFormDisabled(disabledDueToCooldown),
    [disabledDueToCooldown, mergeFormDisabled],
  );
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      loading={twoFaMutations.reSendOtpMutation.isLoading}
      {...props}
      ref={ref}
    >
      {"children" in props ? (
        props.children
      ) : (
        <>
          {t("page.login.2fa.actions.resend")}
          {!countDown ? null : ` (${countDown}s)`}
        </>
      )}
    </Button>
  );
});

export { ResendOtpButton };
