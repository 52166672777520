import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import classNames from "classnames";
import { type ElementRef, type ReactNode, useMemo } from "react";

import { defineStyles } from "@/shared/emotion";

const seed = random();

const gridAreaNameRecord = define<Record<PropertyKey, string>>()({
  counter: `StaticVerticalSteps-counter-${seed}`,
  title: `StaticVerticalSteps-title-${seed}`,
  description: `StaticVerticalSteps-description-${seed}`,
});

const classNameRecord = define<Record<PropertyKey, string>>()({
  root: `StaticVerticalSteps-Step-${seed}`,
  title: `StaticVerticalSteps-title-${seed}`,
  description: `StaticVerticalSteps-description-${seed}`,
});

const styles = defineStyles({
  Step: css({
    display: "grid",
    flexDirection: "row",
    gap: "8px",
    gridTemplate: `${[
      `"${CSS.escape(gridAreaNameRecord.counter)} ${CSS.escape(gridAreaNameRecord.title)}"`,
      `". ${CSS.escape(gridAreaNameRecord.description)}"`,
    ].join("\n")}/ min-content 1fr`,
    "&::before": {
      alignSelf: "center",
      gridArea: gridAreaNameRecord.counter,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowrap",
      height: "20px",
      borderRadius: "50%",
      textAlign: "center",
      aspectRatio: "1/1",
      overflow: "hidden",
      textOverflow: "ellipsis",
      backgroundColor: theme.colors.neutral002,
      color: theme.colors.neutral009,
      fontWeight: 500,
      fontSize: "0.75rem",
      boxSizing: "border-box",
    },
    "&>*": {
      minWidth: 0,
    },
  }),
  title: css({
    alignSelf: "center",
    fontSize: "1rem",
    color: theme.colors.neutral010,
    gridArea: gridAreaNameRecord.title,
  }),
  description: css({
    gridArea: gridAreaNameRecord.description,
    fontSize: "0.875rem",
    color: theme.colors.neutral007,
  }),
});

namespace Step {
  export type Ref = ElementRef<"li">;
  export interface OwnProps {
    title: ReactNode;
    titleProps?: Omit<ComponentProps<"div">, "children">;
    description?: ReactNode;
    descriptionProps?: Omit<ComponentProps<"div">, "children">;
  }
  export type Props = Overwrite<
    Omit<ComponentProps<"li">, "children">,
    OwnProps
  >;
}

const StepInternal = forwardRef<Step.Ref, Step.Props>(function StepInternal(
  { title, titleProps, description, descriptionProps, ...props },
  ref,
) {
  const className = useMemo(
    () => classNames(classNameRecord.root, props.className),
    [props.className],
  );
  const titleCss = useMemo(
    () => css([styles.title, titleProps?.css]),
    [titleProps?.css],
  );
  const titleClassName = useMemo(
    () => classNames(classNameRecord.title, titleProps?.className),
    [titleProps?.className],
  );
  const descriptionCss = useMemo(
    () => css([styles.description, descriptionProps?.css]),
    [descriptionProps?.css],
  );
  const descriptionClassName = useMemo(
    () => classNames(classNameRecord.description, descriptionProps?.className),
    [descriptionProps?.className],
  );
  const children: ReactNode = useMemo(
    () => (
      <>
        <div {...titleProps} css={titleCss} className={titleClassName}>
          {title}
        </div>
        {!description ? null : (
          <div
            {...descriptionProps}
            css={descriptionCss}
            className={descriptionClassName}
          >
            {description}
          </div>
        )}
      </>
    ),
    [
      description,
      descriptionClassName,
      descriptionCss,
      descriptionProps,
      title,
      titleClassName,
      titleCss,
      titleProps,
    ],
  );
  const liCss = useMemo(
    () =>
      css([
        styles.Step,
        {
          gridTemplate: `${[
            `"${CSS.escape(gridAreaNameRecord.counter)} ${CSS.escape(gridAreaNameRecord.title)}"`,
            ...(!gridAreaNameRecord.description
              ? []
              : // eslint-disable-next-line no-restricted-syntax -- false alarm
                [`". ${CSS.escape(gridAreaNameRecord.description)}"`]),
          ].join("\n")}/ min-content 1fr`,
        },
      ]),
    [],
  );
  return (
    <li {...props} css={liCss} ref={ref} className={className}>
      {children}
    </li>
  );
});

/**
 * The step item of the StaticVerticalSteps component.
 *
 * Design: [Figma](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=6922-26673&m=dev)
 */
const Step = Object.assign(StepInternal, {
  styles,
  gridAreaNameRecord,
  classNameRecord,
});

assignDisplayName(Step, "StaticVerticalSteps.Step");

export { Step };
