import { fc } from "@chatbotgang/etude/react/fc";
import { safeString } from "@chatbotgang/etude/string/safeString";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { FormListFieldData, FormListOperation } from "antd";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import { FormItem } from "@/components/Form";
import type { TextAreaProps } from "@/components/Input";
import { InputTextArea } from "@/components/Input";
import { DeleteMessageButton } from "@/components/MessageEditor/DeleteMessageButton";
import { EditMessage } from "@/components/MessageEditor/EditMessage";
import { FilePreview } from "@/components/MessagePreview/FilePreview";
import { ImagePreview } from "@/components/MessagePreview/ImagePreview";
import { VideoPreview } from "@/components/MessagePreview/VideoPreview";
import { config } from "@/resources/message/messageValidator";

const MessageWrapper = styled.div`
  margin-bottom: 24px;
`;

const TextWrapper = styled.div`
  padding: 2px;
  border-radius: ${theme.shape.borderRadius};
  background: ${theme.colors.white};
`;

const TextField = (props: TextAreaProps) => (
  <TextWrapper>
    <InputTextArea {...props} style={{ resize: "vertical" }} />
  </TextWrapper>
);

const TemplateEditorFactory = fc<{
  channelType: CantataTypes["Channel"]["type"];
  fieldsName: string;
  fields: FormListFieldData[];
  operation: FormListOperation;
}>(({ channelType, fieldsName, fields, operation: { remove } }) => {
  const { t } = useTranslation();
  return (
    <>
      {fields.map((field) => (
        <EditMessage key={field.key} data-name={field.name}>
          <FormItem noStyle dependencies={[fieldsName, field.name]}>
            {({ getFieldValue }) => {
              const value = getFieldValue([fieldsName, field.name]);
              switch (value.type) {
                case "text":
                  return (() => {
                    const textMaxLength = config.text.maxLength[channelType];
                    return (
                      <FormItem
                        name={[field.name, "text"]}
                        rules={[
                          {
                            required: true,
                            transform: (v) => safeString(v, { trim: true }),
                            message: t("validation.emptyTextInput"),
                          },
                          {
                            max: textMaxLength,
                            message: t("validation.text.maxLength", {
                              count: textMaxLength,
                            }),
                          },
                        ]}
                      >
                        <TextField autoSize={{ minRows: 2 }} />
                      </FormItem>
                    );
                  })();
                case "image":
                  return (
                    <MessageWrapper>
                      <ImagePreview src={value.originUrl} />
                    </MessageWrapper>
                  );
                case "video":
                  return (
                    <MessageWrapper>
                      <VideoPreview
                        previewUrl={value.previewUrl}
                        src={value.originUrl}
                      />
                    </MessageWrapper>
                  );
                case "file":
                  return (
                    <MessageWrapper>
                      <FilePreview
                        fileName={value.metadata.filename}
                        fileSizePrefix={value.metadata.filesizePrefix}
                        fileSize={value.metadata.filesizeBytes}
                        expiryPrefix={value.metadata.expirationDatePrefix}
                        downloadUrl={value.originUrl}
                        small={true}
                      />
                    </MessageWrapper>
                  );
                default:
                  return null;
              }
            }}
          </FormItem>
          <DeleteMessageButton onClick={() => remove(field.name)} />
        </EditMessage>
      ))}
    </>
  );
});

export { TemplateEditorFactory };
