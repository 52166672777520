import type { F, S } from "ts-toolbelt";

/**
 * Add opacity to a hex color.
 *
 * @example
 *
 * ```ts
 * addOpacityToHex('#012345', 'ab'); // '#012345ab'
 * ```
 */
function addOpacityToHex<Input extends string, Opacity extends string>(
  hex: Input extends `#${infer Hex}`
    ? S.Length<Hex> extends 6
      ? F.Narrow<Input>
      : {
          _error: "Hex must be 6 characters long";
        }
    : {
        _error: "Input must start with #";
      },
  opacity: S.Length<Opacity> extends 2
    ? F.Narrow<Opacity>
    : {
        _error: "Opacity must be 2 characters long";
      },
): `${Input}${Opacity}` {
  return `${hex}${opacity}` as any;
}

export { addOpacityToHex };
