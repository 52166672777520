import { z } from "zod";

/**
 * https://github.com/colinhacks/zod/blob/9340fd5/src/types.ts#L605
 */
const regexp =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(([+-]\d{2}(:?\d{2})?)|Z)$/;

/**
 * Represents a high-resolution date with nanosecond precision.
 *
 * To compare two `HighResDate` instances, use the `HighResDate.compare(a, b)`
 * method.
 *
 * The term "high res" is derived from the [Performance: now()
 * method](https://developer.mozilla.org/en-US/docs/Web/API/Performance/now).
 */
class HighResDate {
  readonly date: Date;
  readonly nanoseconds: number;
  constructor(
    date: ConstructorParameters<typeof Date>[0],
    nanoseconds: number = 0,
  ) {
    this.date = new Date(date);
    this.nanoseconds = nanoseconds;
  }
  /**
   * Compare two `HighResDate` instances.
   *
   * @param a - The first `HighResDate` instance.
   * @param b - The second `HighResDate` instance.
   * @returns -1 if a < b, 1 if a > b, 0 if a == b
   */
  static compare(a: HighResDate, b: HighResDate) {
    const aTime = a.date.getTime();
    const bTime = b.date.getTime();
    if (aTime < bTime) return -1;
    if (aTime > bTime) return 1;
    return a.nanoseconds - b.nanoseconds;
  }
}

/**
 * Parse ISO8601 datetime string with nanoseconds because in javascript, `Date`
 * only supports milliseconds.
 *
 * ```ts
 * Iso8601HighResDateSchema.parse('2021-07-21T12:34:56.789012345Z');
 * // => { date: 2021-07-21T12:34:56.789Z, nanoseconds: 12345 }
 * ```
 */
const Iso8601HighResDateSchema = z
  .string()
  .datetime({
    offset: true,
  })
  .transform((s) => {
    const matched = s.match(regexp);
    if (!matched) throw new Error("Invalid ISO8601 datetime string");
    const nanosecondsString = matched[1];
    const nanoseconds = Number(nanosecondsString?.slice(1) ?? 0) % 1000000;
    return new HighResDate(s, nanoseconds);
  });

export { HighResDate, Iso8601HighResDateSchema };
