import { memo } from "@chatbotgang/etude/react/memo";
import { useVisibilityStateStore } from "@zeffiroso/utils/react/useVisibilityStateStore";
import { secondsToMilliseconds } from "date-fns";
import { useEffect, useState } from "react";
import { ReadyState } from "react-use-websocket";

import { useLegato } from "@/legato";
import { DisconnectAlert } from "@/routes/Chat/ui/BannerAlert/DisconnectAlert";
import { LoadingBar } from "@/routes/Chat/ui/BannerAlert/LoadingBar";
import { useTokenStore } from "@/shared/services/token";

const DISCONNECT_ALERT_DELAY_MS = secondsToMilliseconds(10);

const BannerAlert = memo(function BannerAlert() {
  const visibilityState = useVisibilityStateStore();
  const token = useTokenStore((state) => state.value);
  const { readyState } = useLegato();
  const shouldShowDisconnectAlert: boolean =
    readyState !== ReadyState.OPEN &&
    /**
     * Do not show the disconnect alert if the page is not visible
     * or if the window is just reactivated from the background.
     */
    visibilityState === "visible" &&
    /**
     * Do not show the disconnect alert if we already know the token is
     * expired.
     */
    Boolean(token);
  const [delayedShowDisconnectAlert, setDelayedShowDisconnectAlert] =
    useState(false);
  useEffect(
    /**
     * If the `shouldShowDisconnectAlert` is `true`, then we will show the
     * disconnect alert after a delay for a better user experience.
     */
    function syncDelayedShowDisconnectAlert() {
      if (shouldShowDisconnectAlert === delayedShowDisconnectAlert) return;
      /**
       * If should not show the disconnect alert, it should be hidden
       * immediately.
       */
      if (!shouldShowDisconnectAlert) {
        setDelayedShowDisconnectAlert(false);
        return;
      }
      const timeout = setTimeout(() => {
        setDelayedShowDisconnectAlert(true);
      }, DISCONNECT_ALERT_DELAY_MS);
      return function cleanup() {
        clearTimeout(timeout);
      };
    },
    [shouldShowDisconnectAlert, delayedShowDisconnectAlert],
  );
  if (delayedShowDisconnectAlert) return <DisconnectAlert />;
  if (readyState !== ReadyState.OPEN) return <LoadingBar />;
  return null;
});

export { BannerAlert };
