import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import { throttle } from "lodash-es";
import {
  type ElementRef,
  type FC,
  type ReactNode,
  type Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { mergeRefs } from "react-merge-refs";
import type { T } from "ts-toolbelt";
import type { Writable } from "type-fest";

import { Card } from "@/components/Card";
import { FlexLayout } from "@/components/Card/FlexLayout";
import { styles, throttleMs } from "@/components/Card/RwdFlexCard/utils";
import { Divider } from "@/components/Divider";

const size = 3;
type Size = typeof size;

type LayoutComponentProps = {
  refs: T.Repeat<Ref<ElementRef<"div">>, Size>;
};

const LgLayout: FC<LayoutComponentProps> = ({ refs }) => {
  return (
    <FlexLayout.Row>
      <Card.NoPadding.Content css={styles.portal} ref={refs[0]} />
      <Divider type="vertical" />
      <Card.NoPadding.Content css={styles.portal} ref={refs[1]} />
      <Divider type="vertical" />
      <Card.NoPadding.Content css={styles.portal} ref={refs[2]} />
    </FlexLayout.Row>
  );
};

const MdLayout: FC<LayoutComponentProps> = ({ refs }) => {
  return (
    <>
      <FlexLayout.Row>
        <Card.NoPadding.Content css={styles.portal} ref={refs[0]} />
        <Divider type="vertical" />
        <Card.NoPadding.Content css={styles.portal} ref={refs[1]} />
      </FlexLayout.Row>
      <Divider />
      <FlexLayout.Row>
        <Card.NoPadding.Content css={styles.portal} ref={refs[2]} />
      </FlexLayout.Row>
    </>
  );
};

const SmLayout: FC<LayoutComponentProps> = ({ refs }) => {
  return (
    <>
      <FlexLayout.Row>
        <Card.NoPadding.Content css={styles.portal} ref={refs[0]} />
      </FlexLayout.Row>
      <FlexLayout.Row>
        <Card.NoPadding.Content css={styles.portal} ref={refs[1]} />
      </FlexLayout.Row>
      <Divider />
      <FlexLayout.Row>
        <Card.NoPadding.Content css={styles.portal} ref={refs[2]} />
      </FlexLayout.Row>
    </>
  );
};

type LayoutSize = "sm" | "md" | "lg";
const getLayoutSize = (width: number): LayoutSize => {
  if (width >= 900) return "lg";
  else if (width >= 600) return "md";
  else return "sm";
};

type RwdFlexCard_3_21_Ref = ElementRef<typeof Card>;
type RwdFlexCard_3_21_Props = Omit<ComponentProps<typeof Card>, "children"> & {
  items: T.Repeat<ReactNode, Size>;
};

/**
 * Responsive card layout with 2 columns in the first row and 3 columns in the
 * second row. The layout changes based on the width of the card.
 */
const RwdFlexCard_3_21 = forwardRef<
  RwdFlexCard_3_21_Ref,
  RwdFlexCard_3_21_Props
>(function CardLayout({ items, ...props }, ref) {
  const localRef = useRef<ElementRef<typeof Card>>(null);
  const mergedRef = useMemo(() => mergeRefs([ref, localRef]), [ref]);
  const [layoutSize, setLayoutSize] = useState<LayoutSize>("sm");
  const portal_0 = usePortal();
  const portal_1 = usePortal();
  const portal_2 = usePortal();

  useEffect(function responsiveCardLayout() {
    if (!localRef.current) return;
    let cancel = false;
    const rootElement = localRef.current;

    const throttleResizeHandler = throttle(function resizeHandler() {
      if (cancel) return;
      const size = getLayoutSize(rootElement.clientWidth);
      setLayoutSize(size);
    }, throttleMs);

    const resizeObserver = new ResizeObserver(throttleResizeHandler);
    resizeObserver.observe(rootElement);
    return function cleanup() {
      cancel = true;
      resizeObserver.disconnect();
    };
  }, []);

  const LayoutComponent = useMemo(
    () =>
      layoutSize === "sm"
        ? SmLayout
        : layoutSize === "md"
          ? MdLayout
          : LgLayout,
    [layoutSize],
  );
  const refs = useMemo(() => {
    const tuple = [
      portal_0.setOuterEl,
      portal_1.setOuterEl,
      portal_2.setOuterEl,
    ] as const;
    const readonlyRemoved = tuple as Writable<typeof tuple>;
    return readonlyRemoved;
  }, [portal_0, portal_1, portal_2]);
  return (
    <>
      <Card.NoPadding {...props} ref={mergedRef}>
        <LayoutComponent refs={refs} />
      </Card.NoPadding>
      {createPortal(items[0], portal_0.innerEl)}
      {createPortal(items[1], portal_1.innerEl)}
      {createPortal(items[2], portal_2.innerEl)}
    </>
  );
});

export { RwdFlexCard_3_21 };
export type { RwdFlexCard_3_21_Props, RwdFlexCard_3_21_Ref };
