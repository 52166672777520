import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Avatar } from "@/components/Avatar";
import { FormItem } from "@/components/Form";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import type { SelectProps } from "@/components/Select";
import { Select } from "@/components/Select";
import { availableUserFilter } from "@/resources/user/availableUserFilter";
import { UserName } from "@/resources/user/UserName";
import { UserStatusLabel } from "@/resources/user/UserStatus";
import { defineStyles } from "@/shared/emotion";
import type { ArrayPredicate } from "@/shared/types/ArrayPredicate";

const styles = defineStyles({
  nonActiveStatus: css({
    color: theme.colors.neutral005,
  }),
});

type OptionType = {
  key: number;
  value: number;
  label: ReactNode;
  text: string;
};

type UserSelectorRef = ElementRef<typeof Select>;
type UserSelectorProps = Omit<
  SelectProps<number, OptionType>,
  "options" | "mode"
> & {
  /**
   * User ID. `NaN` means no user selected.
   */
  filter?: ArrayPredicate<CantataTypes["User"]>;
  /**
   * Filter function to filter users. Default is to filter active users only.
   */
  myTeams?: boolean;
};

/**
 * User selector
 *
 * You can customize the selector with all props of Select component other than `options` and `mode`.
 *
 */
const UserSelector = forwardRef<UserSelectorRef, UserSelectorProps>(
  function UserSelector(
    { filter = availableUserFilter, myTeams = false, ...props },
    ref,
  ) {
    const { t } = useTranslation();
    const orgId = useActiveOrgIdStore((state) => state.value);

    const query = cantata.user.useList({
      params: {
        orgId,
      },
      queries: !myTeams
        ? undefined
        : {
            filter: "my-teams",
          },
    });
    const options = useMemo<SelectProps<number, OptionType>["options"]>(() => {
      if (!query.isSuccess) return [];

      const filteredOptions: Array<OptionType> = query.data.users
        .filter(filter)
        .map<OptionType>((user) => ({
          key: user.id,
          value: user.id,
          text: user.name,
          label: (
            <ItemWithIcon
              gap={16}
              startIcon={<Avatar src={user.avatar ?? ""} size={16} />}
            >
              <UserName user={user} />
            </ItemWithIcon>
          ),
        }));

      if (!props.value) return filteredOptions;
      if (filteredOptions.find((option) => option.value === props.value))
        return filteredOptions;

      const user = query.data.users.find((user) => user.id === props.value);

      if (!user) return filteredOptions;

      const fallbackOption: OptionType = {
        key: props.value,
        value: props.value,
        text: "",
        label: (
          <>
            <UserName user={user} />{" "}
            <span css={styles.nonActiveStatus}>
              {`(${t(UserStatusLabel.getUserStatusTranslationKey(user.status))})`}
            </span>
          </>
        ),
      };

      return [fallbackOption, ...filteredOptions];
    }, [filter, props.value, query.data?.users, query.isSuccess, t]);

    if (query.isLoading)
      return <Select {...props} loading disabled ref={ref} />;

    if (query.isError) {
      return (
        <FormItem
          help={inspectMessage`query error: ${query.error}`}
          validateStatus="error"
        >
          <Select {...props} loading disabled ref={ref} />
        </FormItem>
      );
    }
    return (
      <Select<number, OptionType>
        showSearch
        placeholder={t("assignment.searchAgent.placeholder")}
        {...props}
        optionFilterProp="text"
        options={options}
        ref={ref}
      />
    );
  },
);

export { UserSelector };

export type { UserSelectorProps, UserSelectorRef };
