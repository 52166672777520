import { createContext } from "@chatbotgang/etude/react/createContext";
import { CancelledError } from "@tanstack/react-query";
import { HttpStatusCode, isAxiosError } from "axios";
import { type FC, type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { useMessage } from "@/components/message";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { useRecentAssignees } from "@/routes/Chat/components/Assignee/useRecentAssignees";
import { assignErrorUtilsContext } from "@/routes/Chat/ui/ChatPanel/Header/components/Assignee/assignErrorUtilsContext";
import { useCheckMemberInMyScope } from "@/routes/Chat/useCheckMemberInMyScope";

function useUpdateProcessingStateMutation() {
  const { t } = useTranslation();
  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const mutation = cantata.member.useUpdateProcessingState(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      onSuccess() {
        message.success(t("common.updatedSuccessfully"));
      },
    },
  );
  return mutation;
}

function usePinMutations() {
  const { t } = useTranslation();
  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const pinMutation = cantata.member.usePin(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      onSuccess() {
        message.success(t("common.updatedSuccessfully"));
      },
    },
  );
  const unpinMutation = cantata.member.useUnpin(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      onSuccess() {
        message.success(t("common.updatedSuccessfully"));
      },
    },
  );
  return { pinMutation, unpinMutation };
}

function useAssignMutation() {
  const { t } = useTranslation();
  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const clearActiveMember = memberIdUtils.useClear();
  const checkMemberInMyScope = useCheckMemberInMyScope();
  const assignErrorUtils = assignErrorUtilsContext.useContext();
  const { addRecentAssignee } = useRecentAssignees();

  const memberQuery = cantata.member.useGetById(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      enabled: false,
    },
  );
  const assignMutation = cantata.member.useAssign(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      onSuccess(data) {
        if (
          data.assignmentRelationship.teamId &&
          data.assignmentRelationship.userId
        ) {
          addRecentAssignee({
            type: "user-in-team",
            teamId: data.assignmentRelationship.teamId,
            userId: data.assignmentRelationship.userId,
          });
        } else if (data.assignmentRelationship.teamId) {
          addRecentAssignee({
            type: "team",
            teamId: data.assignmentRelationship.teamId,
          });
        } else if (data.assignmentRelationship.userId) {
          addRecentAssignee({
            type: "user",
            userId: data.assignmentRelationship.userId,
          });
        }
        const isInMyScope = checkMemberInMyScope.exec({
          member: {
            assignmentRelationship: {
              team: !data.assignmentRelationship.teamId
                ? null
                : { id: data.assignmentRelationship.teamId, name: "" },
              user: !data.assignmentRelationship.userId
                ? null
                : { id: data.assignmentRelationship.userId, name: "" },
            },
          },
        });
        if (!isInMyScope) clearActiveMember();

        message.success(t("common.updatedSuccessfully"));
      },
      async onError(error) {
        if (!isAxiosError(error) || error instanceof CancelledError) return;
        if (error.status === HttpStatusCode.Forbidden) {
          const result = await memberQuery.refetch();
          if (!result.isSuccess || !result.data.assignmentRelationship.user) {
            message.error(t("common.apiError.unexpectedError"));
            return;
          }
          assignErrorUtils.setAssignedToSomeoneElseError({
            teamName: result.data.assignmentRelationship.team?.name ?? "",
            name: result.data.assignmentRelationship.user.name,
          });
        }
      },
    },
  );
  return assignMutation;
}

function useLeaveGroupMutation() {
  const { t } = useTranslation();
  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const groupId = memberIdUtils.useGet();
  const mutation = cantata.member.useLeaveGroup(
    {
      params: {
        orgId,
        groupId,
      },
    },
    {
      onSuccess() {
        message.success(t("common.updatedSuccessfully"));
      },
    },
  );
  return mutation;
}

function useMutationsController() {
  const updateProcessingStateMutation = useUpdateProcessingStateMutation();
  const { pinMutation, unpinMutation } = usePinMutations();
  const assignMutation = useAssignMutation();
  const leaveGroupMutation = useLeaveGroupMutation();

  const isLoading =
    updateProcessingStateMutation.isLoading ||
    pinMutation.isLoading ||
    unpinMutation.isLoading ||
    assignMutation.isLoading ||
    leaveGroupMutation.isLoading;

  const mutationsController = useMemo(
    () => ({
      updateProcessingStateMutation,
      pinMutation,
      unpinMutation,
      assignMutation,
      leaveGroupMutation,
      isLoading,
    }),
    [
      assignMutation,
      isLoading,
      pinMutation,
      unpinMutation,
      updateProcessingStateMutation,
      leaveGroupMutation,
    ],
  );
  return mutationsController;
}

const MutationsContext = createContext<
  ReturnType<typeof useMutationsController>
>({
  name: "MutationsContext",
});

const MutationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const mutationsController = useMutationsController();
  return (
    <DisabledContextProvider disabled={mutationsController.isLoading}>
      <MutationsContext.Provider value={mutationsController}>
        {children}
      </MutationsContext.Provider>
    </DisabledContextProvider>
  );
};

const mutationsController = {
  Provider: MutationsProvider,
  useContext: MutationsContext.useContext,
};

export { mutationsController };
