import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { flow } from "lodash-es";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { Line } from "@/components/antdPlots/Line";
import { tickFilters } from "@/components/antdPlots/utils";
import { Card } from "@/components/Card";
import { dayHourMinuteSecondDisplayToString } from "@/components/duration/DurationDisplay/DayHourMinuteSecondDisplay";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useFormatDate } from "@/resources/datetime";
import { DateIntervalSelect } from "@/routes/Insights/components/DateIntervalSelect";
import { queriesContext } from "@/routes/Insights/Efficiency/EfficiencyTrend/queries";
import { usePageInfoUtil } from "@/routes/Insights/Efficiency/pageInfo";
import { defineStyles } from "@/shared/emotion";

const defaultColors = [
  theme.colors.blue006,
  theme.colors.green006,
  theme.colors.yellow006,
];
const teamLevelColors = [theme.colors.green006, theme.colors.yellow006];

const styles = defineStyles({
  title: css({
    fontSize: "1.25rem",
    fontWeight: 500,
    color: theme.colors.neutral009,
  }),
  name: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral009,
  }),
});

const EfficiencyTrendLine: FC = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const pageInfo = usePageInfoUtil();
  const data = queriesContext.useData();
  const hasTeamFilter = pageInfo.data.teamIds.length > 0;

  const metrics = useMemo(() => {
    return flow(
      () => [
        ...(hasTeamFilter
          ? /**
             * Currently, we don't have the wait time data for team level.
             */
            []
          : data.waitTime.timeMetrics.map((item) => ({
              ...item,
              label: t("dashboard.efficiency.waitTime.label"),
            }))),
        ...data.firstResponse.timeMetrics.map((item) => ({
          ...item,
          label: t("dashboard.efficiency.firstResponseTime.label"),
        })),
        ...data.resolutionTime.timeMetrics.map((item) => ({
          ...item,
          label: hasTeamFilter
            ? t("dashboard.efficiency.resolutionTimeByTeam.label")
            : t("dashboard.efficiency.resolutionTime.label"),
        })),
      ],
      Ymd.ymdToDateDeep,
    )();
  }, [
    data.firstResponse.timeMetrics,
    data.resolutionTime.timeMetrics,
    data.waitTime.timeMetrics,
    hasTeamFilter,
    t,
  ]);

  return (
    <Line
      data={metrics}
      xField={(item) => formatDate(item.date)}
      yField="value"
      colorField="label"
      axis={{
        y: {
          labelFormatter(datum) {
            if (datum === null) {
              return EMPTY_STRING_PLACEHOLDER;
            }

            return dayHourMinuteSecondDisplayToString({ seconds: datum });
          },
          tickFilter: tickFilters.intergerOnly,
        },
      }}
      tooltip={{
        title: {
          field: "dateRange",
        },
        items: [
          {
            channel: "y",
            valueFormatter(datum) {
              if (datum === null) {
                return EMPTY_STRING_PLACEHOLDER;
              }

              return dayHourMinuteSecondDisplayToString({
                seconds: datum,
              });
            },
          },
        ],
      }}
      scale={{
        color: {
          range: hasTeamFilter ? teamLevelColors : defaultColors,
        },
      }}
    />
  );
};

export const EfficiencyTrend: FC = () => {
  const pageInfoUtil = usePageInfoUtil();
  const tagsInterval = pageInfoUtil.data.trendInterval;
  const filterIntervalByRange = DateIntervalSelect.useFilterIntervalByRange({
    startTime: pageInfoUtil.data.startTime,
    endTime: pageInfoUtil.data.endTime,
  });
  const handleIntervalChange = useHandler<
    ComponentProps<typeof DateIntervalSelect>["onChange"]
  >(function handleTagsIntervalChanged(value) {
    pageInfoUtil.update({
      trendInterval: value,
    });
  });

  return (
    <Card>
      <div css={styles.name}>
        <Trans
          i18nKey="dashboard.efficiency.trend.chart.selectRange"
          components={{
            SelectRange: (
              <DateIntervalSelect
                value={tagsInterval}
                onChange={handleIntervalChange}
                filter={filterIntervalByRange}
              />
            ),
          }}
        />
      </div>
      <Line.Wrapper>
        <ErrorBoundary.Alert fullSize>
          <queriesContext.Provider>
            <EfficiencyTrendLine />
          </queriesContext.Provider>
        </ErrorBoundary.Alert>
      </Line.Wrapper>
    </Card>
  );
};
