import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { ElementRef, ElementType } from "react";

import type {
  DayHourMinuteSecondDisplayProps,
  DayHourMinuteSecondDisplayType,
} from "@/components/duration/DurationDisplay/DayHourMinuteSecondDisplay";
import {
  classNameRecord,
  DayHourMinuteSecondDisplay,
} from "@/components/duration/DurationDisplay/DayHourMinuteSecondDisplay";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  duration: css({
    display: "flex",
    flexDirection: "row",
    gap: 8,
    [`.${classNameRecord.item}`]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      gap: 8,
    },
    [`.${classNameRecord.value}`]: {
      fontVariantNumeric: "tabular-nums",
    },
    [`.${classNameRecord.unit}`]: {
      fontSize: "0.75rem",
    },
  }),
});

type DurationRef = ElementRef<typeof DayHourMinuteSecondDisplay>;
type DurationProps = DayHourMinuteSecondDisplayProps;

/**
 * A styled `DayHourMinuteSecondDisplay` component for presenting a duration
 * within a Card component.
 *
 * For more information, please refer to `DayHourMinuteSecondDisplay`.
 *
 * @see {@link DayHourMinuteSecondDisplay}
 */
const Duration: DayHourMinuteSecondDisplayType = forwardRef(
  function Duration(props, ref) {
    return (
      <DayHourMinuteSecondDisplay<ElementType>
        {...props}
        css={styles.duration}
        ref={ref}
      />
    );
  },
) as DayHourMinuteSecondDisplayType;

assignDisplayName(Duration, "Duration");

export { Duration };
export type { DurationProps, DurationRef };
