import type { FC } from "react";

import { memberFieldRules } from "@/resources/member/memberValidator";
import { TextField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/TextField";

const CityField: FC = () => {
  return <TextField fieldName="city" rules={memberFieldRules.city} />;
};

export { CityField };
