import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { type FC, useMemo } from "react";

import { DebugTab } from "@/layout/DevMode/Content";
import { MainLayout } from "@/layout/MainLayout";
import { FeatureControl } from "@/router/components/Protected/InOrg/Debug/FeatureControl";
import { Permission } from "@/router/components/Protected/InOrg/Debug/Permission";

/**
 * The permission debug content.
 */
const DebugPermission: FC = () => {
  const tab = useMemo<ComponentProps<typeof DebugTab>["tab"]>(
    () => ({
      label: "Permission",
      children: (
        <MainLayout.Content>
          <Permission />
        </MainLayout.Content>
      ),
    }),
    [],
  );
  return <DebugTab tab={tab} />;
};

/**
 * The feature control debug content.
 */
const DebugFeatureControl: FC = () => {
  const tab = useMemo<ComponentProps<typeof DebugTab>["tab"]>(
    () => ({
      label: "Feature Control",
      children: (
        <MainLayout.Content>
          <FeatureControl />
        </MainLayout.Content>
      ),
    }),
    [],
  );
  return <DebugTab tab={tab} />;
};

const Debug = {
  FeatureControl: DebugFeatureControl,
  Permission: DebugPermission,
};

export { Debug };
