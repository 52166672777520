import type { featureFlags } from "@/config/featureFlags";
import type { createFeatureFlagApi, Types } from "@/internal/featureFlag";
import { commonFeatureFlagsApi } from "@/internal/featureFlag";
import { enableTestAccountFeatureFlag } from "@/internal/featureFlag/enableTestAccountFeatureFlag";
import { useLastUserEmailStore } from "@/shared/utils/createZustandStorageStore";

type FeatureFlagTypes = Types<typeof featureFlags>;

const api = commonFeatureFlagsApi as unknown as /**
 * Fully restore the original types from the configuration.
 */ ReturnType<typeof createFeatureFlagApi<typeof featureFlags>>;

const {
  getFeatureFlag,
  useEnabledSomeFeatureFlags,
  useFeatureFlag,
  useFeatureFlagLocalStorageStore,
  useFeatureFlagStore,
  useGetFeatureFlag,
  FeatureFlag,
  withFeatureFlagWrapper,
} = api;

useLastUserEmailStore.subscribe((state) => {
  const featureFlagState = useFeatureFlagLocalStorageStore.getState();
  if (!featureFlagState.value.keepFeatureFlags)
    useFeatureFlagLocalStorageStore.getState().clear();
  if (state.value) enableTestAccountFeatureFlag(state.value);
});

export {
  FeatureFlag,
  getFeatureFlag,
  useEnabledSomeFeatureFlags,
  useFeatureFlag,
  useFeatureFlagLocalStorageStore,
  useFeatureFlagStore,
  useGetFeatureFlag,
  withFeatureFlagWrapper,
};

export type { FeatureFlagTypes };
