import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { TDataFromUseQueryResult } from "@chatbotgang/etude/react-query/TDataFromUseQueryResult";
import { css } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import type { BadgeProps } from "antd";
import { Badge } from "antd";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { useLegatoEvent } from "@/legato";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { useCheckShouldInList } from "@/routes/Chat/ui/MembersPanel/useCheckShouldInList";

type UnreadProcessingState = Extract<
  CantataTypes["MemberProcessingState"],
  "new" | "follow-up"
>;

function useUnreadQuery(
  processingState: UnreadProcessingState,
  // select leads to a type error, so we omit it here as a workaround.
  queryOptions?: Omit<Parameters<typeof cantata.member.useUnread>[1], "select">,
) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channelId = searchController.useStore((state) => state.channelId);
  const filter = searchController.useStore((state) => state.filter);
  const query = cantata.member.useUnread(
    {
      params: {
        orgId,
      },
      queries: {
        ...(!channelId ? null : { channelId }),
        processingState,
        assignmentFilter: filter.assignmentFilter,
        ...(filter.assignmentFilter === "assignee"
          ? { assigneeId: filter.assigneeId }
          : null),
      },
    },
    queryOptions,
  );
  return query;
}

const UpdatedUnreadFromLegato = memo<{
  processingState: UnreadProcessingState;
}>(function UpdatedUnreadFromLegato({ processingState }) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const activeChannelId = searchController.useStore((state) => state.channelId);
  const queryClient = useQueryClient();
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const myTeamsQuery = cantata.team.useListMine(
    {
      params: {
        orgId,
      },
    },
    {
      useErrorBoundary: true,
      suspense: true,
    },
  );

  const orgQueriesData = orgQueriesContext.useData();
  const me = orgQueriesData.me;

  const filter = searchController.useStore((state) => state.filter);
  const isSearching = searchController.useStore(
    searchController.selectors.isSearching,
  );

  const checkShouldInList = useCheckShouldInList();

  const query = useUnreadQuery(processingState, {
    enabled: false,
  });

  const mutateUnreadCache = useHandler(function mutateUnreadCache(
    fn: Parameters<
      typeof queryClient.setQueryData<TDataFromUseQueryResult<typeof query>>
    >[1],
  ) {
    queryClient.setQueryData<TDataFromUseQueryResult<typeof query>>(
      query.key,
      fn,
    );
  });

  useLegatoEvent(
    "member-list-updated",
    function invalidateUnreadCountQuery(event) {
      if (!myTeamsQuery.isSuccess) return;

      // ignore the event when user is searching
      if (isSearching) return;

      const myTeams = myTeamsQuery.data.teams;
      const member = event.content.member;
      /**
       * ignore the member if the member is not in the current channel
       */
      if (member.channelId !== activeChannelId) return;

      const cachedUnread = queryClient
        .getQueryCache()
        .find<TDataFromUseQueryResult<typeof query>>(query.key);
      const hasUnreadMessage = member.unreadMessageCount > 0;
      const shouldInThisList = checkShouldInList({
        channelId: activeChannelId,
        processingState,
        assignmentFilter: filter.assignmentFilter,
        unread: filter.unread,
        me,
        myTeams,
        member,
      });

      // if the member is unread and should be in the list
      if (hasUnreadMessage && shouldInThisList) {
        if (!cachedUnread?.state.data?.unread) {
          // cached is not unread, update it
          mutateUnreadCache(() => ({ unread: true }));
        }
        return;
      }

      safeInvalidateQuery(query.key);
    },
  );
  return null;
});

const UnreadBadge = function UnreadBadge({
  processingState,
  children,
  ...props
}: Omit<BadgeProps, "dot"> & {
  processingState: UnreadProcessingState;
}) {
  const filter = searchController.useStore((state) => state.filter);
  const query = useUnreadQuery(processingState, {
    enabled:
      filter.assignmentFilter !== "assignee" || Boolean(filter.assigneeId),
  });

  if (query.isLoading || query.isError) {
    return (
      <Badge {...props} dot={false}>
        {children}
      </Badge>
    );
  }

  return (
    <Badge
      offset={[3, 3]}
      color={theme.colors.red005}
      css={css`
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
      `}
      {...props}
      dot={query.data.unread}
    >
      <UpdatedUnreadFromLegato processingState={processingState} />
      {children}
    </Badge>
  );
};

export { UnreadBadge };
