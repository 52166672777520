import type { CantataTypes } from "@/cantata/types";

const canSendMemberMessage = (
  member: Pick<CantataTypes["Member"], "processingState" | "status">,
): boolean => {
  return (
    ["new", "follow-up"].includes(member.processingState) &&
    member.status === "active"
  );
};

export { canSendMemberMessage };
