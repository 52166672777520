import type { CommonQueryOptions } from "@zeffiroso/utils/react-query/types";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";

/**
 * Get user by id with queries.
 */
function useGetUserByIdQueries(
  userId: number,
  queryOptions?: CommonQueryOptions,
) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const usersQuery = cantata.user.useList(
    {
      params: {
        orgId,
      },
    },
    queryOptions,
  );

  const userFromList = useMemo(
    function findUserFromList() {
      if (!usersQuery.isSuccess) return undefined;
      return usersQuery.data.users.find((user) => user.id === userId);
    },
    [userId, usersQuery.data, usersQuery.isSuccess],
  );

  const getUserQueryEnabled =
    usersQuery.isSuccess && userFromList === undefined;

  const getUserQuery = cantata.user.useGetById(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      ...queryOptions,
      enabled: getUserQueryEnabled,
    },
  );

  const user = useMemo<CantataTypes["User"] | undefined>(
    () => userFromList || getUserQuery.data,
    [getUserQuery.data, userFromList],
  );

  const ret = useMemo(
    () => ({
      ...((user
        ? ({
            isSuccess: true,
            isLoading: false,
            user,
          } as const)
        : ({
            isSuccess: false,
            isLoading:
              usersQuery.isLoading ||
              (getUserQueryEnabled && getUserQuery.isLoading),
            user: undefined,
          } as const)) satisfies
        | {
            isSuccess: true;
            isLoading: false;
            user: CantataTypes["User"];
          }
        | {
            isSuccess: false;
            isLoading: boolean;
            user: undefined;
          }),
      queries: {
        getUser: getUserQuery,
        users: usersQuery,
      },
    }),
    [getUserQuery, getUserQueryEnabled, user, usersQuery],
  );

  return ret;
}

export { useGetUserByIdQueries };
