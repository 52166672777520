import type { FC } from "react";

import { memberFieldRules } from "@/resources/member/memberValidator";
import { TextField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/TextField";

const LocationField: FC = () => {
  return <TextField fieldName="location" rules={memberFieldRules.location} />;
};

export { LocationField };
