import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import EventEmitter from "eventemitter3";
import PQueue from "p-queue";

const isFlutterWebViewApp = Boolean(
  "flutter_inappwebview" in window && window.flutter_inappwebview,
);

class NonFlutterWebViewAppError extends Error {
  override name = "NonFlutterWebViewAppError";
}

/**
 * The queue for the requests.
 */
const pQueue = new PQueue({ concurrency: 1 });

const requestNotificationPermission = async (): Promise<
  FlutterWebviewAppSdk.RequestMap["requestNotificationPermission"]
> => {
  if (!isFlutterWebViewApp || !window.flutter_inappwebview) {
    throw new NonFlutterWebViewAppError();
  }
  return window.flutter_inappwebview.callHandler(
    "requestNotificationPermission",
  );
};

const queuedRequestNotificationPermission: typeof requestNotificationPermission =
  async (...args) => {
    const result = await pQueue.add(async () =>
      requestNotificationPermission(...args),
    );

    if (result === undefined)
      throw new Error(inspectMessage`result is undefined, result: ${result}`);
    return result;
  };

const getFcmToken = async (): Promise<string> => {
  if (!isFlutterWebViewApp || !window.flutter_inappwebview) {
    throw new NonFlutterWebViewAppError();
  }
  return window.flutter_inappwebview.callHandler("getCaacWebViewAppFcmToken");
};

const queuedGetFcmToken: typeof getFcmToken = async (...args) => {
  const result = await pQueue.add(async () => getFcmToken(...args));

  if (result === undefined)
    throw new Error(inspectMessage`result is undefined, result: ${result}`);
  return result;
};

const revokeFcmToken = async (): Promise<void> => {
  if (!isFlutterWebViewApp || !window.flutter_inappwebview) {
    throw new NonFlutterWebViewAppError();
  }
  return window.flutter_inappwebview.callHandler(
    "revokeCaacWebViewAppFcmToken",
  );
};

const queuedRevokeFcmToken: typeof revokeFcmToken = async (...args) => {
  await pQueue.add(async () => revokeFcmToken(...args));
};

const eventEmitter = (() => {
  if (!isFlutterWebViewApp || !window.flutterPermissionUpdated) return;
  const eventEmitter = new EventEmitter<FlutterWebviewAppSdk.EventMap>();
  const originalCallback = window.flutterPermissionUpdated;
  window.flutterPermissionUpdated = (...args) => {
    // prevent overriding the original callback
    originalCallback(...args);
    eventEmitter.emit("flutterPermissionUpdated", ...args);
  };
  return eventEmitter;
})();

/**
 * The SDK for the Flutter WebView App.
 */
const flutterWebViewAppSdk = {
  isFlutterWebViewApp,
  requestNotificationPermission: queuedRequestNotificationPermission,
  getFcmToken: queuedGetFcmToken,
  revokeFcmToken: queuedRevokeFcmToken,
  eventEmitter,
};

export { flutterWebViewAppSdk };
