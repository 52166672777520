import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";
import { ErrorFallbackPage } from "@/internal/PageErrorBoundary/ErrorFallbackPage";
import { useLogoutMutation } from "@/shared/application/authenticate";

const NoOrg: FC = () => {
  const { t } = useTranslation();
  const logoutMutation = useLogoutMutation();

  const logout = useHandler(() => {
    logoutMutation.mutate();
  });

  return (
    <>
      <ErrorFallbackPage
        action={
          <Button onClick={logout} type="primary">
            {t("common.logout")}
          </Button>
        }
        title={t("common.applicationError.noOrganization")}
        description={() => null}
      />
      {!logoutMutation.isLoading ? null : <Modal.Loading open />}
    </>
  );
};

export { NoOrg };
