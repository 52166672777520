import { createContext } from "@chatbotgang/etude/react/createContext";
import useSwitch from "@react-hook/switch";
import type { FC, ReactNode } from "react";

const Context = createContext<ReturnType<typeof useSwitch>>({
  name: "AssigneeContext",
});

const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const contextValue = useSwitch(false);
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const assigneeContext = {
  Provider,
  useGet: Context.useContext,
};

export { assigneeContext };
