import { type FC, type ReactNode, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { authProviderStore } from "@/app/auth";
import { fromSsoPageStore } from "@/app/auth/fromSsoPageStore";
import { SEARCH_PARAM_KEY_LOGIN_REDIRECT } from "@/appConstant";
import { BarLoading } from "@/components/Loading/BarLoading";
import { rootQueriesContext } from "@/queriesContext/rootQueriesContext";
import { InOrg } from "@/router/components/Protected/InOrg";
import { useIsDifferentAccountSignedInStore } from "@/router/components/Protected/InOrg/useIsDifferentAccountSignedInStore";
import { ExpiredModal } from "@/router/ExpiredModal";
import type { ValidPathString } from "@/router/types";
import * as commonUtils from "@/router/utils";
import { compileToString } from "@/router/utils/compileTo";
import { useAuthStatus } from "@/shared/application/authenticate";

/**
 * Need to be signed in to view the content.
 */
const Protected: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const fromSsoPage = fromSsoPageStore.useValue();
  const authProvider = authProviderStore.useStore((state) => state.value);
  const isDifferentAccountSignedIn = useIsDifferentAccountSignedInStore();
  const location = useLocation();
  const redirect = useMemo(
    () => location.pathname + location.search + location.hash,
    [location.hash, location.pathname, location.search],
  );

  const signedOutTo = useMemo<ValidPathString>(
    () =>
      compileToString({
        ...(fromSsoPage && authProvider?.domain
          ? {
              pathname: "/login/sso/:domain",
              params: { domain: authProvider.domain },
            }
          : {
              pathname: "/login",
            }),
        ...(redirect === "/"
          ? null
          : {
              search: `?${new URLSearchParams({ [SEARCH_PARAM_KEY_LOGIN_REDIRECT]: redirect }).toString()}`,
            }),
      }),
    [authProvider?.domain, fromSsoPage, redirect],
  );

  const authStatus = useAuthStatus();
  if (isDifferentAccountSignedIn || authStatus.isLoading) return <BarLoading />;
  if (authStatus.isSignedOut)
    return <commonUtils.Navigate replace to={signedOutTo} />;
  return (
    <rootQueriesContext.Provider>
      <InOrg>
        {children}
        {!authStatus.isExpired ? null : <ExpiredModal />}
      </InOrg>
    </rootQueriesContext.Provider>
  );
};

export { Protected };
