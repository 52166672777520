import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { Skeleton } from "antd";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useFeatureFlag } from "@/app/featureFlag";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import type { LinkProps } from "@/components/Link";
import { Link } from "@/components/Link";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { conversationHistoryMessages } from "@/routes/Chat/ui/conversationHistoryMessages";
import { useJumpToMessageController } from "@/routes/Chat/ui/jumpToMessage";
import { useConversation } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Context";
import { useCanViewConversationHistories } from "@/routes/Chat/useCanViewConversationHistories";
import { useCheckMemberInMyScope } from "@/routes/Chat/useCheckMemberInMyScope";

/**
 * TODO: this is not ready yet.
 *
 * Need to jumpToConversation when both redirect and open in new tab.
 */
const JumpTo = memo(function JumpTo() {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  /**
   * Since we use nested member IDs for group chats, we need to use the
   * outermost member ID to navigate to the correct conversation.
   */
  const activeMemberId = memberIdUtils.useGetOutermost();
  const setActiveMemberId = memberIdUtils.useSetOutermost();
  const conversation = useConversation();
  const jumpToMessageController = useJumpToMessageController();
  const onClick = useHandler<LinkProps["onClick"]>(function onClick(e) {
    e.preventDefault();
    e.stopPropagation();

    if (activeMemberId !== conversation.memberId)
      setActiveMemberId(conversation.memberId);

    jumpToMessageController.setupConversation({
      orgId,
      memberId: conversation.memberId,
      conversationId: conversation.conversationId,
    });
  });
  return (
    <Link onClick={onClick}>
      {t(
        "chat.memberProfilePanel.tabs.engagement.tags.conversationHistory.actions.jumpToMessage.label",
      )}
    </Link>
  );
});

const ViewConversationHistory = memo(function ViewConversationHistory() {
  const { t } = useTranslation();
  const conversation = useConversation();
  const conversationHistoryMessagesController =
    conversationHistoryMessages.useController();
  const onClick = useHandler<LinkProps["onClick"]>(function onClick() {
    conversationHistoryMessagesController.open({
      memberId: conversation.memberId,
      conversationId: conversation.conversationId,
    });
  });
  return (
    <Link onClick={onClick}>
      {t(
        "chat.memberProfilePanel.tabs.engagement.tags.conversationHistory.actions.viewHistory.label",
      )}
    </Link>
  );
});

const useOverrideConversationHistoryAction = (() => {
  function useFeatureFlagValue() {
    return useFeatureFlag("overrideConversationHistoryAction");
  }
  const overrideConversationHistoryActionMap: Record<
    NonNullable<ReturnType<typeof useFeatureFlagValue>>,
    ReactNode
  > = {
    jumpTo: <JumpTo />,
    viewHistory: <ViewConversationHistory />,
  };
  return function useOverrideConversationHistoryAction() {
    const overrideConversationHistoryAction = useFeatureFlagValue();
    return !overrideConversationHistoryAction
      ? undefined
      : overrideConversationHistoryActionMap[overrideConversationHistoryAction];
  };
})();

const Action = memo(function Action() {
  const overrideConversationHistoryAction =
    useOverrideConversationHistoryAction();
  const conversation = useConversation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberQuery = cantata.member.useGetById({
    params: {
      orgId,
      memberId: conversation.memberId,
    },
  });
  const checkMemberInMyScope = useCheckMemberInMyScope();
  const isLoading = memberQuery.isLoading || checkMemberInMyScope.isLoading;
  const isScope = useMemo(
    () =>
      memberQuery.isSuccess &&
      checkMemberInMyScope.exec({
        member: memberQuery.data,
      }),
    [checkMemberInMyScope, memberQuery],
  );
  const canViewHistories = useCanViewConversationHistories();
  const computedCanViewHistories = useMemo(
    function computeCanViewHistories() {
      if (!memberQuery.isSuccess) return false;
      return canViewHistories.exec({ member: memberQuery.data });
    },
    [canViewHistories, memberQuery.data, memberQuery.isSuccess],
  );

  if (overrideConversationHistoryAction)
    return overrideConversationHistoryAction;
  if (isLoading) return <Skeleton.Input />;
  if (checkMemberInMyScope.errorDisplay)
    return checkMemberInMyScope.errorDisplay;

  if (memberQuery.isError)
    return <Alert type="error" message={memberQuery.error.message} />;

  if (isScope) return <JumpTo />;

  if (computedCanViewHistories) return <ViewConversationHistory />;

  return null;
});

export { Action };
