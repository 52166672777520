import { QrcodeOutlined } from "@ant-design/icons";
import { memo } from "@chatbotgang/etude/react/memo";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useSwitch from "@react-hook/switch";
import { breakpointApi, theme } from "@zeffiroso/theme";
import type { TableColumnProps as ColumnProps } from "antd";
import type { FC, ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { Table } from "@/components/Table";
import { SubText, Text } from "@/components/Typography";
import { UserAssignmentQrcodeModal } from "@/resources/assignmentQrcode/UserAssignmentQrcodeModal";
import { UserAvatar } from "@/resources/user/UserAvatar";
import { UserTeamsInline } from "@/resources/user/UserTeamsInline";
import { routerUtils } from "@/router/routerUtils";
import { AddUserModalBatch } from "@/routes/Settings/Users/People/List/AddUserModalBatch";
import { DeleteUser } from "@/routes/Settings/Users/People/List/DeleteUser";
import { ImportUsersModal } from "@/routes/Settings/Users/People/List/importUsers/ImportUsersModal";
import { ImportUsersToggleButton } from "@/routes/Settings/Users/People/List/importUsers/ImportUsersToggleButton";
import { UpdateUserStatus } from "@/routes/Settings/Users/People/List/UpdateUserStatus";
import { useUserInfo } from "@/shared/application/user";
import { isDefaultRoleType } from "@/shared/domains/role";
import { defineStyles } from "@/shared/emotion";
import { EditOutlined as EditSvg } from "@/shared/icons/common/EditOutlined";
import { defaultRoleTypeTranslationKeyMap } from "@/shared/utils/translation/defaultRoleTypeTranslationKeyMap";

const styles = defineStyles({
  name: css({
    display: "flex",
    alignItems: "center",
    gap: 16,
  }),
});

const MobileControlGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const Assignment: FC<{ userId: CantataTypes["User"]["id"] }> = ({ userId }) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [open, toggle] = useSwitch(false);
  const mergeFormDisabled = useMergeFormDisabled();
  const disabled = useMemo(
    () => mergeFormDisabled(open),
    [mergeFormDisabled, open],
  );
  return (
    <div
      css={css`
        color: ${theme.colors.neutral005};
      `}
    >
      <NarrowIconButton
        size="16px"
        onClick={toggle.on}
        icon={<QrcodeOutlined />}
        disabled={disabled}
      />
      {open && (
        <UserAssignmentQrcodeModal
          orgId={orgId}
          userId={userId}
          onCancel={toggle.off}
        />
      )}
    </div>
  );
};

const MobileTableMiniRow = memo(function MobileTableMiniRow({
  title,
  content,
}: {
  title: ReactNode;
  content?: ReactNode;
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        gap: 8px;
      `}
    >
      <div
        css={css`
          width: 30%;
        `}
      >
        {title}
      </div>
      <div
        css={css`
          width: calc(70% - 8px);
        `}
      >
        {content}
      </div>
    </div>
  );
});

const RoleBox = memo<{
  roleType: CantataTypes["User"]["roleType"];
  roleName: string;
}>(function RoleBox({ roleType, roleName }) {
  return isDefaultRoleType(roleType) ? (
    <Text>
      <Trans i18nKey={defaultRoleTypeTranslationKeyMap[roleType]} />
    </Text>
  ) : (
    <Text>{roleName}</Text>
  );
});

/**
 * Extend user object with displayName, which is computed from user's name or email
 */
const extendDisplayName = (user: CantataTypes["User"]) => {
  const emailUserName = user.email.split("@")[0];
  return {
    ...user,
    displayName: user.name ? user.name : emailUserName[0],
  };
};

const extendUsers = ({ users }: { users: CantataTypes["User"][] }) =>
  users.map(extendDisplayName);

type User = ReturnType<typeof extendUsers>[number];

const Users: FC = () => {
  const isSalesBindingEnabled = useFeatureFlag("salesBinding");
  const mergeFormDisabled = useMergeFormDisabled();
  const lteMobile = breakpointApi.useLte("mobile");
  const { t } = useTranslation();
  const navigate = routerUtils.useNavigate();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const usersQuery = cantata.user.useList(
    {
      params: {
        orgId,
      },
    },
    {
      select: extendUsers,
    },
  );
  const [addUserModalOpened, toggleAddUserModal] = useSwitch(false);
  const [importUsersModalOpened, toggleImportUsersModal] = useSwitch(false);
  const me = useUserInfo();
  const meId = me?.id ?? 0;

  const checkCanOperateEditUser = useCallback(
    (record: CantataTypes["User"]) => {
      return record.id !== meId;
    },
    [meId],
  );

  const checkCanOperateDeleteUser = useCallback(
    (record: CantataTypes["User"]) => {
      return record.id !== meId && record.roleType !== "owner";
    },
    [meId],
  );

  const goToEditUser = useCallback(
    (userId: number) => {
      navigate({
        pathname: "/settings/users/edit/:userId",
        params: {
          userId,
        },
      });
    },
    [navigate],
  );

  const columns = useMemo<ColumnProps<User>[]>(() => {
    const mobileColumns = define<ColumnProps<User>[]>([
      {
        key: "email",
        render: (_, record) => {
          return (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
              `}
            >
              <MobileTableMiniRow
                title={<SubText>{t("common.name")}</SubText>}
                content={
                  <div css={styles.name}>
                    <UserAvatar
                      name={record.displayName}
                      avatar={record.avatar}
                      size="small"
                    />
                    <Text>{record.displayName}</Text>
                  </div>
                }
              />
              <MobileTableMiniRow
                title={<SubText>{t("common.displayName")}</SubText>}
                content={
                  <Text>{record.chatName || EMPTY_STRING_PLACEHOLDER}</Text>
                }
              />
              <MobileTableMiniRow
                title={<SubText>{t("glossary.email")}</SubText>}
                content={<Text>{record.email}</Text>}
              />
              <MobileTableMiniRow
                title={<SubText>{t("organization.roleSetting")}</SubText>}
                content={
                  <RoleBox roleType={record.roleType} roleName={record.name} />
                }
              />
              <MobileTableMiniRow
                title={<SubText>{t("organization.team")}</SubText>}
                content={
                  <Text>
                    <UserTeamsInline userId={record.id} />
                  </Text>
                }
              />
              <MobileTableMiniRow
                title={<SubText>{t("organization.state")}</SubText>}
                content={
                  <UpdateUserStatus status={record.status} userId={record.id} />
                }
              />
              <MobileControlGroup>
                <Assignment userId={record.id} />
                {checkCanOperateEditUser(record) && (
                  <NarrowIconButton
                    size="small"
                    data-test="user-edit-icon"
                    onClick={() => goToEditUser(record.id)}
                    icon={<EditSvg />}
                  />
                )}
                {checkCanOperateDeleteUser(record) && (
                  <DeleteUser userId={record.id} userName={record.name} />
                )}
              </MobileControlGroup>
            </div>
          );
        },
      },
    ]);

    return lteMobile
      ? mobileColumns
      : define<ColumnProps<User>[]>([
          {
            title: (
              <SubText>
                <Trans i18nKey="common.name" />
              </SubText>
            ),
            sorter: (a, b) => a.displayName.localeCompare(b.displayName),
            key: "name",
            render: (_, user) => (
              <div css={styles.name}>
                <UserAvatar
                  name={user.displayName}
                  avatar={user.avatar}
                  size="small"
                />
                <Text>{user.displayName}</Text>
              </div>
            ),
            responsive: ["md"],
          },
          {
            title: (
              <SubText>
                <Trans i18nKey="glossary.email" />
              </SubText>
            ),
            dataIndex: "email",
            key: "email",
            render: (email: string) => <Text>{email}</Text>,
          },
          {
            title: (
              <SubText>
                <Trans i18nKey="organization.roleSetting" />
              </SubText>
            ),
            dataIndex: "roleName",
            key: "roleName",
            render: (roleName: string, { roleType }) => (
              <RoleBox roleType={roleType} roleName={roleName} />
            ),
          },
          {
            title: (
              <SubText>
                <Trans i18nKey="organization.team" />
              </SubText>
            ),
            key: "team",
            render: (_, user) => <UserTeamsInline userId={user.id} />,
          },
          {
            title: <SubText>{t("myProfile.qrCode")}</SubText>,
            key: "qrcode",
            dataIndex: "id",
            render: (userId: number) => <Assignment userId={userId} />,
          },
          {
            title: (
              <SubText>
                <Trans i18nKey="organization.state" />
              </SubText>
            ),
            dataIndex: "status",
            key: "status",
            render: (_, record) => (
              <UpdateUserStatus userId={record.id} status={record.status} />
            ),
          },
          {
            key: "tools",
            render: (_, record) => (
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  gap: 8px;
                  text-align: right;

                  svg {
                    color: ${theme.colors.neutral005};

                    &:hover,
                    &:active {
                      color: ${theme.colors.neutral006};
                    }

                    &:active {
                      color: ${theme.colors.neutral007};
                    }

                    &[disabled] {
                      color: ${theme.colors.neutral001};
                    }
                  }
                `}
              >
                {checkCanOperateEditUser(record) && (
                  <NarrowIconButton
                    size="middle"
                    data-test="user-edit-icon"
                    onClick={() => goToEditUser(record.id)}
                    icon={<EditSvg />}
                  />
                )}
                {checkCanOperateDeleteUser(record) && (
                  <DeleteUser userId={record.id} userName={record.name} />
                )}
              </div>
            ),
          },
        ]);
  }, [
    checkCanOperateDeleteUser,
    checkCanOperateEditUser,
    goToEditUser,
    lteMobile,
    t,
  ]);

  const tableSearch = Table.useTableSearch({
    dataSource: usersQuery.data ?? [],
    columns,
    fuseOptions: {
      keys: ["displayName", "email"],
    },
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          flexWrap: "wrap",
        }}
      >
        {tableSearch.searchInput}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "inherit",
            marginLeft: "auto",
          }}
        >
          {!isSalesBindingEnabled ? null : (
            <ImportUsersToggleButton
              onClick={toggleImportUsersModal.on}
              disabled={mergeFormDisabled(importUsersModalOpened)}
            />
          )}
          <Button
            data-test="invite-user-add-button"
            type="primary"
            onClick={toggleAddUserModal.on}
            disabled={mergeFormDisabled(addUserModalOpened)}
          >
            <Trans i18nKey="common.add" />
          </Button>
        </div>
      </div>
      <Table
        data-test="user-list-table"
        rowKey="id"
        columns={tableSearch.columns}
        dataSource={usersQuery.data}
        scroll={{ x: "100%" }}
        loading={usersQuery.isLoading}
      />
      {!importUsersModalOpened ? null : (
        <ImportUsersModal onCancel={toggleImportUsersModal.off} />
      )}
      {!addUserModalOpened ? null : (
        <AddUserModalBatch onVisibleChange={toggleAddUserModal} />
      )}
    </>
  );
};

export { Users };
