import { memo } from "@chatbotgang/etude/react/memo";
import { useTranslation } from "react-i18next";

import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { UserRecognition } from "@/routes/Settings/ChatSettings/UserRecognition";

const ChatSettings = memo(function ChatSettings() {
  const { t } = useTranslation();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header>
        {t("organization.chatSettings.title")}
      </MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <MainLayout.Section>
            <UserRecognition />
          </MainLayout.Section>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
});

export { ChatSettings };
