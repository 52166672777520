import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import { markdownToHtml } from "@zeffiroso/utils/markdown/markdownToHtml";
import {
  type ElementRef,
  type ElementType,
  type ReactNode,
  useMemo,
} from "react";

import { defineStyles } from "@/shared/emotion";

const defaultRootElement = "div" as const;

namespace Markdown {
  export type DangerouslySetInnerHTMLProp =
    ComponentProps<"div">["dangerouslySetInnerHTML"];
  export type classNameProp = ComponentProps<"div">["className"];
  export interface BaseRootElementProps {
    className?: classNameProp;
    dangerouslySetInnerHTML?: DangerouslySetInnerHTMLProp;
  }
  export type BaseRootElement = ElementType<BaseRootElementProps>;
  export type DefaultRootElement = typeof defaultRootElement;
  export interface OwnProps {
    /**
     * Markdown content.
     */
    markdown: string;

    options?: Overwrite<
      markdownToHtml.Options,
      {
        markedOptions?: Omit<markdownToHtml.Options["markedOptions"], "async">;
      }
    >;
    /**
     * The root element to render.
     */
    component?: BaseRootElement;
  }
  export type Props<TRootElement extends BaseRootElement = DefaultRootElement> =
    Overwrite<
      DistributiveOmit<
        ComponentProps<TRootElement>,
        "dangerouslySetInnerHTML" | "children"
      >,
      OwnProps
    >;
  export interface Type {
    <TRootElement extends BaseRootElement = DefaultRootElement>(
      props: Overwrite<Props<TRootElement>, { component: TRootElement }>,
      ref?: ElementRef<TRootElement>,
    ): ReactNode;
    (
      props: DistributiveOmit<Props, "component">,
      ref?: ElementRef<DefaultRootElement>,
    ): ReactNode;
  }
}

const styles = defineStyles({
  markdown: css({
    whiteSpace: "wrap",
    "&>p": {
      margin: 0,
    },
    "& ul, & ol": {
      paddingLeft: 20,
      margin: 0,
    },

    "&>ul": {
      "&>li": {
        listStyleType: "disc",
      },
    },
  }),
});

/**
 * Render markdown as HTML.
 */
const Markdown: Markdown.Type = forwardRef<
  Markdown.BaseRootElement,
  Markdown.OwnProps
>(function Markdown(
  { markdown, options, component: Component = defaultRootElement, ...props },
  ref,
) {
  const dangerouslySetInnerHTML = useMemo<Markdown.DangerouslySetInnerHTMLProp>(
    () => ({ __html: markdownToHtml(markdown, options) }),
    [markdown, options],
  );
  return (
    <Component
      {...props}
      css={styles.markdown}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      {...(!ref ? null : ({ ref } as Record<PropertyKey, unknown>))}
    />
  );
}) as unknown as Markdown.Type;

assignDisplayName(Markdown, "Markdown");

export { Markdown };
