import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { type ReactNode, useMemo } from "react";

import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";

function useMemberQueries({
  orgId,
  memberId,
}: {
  orgId: CantataTypes["Org"]["id"];
  memberId: CantataTypes["Member"]["id"];
}) {
  const memberQuery = cantata.member.useGetById(
    {
      params: { orgId, memberId },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const channelId: CantataTypes["Channel"]["id"] =
    memberQuery.data?.channelId ?? Number.NaN;
  const channelQuery = cantata.channel.useGetById(
    {
      params: { orgId, channelId },
    },
    {
      enabled: !Number.isNaN(channelId),
      suspense: true,
      useErrorBoundary: true,
    },
  );

  const result = useMemo(
    () => ({
      member: memberQuery,
      channel: channelQuery,
    }),
    [memberQuery, channelQuery],
  );

  return result;
}

const MemberQueriesContext =
  createQueriesContext<ReturnType<typeof useMemberQueries>>();

function Provider({
  children,
  ...params
}: Parameters<typeof useMemberQueries>[0] & {
  children: ReactNode;
}) {
  const queries = useMemberQueries(params);
  return (
    <MemberQueriesContext.Provider queries={queries}>
      {children}
    </MemberQueriesContext.Provider>
  );
}

/**
 * Wrap the children with the queries provider.
 */
const MemberQueries = {
  Provider,
  useQueries: MemberQueriesContext.useQueries,
  useData: MemberQueriesContext.useData,
};

export { MemberQueries };
