import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { define } from "@chatbotgang/etude/util/define";
import { css, type CSSObject } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { merge } from "lodash-es";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type ReactNode,
  useMemo,
} from "react";

import { defineStyles } from "@/shared/emotion";

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

const repeatTrackSizeOptions = define<Array<string>>()([
  "auto-fill",
  "auto-fit",
]);
type RepeatTrackSize = (typeof repeatTrackSizeOptions)[number];

interface AutoGridOwnProps {
  /**
   * The repeat track size of the grid.
   *
   * Reference: [mdn web docs -
   * repeat()](https://developer.mozilla.org/en-US/docs/Web/CSS/repeat#track-size)
   */
  repeatTrackSize?: RepeatTrackSize;
  /**
   * The minimum width of the grid item.
   *
   * - `auto-fill`: `min-width <= width < min-width * 2`
   * - `auto-fit`: `min-width <= width <= 1fr`
   */
  minWidth: NonNullable<CSSObject["minWidth"]>;
  /**
   * The content of the component.
   */
  children?: ReactNode;
  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface AutoGridTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & AutoGridOwnProps;
  defaultComponent: RootComponent;
}

type AutoGridProps<
  RootComponent extends ElementType = AutoGridTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = AutoGridOwnProps &
  OverrideProps<
    AutoGridTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const seed = random();

const cssVarPrefix = `--auto-grid-${seed}-`;
const cssVarMinWidth = `${cssVarPrefix}min-width`;
const cssVarRepeatTrackSize = `${cssVarPrefix}repeat-track-size`;

const styles = defineStyles({
  root: css({
    display: "grid",
    gridTemplateColumns: `repeat(var(${CSS.escape(cssVarRepeatTrackSize)}), minmax(min(var(${CSS.escape(cssVarMinWidth)}), 100%), 1fr))`,
  }),
});

/**
 * A grid component that automatically adjusts the number of columns based on
 * the available space.
 *
 * This component resolves align the items in the justify direction dynamically that we don't need to define the template for
 * each screen size.
 */
const AutoGrid: OverridableComponent<AutoGridTypeMap> = forwardRef(
  function AutoGrid(
    {
      minWidth,
      repeatTrackSize,
      children,
      component: Component = defaultComponent,
      ...props
    }: AutoGridProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const style = useMemo(
      () =>
        merge({}, props.style, {
          [cssVarMinWidth]: minWidth,
          [cssVarRepeatTrackSize]: repeatTrackSize,
        }),
      [minWidth, props.style, repeatTrackSize],
    );
    return (
      <Component css={styles.root} {...props} style={style} ref={ref}>
        {children}
      </Component>
    );
  },
) as OverridableComponent<AutoGridTypeMap>;

assignDisplayName(AutoGrid, "AutoGrid");

export { AutoGrid, defaultComponent, repeatTrackSizeOptions };

export type { AutoGridOwnProps, AutoGridProps, AutoGridTypeMap };
