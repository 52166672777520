import type { F } from "ts-toolbelt";
import { type Primitive, z } from "zod";

/**
 * Create an array of literals
 *
 * ```ts
 * type LiteralArray = ["foo", "bar"]; // [z.ZodLiteral<"foo">, z.ZodLiteral<"bar">]
 * ```
 */
type MapLiteralArray<
  TArray extends [Primitive, Primitive, ...Array<Primitive>],
> = {
  [TKey in keyof TArray]: z.ZodLiteral<TArray[TKey]>;
};

/**
 * Declare a union of literals. Similar to `z.enum` but not only for strings.
 *
 * ```ts
 * const options = ["foo", "bar", 9527] as const;
 * const union = literalUnion(options); // z.ZodUnion<[z.ZodLiteral<"foo">, z.ZodLiteral<"bar">, z.ZodLiteral<9527>]>
 * ```
 */
function literalUnion<
  TArray extends [Primitive, Primitive, ...Array<Primitive>],
>(literals: F.Narrow<TArray>): z.ZodUnion<MapLiteralArray<TArray>> {
  return z.union(
    literals.map((value) => z.literal(value)) as MapLiteralArray<TArray>,
  );
}
export { literalUnion };
