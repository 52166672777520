import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import classNames from "classnames";
import { merge } from "lodash-es";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type ReactNode,
  useMemo,
  useState,
} from "react";

import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { MotifIcon } from "@/components/MotifIcon";
import { cssOneLine, defineStyles } from "@/shared/emotion";

const defaultComponent = "button";

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface ChipOwnProps {
  /**
   * The content of the chip.
   */
  children?: ReactNode;

  /**
   * Checked state of the chip. If not provided, the component will manage its
   * own state.
   */
  checked?: boolean;

  /**
   * Callback when the chip is toggled. It won't be called if
   * `event.preventDefault` is called in the `onClick` callback.
   */
  onChange?: (
    e: Parameters<NonNullable<ComponentProps<DefaultComponent>["onChange"]>>[0],
    checked: boolean,
  ) => void;

  /**
   * The start icon of the chip. Will be replaced by the `checkedIcon` if the
   * chip is checked.
   */
  startIcon?: ReactNode;

  /**
   * The icon to display when the chip is checked.
   */
  checkedIcon?: ReactNode;

  /**
   * The disabled state of the chip.
   */
  disabled?: boolean;

  /**
   * Props for the span that wraps the start icon.
   */
  startIconSpanProps?: Omit<ComponentProps<"span">, "children">;

  /**
   * Props for the span that wraps the children.
   */
  childrenSpanProps?: Omit<ComponentProps<"span">, "children">;

  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface ChipTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & ChipOwnProps;
  defaultComponent: RootComponent;
}

type ChipProps<
  RootComponent extends ElementType = ChipTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = ChipOwnProps &
  OverrideProps<ChipTypeMap<AdditionalProps, RootComponent>, RootComponent> & {
    component?: ElementType;
  };

const seed = random();

const classNamePrefix = `chip-${seed}-` as const;

const classNameRecord = {
  root: `${classNamePrefix}root`,
  startIconSpan: `${classNamePrefix}start-icon-span`,
  childrenSpan: `${classNamePrefix}children-span`,
} satisfies Record<string, `${typeof classNamePrefix}${string}`>;

const styles = defineStyles({
  chip: css([
    cssOneLine,
    {
      display: "inline-flex",
      alignItems: "center",
      marginRight: 0,
      paddingInline: 12,
      paddingBlock: 4,
      gap: 4,
      borderRadius: 26,
      fontSize: 12,
      color: theme.colors.neutral009,
      backgroundColor: theme.colors.white,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.colors.neutral003,
      cursor: "pointer",
      /**
       * Remove the default `<a />` styles.
       */
      textDecoration: "none",
      transition: ["background-color", "border-color", "color"]
        .map((property) => `${property} 0.3s ease-in-out`)
        .join(", "),
      [`&:has(.${classNameRecord.startIconSpan})`]: {
        paddingLeft: 8,
      },
      [`&:hover, &:active, &:focus`]: {
        backgroundColor: theme.colors.neutral001,
      },
      [`&:focus-visible`]: {
        outlineOffset: 2,
        outlineColor: theme.colors.primaryOutline,
      },
      [`&:disabled`]: {
        borderColor: theme.colors.neutral003,
        backgroundColor: theme.colors.neutral001,
        color: theme.colors.neutral005,
        cursor: "not-allowed",
      },
      [`&:not(:disabled) .${classNameRecord.startIconSpan}`]: {
        color: theme.colors.blue006,
      },
    },
  ]),
  checked: css({
    "&:not(:disabled)": {
      borderColor: theme.colors.blue006,
      backgroundColor: theme.colors.blue002,
      "&:hover": {
        borderColor: theme.colors.blue005,
      },
      "&:active": {
        borderColor: theme.colors.blue007,
      },
      "&:focus": {
        borderColor: theme.colors.blue003,
      },
    },
  }),
  startIconSpan: css({
    "&, &.anticon": {
      fontSize: "inherit",
    },
  }),
});

/**
 * Styled chip component. It can be used as a button, checkbox, or radio button.
 *
 * Spec: [Figma](https://www.figma.com/design/o61i5symd25csJUyfXETzv/Design-System-Motif?m=dev&node-id=3960-6099)
 */
const Chip: OverridableComponent<ChipTypeMap> = forwardRef(function Chip(
  { component: Component = defaultComponent, ...props }: ChipProps,
  ref: ForwardedRef<ElementRef<typeof Component>>,
) {
  const [localChecked, setLocalChecked] = useState(props.checked ?? false);
  const mergeFormDisabled = useMergeFormDisabled();
  const mergedDisabled = mergeFormDisabled(props.disabled);
  const mergedChecked = useMemo(
    () => ("checked" in props ? props.checked : localChecked),
    [localChecked, props],
  );
  const defaultProps = useMemo<Partial<ChipProps>>(
    () => ({
      checkedIcon: <MotifIcon un-i-motif="check" />,
    }),
    [],
  );
  const mergedProps = useMemo<ChipProps>(
    () => merge({}, defaultProps, props),
    [defaultProps, props],
  );
  const {
    startIcon,
    checkedIcon,
    startIconSpanProps,
    childrenSpanProps,
    ...restProps
  } = mergedProps;
  /**
   * We provide this just because there is a vanilla `onChange` event on the
   * button element.
   */
  const mergedOnChange = useHandler<
    ComponentProps<DefaultComponent>["onChange"]
  >(function onChange(...args) {
    const [e] = args;
    if (mergedDisabled) return;
    const nextChecked = !mergedChecked;
    setLocalChecked(nextChecked);
    props.onChange?.(e, nextChecked);
  });
  const mergedOnClick = useHandler<ComponentProps<DefaultComponent>["onClick"]>(
    function mergedOnClick(...args) {
      const [e] = args;
      if (mergedDisabled) {
        e.preventDefault();
        return;
      }
      props.onClick?.(...args);
      if (e.defaultPrevented) {
        return;
      }
      const nextChecked = !mergedChecked;
      setLocalChecked(nextChecked);
      props.onChange?.(e, !mergedChecked);
    },
  );
  const mergedStartIcon = useMemo(
    () =>
      mergedChecked
        ? "checkedIcon" in mergedProps
          ? checkedIcon
          : startIcon
        : startIcon,
    [mergedChecked, mergedProps, checkedIcon, startIcon],
  );
  const rootCss = useMemo(
    () => css([styles.chip, !mergedChecked ? null : styles.checked]),
    [mergedChecked],
  );
  const rootClassName = useMemo(
    () => classNames(classNameRecord.root, mergedProps.className),
    [mergedProps.className],
  );
  const startIconSpanCss = useMemo(
    () => css([styles.startIconSpan, startIconSpanProps?.css]),
    [startIconSpanProps?.css],
  );
  const startIconSpanClassName = useMemo(
    () =>
      classNames(classNameRecord.startIconSpan, startIconSpanProps?.className),
    [startIconSpanProps?.className],
  );
  const childrenSpanCss = useMemo(
    () => css([styles.startIconSpan, childrenSpanProps?.css]),
    [childrenSpanProps?.css],
  );
  const childrenSpanClassName = useMemo(
    () =>
      classNames(classNameRecord.childrenSpan, childrenSpanProps?.className),
    [childrenSpanProps?.className],
  );
  return (
    <Component
      css={rootCss}
      ref={ref}
      {...restProps}
      className={rootClassName}
      onChange={mergedOnChange}
      onClick={mergedOnClick}
      disabled={mergedDisabled}
    >
      {mergedStartIcon && (
        <span
          {...childrenSpanProps}
          css={startIconSpanCss}
          className={startIconSpanClassName}
        >
          {mergedStartIcon}
        </span>
      )}
      <span
        {...childrenSpanProps}
        css={childrenSpanCss}
        className={childrenSpanClassName}
      >
        {props.children}
      </span>
    </Component>
  );
}) as OverridableComponent<ChipTypeMap>;

assignDisplayName(Chip, "Chip");

const api = Object.assign(Chip, {
  defaultComponent,
  classNameRecord,
});

export { api as Chip };

export type { ChipOwnProps, ChipProps, ChipTypeMap };
