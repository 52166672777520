import styled from "@emotion/styled";
import type { ComponentPropsWithRef } from "react";

type FlexProps = ComponentPropsWithRef<"div">;

const Flex = styled.div`
  display: flex;
`;

export { Flex };

export type { FlexProps };
