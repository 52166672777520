import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { makeApi, makeParameters } from "@zodios/core";
import { z } from "zod";

import {
  ChannelSchema,
  ChannelTypeSchema,
  DashboardDayHourCountSchema,
  DashboardIntervalSchema,
  DashboardMetricsSchema,
  DashboardTotalCountSchema,
  TeamSchema,
} from "../models";

const DashboardDetailSchema = z.object({
  interval: DashboardIntervalSchema,
  countMetrics: DashboardMetricsSchema.array(),
});

const DashboardQueryParameters = makeParameters([
  {
    type: "Query",
    name: "startTime",
    schema: Ymd.YmdToYmdStringSchema,
  },
  {
    type: "Query",
    name: "endTime",
    schema: Ymd.YmdToYmdStringSchema,
  },
  {
    type: "Query",
    name: "channelId",
    schema: ChannelSchema.shape.id.array().optional(),
  },
  {
    type: "Query",
    name: "channelType",
    schema: ChannelTypeSchema.array().optional(),
  },
  {
    type: "Query",
    name: "teamId",
    schema: TeamSchema.shape.id.array().optional(),
  },
  {
    type: "Query",
    name: "interval",
    schema: DashboardIntervalSchema.optional(),
  },
]);

const api = makeApi([
  {
    alias: "assignedConversationDetail",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/assigned-conversation/detail",
    parameters: DashboardQueryParameters,
    response: DashboardDetailSchema,
  },
  {
    alias: "assignedConversationTotalCount",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/assigned-conversation/total-count",
    parameters: DashboardQueryParameters,
    response: DashboardTotalCountSchema,
  },
  {
    alias: "exportConversationCsv",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/export-csv",
    parameters: DashboardQueryParameters,
    response: z.string(),
    responseType: "blob",
  },
  {
    alias: "handledConversationDetail",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/handled-conversation/detail",
    parameters: DashboardQueryParameters,
    response: DashboardDetailSchema,
  },
  {
    alias: "handledConversationTotalCount",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/handled-conversation/total-count",
    parameters: DashboardQueryParameters,
    response: DashboardTotalCountSchema,
  },
  {
    alias: "exportConversationHeatMapCsv",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/new-conversation/heat-map/export-csv",
    parameters: DashboardQueryParameters,
    response: z.string(),
    responseType: "blob",
  },
  {
    alias: "exportAssignedConversationHeatMapCsv",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/assigned-conversation/heat-map/export-csv",
    parameters: DashboardQueryParameters,
    response: z.string(),
    responseType: "blob",
  },
  {
    alias: "messageSentTotalCount",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/message-sent/total-count",
    parameters: DashboardQueryParameters,
    response: DashboardTotalCountSchema,
  },
  {
    alias: "newConversationDetail",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/new-conversation/detail",
    parameters: DashboardQueryParameters,
    response: DashboardDetailSchema,
  },
  {
    alias: "newConversationHeatMap",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/new-conversation/heat-map",
    parameters: DashboardQueryParameters,
    response: DashboardDayHourCountSchema.array(),
  },
  {
    alias: "assignedConversationHeatMap",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/assigned-conversation/heat-map",
    parameters: DashboardQueryParameters,
    response: DashboardDayHourCountSchema.array(),
  },
  {
    alias: "newConversationTotalCount",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/new-conversation/total-count",
    parameters: DashboardQueryParameters,
    response: DashboardTotalCountSchema,
  },
  {
    alias: "resolvedConversationDetail",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/resolved-conversation/detail",
    parameters: DashboardQueryParameters,
    response: DashboardDetailSchema,
  },
  {
    alias: "resolvedConversationTotalCount",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/resolved-conversation/total-count",
    parameters: DashboardQueryParameters,
    response: DashboardTotalCountSchema,
  },
  {
    alias: "totalConversationTotalCount",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/total-conversation/total-count",
    parameters: DashboardQueryParameters,
    response: DashboardTotalCountSchema,
  },
  {
    alias: "unresolvedConversationTotalCount",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/conversation/unresolved-conversation/total-count",
    parameters: DashboardQueryParameters,
    response: DashboardTotalCountSchema,
  },
]);

export { api };
