import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import type { ReactNode } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";

function useTeamQueries({
  orgId,
  teamId,
}: {
  orgId: CantataTypes["Org"]["id"];
  teamId: CantataTypes["Team"]["id"];
}) {
  const teamQuery = cantata.team.useGetById(
    {
      params: {
        orgId,
        teamId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  return {
    team: teamQuery,
  };
}

const TeamQueriesContext =
  createQueriesContext<ReturnType<typeof useTeamQueries>>();

function TeamQueriesProvider({
  teamId,
  children,
}: {
  teamId: CantataTypes["Team"]["id"];
  children: ReactNode;
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const queries = useTeamQueries({
    orgId,
    teamId,
  });
  return (
    <TeamQueriesContext.Provider queries={queries}>
      {children}
    </TeamQueriesContext.Provider>
  );
}

function useTeam() {
  return TeamQueriesContext.useData().team;
}

const teamQueriesContext = {
  Provider: TeamQueriesProvider,
  useTeam,
};

export { teamQueriesContext };
