/* eslint-disable ts/ban-types -- inherit from MUI */
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import classNames from "classnames";
import type { ElementType, FC } from "react";

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

interface MotifIconOwnProps {
  /**
   * Motif Icons attribute value.
   *
   * Look up the motif icons attribute value in the [Motif Icons
   * documentation](https://chatbotgang.github.io/motif-icons/).
   */
  "un-i-motif": ComponentProps<DefaultComponent>["un-i-motif"] & string;

  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface MotifIconTypeMap<
  AdditionalProps = {},
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & MotifIconOwnProps;
  defaultComponent: RootComponent;
}

type MotifIconProps<
  RootComponent extends ElementType = MotifIconTypeMap["defaultComponent"],
  AdditionalProps = {},
> = OverrideProps<
  MotifIconTypeMap<AdditionalProps, RootComponent>,
  RootComponent
> & {
  component?: ElementType;
};

const basicClassNames: Array<string> = ["anticon"];

/**
 * An Motif icon component compatible with Ant Design.
 *
 * Please check the [Motif Icons
 * documentation](https://chatbotgang.github.io/motif-icons/) for the available
 * icons.
 */
const MotifIcon: OverridableComponent<MotifIconTypeMap> = forwardRef(
  function MotifIcon<
    TComponent extends
      keyof JSX.IntrinsicElements = MotifIconTypeMap["defaultComponent"],
  >(
    {
      component: Component = "div" as TComponent,
      "un-i-motif": motifIconAttr,
      ...props
    }: {
      component?: TComponent;
    } & MotifIconOwnProps,
    ref: ComponentProps<TComponent>["ref"],
  ) {
    const additionalClassName: string | undefined =
      !("className" in props) || typeof props.className !== "string"
        ? undefined
        : props.className;
    const className = classNames(...basicClassNames, additionalClassName);
    return (
      <Component
        {...props}
        {...({
          children: <div un-i-motif={motifIconAttr} />,
          className,
          ref,
        } as any)}
      />
    );
  },
) as FC<MotifIconProps> as OverridableComponent<MotifIconTypeMap>;

const MotifIconUtils = {
  defaultComponent,
};

export { MotifIcon, MotifIconUtils };
export type { MotifIconOwnProps, MotifIconProps, MotifIconTypeMap };
