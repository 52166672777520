import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { theme } from "@zeffiroso/theme";
import type {
  CheckboxProps as AntCheckboxProps,
  CheckboxRef as AntCheckboxRef,
} from "antd";
import { Checkbox as AntCheckbox } from "antd";

const styles = {
  root: {
    "&:not(:has(input[disabled],input:active,input:focus),:hover) .ant-checkbox-checked .ant-checkbox-inner":
      {
        borderColor: theme.colors.blue006,
        backgroundColor: theme.colors.blue006,
      },
  },
};

type CheckboxRef = AntCheckboxRef;
type CheckboxProps = AntCheckboxProps;

const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(
  function Checkbox(props, ref) {
    return <AntCheckbox {...props} ref={ref} css={styles.root} />;
  },
);

export { Checkbox };
export type { CheckboxProps, CheckboxRef };
