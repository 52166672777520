import { css } from "@emotion/react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Flex } from "@/components/Box";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { teamQueriesContext } from "@/queriesContext/teamQueriesContext";
import { TeamUpdateForm } from "@/routes/Settings/Teams/pages/TeamForm/TeamUpdateForm";
import { TeamUsers } from "@/routes/Settings/Teams/pages/TeamForm/TeamUsers";
import {
  PageInfoProvider,
  usePageInfo,
} from "@/routes/Settings/Teams/pages/TeamForm/usePageInfo";

const TeamEdit: FC = () => {
  return (
    <Flex
      css={css`
        width: 100%;
        flex-direction: column;
        gap: 16px;
      `}
    >
      <TeamUpdateForm />
      <TeamUsers />
    </Flex>
  );
};

const Title: FC = () => {
  const { t } = useTranslation();
  const team = teamQueriesContext.useTeam();
  const pageInfo = usePageInfo();

  return pageInfo.isView
    ? t("team.editPage.title.view", { teamName: team.name })
    : t("team.editPage.title.update", { teamName: team.name });
};

const TeamEditWithContext: FC = () => {
  const { t } = useTranslation();
  const pageInfo = usePageInfo();
  const teamId = pageInfo.teamId || Number.NaN;
  return (
    <SideMenuLayout.Layout>
      <ErrorBoundary.Alert>
        <teamQueriesContext.Provider teamId={teamId}>
          <MainLayout.Header
            breadcrumbItems={[
              {
                title: t("menu.team"),
                to: "/settings/teams",
              },
              {
                title: <Title />,
              },
            ]}
          >
            <Title />
          </MainLayout.Header>
          <MainLayout.Body>
            <MainLayout.Content>
              <MainLayout.Section>
                <TeamEdit />
              </MainLayout.Section>
            </MainLayout.Content>
          </MainLayout.Body>
        </teamQueriesContext.Provider>
      </ErrorBoundary.Alert>
    </SideMenuLayout.Layout>
  );
};

const TeamEditWithPageInfo: FC = () => {
  return (
    <PageInfoProvider>
      <TeamEditWithContext />
    </PageInfoProvider>
  );
};

export { TeamEditWithPageInfo as TeamEdit };
