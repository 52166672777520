import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { Virtualizer } from "@tanstack/react-virtual";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { noop } from "lodash-es";
import { type FC, useEffect, useMemo, useState } from "react";
import { createWithEqualityFn } from "zustand/traditional";

interface PortalSwitchHook {
  element: HTMLElement;
  before: () => unknown;
  after: (prev: any) => void;
}

const portalSwitchHooks: Array<PortalSwitchHook> = [];

type UsePortalSwitchOptions<TArgs> =
  | {
      element: HTMLElement;
      before: () => TArgs;
      after?: (prev: TArgs) => void;
    }
  | {
      element: HTMLElement;
      after: () => void;
    };

/**
 * Do something before and after the portal element is switched.
 */
function usePortalSwitchHook<TArgs>(options: UsePortalSwitchOptions<TArgs>) {
  const before = useHandler("before" in options ? options.before : noop);
  const after = useHandler("after" in options ? options.after : noop);
  const hook = useMemo(
    () => ({ element: options.element, before, after }),
    [options.element, before, after],
  );
  useEffect(() => {
    portalSwitchHooks.push(hook);
    return function cleanup() {
      while (portalSwitchHooks.includes(hook))
        portalSwitchHooks.splice(portalSwitchHooks.indexOf(hook), 1);
    };
  }, [hook]);
}

type UsePortalSwitchHookProps<TArgs> = UsePortalSwitchOptions<TArgs>;

/**
 * Component version of `usePortalSwitchHook`.
 */
function UsePortalSwitchHook<TArgs>(options: UsePortalSwitchHookProps<TArgs>) {
  usePortalSwitchHook(options);
  return null;
}

/**
 * Custom React hook to create a stable portal container.
 * This hook allows you to create a portal container that remains persistent
 * even when the original container element is replaced, preventing unnecessary
 * re-mounting of the portal content Virtual DOM.
 *
 * ```tsx
 * const innerEl = document.createElement("div");
 * const persistentPortal = createPersistentPortal(innerEl);
 * function MyComponent() {
 *   const [expanded, setExpanded] = useState(false);
 *   return (
 *     <>
 *       {expanded ? (
 *         <Expanded ref={persistentPortal.setOuterEl} />
 *       ) : (
 *         <Collapsed ref={persistentPortal.setOuterEl} />
 *       )}
 *       {createPortal(<Content />, innerEl)}
 *     </>
 *   );
 * }
 * ```
 */
function createPersistentPortal<OuterEl extends HTMLElement>(
  innerEl: HTMLElement,
) {
  type StoreValue = {
    outerEl: OuterEl | null;
  };
  const useStore = createWithEqualityFn<StoreValue>()(
    () => ({ outerEl: null }),
    shallow,
  );
  function setOuterEl(outerEl: StoreValue["outerEl"]) {
    useStore.setState({ outerEl });
  }
  /**
   * Cache the result of `before` hook when the outerEl is not set or unset.
   */
  const hookToArgsWeakMap = new WeakMap<PortalSwitchHook, unknown>();
  useStore.subscribe((state, prevState) => {
    if (state.outerEl === prevState.outerEl) return;
    const matchedHooks = portalSwitchHooks.filter((hook) =>
      innerEl.contains(hook.element),
    );
    (() => {
      if (!state.outerEl) {
        const hookArgs = matchedHooks.map((hook) => hook.before());
        matchedHooks.forEach((hook, index) =>
          hookToArgsWeakMap.set(hook, hookArgs[index]),
        );
        prevState.outerEl?.removeChild(innerEl);
        return;
      }
      if ([...state.outerEl.children].includes(innerEl)) return;
      state.outerEl.appendChild(innerEl);
      const task = matchedHooks.flatMap((hook) => {
        if (!hookToArgsWeakMap.has(hook)) return [];
        const hookArgs = hookToArgsWeakMap.get(hook);
        return [
          {
            hook,
            hookArgs,
          },
        ];
      });
      task.forEach(({ hook, hookArgs }) => hook.after(hookArgs));
    })();
  });
  return {
    setOuterEl,
  };
}

type UseFixPortalVirtualizerScrollOptions = {
  scrollElement: HTMLElement;
  virtualizer: Virtualizer<Element, Element>;
};

/**
 * When the innerEl is appended to another element, all scrollable elements will
 * scroll to the top. Since no events are triggered, the virtualizer scrolls to
 * the top without re-rendering the content. To address this issue, we need to
 * manually scroll the virtualizer back to the previous scroll position after
 * the innerEl is appended to the new outerEl.
 *
 * - Asana: [Intersection Observer with
 *   Portal](https://app.asana.com/0/1202106311174078/1207859080999040/f)
 * - Slack:
 *   [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1721641017239759)
 */
function useFixPortalVirtualizerScroll({
  scrollElement,
  virtualizer,
}: UseFixPortalVirtualizerScrollOptions) {
  usePortalSwitchHook({
    element: scrollElement,
    before() {
      return virtualizer.scrollOffset;
    },
    after(scrollOffset) {
      virtualizer.scrollToOffset(scrollOffset);
    },
  });
}

type FixPortalVirtualizerScrollProps = UseFixPortalVirtualizerScrollOptions;
/**
 * Component version of `useFixPortalVirtualizerScroll`.
 */
const FixPortalVirtualizerScroll: FC<FixPortalVirtualizerScrollProps> = ({
  scrollElement: element,
  virtualizer,
}) => {
  useFixPortalVirtualizerScroll({ scrollElement: element, virtualizer });
  return null;
};

/**
 * Setup a portal container with the given tag name.
 */
function setupPortal<TTagName extends keyof JSX.IntrinsicElements & string>(
  tagName: TTagName,
) {
  const innerEl = document.createElement(tagName);
  const portal = createPersistentPortal(innerEl);
  return {
    setOuterEl: portal.setOuterEl,
    innerEl,
  };
}

/**
 * Create a in-component portal utility hook.
 */
function createUsePortal<TTagName extends keyof JSX.IntrinsicElements & string>(
  tagName: TTagName,
) {
  return function usePortal() {
    const [portal] = useState(() => setupPortal(tagName));
    return portal;
  };
}

/**
 * A shortcut to use portal with a `div` inner element.
 *
 * ```tsx
 * const portal = usePortal();
 * return (
 *   <div>
 *     <div ref={portal.setOuterEl} />
 *     {createPortal(<div>Portal Content</div>, portal.innerEl)}
 *   </div>
 * );
 * ```
 */
const usePortal = createUsePortal("div");

export {
  createUsePortal,
  FixPortalVirtualizerScroll,
  useFixPortalVirtualizerScroll,
  usePortal,
  UsePortalSwitchHook,
  usePortalSwitchHook,
};
