import type { CantataTypes } from "@/cantata/types";

export const useMemberName = ({
  displayName,
  originalName,
}: Pick<
  CantataTypes["GroupMember"],
  "displayName" | "originalName"
>): string => {
  return displayName || originalName;
};
