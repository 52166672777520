import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { ComponentProps, ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { FlexProps } from "@/components/Box";
import { Flex } from "@/components/Box";
import type { ButtonProps } from "@/components/Button";
import { Button } from "@/components/Button";
import { emotionMedia } from "@/shared/utils/style/emotionMedia";

const ComponentNamespace = "FormLayout";

const gap = "0.5em";
const widthMap = {
  narrow: "366px",
  wide: "504px",
  full: "100%",
} as const;

const StyledTitle = styled.h1`
  padding-bottom: 0;
  margin-bottom: 0;
  color: ${theme.colors.neutral010};
  font-size: 1.5rem;
  font-weight: 500;
  ${emotionMedia(
    String.raw,
    "<=mobile",
    (css) => css`
      font-size: 20px;
    `,
  )}
`;

const StyledLabel = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  gap: ${gap};
  ${emotionMedia(
    String.raw,
    "<=mobile",
    (css) => css`
      flex-direction: column;
    `,
  )}
`;

const StyledLabelSpan = styled.span`
  width: 120px;
  min-width: 120px;
  max-width: 100%;
  color: ${theme.colors.neutral010};
  font-size: 14px;
  font-weight: 500;
  ${emotionMedia(
    String.raw,
    "<=mobile",
    (css) => css`
      width: 100%;
      min-width: 100%;
    `,
  )}
`;

/**
 * @deprecated Please use `MainLayout` instead.
 *
 * @example
 *
 * ```tsx
 * <FormLayout.Container
 *   title="Edit Settings"
 *   submit={<FormLayout.Submit>Update</FormLayout.Submit>}
 * >
 *   <FormLayout.RowFieldItem
 *     title="Name"
 *     content={<Input />}
 *   />
 *   <FormLayout.RowField>
 *     title="Name"
 *     content={
 *       <>
 *         <FormLayout.Item width="wide">
 *           <Alert />
 *         </FormLayout.Item>
 *         <FormLayout.Item>
 *           <Input />
 *         </FormLayout.Item>
 *         <FormLayout.Item width="full">
 *            <Table />
 *         </FormLayout.Item>
 *       </>
 *     }
 *  />
 * </FormLayout.Container>
 * ```
 */
const FormLayout = (() => {
  /**
   * The container of the form.
   *
   * Always wrap the form with this container.
   */
  const Container = memo<{
    title?: ReactNode;
    submit?: ReactNode;
    children: ReactNode;
  }>(function Container({ title, submit, children }) {
    return (
      <Flex
        css={css`
          flex-direction: column;
          align-items: stretch;
          justify-content: flex-start;
          gap: 1em;
        `}
      >
        {!title ? null : (
          <Row>
            <StyledTitle>{title}</StyledTitle>
          </Row>
        )}
        {!submit ? null : (
          <Row
            css={css`
              justify-content: flex-end !important;
            `}
          >
            {submit}
          </Row>
        )}
        <Flex
          css={css`
            position: relative;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 0;
            ${emotionMedia(
              css,
              "<=mobile",
              (css) => css`
                gap: 0.25em;
              `,
            )}
          `}
        >
          {children}
        </Flex>
      </Flex>
    );
  });
  assignDisplayName(Container, `${ComponentNamespace}.Container`);
  /**
   * The default submit button.
   */
  const SubmitButton = memo(function SubmitButton(props: ButtonProps) {
    const { t } = useTranslation();
    return (
      <Button type="primary" htmlType="submit" {...props}>
        {props?.children ?? t("common.save")}
      </Button>
    );
  });
  /**
   * Wrap this for each row.
   */
  const Row = styled(Flex)`
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
  `;
  assignDisplayName(Row, `${ComponentNamespace}.Row`);
  const Field = memo(function Field({
    title,
    content,
  }: {
    title: ReactNode;
    content: ReactNode;
  }) {
    return (
      <StyledLabel>
        <StyledLabelSpan>{title}</StyledLabelSpan>
        <Flex
          css={css`
            overflow: hidden;
            flex: 1;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            gap: ${gap};

            ${emotionMedia(
              css,
              "<=mobile",
              (css) => css`
                flex: unset;
              `,
            )}
          `}
        >
          {content}
        </Flex>
      </StyledLabel>
    );
  });
  assignDisplayName(Field, `${ComponentNamespace}.Field`);
  /**
   * Item in the field with specific width.
   */
  const Item = memo(function Item({
    width,
    ...props
  }: FlexProps & {
    width?: keyof typeof widthMap;
  }) {
    const resolvedWidth: keyof typeof widthMap = width ?? "narrow";
    const mergedCss = useMemo(
      () => css`
        display: flex;
        width: ${widthMap[resolvedWidth]};
        max-width: 100%;
        flex-direction: column;
        align-items: stretch;
        ${emotionMedia(
          css,
          "<=mobile",
          (css) => css`
            width: 100%;
          `,
        )}
      `,
      [resolvedWidth],
    );
    return <Flex {...props} css={mergedCss} />;
  });
  assignDisplayName(Item, `${ComponentNamespace}.Item`);
  /**
   * Combo of `Row`, `Field`
   */
  const RowField = memo(function RowField(props: ComponentProps<typeof Field>) {
    return (
      <Row>
        <Field {...props} />
      </Row>
    );
  });
  assignDisplayName(RowField, `${ComponentNamespace}.RowField`);
  /**
   * Combo of `Row`, `Field`, `Item`
   */
  const RowFieldItem = memo(function RowFieldItem({
    title,
    content,
    width,
  }: {
    title?: ReactNode;
    content?: ReactNode;
    width?: keyof typeof widthMap;
  }) {
    return (
      <Row>
        <Field title={title} content={<Item width={width}>{content}</Item>} />
      </Row>
    );
  });
  assignDisplayName(RowFieldItem, `${ComponentNamespace}.RowFieldItem`);
  return {
    Container,
    SubmitButton,
    Row,
    Field,
    Item,
    RowField,
    RowFieldItem,
  };
})();

export { FormLayout };
