import {
  AlignLeftOutlined,
  PaperClipOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { FormListFieldData, FormListOperation } from "antd";
import { DragDropContext, Draggable } from "react-beautiful-dnd";

import type { CantataTypes } from "@/cantata/types";
import { StrictModeDroppable } from "@/components/dnd/StrictModeDroppable";
import { FormItem } from "@/components/Form";
import { NSResize } from "@/shared/icons/common/NSResize";
import { RoundPictureOutlined } from "@/shared/icons/common/RoundPictureOutlined";

const Wrapper = styled.div`
  display: flex;
  width: 64px;
  height: 32px;
  flex-direction: row;
  align-items: center;
  border-radius: ${theme.shape.borderRadius};
  cursor: move;
  gap: 4px;
`;

const IconWrapper = styled.div<{ $size?: string }>`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.shape.borderRadius};
  background: ${theme.colors.white};
  color: ${theme.colors.neutral005};
  font-size: ${({ $size = "21px" }) => $size};
`;

const IndexSpan = styled.span`
  display: inline-block;
  width: 8px;
  color: ${theme.colors.neutral005};
  font-size: 12px;
  line-height: 16px;
`;

const ResizeIconWrapper = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.neutral005};
`;

const MessageSorter = styled.div`
  --border-radius: ${theme.shape.borderRadius};

  position: absolute;
  top: 90px;
  right: -72px;
  display: flex;
  flex-direction: column;
  padding: 8px 4px;
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
  background: ${theme.colors.neutral001};
  gap: 8px;
`;

const MessageIcon = ({
  value,
}: {
  value?: CantataTypes["QuickTemplateDetail"]["messages"][number];
}) => {
  switch (value?.type) {
    case "text":
      return (
        <IconWrapper>
          <AlignLeftOutlined />
        </IconWrapper>
      );
    case "image":
      return (
        <IconWrapper $size="24px">
          <RoundPictureOutlined />
        </IconWrapper>
      );
    case "video":
      return (
        <IconWrapper>
          <PlayCircleOutlined />
        </IconWrapper>
      );
    case "file":
      return (
        <IconWrapper>
          <PaperClipOutlined />
        </IconWrapper>
      );
    default:
      return null;
  }
};

export const MessageSortFactory = (
  fields: FormListFieldData[],
  { move }: FormListOperation,
): JSX.Element => {
  return (
    <DragDropContext
      onDragEnd={(e) => {
        // destination will be `null` when user drop draggable outside the droppable area
        if (e.destination && e.source.index !== e.destination.index)
          move(e.source.index, e.destination.index);
      }}
    >
      <StrictModeDroppable droppableId="message-sorter">
        {(droppableProvide) => (
          <MessageSorter
            ref={droppableProvide.innerRef}
            {...droppableProvide.droppableProps}
          >
            {fields.map(({ key, ...field }, index) => (
              <FormItem shouldUpdate noStyle key={key}>
                {({ getFieldValue }) => (
                  <Draggable
                    draggableId={getFieldValue([
                      "messages",
                      field.name,
                      "draggableId",
                    ])}
                    index={index}
                  >
                    {(draggableProvided) => (
                      <Wrapper
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <IndexSpan>{index + 1}</IndexSpan>
                        <FormItem {...field} noStyle>
                          <MessageIcon />
                        </FormItem>
                        <ResizeIconWrapper>
                          <NSResize />
                        </ResizeIconWrapper>
                      </Wrapper>
                    )}
                  </Draggable>
                )}
              </FormItem>
            ))}
            {droppableProvide.placeholder}
          </MessageSorter>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};
