import {
  FIREBASE_SHARED_API_KEY,
  FIREBASE_SHARED_APP_ID,
  FIREBASE_SHARED_AUTH_DOMAIN,
  FIREBASE_SHARED_PROJECT_ID,
} from "@zeffiroso/env";
import { initializeApp } from "firebase/app";

const sharedFirebaseApp = initializeApp(
  {
    apiKey: FIREBASE_SHARED_API_KEY,
    appId: FIREBASE_SHARED_APP_ID,
    authDomain: FIREBASE_SHARED_AUTH_DOMAIN,
    projectId: FIREBASE_SHARED_PROJECT_ID,
  },
  "shared",
);

export { sharedFirebaseApp };
