import { makeApi } from "@zodios/core";
import { z } from "zod";

import { ChannelDetailSchema, ChannelSchema } from "../models";

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/channels",
    response: z.object({
      channels: z.array(ChannelSchema),
    }),
  },
  {
    alias: "getById",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/channels/:channelId",
    response: ChannelDetailSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/202311-CAAC-FB-integrate-170397cbc5004166b32bc851890d15ca?pvs=4#a15c1540f827454aa37ebb7952af068e)
     */
    alias: "create",
    method: "post",
    path: "api/v1/orgs/:orgId/organization/channels",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ChannelDetailSchema.pick({
          name: true,
          externalChannelId: true,
        }).extend({
          type: z.enum(["fb", "ig"]),
          accessToken: z.string(),
          externalChannelSecret: z.literal("no secret"),
        }),
      },
    ],
    response: ChannelDetailSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/202311-CAAC-FB-integrate-170397cbc5004166b32bc851890d15ca?pvs=4#74923310c1ea48ab9c1239401c750296)
     */
    alias: "updateStatus",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/channels/:channelId/status",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.union([
          z.object({
            status: ChannelSchema.shape.status.extract(["connected"]),
            accessToken: z.string(),
          }),
          z.object({
            status: ChannelSchema.shape.status.extract(["disconnected"]),
          }),
        ]),
      },
    ],
    response: ChannelDetailSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/202311-CAAC-Agent-Recognition-18ead050942047519a0cc0f407830b3a?pvs=4#5492d18a5bcf4821b0d7c3ecdf81d8c8)
     */
    alias: "patchSettings",
    method: "put",
    path: "api/v1/orgs/:orgId/chat/settings",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          userRecognition: z.array(
            z.object({
              channelId: ChannelSchema.shape.id,
              enable: z.boolean(),
            }),
          ),
        }),
      },
    ],
    response: z.void(),
  },
  /**
   * Slack: [DM](https://chatbotgang.slack.com/archives/C05MTBQATM1/p1707118216901139)
   */
  {
    alias: "update",
    method: "patch",
    path: "api/v1/orgs/:orgId/organization/channels/:channelId",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ChannelSchema.pick({
          name: true,
        }).partial(),
      },
    ],
    // Don't care
    response: z.unknown(),
  },
]);

export { api };
