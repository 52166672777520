import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const DeleteOutlined = generateIcon(function DeleteOutlined() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 96 96">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <path
            d="M65.145,88 L30.855,88 C25.4175,88 21,83.9774504 21,79.0277704 L21,37.1573653 C21,35.5056235 22.3431458,34.1666221 24,34.1666221 C25.6568542,34.1666221 27,35.5056235 27,37.1573653 L27,79.0277704 C27,80.6502485 28.77,82.0185136 30.855,82.0185136 L65.145,82.0185136 C67.2375,82.0185136 69,80.6502485 69,79.0277704 L69,37.1573653 C69,35.5056235 70.3431458,34.1666221 72,34.1666221 C73.6568542,34.1666221 75,35.5056235 75,37.1573653 L75,79.0277704 C75,83.9774504 70.575,88 65.145,88 Z M81,28.5589786 L15,28.5589786 C13.3431458,28.5589786 12,27.2199772 12,25.5682354 C12,23.9164935 13.3431458,22.5774922 15,22.5774922 L81,22.5774922 C82.6568542,22.5774922 84,23.9164935 84,25.5682354 C84,27.2199772 82.6568542,28.5589786 81,28.5589786 Z M40.5,76.0370271 C38.8431458,76.0370271 37.5,74.6980258 37.5,73.0462839 L37.5,43.1388518 C37.5,41.4871099 38.8431458,40.1481085 40.5,40.1481085 C42.1568542,40.1481085 43.5,41.4871099 43.5,43.1388518 L43.5,73.0462839 C43.5,74.6980258 42.1568542,76.0370271 40.5,76.0370271 Z M55.5,76.0370271 C53.8431458,76.0370271 52.5,74.6980258 52.5,73.0462839 L52.5,43.1388518 C52.5,41.4871099 53.8431458,40.1481085 55.5,40.1481085 C57.1568542,40.1481085 58.5,41.4871099 58.5,43.1388518 L58.5,73.0462839 C58.5,74.6980258 57.1568542,76.0370271 55.5,76.0370271 Z M36,25.9420783 C34.3431458,25.9420783 33,24.6030769 33,22.9513351 C33.0011954,16.8298496 36.7447822,11.3272583 42.4493297,9.06203521 C48.1538772,6.79681211 54.6672573,8.22645662 58.89,12.6706553 C59.6268603,13.4466458 59.8924799,14.555588 59.5868025,15.579754 C59.281125,16.60392 58.4505902,17.3877147 57.4080524,17.63589 C56.3655147,17.8840654 55.2693603,17.5589178 54.5325,16.7829272 C52.8392555,14.9856414 50.4733094,13.970151 48,13.9791054 C43.0294373,13.9791054 39,17.9961094 39,22.9513351 C39,24.6030769 37.6568542,25.9420783 36,25.9420783 Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
});
