import type { FC } from "react";

import { memberFieldRules } from "@/resources/member/memberValidator";
import { TextField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/TextField";

const DisplayMobileField: FC = () => {
  return (
    <TextField
      fieldName="displayMobile"
      rules={memberFieldRules.displayMobile}
    />
  );
};

export { DisplayMobileField };
