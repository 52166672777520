import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { extname } from "pathe";
import { type ElementRef, useMemo } from "react";

import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  TruncatedText: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    lineHeight: "normal",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }),
  basename: css({
    textOverflow: "ellipsis",
    overflow: "hidden",
  }),
});

namespace TruncatedText {
  export type Ref = ElementRef<"div">;
  export interface OwnProps {
    /**
     * The length of the trail text. If the value is "extname", the trail text
     * will be the extension of the file name.
     */
    trailLength?: number | "extname";
    text: string;
  }
  export type Props = Overwrite<
    Omit<ComponentProps<"div">, "children">,
    OwnProps
  >;
}

const TruncatedTextInternal = forwardRef<
  TruncatedText.Ref,
  TruncatedText.Props
>(function TruncatedTextInternal(
  { trailLength = "extname", text, ...props },
  ref,
) {
  const trail = useMemo(() => {
    if (trailLength === "extname") {
      const ext = extname(text);
      const expectedTrailLength = ext.length && ext.length - 1;
      const expectedBaseNameLength = text.length - expectedTrailLength;
      // If the expected trail length is greater than the base name length,
      // return an empty string
      if (expectedTrailLength >= expectedBaseNameLength) return "";
      return text.substring(expectedBaseNameLength);
    }
    return text.substring(text.length - trailLength);
  }, [text, trailLength]);
  const basename = useMemo(() => {
    return text.substring(0, text.length - trail.length);
  }, [text, trail.length]);
  return (
    <div css={styles.TruncatedText} ref={ref} {...props}>
      <span css={styles.basename}>{basename}</span>
      {!trail ? null : <span>{trail}</span>}
    </div>
  );
});

/**
 * A component that displays a text with ellipsis when the text overflows.
 */
const TruncatedText = Object.assign(TruncatedTextInternal, {
  styles,
});

assignDisplayName(TruncatedText, "TruncatedText");

export { TruncatedText };
