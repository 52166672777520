import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import objectInspect from "object-inspect";
import type { FC } from "react";

import { withFeatureFlagWrapper } from "@/app/featureFlag";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { Tag } from "@/components/Tag";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { MemberAccountStatus } from "@/routes/Chat/components/AccountStatus";
import { MemberStar } from "@/routes/Chat/components/MemberStar";
import { MemberStatus } from "@/routes/Chat/components/MemberStatus";
import { MemberAvatar } from "@/routes/Chat/ui/MemberProfilePanel/NamePanel/MemberAvatar";
import { MemberName } from "@/routes/Chat/ui/MemberProfilePanel/NamePanel/MemberName";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    borderBottom: `1px solid ${theme.colors.neutral003}`,
    gap: "8px",
  }),
  name: css({
    display: "flex",
    alignItems: "center",
    gap: "inherit",
  }),
  status: css({
    display: "flex",
    padding: "4px 0",
    gap: "inherit",
  }),
});

declare namespace NamePanel {
  export type Props = {
    showCloseDrawerButton: boolean;
    onClose: ComponentProps<typeof NarrowIconButton>["onClick"];
  };
}

const MemberId = withFeatureFlagWrapper("renderMemberId", () => {
  const memberId = memberIdUtils.useGet();
  return <Tag>id:{objectInspect(memberId)}</Tag>;
});

const NamePanel: FC<NamePanel.Props> = ({ showCloseDrawerButton, onClose }) => {
  return (
    <div css={styles.root}>
      <div css={styles.name}>
        {!showCloseDrawerButton ? null : (
          <NarrowIconButton
            icon={<MotifIcon un-i-motif="arrow_left" />}
            onClick={onClose}
          />
        )}
        <MemberAvatar />
        <MemberName />
        <MemberStar />
      </div>
      <div css={styles.status}>
        <MemberAccountStatus />
        <MemberStatus />
        <MemberId />
      </div>
    </div>
  );
};

export { NamePanel };
