import { crescendoLabBirthDay } from "@chatbotgang/etude/dummy";
import type { HourNumberSchema } from "@zeffiroso/utils/zod/HourNumberSchema";
import { setHours, startOfDay } from "date-fns/fp";
import { flow, merge } from "lodash-es";
import { useCallback, useMemo } from "react";
import type { z } from "zod";

import { useDateTimeFormat } from "@/components/DateTimeFormat/hooks/useDateTimeFormat";
import type { DateTimeFormatOptions } from "@/components/DateTimeFormat/types";

const defaultOptions: DateTimeFormatOptions = {
  dateTimeFormatOptions: {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23", // Use 24-hour format
  },
};

/**
 * Return an utility object to get a formatted hour string.
 *
 * ```tsx
 * const { format } = useHourDisplay();
 * const formattedHour = format(12); // "12:00"
 * ```
 */
const useHourDisplay = (options: DateTimeFormatOptions = {}) => {
  const mergedOptions = useMemo<NonNullable<DateTimeFormatOptions>>(
    () => merge({}, defaultOptions, options),
    [options],
  );
  const instance = useDateTimeFormat(mergedOptions);
  const format = useCallback(
    function formatHour(hour: z.infer<typeof HourNumberSchema>) {
      return flow(
        () => crescendoLabBirthDay,
        startOfDay,
        setHours(hour),
        instance.format,
      )();
    },
    [instance],
  );

  return {
    instance,
    format,
  };
};

const api = Object.assign(useHourDisplay, { defaultOptions });

export { api as useHourDisplay };
