import { ArrowDownOutlined } from "@ant-design/icons";
import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import type { BadgeProps } from "antd";
import { Badge } from "antd";

import type { ButtonProps } from "@/components/Button";
import { Button } from "@/components/Button";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";

interface GoToBottomButtonProps extends ButtonProps {
  active?: boolean;
  badgeProps?: BadgeProps;
}

export const GoToBottomButton = memo(function GoToBottomButton({
  active,
  badgeProps,
  ...props
}: GoToBottomButtonProps) {
  const activeMember = memberQueriesContext.useMember();
  return (
    <Badge
      css={css`
        position: absolute;
        right: 8px;
        bottom: 8px;
        transition:
          transform 0.2s ease-in-out,
          opacity 0.2s ease-in-out;
        ${active
          ? undefined
          : css`
              opacity: 0;
              pointer-events: none;
              transform: translateY(100%);
            `}
      `}
      count={activeMember.unreadMessageCount}
      {...badgeProps}
    >
      <Button
        {...props}
        type="primary"
        shape="circle"
        icon={<ArrowDownOutlined />}
        disabled={!active}
      />
    </Badge>
  );
});
