import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { DatePicker } from "@/components/DatePicker";
import { Form } from "@/components/Form";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";

const cssDatePicker = css`
  width: 100%;
  border-color: transparent;

  .ant-picker-input > input {
    color: ${theme.colors.neutral007};
    font-size: 0.75rem;
  }
`;

const BirthdayField: FC = () => {
  const profile = memberQueriesContext.useMember();
  const form = Form.useFormInstance();

  return (
    <Form.Item
      name="birthday"
      noStyle
      getValueProps={(v) => ({ value: v ?? profile.birthday })}
    >
      <DatePicker
        css={cssDatePicker}
        onChange={() => {
          // `clear` won't trigger onBlur
          form.submit();
        }}
      />
    </Form.Item>
  );
};

export { BirthdayField };
