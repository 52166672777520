/**
 * Get domain from email.
 * Returns empty string if email is invalid.
 */
function getDomainFromEmail(email: string): string {
  const splitted = email.split("@");
  if (splitted.length < 2) return "";
  return splitted[splitted.length - 1] || "";
}

export { getDomainFromEmail };
