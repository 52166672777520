import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";

import { Trans } from "@/app/i18n/Trans";
import { Pill } from "@/components/Pill";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";

export const MemberStatus = memo(function MemberStatus() {
  const member = memberQueriesContext.useMember();

  if (!member.memberStatus) return null;

  return (
    <Pill
      $variant="fulfilled"
      css={css`
        font-size: 0.75rem;
      `}
    >
      <Trans i18nKey="feature.member.memberStatus.member.label" />
    </Pill>
  );
});
