import { define } from "@chatbotgang/etude/util/define";
import {
  FIREBASE_CONTENT_STORAGE_BUCKET,
  FIREBASE_CONTENT_STORAGE_IMMORTAL_BUCKET,
} from "@zeffiroso/env";
import { caacFirebaseApp } from "@zeffiroso/firebase-app-caac";
import { randomizeFilename } from "@zeffiroso/utils/string/randomizeFileName";
import type {
  FirebaseStorage,
  StorageObserver,
  UploadTaskSnapshot,
} from "firebase/storage";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import type { PathFunction } from "path-to-regexp";
import { compile } from "path-to-regexp";

import type { CantataTypes } from "@/cantata/types";
import { uuid } from "@/lib/uuid";
import type { uploadAttachment } from "@/resources/attachment/uploadAttachment";

const storageNames = ["immortal", "default"] as const;
type StorageName = (typeof storageNames)[number];
const storageMap: Record<StorageName, FirebaseStorage> = {
  /**
   * Designed for enduring use, this resource remains timeless. Ideal for
   * avatars, quick templates, and other elements that serve a lasting purpose
   * rather than temporary needs.
   */
  immortal: getStorage(
    caacFirebaseApp,
    `gs://${FIREBASE_CONTENT_STORAGE_IMMORTAL_BUCKET}`,
  ),
  /**
   * Subject to expiration within a specified timeframe (e.g., 1 year), this
   * feature is tailored for immediate use and does not necessitate long-term
   * storage, making it particularly suitable for elements like attachments.
   */
  default: getStorage(
    caacFirebaseApp,
    `gs://${FIREBASE_CONTENT_STORAGE_BUCKET}`,
  ),
};

const features = [
  "attachment",
  "quickTemplate",
  "userAvatar",
  "orgAvatar",
] as const;
type Feature = (typeof features)[number];
const attachmentFeatures = define<Array<Feature>>()([
  "attachment",
  //  quickTemplate is not supported yet
  // 'quickTemplate'
]);
type AttachmentFeature = (typeof attachmentFeatures)[number];

const featureConfigs = define<
  Record<
    Feature,
    {
      toPath: PathFunction<any>;
      storage: FirebaseStorage;
    }
  >
>()({
  attachment: {
    toPath: compile<{
      channelId: CantataTypes["Channel"]["id"];
      memberId: CantataTypes["Member"]["id"];
      hash: string;
      fileName: string;
    }>("channels/:channelId/members/:memberId/messages/:hash/:fileName"),
    storage: storageMap.default,
  },
  quickTemplate: {
    toPath: compile<{
      channelId: CantataTypes["Channel"]["id"];
      hash: string;
      fileName: string;
    }>("channels/:channelId/quick-template/:hash/:fileName"),
    storage: storageMap.immortal,
  },
  userAvatar: {
    toPath: compile<{
      orgId: CantataTypes["Org"]["id"];
      userId: CantataTypes["User"]["id"];
      hash: string;
      fileName: string;
    }>("organizations/:orgId/users/:userId/avatars/:hash/:fileName"),
    storage: storageMap.immortal,
  },
  orgAvatar: {
    toPath: compile<{
      orgId: CantataTypes["Org"]["id"];
      hash: string;
      fileName: string;
    }>("organizations/:orgId/avatars/:hash/:fileName"),
    storage: storageMap.immortal,
  },
});

type FeatureConfig<F extends Feature> = (typeof featureConfigs)[F];

const fileNameKey = "fileName";
const hashKey = "hash";
type FileNameKey = typeof fileNameKey;
type HashKey = typeof hashKey;

type BaseParams<F extends Feature> = {
  feature: F;
  pathParams: Omit<
    NonNullable<Parameters<FeatureConfig<F>["toPath"]>[0]>,
    FileNameKey | HashKey
  >;
  file: File;
  randomizeFileName?: boolean;
  signal?: AbortSignal;
  onNext?: (uploadTaskSnapshot: UploadTaskSnapshot) => void;
};

async function asyncUploadBytesResumable(
  {
    signal,
    ...storageObserver
  }: Pick<StorageObserver<UploadTaskSnapshot>, "next"> & {
    signal?: AbortSignal;
  },
  ...args: Parameters<typeof uploadBytesResumable>
) {
  const uploadTask = uploadBytesResumable(...args);
  uploadTask.on("state_changed", {
    next(uploadTaskSnapshot) {
      storageObserver.next?.(uploadTaskSnapshot);
    },
  });
  if (signal?.aborted) {
    uploadTask.cancel();
  } else {
    signal?.addEventListener("abort", () => {
      uploadTask.cancel();
    });
  }
  return uploadTask;
}

/**
 * Avoid employing this particular function for attachment features.
 *
 * @see {@link uploadAttachment}
 */
async function internal<F extends Feature>({
  feature,
  file,
  randomizeFileName: randomizeFileNameOption = true,
  signal,
  pathParams,
  onNext,
}: BaseParams<F>) {
  const featureConfig: FeatureConfig<F> = featureConfigs[feature];
  const storage = featureConfig.storage;
  const toPath: FeatureConfig<F>["toPath"] = featureConfig.toPath;
  const hash = uuid();
  const filePath = toPath({
    ...pathParams,
    [hashKey]: hash,
    [fileNameKey]: randomizeFileNameOption
      ? randomizeFilename(file.name)
      : file.name,
  } as any);
  const storageReference = ref(storage, filePath);
  const uploadTaskSnapshot = await asyncUploadBytesResumable(
    {
      next: onNext,
      signal,
    },
    storageReference,
    file,
    {
      contentType: file.type,
    },
  );
  signal?.throwIfAborted();
  const downloadUrl = await getDownloadURL(storageReference);
  signal?.throwIfAborted();
  return {
    uploadTaskSnapshot,
    downloadUrl,
  };
}

const external: <F extends Exclude<Feature, AttachmentFeature>>(
  params: BaseParams<F>,
) => ReturnType<typeof internal> = internal;

export { internal, external as uploadToFirebaseStorage };
export type { AttachmentFeature, BaseParams, Feature };
