import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

/**
 *
 * @returns {boolean} Is navigator online
 */
function getIsOnline(): boolean {
  return navigator.onLine;
}

interface StoreValue {
  value: boolean;
}

const useOnlineStore = createWithEqualityFn<StoreValue>()(
  () => ({
    value: getIsOnline(),
  }),
  shallow,
);

window.addEventListener("online", () => {
  useOnlineStore.setState({ value: getIsOnline() });
});

window.addEventListener("offline", () => {
  useOnlineStore.setState({ value: getIsOnline() });
});

export function useOnline() {
  const { value } = useOnlineStore();
  return value;
}
