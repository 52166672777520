import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";

const cssH3 = css`
  margin-bottom: 0;
  color: ${theme.colors.neutral010};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
`;

const cssBodyBold = css`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
`;

const cssBodyRegular = css`
  color: ${theme.colors.neutral007};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
`;

const cssSectionWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const cssSection = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const cssWrapper = css`
  display: flex;
  max-width: 540px;
  flex-direction: column;
  gap: 8px;
`;

export {
  cssBodyBold,
  cssBodyRegular,
  cssH3,
  cssSection,
  cssSectionWrapper,
  cssWrapper,
};
