import "@/init";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { App, AppProviders } from "@/App";
import { reportWebVitals } from "@/reportWebVitals";

const container = document.getElementById("root");

if (!container) throw new Error("No root element found");

const root = createRoot(container);

root.render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
