import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { Overwrite } from "@mui/types";
import type { ElementRef } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { MotifIcon } from "@/components/MotifIcon";

namespace ImportUsersToggleButton {
  export type Ref = ElementRef<typeof Button>;
  export interface OwnProps {}
  export type Props = Overwrite<ComponentProps<typeof Button>, OwnProps>;
}

const ImportUsersToggleButtonInternal = forwardRef<
  ImportUsersToggleButton.Ref,
  ImportUsersToggleButton.Props
>(function ImportUsersToggleButtonInternal(props, ref) {
  const { t } = useTranslation();
  return (
    <Button icon={<MotifIcon un-i-motif="arrow_import" />} ref={ref} {...props}>
      {t("page.settings.users.importUsers.toggleModal.button.label")}
    </Button>
  );
});

const ImportUsersToggleButton = Object.assign(
  ImportUsersToggleButtonInternal,
  {},
);

assignDisplayName(ImportUsersToggleButton, "ImportUsersToggleButton");

export { ImportUsersToggleButton };
