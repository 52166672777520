import type EventEmitter from "eventemitter3";
import { useEffect } from "react";

/**
 * Create a hook that adds a listener to an event emitter on mount and removes
 * it on unmount.
 *
 * @example
 *
 * ```tsx
 * type EventTypes = {
 *   foo: (bar: string) => void;
 *   baz: () => void;
 * };
 *
 * const eventEmitter = new EventEmitter<EventTypes>();
 *
 * const useOn = createUseOn(eventEmitter);
 *
 * function MyComponent() {
 *   useOn('foo', (bar) => {
 *     console.log(bar);
 *   });
 *   useOn('baz', () => {
 *     console.log('baz');
 *   });
 *   return null;
 * }
 * ```
 */
function createUseOn<
  EventTypes extends EventEmitter.ValidEventTypes = string | symbol,
  Context = any,
>(eventEmitter: EventEmitter<EventTypes, Context>) {
  function useOn<T extends EventEmitter.EventNames<EventTypes>>(
    eventName: T,
    listener: EventEmitter.EventListener<EventTypes, T>,
    context?: Context,
  ) {
    useEffect(() => {
      eventEmitter.on(eventName, listener, context);
      return function cleanup() {
        eventEmitter.off(eventName, listener, context);
      };
    }, [context, eventName, listener]);
  }
  return useOn;
}

export { createUseOn };
