import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

export const parseMobile = (
  raw: string | null,
): {
  isNumberValid: boolean;
  formattedFullNumber: string;
  formattedCountryCode: string;
  formattedNationalNumber: string;
} => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parseAndKeepRawInput(raw ?? "");
    const isNumberValid = phoneUtil.isValidNumber(phoneNumber);
    const formattedNumber = phoneUtil.format(
      phoneNumber,
      PhoneNumberFormat.E164,
    );

    return {
      isNumberValid,
      formattedFullNumber: formattedNumber,
      formattedCountryCode: `+${phoneNumber.getCountryCode()}`,
      formattedNationalNumber: `${phoneNumber.getNationalNumber()}`,
    };
  } catch {
    return {
      isNumberValid: false,
      formattedFullNumber: "",
      formattedCountryCode: "",
      formattedNationalNumber: "",
    };
  }
};
