import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { downloadFile } from "@zeffiroso/utils/vanilla/downloadFile";
import type { ElementRef } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "@/components/Link";
import { MotifIcon } from "@/components/MotifIcon";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  DownloadErrorReportButton: css({
    display: "flex",
    alignItems: "center",
    gap: 4,
  }),
});

namespace DownloadErrorReportButton {
  export type Ref = ElementRef<typeof Link>;
  export interface OwnProps {
    content: string;
  }
  export type Props = Overwrite<
    Omit<ComponentProps<typeof Link>, "children">,
    OwnProps
  >;
}

const DownloadErrorReportButtonInternal = forwardRef<
  DownloadErrorReportButton.Ref,
  DownloadErrorReportButton.Props
>(function DownloadErrorReportButtonInternal(props, ref) {
  const { t } = useTranslation();
  const onClick = useHandler<ComponentProps<typeof Link>["onClick"]>((ev) => {
    if (props.onClick) {
      props.onClick(ev);
      if (ev.defaultPrevented) return;
    }
    ev.preventDefault();
    const blob = new Blob([props.content], { type: "text/csv" });
    downloadFile(blob, "error-report.csv");
  });
  return (
    <Link
      css={styles.DownloadErrorReportButton}
      ref={ref}
      {...props}
      onClick={onClick}
    >
      <MotifIcon un-i-motif="arrow_download" />
      <span>
        {t(
          "page.settings.users.importUsers.modal.error.errorResult.action.download.label",
        )}
      </span>
    </Link>
  );
});

/**
 * Button to download the error report.
 */
const DownloadErrorReportButton = Object.assign(
  DownloadErrorReportButtonInternal,
  {
    styles,
  },
);

assignDisplayName(DownloadErrorReportButton, "DownloadErrorReportButton");

export { DownloadErrorReportButton };
