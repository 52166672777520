import objectInspect from "object-inspect";
import type { F } from "ts-toolbelt";
import type { Primitive, z } from "zod";

import { GroupMemberListUpdatedSchema } from "./on/groupMemberListUpdated";
import { GroupRejoinedSchema } from "./on/groupRejoined";
import { MemberListUpdated } from "./on/memberListUpdated";
import { MergeStateUpdatedSchema } from "./on/mergeStateUpdated";
import { MessagesSchema } from "./on/messages";
import { MessageUpdateSchema } from "./on/messageUpdate";
import { UserUpdatedSchema } from "./on/userUpdated";
import { defineSchemaMap } from "./utils";

const LegatoOnEventSchemaMap = defineSchemaMap({
  messages: MessagesSchema,
  "message-update": MessageUpdateSchema,
  "user-updated": UserUpdatedSchema,
  "member-list-updated": MemberListUpdated,
  "group-member-list-updated": GroupMemberListUpdatedSchema,
  "group-rejoined": GroupRejoinedSchema,
  "merge-state-updated": MergeStateUpdatedSchema,
});

function isOnSchemaEventType(
  type: string,
): type is keyof typeof LegatoOnEventSchemaMap {
  return type in LegatoOnEventSchemaMap;
}

class InvalidInputError extends Error {
  constructor(...args: ConstructorParameters<typeof Error>) {
    super(...args);
    this.name = "InvalidInputError";
  }
}

type ParseOnReturnType<Input> = Input extends {
  type: infer Type;
}
  ? Type extends keyof typeof LegatoOnEventSchemaMap
    ? z.infer<(typeof LegatoOnEventSchemaMap)[Type]>
    : never
  : Input extends Primitive
    ? never
    : z.infer<
        (typeof LegatoOnEventSchemaMap)[keyof typeof LegatoOnEventSchemaMap]
      >;

function parseLegatoOnEvent<Input>(
  input: F.Narrow<Input>,
): ParseOnReturnType<Input> {
  if (
    !input ||
    typeof input !== "object" ||
    !("type" in input) ||
    typeof input.type !== "string" ||
    !isOnSchemaEventType(input.type)
  )
    throw new InvalidInputError(`Invalid input: ${objectInspect(input)}`);
  return LegatoOnEventSchemaMap[input.type].parse(
    input,
  ) as ParseOnReturnType<Input>;
}

type LegatoOnEventType = keyof typeof LegatoOnEventSchemaMap;

type LegatoOnEventTypes = {
  [EventType in LegatoOnEventType]: z.infer<
    (typeof LegatoOnEventSchemaMap)[EventType]
  >;
};

export { InvalidInputError, LegatoOnEventSchemaMap, parseLegatoOnEvent };

export type { LegatoOnEventType, LegatoOnEventTypes };
