import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import type { FormProps } from "@/components/Form";
import { Form, FormItem } from "@/components/Form";
import { useMessage } from "@/components/message";
import { AvatarFormItem } from "@/routes/Settings/Profile/ui/AvatarFormItem";

type FormValues = {
  logo: string;
};

export const OrgLogo = memo(function OrgLogo() {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [form] = Form.useForm<FormValues>();
  const message = useMessage();
  const query = cantata.org.useGetById({
    params: {
      orgId,
    },
  });

  const mutation = cantata.org.usePartialUpdate(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess() {
        message.success(t("common.updatedSuccessfully"));
      },
    },
  );

  const handleSubmit = useHandler<FormProps<FormValues>["onFinish"]>(
    function onSubmit(values) {
      if (!query.data || !values.logo) return;

      mutation.mutate({ logo: values.logo });
    },
  );

  if (query.isLoading) return <Skeleton.Avatar size={32} />;

  if (query.isError)
    return <Alert type="error" message={query.error.message} />;

  return (
    <Form
      form={form}
      routerPromptOptions={{ disabled: true }}
      initialValues={{ logo: query.data.logo }}
      disabled={mutation.isLoading}
      onValuesChange={form.submit}
      onFinish={handleSubmit}
    >
      <FormItem name="logo" noStyle>
        <AvatarFormItem type="org" orgId={orgId} />
      </FormItem>
    </Form>
  );
});
