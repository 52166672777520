import { fc } from "@chatbotgang/etude/react/fc";
import type { FC, ReactNode } from "react";
import { useEffect, useMemo } from "react";

import { Alert } from "@/components/Alert";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { BarLoading } from "@/components/Loading/BarLoading";
import type { ModalProps } from "@/components/Modal";
import { Modal } from "@/components/Modal";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { Context } from "@/routes/Chat/ui/conversationHistoryMessages/Context";
import { MessagesModal } from "@/routes/Chat/ui/conversationHistoryMessages/MessagesModal";
import { OutOfScopeModal } from "@/routes/Chat/ui/conversationHistoryMessages/OutOfScopeModal";
import { setupConversationHistoryMessagesController } from "@/routes/Chat/ui/conversationHistoryMessages/setupConversationHistoryMessagesController";
import { useCheckMemberInMyScope } from "@/routes/Chat/useCheckMemberInMyScope";
const Loader = (() => {
  const Loader = fc(function Loader() {
    const activeMemberId = memberIdUtils.useGet();
    const conversationHistoryMessagesController =
      conversationHistoryMessages.useController();
    const target = conversationHistoryMessagesController.useTarget();
    const member = memberQueriesContext.useMember();
    const checkInMyScope = useCheckMemberInMyScope();
    const commonModalProps = useMemo<ModalProps>(
      () => ({
        open: true,
        footer: null,
        onCancel: conversationHistoryMessagesController.close,
        onOk: conversationHistoryMessagesController.close,
        destroyOnClose: true,
      }),
      [conversationHistoryMessagesController.close],
    );
    return !target ? null : checkInMyScope.isLoading ||
      activeMemberId !== target.memberId ? (
      <Modal {...commonModalProps}>
        <BarLoading />
      </Modal>
    ) : checkInMyScope.meQuery.isError ? (
      <Modal {...commonModalProps}>
        <Alert type="error" message={checkInMyScope.meQuery.error.message} />
      </Modal>
    ) : checkInMyScope.myTeamsQuery.isError ? (
      <Modal {...commonModalProps}>
        <Alert
          type="error"
          message={checkInMyScope.myTeamsQuery.error.message}
        />
      </Modal>
    ) : !checkInMyScope.exec({
        member,
      }) && member.assignType === "permanent" ? (
      <OutOfScopeModal {...commonModalProps} />
    ) : (
      <MessagesModal {...commonModalProps} />
    );
  });
  const Wrapper: FC = () => {
    const activeMemberId = memberIdUtils.useGet();
    const conversationHistoryMessagesController =
      conversationHistoryMessages.useController();
    return !activeMemberId ? null : (
      <ErrorBoundary.Modal
        ModalProps={{
          onCancel: conversationHistoryMessagesController.close,
        }}
      >
        <memberQueriesContext.Provider>
          <Loader key={activeMemberId} />
        </memberQueriesContext.Provider>
      </ErrorBoundary.Modal>
    );
  };
  return Wrapper;
})();

const SyncActiveMemberId: FC = () => {
  const controller = useController();
  const activeMemberId = memberIdUtils.useGet();
  const setActiveMemberId = memberIdUtils.useSet();
  const clearActiveMemberId = memberIdUtils.useClear();
  const target = controller.useTarget();
  useEffect(() => {
    if (!target) {
      if (activeMemberId) clearActiveMemberId();

      return;
    }
    if (target.memberId === activeMemberId) return;
    setActiveMemberId(target.memberId);
  }, [activeMemberId, clearActiveMemberId, setActiveMemberId, target]);
  return null;
};

const Provider = fc<{ children: ReactNode }>(function Provider({ children }) {
  const controller = useMemo(setupConversationHistoryMessagesController, []);
  return (
    <Context.Provider value={controller}>
      {children}
      <memberIdUtils.Provider>
        <Loader />
        <SyncActiveMemberId />
      </memberIdUtils.Provider>
    </Context.Provider>
  );
});

const useController = Context.useContext;

const conversationHistoryMessages = {
  Provider,
  useController,
};

export { conversationHistoryMessages };
