import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { fc } from "@chatbotgang/etude/react/fc";
import type { ReactNode } from "react";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { BarLoading } from "@/components/Loading/BarLoading";

const Context = createContext<{
  channels: CantataTypes["Channel"][];
}>({
  name: "Channels",
});

const useChannelsQueryContext = Context.useContext;

const ChannelsQueryProvider = fc<{ children: ReactNode }>(
  function ChannelsQueryProvider({ children }) {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const channelsQuery = cantata.channel.useList({
      params: {
        orgId,
      },
    });

    if (channelsQuery.isLoading) return <BarLoading />;

    if (channelsQuery.isError) {
      return (
        <Alert type="error" message={inspectMessage`${channelsQuery.error}`} />
      );
    }

    return (
      <Context.Provider value={channelsQuery.data}>{children}</Context.Provider>
    );
  },
);

function useGetChannel(channelId: CantataTypes["Channel"]["id"]) {
  const controller = useChannelsQueryContext();
  const ret = useMemo(
    function getChannel() {
      return controller.channels.find((channel) => channel.id === channelId);
    },
    [controller.channels, channelId],
  );

  return ret;
}

export { ChannelsQueryProvider, useGetChannel };
