import type { FC } from "react";

import { Trans } from "@/app/i18n/Trans";
import { TextBubble } from "@/routes/Chat/ui/ChatPanel/History/Messages/TextBubble";

const ContentNotAvailable: FC = () => {
  return (
    <TextBubble messageType="caution">
      <Trans i18nKey="resource.message.media.error.notAvailable.content" />
    </TextBubble>
  );
};

export { ContentNotAvailable };
