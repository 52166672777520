import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { random } from "@chatbotgang/etude/string/random";
import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { BarLoading } from "@/components/Loading/BarLoading";
import type { FormValues } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";
import { usePageInfo } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/usePageInfo";

const InitialValuesContext = createContext<FormValues | undefined>(undefined);

function useInitialValues(): FormValues {
  const initialValues = useContext(InitialValuesContext);
  if (initialValues === undefined) {
    throw new Error(
      "useInitialValues must be used within InitialValuesProvider",
    );
  }

  return initialValues;
}

const createDefaultFormValues = (): FormValues => ({
  name: "",
  status: "off",
  assignee: null,
  conditions: [
    {
      key: random(),
      category: null,
    },
  ],
});

const DefaultFormValues = memo(function DefaultFormValues({
  children,
}: {
  children: ReactNode;
}) {
  const [initialValues] = useState<FormValues>(createDefaultFormValues);
  return (
    <InitialValuesContext.Provider value={initialValues}>
      {children}
    </InitialValuesContext.Provider>
  );
});

/**
 * This component is for fetch the data only once.
 *
 * This is a workaround for the issue that the form will be reset when the data
 * is fetched.
 *
 * FIXME: Fetch the data when the form values are not not modified, otherwise
 * use the latest form values before the form values are modified.
 */
const GetById = memo(function GetById({ children }: { children: ReactNode }) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { autoAssignmentRuleId } = usePageInfo();
  if (autoAssignmentRuleId === undefined)
    throw new Error("autoAssignmentRuleId is undefined");

  const [initialValues, setInitialValues] = useState<FormValues | undefined>(
    undefined,
  );
  const query = cantata.autoAssignment.useGetById(
    {
      params: {
        orgId,
        autoAssignmentRuleId,
      },
    },
    {
      // TODO:callbacks for queries are going to be deprecated.
      onSuccess: (data) => {
        if (initialValues !== undefined) return;

        setInitialValues({
          name: data.name,
          status: data.status,
          assignee: data.assignee,
          conditions: data.conditions.map((condition) => ({
            ...condition,
            key: random(),
          })),
        });
      },
    },
  );
  if (initialValues === undefined) return <BarLoading />;

  if (query.isError) {
    return (
      <Alert
        type="error"
        message={inspectMessage`query error: ${query.error}`}
      />
    );
  }

  return (
    <InitialValuesContext.Provider value={initialValues}>
      {children}
    </InitialValuesContext.Provider>
  );
});

const InitialValuesProvider = memo(function InitialValuesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { autoAssignmentRuleId } = usePageInfo();
  return !autoAssignmentRuleId ? (
    <DefaultFormValues>{children}</DefaultFormValues>
  ) : (
    <GetById key={autoAssignmentRuleId}>{children}</GetById>
  );
});

export { createDefaultFormValues, InitialValuesProvider, useInitialValues };
