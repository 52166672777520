import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { ComponentPropsWithoutRef, ElementRef, FC } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { mergeRefs } from "react-merge-refs";

import { useGetMedialUrl } from "@/resources/message/utils";
import { ContentNotAvailable } from "@/routes/Chat/ui/ChatPanel/History/Messages/ContentNotAvailable";
import { messageUtil } from "@/routes/Chat/ui/ChatPanel/History/Messages/messageUtil";
import { defineStyles } from "@/shared/emotion";
import { emotionMedia } from "@/shared/utils/style/emotionMedia";

const styles = defineStyles({
  root: css`
    min-width: 200px;
    max-width: 100%;
    max-height: 300px;

    ${emotionMedia(
      css,
      "<=mobile",
      (css) => css`
        max-width: calc(100vw - 80px);
      `,
    )}
  `,
});

type VideoProps = ComponentProps<"video">;
type OnErrorEvent = Parameters<NonNullable<VideoProps["onError"]>>[0];
const VideoBubble = forwardRef<
  HTMLVideoElement,
  ComponentPropsWithoutRef<"video">
>(function VideoBubble(_props, ref) {
  const getMediaUrl = useGetMedialUrl();
  const message = messageUtil.useMessage();
  const inViewHookResponse = useInView();
  const localRef = useRef<ElementRef<"video">>(null);
  const mergedRef = useMemo(
    function mergedRefFactory() {
      return mergeRefs([ref, localRef, inViewHookResponse.ref]);
    },
    [inViewHookResponse.ref, ref],
  );
  useEffect(
    function pauseVideoIfNotInView() {
      if (inViewHookResponse.inView) return;
      if (!localRef.current) return;
      const videoElement = localRef.current;
      if (videoElement.paused) return;
      videoElement.pause();
    },
    [inViewHookResponse],
  );

  const [errorEvent, setErrorEvent] = useState<OnErrorEvent | null>(null);
  const onError = useHandler<VideoProps["onError"]>(function onError(...args) {
    const e = args[0];
    setErrorEvent(e);
  });
  const src = getMediaUrl(message);

  if (errorEvent) return <ContentNotAvailable />;

  return (
    <video
      css={styles.root}
      controlsList="noremoteplayback"
      controls
      muted
      onError={onError}
      src={src}
      ref={mergedRef}
    />
  );
});
const Wrapper: FC = () => {
  const getMediaUrl = useGetMedialUrl();
  const message = messageUtil.useMessage();
  const src = getMediaUrl(message);
  return <VideoBubble key={src} />;
};

assignDisplayName(Wrapper, "VideoBubble");

export { Wrapper as VideoBubble };
