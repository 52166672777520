import type { availableLocales } from "@/shared/g11n/config";

type AvailableLanguage = (typeof availableLocales)[number]["code"];

/**
 * Unify lang code to our accepted format in `availableLocales`, resource lang
 * code might be from navigator or others
 * @param lang
 * @returns formatted lang code
 */
function formatLangCode(lang: string): AvailableLanguage {
  if (lang.startsWith("zh")) return "zh-hant";
  if (lang.startsWith("en")) return "en";
  if (lang.startsWith("th")) return "th";
  if (lang.startsWith("ja")) return "ja";
  return "en";
}

export { formatLangCode };
export type { AvailableLanguage };
