import type { FileSizeOptionsBase } from "filesize";
import { filesize } from "filesize";
import { merge } from "lodash-es";

const defaultOptions: FileSizeOptionsBase = {
  standard: "jedec",
};

function toFileSizeDisplayString(
  bytes: number,
  options?: FileSizeOptionsBase,
): string {
  const mergedOptions = merge({}, defaultOptions, options);
  return filesize(bytes, mergedOptions);
}

export { toFileSizeDisplayString };
