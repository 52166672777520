import { Outlet } from "react-router-dom";

import { defineChildRouteObject } from "@/router/utils/defineChildRouteObject";
import { TeamCreateForm } from "@/routes/Settings/Teams/pages/TeamForm/TeamCreateForm";
import { TeamEdit } from "@/routes/Settings/Teams/pages/TeamForm/TeamUpdatePage";
import {
  TeamList,
  TeamListTabActiveKeyStateProvider,
} from "@/routes/Settings/Teams/pages/TeamList";

const teamsRouteObject = defineChildRouteObject({
  element: (
    <TeamListTabActiveKeyStateProvider>
      <Outlet />
    </TeamListTabActiveKeyStateProvider>
  ),
  children: [
    {
      index: true,
      element: <TeamList />,
    },
    {
      path: "create",
      element: <TeamCreateForm />,
    },
    {
      path: "view/:teamId",
      element: <TeamEdit />,
    },
    {
      path: "edit/:teamId",
      element: <TeamEdit />,
    },
  ],
});

export { teamsRouteObject };
