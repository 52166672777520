import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useChange from "@react-hook/change";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { isEqual } from "lodash-es";
import type { ElementRef, ReactNode } from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { HIERARCHY_STRING_JOINER } from "@/appConstant";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import type { InputProps } from "@/components/Input";
import { Input } from "@/components/Input";
import { MotifIcon } from "@/components/MotifIcon";
import type {
  AssigneeSelectorContextState,
  Values,
} from "@/routes/Chat/components/Assignee/AssigneeSelector/AssigneeSelectorContext";
import {
  AssigneeSelectorContext,
  useAssigneeSelectorContext,
} from "@/routes/Chat/components/Assignee/AssigneeSelector/AssigneeSelectorContext";
import { AssigneeSelectorProvider } from "@/routes/Chat/components/Assignee/AssigneeSelector/AssigneeSelectorProvider";
import { useDropdownSwitch } from "@/routes/Chat/components/Assignee/AssigneeSelector/useDropdownSwitch";

const useUserName = () => {
  const { t } = useTranslation();
  return (name: string) =>
    !name
      ? t("chat.manualAssignment.assigneeSelector.option.user.unnamed.label")
      : name;
};

const useDisplayValue = (): string => {
  const { t } = useTranslation();
  const fallbackValue = t(
    "chat.manualAssignment.assigneeSelector.placeholder.active",
  );
  const displayUserName = useUserName();
  const { assignee } = useAssigneeSelectorContext();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const teamsQuery = cantata.team.useList(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const usersQuery = cantata.user.useList(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );

  const displayValue = useMemo(() => {
    // show last assignee if memberId is provided
    if (assignee === undefined || assignee.type === "unassigned")
      return fallbackValue;

    if (!usersQuery.isSuccess || !teamsQuery.isSuccess) return fallbackValue;

    const user = usersQuery.data.users.find((u) =>
      !("userId" in assignee) ? undefined : u.id === assignee.userId,
    );
    const userDisplayName = !user ? "" : displayUserName(user.name);
    const team = teamsQuery.data.teams.find((t) =>
      !("teamId" in assignee) ? undefined : t.id === assignee.teamId,
    );
    const teamName = !team ? "" : team.name;

    let displayText = "";
    const activeType = assignee.type;
    switch (activeType) {
      case "user":
        displayText = userDisplayName;
        break;
      case "user-in-team":
        displayText = `${teamName}${HIERARCHY_STRING_JOINER}${userDisplayName}`;
        break;
      case "team":
        displayText = teamName;
        break;
      default:
        activeType satisfies never;
    }

    return displayText;
  }, [
    assignee,
    displayUserName,
    fallbackValue,
    teamsQuery.data?.teams,
    teamsQuery.isSuccess,
    usersQuery.data?.users,
    usersQuery.isSuccess,
  ]);
  return displayValue;
};

type AssigneeSelectorProps = InputProps;

const cssDownArrow = css`
  font-size: 0.75rem;
`;

const AssigneeSearchInput = forwardRef<
  ElementRef<typeof Input>,
  AssigneeSelectorProps
>(function AssigneeSearchInput(props, ref) {
  const { t } = useTranslation();
  const { assignee, searchValue, setSearchValue } =
    useAssigneeSelectorContext();
  const [isDropdownOpen, toggle] = useDropdownSwitch();
  const displayValue = useDisplayValue();
  const assigneeSelectorProps = useAssigneeSelectorProps();
  const isUnassigned = assignee
    ? assignee.type === "unassigned"
    : assigneeSelectorProps.assignType === "unassigned";

  useChange(
    isDropdownOpen,
    function resetSearchValueWhenDropdownClose(current) {
      if (!current) setSearchValue("");
    },
  );

  let inputValue: string = "";
  if (!isDropdownOpen) inputValue = displayValue;
  else if (searchValue) inputValue = searchValue;

  return (
    <Input
      css={css([
        {
          input: {
            transition: "none",
          },
        },
        !isDropdownOpen && {
          "& .ant-input-prefix": {
            flex: 1,
            fontSize: "inherit",
          },
          "& input": {
            width: 0,
            fontSize: "inherit",
          },
        },
        !isDropdownOpen &&
          isUnassigned && {
            color: theme.colors.blue006,
          },
      ])}
      prefix={(() => {
        let key = 0;
        const nodes: Array<ReactNode> = [];
        if (assigneeSelectorProps.assignType === "permanent") {
          nodes.push(
            <MotifIcon
              key={key++}
              un-i-motif="lock"
              css={css`
                color: ${theme.colors.neutral006};
              `}
            />,
          );
        }
        if (!isDropdownOpen) {
          nodes.push(
            <div key={key++}>
              {isUnassigned
                ? t("chat.memberPanel.bulkAction.assignModal.assignee.label")
                : displayValue}
            </div>,
          );
        }
        if (nodes.length === 0) {
          return null;
        }
        return (
          <div
            css={{
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
          >
            {nodes}
          </div>
        );
      })()}
      suffix={
        <span
          css={css({
            display: "flex",
            color:
              !isDropdownOpen && isUnassigned
                ? "inherit"
                : theme.colors.neutral006,
          })}
        >
          {isDropdownOpen ? (
            <SearchOutlined />
          ) : (
            <DownOutlined css={cssDownArrow} />
          )}
        </span>
      }
      value={inputValue}
      placeholder={displayValue}
      onFocus={() => {
        toggle.on();
        setSearchValue("");
      }}
      onChange={(e) => {
        setSearchValue(e.target.value);
      }}
      {...props}
      ref={ref}
    />
  );
});

type AssigneeSelectorPropsWithoutRef = {
  value?: Values["assignee"];
  onChange?: (value: Values["assignee"]) => void;
  assignType?: CantataTypes["Member"]["assignType"];
};

const PropsContext = createContext<AssigneeSelectorPropsWithoutRef>({
  name: "AssigneeSelectorPropsContext",
});

function useAssigneeSelectorProps() {
  return PropsContext.useContext();
}

const AssigneeSelector = forwardRef<
  ElementRef<typeof AssigneeSearchInput>,
  AssigneeSelectorPropsWithoutRef
>(function AssigneeSelector({ value, onChange }, ref) {
  const [searchValue, setSearchValue] = useState("");
  const dropdownSwitchContextValue = useSwitch(false);
  const [, dropdownSwitch] = dropdownSwitchContextValue;

  const handleAssigneeChange = useHandler(function handleAssigneeChange(
    draft: Values["assignee"],
  ) {
    dropdownSwitch.off();
    setSearchValue("");

    if (isEqual(draft, value)) return;
    onChange?.(draft);
  });

  const assigneeSelectorContextValue = useMemo<AssigneeSelectorContextState>(
    () => ({
      assignee: value,
      onAssigneeChange: handleAssigneeChange,
      searchValue,
      setSearchValue,
    }),
    [handleAssigneeChange, searchValue, value],
  );

  return (
    <AssigneeSelectorContext.Provider value={assigneeSelectorContextValue}>
      <AssigneeSelectorProvider value={dropdownSwitchContextValue}>
        <AssigneeSearchInput ref={ref} />
      </AssigneeSelectorProvider>
    </AssigneeSelectorContext.Provider>
  );
});

const ErrorBoundrayWrapped: typeof AssigneeSelector = forwardRef(
  function ErrorBoundrayWrapped(props, ref) {
    return (
      <PropsContext.Provider value={props}>
        <ErrorBoundary.Alert>
          <AssigneeSelector {...props} ref={ref} />
        </ErrorBoundary.Alert>
      </PropsContext.Provider>
    );
  },
);

export { ErrorBoundrayWrapped as AssigneeSelector };
