import { match } from "path-to-regexp";

import { api as authApi } from "./api/auth";

const publicApis = [...authApi.map((api) => api.path)];

function isPublicApi(url: string) {
  return publicApis.some((publicApi) =>
    match(publicApi, { decode: decodeURIComponent })(url),
  );
}

export { isPublicApi };
