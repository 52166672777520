import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { delay } from "@chatbotgang/etude/timer/delay";
import useChange from "@react-hook/change";
import type { FormProps } from "antd";
import { useEffect, useMemo, useState } from "react";

type HasFieldsErrorOptions<Values = any> = {
  formProps?: FormProps<Values>;
};

/**
 * This is a workaround to get the error state from Ant Design Form because it
 * does not expose the state of them. It's not a really good practice to use
 * React state.
 *
 * @example
 * ```tsx
 * const {
 *   hasFieldsError,
 *   injectFormProps,
 * } = useHasFieldsError({ formProps });
 * return <Form {...injectFormProps} />;
 * ```
 */
export function useHasFieldsError<Values = any>({
  formProps,
}: HasFieldsErrorOptions<Values> = {}) {
  const form = formProps?.form;
  /**
   * This is a workaround to sync state from Ant Design Form because it does not
   * expose the state of them. It's not a really good practice to use React
   * state.
   */
  const [hasFieldsError, setHasFieldsError] = useState(false);
  const syncHasFieldsError = useHandler(async () => {
    await delay(1);
    setHasFieldsError(
      !form
        ? false
        : form
            .getFieldsError()
            .some((fieldError) => fieldError.errors.length > 0),
    );
  });
  const onFieldsChange = useHandler<FormProps<Values>["onFieldsChange"]>(
    function onFieldsChange(...args) {
      syncHasFieldsError();
      formProps?.onFieldsChange?.(...args);
    },
  );
  useEffect(() => {
    if (form) {
      syncHasFieldsError();
      return;
    }
    setHasFieldsError(false);
  }, [form, syncHasFieldsError]);
  useChange(formProps?.form, () => {
    throw new Error("[useHasFieldsError] Do not change `formProps.form`");
  });

  const injectedFormProps = useMemo<FormProps<Values>>(
    () => ({
      ...formProps,
      onFieldsChange,
    }),
    [formProps, onFieldsChange],
  );
  return { hasFieldsError, injectedFormProps };
}
