import { memo } from "@chatbotgang/etude/react/memo";
import { kebabCase } from "lodash-es";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { z } from "zod";

import { useSearchParamFromDirectChatStore } from "@/routes/Chat/ui/directChat";

import { searchController } from ".";

const SyncSearchFromDirectChat = (function getSyncSearchFromDirectChat() {
  const actionSchema = z.enum(["external-member-id"]);
  const SyncSearchFromDirectChat = memo(function SyncSearchFromDirectChat() {
    const searchParamFromDirectChatStore = useSearchParamFromDirectChatStore();
    useEffect(() => {
      if (!searchParamFromDirectChatStore.search) return;
      const rawAction = searchParamFromDirectChatStore.search.category;
      /**
       * The parameter from search is in camelCase, but the action in the store is
       * in kebab-case.
       */
      const kebabCasedAction = kebabCase(rawAction);
      const parsedActionResult = actionSchema.safeParse(kebabCasedAction);
      if (parsedActionResult.success) {
        searchController.setState({
          search: {
            action: parsedActionResult.data,
            query: searchParamFromDirectChatStore.search.query,
          },
        });
      }
    }, [searchParamFromDirectChatStore.search]);
    return null;
  });
  return SyncSearchFromDirectChat;
})();

const SearchControllerProvider = memo(function SearchControllerProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <>
      <SyncSearchFromDirectChat />
      {children}
    </>
  );
});

export { SearchControllerProvider };
