import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import { throttle } from "lodash-es";
import {
  type ElementRef,
  type FC,
  type ReactNode,
  type Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { mergeRefs } from "react-merge-refs";
import type { T } from "ts-toolbelt";
import type { Writable } from "type-fest";

import { Card } from "@/components/Card";
import { FlexLayout } from "@/components/Card/FlexLayout";
import { styles, throttleMs } from "@/components/Card/RwdFlexCard/utils";
import { Divider } from "@/components/Divider";

const size = 2;
type Size = typeof size;

type LayoutComponentProps = {
  refs: T.Repeat<Ref<ElementRef<"div">>, Size>;
};

declare namespace RwdFlexCard_2 {
  export type Ref = ElementRef<typeof Card>;
  export type Props = Omit<ComponentProps<typeof Card>, "children"> & {
    items: T.Repeat<ReactNode, Size>;
  };
}

const MdLayout: FC<LayoutComponentProps> = ({ refs }) => {
  return (
    <FlexLayout.Row>
      <Card.NoPadding.Content css={styles.portal} ref={refs[0]} />
      <Divider type="vertical" />
      <Card.NoPadding.Content css={styles.portal} ref={refs[1]} />
    </FlexLayout.Row>
  );
};

const SmLayout: FC<LayoutComponentProps> = ({ refs }) => {
  return (
    <>
      <FlexLayout.Row>
        <Card.NoPadding.Content css={styles.portal} ref={refs[0]} />
      </FlexLayout.Row>
      <Divider />
      <FlexLayout.Row>
        <Card.NoPadding.Content css={styles.portal} ref={refs[1]} />
      </FlexLayout.Row>
    </>
  );
};

type LayoutSize = "sm" | "md";
const getLayoutSize = (width: number): LayoutSize => {
  if (width >= 600) return "md";
  else return "sm";
};

/**
 * Responsive card layout with 2 columns in the first row and 3 columns in the
 * second row. The layout changes based on the width of the card.
 */
const RwdFlexCard_2 = forwardRef<RwdFlexCard_2.Ref, RwdFlexCard_2.Props>(
  function RwdFlexCard_2({ items, ...props }, ref) {
    const localRef = useRef<ElementRef<typeof Card>>(null);
    const mergedRef = useMemo(() => mergeRefs([ref, localRef]), [ref]);
    const [layoutSize, setLayoutSize] = useState<LayoutSize>("sm");
    const portal_0 = usePortal();
    const portal_1 = usePortal();

    useEffect(function responsiveCardLayout() {
      if (!localRef.current) return;
      let cancel = false;
      const rootElement = localRef.current;

      const throttleResizeHandler = throttle(function resizeHandler() {
        if (cancel) return;
        const size = getLayoutSize(rootElement.clientWidth);
        setLayoutSize(size);
      }, throttleMs);

      const resizeObserver = new ResizeObserver(throttleResizeHandler);
      resizeObserver.observe(rootElement);
      return function cleanup() {
        cancel = true;
        resizeObserver.disconnect();
      };
    }, []);

    const LayoutComponent = useMemo(
      () => (layoutSize === "sm" ? SmLayout : MdLayout),
      [layoutSize],
    );
    const refs = useMemo(() => {
      const tuple = [portal_0.setOuterEl, portal_1.setOuterEl] as const;
      const readonlyRemoved = tuple as Writable<typeof tuple>;
      return readonlyRemoved;
    }, [portal_0, portal_1]);
    return (
      <>
        <Card.NoPadding {...props} ref={mergedRef}>
          <LayoutComponent refs={refs} />
        </Card.NoPadding>
        {createPortal(items[0], portal_0.innerEl)}
        {createPortal(items[1], portal_1.innerEl)}
      </>
    );
  },
);

export { RwdFlexCard_2 };
