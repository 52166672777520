import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { TabsProps } from "antd";
import { type FC, type ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Table } from "@/components/Table";
import { PageTabs } from "@/components/Tabs";
import { SubText } from "@/components/Typography";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { TeamRoutingRuleTypeLabel } from "@/resources/team/TeamRoutingRuleTypeLabel";
import { compileToString } from "@/router/utils/compileTo";
import { Assignment } from "@/routes/Settings/Teams/pages/TeamList/Assignment";
import { DeleteButton } from "@/routes/Settings/Teams/pages/TeamList/DeleteTeam";
import { EditLinkButton } from "@/routes/Settings/Teams/pages/TeamList/EditLinkButton";
import { MemberCount } from "@/routes/Settings/Teams/pages/TeamList/MemberCount";
import { ViewLinkButton } from "@/routes/Settings/Teams/pages/TeamList/ViewLinkButton";
import { useUserPermission } from "@/shared/application/user";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

const ViewEditLinkButton = memo(function ViewEditLinkButton({
  teamId,
}: {
  teamId: CantataTypes["Team"]["id"];
}) {
  const { hasPermission } = useUserPermission();
  const userCanEdit = hasPermission("editTeam");
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.team.useList({
    params: {
      orgId,
    },
  });

  if (query.isLoading)
    return <NarrowIconButton size="middle" iconSize="small" loading disabled />;

  if (query.isError) {
    return (
      <Alert
        type="error"
        message={inspectMessage`query.error: ${query.error}`}
      />
    );
  }

  const thisTeam = query.data.teams.find((team) => team.id === teamId);
  if (!thisTeam) {
    return (
      <Alert
        type="error"
        message={inspectMessage`Team not found, teamId: ${teamId}`}
      />
    );
  }

  return userCanEdit && thisTeam.editable ? (
    <EditLinkButton teamId={teamId} />
  ) : (
    <ViewLinkButton teamId={teamId} />
  );
});

const TABLE_SCROLL_WIDTH = 1000;

const useColumns = ({
  search,
  dataSource,
}: Pick<
  Table.UseTableSearchOptions<CantataTypes["Team"]>,
  "search" | "dataSource"
>) => {
  const isSalesBindingEnabled = useFeatureFlag("salesBinding");
  const localeCompare = useLocaleCompare();
  const { hasPermission } = useUserPermission();

  const userCanDelete = hasPermission("deleteTeam");

  const options = useMemo<Table.UseTableSearchOptions<CantataTypes["Team"]>>(
    () => ({
      search,
      dataSource,
      fuseOptions: {
        keys: ["name"],
      },
      columns: [
        {
          dataIndex: "name",
          fixed: "left",
          title: () => (
            <SubText>
              <Trans i18nKey="team.listPage.column.name" />
            </SubText>
          ),
          sorter: (a, b) => localeCompare(a.name, b.name),
          sortDirections: Table.sortDirections.ascendFirst,
          defaultSortOrder: "ascend",
        },
        {
          dataIndex: "description",
          width: "25%",
          title: () => (
            <SubText>
              <Trans i18nKey="team.listPage.column.description" />
            </SubText>
          ),
        },
        {
          dataIndex: "id",
          title: () => (
            <SubText>
              <Trans i18nKey="team.listPage.column.count" />
            </SubText>
          ),
          render: (teamId) => <MemberCount teamId={teamId} />,
        },
        {
          dataIndex: "routingRule",
          title: () => (
            <SubText>
              <Trans i18nKey="team.listPage.column.routingRule" />
            </SubText>
          ),
          render: (routingRule) => (
            <TeamRoutingRuleTypeLabel type={routingRule} />
          ),
        },
        ...((!isSalesBindingEnabled
          ? []
          : [
              {
                title: (
                  <SubText>
                    <Trans i18nKey={"myProfile.qrCode"} />
                  </SubText>
                ),
                key: "qrcode",
                dataIndex: "id",
                render: (_, team) => <Assignment teamId={team.id} />,
              },
            ]) satisfies Table.ColumnsType<CantataTypes["Team"]>),
        {
          render: (team) => {
            return (
              <Flex
                css={css`
                  width: 56px;
                  flex-direction: row;
                  justify-content: flex-end;
                  gap: 16px;

                  svg {
                    color: ${theme.colors.neutral005};

                    &:hover,
                    &:active {
                      color: ${theme.colors.neutral006};
                    }

                    &:active {
                      color: ${theme.colors.neutral007};
                    }

                    &[disabled] {
                      color: ${theme.colors.neutral001};
                    }
                  }
                `}
              >
                <ViewEditLinkButton teamId={team.id} />
                {!userCanDelete ? null : (
                  <DeleteButton teamId={team.id} teamName={team.name} />
                )}
              </Flex>
            );
          },
        },
      ] satisfies Table.ColumnsType<CantataTypes["Team"]>,
    }),
    [dataSource, isSalesBindingEnabled, localeCompare, search, userCanDelete],
  );

  return Table.useTableSearch<CantataTypes["Team"]>(options);
};

type TeamListQueryHook = typeof cantata.team.useList;

const Teams = memo(function Teams({
  useTeamList,
}: {
  useTeamList: TeamListQueryHook;
}) {
  const { hasPermission } = useUserPermission();
  const userCanCreate = hasPermission("createTeam");
  const orgId = useActiveOrgIdStore((state) => state.value);
  const createTeamLink = compileToString("/settings/teams/create");
  const query = useTeamList({
    params: {
      orgId,
    },
  });
  const teams = useMemo(
    () => (query.isSuccess ? query.data.teams ?? [] : []),
    [query.data?.teams, query.isSuccess],
  );
  const columnsOptions = useMemo<Parameters<typeof useColumns>[0]>(
    () => ({ dataSource: teams }),
    [teams],
  );
  const { columns, searchInput } = useColumns(columnsOptions);

  return (
    <div>
      <Flex
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 16,
          gap: 16,
          flexWrap: "wrap",
        }}
      >
        {searchInput}
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            gap: "inherit",
          }}
        >
          {!userCanCreate ? null : (
            <Button
              data-test="create-team-button"
              type="primary"
              href={createTeamLink}
            >
              <Trans i18nKey="team.listPage.create" />
            </Button>
          )}
        </div>
      </Flex>
      <Table
        rowKey="id"
        loading={query.isLoading}
        scroll={{ x: TABLE_SCROLL_WIDTH }}
        columns={columns}
        dataSource={teams}
        locale={{ emptyText: <Trans i18nKey="common.noData" /> }}
      />
    </div>
  );
});

const tabKeysSchema = z.enum(["all-teams", "my-teams"]);

type TabKeys = z.infer<typeof tabKeysSchema>;
type Tab = NonNullable<TabsProps["items"]>[number] & { key: TabKeys };

function useSetupTeamListTabActiveKeyState() {
  const activeKeyState = useState<TabKeys>("all-teams");
  return activeKeyState;
}

const TeamListTabActiveKeyStateContext = createContext<
  ReturnType<typeof useSetupTeamListTabActiveKeyState>
>({
  name: "TeamListTabActiveKeyStateContext",
});

/**
 * To memoize the active tab key when navigating between sub-pages, such as
 * redirecting to the team list page after creating a team, we need to wrap the
 * parent route (including these sub-pages) with this provider.
 */
const TeamListTabActiveKeyStateProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const value = useSetupTeamListTabActiveKeyState();
  return (
    <TeamListTabActiveKeyStateContext.Provider value={value}>
      {children}
    </TeamListTabActiveKeyStateContext.Provider>
  );
};

const tabsItems = define<Tab[]>()([
  {
    key: "all-teams",
    label: <Trans i18nKey="team.listPage.tabAll.label" />,
    children: <Teams useTeamList={cantata.team.useList} />,
  },
  {
    key: "my-teams",
    label: <Trans i18nKey="team.listPage.tabMy.label" />,
    children: <Teams useTeamList={cantata.team.useListMine} />,
  },
]);

function useTeamListTabActiveKeyState() {
  return TeamListTabActiveKeyStateContext.useContext();
}

const TeamList: FC = () => {
  const [activeKey, setActiveKey] = useTeamListTabActiveKeyState();
  const handleTabChange = useHandler<
    ComponentProps<typeof PageTabs>["onChange"]
  >(function handleTabChange(key: string) {
    setActiveKey(tabKeysSchema.parse(key));
  });

  return (
    <PageTabs
      data-test="team-list-tabs"
      activeKey={activeKey}
      onChange={handleTabChange}
      items={tabsItems}
      destroyInactiveTabPane
    />
  );
};

const WrappedTeamList: FC = () => {
  const { t } = useTranslation();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header>{t("menu.team")}</MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <MainLayout.Section>
            <TeamList />
          </MainLayout.Section>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
};

export { WrappedTeamList as TeamList, TeamListTabActiveKeyStateProvider };
