import { breakpointApi } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";

import { GteTabletLandscape } from "@/layout/MainLayout/Outer/GteTabletLandscape";
import { LteMobile } from "@/layout/MainLayout/Outer/LteMobile";
import type { OuterProps } from "@/layout/MainLayout/Outer/types";

const Outer: FC<OuterProps> = (props) => {
  const isGteTabletLandscape = breakpointApi.useGte("tabletLandscape");
  const Component = useMemo(
    () => (isGteTabletLandscape ? GteTabletLandscape : LteMobile),
    [isGteTabletLandscape],
  );
  return <Component {...props} />;
};

export { Outer };
