import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { type ElementRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MotifIcon } from "@/components/MotifIcon";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  DroppingZone: css({
    position: "absolute",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    background: `${theme.colors.white}ee`,
    color: theme.colors.neutral008,
    fontSize: "0.75rem",
    fontWeight: 500,
    gap: "1rem",
    inset: "1em",
    textAlign: "center",
    pointerEvents: "none",
    overflow: "hidden",
  }),
  icon: css({
    color: theme.colors.blue005,
    fontSize: "48px",
  }),
});

namespace DroppingZone {
  export type Ref = ElementRef<"div">;
  export interface OwnProps {}
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const DroppingZoneInternal = forwardRef<DroppingZone.Ref, DroppingZone.Props>(
  function DroppingZoneInternal(props, ref) {
    const { t } = useTranslation();
    const defaultChildren = useMemo(
      () => (
        <>
          <MotifIcon css={styles.icon} un-i-motif="file_add" />
          <div>{t("chat.attachment.droparea.helperText")}</div>
        </>
      ),
      [t],
    );
    return (
      <div
        css={styles.DroppingZone}
        ref={ref}
        {...{ children: defaultChildren }}
        {...props}
      />
    );
  },
);

/**
 * UI displays when the user is dragging files to drop.
 */
const DroppingZone = Object.assign(DroppingZoneInternal, {
  styles,
});

assignDisplayName(DroppingZone, "DroppingZone");

export { DroppingZone };
