import type { FC, ReactNode } from "react";
import { Navigate as UnsafeNavigate, useSearchParams } from "react-router-dom";

import { SEARCH_PARAM_KEY_LOGIN_REDIRECT } from "@/appConstant";
import { BarLoading } from "@/components/Loading/BarLoading";
import { useAuthStatus } from "@/shared/application/authenticate";

/**
 * Need to be signed out to view the content.
 */
const AnonymousOnly: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get(SEARCH_PARAM_KEY_LOGIN_REDIRECT) ?? "/";

  const authStatus = useAuthStatus();
  if (authStatus.isLoading) return <BarLoading />;
  if (authStatus.isSignedIn) return <UnsafeNavigate replace to={redirect} />;
  return children;
};

export { AnonymousOnly };
