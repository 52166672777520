import { Navigate } from "@/router/utils";
import { defineChildRouteObject } from "@/router/utils/defineChildRouteObject";
import { Contacts } from "@/routes/Insights/Contacts";
import { Conversation } from "@/routes/Insights/Conversation";
import { Efficiency } from "@/routes/Insights/Efficiency";
import { Outer } from "@/routes/Insights/Outer";
import { Teams } from "@/routes/Insights/Teams";

const insightsObjectRoute = defineChildRouteObject({
  element: <Outer />,
  children: [
    {
      index: true,
      element: <Navigate replace to="/insights/contacts" />,
    },
    {
      path: "contacts",
      element: <Contacts />,
    },
    {
      path: "conversation",
      element: <Conversation />,
    },
    {
      path: "efficiency",
      element: <Efficiency />,
    },
    {
      path: "teams",
      element: <Teams />,
    },
  ],
});

export { insightsObjectRoute };
