import {
  FIREBASE_LOCALE_STORAGE_API_KEY,
  FIREBASE_LOCALE_STORAGE_APP_ID,
  FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN,
  FIREBASE_LOCALE_STORAGE_PROJECT_ID,
  FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET,
} from "@zeffiroso/env";
import type { FirebaseOptions } from "firebase/app";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const firebaseAppNames = ["locale"] as const;
type FirebaseAppName = (typeof firebaseAppNames)[number];

type Credentials = Array<{
  key: FirebaseAppName;
  name: FirebaseAppName;
  options: FirebaseOptions;
}>;

const clientCredentials: Credentials = [
  {
    /** locale */
    key: "locale",
    name: "locale",
    options: {
      apiKey: FIREBASE_LOCALE_STORAGE_API_KEY,
      authDomain: FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN,
      projectId: FIREBASE_LOCALE_STORAGE_PROJECT_ID,
      storageBucket: FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET,
      appId: FIREBASE_LOCALE_STORAGE_APP_ID,
    },
  },
];

class FirebaseTools {
  constructor() {
    this._initializeFirebaseApps();
  }

  /**
   * Initialize Firebase apps
   */
  private _initializeFirebaseApps = () => {
    if (getApps().length > 0) return;

    clientCredentials.forEach((credential) => {
      const { name, options } = credential;
      initializeApp(options, name);
    });
  };

  /**
   * Get designated Firebase app
   * @param appName designated Firebase app name
   * @returns Firebase app
   */
  private _getApp = (appName: FirebaseAppName) => getApp(appName);

  /**
   * Get Firebase Storage reference
   */
  private _getStorageRef = (appName: FirebaseAppName, filePath: string) => {
    const app = this._getApp(appName);
    const storage = getStorage(app);
    const storageRef = ref(storage, filePath);

    return storageRef;
  };

  /**
   * Get a downloadable file URL
   * @param appName designated Firebase app name
   * @param filePath file path in firebase storage
   * @returns downloadable URL
   */
  public getDownloadUrl = async (
    appName: FirebaseAppName,
    filePath: string,
  ) => {
    const storageRef = this._getStorageRef(appName, filePath);
    const url = await getDownloadURL(storageRef);

    return url;
  };
}

const firebaseTools = new FirebaseTools();

export { firebaseTools };
export type { FirebaseAppName };
