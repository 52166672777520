import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { type FC, type ReactNode, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";

function useSetupQueriesContext() {
  const orgId = useActiveOrgIdStore((state) => state.value);

  const teamsQuery = cantata.team.useList(
    {
      params: {
        orgId,
      },
    },
    {
      select: (data) => data.teams,
      useErrorBoundary: true,
      suspense: true,
    },
  );

  const queriesContextValue = useMemo(
    () => ({
      teams: teamsQuery,
    }),
    [teamsQuery],
  );
  return queriesContextValue;
}

const TeamsQueryContext =
  createQueriesContext<ReturnType<typeof useSetupQueriesContext>>();

const TeamsQueryContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const queries = useSetupQueriesContext();
  return (
    <TeamsQueryContext.Provider queries={queries}>
      {children}
    </TeamsQueryContext.Provider>
  );
};

const teamsQueriesContext = {
  Provider: TeamsQueryContextProvider,
  useData: TeamsQueryContext.useData,
};

export { teamsQueriesContext };
