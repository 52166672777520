import type { QueryOptions } from "@zeffiroso/utils/zodios/types";
import { Skeleton } from "antd";
import { type FC, useCallback } from "react";

import { cantata, type cantataClient } from "@/cantata";
import { Alert } from "@/components/Alert";

declare namespace Api {
  export type TData = Awaited<ReturnType<typeof cantataClient.user.list>>;
  export type TQueryFnData = number;
  export type TQueryOptions = QueryOptions<TData, TQueryFnData>;
  export type User = Awaited<
    ReturnType<typeof cantataClient.user.list>
  >["users"][number];
  export interface UserOrgUsersCountQueryOptions extends TQueryOptions {
    orgId: number;
    excludeNonInternal?: boolean;
    excludeInternal?: boolean;
  }
  export interface Props extends UserOrgUsersCountQueryOptions {}
}

function useOrgUsersCountQuery({
  orgId,
  excludeNonInternal,
  excludeInternal,
  ...options
}: Api.UserOrgUsersCountQueryOptions) {
  const select = useCallback<NonNullable<Api.TQueryOptions["select"]>>(
    (data) => {
      return data.users.filter((user) => {
        if (excludeNonInternal && !user.isInternalUser) return false;
        if (excludeInternal && user.isInternalUser) return false;
        return true;
      }).length;
    },
    [excludeInternal, excludeNonInternal],
  );
  const query = cantata.user.useList(
    {
      params: {
        orgId,
      },
    },
    { select, ...options },
  );

  return query;
}

/**
 * A component that displays the number of users in the organization.
 */
const UsersCount: FC<Api.Props> = (props) => {
  const query = useOrgUsersCountQuery(props);

  if (query.isError)
    return <Alert type="error" message={query.error.message} />;

  if (query.isLoading) return <Skeleton.Input size="small" />;

  return query.data;
};

const Api = Object.assign(UsersCount, { useQuery: useOrgUsersCountQuery });

export { Api as UsersCount };
