import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { Overwrite } from "@mui/types";
import type { ElementRef } from "react";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import type { PillVariant } from "@/components/Pill";
import { Pill } from "@/components/Pill";
import { fakeT } from "@/shared/g11n/fakeT";

type UserStatus = CantataTypes["User"]["status"];

const userStatusMap: Record<UserStatus, PillVariant> = {
  invited: "pending",
  active: "fulfilled",
  suspend: "rejected",
  deleted: "disabled",
};

const userStatusTranslationKey: Record<UserStatus, string> = (() => {
  const t = fakeT;
  return {
    invited: t("common.inviting"),
    suspend: t("common.suspend"),
    active: t("common.enable"),
    deleted: t("common.disabled"),
  };
})();

function getUserStatusPillVariant(status: UserStatus): PillVariant {
  return userStatusMap[status];
}

function getUserStatusTranslationKey(status: UserStatus): string {
  return userStatusTranslationKey[status];
}

namespace UserStatusLabel {
  export interface Props
    extends Omit<
      Overwrite<
        ComponentProps<typeof Pill>,
        {
          status: UserStatus;
        }
      >,
      "children"
    > {}
  export type Ref = ElementRef<typeof Pill>;
}

const UserStatusLabelInternal = forwardRef<
  UserStatusLabel.Ref,
  UserStatusLabel.Props
>(function UserStatusLabel({ status, ...props }, ref) {
  const { t } = useTranslation();
  return (
    <Pill
      data-test={`user-status-${status}-label`}
      $variant={getUserStatusPillVariant(status)}
      {...props}
      ref={ref}
    >
      {t(getUserStatusTranslationKey(status))}
    </Pill>
  );
});

const UserStatusLabel = Object.assign(UserStatusLabelInternal, {
  userStatusMap,
  getUserStatusPillVariant,
  getUserStatusTranslationKey,
});

export { UserStatusLabel };
