import { useTranslation } from "react-i18next";

import {
  type AvailableLanguage,
  formatLangCode,
} from "@/shared/hooks/formatLangCode";

function useLanguage(): AvailableLanguage {
  const { i18n } = useTranslation();
  return formatLangCode(i18n.language);
}

export { useLanguage };
