import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const ArrowDownIcon = generateIcon(function ArrowDownIcon() {
  return (
    <svg viewBox="0 0 10 6" height="1em" width="1em" fill="currentColor">
      <path d="m5.63 5.733 4.108-4.182a.92.92 0 0 0 0-1.284.88.88 0 0 0-1.261 0L4.996 3.802 1.523.267a.88.88 0 0 0-1.261 0 .92.92 0 0 0 0 1.284l4.107 4.182a.88.88 0 0 0 1.262 0Z" />
    </svg>
  );
});
