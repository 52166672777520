import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps, FC } from "react";
import { useMemo } from "react";
import type { RequireAtLeastOne } from "type-fest";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { AssigneeName } from "@/resources/assignee/AssigneeName";

type AssigneeNameByIdProps = RequireAtLeastOne<
  {
    userId: CantataTypes["User"]["id"];
    teamId: CantataTypes["Team"]["id"];
  },
  "teamId" | "userId"
>;

type Variant = "team" | "user-in-team" | "user";

const userFallback: NonNullable<ComponentProps<typeof AssigneeName>["user"]> = {
  name: "",
  email: "",
};
const teamFallback: NonNullable<ComponentProps<typeof AssigneeName>["team"]> = {
  name: "",
};

const AssigneeNameById: FC<AssigneeNameByIdProps> = ({ userId, teamId }) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const teamsQuery = cantata.team.useList(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const usersQuery = cantata.user.useList(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );

  const variant = useMemo<Variant>(
    function getVariant() {
      if (teamId && !userId) return "team";
      if (!teamId && userId) return "user";
      if (teamId && userId) return "user-in-team";
      throw new Error("Require at least one of team or user");
    },
    [teamId, userId],
  );

  if (!teamsQuery.isSuccess || !usersQuery.isSuccess) return null;

  const user =
    usersQuery.data.users.find((user) => user.id === userId) ?? userFallback;

  const team =
    teamsQuery.data.teams.find((team) => team.id === teamId) ?? teamFallback;

  switch (variant) {
    case "team":
      return <AssigneeName team={team} />;
    case "user":
      return <AssigneeName user={user} />;
    case "user-in-team":
      return <AssigneeName team={team} user={user} />;
    default:
      variant satisfies never;
      throw new Error(inspectMessage`Invalid variant: ${variant}`);
  }
};

const Wrapped: FC<AssigneeNameByIdProps> = (props) => {
  return (
    <ErrorBoundary.Alert>
      <AssigneeNameById {...props} />
    </ErrorBoundary.Alert>
  );
};

export { Wrapped as AssigneeNameById };
