import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { EditUserForm } from "@/routes/Settings/Users/People/EditUser/EditUserForm";
import { pageInfoUtils } from "@/routes/Settings/Users/People/EditUser/pageInfoUtils";

const EditUser: FC = () => {
  const { t } = useTranslation();
  const pageInfo = pageInfoUtils.usePageInfo();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header
        breadcrumbItems={[
          {
            title: t("organization.peopleSetting"),
            to: "/settings/users",
          },
          {
            title: t("organization.editMemberInfo"),
          },
        ]}
      >
        {t("organization.editMemberInfo")}
      </MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <MainLayout.Section>
            <EditUserForm userId={pageInfo.params.userId} />
          </MainLayout.Section>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
};

const EditUserWithPageInfo: FC = () => {
  return (
    <pageInfoUtils.Provider>
      <EditUser />
    </pageInfoUtils.Provider>
  );
};

export { EditUserWithPageInfo as EditUser };
