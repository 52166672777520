import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import JSZip from "jszip";
import { merge } from "lodash-es";
import PQueue from "p-queue";

namespace zipFiles {
  /**
   * Copied from the `jszip` package because it's not exported, and `stream`
   * cannot be used in the browser.
   */
  export interface InputByType {
    base64: string;
    string: string;
    text: string;
    binarystring: string;
    array: number[];
    uint8array: Uint8Array;
    arraybuffer: ArrayBuffer;
    blob: Blob;
  }

  /**
   * Copied from the `jszip` package because it's not exported, and `nodebuffer`
   * cannot be used in the browser.
   */
  export interface OutputByType {
    base64: string;
    string: string;
    text: string;
    binarystring: string;
    array: number[];
    uint8array: Uint8Array;
    arraybuffer: ArrayBuffer;
    blob: Blob;
  }

  /**
   * Same spec as parameters of `JSZip.file` method.
   */
  export interface InputOption {
    path: string;
    data:
      | InputByType[keyof InputByType]
      | Promise<InputByType[keyof InputByType]>;
    options?: JSZip.JSZipFileOptions;
  }
  export type Options<TOutputType extends JSZip.OutputType = "blob"> = {
    /**
     * An array of objects representing the files to be zipped.
     */
    input: Array<InputOption>;
    /**
     * Same spec as parameters of `JSZip.generateAsync` method. Defaults to "blob".
     */
    output?: Partial<JSZip.JSZipGeneratorOptions<TOutputType>>;
  };
}

/**
 * A queue to limit the number of concurrent zip operations.
 */
const pQueue = new PQueue({
  concurrency: 1,
});

/**
 * Compresses multiple files into a single zip archive.
 */
async function internalZipFiles<
  TOutputType extends keyof zipFiles.OutputByType = "blob",
>(
  options: zipFiles.Options<TOutputType>,
): Promise<zipFiles.OutputByType[TOutputType]> {
  const zip = new JSZip();
  options.input.forEach(({ path, data, options }) => {
    zip.file(path, data, options);
  });
  const mergedOutputOptions = merge(
    {},
    {
      type: "blob",
    } satisfies JSZip.JSZipGeneratorOptions<JSZip.OutputType>,
    options.output,
  ) satisfies JSZip.JSZipGeneratorOptions<JSZip.OutputType> as JSZip.JSZipGeneratorOptions<TOutputType>;
  return zip.generateAsync<TOutputType>(mergedOutputOptions);
}

const zipFiles: typeof internalZipFiles = async (...args) => {
  const result = await pQueue.add(async () => internalZipFiles(...args));
  if (!result) {
    throw new Error(
      inspectMessage`Failed to generate zip file, result: ${result}, options: ${args}`,
    );
  }
  return result;
};

export { zipFiles };
