import { makeApi } from "@zodios/core";
import { z } from "zod";

import { FbAttachmentTypeSchema } from "../models";

const api = makeApi([
  /**
   * Directs Facebook to prepare the attachment from the provided URL in
   * readiness for sending.
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/202311-CAAC-FB-integrate-170397cbc5004166b32bc851890d15ca?pvs=4#7a558982cd2d49b985fadeacb4223c3a)
   */
  {
    alias: "create",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/channels/:channelId/fb-attachment",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          type: FbAttachmentTypeSchema,
          url: z.string(),
        }),
      },
    ],
    response: z.object({
      attachmentId: z.string(),
    }),
  },
]);

export { api };
