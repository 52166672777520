import type { CantataTypes } from "@/cantata/types";

/**
 * CDH don't unify the member type for line group.
 *
 * Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1725616975991739?thread_ts=1725527279.250359&cid=C02R6ETJMEY)
 */
const isUnifiedMemberType = (
  member: Pick<CantataTypes["Member"], "type">,
): boolean => member.type !== "line_group";

export { isUnifiedMemberType };
