import { useMemo } from "react";
import {
  matchPath as unsafe_matchPath,
  type ParamParseKey,
  type PathMatch,
  type PathPattern,
  useLocation,
} from "react-router-dom";

import type { BasePathName, ValidPathString } from "@/router/types";

/**
 * Strongly typed version of `matchPath`.
 *
 * ```ts
 * const location = useLocation();
 * const match = matchPath("/teams/:teamId/view", location.pathname);
 * ```
 */
function matchPath<
  ParamKey extends ParamParseKey<Path>,
  Path extends ValidPathString | BasePathName,
>(
  pattern: PathPattern<Path> | Path,
  pathname: string,
): PathMatch<ParamKey> | null {
  return unsafe_matchPath(pattern, pathname);
}

function useMatchCurrentPath() {
  const location = useLocation();
  const matchCurrentPath = useMemo(
    () =>
      (() => {
        function matchCurrentPath<Path extends ValidPathString | BasePathName>(
          pattern: PathPattern<Path> | Path,
        ): PathMatch<ParamParseKey<Path>> | null {
          return matchPath(pattern, location.pathname);
        }
        return matchCurrentPath;
      })(),
    [location.pathname],
  );
  return matchCurrentPath;
}

export { matchPath, useMatchCurrentPath };
