import { memo } from "@chatbotgang/etude/react/memo";
import { Skeleton } from "antd";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";

export const MemberCount = memo(function TeamListMemberCount({
  teamId,
}: {
  teamId: number;
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.team.useGetTeamUsersCount({
    params: {
      orgId,
      teamId,
    },
  });

  if (query.isLoading || query.isError)
    return <Skeleton.Button size="small" active />;
  return <>{query.data}</>;
});
