import { LoadingOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import {
  DisabledContextProvider,
  useMergeFormDisabled,
} from "@/components/Form/DisabledContext";
import { Modal } from "@/components/Modal";
import { useMessage } from "@/internal/message";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    maxWidth: 776,
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  title: css({
    fontSize: "1.25rem",
    fontWeight: 500,
    color: theme.colors.neutral010,
    display: "flex",
    gap: 8,
    alignItems: "center",
  }),
  tag: css({
    background: theme.colors.blue006,
    borderRadius: 20,
    color: theme.colors.neutral001,
    fontSize: "0.75rem",
    padding: "2px 8px",
  }),
  note: css({
    color: theme.colors.neutral007,
    fontSize: "0.875rem",
  }),
});

const EnableModal: FC<ComponentProps<typeof Modal>> = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={<Trans i18nKey="organization.2fa.enableModal.title" />}
      okText={t("organization.2fa.enableButton.label")}
      {...props}
    >
      <Trans i18nKey="organization.2fa.enableModal.description" />
    </Modal>
  );
};

const DisableModal: FC<ComponentProps<typeof Modal>> = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={<Trans i18nKey="organization.2fa.disableModal.title" />}
      okText={t("organization.2fa.disableButton.label")}
      {...props}
    >
      <Trans i18nKey="organization.2fa.disableModal.description" />
    </Modal>
  );
};

const OrgTwoFactor: FC = () => {
  const mergeFormDisabled = useMergeFormDisabled();
  const { t } = useTranslation();
  const [openEnableModal, toggleOpenEnableModal] = useSwitch();
  const [openDisableModal, toggleOpenDisableModal] = useSwitch();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.org.useGetById(
    {
      params: {
        orgId,
      },
    },
    {
      useErrorBoundary: true,
      suspense: true,
    },
  );

  const mutation = cantata.org.usePartialUpdate({
    params: {
      orgId,
    },
  });

  const message = useMessage();

  const handleEnable = useHandler(function handleEnable() {
    if (!query.isSuccess) return;
    if (mutation.isLoading) return;

    mutation.mutate(
      {
        enableTwoFactor: true,
      },
      {
        onSuccess() {
          message.success(t("organization.2fa.enabled.message"));
          toggleOpenEnableModal.off();
        },
      },
    );
  });

  const handleDisable = useHandler(function handleDisable() {
    if (!query.isSuccess) return;
    if (mutation.isLoading) return;

    mutation.mutate(
      {
        enableTwoFactor: false,
      },
      {
        onSuccess() {
          message.success(t("organization.2fa.disabled.message"));
          toggleOpenDisableModal.off();
        },
      },
    );
  });

  const onCancelEnableModal = useHandler(function onCancelEnableModal() {
    if (mutation.isLoading) return;
    toggleOpenEnableModal.off();
  });

  const onCancelDisableModal = useHandler(function onCancelDisableModal() {
    if (mutation.isLoading) return;
    toggleOpenDisableModal.off();
  });

  const disabled = mergeFormDisabled(mutation.isLoading);

  if (!query.isSuccess) return null;

  return (
    <DisabledContextProvider disabled={disabled}>
      <div css={styles.root}>
        <div css={styles.title}>
          <Trans i18nKey="organization.2fa.header" />
          <div>
            <div css={styles.tag}>New</div>
          </div>
        </div>
        <div css={styles.note}>
          <Trans i18nKey="organization.2fa.desc" />
        </div>
        {query.data.enableTwoFactor ? (
          <>
            <Alert
              backgroundVariant="transparent"
              type="success"
              message={<Trans i18nKey="organization.2fa.enabledInfo" />}
            />
            <div>
              <Button onClick={toggleOpenDisableModal}>
                <Trans i18nKey="organization.2fa.disableButton.label" />
              </Button>
            </div>
          </>
        ) : (
          <>
            <Alert
              type="warning"
              message={<Trans i18nKey="organization.2fa.disabledWarning" />}
            />
            <div>
              <Button
                crescendolab-selector="organization-twoFactor-enableButton"
                type="primary"
                ghost
                onClick={toggleOpenEnableModal.on}
                icon={mutation.isLoading ? <LoadingOutlined /> : undefined}
              >
                <Trans i18nKey="organization.2fa.enableButton.label" />
              </Button>
            </div>
          </>
        )}
        <EnableModal
          open={openEnableModal}
          onCancel={onCancelEnableModal}
          onOk={handleEnable}
          okButtonProps={{
            icon: mutation.isLoading ? <LoadingOutlined /> : undefined,
          }}
        />
        <DisableModal
          open={openDisableModal}
          onCancel={onCancelDisableModal}
          onOk={handleDisable}
          okButtonProps={{
            danger: true,
            icon: mutation.isLoading ? <LoadingOutlined /> : undefined,
          }}
        />
      </div>
    </DisabledContextProvider>
  );
};

export { OrgTwoFactor };
