// eslint-disable-next-line no-restricted-imports -- Use this function instead of inline Icon component.
import Icon from "@ant-design/icons";
// eslint-disable-next-line no-restricted-imports -- Inherit types which are not exported by `@ant-design/icons`.
import type { IconComponentProps } from "@ant-design/icons/es/components/Icon";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { getDisplayName } from "@chatbotgang/etude/react/getDisplayName";
import { omit } from "lodash-es";
import type { ComponentProps, ComponentType, ElementRef } from "react";
import { useMemo } from "react";

import type { MotifIcon } from "@/components/MotifIcon";

const emptyProps = Object.freeze({});
type EmptyProps = typeof emptyProps;

type IconComponentPropsWithIconParams<P extends EmptyProps> =
  IconComponentProps & (EmptyProps extends P ? EmptyProps : { iconProps: P });

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 *
 * A wrapper for `@ant-design/icons`' `Icon` component.
 *
 * @example
 * ```tsx
 * import { generateIcon } from './util';
 *
 * const MyIcon = generateIcon<{
 *   foo: string;
 * }>(function MySvg({ foo }) {
 *   //                ^?string
 *   return (
 *     <svg viewBox="0 0 20 20">
 *         <path
 *           // ...
 *           fill="currentColor"
 *         />
 *     </svg>
 *   );
 * });
 *
 * export default MyIcon;
 *
 * // Usage:
 *
 * <MyIcon foo="bar" />
 * ```
 *
 * @param component SVG component to wrap.
 * @returns Icon component.
 */
export function generateIcon<P extends EmptyProps = EmptyProps>(
  component: ComponentType<P>,
) {
  const Component = component;
  const iconPropsContext = createContext<P>();
  const componentName =
    (component ? getDisplayName(component) : undefined) || "AnonymousIcon";
  const withContextComponent: IconComponentProps["component"] = function (
    props: ComponentProps<NonNullable<IconComponentProps["component"]>>,
  ) {
    const iconProps = iconPropsContext.useContext();
    return <Component {...props} {...iconProps} />;
  };
  const GeneratedIcon = forwardRef<
    ElementRef<typeof Icon>,
    IconComponentPropsWithIconParams<P>
  >(
    assignDisplayName(function (props, ref) {
      const contextValue: P =
        "iconProps" in props ? props.iconProps : (emptyProps as P);
      // eslint-disable-next-line react-hooks/rules-of-hooks -- Misinterpreted.
      const restProps = useMemo(() => omit(props, "iconProps"), [props]);
      return (
        <iconPropsContext.Provider value={contextValue}>
          <Icon {...restProps} component={withContextComponent} ref={ref} />
        </iconPropsContext.Provider>
      );
    }, `generateIcon(${componentName})`),
  );
  assignDisplayName(GeneratedIcon, `generateIcon(${componentName})`);
  return GeneratedIcon;
}
