import { number } from "@chatbotgang/etude/pitch-shifter/number";
import { memo } from "@chatbotgang/etude/react/memo";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useMatchCurrentPath } from "@/router/utils/matchPath";

type PageInfoContextValue =
  | {
      teamId: number;
      isCreating: false;
      isView: false;
      isUpdating: true;
    }
  | {
      teamId: number;
      isCreating: false;
      isView: true;
      isUpdating: false;
    }
  | {
      teamId: undefined;
      isCreating: true;
      isView: false;
      isUpdating: false;
    };

const PageInfoContext = createContext<PageInfoContextValue | undefined>(
  undefined,
);

const PageInfoProvider = memo(function PageInfoProvider({
  children,
}: {
  children: ReactNode;
}) {
  const params = useParams();
  const teamId: number | undefined = (() => {
    const param = params["teamId"];
    return !param ? undefined : number()(param);
  })();
  const matchCurrentPath = useMatchCurrentPath();
  const isView = useMemo(
    () => Boolean(matchCurrentPath("/settings/teams/view/:teamId")),
    [matchCurrentPath],
  );

  const pageInfoContextValue = useMemo<PageInfoContextValue>(() => {
    return teamId === undefined
      ? {
          teamId,
          isCreating: true,
          isView: false,
          isUpdating: false,
        }
      : isView
        ? {
            teamId,
            isCreating: false,
            isView: true,
            isUpdating: false,
          }
        : {
            teamId,
            isCreating: false,
            isView: false,
            isUpdating: true,
          };
  }, [teamId, isView]);
  return (
    <PageInfoContext.Provider key={teamId} value={pageInfoContextValue}>
      {children}
    </PageInfoContext.Provider>
  );
});

const RenderIn = memo(function RenderIn({
  children,
  to,
}: {
  children: ReactNode;
  to: keyof Pick<PageInfoContextValue, "isView" | "isCreating" | "isUpdating">;
}) {
  const pageInfo = usePageInfo();
  return <>{!pageInfo[to] ? null : children}</>;
});

function usePageInfo() {
  const pageInfoContextValue = useContext(PageInfoContext);
  if (pageInfoContextValue === undefined)
    throw new Error("usePageInfo must be used within PageInfoProvider");

  return pageInfoContextValue;
}

export { PageInfoProvider, RenderIn, usePageInfo };
