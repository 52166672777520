import { define } from "@chatbotgang/etude/util/define";
import type { CSSObject } from "@emotion/react";
import { css } from "@emotion/react";

/**
 * This is a helper to make a flex container fill its parent and inherit its flex properties.
 *
 * To set specific width:
 *
 * ```tsx
 * <div css={css`
 *   ${cssFlexInheritAndFill}
 *   flex: initial;
 *   width: 200px;`
 * } />
 * ```
 *
 * To set specific flex direction:
 *
 * ```tsx
 * <div css={css`
 *   ${cssFlexInheritAndFill}
 *   flex-direction: column;
 * `} />
 * ```
 */
const cssFlexInheritAndFill = css`
  position: relative;
  display: flex;
  width: 100%;
  min-width: 0;
  height: 100%;
  min-height: 0;
  box-sizing: border-box;
  flex: 1;
  flex-direction: inherit;
  align-items: inherit;
  justify-content: inherit;
`;

const cssInheritGap = css`
  gap: inherit;
`;

/**
 * Word wrap for defensive styling.
 */
const cssWrap = css`
  hyphens: auto;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  word-break: normal;
`;

const cssOneLine = css`
  overflow: hidden;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

function cssLineClamp<
  TLineClamp extends NonNullable<CSSObject["WebkitLineClamp"]> = number,
>(lines: TLineClamp) {
  return css([
    css`
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      line-height: normal;
    `,
    {
      WebkitLineClamp: lines,
    },
  ]);
}

interface RecordCss {
  [key: string]: ReturnType<typeof css>;
}

/**
 * Define styles with nested objects.
 *
 * ```ts
 * const styles = defineStyles({
 *   root: css({ /* ... * / }),
 *   title: css({ /* ... * / }),
 *   subtitle: css({ /* ... * / }),
 * });
 * ```
 */
const defineStyles = define<RecordCss>();

export {
  cssFlexInheritAndFill,
  cssInheritGap,
  cssLineClamp,
  cssOneLine,
  cssWrap,
  defineStyles,
};
