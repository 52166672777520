import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  Fragment,
  type ReactNode,
  useMemo,
} from "react";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";

type NumberWithUnitItem<TUnit extends string = string> = {
  unit: TUnit;
  currentValue: number;
  overflow: boolean;
};

type RenderArgs<TUnit extends string = string> = NumberWithUnitItem<TUnit> & {
  largestUnitMaxValue: number;
};

interface Options<TUnit extends string = string> {
  /**
   * The array of units to display. From the largest to the smallest.
   */
  units: Array<TUnit>;

  /**
   * The array of numbers with units to display. From the largest to the
   * smallest.
   */
  numbers: Partial<Record<TUnit, number>>;

  /**
   * If over the max unit, display n+.
   */
  largestUnitMaxValue?: number;

  /**
   * The maximum number of top units to display.
   */
  maxLargestUnitCount?: number;

  /**
   * The minimum number of top units to display.
   *
   * `false` to disable filling smaller units.
   */
  minLargestUnitCount?: number;

  /**
   * If true, allows the display of larger zero units to fill the zero for
   * larger units when `minLargestUnitCount` is set.
   */
  allowLargerZeroUnits?: boolean;
}

/**
 * Prunes smaller units based on the provided options.
 *
 * Returns null if the units array is empty.
 *
 * Returns an array of items with the number and unit to display.
 *
 * In most cases, you don't use this function directly. Use
 * `pruneSmallerUnitsToString` for string output or `PruneSmallerUnits` for
 * React component output.
 *
 * @see {@link pruneSmallerUnitsToString}
 * @see {@link PruneSmallerUnits}
 *
 *
 * Transform an array of numbers with units to display to a string.
 *
 * example:
 *
 * ```ts
 * pruneSmallerUnitsToString({
 *   units: ["d", "h", "m", "s"],
 *   numbers: { d: 1, h: 2, m: 3, s: 4 },
 *   largestUnitMaxValue: 2,
 * }); // [ { unit: "d", currentValue: 1, overflow: false }, { unit: "h", currentValue: 2, overflow: false }]
 * ```
 */
function pruneSmallerUnits<TUnit extends string = string>({
  units,
  numbers,
  largestUnitMaxValue = Number.POSITIVE_INFINITY,
  maxLargestUnitCount = units.length,
  minLargestUnitCount = 1,
  allowLargerZeroUnits = false,
}: Options<TUnit>) {
  if (units.length === 0) {
    return null;
  }
  const largestUnit: TUnit | undefined = units[0];
  const allItems = units.map((unit) => ({
    unit,
    value: numbers[unit] ?? 0,
  }));
  const normalizedItems = (() => {
    const items = allItems.map<NumberWithUnitItem<TUnit>>(
      ({ unit, value }) => ({
        unit,
        currentValue: value,
        overflow: false,
      }),
    );
    function getLargestItem(): (typeof items)[number] | undefined {
      return items[0];
    }

    // Remove items with value 0 from largest units
    while (items.length > 1) {
      const largestItem = getLargestItem();
      if (!largestItem) break;
      if (largestItem.currentValue > 0) break;
      items.shift();
    }

    {
      // Return n+ if largest item is over largestUnitMaxValue
      const largestItem = getLargestItem();
      if (
        largestItem &&
        largestItem.unit === largestUnit &&
        largestItem.currentValue > largestUnitMaxValue
      ) {
        return [
          {
            unit: largestItem.unit,
            currentValue: largestItem.currentValue,
            overflow: true,
          } satisfies NumberWithUnitItem<TUnit>,
        ];
      }
    }

    // Splice items to match maxLargestUnitCount
    items.splice(maxLargestUnitCount);

    function getCurrentSmallestItem(): (typeof items)[number] | undefined {
      return items[items.length - 1];
    }
    function getCurrentSmallestUnit(): TUnit | undefined {
      return getCurrentSmallestItem()?.unit;
    }
    function getNextSmallestUnit(): TUnit | undefined {
      const currentSmallestUnit = getCurrentSmallestUnit();
      if (!currentSmallestUnit) return;
      const currentSmallestUnitIndex = units.indexOf(currentSmallestUnit);
      return units[currentSmallestUnitIndex + 1];
    }

    // Remove items with value 0 from smaller units
    while (1) {
      if (items.length <= 1 || items.length <= minLargestUnitCount) break;
      const smallestItem = getCurrentSmallestItem();
      if (!smallestItem) break;
      if (smallestItem.currentValue > 0) break;
      items.pop();
    }

    // Fill smaller units to match minLargestUnitCount
    while (1) {
      if (items.length >= minLargestUnitCount) break;
      const smallestUnit = getCurrentSmallestUnit();
      if (!smallestUnit) break;
      const nextSmallestUnit = getNextSmallestUnit();
      if (!nextSmallestUnit) break;
      items.push({
        unit: nextSmallestUnit,
        currentValue: 0,
        overflow: false,
      });
    }

    if (allowLargerZeroUnits) {
      // Fill larger zero units to match `minLargestUnitCount`
      while (1) {
        if (items.length >= minLargestUnitCount) break;
        const largestItem = getLargestItem();
        const smallestItem = getCurrentSmallestItem();
        const nextLargestUnit: TUnit | undefined = (() => {
          if (!largestItem) {
            if (!smallestItem) return undefined;
            return units[units.indexOf(smallestItem.unit) - 1];
          }
          return units[units.indexOf(largestItem.unit) - 1];
        })();
        if (!nextLargestUnit) break;
        items.unshift({
          unit: nextLargestUnit,
          currentValue: 0,
          overflow: false,
        });
      }
    }
    return items;
  })();
  return normalizedItems;
}

const defaultRenderFn: (
  args: RenderArgs,
  index: number,
  all: Array<RenderArgs>,
) => string = (args) =>
  `${args.overflow ? `${args.largestUnitMaxValue}+` : `${args.currentValue}`}${args.unit}`;

/**
 * Transform an array of numbers with units to display to a string.
 *
 * example:
 *
 * ```ts
 * pruneSmallerUnitsToString({
 *   units: ["d", "h", "m", "s"],
 *   numbers: { d: 1, h: 2, m: 3, s: 4 },
 *   largestUnitMaxValue: 2,
 *   render: (args) =>
 *     args.overflow
 *       ? `${args.largestUnitMaxValue}+`
 *       : `${args.currentValue}${args.unit}`,
 * }); // "1d2h"
 * ```
 */
function pruneSmallerUnitsToString<TUnit extends string = string>(
  options: Options<TUnit> & {
    /**
     * The function to render the number with unit.
     * default:
     *   - `${currentValue}${unit}`
     *   - `${largestUnitMaxValue}+${unit}` if overflow.
     */
    render?: (
      args: RenderArgs<TUnit>,
      index: number,
      all: Array<RenderArgs<TUnit>>,
    ) => string;
  },
): string {
  const largestUnitMaxValue: NonNullable<
    (typeof options)["largestUnitMaxValue"]
  > = options.largestUnitMaxValue ?? Number.POSITIVE_INFINITY;
  const render: NonNullable<(typeof options)["render"]> =
    options.render ?? defaultRenderFn;

  const items = (() => {
    const items = pruneSmallerUnits(options);
    if (!items) return items;
    return items.map((item) => ({
      ...item,
      largestUnitMaxValue,
    }));
  })();
  if (!items) return "";
  return items.map(render).join("");
}

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface PruneSmallerUnitsOwnProps<
  TUnit extends string = string,
  TRootComponent extends ElementType = DefaultComponent,
> extends Options<TUnit> {
  /**
   * The function to render the number with unit.
   *
   * default:
   *   - `${currentValue}${unit}`
   *   - `${largestUnitMaxValue}+${unit}` if overflow.
   */
  render?: (
    item: RenderArgs<TUnit>,
    index: number,
    all: Array<RenderArgs<TUnit>>,
  ) => ReactNode;

  /**
   * Fallback render when there are no units to display.
   * Note: This is not a fallback for when there are no items to display.
   * The smallest unit will be displayed when zero.
   */
  fallback?: ReactNode;

  /**
   * The component used for the root node.
   */
  component?: TRootComponent;
}

type PruneSmallerUnitsProps<
  TUnit extends string = string,
  TRootComponent extends ElementType = DefaultComponent,
> = OverrideWith<
  ComponentProps<TRootComponent>,
  PruneSmallerUnitsOwnProps<TUnit, TRootComponent>
>;

interface PruneSmallerUnitsType<
  TBaseUnit extends string = string,
  TDefaultComponent extends ElementType = DefaultComponent,
> {
  <
    TUnit extends TBaseUnit = TBaseUnit,
    TRootComponent extends ElementType = TDefaultComponent,
  >(
    props: PruneSmallerUnitsProps<TUnit, TRootComponent> & {
      component: TRootComponent;
    },
    ref?: ElementRef<TRootComponent>,
  ): ReactNode;
  <
    TUnit extends TBaseUnit = TBaseUnit,
    TRootComponent extends ElementType = TDefaultComponent,
  >(
    props: Omit<PruneSmallerUnitsProps<TUnit, TRootComponent>, "component">,
    ref?: ElementRef<TDefaultComponent>,
  ): ReactNode;
}

/**
 * A component version of `pruneSmallerUnits`. This component is a headless
 * component with `render`, `fallback`, and `component` props to customize
 * the rendering.
 *
 * As most props are inherited from `pruneSmallerUnits`, we cannot display
 * the documentation in the storybook. Please refer to the `pruneSmallerUnits`
 * function for documentation.
 */
const PruneSmallerUnits: PruneSmallerUnitsType = forwardRef(
  function PruneSmallerUnits<
    TUnit extends string = string,
    TRootComponent extends ElementType = DefaultComponent,
  >(
    {
      units,
      numbers,
      largestUnitMaxValue = Number.POSITIVE_INFINITY,
      maxLargestUnitCount = units.length,
      minLargestUnitCount = 1,
      allowLargerZeroUnits = false,
      component: Component = defaultComponent as TRootComponent,
      render = defaultRenderFn,
      fallback = EMPTY_STRING_PLACEHOLDER,
      ...props
    }: PruneSmallerUnitsProps<TUnit, ElementType>,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    /**
     * Items to display.
     */
    const normalizedItems = useMemo<Array<RenderArgs<TUnit>> | null>(() => {
      const items = pruneSmallerUnits<TUnit>({
        units,
        numbers,
        largestUnitMaxValue,
        maxLargestUnitCount,
        minLargestUnitCount,
        allowLargerZeroUnits,
      });
      if (!items) return items;
      return items.map((item) => ({
        ...item,
        largestUnitMaxValue,
      }));
    }, [
      allowLargerZeroUnits,
      largestUnitMaxValue,
      maxLargestUnitCount,
      minLargestUnitCount,
      numbers,
      units,
    ]);
    if (!normalizedItems) return fallback;
    return (
      <Component {...props} ref={ref}>
        {normalizedItems.map((...args) => (
          <Fragment key={args[0].unit}>{render(...args)}</Fragment>
        ))}
      </Component>
    );
  },
) as PruneSmallerUnitsType;

assignDisplayName(PruneSmallerUnits, "PruneSmallerUnits");

export {
  defaultComponent,
  PruneSmallerUnits,
  pruneSmallerUnits,
  pruneSmallerUnitsToString,
};

export type {
  DefaultComponent,
  NumberWithUnitItem,
  Options,
  PruneSmallerUnitsOwnProps,
  PruneSmallerUnitsProps,
  PruneSmallerUnitsType,
  RenderArgs,
};
