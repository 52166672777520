import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { secondsToMilliseconds } from "date-fns";
import { createWithEqualityFn } from "zustand/traditional";

const useStore = createWithEqualityFn<{
  now: Date;
}>()(
  () => ({
    now: new Date(),
  }),
  shallow,
);

const fps = 5;
const timeoutMs = secondsToMilliseconds(1) / fps;

function updateNow() {
  useStore.setState({ now: new Date() });
  setTimeout(updateNow, timeoutMs);
}

updateNow();

/**
 * Retrieves the current time.
 *
 * Use this hook with caution, as it can lead to the component re-rendering
 * with every frame.
 */
function useNow() {
  return useStore((state) => state.now);
}

export { useNow };
