import type { CantataTypes } from "@/cantata/types";

/**
 * Get display name from member. Fallback to original name.
 *
 * Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1708928372930869?thread_ts=1708509093.319109&cid=C02R6ETJMEY)
 */
function getMemberDisplayName(
  member: Pick<CantataTypes["Member"], "displayName" | "originalName">,
) {
  return member.displayName || member.originalName || "";
}

export { getMemberDisplayName };
