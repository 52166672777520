import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { flow } from "lodash-es";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { Line } from "@/components/antdPlots/Line";
import { tickFilters } from "@/components/antdPlots/utils";
import { Card } from "@/components/Card";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useFormatDate } from "@/resources/datetime";
import { DateIntervalSelect } from "@/routes/Insights/components/DateIntervalSelect";
import { queriesContext } from "@/routes/Insights/Conversation/ConversationTrend/queries";
import { usePageInfoUtil } from "@/routes/Insights/Conversation/pageInfo";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  title: css({
    fontSize: "1.25rem",
    fontWeight: 500,
    color: theme.colors.neutral009,
  }),
  name: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral009,
  }),
});

const ConversationTrendLine: FC = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const data = queriesContext.useData();

  const countMetrics = useMemo(() => {
    return flow(
      () => [
        ...data.new.countMetrics.map((item) => ({
          ...item,
          label: t("dashboard.conversation.conversationTrend.new.label"),
        })),
        ...data.handled.countMetrics.map((item) => ({
          ...item,
          label: t("dashboard.conversation.conversationTrend.handled.label"),
        })),
        ...data.resolved.countMetrics.map((item) => ({
          ...item,
          label: t("dashboard.conversation.conversationTrend.resolved.label"),
        })),
      ],
      Ymd.ymdToDateDeep,
    )();
  }, [data, t]);

  return (
    <Line
      data={countMetrics}
      xField={(item) => formatDate(item.date)}
      yField="value"
      colorField="label"
      axis={{
        y: {
          tickFilter: tickFilters.intergerOnly,
        },
      }}
      tooltip={{
        title: {
          field: "dateRange",
        },
      }}
      scale={{
        color: {
          range: [
            theme.colors.blue006,
            theme.colors.green006,
            theme.colors.yellow006,
            theme.colors.red006,
          ],
        },
      }}
    />
  );
};

export const ConversationTrend: FC = () => {
  const pageInfoUtil = usePageInfoUtil();
  const tagsInterval = pageInfoUtil.data.trendInterval;
  const filterIntervalByRange = DateIntervalSelect.useFilterIntervalByRange({
    startTime: pageInfoUtil.data.startTime,
    endTime: pageInfoUtil.data.endTime,
  });
  const handleIntervalChange = useHandler<
    ComponentProps<typeof DateIntervalSelect>["onChange"]
  >(function handleTagsIntervalChanged(value) {
    pageInfoUtil.update({
      trendInterval: value,
    });
  });

  return (
    <Card>
      <div css={styles.name}>
        <Trans
          i18nKey="dashboard.conversation.trend.chart.selectRange"
          components={{
            SelectRange: (
              <DateIntervalSelect
                value={tagsInterval}
                onChange={handleIntervalChange}
                filter={filterIntervalByRange}
              />
            ),
          }}
        />
      </div>
      <Line.Wrapper>
        <ErrorBoundary.Alert fullSize>
          <queriesContext.Provider>
            <ConversationTrendLine />
          </queriesContext.Provider>
        </ErrorBoundary.Alert>
      </Line.Wrapper>
    </Card>
  );
};
