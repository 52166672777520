import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { ComponentProps } from "react";

import { Trans } from "@/app/i18n/Trans";
import {
  closeRequirePermission,
  useOpenRequirePermissionStore,
} from "@/app/modal/requirePermission";
import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";

const RequirePermission = fc(function RequireEditSettingPermission() {
  const content = useOpenRequirePermissionStore();
  const closeModal = useHandler<ComponentProps<typeof Modal>["onCancel"]>(
    function closeModal() {
      closeRequirePermission();
    },
  );

  return (
    <Modal
      title={<Trans i18nKey="resource.user.setting.requirePermission.title" />}
      open={Boolean(content)}
      onCancel={closeModal}
      footer={
        <Button type="primary" onClick={closeModal}>
          <Trans i18nKey="common.confirm" />
        </Button>
      }
    >
      {content}
    </Modal>
  );
});

export { RequirePermission };
