import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import { useEnabledSomeFeatureFlags, useFeatureFlag } from "@/app/featureFlag";
import { Modal } from "@/components/Modal";
import { Content } from "@/layout/DevMode/Content";
import { Fab } from "@/layout/DevMode/Fab";
import { registerClicks } from "@/layout/DevMode/register/registerClicks";
import { registerComboKeys } from "@/layout/DevMode/register/registerComboKeys";
import { registerCommand } from "@/layout/DevMode/register/registerCommand";
import { registerTaps } from "@/layout/DevMode/register/registerTaps";

type Store = {
  opened: boolean;
  open: () => void;
  close: () => void;
};

const useStore = createWithEqualityFn<Store>()(
  (set) => ({
    opened: false,
    open: () => set({ opened: true }),
    close: () => set({ opened: false }),
  }),
  shallow,
);

function openDevMode() {
  useStore.getState().open();
}

registerComboKeys(openDevMode);
registerClicks(openDevMode);
registerTaps(openDevMode);
registerCommand(useStore);

export const DevMode = memo(function DevMode() {
  const { opened, close } = useStore(
    useHandler(({ opened, close }) => ({
      opened,
      close,
    })),
  );
  const enabledSomeFeatureFlags = useEnabledSomeFeatureFlags();
  const alwaysHideDevModeFab = useFeatureFlag("alwaysHideDevModeFab");
  return (
    <>
      <Modal
        open={opened}
        maskClosable={false}
        destroyOnClose
        onCancel={close}
        footer={null}
        limitedHeight
      >
        <Content />
      </Modal>
      {!enabledSomeFeatureFlags || alwaysHideDevModeFab ? null : (
        <Fab onClick={openDevMode} />
      )}
    </>
  );
});
