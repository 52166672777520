/**
 * Cloned from <https://github.com/github/hotkey/blob/54259b6/src/utils.ts#L35-L69>
 */

import { type NormalizedHotkeyString, normalizeHotkey } from "@github/hotkey";

import { SEQUENCE_DELIMITER } from "./sequence";

export function expandHotkeyToEdges(
  hotkey: string,
): NormalizedHotkeyString[][] {
  // NOTE: we can't just split by comma, since comma is a valid hotkey character!
  const output = [];
  let acc = [""];
  let commaIsSeparator = false;
  for (let i = 0; i < hotkey.length; i++) {
    if (commaIsSeparator && hotkey[i] === ",") {
      output.push(acc);
      acc = [""];
      commaIsSeparator = false;
      continue;
    }

    if (hotkey[i] === SEQUENCE_DELIMITER) {
      // Spaces are used to separate key sequences, so a following comma is
      // part of the sequence, not a separator.
      acc.push("");
      commaIsSeparator = false;
      continue;
    } else if (hotkey[i] === "+") {
      // If the current character is a +, a following comma is part of the
      // shortcut and not a separator.
      commaIsSeparator = false;
    } else {
      commaIsSeparator = true;
    }

    // @ts-expect-error -- It's cloned so we don't fix this.
    acc[acc.length - 1] += hotkey[i];
  }

  output.push(acc);

  // Remove any empty hotkeys/sequences
  return output
    .map((h) => h.map((k) => normalizeHotkey(k)).filter((k) => k !== ""))
    .filter((h) => h.length > 0);
}
