import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { pick } from "lodash-es";
import { compile } from "path-to-regexp";
import type { ComponentProps, FC, ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { openRequirePermission } from "@/app/modal/requirePermission";
import { Link } from "@/components/Link";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { useChatState } from "@/resources/member/useChatState";
import { useUserPermission } from "@/shared/application/user";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  self: css({
    fontSize: "0.875rem",
    lineHeight: 1.42,
  }),
});

const DisabledCaption: FC = () => {
  const member = memberQueriesContext.useMember();
  const channel = memberQueriesContext.useMemberChannel();
  const { hasPermission } = useUserPermission();
  const userCanEdit = hasPermission("manageOrg");
  const chatState = useChatState(member);
  const { t } = useTranslation();
  const handlePermissionRequired = useHandler<
    ComponentProps<typeof Link>["onClick"]
  >(function handlePermissionRequired() {
    openRequirePermission(
      t("resource.user.setting.requirePermission.description"),
    );
  });

  const children = useMemo<ReactNode>(
    function declareChildren() {
      switch (true) {
        case channel.status === "disconnected":
          return <Trans i18nKey="chat.editor.channelDisconnected" />;
        case channel.status === "token-expired":
          return (
            <Trans
              i18nKey="chat.editor.channelTokenExpired"
              components={{
                externalLink: (
                  <Link
                    {...(!userCanEdit
                      ? { onClick: handlePermissionRequired }
                      : {
                          href: compile("/editChannel/:channelId")({
                            channelId: channel.id,
                          }),
                          target: "_blank",
                        })}
                  />
                ),
              }}
            />
          );
        case member.status === "inactive":
          if (member.type === "line_group")
            return <Trans i18nKey="chat.line.inactiveGroup.message" />;
          return <Trans i18nKey="chat.memberBlockedOA" />;
        case member.processingState === "blocked":
          return <Trans i18nKey="chat.memberBlockedByUser" />;
        case member.processingState === "resolved":
          return <Trans i18nKey="chat.conversationCompleted.desc" />;
        case chatState === "CannotSendAnyContent":
          return <Trans i18nKey="chat.fb.cannotSendMessage" />;
        case channel.status === "connected":
          return null;
        default:
          return inspectMessage`Unexpected, ${{
            channel: pick(channel, ["id", "status", "type"]),
            member: pick(member, ["id", "status", "processingState"]),
            chatState,
          }}`;
      }
    },
    [channel, userCanEdit, handlePermissionRequired, member, chatState],
  );

  return <span css={styles.self}>{children}</span>;
};

export { DisabledCaption };
