import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";

import type { CantataTypes } from "@/cantata/types";

type Base = Pick<CantataTypes["Member"], "pinned" | "lastMessageAt">;

function sortByPinnedAndLastMessageAtDesc<A extends Base, B extends Base>(
  a: A,
  b: B,
): number {
  // pinned first
  if (a.pinned && !b.pinned) return -1;
  if (!a.pinned && b.pinned) return 1;

  // Invalid Date First
  const isAInvalidDate = isInvalidDate(a.lastMessageAt);
  const isBInvalidDate = isInvalidDate(b.lastMessageAt);
  if (isAInvalidDate && !isBInvalidDate) return -1;
  if (!isAInvalidDate && isBInvalidDate) return 1;
  if (isAInvalidDate && isBInvalidDate) return 0;

  return b.lastMessageAt.getTime() - a.lastMessageAt.getTime();
}

export { sortByPinnedAndLastMessageAtDesc };
