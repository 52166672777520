import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { Skeleton } from "antd";
import { type FC, Suspense, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { GroupMemberName } from "@/resources/groupMember/GroupMemberName";
import { Unknown } from "@/resources/groupMember/Unknown";

const GroupMemberNameById: FC<{ groupId: number; memberId: number }> = ({
  groupId,
  memberId,
}) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const groupMembersQuery = cantata.chatGroup.useListAll(
    {
      params: {
        orgId,
        groupId,
      },
    },
    {
      select: (data) => data.members,
    },
  );

  const member = useMemo(
    function getMember() {
      return groupMembersQuery.data?.find((member) => member.id === memberId);
    },
    [groupMembersQuery.data, memberId],
  );

  const memberQuery = cantata.member.useGetById(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      enabled: groupMembersQuery.isSuccess && !member,
      suspense: true,
      useErrorBoundary: true,
    },
  );

  if (!groupMembersQuery.isSuccess) return null;

  if (member) return <GroupMemberName member={member} />;

  if (!memberQuery.isSuccess) return <Unknown />;

  return <GroupMemberName member={memberQuery.data} />;
};

const Wrapper: FC<ComponentProps<typeof GroupMemberNameById>> = (props) => {
  return (
    <ErrorBoundary.Alert>
      <Suspense fallback={<Skeleton.Input size="small" />}>
        <GroupMemberNameById {...props} />
      </Suspense>
    </ErrorBoundary.Alert>
  );
};

assignDisplayName(Wrapper, "GroupMemberNameById");

export { GroupMemberNameById };
