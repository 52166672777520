import { z } from "zod";

const UserUpdatedSchema = z.object({
  type: z.literal("user-updated"),
  content: z.object({
    idempotencyKey: z.string(),
    userId: z.number().int(),
    action: z.enum([
      "ON_DUTY_STATUS_ONLINE",
      "ON_DUTY_STATUS_OFFLINE",
      "ON_DUTY_STATUS_AWAY",
    ]),
    createdAt: z.coerce.date(),
  }),
});

export { UserUpdatedSchema };
