import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { MotifIcon } from "@/components/MotifIcon";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { messageUtil } from "@/routes/Chat/ui/ChatPanel/History/Messages/messageUtil";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  self: css({
    color: theme.colors.neutral009,
  }),
});

const Pinned: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const message = messageUtil.useMessage();
  const query = cantata.message.useListPinnedMessages({
    params: {
      orgId,
      memberId,
    },
  });
  const pinned = useMemo(() => {
    if (!query.isSuccess) return false;
    return query.data.messages.some((m) => m.id === message.id);
  }, [query.isSuccess, query.data, message.id]);

  if (!pinned) return null;

  return (
    <span css={styles.self}>
      <MotifIcon un-i-motif="bookmark_fill" />
    </span>
  );
};

export { Pinned };
