import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";
import { cssOneLine } from "@/shared/emotion";

const MemberAssignment: FC<
  Pick<CantataTypes["Member"], "assignmentRelationship">
> = ({ assignmentRelationship: { team, user } }) => {
  if (team === null && user === null) return null;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 2px 6px;
        border-radius: 20px;
        background: ${theme.colors.yellow002};
        color: ${theme.colors.neutral009};
        font-size: 0.75rem;
        gap: 0.5em;

        & {
          overflow: hidden;
        }

        & > * {
          display: block;

          ${cssOneLine}
          &:not(:first-child)::before {
            margin-right: 0.5em;
            content: "-";
          }
        }
      `}
    >
      {!team ? null : (
        <div>{!team.name ? EMPTY_STRING_PLACEHOLDER : team.name}</div>
      )}
      {!user ? null : <div>{!user ? EMPTY_STRING_PLACEHOLDER : user.name}</div>}
    </div>
  );
};

export { MemberAssignment };
