import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import type { ReactNode } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";

function useChannelQueries({
  orgId,
  channelId,
}: {
  orgId: CantataTypes["Org"]["id"];
  channelId: CantataTypes["Channel"]["id"];
}) {
  const channelQuery = cantata.channel.useGetById(
    {
      params: {
        orgId,
        channelId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  return {
    channel: channelQuery,
  };
}

const ChannelQueriesContext =
  createQueriesContext<ReturnType<typeof useChannelQueries>>();

function ChannelQueriesProvider({
  channelId,
  children,
}: {
  channelId: CantataTypes["Channel"]["id"];
  children: ReactNode;
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const queries = useChannelQueries({
    orgId,
    channelId,
  });
  return (
    <ChannelQueriesContext.Provider queries={queries}>
      {children}
    </ChannelQueriesContext.Provider>
  );
}

function useQueries() {
  return ChannelQueriesContext.useQueries();
}

function useChannel() {
  return ChannelQueriesContext.useData().channel;
}

const channelQueriesContext = {
  Provider: ChannelQueriesProvider,
  useQueries,
  useChannel,
};

export { channelQueriesContext };
