import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import useSwitch from "@react-hook/switch";
import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { Modal } from "@/components/Modal";
import { mutationsController } from "@/routes/Chat/ui/ChatPanel/Header/mutationsController";

type LeaveGroupModalProps = Omit<
  ComponentProps<typeof Modal>,
  "title" | "okText" | "confirmLoading" | "open" | "onOk"
>;

const LeaveGroupModal: FC<LeaveGroupModalProps> = (props) => {
  const { t } = useTranslation();
  const [modalVisible, toggleModalVisible] = useSwitchBlockModal();
  const mutations = mutationsController.useContext();

  const leaveGroup = useHandler(async () => {
    const result = await safePromise(async () =>
      mutations.leaveGroupMutation.mutateAsync(undefined),
    );
    if (result.isSuccess) {
      toggleModalVisible.off();
    }
  });

  return (
    <Modal
      {...props}
      title={t("chat.leaveGroupModal.title")}
      okText={t("chat.leaveGroupModal.confirm")}
      onCancel={toggleModalVisible.off}
      okButtonProps={{ danger: true }}
      confirmLoading={mutations.isLoading}
      open={modalVisible}
      onOk={leaveGroup}
    >
      <Trans i18nKey="chat.leaveGroupModal.desc" />
    </Modal>
  );
};

const Context = createContext<ReturnType<typeof useSwitch>>({
  name: "LeaveGroupModalContext",
});

function useSwitchBlockModal() {
  return Context.useContext();
}

const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const contextValue = useSwitch(false);
  return (
    <Context.Provider value={contextValue}>
      {children}
      <LeaveGroupModal />
    </Context.Provider>
  );
};

const Api = Object.assign(Provider, {
  useSwitch: useSwitchBlockModal,
});

export { Api as LeaveGroupModalProvider };
