import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import type { FC } from "react";
import { Suspense } from "react";

import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useGetUserByIdQueries } from "@/resources/user/useGetUserByIdQueries";
import { UserAvatar } from "@/resources/user/UserAvatar";

declare namespace UserAvatarById {
  export type Props = Omit<
    ComponentProps<typeof UserAvatar>,
    "name" | "avatar"
  > & {
    userId: CantataTypes["User"]["id"];
  };
}

const UserAvatarById: FC<UserAvatarById.Props> = ({ userId, ...props }) => {
  const { user } = useGetUserByIdQueries(userId, {
    suspense: true,
    useErrorBoundary: true,
  });

  if (!user) return <UserAvatar {...props} name="" avatar="" />;

  return <UserAvatar {...props} name={user.name} avatar={user.avatar} />;
};

const Wrapper: FC<UserAvatarById.Props> = (props) => {
  return (
    <ErrorBoundary.Alert>
      <Suspense fallback={<UserAvatar {...props} name="" avatar="" />}>
        <UserAvatarById {...props} />
      </Suspense>
    </ErrorBoundary.Alert>
  );
};

assignDisplayName(Wrapper, "UserAvatarById");

export { Wrapper as UserAvatarById };
