import { MessageSchema } from "@zeffiroso/cantata/models";
import { z } from "zod";

/**
 * - Spec: [Notion](https://www.notion.so/cresclab/Real-Time-Web-Notification-7643cb44c55249eea3c1b7f6b27bde7a?pvs=4#e9a9eede3768476182c5d4e2ab607258)
 */
const MessageUpdateSchema = z.object({
  type: z.literal("message-update"),
  content: MessageSchema.pick({ id: true }).and(MessageSchema.partial()),
});

export { MessageUpdateSchema };
