import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { define } from "@chatbotgang/etude/util/define";
import type { css } from "@emotion/react";
import type { ScreenSize, ScreenType } from "@zeffiroso/theme";
import {
  breakpoints,
  screenSizes,
  screenTypeBreakpoints,
  screenTypes,
} from "@zeffiroso/theme";
import { Fragment } from "react";

type Condition = {
  compare: "exact" | "gt" | "gte" | "lt" | "lte";
  screen: ScreenType | ScreenSize;
};

const compareSignToStringMap = define<Record<string, Condition["compare"]>>()({
  "=": "exact",
  ">": "gt",
  ">=": "gte",
  "<": "lt",
  "<=": "lte",
});

type CompareSign = keyof typeof compareSignToStringMap;

type ConditionStr = `${CompareSign}${Condition["screen"]}`;

function conditionToMediaQuery(condition: Condition) {
  const [minWidth, nextMinWidth]: [number, number | undefined] = (() => {
    if (condition.screen in breakpoints) {
      const screenSize = condition.screen as ScreenSize;
      const nextScreenSize = screenSizes[screenSizes.indexOf(screenSize) + 1];
      const breakpoint = breakpoints[screenSize];
      const nextBreakpoint = nextScreenSize && breakpoints[nextScreenSize];
      return [breakpoint, nextBreakpoint];
    }
    const screenType = condition.screen as ScreenType;
    const nextScreenType = screenTypes[screenTypes.indexOf(screenType) + 1];
    const breakpoint = screenTypeBreakpoints[screenType];
    const nextBreakpoint =
      nextScreenType && screenTypeBreakpoints[nextScreenType];
    return [breakpoint, nextBreakpoint];
  })();
  if (condition.compare === "exact") {
    return nextMinWidth === undefined
      ? `(${minWidth}px <= width)`
      : `(${minWidth}px <= width < ${nextMinWidth}px)`;
  }
  if (condition.compare === "gte") return `(${minWidth}px <= width)`;

  if (condition.compare === "gt") {
    return nextMinWidth === undefined
      ? undefined
      : `(${nextMinWidth}px <= width)`;
  }

  if (condition.compare === "lte") {
    return nextMinWidth === undefined
      ? undefined
      : `(width < ${nextMinWidth}px)`;
  }

  if (condition.compare === "lt") return `(width < ${minWidth}px)`;

  throw new Error(inspectMessage`Invalid condition: ${condition}`);
}

/**
 * TODO: support `CSSInterpolation`(`@emotion/serialize`) like `{ color: 'red' }`;
 *
 * See {@link EmotionMedia} for usage.
 *
 * @see {@link EmotionMedia}
 */
function emotionMedia<Css extends typeof String.raw | typeof css>(
  cssLiteral: Css,
  condition: ConditionStr,
  callback: (css: Css) => ReturnType<Css>,
): ReturnType<Css> {
  const parsedCondition = (function parseCondition() {
    const compareSign = condition.match(/^[<>=]+/)?.[0] as CompareSign;
    const screen = condition.slice(compareSign.length) as Condition["screen"];
    return {
      compare: compareSignToStringMap[compareSign],
      screen,
    } as Condition;
  })();
  const mediaQuery = conditionToMediaQuery(parsedCondition);
  if (!mediaQuery)
    return cssLiteral`${callback(cssLiteral)}` as ReturnType<Css>;
  return cssLiteral`@media ${mediaQuery} { ${callback(
    cssLiteral,
  )} }` as ReturnType<Css>;
}

/**
 * A function that returns a CSS string that is wrapped in the specified
 * breakpoints.
 *
 * ```ts
 * import { css } from '@emotion/react';
 *
 * // For css prop
 * const cssMedia = css`
 *   ...
 *   ${emotionMedia(
 *     css,
 *     '>=md',
 *     (css) => css`
 *       ...
 *     `,
 *   )}
 * `;
 *
 * // For styled component
 * const Media = styled.div`
 *   ...
 *   ${emotionMedia(
 *     String.raw,
 *     '>=md',
 *     (css) => css`
 *       ...
 *     `,
 *   )}
 * `;
 * ```
 *
 * In most cases, you should use {@link ScreenType} instead of
 * {@link ScreenSize}.
 *
 * @see {@link breakpoints}
 */
const EmotionMedia = () => <Fragment />;

export { compareSignToStringMap, EmotionMedia, emotionMedia };
export type { CompareSign, Condition, ConditionStr };
