import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type ReactNode,
  useMemo,
} from "react";

import {
  defaultEndColor,
  defaultStartColor,
} from "@/components/antdPlots/Heatmap/constants";
import { defineStyles } from "@/shared/emotion";

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface HeatmapLegendOwnProps {
  title: ReactNode;
  from?: {
    title?: ReactNode;
    color?: string;
  };
  ribbonProps?: ComponentProps<"div">;
  to?: {
    title?: ReactNode;
    color?: string;
  };
  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface HeatmapLegendTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & HeatmapLegendOwnProps;
  defaultComponent: RootComponent;
}

type HeatmapLegendProps<
  RootComponent extends ElementType = HeatmapLegendTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = HeatmapLegendOwnProps &
  OverrideProps<
    HeatmapLegendTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const styles = defineStyles({
  heatmapRoot: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
    fontSize: "0.75rem",
    color: theme.colors.neutral007,
  }),
  title: css({
    color: "inherit",
  }),
  ribbon: css({
    width: 60,
    maxWidth: "100%",
    height: 12,
  }),
  fromTitle: css({
    color: "inherit",
  }),
  toTitle: css({
    color: "inherit",
  }),
});

/**
 * The Ant Design Heatmap legend component is not satisfy design requirements,
 * so we need to create a custom one.
 *
 * Design:
 * [Figma](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek/Insights?m=dev&node-id=2040-44525)
 */
const HeatmapLegend: OverridableComponent<HeatmapLegendTypeMap> = forwardRef(
  function HeatmapLegend(
    {
      title,
      from,
      to,
      ribbonProps,
      component: Component = defaultComponent,
      ...props
    }: HeatmapLegendProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const startColor = from?.color ?? defaultStartColor;
    const endColor = to?.color ?? defaultEndColor;
    const mergedRibbonProps = useMemo<HeatmapLegendProps["ribbonProps"]>(() => {
      return {
        ...ribbonProps,
        css: css([
          styles.ribbon,
          {
            backgroundImage: [
              `linear-gradient(to right, ${startColor}, ${endColor})`,
              `linear-gradient(to right in oklch, ${startColor}, ${endColor})`,
            ],
          },
          ribbonProps?.css,
        ]),
      };
    }, [endColor, ribbonProps, startColor]);
    return (
      <Component css={styles.heatmapRoot} {...props} ref={ref}>
        {!title ? null : <div css={styles.title}>{title}</div>}
        {from && "title" in from && !from.title ? null : (
          <div css={styles.fromTitle}>{from?.title ?? "0"}</div>
        )}
        <div css={styles.ribbon} {...mergedRibbonProps} />
        {to && "title" in to && !to.title ? null : (
          <div css={styles.toTitle}>{to?.title ?? "100%"}</div>
        )}
      </Component>
    );
  },
) as OverridableComponent<HeatmapLegendTypeMap>;

assignDisplayName(HeatmapLegend, "Heatmap.Legend");

export { defaultComponent, HeatmapLegend };

export type { HeatmapLegendOwnProps, HeatmapLegendProps, HeatmapLegendTypeMap };
