const SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_ORG_UUID = "org_uuid";
const SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_TYPE = "channel_type";
const SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_ID = "external_channel_id";
const SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_MEMBER_ID = "external_member_id";
const SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_ORG_ID = "org_id";
const SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_CHANNEL_ID = "channel_id";
const SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_MEMBER_ID = "member_id";
const QUICK_TEMPLATE_DRAWER_WIDTH = 368;

export {
  QUICK_TEMPLATE_DRAWER_WIDTH,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_ID,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_CHANNEL_TYPE,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_MEMBER_ID,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_EXTERNAL_ORG_UUID,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_CHANNEL_ID,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_MEMBER_ID,
  SEARCH_PARAMS_KEY_DIRECT_CHAT_INTERNAL_ORG_ID,
};
