import { useEffect, useState } from "react";

/**
 * Hook that listens to a media query list.
 *
 * ```tsx
 * function MyComponent() {
 *   const isMobile = useMediaQueryListMatches("(max-width: 768px)");
 *   return <div>{isMobile ? "Mobile" : "Desktop"}</div>;
 * }
 * ```
 */
function useMediaQueryListMatches(query: string) {
  const [matches, setMatches] = useState(
    () => window.matchMedia(query).matches,
  );
  useEffect(
    function updateSetMatches() {
      const mediaQueryList = window.matchMedia(query);
      setMatches(mediaQueryList.matches);
      const listener = function updateMatches() {
        setMatches(mediaQueryList.matches);
      };
      mediaQueryList.addEventListener("change", listener);
      return function cleanup() {
        mediaQueryList.removeEventListener("change", listener);
      };
    },
    [query],
  );
  return matches;
}

export { useMediaQueryListMatches };
