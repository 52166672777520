import styled from "@emotion/styled";
import type { ReactNode } from "react";

import { Empty as EmptyIcon } from "@/shared/icons/deprecated/Empty";

const Wrapper = styled.div`
  margin: 15px 0;
  text-align: center;

  > div:first-child {
    margin-bottom: 16px;
    line-height: 1;
  }
`;

export function NotFoundContent({
  message,
}: {
  message: ReactNode;
}): JSX.Element {
  return (
    <Wrapper>
      <div>
        <EmptyIcon />
      </div>
      <div>{message}</div>
    </Wrapper>
  );
}
