import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import { omit, pick } from "lodash-es";
import { type FC, useMemo } from "react";
import { createPortal } from "react-dom";
import { Outlet } from "react-router-dom";

import { Layout } from "@/layout/SideMenuLayout/Outer/Layout";
import { LayoutContext } from "@/layout/SideMenuLayout/Outer/Layout/Context";
import type { LayoutProps } from "@/layout/SideMenuLayout/Outer/Layout/types";

type OuterProps = Omit<LayoutProps, "mainRef"> &
  ComponentProps<typeof LayoutContext.Provider>;

const LayoutContextPropKeys = [
  "title",
  "sidebarMenuItems",
] as const satisfies Array<keyof ComponentProps<typeof LayoutContext.Provider>>;

const Outer: FC<OuterProps> = (props) => {
  const mainPortal = usePortal();
  const LayoutContextProviderProps = useMemo(
    () => pick(props, LayoutContextPropKeys),
    [props],
  );
  const LayoutProps = useMemo(
    () => omit(props, LayoutContextPropKeys),
    [props],
  );
  return (
    <LayoutContext.Provider {...LayoutContextProviderProps}>
      <Layout mainRef={mainPortal.setOuterEl} {...LayoutProps} />
      {createPortal(<Outlet />, mainPortal.innerEl)}
    </LayoutContext.Provider>
  );
};

export { Outer };
