import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { ElementRef } from "react";

import type { CantataTypes } from "@/cantata/types";
import { Avatar } from "@/components/Avatar";
import { useMemberName } from "@/resources/groupMember/useMemberName";

declare namespace GroupMemberAvatar {
  type Props = Omit<ComponentProps<typeof Avatar>, "children" | "src"> & {
    member: Pick<
      CantataTypes["GroupMember"],
      "displayName" | "originalName" | "avatar"
    >;
  };
  type Ref = ElementRef<typeof Avatar>;
}

const GroupMemberAvatar = forwardRef<
  GroupMemberAvatar.Ref,
  GroupMemberAvatar.Props
>(function GroupMemberAvatar({ member, ...props }, ref) {
  const memberName = useMemberName(member);
  return (
    <Avatar ref={ref} {...props} src={member.avatar}>
      {memberName}
    </Avatar>
  );
});

assignDisplayName(GroupMemberAvatar, "GroupMemberAvatar");

export { GroupMemberAvatar };
