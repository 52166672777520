import { PaperClipOutlined } from "@ant-design/icons";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { Form } from "antd";
import { extname } from "pathe";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import type { FileInputProps } from "@/components/Input";
import { FileInput } from "@/components/Input";
import { BarLoading } from "@/components/Loading/BarLoading";
import { useMessage } from "@/components/message";
import { FilePreview } from "@/components/MessagePreview/FilePreview";
import { ga4Event } from "@/lib/ga4";
import {
  validateFile,
  validator,
} from "@/resources/attachment/attachmentValidator";
import { uploadToFirebaseStorage } from "@/resources/attachment/uploadToFirebaseStorage";
import { buildFileMessage } from "@/routes/QuickTemplate/applications/utils";
import { SelectedChannelContext } from "@/routes/QuickTemplate/SelectedChannelContext";
import { FileFieldWrapper } from "@/routes/QuickTemplate/ui/FileFieldWrapper";
import { UploadFileLabel } from "@/routes/QuickTemplate/ui/UploadFileLabel";
import {
  ReplaceUploadHints,
  UploadHints,
} from "@/routes/QuickTemplate/ui/UploadHints";

const FileMessageFieldWrapper = styled(FileFieldWrapper)`
  --width: ${theme.shape.mediaFieldSize};
`;

type QuickTemplateFileMessageFormValue = ReturnType<typeof buildFileMessage>;

interface FileFieldProps {
  value?: QuickTemplateFileMessageFormValue;
  onChange?: (value: QuickTemplateFileMessageFormValue) => void;
}

export function FileField({ value, onChange }: FileFieldProps): JSX.Element {
  const { t } = useTranslation();
  const { status } = Form.Item.useStatus();
  const message = useMessage();
  const labelRef = useRef<HTMLLabelElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channel = SelectedChannelContext.useContext();
  const channelId = channel.id;

  const uploadFile = useCallback(
    async (selectedFile: File) => {
      const filename = selectedFile.name;
      const downloadLink = await uploadToFirebaseStorage({
        feature: "quickTemplate",
        pathParams: {
          channelId: channel.id,
        },
        randomizeFileName: false,
        file: selectedFile,
      });

      const draft = value ?? buildFileMessage();

      onChange?.({
        ...draft,
        metadata: {
          filename,
          filesizePrefix: t("chat.fileSize.prefix"),
          filesizeBytes: selectedFile.size,
          expirationDatePrefix: t("chat.expireAt.prefix"),
          downloadExpirationDate: new Date(0),
        },
        originUrl: downloadLink.downloadUrl,
      });
      setIsLoading(false);
    },
    [channel.id, onChange, t, value],
  );

  const handleFileChange = useHandler<FileInputProps["onChange"]>(
    async function handleFileChange(e) {
      if (
        e.currentTarget.files !== null &&
        e.currentTarget.files.length === 1
      ) {
        const file = e.currentTarget.files[0];
        ga4Event("attachmentUpload", {
          orgId,
          channelId,
          extname: extname(file.name),
          fileSize: file.size,
          feature: "quickTemplate",
        });
        const validSupportedError = validateFile(channel.type, file);

        if (validSupportedError) {
          message.error(validSupportedError.reactNode);
          return;
        }

        try {
          setIsLoading(true);
          await uploadFile(file);
        } catch (error) {
          message.error(
            t("common.failToFetchModule", { module: t("message.module.file") }),
          );
        }
      }
    },
  );

  const accept = useMemo(
    () =>
      validator[channel.type].rules.other.extnames
        .map((ext) => `.${ext}`)
        .join(","),
    [channel.type],
  );

  return (
    <FileMessageFieldWrapper $status={status}>
      <UploadFileLabel $imageUrl={undefined} ref={labelRef}>
        <FileInput accept={accept} onChange={handleFileChange} />
        {isLoading && <BarLoading />}
      </UploadFileLabel>
      {value?.originUrl !== "" && (
        <>
          <FilePreview
            fileName={value?.metadata.filename ?? ""}
            fileSizePrefix={value?.metadata.filesizePrefix ?? ""}
            fileSize={value?.metadata.filesizeBytes ?? 0}
            expiryPrefix={value?.metadata.expirationDatePrefix ?? ""}
            downloadUrl={value?.originUrl ?? ""}
          />
          <ReplaceUploadHints
            hintDescription={t("common.replace")}
            onClick={() => labelRef.current?.click()}
          >
            <PaperClipOutlined />
          </ReplaceUploadHints>
        </>
      )}
      {!isLoading && value?.originUrl === "" && (
        <UploadHints
          hintTitle={t("message.uploadFile")}
          hintDescription={t("quickTemplate.fileUploadLimit")}
        >
          <PaperClipOutlined />
        </UploadHints>
      )}
    </FileMessageFieldWrapper>
  );
}
