import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import { omit, pick } from "lodash-es";
import { type ElementRef, Suspense } from "react";

import { BarLoading } from "@/components/Loading/BarLoading";
import { defineStyles } from "@/shared/emotion";

import { QrCode } from ".";

const forwardedQrCodePropKeys = [
  "text",
  "options",
  "queryOptions",
  "imperativeHandleRef",
] as const;

namespace QrCodeBlock {
  export type ForwardedQrCodePropKeys =
    (typeof forwardedQrCodePropKeys)[number];
  export type ForwardedQrCodeProps = Pick<
    QrCode.Props,
    ForwardedQrCodePropKeys
  >;
  /**
   * The type of the ref forwarded to the `div` element.
   */
  export type Ref = ElementRef<"div">;
  /**
   * The props for the `QrCodeBlock` component.
   */
  export interface Props
    extends Overwrite<
      Overwrite<Omit<ComponentProps<"div">, "children">, ForwardedQrCodeProps>,
      {
        /**
         * The fallback UI to display while the `QrCode` component is loading. Defaults to `LoadingBar`.
         */
        suspenseFallback?: ComponentProps<typeof Suspense>["fallback"];
        /**
         * Rest props for the `QrCode` component.
         */
        QrCodeProps?: DistributiveOmit<QrCode.Props, ForwardedQrCodePropKeys>;
      }
    > {}
}

const styles = defineStyles({
  qrCodeBlockContainer: css({
    position: "relative",
    display: "flex",
    justifyContent: "stretch",
    alignItems: "stretch",
    flexDirection: "column",
    aspectRatio: "1/1",
    "&>*": {
      flex: 1,
      width: "100%",
      aspectRatio: "1/1",
    },
  }),
});

const defaultSuspenseFallback = <BarLoading />;

/**
 * `QrCodeBlock` is a React component that wraps a `QrCode` component inside a `div` with a 1:1 aspect ratio.
 * It uses a `Suspense` component to handle the loading state with a fallback UI.
 */
const QrCodeBlock = forwardRef<QrCodeBlock.Ref, QrCodeBlock.Props>(
  function QrCodeBlock(
    { suspenseFallback = defaultSuspenseFallback, QrCodeProps, ...props },
    ref,
  ) {
    const forwardedQrCodeProps = pick(props, forwardedQrCodePropKeys);
    const restProps = omit(props, forwardedQrCodePropKeys);
    return (
      <div css={styles.qrCodeBlockContainer} {...restProps} ref={ref}>
        <Suspense fallback={suspenseFallback}>
          <QrCode {...QrCodeProps} {...forwardedQrCodeProps} />
        </Suspense>
      </div>
    );
  },
);

export { QrCodeBlock };
