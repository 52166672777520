import type { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";

export const UnSaveModal = ({
  open,
  onLeave,
  onKeepEdit,
}: {
  open?: boolean;
  onKeepEdit?: MouseEventHandler<HTMLElement>;
  onLeave?: MouseEventHandler<HTMLElement>;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t("common.unsaved.title")}
      onCancel={onKeepEdit}
      footer={[
        <Button key="cancel" type="default" onClick={onLeave}>
          {t("common.unsaved.leave")}
        </Button>,
        <Button key="submit" type="primary" onClick={onKeepEdit}>
          {t("common.unsaved.keepEditing")}
        </Button>,
      ]}
    >
      {t("common.unsaved.desc")}
    </Modal>
  );
};
