import type { FC } from "react";
import { create } from "zustand";

const touchOnlyLikeDeviceQuery = "(hover: none), (pointer: coarse)";

const mql = window.matchMedia(touchOnlyLikeDeviceQuery);

/**
 * @see {@link UseIsTouchOnlyLikeDeviceStore}
 */
const useIsTouchOnlyLikeDeviceStore = create<boolean>(() => mql.matches);

mql.addEventListener("change", () => {
  useIsTouchOnlyLikeDeviceStore.setState(mql.matches);
});

/**
 * Zustand store to track whether the device is touch-only like.
 *
 * ```ts
 * const isTouchOnlyLikeDevice = useIsTouchOnlyLikeDeviceStore();
 * ```
 */
const UseIsTouchOnlyLikeDeviceStore: FC = () => null;

export { UseIsTouchOnlyLikeDeviceStore, useIsTouchOnlyLikeDeviceStore };
