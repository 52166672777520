import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import { type FC, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { AutoGrid } from "@/components/AutoGrid";
import { Card } from "@/components/Card";
import { DashboardCardLayout } from "@/components/Card/DashBoardLayout";
import { RwdFlexCard_23_221 } from "@/components/Card/RwdFlexCard/RwdFlexCard_23_221";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { NumberFormat } from "@/components/NumberFormat";
import { TooltipInfo } from "@/components/TooltipInfo";
import { Trend } from "@/components/Trend";
import { usePageInfoUtil } from "@/routes/Insights/Conversation/pageInfo";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    containerType: "inline-size",
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
});

const TotalCount: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query = cantata.dashboardConversation.useTotalConversationTotalCount(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  if (!query.isSuccess) return null;
  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.conversation.overview.total.label" />
        <TooltipInfo
          title={
            <Trans i18nKey="dashboard.conversation.overview.total.tooltip.title" />
          }
        />
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        <NumberFormat
          numberFormatOptions={{
            notation: "compact",
          }}
          value={query.data.currentCount}
        />
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.contacts.contact.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            query.data === undefined
              ? 0
              : (query.data.currentCount - query.data.previousCount) /
                query.data.previousCount
          }
          valueCurrent={query.data.currentCount}
          valuePrevious={query.data.previousCount}
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};
const NewCount: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query = cantata.dashboardConversation.useNewConversationTotalCount(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  if (!query.isSuccess) return null;
  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.conversation.conversationTrend.new.label" />
        <TooltipInfo
          title={
            <Trans i18nKey="dashboard.conversation.conversationTrend.new.tooltip.title" />
          }
        />
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        <NumberFormat
          numberFormatOptions={{
            notation: "compact",
          }}
          value={query.data.currentCount}
        />
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.contacts.contact.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            query.data === undefined
              ? 0
              : (query.data.currentCount - query.data.previousCount) /
                query.data.previousCount
          }
          valueCurrent={query.data.currentCount}
          valuePrevious={query.data.previousCount}
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};
/**
 * We use assigned conversation query for team insights.
 * It's because the team insights are based on the assigned conversations.
 *
 * - [Reference](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek?node-id=2322-25579#838055795)
 */
const AssignedCount: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query = cantata.dashboardConversation.useAssignedConversationTotalCount(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  if (!query.isSuccess) return null;
  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.conversation.conversationTrend.new.label" />
        <TooltipInfo
          title={
            <Trans i18nKey="dashboard.conversation.conversationTrend.new.tooltip.title" />
          }
        />
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        <NumberFormat
          numberFormatOptions={{
            notation: "compact",
          }}
          value={query.data.currentCount}
        />
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.contacts.contact.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            query.data === undefined
              ? 0
              : (query.data.currentCount - query.data.previousCount) /
                query.data.previousCount
          }
          valueCurrent={query.data.currentCount}
          valuePrevious={query.data.previousCount}
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};
const HandledCount: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query = cantata.dashboardConversation.useHandledConversationTotalCount(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  if (!query.isSuccess) return null;
  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.conversation.conversationTrend.handled.label" />
        <TooltipInfo
          title={
            <Trans i18nKey="dashboard.conversation.conversationTrend.handled.tooltip.title" />
          }
        />
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        <NumberFormat
          numberFormatOptions={{
            notation: "compact",
          }}
          value={query.data.currentCount}
        />
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.contacts.contact.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            query.data === undefined
              ? 0
              : (query.data.currentCount - query.data.previousCount) /
                query.data.previousCount
          }
          valueCurrent={query.data.currentCount}
          valuePrevious={query.data.previousCount}
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};
const ResolvedCount: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query = cantata.dashboardConversation.useResolvedConversationTotalCount(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  if (!query.isSuccess) return null;
  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.conversation.conversationTrend.resolved.label" />
        <TooltipInfo
          title={
            <Trans i18nKey="dashboard.conversation.conversationTrend.resolved.tooltip.title" />
          }
        />
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        <NumberFormat
          numberFormatOptions={{
            notation: "compact",
          }}
          value={query.data.currentCount}
        />
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.contacts.contact.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            query.data === undefined
              ? 0
              : (query.data.currentCount - query.data.previousCount) /
                query.data.previousCount
          }
          valueCurrent={query.data.currentCount}
          valuePrevious={query.data.previousCount}
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};
const UnresolvedCount: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query =
    cantata.dashboardConversation.useUnresolvedConversationTotalCount(
      {
        params: {
          orgId,
        },
        queries: pageInfoUtil.computed.commonQueries,
      },
      {
        suspense: true,
        useErrorBoundary: true,
      },
    );
  if (!query.isSuccess) return null;
  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.conversation.conversationTrend.unresolved.label" />
        <TooltipInfo
          title={
            <Trans i18nKey="dashboard.conversation.conversationTrend.unresolved.tooltip.title" />
          }
        />
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        <NumberFormat
          numberFormatOptions={{
            notation: "compact",
          }}
          value={query.data.currentCount}
        />
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.contacts.contact.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            query.data === undefined
              ? 0
              : (query.data.currentCount - query.data.previousCount) /
                query.data.previousCount
          }
          valueCurrent={query.data.currentCount}
          valuePrevious={query.data.previousCount}
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};

const SentCount: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query = cantata.dashboardConversation.useMessageSentTotalCount(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  if (!query.isSuccess) return null;
  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.conversation.conversationTrend.sent.label" />
        <TooltipInfo
          title={
            <Trans i18nKey="dashboard.conversation.conversationTrend.sent.tooltip.title" />
          }
        />
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        <NumberFormat
          numberFormatOptions={{
            notation: "compact",
          }}
          value={query.data.currentCount}
        />
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.contacts.contact.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            query.data === undefined
              ? 0
              : (query.data.currentCount - query.data.previousCount) /
                query.data.previousCount
          }
          valueCurrent={query.data.currentCount}
          valuePrevious={query.data.previousCount}
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};

export const ConversationCount: FC = () => {
  const pageInfoUtil = usePageInfoUtil();
  const items = useMemo<ComponentProps<typeof RwdFlexCard_23_221>["items"]>(
    () => [
      <ErrorBoundary.Alert key="total">
        <TotalCount />
      </ErrorBoundary.Alert>,
      <ErrorBoundary.Alert key="new">
        {pageInfoUtil.data.teamIds.length > 0 ? (
          <AssignedCount />
        ) : (
          <NewCount />
        )}
      </ErrorBoundary.Alert>,
      <ErrorBoundary.Alert key="handled">
        <HandledCount />
      </ErrorBoundary.Alert>,
      <ErrorBoundary.Alert key="resolved">
        <ResolvedCount />
      </ErrorBoundary.Alert>,
      <ErrorBoundary.Alert key="unresolved">
        <UnresolvedCount />
      </ErrorBoundary.Alert>,
    ],
    [pageInfoUtil.data.teamIds.length],
  );
  return (
    <div css={styles.root}>
      <RwdFlexCard_23_221 items={items} />
      <AutoGrid minWidth="300px" repeatTrackSize="auto-fill">
        <Card>
          <ErrorBoundary.Alert>
            <SentCount />
          </ErrorBoundary.Alert>
        </Card>
      </AutoGrid>
    </div>
  );
};
