import { createWithEqualityFn } from "zustand/traditional";

import { shallow } from "../zustand/shallow";

function getActiveElement() {
  return document.activeElement;
}

type StoreValue = {
  element: ReturnType<typeof getActiveElement>;
};

const useStore = createWithEqualityFn<StoreValue>()(
  () => ({
    element: getActiveElement(),
  }),
  shallow,
);

/**
 * Get the currently focused element.
 */
function useActiveElement() {
  return useStore((state) => state.element);
}

window.addEventListener(
  "focus",
  () => {
    useStore.setState({ element: getActiveElement() });
  },
  {
    capture: true,
  },
);

window.addEventListener(
  "blur",
  () => {
    useStore.setState({ element: null });
  },
  {
    capture: true,
  },
);

export { useActiveElement };
