import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import useSwitch from "@react-hook/switch";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Button } from "@/components/Button";
import { Form } from "@/components/Form";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { BoldText } from "@/components/Typography";
import { TeamAssignmentQrcodeModal } from "@/resources/assignmentQrcode/TeamAssignmentQrcodeModal";
import { usePageInfo } from "@/routes/Settings/Teams/pages/TeamForm/usePageInfo";

const AssignmentQrCodes: FC = () => {
  const mergeFormDisabled = useMergeFormDisabled();
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfo = usePageInfo();
  const [opened, toggle] = useSwitch();
  return (
    <>
      <Form.Item
        label={<BoldText>{t("team.form.qrCodes.label")}</BoldText>}
        /**
         * The `required` prop should be set to `true` to prevent showing an
         * optional label for this item, as it is not a real field. We are only
         * borrowing the style from `FormItem`.
         */
        required
      >
        <Button
          type="primary"
          onClick={toggle.on}
          disabled={mergeFormDisabled(opened)}
        >
          {t("team.form.qrCodes.openModalButton.label")}
        </Button>
      </Form.Item>
      {!opened || !pageInfo.teamId ? null : (
        <TeamAssignmentQrcodeModal
          orgId={orgId}
          teamId={pageInfo.teamId}
          onCancel={toggle.off}
        />
      )}
    </>
  );
};

assignDisplayName(AssignmentQrCodes, "AssignmentQrCodes");

export { AssignmentQrCodes };
