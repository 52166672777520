import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { useTranslation } from "react-i18next";

import { Avatar } from "@/components/Avatar";
import exampleImage from "@/routes/Settings/ChatSettings/UserRecognition/example.png";

const cssTitle = css`
  margin-bottom: 0;
  color: ${theme.colors.neutral010};
  font-size: 0.875rem;
  font-weight: 500;
`;

const cssExampleTitle = css`
  ${cssTitle}
  margin-bottom: 18px;
`;

const cssExample = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

const cssDisplay = css`
  color: ${theme.colors.neutral009};
  font-size: 0.875rem;
  font-weight: 500;
`;

const cssExampleRight = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const cssMessage = css`
  padding: 8px;
  border-radius: 8px;
  background-color: ${theme.colors.neutral002};
  color: ${theme.colors.neutral010};
  font-size: 0.875rem;
  font-weight: 400;
`;

const TooltipContent = memo(function TooltipContent() {
  const { t } = useTranslation();
  return (
    <div>
      <p css={cssTitle}>
        {t(
          "organization.chatSettings.userRecognition.columns.enabled.label.tooltip.title",
        )}
      </p>
      <p css={cssExampleTitle}>
        {t(
          "organization.chatSettings.userRecognition.columns.enabled.label.tooltip.sample.title",
        )}
      </p>
      <div css={cssExample}>
        <Avatar src={exampleImage} size={24} />
        <div css={cssExampleRight}>
          <div css={cssDisplay}>
            {t(
              "organization.chatSettings.userRecognition.columns.enabled.label.tooltip.sample.display.template",
              {
                user: t(
                  "organization.chatSettings.userRecognition.columns.enabled.label.tooltip.sample.display.value.user",
                ),
                channel: t(
                  "organization.chatSettings.userRecognition.columns.enabled.label.tooltip.sample.display.value.channel",
                ),
              },
            )}
          </div>
          <div css={cssMessage}>
            {t(
              "organization.chatSettings.userRecognition.columns.enabled.label.tooltip.sample.message.content",
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export { TooltipContent };
