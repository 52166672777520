import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import { fakeT } from "@/shared/g11n/fakeT";

const processingStateToTranslationKeyMap: Record<
  CantataTypes["MemberProcessingState"],
  string
> = (() => {
  const t = fakeT;
  const ret: typeof processingStateToTranslationKeyMap = {
    new: t("chat.memberPanel.pipelineBar.new"),
    "follow-up": t("chat.memberPanel.pipelineBar.followUp"),
    resolved: t("chat.memberPanel.pipelineBar.resolved"),
    blocked: t("chat.spam"),
    none: "",
  };
  return ret;
})();

function useToLocaleProcessingState(): (
  processingState: CantataTypes["MemberProcessingState"],
) => string {
  const { t } = useTranslation();
  const toLocaleProcessingState = useCallback(
    function toLocaleProcessingState(
      ...args: Parameters<ReturnType<typeof useToLocaleProcessingState>>
    ) {
      return t(processingStateToTranslationKeyMap[args[0]]);
    },
    [t],
  );
  return toLocaleProcessingState;
}

export { useToLocaleProcessingState };
