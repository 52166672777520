import { defineArrayPredicate } from "@chatbotgang/etude/array/defineArrayPredicate";

import type { CantataTypes } from "@/cantata/types";

/**
 * Only with required fields for filtering.
 */
type BaseUser = Pick<CantataTypes["User"], "status">;

type AvailableUser<User extends BaseUser> = User & {
  status: "active";
};

function isAvailableUser<User extends BaseUser>(
  user: User,
): user is AvailableUser<User> & {
  status: "active";
} {
  return user.status === "active";
}

/**
 * Filter for available users.
 *
 * Use this for most user list other than the one in the user management page.
 *
 * Naming this function as `availableUserFilter` is because the condition for
 * available users may change in the future.
 *
 * For now, only users with status 'active' are available.
 */
const availableUserFilter = defineArrayPredicate<BaseUser>()(isAvailableUser);

export { availableUserFilter };
