import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const NSResize = generateIcon(function NSResize() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor">
      <path d="M8.699 11.336h1.114a.124.124 0 0 1 .096.202L8.176 13.73a.123.123 0 0 1-.194 0l-1.733-2.192a.125.125 0 0 1 .098-.202H7.46v-6.67H6.348a.124.124 0 0 1-.098-.202L7.984 2.27a.123.123 0 0 1 .194 0L9.91 4.464a.125.125 0 0 1-.098.201H8.699v6.671Z" />
    </svg>
  );
});
