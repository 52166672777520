import { noop } from "lodash-es";
import { type FC, useEffect } from "react";

type Props = {
  force?: boolean;
};

/**
 * This is a component that throws an error in development for testing purposes.
 */
const ErrorComponent: FC<Props> = (props) => {
  if (import.meta.env.DEV || props.force) throw new Error("This is an error");
  return null;
};

/**
 * This is a component that suspends in development for testing purposes.
 */
const SuspenseComponent: FC<Props> = (props) => {
  if (import.meta.env.DEV || props.force) throw new Promise(noop);
  return null;
};

/**
 * This is a component that logs messages in development for debug purposes. It
 * makes it easier to find out when a component is mounted and unmounted.
 *
 * ```tsx
 * function MyComponent() {
 *   Debug.LogEffect.useLogEffect("MyComponent");
 *   return (
 *     <ComponentA>
 *       <Debug.LogEffect content="ComponentA" />
 *       <ComponentB>
 *         <Debug.LogEffect content="ComponentB" />
 *       </ComponentB>
 *     </ComponentA>
 *   );
 * }
 * ```
 */
const LogEffectComponent: FC<{ content: string }> = ({ content }) => {
  useLogEffect(content);
  return null;
};

/**
 * This is a hook that logs messages in development for debug purposes. It makes
 * it easier to find out when a component is mounted and unmounted.
 *
 * ```tsx
 * function MyComponent() {
 *   Debug.LogEffect.useLogEffect("MyComponent");
 *   // ...
 * }
 * ```
 */
function useLogEffect(content: string) {
  useEffect(() => {
    console.log("LogEffect", content);
    return () => {
      console.log("LogEffect cleanup", content);
    };
  }, [content]);
}

/**
 * Debug API provides components that throw errors or suspend in development for
 * testing purposes.
 */
const Debug = {
  Error: ErrorComponent,
  Suspense: SuspenseComponent,
  LogEffect: Object.assign(LogEffectComponent, { useLogEffect }),
};

export { Debug };
