import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";

import { Form } from "@/components/Form";
import { createEasyForm } from "@/components/Form/createEasyForm";
import type { FieldValues } from "@/routes/Chat/ui/ChatPanel/Editor/Old/types";

const EasyForm = createEasyForm<FieldValues>();

function useSetupEasyForm() {
  const easyFormReturnValue = EasyForm.useForm();
  return easyFormReturnValue;
}

const Context = createContext<ReturnType<typeof useSetupEasyForm>>({
  name: "EasyForm",
});

/**
 * This allows us to access the easyForm and form instance directly, rather than
 * using `EasyForm.useFormInstance()` outside of the `<EasyForm />` component.
 */
const useEasyForm = Context.useContext;

function EasyFormProvider(
  props: Omit<ComponentProps<typeof Context.Provider>, "value">,
) {
  const easyFormReturnValue = useSetupEasyForm();
  return <Context.Provider {...props} value={easyFormReturnValue} />;
}

function useMessageFieldValue() {
  const [, form] = useEasyForm();
  const messageFieldValue = Form.useWatch<
    | FieldValues["message"]
    /**
     * It always return `undefined` at the first render.
     */
    | undefined
  >("message", form);
  return messageFieldValue;
}

export { EasyForm, EasyFormProvider, useEasyForm, useMessageFieldValue };
