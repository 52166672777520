import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import type { FC, ReactNode } from "react";

import { Paper } from "@/components/Paper";
import type { TriggerProps } from "@/components/Trigger";
import { Trigger } from "@/components/Trigger";
import { Content } from "@/routes/Chat/ui/ChatPanel/Pin/Content";
import {
  DropDownSwitchContext,
  useDropDownSwitch,
} from "@/routes/Chat/ui/ChatPanel/Pin/useDropDownSwitch";

const trigger: TriggerProps["trigger"] = ["click"];

const cssPaper = css`
  display: flex;
  width: 344px;
  max-width: 100%;
  min-height: 168px;
  max-height: 364px;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  transform: translate(16px, 6px);
`;

const PinMessageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dropDownSwitchContextValue = useSwitch(false);
  return (
    <DropDownSwitchContext.Provider value={dropDownSwitchContextValue}>
      {children}
    </DropDownSwitchContext.Provider>
  );
};

type PinMessageTriggerProps = Omit<
  TriggerProps,
  "open" | "content" | "onOpenChange" | "trigger" | "placement"
>;

const PinMessageTrigger: FC<PinMessageTriggerProps> = (props) => {
  const [dropdownActive, dropdownSwitch] = useDropDownSwitch();
  const onOpenChange = useHandler<TriggerProps["onOpenChange"]>(
    function onOpenChange(open) {
      /**
       * Because the dropdown is not triggered by the direct children, we need to
       * manually control the switch state.
       */
      if (open) return;
      dropdownSwitch.off();
    },
  );
  return (
    <Trigger
      open={dropdownActive}
      content={
        <Paper css={cssPaper}>
          <Content />
        </Paper>
      }
      onOpenChange={onOpenChange}
      trigger={trigger}
      placement="bottomRight"
      {...props}
    />
  );
};

const PinMessage = {
  Provider: PinMessageProvider,
  Trigger: PinMessageTrigger,
  useSwitch: useDropDownSwitch,
};

export { PinMessage };
