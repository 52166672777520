import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import type { ModalProps as AntModalProps } from "antd";
import { Modal as AntModal } from "antd";
import { type FC, useMemo } from "react";

import {
  DisabledContextProvider,
  useDisabled,
} from "@/components/Form/DisabledContext";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { StyledExclamationIcon } from "@/shared/icons/deprecated/styled/StyledExclamationIcon";

type ModalProps = AntModalProps & {
  limitedHeight?: boolean;
};

const ExtendedModal: FC<ModalProps> = function Modal({
  limitedHeight,
  footer: externalFooter,
  ...restProps
}) {
  const disabled = useDisabled();

  const footer = useMemo<ModalProps["footer"]>(
    /**
     * @see: https://github.com/ant-design/ant-design/issues/43044
     * This is a workaround for antd Modal's footer prop not working with disabled context.
     */
    function getFooter() {
      if (typeof externalFooter === "function") {
        return function renderFooter(...args) {
          return (
            <DisabledContextProvider disabled={disabled}>
              {externalFooter(...args)}
            </DisabledContextProvider>
          );
        };
      }

      if (externalFooter === null) {
        return null;
      }

      if (typeof externalFooter === "undefined") {
        return function renderFooter(footerNode) {
          return (
            <DisabledContextProvider disabled={disabled}>
              {footerNode}
            </DisabledContextProvider>
          );
        };
      }

      return (
        <DisabledContextProvider disabled={disabled}>
          {externalFooter}
        </DisabledContextProvider>
      );
    },
    [disabled, externalFooter],
  );

  return (
    <AntModal
      css={
        !limitedHeight
          ? null
          : css`
              /**
               * Should be the same as padding-bottom from .ant-modal
               */
              --zeffiroso-modal-outer-padding-block: 24px;

              /**
               * Should be the same as top from .ant-modal
               */
              --zeffiroso-modal-outer-padding-top-max: 100px;

              /**
               * To center the modal vertically.
               */
              --zeffiroso-modal-outer-padding-top: calc((100dvh - 100%) / 2);

              /**
               * Use max-height and translate from .ant-modal-content for positioning instead.
               */
              top: 0;
              padding-bottom: 0;

              & .ant-modal-content {
                display: flex;
                overflow: auto;
                width: 100%;
                max-height: calc(
                  100dvh - var(--zeffiroso-modal-outer-padding-block) * 2
                );
                flex-direction: column;
                translate: 0
                  min(
                    var(--zeffiroso-modal-outer-padding-top-max),
                    var(--zeffiroso-modal-outer-padding-top)
                  );

                & .ant-modal-body {
                  position: relative;
                  display: flex;
                  overflow: auto;
                  flex: 1;
                  flex-direction: column;
                }
              }
            `
      }
      {...restProps}
      footer={footer}
    />
  );
};

const Warning = (() => {
  const styles = {
    content: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
      text-align: center;

      > :first-child {
        display: flex;
        width: min(100%, 110px);
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;

        > * {
          overflow: hidden;
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
        }
      }
    `,
  } satisfies Record<string, SerializedStyles>;
  const Warning: FC<ModalProps> = (props) => {
    return (
      <ExtendedModal {...props}>
        <div css={styles.content}>
          <div>
            <StyledExclamationIcon />
          </div>
          <div>{props.children}</div>
        </div>
      </ExtendedModal>
    );
  };
  return Warning;
})();

const originModalApi = {
  ...AntModal,
};

const Loading: FC<Omit<ModalProps, "children" | "footer" | "closable">> = (
  props,
) => (
  <Modal footer={null} closable={Boolean(props.onCancel)} {...props}>
    <BarLoadingBlock />
  </Modal>
);

// inherit origin Modal static methods.
const Modal = Object.assign(ExtendedModal, {
  ...originModalApi,
  Warning,
  Loading,
});

export { Modal };

export type { ModalProps };
