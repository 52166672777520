import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Modal, type ModalProps } from "@/components/Modal";
import { ResultList } from "@/components/ResultList";
import type { ResultListItem } from "@/components/ResultList/ResultListItem";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  ImportUsersResultModal: css({
    "& .ant-modal-body": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 8,
    },
  }),
});

namespace ImportUsersResultModal {
  export interface Props
    extends Omit<
      ModalProps,
      | "title"
      | "children"
      | "okText"
      | "okButtonProps"
      | "cancelText"
      | "cancelButtonProps"
      | "onCancel"
    > {
    invitedUsersCount: number;
    updatedUsersCount: number;
  }
}

/**
 * The modal for showing the result of importing users.
 *
 * Design: [Figma](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=6806-23061&t=RP4duup0NmJFPFCd-1)
 */
const ImportUsersResultModal: FC<ImportUsersResultModal.Props> = ({
  invitedUsersCount,
  updatedUsersCount,
  ...props
}) => {
  const { t } = useTranslation();
  const results: Array<ResultListItem.Props> = useMemo(() => {
    const results: Array<ResultListItem.Props> = [];
    if (invitedUsersCount > 0) {
      results.push({
        color: "success",
        children: t("page.users.import.success.modal.invitedUsers", {
          count: invitedUsersCount,
        }),
      });
    }
    if (updatedUsersCount > 0) {
      results.push({
        color: "success",
        children: t("page.users.import.success.modal.updatedUsers", {
          count: updatedUsersCount,
        }),
      });
    }
    return results;
  }, [invitedUsersCount, t, updatedUsersCount]);
  const cancelButtonProps = useMemo<
    ComponentProps<typeof Modal>["cancelButtonProps"]
  >(
    () => ({
      style: { display: "none" },
    }),
    [],
  );
  return (
    <Modal
      css={styles.ImportUsersResultModal}
      {...props}
      onCancel={props.onOk}
      cancelButtonProps={cancelButtonProps}
      title={t("page.users.import.success.modal.title")}
    >
      {t("page.users.import.success.modal.content")}
      {results.length === 0 ? null : (
        <ResultList>
          {results.map((result, index) => (
            <ResultList.Item key={index} {...result} />
          ))}
        </ResultList>
      )}
    </Modal>
  );
};

export { ImportUsersResultModal };
