import { preload } from "@chatbotgang/etude/dom/preload";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { FC, ReactNode } from "react";
import { useMemo } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { ErrorData } from "@/components/ErrorBoundary";
import { Paragraph } from "@/components/Typography";
import img from "@/internal/PageErrorBoundary/ErrorFallbackPage/error.png";
import { ReloadButton } from "@/internal/PageErrorBoundary/ErrorFallbackPage/ReloadButton";
import { logError } from "@/shared/application/logger/sentry";

const maxImageWidth = "304px";

const cssContainer = css`
  position: relative;
  display: flex;
  max-width: 789px;
  box-sizing: content-box;
  align-items: center;
  justify-content: space-between;
  padding-top: 160px;
  padding-right: var(--px);
  padding-left: var(--px);
  margin: auto;
  gap: 4em;

  --px: 2em;
`;

const cssMain = css`
  display: flex;
  overflow: hidden;
  width: fit-content;
  flex-direction: column;
  flex-grow: 1;
  gap: 40px;
  justify-items: center;
`;

const cssAction = css`
  display: flex;
  margin: 20px 0;
  place-items: center center;

  > button {
    width: clamp(4em, 229px, 100%);
  }
`;

const cssImage = css`
  display: flex;
  min-width: 6em;
  max-width: calc(100% - ${maxImageWidth} - 4em);
`;

const cssTitle = css`
  font-size: 29px;
  font-weight: 500;
`;

const Title: FC<ComponentProps<"h1">> = ({ children, ...props }) => {
  const titleNode = useMemo(
    () =>
      children ?? (
        <Trans i18nKey="errorBoundary.title.default">
          Oops ! Something’s wrong here.
        </Trans>
      ),
    [children],
  );
  return (
    <h1 css={cssTitle} {...props}>
      {titleNode}
    </h1>
  );
};

type ContentProps = {
  children?: ReactNode | (({ children }: { children: ReactNode }) => ReactNode);
};

const Content: FC<ContentProps> = ({ children }) => {
  const defaultContent: ReactNode = useMemo<ReactNode>(
    () => (
      <Paragraph>
        <Trans i18nKey="errorBoundary.content.default">
          {"Don’t worry, we're trying to figure out the issue."}
        </Trans>
      </Paragraph>
    ),
    [],
  );
  const content = useMemo(
    () =>
      typeof children === "function"
        ? children({ children: defaultContent })
        : children ?? defaultContent,
    [children, defaultContent],
  );
  return content;
};

export type ErrorFallbackPageProps = {
  title?: ReactNode;
  description?: ComponentProps<typeof Content>["children"];
  action?: ReactNode;
  reloadWindow?: boolean;
  errorData?: ErrorData;
} & Omit<ComponentProps<"div">, "children">;

// Preload the image that make it able to display when disconnected.
preload({ href: img, as: "image" });

/**
 * The error fallback page that is displayed when an error is caught by the
 * ErrorBoundary.
 *
 * We can also use the `action` prop to customize the action button for other
 * purposes.
 */
const ErrorFallbackPage: FC<ErrorFallbackPageProps> = ({
  title,
  description,
  reloadWindow = false,
  errorData,
  ...props
}) => {
  const reload = useHandler(() => {
    if (reloadWindow || !errorData?.resetError) {
      window.location.reload();
      return;
    }
    try {
      errorData.resetError();
    } catch (e) {
      // Record error and do nothing because ErrorBoundary should be the last error guard.
      logError(e);
    }
  });
  const defaultAction = useMemo(
    () => (
      <ReloadButton onClick={reload} type="primary">
        <Trans i18nKey="errorBoundary.action.reload">Reload</Trans>
      </ReloadButton>
    ),
    [reload],
  );
  const action = useMemo(
    () => ("action" in props ? props.action : defaultAction),
    [props, defaultAction],
  );
  return (
    <div css={cssContainer} {...props}>
      <div css={cssMain}>
        <Title>{title}</Title>
        <Content>{description}</Content>
        {!action ? null : <div css={cssAction}>{action}</div>}
      </div>
      <img css={cssImage} src={img} alt="Error" />
    </div>
  );
};

export { ErrorFallbackPage };
