// cSpell:disable - This file includes 3rd part code which contains spelling errors

import styled from "@emotion/styled";
import cn from "classnames/bind";

import { cssOneLine, cssWrap } from "@/shared/emotion";

const bubbleSizeClassNames = {
  giga: "LyGi",
  mega: "LyMe",
  kilo: "LyKi",
  micro: "LyMi",
  nano: "LyNa",
};

const directionClassNames = {
  ltr: "fxLTR",
  rtl: "fxRTL",
};

const blockClassNames = {
  header: "t1Header",
  hero: "t1Hero",
  body: "t1Body",
  footer: "t1Footer",
};

const componentClassNames = {
  box: "MdBx",
  button: "MdBtn",
  icon: "MdIco",
  image: "MdImg",
  text: "MdTxt",
  separator: "MdSep",
  span: "MdSpn",
};

const marginClassNames = {
  margin_a_none: "ExMgnANone",
  margin_a_xs: "ExMgnAXs",
  margin_a_sm: "ExMgnASm",
  margin_a_md: "ExMgnAMd",
  margin_a_lg: "ExMgnALg",
  margin_a_xl: "ExMgnAXl",
  margin_a_xxl: "ExMgnAXXl",
  margin_t_none: "ExMgnTNone",
  margin_t_xs: "ExMgnTXs",
  margin_t_sm: "ExMgnTSm",
  margin_t_md: "ExMgnTMd",
  margin_t_lg: "ExMgnTLg",
  margin_t_xl: "ExMgnTXl",
  margin_t_xxl: "ExMgnTXXl",
  margin_b_none: "ExMgnBNone",
  margin_b_xs: "ExMgnBXs",
  margin_b_sm: "ExMgnBSm",
  margin_b_md: "ExMgnBMd",
  margin_b_lg: "ExMgnBLg",
  margin_b_xl: "ExMgnBXl",
  margin_b_xxl: "ExMgnBXXl",
  margin_l_none: "ExMgnLNone",
  margin_l_xs: "ExMgnLXs",
  margin_l_sm: "ExMgnLSm",
  margin_l_md: "ExMgnLMd",
  margin_l_lg: "ExMgnLLg",
  margin_l_xl: "ExMgnLXl",
  margin_l_xxl: "ExMgnLXXl",
  margin_r_none: "ExMgnRNone",
  margin_r_xs: "ExMgnRXs",
  margin_r_sm: "ExMgnRSm",
  margin_r_md: "ExMgnRMd",
  margin_r_lg: "ExMgnRLg",
  margin_r_xl: "ExMgnRXl",
  margin_r_xxl: "ExMgnRXXl",
};

const layoutClassNames = {
  vertical: "vr",
  horizontal: "hr",
  baseline: "hr bl",
};

const componentSizeClassNames = {
  size_xxs: "ExXXs",
  size_xs: "ExXs",
  size_sm: "ExSm",
  size_md: "ExMd",
  size_lg: "ExLg",
  size_xl: "ExXl",
  size_xxl: "ExXXl",
  size_3xl: "Ex3Xl",
  size_4xl: "Ex4Xl",
  size_5xl: "Ex5Xl",
  size_full: "ExFull",
};

const spacingClassNames = {
  spacing_xs: "spcXs",
  spacing_sm: "spcSm",
  spacing_md: "spcMd",
  spacing_lg: "spcLg",
  spacing_xl: "spcXl",
  spacing_xxl: "spcXXl",
};

const aspectModeClassNames = {
  cover: "ExCover",
  fit: "ExFit",
};

const booleanClassNames = {
  hasFooter: "ExHasFooter",
  // position
  absolute: "ExAbs",
  // weight
  bold: "ExWB",
  // wrap
  wrap: "ExWrap",
};

const flexClassNames = {
  flex0: "fl0",
  flex1: "fl1",
  flex2: "fl2",
  flex3: "fl3",
};

const flexAlignItems = {
  align_items_start: "itms-algS",
  align_items_end: "itms-algE",
  align_items_center: "itms-algC",
};

const flexJustifyContents = {
  justify_contents_start: "itms-jfcS",
  justify_contents_end: "itms-jfcE",
  justify_contents_center: "itms-jfcC",
  justify_contents_SB: "itms-jfcSB",
  justify_contents_SA: "itms-jfcSA",
  justify_contents_SE: "itms-jfcSE",
};

const alignClassNames = {
  // no class name for start
  align_start: "",
  align_end: "ExAlgE",
  align_center: "ExAlgC",
};

const buttonStyleClassNames = {
  button_style_link: "ExBtnL",
  button_style_primary: "ExBtn1",
  button_style_secondary: "ExBtn2",
};

const buttonHeightClassNames = {
  button_height_sm: "ExSm",
};

const cornerRadiusClassNames = {
  none: "None",
  xs: "ExBdrRadXs",
  sm: "ExBdrRadSm",
  md: "ExBdrRadMd",
  lg: "ExBdrRadLg",
  xl: "ExBdrRadXl",
  xxl: "ExBdrRadXXl",
};

const classNames = cn.bind({
  ...bubbleSizeClassNames,
  ...directionClassNames,
  ...blockClassNames,
  ...layoutClassNames,
  ...componentClassNames,
  ...componentSizeClassNames,
  ...aspectModeClassNames,
  ...booleanClassNames,
  ...marginClassNames,
  ...spacingClassNames,
  ...flexClassNames,
  ...flexAlignItems,
  ...flexJustifyContents,
  ...alignClassNames,
  ...buttonStyleClassNames,
  ...buttonHeightClassNames,
  ...cornerRadiusClassNames,
});

const StyleProvider = styled.div`
  * {
    box-sizing: border-box;
    padding: 0;
    border: 0 solid transparent;
    margin: 0;
    font-size: 100%;
    font-style: normal;
    font-weight: normal;
    list-style: none;
  }

  .LyGi {
    width: 400px;

    /* width: 218px; */
  }

  .LyMe {
    width: 300px;

    /* width: 163px; */
  }

  .LyKi {
    width: 260px;

    /* width: 141px; */
  }

  .LyMi {
    width: 160px;

    /* width: 87px; */
  }

  .LyNa {
    width: 120px;

    /* width: 65px; */
  }

  a:active,
  a:focus,
  a:hover,
  a:link,
  a:visited {
    text-decoration: none;
  }

  .LySlider {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }

  .LySlider::-webkit-scrollbar {
    display: none;
  }

  .LySlider .lyInner {
    display: -webkit-box;
    display: flex;
    width: 100%;
  }

  .LySlider .lyInner::after {
    display: block;
    width: 7px;
    height: 1px;
    flex: none;
    -webkit-box-flex: 0;
    content: "";
  }

  .LySlider .lyItem {
    display: inherit;
    width: 80%;
    max-width: 300px;
    flex: none;
    margin-left: 7px;
    -webkit-box-flex: 0;
  }

  .LySlider .lyItem + .lyItem {
    margin-left: 9px;
  }

  .LySlider .lyItem.LyGi {
    max-width: 500px;

    /* max-width: 273px; */
  }

  .LySlider .lyItem.LyMe {
    max-width: 300px;

    /* max-width: 163px; */
  }

  .LySlider .lyItem.LyKi {
    max-width: 260px;

    /* max-width: 141px; */
  }

  .LySlider .lyItem.LyMi {
    max-width: 160px;

    /* max-width: 87px; */
  }

  .LySlider .lyItem.LyNa {
    max-width: 120px;

    /* max-width: 65px; */
  }

  .LySlider .T1 {
    flex: 1 1 auto;
    -webkit-box-flex: 1;
  }

  .LySlider .T1 .t1Header {
    flex: none;
    -webkit-box-flex: 0;
  }

  .LySlider .T1 .t1Hero {
    flex: none;
    -webkit-box-flex: 0;
  }

  .LySlider .T1 .t1Hero:last-child {
    border-radius: 0;
  }

  .LySlider .T1 .t1Body {
    flex: 1 1 auto;
    -webkit-box-flex: 1;
  }

  .LySlider .T1 .t1Body > .MdBx.vr {
    flex: 1 1 auto;
    -webkit-box-flex: 1;
  }

  .LyGi .T1 {
    max-width: 500px;
    border-radius: 5px;
    margin: 0 7px;
  }

  .LyMe .T1 {
    border-radius: 17px;
  }

  .LyKi .T1 {
    border-radius: 10px;
  }

  .LyMi .T1 {
    border-radius: 10px;
  }

  .LyNa .T1 {
    border-radius: 10px;
  }

  .T1 {
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    border-radius: 17px;
    background-color: #fff;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }

  .T1 .t1Body,
  .T1 .t1Footer,
  .T1 .t1Header,
  .T1 .t1Hero {
    display: inherit;
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }

  .T1 .t1Hero:first-child,
  .T1 .t1Hero:first-child .MdImg.ExFull,
  .T1 .t1Hero:first-child .MdImg.ExFull > div,
  .T1 .t1Hero:first-child .MdImg.ExFull > div > a,
  .T1 .t1Hero:first-child .MdImg.ExFull > div > a > span {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  .T1 .t1Hero:last-child,
  .T1 .t1Hero:last-child .MdImg.ExFull,
  .T1 .t1Hero:last-child .MdImg.ExFull > div,
  .T1 .t1Hero:last-child .MdImg.ExFull > div > a,
  .T1 .t1Hero:last-child .MdImg.ExFull > div > a > span {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  .T1 .t1Hero:first-child .MdImg.ExFull > div > a > span,
  .T1 .t1Hero:last-child .MdImg.ExFull > div > a > span {
    overflow: hidden;
  }

  .T1 .t1Header > .MdBx {
    padding: 20px;
  }

  .LyKi .T1 .t1Header > .MdBx {
    padding: 13px;
  }

  .LyMi .T1 .t1Header > .MdBx {
    padding: 10px;
  }

  .LyNa .T1 .t1Header > .MdBx {
    padding: 10px;
  }

  .T1 .t1Body > .MdBx {
    padding: 20px;
    padding-top: 19px;
  }

  .LyKi .T1 .t1Body > .MdBx {
    padding: 13px;
  }

  .LyMi .T1 .t1Body > .MdBx {
    padding: 10px;
  }

  .LyNa .T1 .t1Body > .MdBx {
    padding: 10px;
  }

  .T1 .t1Body.ExHasFooter > .MdBx {
    padding-bottom: 10px;
  }

  .LyKi .T1 .t1Body.ExHasFooter > .MdBx {
    padding-bottom: 17px;
  }

  .T1 .t1Footer > .MdBx {
    padding: 10px;
  }

  .fxLTR,
  [dir="ltr"] {
    direction: ltr;
  }

  .fxRTL,
  [dir="rtl"] {
    direction: rtl;
  }

  .MdHide {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
  }

  .MdNonDisp {
    display: none !important;
  }

  .MdBtn,
  .MdBx,
  .MdIco,
  .MdImg,
  .MdTxt,
  [class*="MdSep"] {
    display: -webkit-box;
    display: flex;
    width: auto;
    min-width: 0;
    max-width: 100%;
    flex: 1 1 auto;
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-flex: 1;
    -webkit-box-orient: vertical;
  }

  .MdBx {
    position: relative;
    overflow: hidden;
  }

  .MdBx.vr > div {
    flex: none;
    -webkit-box-flex: 0;
  }

  .MdBx.hr {
    width: 100%;
    flex-direction: row;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }

  .MdBx.hr > div {
    overflow: hidden;
    width: 0;
    flex: 1 1 auto;
    -webkit-box-flex: 1;
  }

  .MdBx.hr > div.MdSep {
    overflow: visible;
  }

  .MdBx.hr > div.fl0 {
    width: auto;
  }

  .MdBx.hr > div.fl0.MdImg.ExFull {
    width: 100%;
  }

  .MdBx.hr > div[class*="MdSep"] {
    flex: none;
    -webkit-box-flex: 0;
  }

  .MdBx.hr.bl {
    align-items: baseline;
    -webkit-box-align: baseline;
  }

  .MdBx.hr > div.ExAbs {
    width: auto;
  }

  .MdBx.hr.ExAbs {
    width: auto;
  }

  .MdBx.hr.ExAbs > div {
    width: auto;
  }

  .ExAbs .MdBx.hr {
    width: auto;
  }

  .ExAbs .MdBx.hr > div {
    width: auto;
  }

  .T1 .MdBx.hr.spcXs > div {
    margin-left: 2px;
  }

  .T1 .MdBx.hr.spcSm > div {
    margin-left: 4px;
  }

  .T1 .MdBx.hr.spcMd > div {
    margin-left: 8px;
  }

  .T1 .MdBx.hr.spcLg > div {
    margin-left: 12px;
  }

  .T1 .MdBx.hr.spcXl > div {
    margin-left: 16px;
  }

  .T1 .MdBx.hr.spcXXl > div {
    margin-left: 20px;
  }

  .T1 .MdBx.hr > div.mdBxFiller,
  .T1 .MdBx.hr > div.mdBxSpacer,
  .T1 .MdBx.hr > div:first-child {
    margin-left: 0;
  }

  .T1.fxRTL .MdBx.hr > div {
    margin-left: 0;
  }

  .T1.fxRTL .MdBx.hr.spcXs > div {
    margin-right: 2px;
  }

  .T1.fxRTL .MdBx.hr.spcSm > div {
    margin-right: 4px;
  }

  .T1.fxRTL .MdBx.hr.spcMd > div {
    margin-right: 8px;
  }

  .T1.fxRTL .MdBx.hr.spcLg > div {
    margin-right: 12px;
  }

  .T1.fxRTL .MdBx.hr.spcXl > div {
    margin-right: 16px;
  }

  .T1.fxRTL .MdBx.hr.spcXXl > div {
    margin-right: 20px;
  }

  .T1.fxRTL .MdBx.hr > div.mdBxFiller,
  .T1.fxRTL .MdBx.hr > div.mdBxSpacer,
  .T1.fxRTL .MdBx.hr > div:first-child {
    margin-right: 0;
  }

  .MdBx.itms-jfcS {
    justify-content: flex-start;
    -webkit-box-pack: start;
  }

  .MdBx.itms-jfcE {
    justify-content: flex-end;
    -webkit-box-pack: end;
  }

  .MdBx.itms-jfcC {
    justify-content: center;
    -webkit-box-pack: center;
  }

  .MdBx.itms-jfcSB {
    justify-content: space-between;
    -webkit-box-pack: justify;
  }

  .MdBx.itms-jfcSA {
    justify-content: space-around;
  }

  .MdBx.itms-jfcSE {
    justify-content: space-evenly;
  }

  .MdBx.itms-algS {
    align-items: flex-start;
    -webkit-box-align: start;
  }

  .MdBx.itms-algE {
    align-items: flex-end;
    -webkit-box-align: end;
  }

  .MdBx.itms-algC {
    align-items: center;
    -webkit-box-align: center;
  }

  .MdBx.itms-algBL {
    align-items: baseline;
    -webkit-box-align: baseline;
  }

  .MdBx.itms-algSR {
    align-items: stretch;
    -webkit-box-align: stretch;
  }

  .MdBx.vr.spcXs > div {
    margin-top: 2px;
  }

  .MdBx.vr.spcSm > div {
    margin-top: 4px;
  }

  .MdBx.vr.spcMd > div {
    margin-top: 8px;
  }

  .MdBx.vr.spcLg > div {
    margin-top: 12px;
  }

  .MdBx.vr.spcXl > div {
    margin-top: 16px;
  }

  .MdBx.vr.spcXXl > div {
    margin-top: 20px;
  }

  .MdBx.vr > div.mdBxFiller,
  .MdBx.vr > div.mdBxSpacer,
  .MdBx.vr > div:first-child {
    margin-top: 0;
  }

  .MdBx > div.fl0 {
    flex: none !important;
    -webkit-box-flex: 0 !important;
  }

  .MdBx > div.fl1 {
    flex: 1 1 auto !important;
    -webkit-box-flex: 1 !important;
  }

  .MdBx > div.fl2 {
    flex: 2 2 auto !important;
    -webkit-box-flex: 2 !important;
  }

  .MdBx > div.fl3 {
    flex: 3 3 auto !important;
    -webkit-box-flex: 3 !important;
  }

  .MdBx > div.algS {
    align-items: flex-start;
    -webkit-box-align: start;
  }

  .MdBx > div.algE {
    align-items: flex-end;
    -webkit-box-align: end;
  }

  .MdBx > div.grvC {
    justify-content: center;
    -webkit-box-pack: center;
  }

  .MdBx > div.grvB {
    justify-content: flex-end;
    -webkit-box-pack: end;
  }

  .T1 .MdBx > div.mdBxFiller {
    flex: 1 1 auto;
    -webkit-box-flex: 1;
  }

  .MdBx > div.mdBxSpacer {
    flex: none;
    -webkit-box-flex: 0;
  }

  .MdBx.hr > div.mdBxSpacer {
    height: 1px;
  }

  .MdBx.hr > div.mdBxSpacer.spcXs {
    width: 2px;
  }

  .MdBx.hr > div.mdBxSpacer.spcSm {
    width: 4px;
  }

  .MdBx.hr > div.mdBxSpacer.spcMd {
    width: 8px;
  }

  .MdBx.hr > div.mdBxSpacer.spcLg {
    width: 12px;
  }

  .MdBx.hr > div.mdBxSpacer.spcXl {
    width: 16px;
  }

  .MdBx.hr > div.mdBxSpacer.spcXXl {
    width: 20px;
  }

  .MdBx.hr[class*="spc"] > div.mdBxSpacer + div {
    margin-left: 0;
  }

  .MdBx.vr > div.mdBxSpacer {
    width: 1px;
  }

  .MdBx.vr > div.mdBxSpacer.spcXs {
    height: 2px;
  }

  .MdBx.vr > div.mdBxSpacer.spcSm {
    height: 4px;
  }

  .MdBx.vr > div.mdBxSpacer.spcMd {
    height: 8px;
  }

  .MdBx.vr > div.mdBxSpacer.spcLg {
    height: 12px;
  }

  .MdBx.vr > div.mdBxSpacer.spcXl {
    height: 16px;
  }

  .MdBx.vr > div.mdBxSpacer.spcXXl {
    height: 20px;
  }

  .MdBx[class*="spc"] > div.mdBxSpacer + div {
    margin-top: 0;
  }

  [class*="MdSep"] {
    width: 100%;
    box-sizing: content-box;
    flex: none;
    border: 0 solid #d4d6da;
    -webkit-box-flex: 0;
  }

  .MdSep {
    position: relative;
    flex: none;
    -webkit-box-flex: 0;
  }

  .MdSep::before {
    position: absolute;
    z-index: 1;
    display: block;
    width: 100%;
    height: 1px;
    box-sizing: content-box;
    border: inherit;

    /* border-top-width: 1px; */

    /* border-top 在 scale 後太不明顯，故這邊變 2px */
    border-top-width: 2px;
    content: "";
    transform: scaleY(0.5);
    transform-origin: left top;
  }

  .MdSep:last-child::before {
    border-top-width: 0;
    border-bottom-width: 1px;
    margin-top: -1px;
  }

  .fxRTL .MdSep::before {
    transform-origin: right top;
  }

  .MdSepB {
    border-top-width: 1px;
  }

  .MdBx.hr > div.MdSep::before {
    width: 1px;
    height: 100%;
    border-top-width: 0;
    border-left-width: 1px;
    transform: scaleX(0.5);
  }

  .MdBx.hr > div.MdSep:last-child::before {
    border-right-width: 1px;
    border-left-width: 0;
    margin-top: 0;
    margin-left: -1px;
  }

  .MdBx.hr > div.MdSepB {
    border-top-width: 0;
    border-left-width: 1px;
  }

  .fxRTL .MdBx.hr > div.MdSep::before {
    border-right-width: 1px;
    border-left-width: 0;
  }

  .fxRTL .MdBx.hr > div.MdSep:last-child::before {
    border-right-width: 0;
    border-left-width: 1px;
    margin-right: -1px;
    margin-left: 0;
  }

  .MdTxt {
    position: relative;
  }

  .MdTxt p {
    ${cssOneLine}
  }

  .MdTxt p:empty {
    height: 1.4em;
  }

  .MdTxt.ExWrap p {
    text-overflow: clip;
    ${cssWrap}
    white-space: normal;
  }

  .MdTxt.Ex2nd {
    color: #aaa;
  }

  .MdTxt.ExAlgC p {
    text-align: center;
  }

  .MdTxt.ExAlgE p {
    text-align: end;
  }

  .MdSpn {
    font-style: inherit;
    font-weight: inherit;
    text-decoration: inherit;
  }

  .MdSpn.ExXXs {
    font-size: 11px;
  }

  .MdSpn.ExXs {
    font-size: 13px;
  }

  .MdSpn.ExSm {
    font-size: 14px;
  }

  .MdSpn.ExMd {
    font-size: 16px;
  }

  .MdSpn.ExLg {
    font-size: 19px;
  }

  .MdSpn.ExXl {
    font-size: 22px;
  }

  .MdSpn.ExXXl {
    font-size: 29px;
  }

  .MdSpn.Ex3Xl {
    font-size: 35px;
  }

  .MdSpn.Ex4Xl {
    font-size: 48px;
  }

  .MdSpn.Ex5Xl {
    font-size: 74px;
  }

  .MdIco {
    position: relative;
  }

  .MdIco span {
    display: inline-block;
    overflow: hidden;
    width: 1em;
    height: 1em;
    background: no-repeat center;
    background-size: contain;
    vertical-align: baseline;
  }

  .MdIco.ExAr2to1 span {
    width: 2em;
  }

  .MdIco.ExAr3to1 span {
    width: 3em;
  }

  .MdIco.ExXXs,
  .MdTxt.ExXXs {
    font-size: 11px;
  }

  .MdIco.ExXs,
  .MdTxt.ExXs {
    font-size: 13px;
  }

  .MdIco.ExSm,
  .MdTxt.ExSm {
    font-size: 14px;
  }

  .MdIco.ExMd,
  .MdTxt.ExMd {
    font-size: 16px;
  }

  .MdIco.ExLg,
  .MdTxt.ExLg {
    font-size: 19px;
  }

  .MdIco.ExXl,
  .MdTxt.ExXl {
    font-size: 22px;
  }

  .MdIco.ExXXl,
  .MdTxt.ExXXl {
    font-size: 29px;
  }

  .MdIco.Ex3Xl,
  .MdTxt.Ex3Xl {
    font-size: 35px;
  }

  .MdIco.Ex4Xl,
  .MdTxt.Ex4Xl {
    font-size: 48px;
  }

  .MdIco.Ex5Xl,
  .MdTxt.Ex5Xl {
    font-size: 74px;
  }

  .MdBtn {
    position: relative;
  }

  .MdBtn a {
    display: inherit;
    width: 100%;
    height: 52px;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    border-radius: 8px;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    font-size: 16px;
  }

  .MdBtn div {
    ${cssOneLine}
    max-width: 100%;
  }

  .MdBtn.ExBtn1 a {
    background-color: #17c950;
    color: #fff;
  }

  .MdBtn.ExBtn2 a {
    background-color: #dcdfe5;
    color: #111;
  }

  .MdBtn.ExBtnL a {
    color: #42659a;
  }

  .MdBtn.ExSm a {
    height: 40px;
  }

  .MdImg {
    position: relative;
    overflow: hidden;
    width: 100%;
    align-items: center;
    -webkit-box-align: center;
  }

  .MdImg > div {
    max-width: 100%;
  }

  .MdImg a {
    position: relative;
    display: block;
    width: 100%;
  }

  .MdImg span {
    position: absolute;
    display: block;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    inset: 0;
  }

  .MdImg.ExXXs > div {
    width: 40px;
  }

  .MdImg.ExXs > div {
    width: 60px;
  }

  .MdImg.ExSm > div {
    width: 80px;
  }

  .MdImg.ExMd > div {
    width: 100px;
  }

  .MdImg.ExLg > div {
    width: 120px;
  }

  .MdImg.ExXl > div {
    width: 140px;
  }

  .MdImg.ExXXl > div {
    width: 160px;
  }

  .MdImg.Ex3Xl > div {
    width: 180px;
  }

  .MdImg.Ex4Xl > div {
    width: 200px;
  }

  .MdImg.Ex5Xl > div {
    width: 220px;
  }

  .MdImg.ExFull > div {
    width: 100%;
  }

  .MdImg.ExAr1to1 a {
    padding-bottom: 100%;
  }

  .MdImg.ExAr1_51to1 a {
    padding-bottom: 66.22517%;
  }

  .MdImg.ExAr1_91to1 a {
    padding-bottom: 52.35602%;
  }

  .MdImg.ExAr20to13 a {
    padding-bottom: 65%;
  }

  .MdImg.ExAr4to3 a {
    padding-bottom: 75%;
  }

  .MdImg.ExAr16to9 a {
    padding-bottom: 56.25%;
  }

  .MdImg.ExAr2to1 a {
    padding-bottom: 50%;
  }

  .MdImg.ExAr3to1 a {
    padding-bottom: 33.33333%;
  }

  .MdImg.ExAr3to4 a {
    padding-bottom: 133.33333%;
  }

  .MdImg.ExAr9to16 a {
    padding-bottom: 177.77778%;
  }

  .MdImg.ExAr1to2 a {
    padding-bottom: 200%;
  }

  .MdImg.ExAr1to3 a {
    padding-bottom: 300%;
  }

  .MdImg.ExFit span {
    background-size: contain;
  }

  .MdImg.ExCover span {
    background-size: cover;
  }

  .MdImg.ExCircle span {
    border-radius: 50%;
  }

  .MdImg.ExMirrored span {
    transform: scaleX(-1);
  }

  .MdImg.ExAbs {
    width: auto;
  }

  .MdImg.ExAbs.ExFull {
    width: 100%;
  }

  .ExMgnANone {
    margin: 0 !important;
  }

  .ExMgnAXs {
    margin: 2px !important;
  }

  .ExMgnASm {
    margin: 4px !important;
  }

  .ExMgnAMd {
    margin: 8px !important;
  }

  .ExMgnALg {
    margin: 12px !important;
  }

  .ExMgnAXl {
    margin: 16px !important;
  }

  .ExMgnAXXl {
    margin: 20px !important;
  }

  .ExMgnTNone {
    margin-top: 0 !important;
  }

  .ExMgnTXs {
    margin-top: 2px !important;
  }

  .ExMgnTSm {
    margin-top: 4px !important;
  }

  .ExMgnTMd {
    margin-top: 8px !important;
  }

  .ExMgnTLg {
    margin-top: 12px !important;
  }

  .ExMgnTXl {
    margin-top: 16px !important;
  }

  .ExMgnTXXl {
    margin-top: 20px !important;
  }

  .ExMgnBNone {
    margin-bottom: 0 !important;
  }

  .ExMgnBXs {
    margin-bottom: 2px !important;
  }

  .ExMgnBSm {
    margin-bottom: 4px !important;
  }

  .ExMgnBMd {
    margin-bottom: 8px !important;
  }

  .ExMgnBLg {
    margin-bottom: 12px !important;
  }

  .ExMgnBXl {
    margin-bottom: 16px !important;
  }

  .ExMgnBXXl {
    margin-bottom: 20px !important;
  }

  .ExMgnLNone {
    margin-left: 0 !important;
  }

  .ExMgnLXs {
    margin-left: 2px !important;
  }

  .ExMgnLSm {
    margin-left: 4px !important;
  }

  .ExMgnLMd {
    margin-left: 8px !important;
  }

  .ExMgnLLg {
    margin-left: 12px !important;
  }

  .ExMgnLXl {
    margin-left: 16px !important;
  }

  .ExMgnLXXl {
    margin-left: 20px !important;
  }

  .ExMgnRNone {
    margin-right: 0 !important;
  }

  .ExMgnRXs {
    margin-right: 2px !important;
  }

  .ExMgnRSm {
    margin-right: 4px !important;
  }

  .ExMgnRMd {
    margin-right: 8px !important;
  }

  .ExMgnRLg {
    margin-right: 12px !important;
  }

  .ExMgnRXl {
    margin-right: 16px !important;
  }

  .ExMgnRXXl {
    margin-right: 20px !important;
  }

  .ExPadANone {
    padding: 0 !important;
  }

  .ExPadAXs {
    padding: 2px !important;
  }

  .ExPadASm {
    padding: 4px !important;
  }

  .ExPadAMd {
    padding: 8px !important;
  }

  .ExPadALg {
    padding: 12px !important;
  }

  .ExPadAXl {
    padding: 16px !important;
  }

  .ExPadAXXl {
    padding: 20px !important;
  }

  .ExPadTNone {
    padding-top: 0 !important;
  }

  .ExPadTXs {
    padding-top: 2px !important;
  }

  .ExPadTSm {
    padding-top: 4px !important;
  }

  .ExPadTMd {
    padding-top: 8px !important;
  }

  .ExPadTLg {
    padding-top: 12px !important;
  }

  .ExPadTXl {
    padding-top: 16px !important;
  }

  .ExPadTXXl {
    padding-top: 20px !important;
  }

  .ExPadBNone {
    padding-bottom: 0 !important;
  }

  .ExPadBXs {
    padding-bottom: 2px !important;
  }

  .ExPadBSm {
    padding-bottom: 4px !important;
  }

  .ExPadBMd {
    padding-bottom: 8px !important;
  }

  .ExPadBLg {
    padding-bottom: 12px !important;
  }

  .ExPadBXl {
    padding-bottom: 16px !important;
  }

  .ExPadBXXl {
    padding-bottom: 20px !important;
  }

  .ExPadLNone {
    padding-left: 0 !important;
  }

  .ExPadLXs {
    padding-left: 2px !important;
  }

  .ExPadLSm {
    padding-left: 4px !important;
  }

  .ExPadLMd {
    padding-left: 8px !important;
  }

  .ExPadLLg {
    padding-left: 12px !important;
  }

  .ExPadLXl {
    padding-left: 16px !important;
  }

  .ExPadLXXl {
    padding-left: 20px !important;
  }

  .ExPadRNone {
    padding-right: 0 !important;
  }

  .ExPadRXs {
    padding-right: 2px !important;
  }

  .ExPadRSm {
    padding-right: 4px !important;
  }

  .ExPadRMd {
    padding-right: 8px !important;
  }

  .ExPadRLg {
    padding-right: 12px !important;
  }

  .ExPadRXl {
    padding-right: 16px !important;
  }

  .ExPadRXXl {
    padding-right: 20px !important;
  }

  .ExBdrWdtNone {
    border-width: 0 !important;
  }

  .ExBdrWdtLgh {
    border-width: 0.5px !important;
  }

  .ExBdrWdtNml {
    border-width: 1px !important;
  }

  .ExBdrWdtMdm {
    border-width: 2px !important;
  }

  .ExBdrWdtSbd {
    border-width: 3px !important;
  }

  .ExBdrWdtBld {
    border-width: 4px !important;
  }

  .ExBdrRadNone {
    border-radius: 0 !important;
  }

  .ExBdrRadXs {
    border-radius: 2px !important;
  }

  .ExBdrRadSm {
    border-radius: 4px !important;
  }

  .ExBdrRadMd {
    border-radius: 8px !important;
  }

  .ExBdrRadLg {
    border-radius: 12px !important;
  }

  .ExBdrRadXl {
    border-radius: 16px !important;
  }

  .ExBdrRadXXl {
    border-radius: 20px !important;
  }

  .ExAbs {
    position: absolute;
    margin: 0 !important;
  }

  .ExTNone {
    top: 0;
  }

  .ExTXs {
    top: 2px;
  }

  .ExTSm {
    top: 4px;
  }

  .ExTMd {
    top: 8px;
  }

  .ExTLg {
    top: 12px;
  }

  .ExTXl {
    top: 16px;
  }

  .ExTXXl {
    top: 20px;
  }

  .ExBNone {
    bottom: 0;
  }

  .ExBXs {
    bottom: 2px;
  }

  .ExBSm {
    bottom: 4px;
  }

  .ExBMd {
    bottom: 8px;
  }

  .ExBLg {
    bottom: 12px;
  }

  .ExBXl {
    bottom: 16px;
  }

  .ExBXXl {
    bottom: 20px;
  }

  .ExLNone {
    left: 0;
  }

  .ExLXs {
    left: 2px;
  }

  .ExLSm {
    left: 4px;
  }

  .ExLMd {
    left: 8px;
  }

  .ExLLg {
    left: 12px;
  }

  .ExLXl {
    left: 16px;
  }

  .ExLXXl {
    left: 20px;
  }

  .ExRNone {
    right: 0;
  }

  .ExRXs {
    right: 2px;
  }

  .ExRSm {
    right: 4px;
  }

  .ExRMd {
    right: 8px;
  }

  .ExRLg {
    right: 12px;
  }

  .ExRXl {
    right: 16px;
  }

  .ExRXXl {
    right: 20px;
  }

  .ExWR {
    font-weight: normal !important;
  }

  .ExWR * {
    font-weight: inherit;
  }

  .ExWB {
    font-weight: bold !important;
  }

  .ExWB * {
    font-weight: inherit;
  }

  .ExFntStyNml {
    font-style: normal !important;
  }

  .ExFntStyNml * {
    font-style: inherit;
  }

  .ExFntStyIt {
    font-style: italic !important;
  }

  .ExFntStyIt * {
    font-style: inherit;
  }

  .ExTxtDecNone {
    text-decoration: none !important;
  }

  .ExTxtDecUl {
    text-decoration: underline !important;
  }

  .ExTxtDecLt {
    text-decoration: line-through !important;
  }
`;

export { classNames, StyleProvider };
