import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import type { ElementRef, FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ShowMoreButton } from "@/components/Button/ShowMoreButton";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";
import { ChannelTypeChattingIcon } from "@/resources/channel/ChannelTypeIcon";
import { useSortChannels } from "@/resources/channel/useSortChannels";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { Menu } from "@/routes/Chat/ui/SideMenu/components/Menu";
import { Count } from "@/routes/Chat/ui/SideMenu/Count";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  channelButtonIcon: css({
    fontSize: 20,
    transition: "scale 0.3s ease",
    "button:hover &": {
      scale: "1.1",
    },
  }),
  channelButtonDisconnected: css({
    [[
      "&:not(:active, :focus, :hover)",
      "&:not(:active, :focus, :hover) *",
    ].join(", ")]: {
      color: theme.colors.neutral005,
    },
  }),
  toggleExpandedButton: css({
    fontSize: "0.875rem",
    "&>button:not(:active, :focus, :hover)": {
      color: theme.colors.blue006,
    },
  }),
});

function useChannels() {
  const channels = ChannelsQueriesContext.useData().channels;
  const sortChannels = useSortChannels();
  const processedChannels = useMemo(
    () => sortChannels(channels),
    [channels, sortChannels],
  );
  return processedChannels;
}

const ChannelButton = forwardRef<
  ElementRef<typeof Menu.ButtonItem>,
  Omit<
    ComponentProps<typeof Menu.ButtonItem>,
    "children" | "buttonItemProps"
  > & {
    channel: Pick<CantataTypes["Channel"], "id" | "name" | "type" | "status">;
  }
>(function ChannelButton({ channel, ...props }, ref) {
  const { t } = useTranslation();
  const activeChannelId = searchController.useStore((state) => state.channelId);
  const onClick = useHandler(function onClick() {
    searchController.setState(() => ({
      channelId: channel.id,
    }));
  });
  const cssButton = useMemo(
    () =>
      css(
        channel.status === "disconnected" && styles.channelButtonDisconnected,
      ),
    [channel.status],
  );
  return (
    <Menu.ButtonItem
      css={cssButton}
      ref={ref}
      {...props}
      start={
        <ChannelTypeChattingIcon
          channel={channel}
          css={styles.channelButtonIcon}
        />
      }
      end={
        channel.status === "connected" ? (
          <Count channelId={channel.id} />
        ) : channel.status === "token-expired" ? (
          <Tooltip
            title={t("resource.channel.status.options.tokenExpired.tooltip")}
          >
            <MotifIcon
              un-i-motif="warning_fill"
              style={{
                color: theme.colors.yellow006,
              }}
            />
          </Tooltip>
        ) : channel.status === "disconnected" ? (
          <Tooltip
            title={t("resource.channel.status.options.disconnected.tooltip")}
          >
            <MotifIcon un-i-motif="disconnect" />
          </Tooltip>
        ) : (
          (() => {
            channel.status satisfies never;
            throw new Error(
              inspectMessage`Unexpected channel status, channel: ${channel}`,
            );
          })()
        )
      }
      onClick={onClick}
      active={channel.id === activeChannelId}
    >
      {channel.name}
    </Menu.ButtonItem>
  );
});

const collapsedSize = 5;

const Channels: FC = () => {
  const { t } = useTranslation();
  const activeChannelId = searchController.useStore((state) => state.channelId);
  const channels = useChannels();
  const [expanded, toggleExpanded] = useSwitch(false);
  const displayChannels = useMemo(
    () =>
      expanded || channels.length <= collapsedSize
        ? channels
        : channels.slice(0, collapsedSize),
    [channels, expanded],
  );
  const collapsable = channels.length > collapsedSize;

  const unSelectChannel = useHandler(function unSelectChannel() {
    searchController.clearChannelId();
  });

  return (
    <Menu.Root label={t("chat.memberPanel.filter.channel.title")}>
      <Menu.ButtonItem
        onClick={unSelectChannel}
        active={!activeChannelId}
        end={<Count />}
      >
        {t("resource.channel.selector.option.all.label")}
      </Menu.ButtonItem>
      <Menu.List>
        {displayChannels.map((channel) => (
          <ChannelButton key={channel.id} channel={channel} />
        ))}
        {collapsable && (
          <ShowMoreButton
            component={Menu.ButtonItem}
            onChange={toggleExpanded}
            expandType="showAll"
            css={styles.toggleExpandedButton}
            icon={null}
          />
        )}
      </Menu.List>
    </Menu.Root>
  );
};

function useChannelsQueries() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channelsQueries = cantata.channel.useList(
    {
      params: { orgId },
    },
    {
      select: (data) => data.channels,
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const queries = useMemo(
    () => ({ channels: channelsQueries }),
    [channelsQueries],
  );
  return queries;
}

const ChannelsQueriesContext =
  createQueriesContext<ReturnType<typeof useChannelsQueries>>();

const Wrapped: FC = () => {
  const queries = useChannelsQueries();
  return (
    <ErrorBoundary.Alert>
      <ChannelsQueriesContext.Provider queries={queries}>
        <Channels />
      </ChannelsQueriesContext.Provider>
    </ErrorBoundary.Alert>
  );
};

export { Wrapped as Channels };
