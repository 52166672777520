import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import type { ElementRef } from "react";

import { FileListItem } from "@/components/FileDropZone/FileListItem";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  FileList: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    alignItems: "center",
    margin: 0,
    maxWidth: "100%",
  }),
});

namespace FileList {
  export type Ref = ElementRef<"ul">;
  export interface OwnProps {
    // props here
  }
  export type Props = Overwrite<ComponentProps<"ul">, OwnProps>;
}

const FileListInternal = forwardRef<FileList.Ref, FileList.Props>(
  function FileListInternal(props, ref) {
    return <ul css={styles.FileList} ref={ref} {...props} />;
  },
);

/**
 * A list of files.
 *
 * Design:
 * [Figma](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=6920-25270&m=dev)
 */
const FileList = Object.assign(FileListInternal, {
  Item: FileListItem,
  styles,
});

assignDisplayName(FileList, "FileList");

export { FileList };
