import { shallow } from "@zeffiroso/utils/zustand/shallow";
import type { FC } from "react";
import { createWithEqualityFn } from "zustand/traditional";

import type { LayoutProps } from "@/layout/SideMenuLayout/Outer/Layout/types";

function setupUtils() {
  type State = {
    panelExpanded: boolean;
  };
  const useStore = createWithEqualityFn<State>(
    () => ({
      panelExpanded: true,
    }),
    shallow,
  );
  const panel = {
    collapse() {
      useStore.setState({ panelExpanded: false });
    },
    expand() {
      useStore.setState({ panelExpanded: true });
    },
  };
  const api = {
    useStore,
    panel,
  };
  return api;
}

const utils = setupUtils();

const LayoutLteMobile: FC<LayoutProps> = ({ mainRef }) => {
  return (
    <div>
      <div ref={mainRef} />
    </div>
  );
};

const api = Object.assign(LayoutLteMobile, {
  utils,
});

export { api as LayoutLteMobile };
