import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Image } from "@/components/Image";
import { useGetMedialUrl } from "@/resources/message/utils";
import { ContentNotAvailable } from "@/routes/Chat/ui/ChatPanel/History/Messages/ContentNotAvailable";
import { messageUtil } from "@/routes/Chat/ui/ChatPanel/History/Messages/messageUtil";

type ImageProps = ComponentProps<typeof Image>;
type OnErrorEvent = Parameters<NonNullable<ImageProps["onError"]>>[0];
interface ImgProps {
  onError?: (e: OnErrorEvent) => void;
}

/**
 * By default, Ant Design Image has a width of 100%. However, this can cause
 * the image to stretch when it is smaller than the container. It can look odd
 * when it reaches the maximum height. To address this, we set the width to
 * auto, specifically for Safari.
 */
const cssBase = css({
  width: "auto",
  minHeight: "50px",
  maxHeight: "200px",
});

const Img: FC<ImgProps> = (props) => {
  const getMediaUrl = useGetMedialUrl();
  const message = messageUtil.useMessage();
  const { t } = useTranslation();
  const [errorEvent, setErrorEvent] = useState<OnErrorEvent | null>(null);
  const onError = useHandler<ImageProps["onError"]>(function onError(...args) {
    const e = args[0];
    setErrorEvent(e);
    props.onError?.(e);
  });
  const src = getMediaUrl(message);
  if (errorEvent) return <ContentNotAvailable />;

  const alt: ImageProps["alt"] = errorEvent
    ? t("resource.message.media.error.notAvailable.content")
    : message.senderType === "member"
      ? t("chat.memberPanel.memberSentPhoto")
      : t("chat.oaSentPhoto");
  return <Image css={cssBase} onError={onError} src={src} alt={alt} />;
};

const Wrapper: FC<ImgProps> = (props) => {
  const getMediaUrl = useGetMedialUrl();
  const message = messageUtil.useMessage();
  const src = getMediaUrl(message);
  return <Img key={src} {...props} />;
};

assignDisplayName(Wrapper, "Img");

export { Img };
