import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { mergeWith } from "lodash-es";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { Checkbox } from "@/components/Checkbox";
import { ExternalLink } from "@/components/ExternalLink";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import { BarLoading } from "@/components/Loading/BarLoading";
import { useMessage } from "@/components/message";
import { Radio } from "@/components/Radio";
import { Headline2, SubText } from "@/components/Typography";
import { useUserPermission } from "@/shared/application/user";

const Headline = styled(Headline2)`
  margin-bottom: 16px;
  font-weight: 500;
`;

const Description = styled(SubText)`
  display: block;
  margin-bottom: 8px;
`;

type FormValues = Partial<CantataTypes["AssignmentGeneralSetting"]>;

const GeneralSetting = memo(function GeneralSetting() {
  const [form] = Form.useForm<FormValues>();
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const message = useMessage();
  const query = cantata.assignmentGeneralSetting.useGetSettings({
    params: {
      orgId,
    },
  });
  const [defaultFormValues] = useState<
    CantataTypes["AssignmentGeneralSetting"]
  >({
    enableDefaultAssignment: false,
    assignmentType: "case-based",
  });
  const updateMutation = cantata.assignmentGeneralSetting.useUpdate(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess: () => {
        form.resetFields();
        message.success(
          t("assignment.generalSetting.request.success.message.toast"),
        );
      },
    },
  );
  const { hasPermission } = useUserPermission();
  const hasEditPermission = hasPermission("editAssignmentGeneralSetting");

  const onValuesChange = useHandler<FormProps<FormValues>["onValuesChange"]>(
    function onValuesChange() {
      updateMutation.reset();
    },
  );

  const routerPromptOptions = useMemo<
    FormProps<FormValues>["routerPromptOptions"]
  >(() => {
    return {
      disabled: updateMutation.isSuccess,
    };
  }, [updateMutation.isSuccess]);

  const onFinish = useHandler<FormProps<FormValues>["onFinish"]>(
    function onFinish(values) {
      if (!query.isSuccess) return;
      const nextValues = mergeWith(
        {},
        query.data,
        values,
        (objValue, srcValue) => {
          if (srcValue === undefined) return objValue;
          return srcValue;
        },
      );
      updateMutation.mutate(nextValues);
    },
  );

  return query.isLoading ? (
    <BarLoading />
  ) : query.isError ? (
    <Alert type="error" message={query.error.message} />
  ) : (
    <Form
      form={form}
      disabled={!hasEditPermission || updateMutation.isLoading}
      routerPromptOptions={routerPromptOptions}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
    >
      <Flex
        css={css`
          justify-content: space-between;
        `}
      >
        <Headline>
          {t("assignment.generalSetting.defaultAssignment.title")}
        </Headline>
        <Button
          type="primary"
          htmlType="submit"
          loading={updateMutation.isLoading}
        >
          {t("common.save")}
        </Button>
      </Flex>
      <Form.Item
        name="enableDefaultAssignment"
        valuePropName="checked"
        getValueProps={(v) => ({
          checked:
            v ??
            query.data?.enableDefaultAssignment ??
            defaultFormValues.enableDefaultAssignment,
        })}
      >
        <Checkbox
          css={css`
            span:not(.ant-checkbox) {
              color: ${theme.colors.neutral007};
            }
          `}
        >
          {t("assignment.generalSetting.defaultAssignment.description")}
        </Checkbox>
      </Form.Item>
      <Headline>{t("assignment.generalSetting.title")}</Headline>
      <Description>{t("assignment.generalSetting.desc")}</Description>
      <ExternalLink
        href={t("feature.assignmentGeneralSetting.helpCenter.link")}
        trailingIcon
        css={css`
          margin-bottom: 24px;
        `}
      >
        {t("common.learnMore")}
      </ExternalLink>
      <Form.Item
        name="assignmentType"
        getValueProps={(v) => ({
          value:
            v ?? query.data?.assignmentType ?? defaultFormValues.assignmentType,
        })}
      >
        <Radio.Group>
          <Radio value="case-based">{t("assignment.caseBaseAssignment")}</Radio>
          <Radio value="permanent">{t("assignment.permanentAssignment")}</Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});

export { GeneralSetting };
