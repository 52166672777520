import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { FC } from "react";

import { Form } from "@/components/Form";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { MemberGenderSelect } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/MemberGenderSelect";

const GenderField: FC = () => {
  const profile = memberQueriesContext.useMember();
  const form = Form.useFormInstance();

  const onBlur = useHandler(function onBlur() {
    form.submit();
  });

  return (
    <Form.Item
      name="gender"
      noStyle
      getValueProps={(v) => ({ value: v ?? profile.gender })}
    >
      <MemberGenderSelect popupMatchSelectWidth={false} onBlur={onBlur} />
    </Form.Item>
  );
};

export { GenderField };
