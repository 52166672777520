import type { DropDownProps as AntDropDownProps } from "antd";
// eslint-disable-next-line no-restricted-imports -- This should be the only place importing Dropdown directly.
import { Dropdown as AntDropdown } from "antd";
// eslint-disable-next-line no-restricted-imports -- import button directly to avoid circular dependency.
import Button from "antd/es/dropdown/dropdown-button";
import type { FC } from "react";

type DropdownProps = AntDropDownProps;

const defaultTrigger: AntDropDownProps["trigger"] = ["click"];

const plugins = {
  /**
   * Do not use `AntDropdown.Button` directly, use `Dropdown` instead because
   * of circular dependency.
   */
  Button,
};

namespace Dropdown {
  export type Props = DropdownProps;
}

const DropdownInternal: FC<Dropdown.Props> = (props) => {
  return <AntDropdown trigger={defaultTrigger} {...props} />;
};

const Dropdown = Object.assign(DropdownInternal, plugins);

export { Dropdown };
