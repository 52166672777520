import type { configs } from "@/config/sentryDebug";
import type { createSentryDebug } from "@/internal/sentryDebug";
import { sentryDebug as internalSentryDebug } from "@/internal/sentryDebug";

type SentryDebugType = ReturnType<typeof createSentryDebug<typeof configs>>;

/**
 * Assert the type depending on the config for `sentryDebug`.
 */
const sentryDebug: SentryDebugType =
  internalSentryDebug as unknown as SentryDebugType;

export { sentryDebug };
