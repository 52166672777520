import type { FC } from "react";

import { assignErrorUtilsContext } from "@/routes/Chat/ui/ChatPanel/Header/components/Assignee/assignErrorUtilsContext";
import { BlockMemberModalProvider } from "@/routes/Chat/ui/ChatPanel/Header/components/BlockMemberModal";
import { LeaveGroupModalProvider } from "@/routes/Chat/ui/ChatPanel/Header/components/LeaveGroupModal";
import { mutationsController } from "@/routes/Chat/ui/ChatPanel/Header/mutationsController";
import { UiNarrow } from "@/routes/Chat/ui/ChatPanel/Header/UiNarrow";
import { UiWide } from "@/routes/Chat/ui/ChatPanel/Header/UiWide";
import { PinMessage } from "@/routes/Chat/ui/ChatPanel/Pin";
import { Layout } from "@/routes/Chat/ui/Layout";

const ChatPanelHeader: FC = () => {
  const isNarrow = Layout.breakpointHooks.useLteSm();
  return (
    <PinMessage.Provider>
      <assignErrorUtilsContext.Provider>
        <mutationsController.Provider>
          <BlockMemberModalProvider>
            <LeaveGroupModalProvider>
              {isNarrow ? <UiNarrow /> : <UiWide />}
            </LeaveGroupModalProvider>
          </BlockMemberModalProvider>
        </mutationsController.Provider>
      </assignErrorUtilsContext.Provider>
    </PinMessage.Provider>
  );
};

export { ChatPanelHeader };
