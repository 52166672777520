import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { useIsDifferentAccountSignedInStore } from "@/app/auth";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { Modal } from "@/components/Modal";
import { useNavigate } from "@/router/utils";
import {
  useAuthStatus,
  useLogoutMutation,
} from "@/shared/application/authenticate";
import { useLastUserEmailStore } from "@/shared/utils/createZustandStorageStore";

/**
 * Modal UI only.
 */
const SignInConflictModal: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logoutMutation = useLogoutMutation();
  const logout = useHandler(function logout() {
    logoutMutation.mutate({
      redirectToHome: false,
    });
  });
  const goHome = useHandler(function goHome() {
    navigate("/", { replace: true });
  });
  return logoutMutation.isLoading ? (
    <Modal.Loading open />
  ) : (
    <Modal.Warning
      open
      title={t("invite.signInConflicted.modal.title")}
      closable={false}
      onOk={logout}
      okText={t("invite.signInConflicted.modal.action.signOut.label")}
      onCancel={goHome}
      cancelText={t("invite.signInConflicted.modal.action.goHome.label")}
    >
      {t("invite.signInConflicted.modal.description")}
    </Modal.Warning>
  );
};

/**
 * Show modal when the user is signed in but the email is different.
 */
const SignInConflict: FC<{
  email: string;
}> = ({ email }) => {
  const authStatus = useAuthStatus();
  const lastEmail = useLastUserEmailStore((state) => state.value);
  const isDifferentAccountSignedInStore = useIsDifferentAccountSignedInStore();
  if (authStatus.status !== "signedIn" || !email || lastEmail === email)
    return null;
  return (
    <DisabledContextProvider disabled={isDifferentAccountSignedInStore}>
      <SignInConflictModal />;
    </DisabledContextProvider>
  );
};

export { SignInConflict };
