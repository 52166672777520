import { useCallback, useMemo } from "react";

import type { CantataTypes } from "@/cantata/types";

type Member = Pick<CantataTypes["Member"], "assignType">;

function check({ member }: { member: Member }) {
  return member.assignType !== "permanent";
}

/**
 * To determine if current user can view member history.
 */
function useCanViewConversationHistories() {
  const exec = useCallback(({ member }: { member: Member }): boolean => {
    return check({ member });
  }, []);

  const returnValue = useMemo(
    () => ({
      exec,
    }),
    [exec],
  );
  return returnValue;
}

export { useCanViewConversationHistories };
