import EventEmitter from "eventemitter3";

import type { ApiErrorResponseData } from "@/shared/domains/error";

type MutationErrorEvents = {
  error: (err: unknown) => void;
  knownError: (err: ApiErrorResponseData) => void;
};

const mutationErrorEventEmitter = new EventEmitter<MutationErrorEvents>();

export { mutationErrorEventEmitter };
export type { MutationErrorEvents };
