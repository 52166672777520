import type { CantataTypes } from "@/cantata/types";

const finishedStates: CantataTypes["Conversation"]["processingState"][] = [
  "blocked",
  "resolved",
];

function shouldFallbackToMemberCurrentAssignee(
  conversation: Pick<
    CantataTypes["Conversation"],
    "processingState" | "resolverAssignmentRelationship"
  >,
): boolean {
  return (
    !finishedStates.includes(conversation.processingState) &&
    !conversation.resolverAssignmentRelationship?.user &&
    !conversation.resolverAssignmentRelationship?.team
  );
}

export { shouldFallbackToMemberCurrentAssignee };
