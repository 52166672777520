import { boolean } from "@chatbotgang/etude/pitch-shifter/boolean";

import { LOCAL_STORAGE_LOGIN_FROM_SSO_PAGE } from "@/appConstant";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const parser = boolean();

const fromSsoPageStorageStore = createZustandStorageStore(
  LOCAL_STORAGE_LOGIN_FROM_SSO_PAGE,
  parser,
  {
    forUser: false,
  },
);

function useFromSsoPage() {
  const fromSsoPage = fromSsoPageStorageStore.useStore((state) => state.value);
  return fromSsoPage;
}

/**
 * Storage store for the value of whether the user is coming from the SSO page.
 */
const fromSsoPageStore = {
  useValue: useFromSsoPage,
  getValue: fromSsoPageStorageStore.useStore.getState().value,
  setValue(value: ReturnType<typeof parser>) {
    fromSsoPageStorageStore.useStore.setState({ value });
  },
  clearValue() {
    fromSsoPageStorageStore.useStore.setState({ value: false });
  },
  useStore: fromSsoPageStorageStore.useStore,
};

export { fromSsoPageStore };
