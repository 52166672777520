import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import type { MessageSchema } from "@zeffiroso/cantata/models";
import type { z } from "zod";

function isDeleted(
  message: Pick<z.infer<typeof MessageSchema>, "deletedAt">,
): boolean {
  return message.deletedAt instanceof Date && !isInvalidDate(message.deletedAt);
}

export { isDeleted };
