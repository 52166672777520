import type { css } from "@emotion/react";

/**
 * Since `@container` is not currently supported by the
 * `@styled/typescript-styled-plugin`, we need to use this function as a
 * workaround to avoid complaints from the ts-styled-plugin.
 *
 * @example
 *
 * ```ts
 * import { css } from '@emotion/react';
 * import { getCssStr } from '@/shared/utils/style/getCssStr';
 *
 * // For css prop
 * const cssContainer = css`
 *   ...
 *   ${emotionContainer(css, 'screen and (min-width: 768px)', (css) => css`
 *    ...
 *  `)}
 * `;
 *
 * // For styled component
 * const Container = styled.div`
 *   ...
 *   ${getCssStr(
 *     (css) => emotionContainer(css, 'screen and (min-width: 768px)', (css) => css`
 *       ...
 *     `),
 *   )}
 * `;
 * ```
 *
 */
function emotionContainer<Css extends typeof String.raw | typeof css>(
  cssLiteral: Css,
  condition: string,
  callback: (css: Css) => ReturnType<Css>,
): ReturnType<Css> {
  return cssLiteral`
    @container ${condition} {
      ${callback(cssLiteral)}
    }
  ` as ReturnType<Css>;
}

export { emotionContainer };
