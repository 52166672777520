import { memo } from "@chatbotgang/etude/react/memo";

import { OldEditor } from "@/routes/Chat/ui/ChatPanel/Editor/Old/Editor";

export const Editor = memo(function Editor() {
  /**
   * TODO: Waiting for final design.
   *
   * @see {@link https://www.figma.com/file/kkbHkY1sN6DHStoBBvJMNr/CAAC_Phase1_20220210%E2%9C%85?node-id=2%3A129539&t=GpharyvScczi7hqJ-4}
   */
  return <OldEditor />;
});
