import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const UserTransferOutlined = generateIcon(
  function UserTransferOutlined() {
    return (
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.64087 3.20509C7.69788 3.20509 6.12277 4.79259 6.12277 6.75088C6.12277 8.70916 7.69788 10.2967 9.64087 10.2967C11.5839 10.2967 13.159 8.70916 13.159 6.75088C13.159 4.79259 11.5839 3.20509 9.64087 3.20509ZM4.59632 6.75088C4.59632 3.94292 6.85484 1.66663 9.64087 1.66663C12.4269 1.66663 14.6854 3.94292 14.6854 6.75088C14.6854 9.55883 12.4269 11.8351 9.64087 11.8351C9.56536 11.8351 9.49024 11.8335 9.41554 11.8301V11.8351C8.28988 11.8351 6.7186 12.149 5.44939 13.1021C4.21836 14.0266 3.19344 15.6082 3.19344 18.3333H1.66699C1.66699 15.1536 2.8954 13.1015 4.53737 11.8685C5.1843 11.3827 5.88291 11.033 6.57504 10.7887C5.37204 9.85951 4.59632 8.3965 4.59632 6.75088ZM15.4645 12.6703L14.4841 11.5833L15.6136 10.5485L18.3337 13.5641L15.6136 16.5797L14.4841 15.5448L15.6921 14.2056C14.3688 14.2028 13.5969 14.5349 13.1321 14.9931C12.4918 15.6243 12.2068 16.7081 12.2068 18.3333H10.6803C10.6803 16.6252 10.9586 14.9837 12.0647 13.8933C12.8682 13.1011 13.9961 12.705 15.4645 12.6703Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);
