import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { makeApi, makeParameters } from "@zodios/core";
import { z } from "zod";

import { ChannelTypeSchema, TeamSchema, UserSchema } from "../models";

const DashboardQueryParameters = makeParameters([
  {
    type: "Query",
    name: "startTime",
    schema: Ymd.YmdToYmdStringSchema,
  },
  {
    type: "Query",
    name: "endTime",
    schema: Ymd.YmdToYmdStringSchema,
  },
  {
    type: "Query",
    name: "channelId",
    schema: z.number().int().array().optional(),
  },
  {
    type: "Query",
    name: "channelType",
    schema: ChannelTypeSchema.array().optional(),
  },
]);

const PerformanceMetricSchema = z.object({
  newMember: z.number(),
  handledMember: z.number(),
  newGroup: z.number(),
  handledGroup: z.number(),
  newConversation: z.number(),
  handledConversation: z.number(),
  resolvedConversation: z.number(),
  unresolvedConversation: z.number(),
  messageSent: z.number(),
  firstResponseTime: z.number().nullable(),
  timeToResolution: z.number().nullable(),
});

const TeamPerformanceSchema = z.object({
  teamId: TeamSchema.shape.id,
  teamName: TeamSchema.shape.name,
  metrics: PerformanceMetricSchema,
});

const UserPerformanceSchema = z.object({
  userId: UserSchema.shape.id,
  userName: UserSchema.shape.name,
  userAvatar: UserSchema.shape.avatar,
  metrics: PerformanceMetricSchema,
});

const UserInTeamPerformanceSchema = z.object({
  ...UserPerformanceSchema.shape,
  teamId: TeamSchema.shape.id,
  teamName: TeamSchema.shape.name,
});

const UserAcrossTeamPerformanceSchema = z.object({
  ...UserPerformanceSchema.shape,
  teamId: TeamSchema.shape.id.optional(),
  teamName: TeamSchema.shape.name.optional(),
});

const api = makeApi([
  /**
   * Get team performance metrics
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#1a966f8adfca481d8670d9e6244ab7cc)
   */
  {
    alias: "getTeamPerformanceMetrics",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/teams/performance",
    parameters: DashboardQueryParameters,
    response: TeamPerformanceSchema.array(),
  },

  /**
   * Get user performance metrics
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#074499fa8b764d279c4ddbd532f2bd81)
   */
  {
    alias: "getUserPerformanceMetrics",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/users/performance",
    parameters: DashboardQueryParameters,
    response: UserPerformanceSchema.array(),
  },

  /**
   * Get user performance metrics in team
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#e422dbe2bb754af596b569756fc292e5)
   */
  {
    alias: "getUserPerformanceMetricsInTeam",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/teams/:teamId/users/performance",
    parameters: DashboardQueryParameters,
    response: UserInTeamPerformanceSchema.array(),
  },

  /**
   * Get user performance metrics across teams
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#6a7e26640f7f4e7486ebd7bb540b9ee2)
   */
  {
    alias: "getUserPerformanceMetricsAcrossTeams",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/users/:userId/performance",
    parameters: DashboardQueryParameters,
    response: UserAcrossTeamPerformanceSchema.array(),
  },

  /**
   * Export team report to CSV
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#e82e7b9c5fff4f8284a9e91e7c25e653)
   */
  {
    alias: "exportTeamsReportCsv",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/teams/performance/export-csv",
    parameters: DashboardQueryParameters,
    response: z.string(),
    responseType: "blob",
  },

  /**
   * Export user report to CSV
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/Dashboard-061b3099b0ad43c0913f9e1bad0172ba?pvs=4#05a6c16ca2544199b626053dda985128)
   */
  {
    alias: "exportUsersReportCsv",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/users/performance/export-csv",
    parameters: DashboardQueryParameters,
    response: z.string(),
    responseType: "blob",
  },
]);

export { api };
