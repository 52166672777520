import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const RoundPictureOutlined = generateIcon(
  function RoundPictureOutlined() {
    return (
      <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
        <circle
          cx={8.5}
          cy={8.5}
          r={1.5}
          stroke="currentColor"
          strokeWidth={1.5}
        />
        <rect
          x={3}
          y={3}
          width={18}
          height={18}
          rx={2}
          stroke="currentColor"
          strokeWidth={1.5}
        />
        <path
          d="m3 16.016 3.281-3.836 3.483 4.157L15.208 10l5.755 6.71"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
