import { Line as AntLine } from "@ant-design/plots";
import type { BaseStyleProps, ParsedBaseStyleProps } from "@antv/g-lite";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
import { flow, merge, values } from "lodash-es";
import objectInspect from "object-inspect";
import { type ElementRef, useMemo } from "react";
import type { Get } from "type-fest";

import { EMPTY_STRING_PLACEHOLDER, RANGE_STRING_JOINER } from "@/appConstant";
import { baseDefaultProps } from "@/components/antdPlots/base/baseDefaultProps";
import type { FieldProp, Primitive } from "@/components/antdPlots/types/common";
import type {
  PlotComponentProps,
  TypedComponent,
} from "@/components/antdPlots/types/component";
import { useFormatDate } from "@/resources/datetime";
import { defineStyles } from "@/shared/emotion";

type LineType = TypedComponent<typeof AntLine>;
type LineProps<
  T extends object,
  TXFieldProp extends FieldProp<T, Primitive> = FieldProp<T, Primitive>,
  TYFieldProp extends FieldProp<T, Primitive> = FieldProp<T, Primitive>,
  TStyleProps extends BaseStyleProps = BaseStyleProps,
  TParsedStyleProps extends ParsedBaseStyleProps = ParsedBaseStyleProps,
> = OverrideWith<
  ComponentProps<typeof AntLine>,
  PlotComponentProps<
    T,
    TXFieldProp,
    TYFieldProp,
    TStyleProps,
    TParsedStyleProps
  >
>;
type LineRef = ElementRef<LineType>;

/**
 * The styled Line component based on Ant Design's Line.
 */
const Line: LineType = forwardRef<LineRef, LineProps<object>>(
  function Line(props, ref) {
    const formatDate = useFormatDate();
    const lineCount = useMemo(
      () =>
        flow(
          () => props.data,
          (data) =>
            Object.groupBy(data ?? [], (item, index) =>
              objectInspect(
                !props.colorField
                  ? ""
                  : typeof props.colorField === "function"
                    ? props.colorField(item, index, data ?? [])
                    : item[props.colorField],
              ),
            ),
          values,
          (values) => values.length,
        )(),
      [props],
    );
    const defaultScaleColorRange = useMemo<
      NonNullable<Get<LineProps<object>, ["scale", "color", "range"]>>
    >(() => {
      if (lineCount <= 2) {
        return [theme.colors.blue006, theme.colors.green006];
      }
      return [
        theme.colors.blue006,
        theme.colors.blue004,
        theme.colors.blue003,
        theme.colors.green006,
        theme.colors.green004,
        theme.colors.green003,
      ];
    }, [lineCount]);
    const defaultFormatValue = useMemo<(value: unknown) => string>(
      () =>
        function defaultFormatValue(value) {
          if (value === null) {
            return EMPTY_STRING_PLACEHOLDER;
          }
          if (value instanceof Date) {
            return formatDate(value);
          }
          if (typeof value === "string") {
            return value;
          }
          return objectInspect(value);
        },
      [formatDate],
    );
    /**
     * Similar to `defaultFormatValue`, but also works for ranges like `[start,
     * end]`.
     */
    const defaultFormatter = useMemo<(value: unknown) => string>(
      () =>
        function defaultFormatter(value) {
          if (Array.isArray(value) && value.length === 2) {
            const start = defaultFormatValue(value[0]);
            const end = defaultFormatValue(value[1]);
            if (start === end) return start;
            return [start, end].join(RANGE_STRING_JOINER);
          }
          return defaultFormatValue(value);
        },
      [defaultFormatValue],
    );
    const defaultProps: Partial<LineProps<object>> = useMemo(
      () => ({
        style: {
          lineWidth: 2,
        },
        autoFit: true,
        axis: {
          x: {
            labelFormatter: defaultFormatter,
            labelAutoRotate: false,
          },
          y: {
            labelFormatter: defaultFormatter,
          },
        },
        scale: {
          color: {
            range: defaultScaleColorRange,
          },
        },
        legend: {
          color: {
            itemMarker: "circle",
            position: "bottom",
          },
        },
        tooltip: {
          title: {
            valueFormatter: defaultFormatter,
          },
        },
      }),
      [defaultFormatter, defaultScaleColorRange],
    );
    const mergedProps = useMemo<LineProps<object>>(
      () => merge({}, baseDefaultProps, defaultProps, props),
      [defaultProps, props],
    );
    return <AntLine ref={ref} {...mergedProps} />;
  },
) as LineType;

/**
 * Reference: [Figma](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek/Insights?m=dev&node-id=1149-30336)
 */
const height =
  // line chart height
  229 +
  // gap
  16 +
  // legend height
  16;

const wrapperStyles = defineStyles({
  root: css({
    position: "relative",
    maxHeight: height,
    minHeight: height,
    height,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ">[data-chart-source-type]": {
      width: "100%",
      height: "100%",
    },
  }),
});

type WrapperRef = ElementRef<"div">;
type WrapperProps = ComponentProps<"div">;

/**
 * Wrap the Line component with a fixed height. This can be used to prevent the
 * layout shift when the chart is loading.
 */
const Wrapper = forwardRef<WrapperRef, WrapperProps>(
  function Wrapper(props, ref) {
    return <div css={wrapperStyles.root} ref={ref} {...props} />;
  },
);

assignDisplayName(Wrapper, "Line.Wrapper");

const api = Object.assign(Line, {
  Wrapper,
});

export { api as Line };
export type { LineProps, LineRef };
