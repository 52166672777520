import { Iso8601DateSchema } from "@chatbotgang/etude/zod/Iso8601DateSchema";
import { MemberSchema } from "@zeffiroso/cantata/models";
import { Iso8601HighResDateSchema } from "@zeffiroso/utils/zod/Iso8601HighResDateSchema";
import { pick } from "lodash-es";
import { z } from "zod";

/**
 * - Spec: [Notion](https://www.notion.so/cresclab/Real-Time-Web-Notification-7643cb44c55249eea3c1b7f6b27bde7a?pvs=4#a184339a36ae4062b11b284ab35afdc3)
 */
export const GroupMemberListUpdatedSchema = z.object({
  type: z.literal("group-member-list-updated"),
  content: z.object({
    ...pick(MemberSchema.shape, [
      "status",
      "processingState",
      "originalName",
      "displayName",
      "avatar",
    ]),
    idempotencyKey: z.string(),
    groupId: MemberSchema.shape.id,
    memberId: MemberSchema.shape.id,
    joinedAt: Iso8601DateSchema,
    inGroup: z.boolean(),
  }),
  triggerTime: Iso8601HighResDateSchema,
});
