import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { useCallback, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { hasPermission } from "@/shared/application/user";

type MemberWithAssignmentRelationship = Pick<
  CantataTypes["Member"],
  "assignmentRelationship"
>;

function check({
  me,
  myTeams,
  member,
}: {
  me: CantataTypes["UserMe"];
  myTeams: CantataTypes["Team"][];
  member: MemberWithAssignmentRelationship;
}) {
  return (
    me.permission.manualAssignmentAll ||
    // Unassigned member
    (!member.assignmentRelationship.team &&
      !member.assignmentRelationship.user) ||
    (hasPermission({
      user: me,
      permissionName: "canAssignMembersInMyTeams",
    }) &&
      myTeams.some(
        (team) =>
          member.assignmentRelationship.team &&
          team.id === member.assignmentRelationship.team.id,
      )) ||
    // Assigned to me
    Boolean(
      member.assignmentRelationship.user &&
        me.id === member.assignmentRelationship.user.id,
    )
  );
}

/**
 * To determine if the member can be assigned by the current user.
 */
function useCanAssignmentMember() {
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const meQuery = cantata.user.useGetMe({
    params: {
      orgId,
    },
  });
  const myTeamsQuery = cantata.team.useListMine({
    params: {
      orgId,
    },
  });

  const refetch = useHandler(function refetch() {
    safeInvalidateQuery(meQuery.key);
    safeInvalidateQuery(myTeamsQuery.key);
  });

  const exec = useCallback(
    ({ member }: { member: MemberWithAssignmentRelationship }): boolean => {
      if (!meQuery.isSuccess) return false;
      if (!myTeamsQuery.isSuccess) return false;
      return check({
        me: meQuery.data,
        myTeams: myTeamsQuery.data.teams,
        member,
      });
    },
    [
      meQuery.data,
      meQuery.isSuccess,
      myTeamsQuery.data?.teams,
      myTeamsQuery.isSuccess,
    ],
  );

  const returnValue = useMemo(
    () => ({
      meQuery,
      myTeamsQuery,
      isFetching: meQuery.isFetching || myTeamsQuery.isFetching,
      isLoading: meQuery.isLoading || myTeamsQuery.isLoading,
      isError: meQuery.isError || myTeamsQuery.isError,
      error: meQuery.error || myTeamsQuery.error,
      refetch,
      exec,
    }),
    [exec, meQuery, myTeamsQuery, refetch],
  );
  return returnValue;
}

export { useCanAssignmentMember };
