import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { useNow } from "@zeffiroso/utils/react/useNow";
import { Checkbox, Typography } from "antd";
import { deburr, flow, omitBy } from "lodash-es";
import type { ComponentProps, FC, ReactNode } from "react";
import { useMemo, useState } from "react";
import type { ConditionalKeys } from "type-fest";

import {
  APP_LOADED_DATE,
  GLOBAL_SEARCH_PARAM_FEATURE_FLAG,
} from "@/appConstant";
import { Button } from "@/components/Button";
import { CountDown } from "@/components/CountDown";
import { Divider } from "@/components/Divider";
import { Form } from "@/components/Form";
import { CopyInput, Input } from "@/components/Input";
import { PhIcon } from "@/components/PhIcon";
import { Radio } from "@/components/Radio";
import type { CommonFeatureFlagsTypes } from "@/internal/featureFlag";
import { commonFeatureFlagsApi } from "@/internal/featureFlag";
import type { BaseToggleFeatureFlagConfig } from "@/internal/featureFlag/baseTypes";
import { commonConfig } from "@/internal/featureFlag/commonConfig";
import { defineStyles } from "@/shared/emotion";
import { useVanillaLocation } from "@/shared/hooks/useVanillaLocation";

const {
  configs,
  isToggleFeatureFlagKey,
  isSingleSelectFeatureFlagKey,
  useFeatureFlagLocalStorageStore,
  useEnabledSomeFeatureFlags,
  useFeatureFlagStore,
} = commonFeatureFlagsApi;

const styles = defineStyles({
  root: css({
    display: "grid",
    gap: 8,
  }),
  topControls: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "inherit",
  }),
});

function useShouldAutoEnable(feature: BaseToggleFeatureFlagConfig) {
  const now = useNow();
  return feature.autoEnableAt !== undefined && feature.autoEnableAt < now;
}

function getAutoEnabled(feature: BaseToggleFeatureFlagConfig) {
  return (
    feature.autoEnableAt !== undefined && feature.autoEnableAt < APP_LOADED_DATE
  );
}

const AutoEnableMessage = memo(function AutoEnableMessage({
  featureFlagKey,
  autoEnableAt,
}: {
  featureFlagKey: CommonFeatureFlagsTypes["ToggleKey"];
  autoEnableAt: BaseToggleFeatureFlagConfig["autoEnableAt"];
}) {
  const autoEnabled = getAutoEnabled(configs[featureFlagKey]);
  const shouldAutoEnabled = useShouldAutoEnable(configs[featureFlagKey]);
  return (
    <>
      {autoEnableAt === undefined ? null : (
        <div style={{ paddingLeft: "2em" }}>
          {shouldAutoEnabled ? (
            autoEnabled ? (
              "Enabled automatically"
            ) : (
              "Please refresh to enable automatically"
            )
          ) : (
            <>
              Count down to auto enable: <CountDown targetDate={autoEnableAt} />
            </>
          )}
        </div>
      )}
    </>
  );
});

function getLabel(featureFlagKey: CommonFeatureFlagsTypes["Key"]) {
  return commonConfig[featureFlagKey].label ?? featureFlagKey;
}

const FeatureItemToggle = memo(function FeatureItemToggle<
  Key extends CommonFeatureFlagsTypes["ToggleKey"],
>({ featureFlagKey }: { featureFlagKey: Key }) {
  const label = getLabel(featureFlagKey);
  const feature = configs[featureFlagKey];
  const autoEnabled = getAutoEnabled(configs[featureFlagKey]);
  const checked: boolean = useFeatureFlagStore(
    (state) => state.value[featureFlagKey],
  );
  const onChange = useHandler(() =>
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: !current[featureFlagKey],
    })),
  );
  return (
    <>
      <Checkbox
        checked={checked ?? false}
        onChange={onChange}
        disabled={autoEnabled}
      >
        {label}
      </Checkbox>
      {feature.autoEnableAt === undefined ? null : (
        <AutoEnableMessage
          featureFlagKey={featureFlagKey}
          autoEnableAt={feature.autoEnableAt}
        />
      )}
    </>
  );
});

const FeatureItemSingleSelect = memo(function FeatureItemSingleSelect<
  Key extends CommonFeatureFlagsTypes["SingleSelectKey"],
>({ featureFlagKey }: { featureFlagKey: Key }) {
  const label = getLabel(featureFlagKey);
  const feature = configs[featureFlagKey];
  const value = useFeatureFlagStore((state) => state.value[featureFlagKey]);
  const enabled = value !== null;
  const changeHandler = useHandler<
    ComponentProps<typeof Radio.Group>["onChange"]
  >((e) => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: e.target
        .value as CommonFeatureFlagsTypes["Values"][Key],
    }));
  });
  const enable = useHandler(() => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: feature.options[0].value,
    }));
  });
  const disable = useHandler(() => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: null,
    }));
  });
  return (
    <div>
      <Checkbox checked={enabled} onChange={enabled ? disable : enable}>
        {label}
      </Checkbox>
      <div style={{ paddingLeft: "2em" }}>
        <Radio.Group onChange={changeHandler} value={value}>
          {feature.options.map(
            ({ value: targetValue, label = targetValue }) => (
              <Radio key={targetValue} value={targetValue}>
                {label}
              </Radio>
            ),
          )}
        </Radio.Group>
      </div>
    </div>
  );
});

const FeatureItem: FC<{
  featureFlagKey: CommonFeatureFlagsTypes["FunctionalKey"];
}> = ({ featureFlagKey }) => {
  if (isToggleFeatureFlagKey(featureFlagKey))
    return <FeatureItemToggle featureFlagKey={featureFlagKey} />;
  if (isSingleSelectFeatureFlagKey(featureFlagKey))
    return <FeatureItemSingleSelect featureFlagKey={featureFlagKey} />;
  featureFlagKey satisfies never;
  throw new Error(
    inspectMessage`Unhandled feature flag type: ${featureFlagKey}`,
  );
};

const configsGroupedByDivider = (function iife() {
  const result: Array<{
    divider: {
      key: string;
      label: ReactNode;
    };
    configs: Partial<
      Pick<
        typeof configs,
        ConditionalKeys<typeof configs, { type: "toggle" | "singleSelect" }>
      >
    >;
  }> = [
    {
      divider: {
        key: "",
        label: "Ungrouped",
      },
      configs: {},
    },
  ];
  Object.entries(configs).forEach(([key, value]) => {
    if (value.type === "divider") {
      result.push({
        divider: {
          key,
          label: value.label,
        },
        configs: {},
      });
    } else {
      Object.defineProperty(result[result.length - 1].configs, key, {
        value,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
  });
  return result;
})();

/**
 * Normalize the search string.
 */
function searchNormalize(search: string) {
  return flow(
    () => search,
    deburr,
    (search) => search.replaceAll(/[^a-zA-Z]+/g, ""),
    (search) => search.toLowerCase(),
  )();
}

const Feature: FC = () => {
  const { value, clear } = useFeatureFlagLocalStorageStore(
    ({ value, clear }) => ({
      value,
      clear,
    }),
  );

  const simplifiedValue = useMemo(() => {
    return flow(
      () => value,
      (value) =>
        Object.entries(value).flatMap(([featureFlagKey, value]) => {
          const typedFeatureFlagKey =
            featureFlagKey as CommonFeatureFlagsTypes["Key"];
          const featureConfig = commonConfig[typedFeatureFlagKey];
          if (featureConfig.type === "toggle" && !value) return [];

          if (featureConfig.type === "singleSelect" && value === null)
            return [];

          return [[featureFlagKey, value]];
        }),
      (entries) => Object.fromEntries(entries),
    )();
  }, [value]);

  const enabledSomeFeatureFlags = useEnabledSomeFeatureFlags();

  const location = useVanillaLocation();

  const url = useMemo(() => {
    const draftLocation = new URL(location);
    const params = new URLSearchParams(draftLocation.search);
    if (enabledSomeFeatureFlags) {
      params.set(
        GLOBAL_SEARCH_PARAM_FEATURE_FLAG,
        JSON.stringify(simplifiedValue),
      );
    } else {
      params.set(GLOBAL_SEARCH_PARAM_FEATURE_FLAG, "");
    }

    draftLocation.search = params.toString();
    return draftLocation.toString();
  }, [enabledSomeFeatureFlags, location, simplifiedValue]);

  const [search, setSearch] = useState("");
  const normalizedSearch = useMemo(() => searchNormalize(search), [search]);

  const filteredGroupedConfigs = useMemo(() => {
    return configsGroupedByDivider.flatMap<
      (typeof configsGroupedByDivider)[number]
    >((configsGroup) => {
      const filteredConfigs = omitBy(configsGroup.configs, (config, key) =>
        !normalizedSearch
          ? false
          : !searchNormalize(key).includes(normalizedSearch) &&
            !(
              config.label &&
              searchNormalize(config.label).includes(normalizedSearch)
            ),
      );
      if (Object.keys(filteredConfigs).length === 0) return [];
      return [
        {
          ...configsGroup,
          configs: filteredConfigs,
        },
      ];
    });
  }, [normalizedSearch]);

  return (
    <div css={styles.root}>
      <div css={styles.topControls}>
        <CopyInput value={url} />
        <Button onClick={clear} type="primary">
          Clear All
        </Button>
      </div>
      <ul
        css={css`
          list-style-type: none;
        `}
      >
        <Form.Item
          label="Search"
          style={{
            marginBottom: "0.75rem",
          }}
        >
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            allowClear
            prefix={<PhIcon un-i-ph="magnifying-glass" />}
          />
        </Form.Item>
        {filteredGroupedConfigs.length > 0 ? null : (
          <Typography.Text type="secondary">
            No feature flag found
          </Typography.Text>
        )}
        {filteredGroupedConfigs.map(({ divider, configs }) => (
          <li key={divider.key}>
            {divider.label && <Divider>{divider.label}</Divider>}
            <ul
              css={css`
                list-style-type: none;
              `}
            >
              {Object.entries(configs).map(([key]) => {
                const featureFlagKey =
                  key as CommonFeatureFlagsTypes["FunctionalKey"];
                return (
                  <li key={key}>
                    <FeatureItem featureFlagKey={featureFlagKey} />
                  </li>
                );
              })}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { Feature };
