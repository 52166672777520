import type { FC } from "react";

import type { CantataTypes } from "@/cantata/types";
import { Unnamed } from "@/resources/user/Unnamed";

type UserChatNameProps = Pick<CantataTypes["User"], "chatName">;

export const UserChatName: FC<UserChatNameProps> = (props) => {
  return props.chatName || <Unnamed />;
};
