import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import { merge, omit } from "lodash-es";
import { type ElementRef, type ReactNode, useMemo } from "react";

import { Button, type ButtonProps } from "@/components/Button";
import { cssOneLine, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    "& .anticon.anticon-right.ant-collapse-arrow": {
      color: theme.colors.neutral005,
      transform: "rotate(-90deg)",
    },
    "& .ant-collapse-item-active .anticon.anticon-right.ant-collapse-arrow": {
      transform: "rotate(0deg)",
    },
    "& .ant-collapse-header": {
      color: theme.colors.neutral007,
      fontWeight: 400,
      fontSize: "0.875rem",
      paddingBlock: 8,
      paddingInline: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 8,
      borderRadius: 4,
      "&:focus-visible": {
        outlineColor: theme.colors.primaryOutline,
        outlineOffset: 1,
        outlineStyle: "solid",
        outlineWidth: 4,
      },
    },
    "& .ant-collapse-content-box": {
      padding: 0,
    },
  }),
  list: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 8,
    margin: 0,
  }),
  item: css({
    backgroundColor: "transparent",
    paddingBlock: 4,
    paddingInline: 12,
    display: "flex",
    gap: 8,
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    boxShadow: "none",
    color: theme.colors.neutral009,
  }),
  itemStart: css({
    display: "block",
    lineHeight: "normal",
  }),
  itemBody: css([
    cssOneLine,
    {
      flex: 1,
      display: "block",
      textAlign: "start",
    },
  ]),
  itemEnd: css({
    display: "block",
    lineHeight: "normal",
  }),
  buttonItemRoot: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "inherit",
    padding: 0,
  }),
  buttonItemButton: css({
    border: 0,
    borderRadius: 4,
    "&:not(:active, :focus, :hover)": {
      color: "inherit",
    },
    "&:hover": {
      color: theme.colors.blue005,
    },
    "&:focus": {
      color: "inherit",
    },
  }),
  buttonItemButtonActive: css({
    backgroundColor: theme.colors.blue002,
    color: theme.colors.blue007,
    "&:not(:active, :focus, :hover)": {
      fontWeight: 500,
      color: theme.colors.blue006,
    },
    "&:focus": {
      color: theme.colors.blue007,
    },
  }),
});

type RootRef = ElementRef<typeof Collapse>;
/**
 * Turn a list of items into a single collapsible item.
 */
type RootProps = Omit<
  ComponentProps<typeof Collapse>,
  "items" | "defaultActiveKey" | "activeKey"
> & {
  label: ReactNode;
  children: ReactNode;
  expanded?: boolean;
  defaultExpanded?: boolean;
};
const collapseDefaultProps: Partial<RootProps> = {
  ghost: true,
  defaultExpanded: true,
  expandIconPosition: "end",
};
const Root = forwardRef<RootRef, RootProps>(function Root(
  { label, children, ...props },
  ref,
) {
  const mergedProps = useMemo(
    () => merge({}, collapseDefaultProps, props),
    [props],
  );
  const items = useMemo<ComponentProps<typeof Collapse>["items"]>(
    () => [
      {
        label,
        children,
      },
    ],
    [children, label],
  );
  const CollapseProps = useMemo<CollapseProps>(
    () =>
      merge({}, omit(mergedProps, ["defaultExpanded", "expanded"]), {
        ...(!("defaultExpanded" in mergedProps)
          ? null
          : {
              defaultActiveKey: mergedProps.defaultExpanded ? ["0"] : [],
            }),
        ...(!("expanded" in mergedProps)
          ? null
          : {
              activeKey: mergedProps.expanded ? ["0"] : [],
            }),
      }),
    [mergedProps],
  );
  return (
    <Collapse {...CollapseProps} css={styles.root} ref={ref} items={items} />
  );
});

type ListRef = ElementRef<"ul">;
type ListProps = ComponentProps<"ul">;
const List = forwardRef<ListRef, ListProps>(function List(props, ref) {
  return <ul css={styles.list} ref={ref} {...props} />;
});

type ItemRef = ElementRef<"li">;
type ItemProps = ComponentProps<"li"> & {
  start?: ReactNode;
  end?: ReactNode;
};
const Item = forwardRef<ItemRef, ItemProps>(function Item(
  { start, children, end, ...props },
  ref,
) {
  return (
    <li css={styles.item} ref={ref} {...props}>
      {!start ? null : <div css={styles.itemStart}>{start}</div>}
      <div css={styles.itemBody}>{children}</div>
      {!end ? null : <div css={styles.itemEnd}>{end}</div>}
    </li>
  );
});

type ButtonItemRef = ElementRef<"li">;
type ButtonItemProps = ComponentProps<"li"> & {
  start?: ReactNode;
  end?: ReactNode;
  buttonProps?: ButtonProps;
  active?: boolean;
};
const ButtonItem = forwardRef<ButtonItemRef, ButtonItemProps>(
  function ButtonItem(
    { start, children, end, active, buttonProps, ...props },
    ref,
  ) {
    const cssButton = useMemo(
      () =>
        css([
          styles.item,
          styles.buttonItemButton,
          active ? styles.buttonItemButtonActive : null,
        ]),
      [active],
    );
    return (
      <li {...props} ref={ref} css={styles.buttonItemRoot}>
        <Button {...buttonProps} css={cssButton}>
          {!start ? null : <div css={styles.itemStart}>{start}</div>}
          <div css={styles.itemBody}>{children}</div>
          {!end ? null : <div css={styles.itemEnd}>{end}</div>}
        </Button>
      </li>
    );
  },
);

const Menu = {
  Root,
  List,
  Item,
  ButtonItem,
  styles,
};

interface MenuProps {
  Root: RootProps;
  List: ListProps;
  Item: ItemProps;
}

export { Menu };
export type { MenuProps };
