/**
 * Although the message function is not a component. What people think it is is
 * more important than what exactly it is. We decide to move this file here to
 * make people find this file. Again, the folder structure should not be named
 * depending on what it is. It should depend on what its purpose is.
 *
 * @see {@link https://github.com/chatbotgang/Zeffiroso/pull/377}
 */

import { CloseOutlined } from "@ant-design/icons";
import { memo } from "@chatbotgang/etude/react/memo";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
// eslint-disable-next-line no-restricted-imports -- This is the only one adapter for antd message.
import { message as antdMessage } from "antd";
// eslint-disable-next-line no-restricted-imports -- Inherit the type from antd.
import type { MessageInstance, MessageType } from "antd/es/message/interface";
import type { FC, ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo } from "react";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";

type JointContent = Parameters<typeof message.info>[0];
type ArgsProps = Extract<
  JointContent,
  {
    content: unknown;
  }
>;
type TypeOpen = typeof antdMessage.info;

const CloseButton = memo(function CloseButton({
  messageKey,
}: {
  messageKey: string | number;
}) {
  const message = useMessage();
  return (
    <NarrowIconButton
      css={css`
        & .anticon {
          margin-right: 0;
          color: ${theme.colors.neutral006};
        }
      `}
      onClick={() => {
        message.destroy(messageKey);
      }}
      icon={<CloseOutlined />}
      iconSize="small"
    />
  );
});

function withCloseButton<
  Fn extends TypeOpen | ((args: ArgsProps) => MessageType),
>(originalFn: Fn): Fn {
  type Args = Fn extends (...args: infer Args) => MessageType ? Args : never;
  const functionName = originalFn.name;
  const functionWithCloseButton: Fn = ((...args: Args): MessageType => {
    const content = args[0];
    const duration = typeof args[1] === "number" ? args[1] : undefined;
    const onClose = typeof args[1] === "function" ? args[1] : args[2];
    const contentObj: ArgsProps =
      content && typeof content === "object" && "content" in content
        ? { ...content }
        : {
            content,
            duration,
            onClose,
            key: random(),
          };
    const key = ("key" in contentObj && contentObj.key) || random();
    contentObj.key = key;
    contentObj.content = (
      <div
        css={css`
          display: inline-flex;
          align-items: center;
          justify-content: center;
          gap: 12px;
          text-align: left;
        `}
      >
        <div>{contentObj.content}</div>
        <CloseButton messageKey={key} />
      </div>
    );
    contentObj.duration = duration;
    contentObj.onClose = onClose;
    return originalFn(contentObj);
  }) as Fn;
  Object.defineProperty(functionWithCloseButton, "name", {
    value: `${functionName}WithCloseButton`,
  });
  return functionWithCloseButton;
}

const Container: FC<{ children: ReactNode }> = (props) => (
  <div
    css={css({
      display: "flex",
      flexDirection: "column",
    })}
    {...props}
  />
);
const Title: FC<{ children: ReactNode }> = (props) => (
  <h6
    css={css({
      fontSize: "0.875rem",
      color: theme.colors.neutral009,
      fontWeight: 700,
      marginBottom: 0,
    })}
    {...props}
  />
);
const Content: FC<{ children: ReactNode }> = (props) => <span {...props} />;

/**
 * @deprecated Use `useMessage` instead.
 * @see {@link useMessage}
 */
const message = (() => {
  const message: MessageInstance = Object.assign(
    {},
    {
      ...antdMessage,
    },
  );
  return Object.assign(message, {
    Container,
    Content,
    Title,
  });
})();

const Context = createContext<typeof message>(message);

function useMessage() {
  return useContext(Context);
}

const MessageProvider = memo(function MessageProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [messageApi, contextHolder] = antdMessage.useMessage();
  const extendedMessageApi = useMemo(
    () => ({
      ...message,
      ...messageApi,
      info: withCloseButton(messageApi.info),
      success: withCloseButton(messageApi.success),
      error: withCloseButton(messageApi.error),
      warning: withCloseButton(messageApi.warning),
      loading: withCloseButton(messageApi.loading),
      open: withCloseButton(messageApi.open),
    }),
    [messageApi],
  );
  useEffect(() => {
    // Override all properties of `message` with `messageApi`.
    Object.assign(message, extendedMessageApi);
  }, [extendedMessageApi]);
  return (
    <Context.Provider value={extendedMessageApi}>
      {children}
      {contextHolder}
    </Context.Provider>
  );
});

type AntdMessageMethod = Extract<
  keyof MessageInstance,
  "info" | "success" | "error" | "warning" | "loading"
>;

export { message, MessageProvider, useMessage };
export type { AntdMessageMethod };
