import { defineArrayPredicate } from "@chatbotgang/etude/array/defineArrayPredicate";

import type { CantataTypes } from "@/cantata/types";

const availableOrgFilter = defineArrayPredicate<CantataTypes["Org"]>()((
  organization,
) => {
  return organization.userStatus === "active";
});

export { availableOrgFilter };
