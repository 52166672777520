import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { random } from "@chatbotgang/etude/string/random";
import {
  type MutationOptions,
  useMutation,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";
import { getDomainFromEmail } from "@zeffiroso/utils/string/getDomainFromEmail";
import { generateAbortError } from "@zeffiroso/utils/vanilla/generateAbortError";
import { AxiosError } from "axios";
import {
  type AuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
} from "firebase/auth";
import { omit } from "lodash-es";
import { useMemo } from "react";

import { authProviderStore } from "@/app/auth/authProviderStore";
import { useTwoFaUtils } from "@/app/auth/useTwoFaUtils";
import { cantataClient } from "@/cantata";
import { authSdk } from "@/internal/firebase/auth";
import { useIsDifferentAccountSignedInStore } from "@/router/components/Protected/InOrg/useIsDifferentAccountSignedInStore";
import { updateLastUserEmail } from "@/shared/application/authenticate";
import { rememberDeviceTokenUtils } from "@/shared/services/rememberDeviceToken";
import { useTokenStore } from "@/shared/services/token";

/**
 * Get login provider and allow_password by domain.
 *
 * Returns null if domain is login by username and password. (404)
 *
 * Other errors will be thrown.
 */
async function getAuthProviders(
  domain: string,
  options?: {
    signal?: AbortSignal;
  },
): Promise<null | Awaited<ReturnType<typeof cantataClient.auth.authProvider>>> {
  if (!domain) return null;
  const result = await safePromise(() =>
    cantataClient.auth.authProvider({
      queries: {
        domain,
      },
      signal: options?.signal,
    }),
  );
  if (result.isError) {
    if (
      result.error instanceof AxiosError &&
      result.error.response?.status === 404
    )
      return null;

    throw result.error;
  }
  return result.data;
}

const useFirstSsoProviderQuery = (() => {
  const seed = random();
  type Options = {
    domain: string;
  };
  const getQueryKey = (options: Options) =>
    [
      {
        useFirstSsoProviderQuery: seed,
        ...options,
      },
    ] as const;
  type TQueryFnData = Awaited<ReturnType<typeof getFirstSsoProvider>>;
  type TError = unknown;
  type TQueryKey = ReturnType<typeof getQueryKey>;
  function useFirstSsoProviderQuery<TData = TQueryFnData>(
    options: Options,
    useQueryOptions?: Omit<
      UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
      "queryKey" | "queryFn"
    >,
  ) {
    const queryKey = useMemo<TQueryKey>(() => getQueryKey(options), [options]);
    return useQuery<TQueryFnData, TError, TData, TQueryKey>(
      queryKey,
      async ({ queryKey, signal }) => {
        const domain = queryKey[0].domain;
        if (!domain) return null;
        const provider = await getFirstSsoProvider(domain, { signal });
        return provider;
      },
      ...(!useQueryOptions ? [] : [useQueryOptions]),
    );
  }
  return Object.assign(useFirstSsoProviderQuery, {
    getQueryKey,
  });
})();

const useAllowPasswordAuthQuery = (() => {
  const seed = random();
  type Options = {
    domain: string;
  };
  const getQueryKey = (options: Options) =>
    [
      {
        useAllowPasswordAuthQuery: seed,
        ...options,
      },
    ] as const;
  type TQueryFnData = boolean;
  type TError = unknown;
  type TQueryKey = ReturnType<typeof getQueryKey>;
  function useAllowPasswordAuthQuery<TData = TQueryFnData>(
    options: Options,
    useQueryOptions?: Omit<
      UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
      "queryKey" | "queryFn"
    >,
  ) {
    const queryKey = useMemo<TQueryKey>(() => getQueryKey(options), [options]);
    return useQuery<TQueryFnData, TError, TData, TQueryKey>(
      queryKey,
      async (context): Promise<TQueryFnData> => {
        const domain = queryKey[0].domain;
        if (!domain) return false;
        const provider = await (async () => {
          const result = await safePromise(() =>
            cantataClient.auth.authProvider({
              queries: {
                domain,
              },
              signal: context?.signal,
            }),
          );
          if (result.isError) {
            if (
              result.error instanceof AxiosError &&
              result.error.response?.status === 404
            )
              return true;
            throw result.error;
          }
          return result.data.allowPassword;
        })();
        return provider;
      },
      ...(!useQueryOptions ? [] : [useQueryOptions]),
    );
  }
  return Object.assign(useAllowPasswordAuthQuery, {
    getQueryKey,
  });
})();

function newSsoProvider(pid: string): AuthProvider | null {
  if (pid.startsWith("saml.")) return new SAMLAuthProvider(pid);
  if (pid.startsWith("oidc.")) return new OAuthProvider(pid);
  return null;
}

async function getFirstSsoProvider(
  domain: string,
  options?: {
    signal?: AbortSignal;
  },
): Promise<null | AuthProvider> {
  const result = await safePromise(() =>
    cantataClient.auth.authProvider({
      queries: {
        domain,
      },
      signal: options?.signal,
    }),
  );
  if (result.isError) {
    if (
      result.error instanceof AxiosError &&
      result.error.response?.status === 404
    )
      return null;

    throw result.error;
  }
  if (!result.data.firebaseAuthProviders.length) return null;

  const ssoProvider: AuthProvider | undefined =
    result.data.firebaseAuthProviders.flatMap((pid) => {
      const ssoProvider = newSsoProvider(pid);
      if (!ssoProvider) return [];
      return [ssoProvider];
    })[0];

  return ssoProvider || null;
}

const useSignInUtils = (() => {
  type TData =
    | Awaited<ReturnType<typeof cantataClient.auth.signIn>>
    | Awaited<ReturnType<typeof cantataClient.auth.verifyTwoFa>>
    | Awaited<ReturnType<typeof cantataClient.auth.firebaseSignIn>>;
  type TError = unknown;
  type TVariables =
    | Omit<
        Parameters<typeof cantataClient.auth.signIn>[0],
        // Remember token will be handled in this hook.
        "rememberToken"
      >
    | (Parameters<typeof cantataClient.auth.firebaseSignIn>[0] & {
        provider: AuthProvider;
        domain: string;
      });
  type TContext = undefined;

  type Options = Omit<
    MutationOptions<TData, TError, TVariables, TContext>,
    "mutationFn"
  >;
  function useSignInUtils(options?: Options) {
    const twoFaUtils = useTwoFaUtils();
    const twoFaMutations = twoFaUtils.useTwoFaUtilsMutations();
    const mutation = useMutation<TData, TError, TVariables, TContext>(
      async function signIn(variables) {
        if ("password" in variables) {
          const domain = getDomainFromEmail(variables.email);
          if (!domain)
            throw new Error(
              inspectMessage`Domain is empty. email: ${variables.email}`,
            );

          const authProviders = await getAuthProviders(domain);
          const allowPassword = !authProviders || authProviders.allowPassword;
          if (allowPassword) {
            const requestTime = new Date();
            const rememberDeviceToken = rememberDeviceTokenUtils.getValue();
            const signInResponse = await cantataClient.auth.signIn({
              ...variables,
              rememberToken: !rememberDeviceToken ? null : rememberDeviceToken,
            });
            const responseTime = new Date();
            if (signInResponse.accountId && signInResponse.token) {
              return {
                accountId: signInResponse.accountId,
                token: signInResponse.token,
              };
            }
            if (signInResponse.requiresTwoFactor) {
              return twoFaUtils.startTwoFa({
                email: variables.email,
                password: variables.password,
                otpRequestTime: requestTime,
                otpResponseTime: responseTime,
                restAttempts: signInResponse.twoFactorMaxAttempts,
              });
            }
            throw new Error(
              inspectMessage`Unexpected response: ${signInResponse}`,
            );
          }
          const allowSsoProvider: AuthProvider | null =
            authProviders.firebaseAuthProviders.flatMap((pid) => {
              const provider = newSsoProvider(pid);
              if (!provider) return [];
              return [provider];
            })[0] || null;
          if (allowSsoProvider) {
            throw generateAbortError({
              cause: "The domain allows SSO provider only.",
            });
          }
          throw new Error(
            inspectMessage`No auth provider found. email: ${variables.email}`,
          );
        }

        return cantataClient.auth.firebaseSignIn(variables);
      },
      {
        ...options,
        async onSuccess(...args) {
          const token = args[0].token;
          const variables = args[1];
          useTokenStore.getState().setValue(token);
          await updateLastUserEmail();
          const rememberDeviceToken =
            "rememberToken" in args[0] ? args[0].rememberToken : null;
          if (rememberDeviceToken) {
            rememberDeviceTokenUtils.setValue(rememberDeviceToken);
          }
          if ("password" in variables) {
            authProviderStore.clearValue();
          } else {
            // Remember the auth provider used to sign in.
            authProviderStore.setValue({
              providerId: variables.provider.providerId,
              domain: variables.domain,
            });
          }
          await options?.onSuccess?.(...args);
        },
      },
    );
    const api = useMemo(
      () => ({
        twoFa: {
          utils: omit(twoFaUtils, [
            /**
             * We don't expose duplicated mutations.
             */
            "useTwoFaUtilsMutations",
          ]),
          mutations: twoFaMutations,
        },
        mutation,
      }),
      [mutation, twoFaMutations, twoFaUtils],
    );
    return api;
  }
  return useSignInUtils;
})();

export {
  authProviderStore,
  authSdk,
  getFirstSsoProvider,
  newSsoProvider,
  useAllowPasswordAuthQuery,
  useFirstSsoProviderQuery,
  useIsDifferentAccountSignedInStore,
  useSignInUtils,
};
