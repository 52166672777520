import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { Overwrite } from "@mui/types";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import type { SwitchProps } from "antd";
import { Switch } from "antd";
import {
  type ElementRef,
  type FC,
  type ReactNode,
  Suspense,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { useMessage } from "@/components/message";

const ParamsContext = createContext<{
  orgId: CantataTypes["Org"]["id"];
  ruleId: CantataTypes["AutoAssignmentRule"]["id"];
}>({
  name: "ParamsContext",
});

function useSetupQueriesContext() {
  const params = ParamsContext.useContext();
  const usersQuery = cantata.user.useList(
    {
      params: {
        orgId: params.orgId,
      },
    },
    {
      select: (data) => data.users,
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const assignmentRulesQuery = cantata.autoAssignment.useList(
    {
      params: {
        orgId: params.orgId,
      },
    },
    {
      select: (data) => data.autoAssignmentRules,
      suspense: true,
      useErrorBoundary: true,
    },
  );
  const queriesContextValue = useMemo(
    () => ({
      users: usersQuery,
      assignmentRules: assignmentRulesQuery,
    }),
    [assignmentRulesQuery, usersQuery],
  );
  return queriesContextValue;
}

const QueryContext =
  createQueriesContext<ReturnType<typeof useSetupQueriesContext>>();

const QueryContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const queries = useSetupQueriesContext();
  return (
    <QueryContext.Provider queries={queries}>{children}</QueryContext.Provider>
  );
};

namespace RuleStatusSwitch {
  export interface Props
    extends Omit<SwitchProps, "checked" | "onChange" | "isLoading"> {
    status: "on" | "off";
  }
  export type Ref = ElementRef<typeof Switch>;
}

const RuleStatusSwitchInternal = forwardRef<
  RuleStatusSwitch.Ref,
  RuleStatusSwitch.Props
>(function StatusSwitch({ status, ...props }, ref) {
  const { t } = useTranslation();
  const message = useMessage();
  const params = ParamsContext.useContext();
  const mutation = cantata.autoAssignment.useSwitchStatus(
    {
      params: {
        orgId: params.orgId,
        autoAssignmentRuleId: params.ruleId,
      },
    },
    {
      onSuccess: ({ status }) => {
        message.success(
          status === "on"
            ? t("feature.switchRuleStatus.enabled.success.message")
            : t("feature.switchRuleStatus.disabled.success.message"),
        );
      },
    },
  );

  const handleUpdate = useHandler<SwitchProps["onChange"]>(
    (checked: boolean) => {
      const draftStatus = checked ? "on" : "off";
      mutation.mutate({ status: draftStatus });
    },
  );

  const queresData = QueryContext.useData();

  const currentRule = useMemo(
    () => queresData.assignmentRules.find((rule) => rule.id === params.ruleId),
    [params.ruleId, queresData.assignmentRules],
  );

  const userAssigneeId = useMemo(
    () =>
      currentRule && currentRule.assignee.type === "an-agent"
        ? currentRule.assignee.userId
        : null,
    [currentRule],
  );
  const isUserAssignee = Boolean(userAssigneeId);
  const userAssignee = queresData.users.find(
    (user) => user.id === userAssigneeId,
  );

  const mergeFormDisabled = useMergeFormDisabled();

  const disabled = useMemo(
    () =>
      mergeFormDisabled(
        mutation.isLoading ||
          !currentRule ||
          (currentRule.status === "off" &&
            currentRule.assignee.type === null) ||
          (isUserAssignee &&
            (!userAssignee || userAssignee.status !== "active")),
      ),
    [
      currentRule,
      isUserAssignee,
      mergeFormDisabled,
      mutation.isLoading,
      userAssignee,
    ],
  );

  return (
    <Switch
      data-test={`rule-status-switch-${params.ruleId}`}
      loading={mutation.isLoading}
      checked={status === "on"}
      onChange={handleUpdate}
      checkedChildren={t("glossary.switchOn")}
      unCheckedChildren={t("glossary.switchOff")}
      disabled={disabled}
      {...props}
      ref={ref}
    />
  );
});

const WithBoundary = forwardRef<
  RuleStatusSwitch.Ref,
  Overwrite<
    RuleStatusSwitch.Props,
    {
      orgId: CantataTypes["Org"]["id"];
      ruleId: CantataTypes["AutoAssignmentRule"]["id"];
    }
  >
>(function WithBoundary({ orgId, ruleId, ...props }, ref) {
  const params = useMemo<
    ComponentProps<typeof ParamsContext.Provider>["value"]
  >(
    () => ({
      orgId,
      ruleId,
    }),
    [orgId, ruleId],
  );
  return (
    <ParamsContext.Provider value={params}>
      <Suspense fallback={<Switch loading disabled />}>
        <QueryContextProvider>
          <RuleStatusSwitchInternal {...props} ref={ref} />
        </QueryContextProvider>
      </Suspense>
    </ParamsContext.Provider>
  );
});

const RuleStatusSwitch = Object.assign(WithBoundary, {});

export { RuleStatusSwitch };
