import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const CloseCircleOutlined = generateIcon(function CloseCircleOutlined() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 2.66683C6.94509 2.66683 5.91394 2.97962 5.03688 3.56566C4.15982 4.15169 3.47623 4.98465 3.07256 5.95918C2.6689 6.93372 2.56328 8.00608 2.76907 9.04064C2.97485 10.0752 3.48281 11.0255 4.22869 11.7714C4.97457 12.5173 5.92488 13.0252 6.95944 13.231C7.99401 13.4368 9.06636 13.3312 10.0409 12.9275C11.0154 12.5239 11.8484 11.8403 12.4344 10.9632C13.0205 10.0861 13.3333 9.055 13.3333 8.00016C13.3333 6.58568 12.7714 5.22912 11.7712 4.22893C10.771 3.22873 9.41441 2.66683 7.99992 2.66683ZM4.29612 2.45703C5.39245 1.72449 6.68138 1.3335 7.99992 1.3335C9.76803 1.3335 11.4637 2.03587 12.714 3.28612C13.9642 4.53636 14.6666 6.23205 14.6666 8.00016C14.6666 9.3187 14.2756 10.6076 13.5431 11.704C12.8105 12.8003 11.7693 13.6548 10.5511 14.1594C9.33297 14.6639 7.99253 14.796 6.69932 14.5387C5.40611 14.2815 4.21823 13.6466 3.28588 12.7142C2.35353 11.7819 1.71859 10.594 1.46135 9.30077C1.20412 8.00756 1.33614 6.66711 1.84072 5.44894C2.34531 4.23077 3.19979 3.18957 4.29612 2.45703ZM5.52852 5.52876C5.78887 5.26841 6.21098 5.26841 6.47133 5.52876L7.99992 7.05735L9.52852 5.52876C9.78887 5.26841 10.211 5.26841 10.4713 5.52876C10.7317 5.78911 10.7317 6.21122 10.4713 6.47157L8.94273 8.00016L10.4713 9.52876C10.7317 9.78911 10.7317 10.2112 10.4713 10.4716C10.211 10.7319 9.78887 10.7319 9.52852 10.4716L7.99992 8.94297L6.47133 10.4716C6.21098 10.7319 5.78887 10.7319 5.52852 10.4716C5.26817 10.2112 5.26817 9.78911 5.52852 9.52876L7.05711 8.00016L5.52852 6.47157C5.26817 6.21122 5.26817 5.78911 5.52852 5.52876Z"
        fill="currentcolor"
      />
    </svg>
  );
});
