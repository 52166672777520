import type { FormRule } from "antd";
import { isEmpty } from "lodash-es";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import { config } from "@/resources/message/messageValidator";

const useTextRules = (channelType: CantataTypes["Channel"]["type"]) => {
  const { t } = useTranslation();
  return useCallback(
    (value: CantataTypes["QuickTemplateText"]) => {
      if (isEmpty(value.text))
        return Promise.reject(t("validation.emptyTextInput"));

      const maxLength = config.text.maxLength[channelType];

      if (value.text.length > maxLength) {
        return Promise.reject(
          t("validation.text.maxLength", { count: maxLength }),
        );
      }

      return Promise.resolve();
    },
    [t, channelType],
  );
};

const useImageRules = () => {
  const { t } = useTranslation();
  return useCallback(
    (value: CantataTypes["QuickTemplateImage"]) => {
      if (isEmpty(value.originUrl) || isEmpty(value.previewUrl))
        return Promise.reject(t("quickTemplate.validation.emptyImage"));

      return Promise.resolve();
    },
    [t],
  );
};

const useVideoRules = () => {
  const { t } = useTranslation();
  return useCallback(
    (value: CantataTypes["QuickTemplateVideo"]) => {
      if (isEmpty(value.originUrl) || isEmpty(value.previewUrl))
        return Promise.reject(t("quickTemplate.validation.emptyVideo"));

      return Promise.resolve();
    },
    [t],
  );
};

const useFileRules = () => {
  const { t } = useTranslation();
  return useCallback(
    (value: CantataTypes["QuickTemplateFile"]) => {
      if (isEmpty(value.originUrl))
        return Promise.reject(t("quickTemplate.validation.emptyFile"));

      return Promise.resolve();
    },
    [t],
  );
};

function useEditorRules(channelType: CantataTypes["Channel"]["type"]) {
  const validateTextMessage = useTextRules(channelType);
  const validateImageMessage = useImageRules();
  const validateVideoMessage = useVideoRules();
  const validateFileMessage = useFileRules();

  return useMemo<FormRule[]>(
    () => [
      () => ({
        validator(_, value) {
          switch (value.type) {
            case "text":
              return validateTextMessage(value);
            case "image":
              return validateImageMessage(value);
            case "video":
              return validateVideoMessage(value);
            case "file":
              return validateFileMessage(value);
            default:
              return Promise.resolve();
          }
        },
      }),
    ],
    [
      validateTextMessage,
      validateImageMessage,
      validateVideoMessage,
      validateFileMessage,
    ],
  );
}

export { useEditorRules };
