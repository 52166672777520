import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";
import { toFileSizeDisplayString } from "@zeffiroso/utils/file/toFileSizeDisplayString";
import { Upload } from "antd";
// eslint-disable-next-line no-restricted-imports -- Not exported from antd
import type { RcFile } from "antd/es/upload";
import { noop } from "lodash-es";
import { extname } from "pathe";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { MB } from "@/appConstant";
import { Avatar } from "@/components/Avatar";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { useMessage } from "@/components/message";
import { SubText } from "@/components/Typography";
import { uploadToFirebaseStorage } from "@/resources/attachment/uploadToFirebaseStorage";
import { AvatarAcceptExtensionsSchema } from "@/resources/user/userValidator";

const acceptAttribute = AvatarAcceptExtensionsSchema.options.join(",");

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  gap: 12px;
`;

const StyledAvatarBox = styled(Flex)`
  gap: 24px;
`;

type AvatarFormItemProps = {
  type: "user" | "org";
  orgId: number;
  userId?: number;
  value?: string;
  onChange?: (value: string) => void;
  compact?: boolean;
};

export const AvatarFormItem = fc<AvatarFormItemProps>(function AvatarFormItem({
  type,
  orgId,
  userId,
  value,
  onChange,
  compact,
}) {
  const { t } = useTranslation();
  const onChangeCallback = useHandler(onChange || noop);
  const message = useMessage();
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = useCallback(
    (file: RcFile) => {
      if (file.size > MB) {
        message.error(
          t("common.fileSizeExceedWithSize", {
            size: toFileSizeDisplayString(MB),
          }),
        );
        return false;
      }

      const fileExt = extname(file.name);
      if (
        !AvatarAcceptExtensionsSchema.options.includes(fileExt.toLowerCase())
      ) {
        return false;
      }

      (async function doUpload() {
        try {
          setIsUploading(true);

          const uploadedUrl =
            type === "user"
              ? userId
                ? await uploadToFirebaseStorage({
                    feature: "userAvatar",
                    pathParams: {
                      orgId,
                      userId,
                    },
                    file,
                  })
                : (() => {
                    throw new Error(
                      inspectMessage`userId is required, userId: ${userId}`,
                    );
                  })()
              : await uploadToFirebaseStorage({
                  feature: "orgAvatar",
                  pathParams: {
                    orgId,
                  },
                  file,
                });

          onChangeCallback(uploadedUrl.downloadUrl);
        } catch (error) {
          message.error(t("common.failedToUploadImage"));
        } finally {
          setIsUploading(false);
        }
      })();

      return false; // this is required to block the default Ant Upload flow, so we can run our own code logic only
    },
    [message, onChangeCallback, orgId, t, type, userId],
  );

  return (
    <StyledWrapper>
      <StyledAvatarBox>
        <Avatar src={value} />
        <Upload
          accept={acceptAttribute}
          showUploadList={false}
          beforeUpload={handleFileChange}
          disabled={isUploading}
        >
          <Button loading={isUploading}>{t("common.uploadImage")}</Button>
        </Upload>
      </StyledAvatarBox>
      {!compact && (
        <SubText>{t("organization.uploadFileSizeLimitHint")}</SubText>
      )}
    </StyledWrapper>
  );
});
