import { snakeCase } from "lodash-es";
import ReactGA4 from "react-ga4";
import snakecaseKeys from "snakecase-keys";

import type { Ga4Events } from "@/config/ga4";

const eventPrefix = "caac_";

function ga4Event<K extends keyof Ga4Events & string>(
  eventName: K,
  params: Ga4Events[K],
) {
  ReactGA4.event(eventPrefix + snakeCase(eventName), snakecaseKeys(params));
}

export { ga4Event };
