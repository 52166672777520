import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { useGetMedialUrl } from "@/resources/message/utils";
import { Img } from "@/routes/Chat/ui/ChatPanel/History/Messages/Img";
import { messageUtil } from "@/routes/Chat/ui/ChatPanel/History/Messages/messageUtil";
import { TextBubble } from "@/routes/Chat/ui/ChatPanel/History/Messages/TextBubble";
import { VideoBubble } from "@/routes/Chat/ui/ChatPanel/History/Messages/VideoBubble";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  textBubble: css({
    color: theme.colors.neutral007,
    gap: "4px",
  }),
  prompt: css({
    fontWeight: 500,
  }),
  quote: css({
    paddingLeft: "8px",
    borderLeft: "3px solid currentcolor",
  }),
});

const IgStoryMention: FC = () => {
  const { t } = useTranslation();
  const message = messageUtil.useMessage();
  const [state, setState] = useState<
    | {
        type: "image" | "video" | "loading";
      }
    | {
        type: "error";
        error: unknown;
      }
  >({
    type: "loading",
  });
  const setType = useHandler(function setType(
    type: Exclude<typeof state, { error: unknown }>["type"],
  ) {
    setState({ type });
  });
  const setError = useHandler(function setError(error: unknown) {
    setState({ type: "error", error });
  });
  useEffect(
    function updateType() {
      if (!message.originUrl) {
        setError(new Error("originUrl is not provided"));
        return;
      }
      const originUrl = message.originUrl;
      const abortController = new AbortController();
      (async function checkType() {
        if (abortController.signal.aborted) return;
        try {
          const mimeType = (
            await (
              await fetch(originUrl, {
                method: "HEAD",
                signal: abortController.signal,
              })
            ).blob()
          ).type;
          if (abortController.signal.aborted) return;
          if (mimeType.startsWith("video/")) setType("video");
          else setType("image");
        } catch (e) {
          if (abortController.signal.aborted) return;
          setType("image");
        }
      })();
      return function cleanup() {
        abortController.abort();
      };
    },
    [message.originUrl, setError, setType],
  );
  return (
    <TextBubble css={styles.textBubble}>
      <div css={styles.prompt}>
        {message.senderType === "member"
          ? t("resource.message.igStoryMention.byMember.message.prompt")
          : /**
             * Not supported for now.
             */
            ""}
      </div>
      <div css={styles.quote}>
        {state.type === "loading" ? (
          <BarLoadingBlock />
        ) : state.type === "error" ? (
          t("resource.message.media.error.notAvailable.content")
        ) : state.type === "image" ? (
          <Img onError={setError} />
        ) : (
          <VideoBubble />
        )}
      </div>
    </TextBubble>
  );
};

const Wrapped: FC = () => {
  const getMediaUrl = useGetMedialUrl();
  const message = messageUtil.useMessage();
  const src = getMediaUrl(message);
  return <IgStoryMention key={src} />;
};

assignDisplayName(Wrapped, "IgStoryMention");

export { Wrapped as IgStoryMention };
