import type { FeatureControlsSchema } from "@zeffiroso/cantata/models";
import { useCallback, useMemo } from "react";
import type { z } from "zod";

import { APP_LOADED_DATE } from "@/appConstant";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { useFeatureControlOverrideUtils } from "@/router/components/Protected/InOrg/Debug/FeatureControl";

type FeatureControl = z.infer<typeof FeatureControlsSchema>;
type FeatureControlName = keyof FeatureControl;

function useOverriddenFeatureControl() {
  const orgQueriesData = orgQueriesContext.useData();
  const featureControlOverrideUtils = useFeatureControlOverrideUtils();
  const overrideFeatureControl = featureControlOverrideUtils.useStore();

  const overriddenFeatureControl = useMemo<FeatureControl>(() => {
    return {
      ...orgQueriesData.featureControl,
      ...Object.fromEntries(
        overrideFeatureControl.featureControls.map(
          ({ featureControl, value }) => [featureControl, value],
        ),
      ),
    };
  }, [orgQueriesData.featureControl, overrideFeatureControl.featureControls]);

  return overriddenFeatureControl;
}

function checkFeatureControl(
  featureControl: FeatureControl,
  key: FeatureControlName,
): boolean {
  const enabledAt = featureControl[key].enabledAt;
  return enabledAt !== null && APP_LOADED_DATE >= enabledAt;
}

function useFeatureControl(key: FeatureControlName): boolean {
  const overriddenFeatureControl = useOverriddenFeatureControl();
  const ret = useMemo(
    () => checkFeatureControl(overriddenFeatureControl, key),
    [key, overriddenFeatureControl],
  );
  return ret;
}

function useGetFeatureControl() {
  const overriddenFeatureControl = useOverriddenFeatureControl();
  const getFeatureControl = useCallback(
    function getFeatureControl(key: FeatureControlName): boolean {
      return checkFeatureControl(overriddenFeatureControl, key);
    },
    [overriddenFeatureControl],
  );
  return getFeatureControl;
}

export {
  type FeatureControl,
  type FeatureControlName,
  useFeatureControl,
  useGetFeatureControl,
};
