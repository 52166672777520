import { makeApi } from "@zodios/core";
import { z } from "zod";

import { KeywordSchema } from "../models";

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/keyword/keywords",
    response: z.object({
      keywords: z.array(KeywordSchema),
    }),
  },
  {
    alias: "create",
    method: "post",
    path: "api/v1/orgs/:orgId/keyword/keywords",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: KeywordSchema.pick({ text: true }),
      },
    ],
    response: KeywordSchema,
  },
]);

export { api };
