// cspell:words pangea
/**
 * A workaround for React 18's Strict Mode and react-beautiful-dnd.
 *
 * Credits to https://github.com/GiovanniACamacho and https://github.com/Meligy for the TypeScript version
 * Original post: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
 * @see https://medium.com/@wbern/getting-react-18s-strict-mode-to-work-with-react-beautiful-dnd-47bc909348e4
 *
 * Considering replace `react-beautiful-dnd` with `hello-pangea/dnd` which is a
 * fork of `react-beautiful-dnd` with support for React 18's Strict Mode or
 * `dnd-kit`.
 */
import { memo } from "@chatbotgang/etude/react/memo";
import { useEffect, useState } from "react";
import type { DroppableProps } from "react-beautiful-dnd";
// eslint-disable-next-line no-restricted-imports -- Extended.
import { Droppable } from "react-beautiful-dnd";

type StrictModeDroppableProps = DroppableProps;

const StrictModeDroppable = memo(function StrictModeDroppable({
  children,
  ...props
}: DroppableProps) {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) return null;

  return <Droppable {...props}>{children}</Droppable>;
});

export type { StrictModeDroppableProps };
export { StrictModeDroppable };
