/**
 * We use this to log issues that are not frontend errors but record specific
 * information to figure out what is going on, especially the websocket
 * connection problems.
 */

import { configs } from "@/config/sentryDebug";
import { SentryDebug } from "@/internal/sentryDebug/classes";
import type {
  BaseConfig,
  ErrConfig,
} from "@/internal/sentryDebug/defineConfigs";
import type { Cause, ExtractErrConfig } from "@/internal/sentryDebug/types";
import { logError } from "@/shared/application/logger/sentry";

/**
 * Use the fake type to make sure it works for all kinds of configs.
 */
const fakeTypedConfigs =
  configs satisfies BaseConfig<any> as BaseConfig<any> as {
    foo: ErrConfig<{ foo: string }>;
    bar: ErrConfig<{ bar: number; baz: Date }>;
    nonParams: ErrConfig<undefined>;
    optionalParams: ErrConfig<undefined | { bar: number; baz: Date }>;
  } satisfies BaseConfig<any>;

function createSentryDebug<Config extends BaseConfig<any>>(config: Config) {
  type Key = keyof Config & string;
  type ErrConfig<K extends Key> = Config[K];
  type GetParams<K extends Key> = ExtractErrConfig<ErrConfig<K>>;
  type NoParamsKey<K extends Key> =
    GetParams<K> extends undefined
      ? undefined extends GetParams<K>
        ? K
        : never
      : never;
  type OptionalParamsKey<K extends Key> =
    NoParamsKey<K> extends never
      ? undefined extends GetParams<K>
        ? K
        : never
      : never;
  type RequiredParamsKey<K extends Key> =
    NoParamsKey<K> extends never
      ? OptionalParamsKey<K> extends never
        ? K
        : never
      : never;

  interface SentryDebugType {
    <K extends Key>(
      ...args:
        | [NoParamsKey<K> | OptionalParamsKey<K>]
        | [NoParamsKey<K> | OptionalParamsKey<K>, undefined, Cause]
        | [RequiredParamsKey<K> | OptionalParamsKey<K>, GetParams<K>, Cause?]
    ): void;
  }
  const sentryDebug: SentryDebugType = function sentryDebug<K extends Key>(
    key: K,
    params?: GetParams<K>,
    cause?: NonNullable<Error["cause"]>,
  ) {
    const value = config[key];
    if (!value.enabled) return;
    const message =
      typeof value.message === "function"
        ? value.message(params)
        : value.message;
    const Err = new SentryDebug(key, message, ...(cause ? [cause] : []));
    logError(Err);
  };
  return sentryDebug;
}

const sentryDebug = createSentryDebug(fakeTypedConfigs);

export { createSentryDebug, sentryDebug };
