import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { useTranslation } from "react-i18next";

import { fbSdk } from "@/app/fbSdk";
import type { CantataTypes } from "@/cantata/types";
import type { ButtonProps } from "@/components/Button";
import { Button } from "@/components/Button";
import { useMessage } from "@/internal/message";
import { channelQueriesContext } from "@/queriesContext/channelQueriesContext";
import { channelTypeScopeMap } from "@/resources/channel/facebook/scope";
import { useReconnectChannelMutation } from "@/resources/channel/mutations";
import { useGetErrorMessage } from "@/shared/application/error/handleError";
import { fakeT } from "@/shared/g11n/fakeT";

const translationI18nKeyMap = (() => {
  const t = fakeT;
  return define<
    Record<
      Extract<CantataTypes["ChannelType"], "fb" | "ig">,
      Record<string, string>
    >
  >()({
    fb: {
      loginFailedMessage: t("organization.channels.loginFbFailed.message.text"),
      failedMessage: t("organization.channels.reconnectFbFailed.message"),
    },
    ig: {
      loginFailedMessage: t("organization.channels.loginIgFailed.message.text"),
      failedMessage: t("organization.channels.reconnectIgFailed.message"),
    },
  });
})();

/**
 * Update channel status to `connected` and renew access token.
 * use for reconnect and reauthorize
 */
const ReconnectFacebookChannel = fc<
  {
    channelId: CantataTypes["Channel"]["id"];
    channelType: Extract<CantataTypes["Channel"]["type"], "fb" | "ig">;
  } & Omit<ButtonProps, "onClick">
>(function ReconnectFacebookChannel({
  channelId,
  channelType,
  disabled,
  ...buttonProps
}) {
  const { t } = useTranslation();
  const queries = channelQueriesContext.useQueries();
  const channelQuery = queries.channel;
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const message = useMessage();
  const getErrorMessage = useGetErrorMessage();
  const useAbortControllerStore = useUseAbortControllerStore();
  const reconnectMutation = useReconnectChannelMutation(
    channelId,
    channelType,
    {
      async onSuccess() {
        await safeInvalidateQuery(channelQuery.key);
        message.success(t("common.updatedSuccessfully"));
      },
      async onError(e) {
        await safeInvalidateQuery(channelQuery.key);
        message.error(
          getErrorMessage(e, {
            unknownErrorMessage: t(
              translationI18nKeyMap[channelType].failedMessage,
            ),
          }),
        );
      },
    },
  );

  const loginMutation = fbSdk.hooks.mutations.useLoginMutation({
    onSuccess(data) {
      if (!channelQuery.isSuccess) return;

      if (data.status !== "connected") {
        message.error(
          t(translationI18nKeyMap[channelType].loginFailedMessage) +
            inspectMessage`, status: ${data.status}`,
        );
        return;
      }

      reconnectMutation.mutate({
        accountId: channelQuery.data.externalChannelId,
        signal: useAbortControllerStore.getState().signal,
      });
    },
    onError(e) {
      message.error(
        getErrorMessage(e, {
          unknownErrorMessage: t(
            translationI18nKeyMap[channelType].loginFailedMessage,
          ),
        }),
      );
    },
  });

  const handleReconnect = useHandler(function handleReconnect() {
    if (!channelQuery.isSuccess) return;

    loginMutation.mutate({
      loginOptions: {
        scope: channelTypeScopeMap[channelType],
      },
    });
  });

  return (
    <Button
      onClick={handleReconnect}
      disabled={disabled || reconnectMutation.isLoading}
      {...buttonProps}
    />
  );
});

export { ReconnectFacebookChannel };
