import type {
  ZodiosAliases,
  ZodiosBodyByAlias,
  ZodiosEndpointDefinition,
  ZodiosRequestOptionsByAlias,
} from "@zodios/core/lib/zodios.types";
import type { ZodiosHooksInstance } from "@zodios/react";
import { get } from "lodash-es";

import type { IsImmutableQueryAlias } from "./types";

/**
 * This is a workaround for zodios v10.
 *
 * We cannot get the query key without a react hook for an immutable query by original
 * `getKeyByAlias()`.
 *
 * @example
 *
 * ```ts
 * // We cannot use `body` for immutable query keys:
 * const key = zodiosHooksInstance.getKeyByAlias('myAlias', {
 *   params: { id: 1 },
 * });
 *
 * // We cannot use `body` for immutable query keys:
 * const key = getImmutableQueryKeyByAlias(
 *   zodiosHooksInstance,
 *   'myAlias',
 *   { foo: 'bar' }
 *   { params: { id: 1 } },
 * );
 *
 * // This will be equal to the key:
 * const { key } = zodiosHooksInstance.useMyAliasQuery(
 *   {
 *     foo: 'bar',
 *   },
 *   {
 *     params: { id: 1 },
 *   },
 * );
 * ```
 *
 * @see https://github.com/ecyrbe/zodios/issues/242#issuecomment-1325652398
 */
function getImmutableQueryKeyByAlias<
  Api extends ZodiosEndpointDefinition[],
  Alias extends keyof ZodiosAliases<Api>,
>(
  zodiosHooksInstance: ZodiosHooksInstance<Api>,
  alias: Alias extends string
    ? IsImmutableQueryAlias<Api, Alias> extends true
      ? Alias
      : never
    : never,
  body: Alias extends string
    ? ZodiosBodyByAlias<Api, Alias> extends never
      ? undefined
      : ZodiosBodyByAlias<Api, Alias>
    : never,
  config?: Alias extends string
    ? ZodiosRequestOptionsByAlias<Api, Alias>
    : never,
) {
  const key = zodiosHooksInstance.getKeyByAlias<Alias>(alias, config);
  return [
    {
      ...(get(key, 0) ?? {}),
    },
    {
      ...(get(key, 1) ?? {}),
    },
    body,
  ];
}

export { getImmutableQueryKeyByAlias };
