import { css } from "@emotion/react";
import type { FC } from "react";

import { MainLayout } from "@/layout/MainLayout";
import { SideMenu } from "@/layout/SideMenu";
import { LayoutContext } from "@/layout/SideMenuLayout/Outer/Layout/Context";
import { useSideMenuListProps } from "@/layout/SideMenuLayout/Outer/Layout/sideMenuList";
import type { LayoutProps } from "@/layout/SideMenuLayout/Outer/Layout/types";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    height: "100%",
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
  }),
  sideMenu: css({
    height: "100%",
    overflow: "auto",
  }),
  main: css({
    "&, & > *": {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
    },
  }),
});

const LayoutGteTabletLandscape: FC<LayoutProps> = ({ mainRef }) => {
  const layoutContextValue = LayoutContext.useContextValue();
  const sideMenuListProps = useSideMenuListProps();
  return (
    <div css={styles.root}>
      <SideMenu.Panel
        css={styles.sideMenu}
        title={layoutContextValue.title}
        expanded={layoutContextValue.gteTabletLandscape.sidebar.expanded}
        onCollapse={layoutContextValue.gteTabletLandscape.sidebar.toggle.off}
      >
        <SideMenu.Content>
          <SideMenu.List {...sideMenuListProps} />
        </SideMenu.Content>
      </SideMenu.Panel>
      <MainLayout.Paper css={styles.main} ref={mainRef} />
    </div>
  );
};

export { LayoutGteTabletLandscape };
