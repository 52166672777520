import { TeamSchema } from "@zeffiroso/cantata/models";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import type { SelectProps } from "@/components/Select";
import { Select } from "@/components/Select";
import { TeamRoutingRuleTypeLabel } from "@/resources/team/TeamRoutingRuleTypeLabel";

type TeamRoutingRuleSelectProps = SelectProps<
  CantataTypes["Team"]["routingRule"]
>;

export const TeamRoutingRuleSelect = function RoutingRuleSelect(
  props: TeamRoutingRuleSelectProps,
) {
  const { t } = useTranslation();
  return (
    <Select<CantataTypes["Team"]["routingRule"]>
      placeholder={t("team.form.routingRule.placeholder")}
      options={TeamSchema.shape.routingRule.options.map((rule) => ({
        "data-test": `team-routing-rule-option-${rule}`,
        label: <TeamRoutingRuleTypeLabel type={rule} />,
        value: rule,
      }))}
      {...props}
    />
  );
};
