import { memo } from "@chatbotgang/etude/react/memo";
import styled from "@emotion/styled";
import { Spin } from "antd";

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Loading = memo(function Loading() {
  return (
    <Container>
      <Spin />
    </Container>
  );
});
