import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";

const PreviewPlaceholder = styled.div`
  max-width: 70%;
  padding-bottom: 70%;
  border-radius: ${theme.shape.borderRadius};
  background-color: ${theme.colors.white};
`;

const Image = styled.img`
  max-width: 70%;
  border-radius: ${theme.shape.borderRadius};
`;

const ImagePreview = ({ src }: { src: string }): JSX.Element => {
  if (!src) return <PreviewPlaceholder />;

  return <Image src={src} />;
};

export { ImagePreview };
