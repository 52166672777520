import { useHandler } from "@chatbotgang/etude/react/useHandler";
import useSwitch from "@react-hook/switch";
import type { FormProps } from "antd";
import EventEmitter from "eventemitter3";
import type { ReactNode } from "react";
import { useEffect, useMemo } from "react";

import { useHasFieldsError } from "@/components/Form/useHasFieldsError";
import { routerUtils } from "@/router/routerUtils";

import type { Form } from ".";

export type RouterPromptOptions<Values = any> = {
  disabled?: boolean;
  promptIfAnyFieldsError?: boolean;
  formProps?: FormProps<Values> & {
    children?: ReactNode;
  };
};

type FormInstance<Values = unknown> = ReturnType<
  (typeof Form<Values>)["useForm"]
>[0];

/**
 * Listen function calls of methods of `FormInstance`.
 */
const useFormEvent = (() => {
  type FormEventTypes<Values = unknown> = {
    resetFields: FormInstance<Values>["resetFields"];
  };

  const formEventWeakMap = new WeakMap<
    FormInstance<unknown>,
    EventEmitter<FormEventTypes>
  >();

  function getEventEmitter<Values = unknown>(form: FormInstance<Values>) {
    if (formEventWeakMap.has(form)) {
      const ret = formEventWeakMap.get(form);
      if (!ret) throw new Error("Unexpected undefined value");
      return ret;
    }
    const ret = new EventEmitter<FormEventTypes<Values>>();
    // inject form event
    (() => {
      const { resetFields } = form;
      form.resetFields = (...args) => {
        ret.emit("resetFields", ...args);
        return resetFields?.(...args);
      };
    })();
    formEventWeakMap.set(form, ret);
    return ret;
  }

  function useFormEvent<Values = unknown>(
    form: FormInstance<Values> | undefined,
    eventType: keyof FormEventTypes,
    handler: FormEventTypes<Values>[typeof eventType],
  ) {
    const eventEmitter = useMemo(() => form && getEventEmitter(form), [form]);
    useEffect(() => {
      if (!eventEmitter) return;
      eventEmitter.on(eventType, handler);
      return function cleanup() {
        eventEmitter.off(eventType, handler);
      };
    }, [eventEmitter, eventType, handler]);
  }
  return useFormEvent;
})();

/**
 * Do not use this hook directly. Use `Form` instead.
 *
 * @see {@link Form}
 *
 * A shortcut to use React Router Prompt with Ant Design Form. It will prompt
 * the user when the form is modified and the user tries to leave the page.
 *
 * @example
 * ```ts
 * const form = useForm();
 * const { routerPromptElement, formProps } = useRouterPrompt({
 *   formProps: {
 *     form,
 *     onFinish
 *   }
 * });
 * return (
 *   <Form {...formProps}>
 *     {yourFormFields}
 *     {routerPromptElement}
 *   </Form>
 * );
 * ```
 */
export function useRouterPrompt<Values = any>({
  promptIfAnyFieldsError,
  disabled,
  formProps,
}: RouterPromptOptions<Values> = {}) {
  /**
   * This is a workaround to sync state from Ant Design Form because it does not
   * expose the state of them. It's not a really good practice to use React
   * state.
   */
  const [isModified, setModified] = useSwitch(false);
  const { hasFieldsError, injectedFormProps: propsWithHasFieldsErrorHandlers } =
    useHasFieldsError({
      formProps,
    });
  const onValuesChange = useHandler<FormProps<Values>["onValuesChange"]>(
    function onValuesChange(...args) {
      const changedValues = args[0];
      if (Object.keys(changedValues).length > 0) setModified.on();
      else setModified.off();

      propsWithHasFieldsErrorHandlers?.onValuesChange?.(...args);
    },
  );
  const onReset = useHandler<FormProps<Values>["onReset"]>(function onReset(
    ...args
  ) {
    setModified.off();
    propsWithHasFieldsErrorHandlers?.onReset?.(...args);
  });

  // listen form reset from the form instance
  useFormEvent(formProps?.form, "resetFields", setModified.off);

  const when: boolean =
    !disabled &&
    (isModified || (Boolean(promptIfAnyFieldsError) && hasFieldsError));

  const routerPromptElement = useMemo(
    () => (!when ? null : <routerUtils.Blocker />),
    [when],
  );
  const injectedFormProps = useMemo<
    FormProps<Values> & {
      children?: ReactNode;
    }
  >(
    () => ({
      ...formProps,
      onValuesChange,
      onReset,
    }),
    [formProps, onReset, onValuesChange],
  );
  const returnValues = useMemo(
    () => ({
      routerPromptElement,
      formProps: injectedFormProps,
    }),
    [injectedFormProps, routerPromptElement],
  );
  return returnValues;
}
