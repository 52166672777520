import { addOpacityToHex } from "@zeffiroso/utils/colors/hex";

/**
 * Design Tokens
 * @see {@link https://www.notion.so/cresclab/f6cde3c1a0c84384b673898b5ea8aaef?v=7453e5b553f34c8192eb776cbf90d552&pvs=4}
 */

enum NeutralPalette {
  neutral001 = "#F8F9FB",
  neutral002 = "#E7EBEF",
  neutral003 = "#D7DEE4",
  neutral004 = "#C7D0D9",
  neutral005 = "#BBC6D1",
  neutral006 = "#AFBCC9",
  neutral007 = "#8C9CAB",
  neutral008 = "#5C7388",
  neutral009 = "#425A70",
  neutral010 = "#223B53",
}

enum RedPalette {
  red001 = "#FFF3EF",
  red002 = "#FFEBE4",
  red003 = "#FFBAAB",
  red004 = "#FF8569",
  red005 = "#E9573B",
  red006 = "#D93721",
  red007 = "#CF2A18",
  red008 = "#8C0C00",
  red009 = "#660500",
  red010 = "#400100",
}

enum YellowPalette {
  yellow001 = "#FFFAE4",
  yellow002 = "#FFF6D0",
  yellow003 = "#FFE976",
  yellow004 = "#FFD235",
  yellow005 = "#FAB918",
  yellow006 = "#F29B00",
  yellow007 = "#EE8900",
  yellow008 = "#AE7013",
  yellow009 = "#875410",
  yellow010 = "#61390B",
}

enum GreenPalette {
  green001 = "#F2FDF1",
  green002 = "#E7FBE7",
  green003 = "#99ED9E",
  green004 = "#6AD170",
  green005 = "#43B849",
  green006 = "#179C34",
  green007 = "#118A27",
  green008 = "#2E6B03",
  green009 = "#1A4500",
  green010 = "#063306",
}

enum BluePalette {
  blue001 = "#F3F9FF",
  blue002 = "#E7F2FF",
  blue003 = "#B5D7FF",
  blue004 = "#7DAFFF",
  blue005 = "#5998FF",
  blue006 = "#4586F0",
  blue007 = "#1F56E5",
  blue008 = "#1834A3",
  blue009 = "#0C1F7D",
  blue010 = "#081257",
}

enum CeruleanPalette {
  cerulean001 = "#F0FEFF",
  cerulean002 = "#E0FDFE",
  cerulean003 = "#B3FBFF",
  cerulean004 = "#84EBF5",
  cerulean005 = "#58D7E8",
  cerulean006 = "#2DC4D8",
  cerulean007 = "#259AB0",
  cerulean008 = "#1C788C",
  cerulean009 = "#0F5366",
  cerulean010 = "#0A3946",
}

enum PurplePalette {
  purple001 = "#F8F2FF",
  purple002 = "#F2E8FF",
  purple003 = "#D4B9F9",
  purple004 = "#B98EF5",
  purple005 = "#A257FF",
  purple006 = "#8020F8",
  purple007 = "#6C1DED",
  purple008 = "#4716A1",
  purple009 = "#2E0B7A",
  purple010 = "#1D0754",
}

const colorPalette = {
  white: "#ffffff",
  primary: BluePalette.blue006,
  primaryHover: BluePalette.blue005,
  primaryActive: BluePalette.blue007,
  primaryOutline: addOpacityToHex(BluePalette.blue006, "33"),
  secondary: NeutralPalette.neutral006,
  secondaryHover: NeutralPalette.neutral005,
  secondaryActive: NeutralPalette.neutral007,
  success: GreenPalette.green006,
  successHover: GreenPalette.green005,
  successActive: GreenPalette.green007,
  error: RedPalette.red006,
  errorHover: RedPalette.red005,
  errorActive: RedPalette.red007,
  errorOutline: addOpacityToHex(RedPalette.red006, "33"),
  warning: YellowPalette.yellow006,
  warningHover: YellowPalette.yellow005,
  warningActive: YellowPalette.yellow007,
  info: CeruleanPalette.cerulean006,
  infoHover: CeruleanPalette.cerulean005,
  infoActive: CeruleanPalette.cerulean007,
  light: NeutralPalette.neutral001,
  dark: NeutralPalette.neutral008,
  colorText: NeutralPalette.neutral010,
  backgroundDisabled: NeutralPalette.neutral001,
};

export const colors = {
  ...NeutralPalette,
  ...RedPalette,
  ...YellowPalette,
  ...GreenPalette,
  ...BluePalette,
  ...CeruleanPalette,
  ...PurplePalette,
  ...colorPalette,
};
