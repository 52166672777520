import { makeApi } from "@zodios/core";
import { z } from "zod";

const api = makeApi([
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#ba7f768b0e714dc9957e63a056ff3084)
     */
    alias: "token",
    method: "post",
    path: "/api/v1/platform/token",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          orgId: z.number().int(),
        }),
      },
    ],
    response: z.object({
      orgId: z.number().int(),
      token: z.string(),
    }),
  },
]);

export { api };
