import { memo } from "@chatbotgang/etude/react/memo";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { ComponentProps } from "react";

import { Empty as EmptyIcon } from "@/shared/icons/deprecated/Empty";

type TextSize = "small" | "middle";

interface EmptyProps extends ComponentProps<typeof EmptyContainer> {
  /**
   * The text size of the message.
   */
  size?: TextSize;
}

const EmptyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const textStyleMap: Record<TextSize, SerializedStyles> = {
  middle: css`
    font-size: 14px;
    line-height: 20px;
  `,
  small: css`
    font-size: 12px;
    line-height: 16px;
  `,
};

const EmptyMessage = styled.div`
  color: ${theme.colors.neutral007};
  font-weight: 400;
`;

const Empty = memo(function Empty({
  children,
  size = "middle",
  ...props
}: EmptyProps) {
  return (
    <EmptyContainer data-test="empty-data-container" {...props}>
      <EmptyIcon />
      <EmptyMessage css={textStyleMap[size]}>{children}</EmptyMessage>
    </EmptyContainer>
  );
});

export { Empty };
