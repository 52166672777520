import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import type { SwitchProps as AntdSwitchProps } from "antd";
import { Switch as AntdSwitch } from "antd";
import type { ElementRef } from "react";

type SwitchProps = AntdSwitchProps;

const Switch = memo(
  forwardRef<ElementRef<typeof AntdSwitch>, SwitchProps>(
    function Switch(props, ref) {
      return <AntdSwitch {...props} ref={ref} />;
    },
  ),
) as typeof AntdSwitch;

assignDisplayName(Switch, "Switch");

export { Switch };
export type { SwitchProps };
