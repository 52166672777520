import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { staticSize } from "@zeffiroso/utils/emotion/staticSize";

import { ProcessingStateIcon } from "@/resources/conversation/ProcessingStateIcon";
import { useConversation } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Context";
import { cssFlexInheritAndFill } from "@/shared/emotion";

const cssLine = css`
  width: 1px;
  background-color: ${theme.colors.neutral003};
`;

/**
 * Spec: [Figma - Omnichannel inbox](https://www.figma.com/file/k0XwP83RAV16nVdyFx7crb/Omnichannel-inbox?type=design&node-id=3209%3A78996&mode=dev)
 */
const ConversationStatusIcon = (() => {
  const cssConversationStatusIcon = css([
    cssFlexInheritAndFill,
    staticSize.circle.css,
    css`
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      border: 1px solid ${theme.colors.neutral003};
      font-size: 1rem;
    `,
  ]);
  const ConversationStatusIcon = memo(function ConversationStatusIcon() {
    const conversation = useConversation();
    return (
      <div css={cssConversationStatusIcon} style={staticSize.circle.style(24)}>
        <ProcessingStateIcon processingState={conversation.processingState} />
      </div>
    );
  });
  return ConversationStatusIcon;
})();

const Timeline = memo(function Timeline() {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <div
        css={css([
          cssLine,
          css`
            height: 6px;
          `,
        ])}
      />
      <ConversationStatusIcon />
      <div
        css={css([
          cssLine,
          css`
            flex: 1;
          `,
        ])}
      />
    </div>
  );
});

export { Timeline };
