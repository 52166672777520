/**
 * Defines external date schemas not compatible with Date.
 */

import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  endOfDay,
  isAfter,
  isBefore,
  isEqual,
} from "date-fns/fp";
import { mapValues, padStart } from "lodash-es";
import type { Tagged } from "type-fest";
import { z } from "zod";

import type { DateNumber, MonthIndexNumber } from "../types";

type YmdString = Tagged<"YmdString", `${string}-${string}-${string}`>;

namespace Ymd {
  export type DateToYmdDeep<T> = T extends Date
    ? Ymd
    : T extends object
      ? {
          [K in keyof T]: DateToYmdDeep<T[K]>;
        }
      : T;

  export type YmdToDateDeep<T> = T extends Ymd
    ? Date
    : T extends object
      ? {
          [K in keyof T]: YmdToDateDeep<T[K]>;
        }
      : T;
}

/**
 * YMD object. YMD stands for Year, Month, Day. Immutable.
 *
 * This class is used for date without time to avoid timezone issues.
 */
class Ymd {
  raw: Readonly<{
    year: number;
    monthIndex: MonthIndexNumber;
    date: DateNumber;
  }>;
  constructor(raw: Ymd["raw"] | Date) {
    this.raw =
      raw instanceof Date
        ? {
            year: raw.getFullYear(),
            monthIndex: raw.getMonth() as MonthIndexNumber,
            date: raw.getDate() as DateNumber,
          }
        : raw;
  }

  /**
   * Returns a Date object with the local time set to the start of the day.
   */
  get localStartOfDay(): Date {
    return new Date(
      `${this.raw.year}/${this.raw.monthIndex + 1}/${this.raw.date}`,
    );
  }

  get localEndOfDay(): Date {
    const startOfDay = this.localStartOfDay;
    return endOfDay(startOfDay);
  }

  addDays(days: number): Ymd {
    const date = this.localStartOfDay;
    const newDate = addDays(days)(date);
    return new Ymd(newDate);
  }

  addWeeks(weeks: number): Ymd {
    const date = this.localStartOfDay;
    const newDate = addWeeks(weeks)(date);
    return new Ymd(newDate);
  }

  addMonths(months: number): Ymd {
    const date = this.localStartOfDay;
    const newDate = addMonths(months)(date);
    return new Ymd(newDate);
  }

  addYears(years: number): Ymd {
    return new Ymd({
      year: this.raw.year + years,
      monthIndex: this.raw.monthIndex,
      date: this.raw.date,
    });
  }

  isBefore(ymd: Ymd): boolean {
    return isBefore(ymd.localStartOfDay, this.localStartOfDay);
  }

  isEqual(ymd: Ymd): boolean {
    return isEqual(ymd.localStartOfDay, this.localStartOfDay);
  }

  isAfter(ymd: Ymd): boolean {
    return isAfter(ymd.localStartOfDay, this.localStartOfDay);
  }

  diffInDays(ymd: Ymd): number {
    return differenceInDays(ymd.localStartOfDay, this.localStartOfDay);
  }

  /**
   *
   * Returns a string representation of the YMD object.
   */
  toString(): YmdString {
    return `${this.raw.year}-${padStart(
      String(this.raw.monthIndex + 1),
      2,
      "0",
    )}-${padStart(String(this.raw.date), 2, "0")}` as YmdString;
  }

  static YmdStringToYmdSchema = z
    .string()
    .date()
    .transform((value) => {
      const [year, month, day] = value.split("-").map(Number);
      if (!year || !month || !day) {
        /**
         * Should not happen because of the value is already validated by zod.
         */
        throw new Error(`Invalid YMD string: ${value}`);
      }
      return new Ymd({
        year,
        monthIndex: (month - 1) as MonthIndexNumber,
        date: day as DateNumber,
      });
    });

  static DateToYmdSchema = z.date().transform((value) => {
    return new Ymd(value);
  });

  static DateToYmdStringSchema = Ymd.DateToYmdSchema.transform((ymd) =>
    ymd.toString(),
  );

  static YmdToYmdStringSchema = z
    .instanceof(Ymd)
    .transform((ymd) => ymd.toString());

  static dateToYmdDeep = <T>(obj: T): Ymd.DateToYmdDeep<T> => {
    if (obj instanceof Date) {
      return new Ymd(obj) as Ymd.DateToYmdDeep<T>;
    }
    if (Array.isArray(obj)) {
      return obj.map(Ymd.dateToYmdDeep) as Ymd.DateToYmdDeep<T>;
    }
    if (obj instanceof Object) {
      return mapValues(obj, Ymd.dateToYmdDeep) as Ymd.DateToYmdDeep<T>;
    }
    return obj as Ymd.DateToYmdDeep<T>;
  };

  static ymdToDateDeep = <T>(obj: T): Ymd.YmdToDateDeep<T> => {
    if (obj instanceof Ymd) {
      return obj.localStartOfDay as Ymd.YmdToDateDeep<T>;
    }
    if (Array.isArray(obj)) {
      return obj.map(Ymd.ymdToDateDeep) as Ymd.YmdToDateDeep<T>;
    }
    if (obj instanceof Object) {
      return mapValues(obj, Ymd.ymdToDateDeep) as Ymd.YmdToDateDeep<T>;
    }
    return obj as Ymd.YmdToDateDeep<T>;
  };
}

export { Ymd };
