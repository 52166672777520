import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { delay } from "@chatbotgang/etude/timer/delay";
import useChange from "@react-hook/change";
import type { InputRef } from "antd";
import { useRef } from "react";

import { Form } from "@/components/Form";
import type { SearchBarUiProps } from "@/routes/Chat/ui/ChatPanel/SearchBar/SearchBarUi";
import { SearchBarUi } from "@/routes/Chat/ui/ChatPanel/SearchBar/SearchBarUi";
import { useSearchMessageFromAMember } from "@/routes/Chat/ui/searchMessageFromMember";

export const SearchBar = memo(function SearchBar() {
  const [form] = Form.useForm();
  const searchMessageFromAMember = useSearchMessageFromAMember();
  const onIndexChange = useHandler<SearchBarUiProps["onIndexChange"]>(
    function onChangeHandler(index) {
      searchMessageFromAMember.jumpTo(index);
    },
  );

  const searchInputRef = useRef<InputRef>(null);

  useChange(searchMessageFromAMember.searchInputOpened, async (opened) => {
    if (!opened) return;
    await delay(300);
    if (!opened) return;
    if (!searchInputRef.current) return;
    searchInputRef.current.focus();
  });
  useChange(
    searchMessageFromAMember.searchInputText,
    function syncSearchInputText(text) {
      const fieldValue = form.getFieldValue("query");
      if (text === fieldValue) return;
      form.setFieldValue("query", text);
    },
  );

  return !searchMessageFromAMember.searchInputOpened ? null : (
    <Form
      form={form}
      routerPromptOptions={{
        disabled: true,
      }}
      initialValues={{
        query: searchMessageFromAMember.searchInputText,
      }}
      onFinish={async (values) => {
        searchMessageFromAMember.search(values.query);
      }}
      disabled={
        /**
         * The variable `isLoading` is used to indicate when data is undefined. It's
         * important to also consider the `isFetching` variable to verify that the
         * data is actually being loaded.
         */
        searchMessageFromAMember.query.isLoading &&
        searchMessageFromAMember.query.isFetching
      }
    >
      <Form.Item
        name="query"
        rules={[
          {
            required: true,
            message: "",
          },
        ]}
        noStyle
      >
        <SearchBarUi
          cycle
          index={searchMessageFromAMember.index}
          onIndexChange={onIndexChange}
          totalCount={
            !searchMessageFromAMember.query.isSuccess
              ? undefined
              : searchMessageFromAMember.query.data.matchedMessages.length
          }
          onClose={searchMessageFromAMember.closeSearchInput}
          searchInputProps={{
            ref: searchInputRef,
          }}
        />
      </Form.Item>
    </Form>
  );
});
