import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import type { ElementRef } from "react";

import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";

namespace TooltipInfo {
  export type Props = Overwrite<
    Overwrite<
      DistributiveOmit<ComponentProps<typeof MotifIcon>, "un-i-motif">,
      Pick<ComponentProps<typeof Tooltip>, "title">
    >,
    {
      tooltipProps?: DistributiveOmit<
        Tooltip.Props,
        "children" | "title" | "trigger"
      >;
    }
  >;
  export type Ref = ElementRef<typeof MotifIcon>;
}

/**
 * An info icon with a tooltip.
 *
 * By default, the tooltip can be triggered by hovering over the icon on
 * non-touch devices and by clicking on the icon on touch devices.
 */
const TooltipInfo = forwardRef<TooltipInfo.Ref, TooltipInfo.Props>(
  function Info({ title, tooltipProps, ...props }, ref) {
    return (
      <Tooltip {...tooltipProps} title={title} trigger="info">
        <MotifIcon un-i-motif="circle_info" {...props} ref={ref} />
      </Tooltip>
    );
  },
);

assignDisplayName(TooltipInfo, "Info");

export { TooltipInfo };
