import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { defineCssVariablesStyleProp } from "@zeffiroso/utils/react/defineCssVariablesStyleProp";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { secondsToMilliseconds } from "date-fns";
import { isEqual } from "lodash-es";
import { useEffect, useMemo } from "react";
import type { Mutate, StoreApi, UseBoundStore } from "zustand";
import { createWithEqualityFn } from "zustand/traditional";

type Options = {
  tappingThreshold: {
    move: number;
    duration: number;
  };
  padding: number;
};

const defaultOptions: Options = {
  tappingThreshold: {
    move: 8,
    duration: secondsToMilliseconds(0.5),
  },
  padding: 8,
};

type Position = {
  right: number;
  bottom: number;
};

function fixPosition({
  target,
  tryPosition,
  padding = defaultOptions.padding,
}: {
  target: Element;
  tryPosition: Position;
  padding?: number;
}): Position {
  const containerRect: {
    width: number;
    height: number;
  } = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const rect = target.getBoundingClientRect();
  const minRight = padding;
  const maxRight = containerRect.width - rect.width - padding;
  const minBottom = padding;
  const maxBottom = containerRect.height - rect.height - padding;
  const right = Math.max(minRight, Math.min(maxRight, tryPosition.right));
  const bottom = Math.max(minBottom, Math.min(maxBottom, tryPosition.bottom));

  return { right, bottom };
}

function getDistance(a: Position, b: Position) {
  return Math.sqrt((a.right - b.right) ** 2 + (a.bottom - b.bottom) ** 2);
}

type DragEvent = MouseEvent | TouchEvent;

function isDragEvent(e: Event): e is DragEvent {
  return e instanceof MouseEvent || e instanceof TouchEvent;
}

function getCursorPosition(e: DragEvent): {
  x: number;
  y: number;
} | null {
  if (e instanceof MouseEvent) return { x: e.clientX, y: e.clientY };
  if (e instanceof TouchEvent) {
    if (e.touches.length <= 0)
      throw new Error(`Touches length is ${e.touches.length}`);
    const touch = e.touches[0];
    if (!touch) return null;
    return { x: touch.clientX, y: touch.clientY };
  }
  e satisfies never;
  throw new Error("Invalid event");
}

type ElStoreState = {
  target: Element | null;
  container: Element | null;
};

function setupDraggableFloatingElementController() {
  const useElStore = createWithEqualityFn<ElStoreState>()(
    () => ({
      target: null,
      container: null,
    }),
    shallow,
  );
  function targetRef(target: ElStoreState["target"]) {
    useElStore.setState({ target });
  }
  function refContainer(container: ElStoreState["container"]) {
    useElStore.setState({ container });
  }
  function useSetupEffect<
    Store extends UseBoundStore<
      Mutate<
        StoreApi<{
          value: Position;
        }>,
        []
      >
    >,
  >({
    positionStore,
    onClick,
  }: {
    positionStore: Store;
    onClick?: () => void;
  }) {
    const elState = useElStore();
    const tryTarget = elState.target;
    const container = elState.container || window;
    const memorizedOnClick = useHandler<NonNullable<typeof onClick>>(
      (...args) => {
        onClick?.(...args);
      },
    );
    useEffect(
      function listenEvents() {
        if (!tryTarget) return;
        const target = tryTarget;

        function updatePosition(position?: Position): boolean {
          const currentPosition = positionStore.getState().value;
          const nextPosition = fixPosition({
            target,
            tryPosition: position || currentPosition,
          });
          if (isEqual(currentPosition, nextPosition)) return false;
          positionStore.setState(() => ({
            value: nextPosition,
          }));
          return true;
        }

        function contextmenuHandler(e: Event) {
          // Prevent long press context menu on mobile
          e.preventDefault();
          e.stopPropagation();
        }
        target.addEventListener("contextmenu", contextmenuHandler);
        function mousedownHandler(e: Event) {
          e.preventDefault();
          e.stopPropagation();
          if (!isDragEvent(e)) return;
          let isTapping = true;
          const startTimestamp = Date.now();
          const startFabPosition = positionStore.getState().value;
          const startCursorPosition = getCursorPosition(e);
          if (!startCursorPosition) return;
          function getNextFabPosition(e: DragEvent) {
            const endCursorPosition = getCursorPosition(e);
            if (!startCursorPosition || !endCursorPosition) return;
            const delta = {
              x: endCursorPosition.x - startCursorPosition.x,
              y: endCursorPosition.y - startCursorPosition.y,
            };
            const tryPosition = {
              right: startFabPosition.right - delta.x,
              bottom: startFabPosition.bottom - delta.y,
            };
            return fixPosition({ target, tryPosition });
          }
          function mousemoveHandler(e: Event) {
            e.preventDefault();
            e.stopPropagation();
            if (!isDragEvent(e)) return;
            const mouseEvent = e;

            const nextFabPosition = getNextFabPosition(mouseEvent);

            if (isEqual(nextFabPosition, startFabPosition)) return;
            if (
              isTapping &&
              nextFabPosition &&
              getDistance(nextFabPosition, startFabPosition) >
                defaultOptions.tappingThreshold.move
            )
              isTapping = false;

            updatePosition(nextFabPosition);
          }
          function mouseupHandler(e: Event) {
            e.preventDefault();
            e.stopPropagation();
            document.removeEventListener("mousemove", mousemoveHandler);
            document.removeEventListener("touchmove", mousemoveHandler);
            document.removeEventListener("mouseup", mouseupHandler);
            document.removeEventListener("touchend", mouseupHandler);
            document.removeEventListener("touchcancel", mouseupHandler);
            if (
              !isTapping ||
              Date.now() - startTimestamp >
                defaultOptions.tappingThreshold.duration
            )
              return;
            memorizedOnClick?.();
          }
          document.addEventListener("mousemove", mousemoveHandler);
          document.addEventListener("touchmove", mousemoveHandler);
          document.addEventListener("mouseup", mouseupHandler, { once: true });
          document.addEventListener("touchend", mouseupHandler, { once: true });
          document.addEventListener("touchcancel", mouseupHandler, {
            once: true,
          });
        }
        target.addEventListener("mousedown", mousedownHandler);
        target.addEventListener("touchstart", mousedownHandler);
        function resizeHandler() {
          updatePosition();
        }
        container.addEventListener("resize", resizeHandler);
        (function fixPositionOnFirstRender() {
          updatePosition();
        })();
        return function cleanup() {
          target.removeEventListener("contextmenu", contextmenuHandler);
          target.removeEventListener("mousedown", mousedownHandler);
          target.removeEventListener("touchstart", mousedownHandler);
          container.removeEventListener("resize", resizeHandler);
        };
      },
      [container, memorizedOnClick, positionStore, tryTarget],
    );
  }
  const draggableController = {
    useSetupEffect,
    refContainer,
    targetRef,
  };
  return draggableController;
}

/**
 * Create a controller for a draggable floating element.
 *
 * ```tsx
 * const usePositionStore = useMemo(() => createWithEqualityFn<PositionStore>()({
 *   value: { right: 0, bottom: 0 },
 * }), []);
 * const onClick = useHandler(() => { doSomething(); });
 * const draggableFabController = useDraggableFloatingElementController({
 *   positionStore: usePositionStore,
 *   onClick,
 * });
 * return <FloatButton
 *   css={draggableFabController.css}
 *   style={draggableFabController.style}
 *   ref={draggableFabController.targetRef}
 * />;
 * ```
 */
function useDraggableFloatingElementController(
  ...args: Parameters<
    ReturnType<typeof setupDraggableFloatingElementController>["useSetupEffect"]
  >
) {
  const draggableController = useMemo(
    setupDraggableFloatingElementController,
    [],
  );
  draggableController.useSetupEffect(...args);
  const options = args[0];
  const positionStore = options.positionStore;
  const positionState = positionStore().value;
  const style = useMemo(() => {
    return defineCssVariablesStyleProp({
      "--draggable-fab-position-right": `${positionState.right}px`,
      "--draggable-fab-position-bottom": `${positionState.bottom}px`,
    });
  }, [positionState.bottom, positionState.right]);
  const ret = useMemo(
    () =>
      Object.assign({}, draggableController, {
        css: css`
          inset-block-end: var(--draggable-fab-position-bottom);
          inset-inline-end: var(--draggable-fab-position-right);
        `,
        style,
      }),
    [draggableController, style],
  );
  return ret;
}

export { useDraggableFloatingElementController };
