import { string } from "@chatbotgang/etude/pitch-shifter/string";

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from "@/appConstant";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const { useStore: useTokenStore } = createZustandStorageStore(
  LOCAL_STORAGE_AUTH_TOKEN_KEY,
  string(),
  {
    forUser: false,
  },
);

export { useTokenStore };
