import { PaperClipOutlined } from "@ant-design/icons";
import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import { memo } from "@chatbotgang/etude/react/memo";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import prettyBytes from "pretty-bytes";

import { Description, LabelText } from "@/components/Typography";
import { useFormatDateTime } from "@/resources/datetime";
import { getCssStr } from "@/shared/utils/style/getCssStr";

const FileIcon = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 3px;
  color: ${theme.colors.neutral005};
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Wrapper = styled.div<{ $small?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  padding: 32px;
  border-radius: ${theme.shape.borderRadius};
  background-color: ${theme.colors.neutral003};
  color: ${theme.colors.neutral007};
  font-size: 14px;
  gap: 8px;

  ${FileIcon} {
    margin-top: 3px;

    ${(props) =>
      props.$small &&
      getCssStr(
        (css) => css`
          margin-top: 2px;
        `,
      )}
  }

  ${(props) =>
    props.$small &&
    getCssStr(
      (css) => css`
        ${LabelText} {
          font-size: 12px;
          line-height: 16px;
        }

        ${Row} {
          gap: 4px;
        }

        padding: 8px;
        font-size: 12px;
        gap: 4px;
        line-height: 16px;
      `,
    )};
`;

const FullWidthLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface FilePreviewProps {
  downloadUrl: string;
  fileName: string;
  fileSizePrefix: string;
  /** File size in bytes */
  fileSize: number;
  expiryPrefix: string;
  /** If invalid date string is provided, the date string placeholder will be displayed  */
  expiry?: Date;
  small?: boolean;
}

const FilePreview = memo<FilePreviewProps>(function FilePreview({
  fileName,
  fileSize,
  fileSizePrefix,
  expiryPrefix,
  expiry,
  downloadUrl,
  small,
}) {
  const formatDateTime = useFormatDateTime();
  return (
    <Wrapper $small={small}>
      <Row>
        <FileIcon>
          <PaperClipOutlined />
        </FileIcon>
        <LabelText>{fileName}</LabelText>
      </Row>
      <div>
        {fileSizePrefix}: {prettyBytes(fileSize)}
      </div>
      <Description>
        {expiryPrefix}:{" "}
        {expiry && !isInvalidDate(expiry)
          ? formatDateTime(expiry)
          : "yyyy-MM-dd HH:mm"}
      </Description>
      <FullWidthLink
        href={downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
      />
    </Wrapper>
  );
});

export { FilePreview };
