import { shallow } from "@zeffiroso/utils/zustand/shallow";
import type { ComponentProps, FC } from "react";
import { createContext, useContext, useMemo } from "react";
import { createWithEqualityFn } from "zustand/traditional";

import type { CantataTypes } from "@/cantata/types";
type Store = {
  messageId: CantataTypes["MessageDetail"]["id"] | null;
};

const initialValue: Store = {
  messageId: null,
};

function setupStore() {
  const useStore = createWithEqualityFn<Store>()(() => initialValue, shallow);

  function reset() {
    useStore.setState(initialValue);
  }

  function useReplyMessageId() {
    return useStore((store) => store.messageId);
  }

  function setReplyMessageId(messageId: NonNullable<Store["messageId"]>) {
    useStore.setState({ messageId });
  }

  const removeReplyMessage = reset;

  return {
    reset,
    useReplyMessageId,
    setReplyMessageId,
    removeReplyMessage,
  };
}

const Context = createContext<null | ReturnType<typeof setupStore>>(null);

const ReplyMessageControllerProvider: FC<
  Omit<ComponentProps<typeof Context.Provider>, "value">
> = (props) => {
  const useReplyMessageController = useMemo(setupStore, []);
  return <Context.Provider value={useReplyMessageController} {...props} />;
};

function useReplyMessageController() {
  const replyMessageContextValue = useContext(Context);
  if (replyMessageContextValue === null) {
    throw new Error(
      "useReplyMessageController must be used within ReplyMessageController",
    );
  }
  return replyMessageContextValue;
}

export { ReplyMessageControllerProvider, useReplyMessageController };
