import { LoadingOutlined } from "@ant-design/icons";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { MotifIcon } from "@/components/MotifIcon";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import type { SidebarMenuItem } from "@/layout/SideMenuLayout/Outer/Layout/types";
import { usePlatformLink } from "@/resources/platform/platformLink";

function useApplications() {
  const platformLink = usePlatformLink({
    path: "applications",
  });
  const onClick = useHandler<
    Extract<SidebarMenuItem, { onClick: Function }>["onClick"]
  >(function onClick(e) {
    platformLink.handleClick(e.domEvent);
  });

  return SideMenuLayout.defineSidebarMenuItems([
    {
      key: "application",
      label: (
        <ItemWithIcon
          endIcon={
            platformLink.isLoading ? (
              <LoadingOutlined />
            ) : (
              <MotifIcon un-i-motif="new_window" />
            )
          }
        >
          <Trans i18nKey="menu.setting.application.label" />
        </ItemWithIcon>
      ),
      onClick,
      requirePermission: "manageOrg",
    },
  ]);
}

function useSidebarMenuItems() {
  const { t } = useTranslation();
  const applications = useApplications();

  const sidebarMenuItems = useMemo(
    () =>
      SideMenuLayout.defineSidebarMenuItems([
        {
          key: "page.settings.sideMenu.category.my.title",
          label: t("page.settings.sideMenu.category.my.title"),
          children: [
            {
              label: t("glossary.myProfile"),
              path: "/settings/profile",
            },
            {
              label: t("notification.setting.title"),
              path: "/settings/notification",
            },
          ],
        },
        {
          key: "page.settings.sideMenu.category.workspace.title",
          label: t("page.settings.sideMenu.category.workspace.title"),
          children: [
            {
              label: t("organization.peopleSetting"),
              path: "/settings/users",
            },
            {
              label: t("menu.team"),
              path: "/settings/teams",
            },
            {
              label: t("organization.chatSettings.title"),
              path: "/settings/chat-settings",
            },
            {
              label: t("menu.assignmentSetting"),
              path: "/settings/assignment",
            },
          ],
        },
        {
          key: "page.settings.sideMenu.category.org.title",
          label: t("page.settings.sideMenu.category.org.title"),
          children: [
            {
              label: t("organization.basicSetting"),
              path: "/settings/org",
            },
            {
              label: t("organization.channelSetting"),
              path: "/settings/channels",
            },
            ...applications,
          ],
        },
      ]),
    [applications, t],
  );
  return sidebarMenuItems;
}

export { useSidebarMenuItems };
