import {
  MemberNoteSchema,
  MemberSchema,
  OrgSchema,
} from "@zeffiroso/cantata/models";
import { isEqual } from "lodash-es";
import { z } from "zod";

import { LOCAL_STORAGE_DRAFT_NOTE } from "@/appConstant";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

/**
 * Distinct schema for selecting draft note parameters.
 */
const selectDraftNoteParametersSchema = z.object({
  orgId: OrgSchema.shape.id,
  memberId: MemberSchema.shape.id,
});

const DraftNoteSchema = z
  .object({
    content: MemberNoteSchema.shape.content,
  })
  .and(selectDraftNoteParametersSchema);
type DraftNote = z.infer<typeof DraftNoteSchema>;
type SelectDraftNoteParameters = z.infer<
  typeof selectDraftNoteParametersSchema
>;
type StoreValue = Array<DraftNote>;

const { useStore: useDraftStore } = createZustandStorageStore<StoreValue>(
  LOCAL_STORAGE_DRAFT_NOTE,
  function parser(input) {
    return !Array.isArray(input)
      ? []
      : input.flatMap(function parseItem(input) {
          const parsedResult = DraftNoteSchema.safeParse(input);
          return parsedResult.success ? [parsedResult.data] : [];
        });
  },
);

const MAX_DRAFT_NOTE_SIZE = 5;

function getDraftNoteFromStoreValue({
  storeValue,
  selectDraftNoteParameters,
}: {
  storeValue: StoreValue;
  selectDraftNoteParameters: SelectDraftNoteParameters;
}): DraftNote | undefined {
  return storeValue.find(
    (note) =>
      selectDraftNoteParameters.orgId === note.orgId &&
      selectDraftNoteParameters.memberId === note.memberId,
  );
}

function getDraftNote(
  selectDraftNoteParameters: SelectDraftNoteParameters,
): DraftNote | undefined {
  const state = useDraftStore.getState();
  return getDraftNoteFromStoreValue({
    storeValue: state.value,
    selectDraftNoteParameters,
  });
}

function getDraftNoteContent(
  ...args: Parameters<typeof getDraftNote>
): undefined | DraftNote["content"] {
  const draftNote = getDraftNote(...args);
  return draftNote?.content;
}

/**
 * Clear the draft note.
 */
function clear(selectDraftNoteParameters: SelectDraftNoteParameters) {
  const draftNote = getDraftNote(selectDraftNoteParameters);
  if (!draftNote) return;
  useDraftStore.setState({
    value: useDraftStore
      .getState()
      .value.filter((current) => current !== draftNote),
  });
}

/**
 * Set the content of the draft note. The content will be saved to the local
 * storage. The newer draft note will be at the top. The size of the draft notes
 * will be limited. If the size exceeds the limit, the older draft notes will be
 * removed.
 */
function setContent({
  content,
  selectDraftNoteParameters,
}: {
  content: string | ((prev: string) => string);
  selectDraftNoteParameters: SelectDraftNoteParameters;
}) {
  const currentState = useDraftStore.getState();
  const currentDraftNote = getDraftNoteFromStoreValue({
    storeValue: currentState.value,
    selectDraftNoteParameters,
  });
  const newContent =
    typeof content === "function"
      ? content(currentDraftNote?.content ?? "")
      : content;
  const newDraftNote: DraftNote = {
    content: newContent,
    ...selectDraftNoteParameters,
  };
  if (isEqual(currentDraftNote, newDraftNote)) return;
  const nextStateValue = [
    // Newer draft note should be at the top
    newDraftNote,
    ...currentState.value.filter(
      (note) =>
        !(
          note.orgId === selectDraftNoteParameters.orgId &&
          note.memberId === selectDraftNoteParameters.memberId
        ),
    ),
  ];
  // Limit the size of draft notes
  while (
    nextStateValue.length > MAX_DRAFT_NOTE_SIZE &&
    nextStateValue.length > 0
  )
    nextStateValue.pop();
  useDraftStore.setState({
    value: nextStateValue,
  });
}

/**
 * Draft note utilities.
 */
const draftNoteUtils = {
  clear,
  setContent,
  getDraftNoteContent,
};

export { draftNoteUtils };
