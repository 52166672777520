import type { z } from "zod";

/**
 * Set a fallback value for a Zod schema.
 *
 * This function is designed to provide fallback values for Zod schemas,
 * particularly useful for handling fallback partials and extending schemas.
 *
 * Example Usage:
 * ```ts
 * // Set a fallback value for a string schema
 * const nameSchema = setZodFallback(z.string(), "Unknown");
 *
 * // Set a fallback value for a number schema
 * const ageSchema = setZodFallback(z.number(), NaN);
 *
 * // Set fallback values for an object schema with nested schemas
 * const schema = setZodFallback(
 *   z.object({
 *     name: nameSchema,
 *     age: ageSchema,
 *   }),
 *   { name: nameSchema.fallback, age: ageSchema.fallback },
 * );
 * ```
 */
function setZodFallback<T>(
  schema: z.ZodType<T>,
  fallback: T extends undefined ? never : T,
) {
  return Object.assign(schema.catch(fallback) as typeof schema, { fallback });
}

export { setZodFallback };
