import { makeApi } from "@zodios/core";
import { z } from "zod";

import { TagSchema } from "../models";

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/tag/tags",
    response: z.object({
      tags: z.array(TagSchema),
    }),
  },
  {
    alias: "create",
    method: "post",
    path: "api/v1/orgs/:orgId/tag/tags",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          name: z.string(),
        }),
      },
    ],
    response: TagSchema,
  },
]);

export { api };
