import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import { ClassNames, css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { useMediaQueryListMatches } from "@zeffiroso/utils/react/useMediaQueryListMatches";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import type { ElementRef, FC, ReactNode, Ref } from "react";
import { useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { create } from "zustand";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Drawer } from "@/components/Drawer";
import { MotifIcon } from "@/components/MotifIcon";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenu } from "@/layout/SideMenu";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { MemberUnselected } from "@/routes/Chat/ui/MemberUnselected";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";

type LayoutSize = "sm" | "md" | "lg";

const breakpointGte = define<Partial<Record<LayoutSize, number>>>()({
  lg: 1330,
  md: 1024,
});

const breakpointLte = define<Partial<Record<LayoutSize, number>>>()({
  md: breakpointGte.lg - 1,
  sm: breakpointGte.md - 1,
});

/**
 * We don't use the `breakpoints` from the theme because the chat page is too
 * complex to be able to use the same breakpoints as the rest of the app.
 */
const breakpoints = {
  lte: breakpointLte,
  gte: breakpointGte,
};

const breakpointHooks = {
  useLteMd: () =>
    useMediaQueryListMatches(`(max-width: ${breakpoints.lte.md}px)`),
  useLteSm: () =>
    useMediaQueryListMatches(`(max-width: ${breakpoints.lte.sm}px)`),
  useGteLg: () =>
    useMediaQueryListMatches(`(min-width: ${breakpoints.gte.lg}px)`),
  useGteMd: () =>
    useMediaQueryListMatches(`(min-width: ${breakpoints.gte.md}px)`),
};

type LayoutProps = {
  sideMenuRef: Ref<ElementRef<"div">>;
  sideMenuFooterRef: Ref<ElementRef<"div">>;
  membersPanelRef: Ref<ElementRef<"div">>;
  chatPanelRef: Ref<ElementRef<"div">>;
  memberProfilePanelRef: Ref<ElementRef<"div">>;
};

const wideScreenStyles = defineStyles({
  outer: css([
    cssFlexInheritAndFill,
    {
      flexDirection: "row",
    },
  ]),
  main: css([
    cssFlexInheritAndFill,
    {
      overflow: "auto",
    },
  ]),
  sideMenuPanel: css([
    cssFlexInheritAndFill,
    {
      "& > *": cssFlexInheritAndFill,
    },
  ]),
  membersPanel: css([
    cssFlexInheritAndFill,
    {
      width: 318,
      boxSizing: "content-box",
      flex: "initial",
      borderRight: `1px solid ${theme.colors.neutral003}`,
      "& > *": cssFlexInheritAndFill,
    },
  ]),
  chatPanel: css([
    cssFlexInheritAndFill,
    {
      "& > *": cssFlexInheritAndFill,
    },
  ]),
  memberProfilePanel: css([
    cssFlexInheritAndFill,
    {
      width: 316,
      flex: "initial",
      borderLeft: `1px solid ${theme.colors.neutral003}`,
      /**
       * Portal inner
       */
      "& > *": cssFlexInheritAndFill,
    },
  ]),
});

const useSideMenuPanelStore = create<boolean>(() => true);
function openSideMenuPanel() {
  useSideMenuPanelStore.setState(true);
}
function closeSideMenuPanel() {
  useSideMenuPanelStore.setState(false);
}

const useSideMenuDrawerStore = create<boolean>(() => false);
function openSideMenuDrawer() {
  useSideMenuDrawerStore.setState(true);
}
function closeSideMenuDrawer() {
  useSideMenuDrawerStore.setState(false);
}

const useOpenProfileDrawerStore = create<boolean>()(() => false);
function openProfileDrawer() {
  useOpenProfileDrawerStore.setState(true);
}
function closeProfileDrawer() {
  useOpenProfileDrawerStore.setState(false);
}

const SideMenuPanel = forwardRef<
  ElementRef<typeof SideMenu.Panel>,
  Omit<ComponentProps<typeof SideMenu.Panel>, "title" | "onCollapse">
>(function SideMenuPanel(props, ref) {
  const { t } = useTranslation();
  const sideMenuOpened = useSideMenuPanelStore();
  return (
    <SideMenu.Panel
      expanded={sideMenuOpened}
      onCollapse={closeSideMenuPanel}
      title={t("page.chat.title")}
      {...props}
      ref={ref}
    />
  );
});

const LgLayout: FC<LayoutProps> = ({
  sideMenuRef,
  sideMenuFooterRef,
  membersPanelRef,
  chatPanelRef,
  memberProfilePanelRef,
}) => {
  const activeMemberId = memberIdUtils.useGet();
  return (
    <div css={wideScreenStyles.outer}>
      <SideMenuPanel footer={<div ref={sideMenuFooterRef} />}>
        <div css={wideScreenStyles.sideMenuPanel} ref={sideMenuRef} />
      </SideMenuPanel>
      <MainLayout.Paper css={wideScreenStyles.main}>
        <div css={wideScreenStyles.membersPanel} ref={membersPanelRef} />
        <MemberUnselected
          style={!activeMemberId ? undefined : { display: "none" }}
        />
        <div
          style={activeMemberId ? undefined : { display: "none" }}
          css={wideScreenStyles.chatPanel}
          ref={chatPanelRef}
        />
        <div
          style={activeMemberId ? undefined : { display: "none" }}
          css={wideScreenStyles.memberProfilePanel}
          ref={memberProfilePanelRef}
        />
      </MainLayout.Paper>
    </div>
  );
};

const MdLayout: FC<LayoutProps> = ({
  sideMenuRef,
  sideMenuFooterRef,
  membersPanelRef,
  chatPanelRef,
  memberProfilePanelRef,
}) => {
  const activeMemberId = memberIdUtils.useGet();
  const profileOpened = useOpenProfileDrawerStore();
  return (
    <>
      <div css={wideScreenStyles.outer}>
        <SideMenuPanel footer={<div ref={sideMenuFooterRef} />}>
          <div css={wideScreenStyles.sideMenuPanel} ref={sideMenuRef} />
        </SideMenuPanel>
        <MainLayout.Paper css={wideScreenStyles.main}>
          <div css={wideScreenStyles.membersPanel} ref={membersPanelRef} />
          <MemberUnselected
            style={!activeMemberId ? undefined : { display: "none" }}
          />
          <div
            style={activeMemberId ? undefined : { display: "none" }}
            css={wideScreenStyles.chatPanel}
            ref={chatPanelRef}
          />
        </MainLayout.Paper>
      </div>
      <ClassNames>
        {({ css }) => {
          const rootClassName = css`
            .ant-drawer-body {
              padding: 0;
            }
          `;
          return (
            <Drawer.Portal
              rootClassName={rootClassName}
              closable={false}
              onClose={closeProfileDrawer}
              open={Boolean(activeMemberId) && profileOpened}
              ref={memberProfilePanelRef}
            />
          );
        }}
      </ClassNames>
    </>
  );
};

const smStyles = defineStyles({
  root: css(cssFlexInheritAndFill),
  sideMenuDrawer: css({
    "&": {
      backgroundColor: theme.colors.neutral001,
    },
    "& .ant-drawer-header": {
      padding: 16,
    },
    "& .ant-drawer-title": {
      fontSize: "1.125rem",
      fontWeight: 500,
      color: theme.colors.neutral010,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 12,
    },
    "& .ant-drawer-body": {
      padding: 0,
    },
    "& .ant-drawer-footer": {
      padding: 0,
    },
  }),
  sideMenuTitle: css({
    fontSize: "inherit",
    fontWeight: "inherit",
    color: "inherit",
    margin: 0,
  }),
  sideMenuCollapseButton: css({
    "&:not(:active):not(:focus):not(:hover) .anticon": {
      color: theme.colors.neutral009,
    },
  }),
  sideMenuContent: css({
    "&, & > *": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
  }),
  membersPanel: css({
    "&, & > *": [
      cssFlexInheritAndFill,
      {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      },
    ],
  }),
});

const SmLayout: FC<LayoutProps> = ({
  sideMenuRef,
  sideMenuFooterRef,
  membersPanelRef,
  chatPanelRef,
  memberProfilePanelRef,
}) => {
  const { t } = useTranslation();
  const activeMemberId = memberIdUtils.useGet();
  const sideMenuOpened = useSideMenuDrawerStore();
  const profileOpened = useOpenProfileDrawerStore();
  const outerElRef = useRef<ElementRef<"div">>(null);
  return (
    <div css={smStyles.root} ref={outerElRef}>
      <Drawer
        css={smStyles.sideMenuDrawer}
        getContainer={() => outerElRef.current ?? document.body}
        closable={false}
        open={sideMenuOpened}
        onClose={closeSideMenuDrawer}
        placement="left"
        width={300}
        title={
          <>
            <h1 css={smStyles.sideMenuTitle}>{t("page.chat.title")}</h1>
            <NarrowIconButton
              css={smStyles.sideMenuCollapseButton}
              size="large"
              iconSize="middle"
              icon={<MotifIcon un-i-motif="menu_collapse" />}
              onClick={closeSideMenuDrawer}
            />
          </>
        }
        footer={<div ref={sideMenuFooterRef} />}
      >
        <div css={smStyles.sideMenuContent} ref={sideMenuRef} />
      </Drawer>
      <div css={smStyles.membersPanel} ref={membersPanelRef} />
      <Drawer.Portal.Full
        getContainer={() => outerElRef.current ?? document.body}
        closable={false}
        open={Boolean(activeMemberId)}
        ref={chatPanelRef}
      />
      <Drawer.Portal.Full
        getContainer={() => outerElRef.current ?? document.body}
        closable={false}
        open={Boolean(activeMemberId) && profileOpened}
        ref={memberProfilePanelRef}
      />
    </div>
  );
};

function useLayoutSize(): "sm" | "md" | "lg" {
  const lteSm = breakpointHooks.useLteSm();
  const lteMd = breakpointHooks.useLteMd();
  return lteSm ? "sm" : lteMd ? "md" : "lg";
}

const Layout: FC<{
  sideMenu: ReactNode;
  sideMenuFooter: ReactNode;
  membersPanel: ReactNode;
  chatPanel: ReactNode;
  memberProfilePanel: ReactNode;
}> = ({
  sideMenu,
  sideMenuFooter,
  membersPanel,
  chatPanel,
  memberProfilePanel,
}) => {
  const layoutSize = useLayoutSize();
  const sideMenuPortal = usePortal();
  const sideMenuFooterPortal = usePortal();
  const membersPanelPortal = usePortal();
  const chatPanelPortal = usePortal();
  const memberProfilePanelPortal = usePortal();
  const LayoutComponent = useMemo(
    () =>
      layoutSize === "lg"
        ? LgLayout
        : layoutSize === "md"
          ? MdLayout
          : SmLayout,
    [layoutSize],
  );
  return (
    <>
      <LayoutComponent
        sideMenuRef={sideMenuPortal.setOuterEl}
        sideMenuFooterRef={sideMenuFooterPortal.setOuterEl}
        membersPanelRef={membersPanelPortal.setOuterEl}
        chatPanelRef={chatPanelPortal.setOuterEl}
        memberProfilePanelRef={memberProfilePanelPortal.setOuterEl}
      />
      {createPortal(sideMenu, sideMenuPortal.innerEl)}
      {createPortal(sideMenuFooter, sideMenuFooterPortal.innerEl)}
      {createPortal(membersPanel, membersPanelPortal.innerEl)}
      {createPortal(chatPanel, chatPanelPortal.innerEl)}
      {createPortal(memberProfilePanel, memberProfilePanelPortal.innerEl)}
    </>
  );
};

const api = Object.assign(Layout, {
  useLayoutSize,
  breakpointHooks,
  sideMenu: {
    panel: {
      open: openSideMenuPanel,
      close: closeSideMenuPanel,
      useOpened() {
        return useSideMenuPanelStore();
      },
    },
    drawer: {
      open: openSideMenuDrawer,
      close: closeSideMenuDrawer,
      useOpened() {
        return useSideMenuDrawerStore();
      },
    },
  },
  profileDrawer: {
    open: openProfileDrawer,
    close: closeProfileDrawer,
    useOpened() {
      return useOpenProfileDrawerStore();
    },
  },
});

export { api as Layout };
