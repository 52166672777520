import type {
  Action,
  Background,
  FlexBox,
  FlexContainer,
  FlexImage,
  FlexVideo,
  MessageAction,
  Offset,
  URIAction,
} from "@line/bot-sdk";

interface FlexPreviewProps {
  rowIndex: number;
  message: FlexContainer;
}

const isFlexBox = (n: FlexBox | FlexImage | FlexVideo): n is FlexBox =>
  n.type === "box";

type ExtraFields = {
  label: string;
};

const isMessageAction = (
  action: Action | undefined,
): action is MessageAction & ExtraFields =>
  action !== undefined && action.type === "message";

const isURIAction = (
  action: Action | undefined,
): action is URIAction & ExtraFields =>
  action !== undefined && action.type === "uri";

interface FlexBoxStyles extends Offset {
  background?: Background;
  backgroundColor?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  paddingAll?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingStart?: string;
  paddingEnd?: string;
  cornerRadius?: string;
}

interface FlexBoxClasses {
  flex?: number;
  spacing?: "none" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  margin?: "none" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  borderWidth?:
    | string
    | "none"
    | "light"
    | "normal"
    | "medium"
    | "semi-bold"
    | "bold";
  cornerRadius?: string | "none" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  position?: "absolute" | "relative";
}

export type { FlexBoxClasses, FlexBoxStyles, FlexPreviewProps };

export { isFlexBox, isMessageAction, isURIAction };
