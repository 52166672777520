import { isEqual } from "date-fns";
import { uniqBy } from "lodash-es";
import { useCallback } from "react";

import { useFeatureFlag } from "@/app/featureFlag";
import type { CantataTypes } from "@/cantata/types";

/**
 * Compare function for sorting messages.
 * From the oldest to the newest.
 */
const sortMessageCompareFn = (() => {
  type Item = Pick<CantataTypes["MessageDetail"], "createdAt" | "id">;
  return function compareFn(a: Item, b: Item) {
    return !isEqual(a.createdAt, b.createdAt)
      ? a.createdAt > b.createdAt
        ? 1
        : -1
      : a.id > b.id
        ? 1
        : -1;
  };
})();

/**
 * Merge data from the current, previous, and next.
 */
function mergeDataFn<
  M extends Parameters<typeof sortMessageCompareFn>[number] & {
    id: CantataTypes["MessageDetail"]["id"];
  },
>({
  current,
  previous = [],
  next = [],
}: {
  current: M[];
  previous?: M[];
  next?: M[];
}) {
  return uniqBy(
    [
      /**
       * Ensure that the "current" data is always placed last, as it should be
       * overwritten in case of any duplicates.
       */
      ...next,
      ...previous,
      ...current,
    ],
    "id",
  ).sort(sortMessageCompareFn);
}

/**
 * Get `metadata.backupMediaUrl` from the message.
 */
function getBackupMediaUrl(
  message: Pick<CantataTypes["Message"], "metadata">,
): string {
  return (
    (hasMediaMetadata(message.metadata)
      ? message.metadata.backupMediaUrl
      : "") || ""
  );
}

/**
 * Try `metadata.backupMediaUrl` first, then `originUrl`.
 *
 * If the feature flag `originUrlOnly` is enabled, only `originUrl` will be
 * used.
 *
 * Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1708360164026869?thread_ts=1707981664.646579&cid=C02R6ETJMEY)
 */
function useGetMedialUrl() {
  const originUrlOnly = useFeatureFlag("originUrlOnly");
  const getMediaUrl = useCallback(
    function getMediaUrl(
      message: Pick<CantataTypes["Message"], "originUrl" | "metadata">,
    ): string {
      return (
        (originUrlOnly
          ? message.originUrl
          : getBackupMediaUrl(message) || message.originUrl) || ""
      );
    },
    [originUrlOnly],
  );
  return getMediaUrl;
}

type FileMetadata = Extract<
  CantataTypes["Message"]["metadata"],
  { filename: any }
>;

type MediaMetadata = Extract<
  CantataTypes["Message"]["metadata"],
  {
    backupMediaUrl?: any;
  }
> & {
  backupMediaUrl: unknown;
};

function isFileMetadata<Metadata extends CantataTypes["Message"]["metadata"]>(
  metadata: Metadata,
): metadata is Metadata & FileMetadata {
  return !metadata ? false : "filename" in metadata;
}

function hasMediaMetadata<Metadata extends CantataTypes["Message"]["metadata"]>(
  metadata: Metadata,
): metadata is Metadata & MediaMetadata {
  return !metadata ? false : "backupMediaUrl" in metadata;
}

export {
  hasMediaMetadata,
  isFileMetadata,
  mergeDataFn,
  sortMessageCompareFn,
  useGetMedialUrl,
};
