import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { type FC, type ReactNode, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { useFillYmdRangeUtils } from "@/resources/datetime";
import { usePageInfoUtil } from "@/routes/Insights/Contacts/pageInfo";

function useMemberDetailQueries() {
  const orgId = useActiveOrgIdStore((state) => state.value);

  const pageInfoUtil = usePageInfoUtil();

  const fillYmdRangeUtils = useFillYmdRangeUtils();
  const fillYmdRange = useMemo(
    () => fillYmdRangeUtils[pageInfoUtil.data.membersInterval],
    [fillYmdRangeUtils, pageInfoUtil.data.membersInterval],
  );

  const newMemberDetailQuery =
    cantata.dashboardMember.useNewChannelMemberDetail(
      {
        params: { orgId },
        queries: {
          ...pageInfoUtil.computed.commonQueries,
          interval: pageInfoUtil.data.membersInterval,
        },
      },
      {
        suspense: true,
        useErrorBoundary: true,
        select: (state) => {
          return {
            ...state,
            countMetrics: fillYmdRange({
              dateKey: "date",
              data: state.countMetrics,
              fillValue: {
                value: 0,
              },
              start: pageInfoUtil.data.startTime,
              end: pageInfoUtil.data.endTime,
            }),
          };
        },
      },
    );

  const handledMemberDetailQuery =
    cantata.dashboardMember.useHandledChannelMemberetail(
      {
        params: { orgId },
        queries: {
          ...pageInfoUtil.computed.commonQueries,
          interval: pageInfoUtil.data.membersInterval,
        },
      },
      {
        suspense: true,
        useErrorBoundary: true,
        select: (state) => {
          return {
            ...state,
            countMetrics: fillYmdRange({
              dateKey: "date",
              data: state.countMetrics,
              fillValue: {
                value: 0,
              },
              start: pageInfoUtil.data.startTime,
              end: pageInfoUtil.data.endTime,
            }),
          };
        },
      },
    );

  const newGroupDetailQuery = cantata.dashboardMember.useNewChannelMemberDetail(
    {
      params: { orgId },
      queries: {
        ...pageInfoUtil.computed.commonQueries,
        interval: pageInfoUtil.data.membersInterval,
        isGroup: true,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: (state) => {
        return {
          ...state,
          countMetrics: fillYmdRange({
            dateKey: "date",
            data: state.countMetrics,
            fillValue: {
              value: 0,
            },
            start: pageInfoUtil.data.startTime,
            end: pageInfoUtil.data.endTime,
          }),
        };
      },
    },
  );

  const handledGroupDetailQuery =
    cantata.dashboardMember.useHandledChannelMemberetail(
      {
        params: { orgId },
        queries: {
          ...pageInfoUtil.computed.commonQueries,
          interval: pageInfoUtil.data.membersInterval,
          isGroup: true,
        },
      },
      {
        suspense: true,
        useErrorBoundary: true,
        select: (state) => {
          return {
            ...state,
            countMetrics: fillYmdRange({
              dateKey: "date",
              data: state.countMetrics,
              fillValue: {
                value: 0,
              },
              start: pageInfoUtil.data.startTime,
              end: pageInfoUtil.data.endTime,
            }),
          };
        },
      },
    );

  const queries = useMemo(
    () => ({
      new: newMemberDetailQuery,
      handled: handledMemberDetailQuery,
      newGroup: newGroupDetailQuery,
      handledGroup: handledGroupDetailQuery,
    }),
    [
      newMemberDetailQuery,
      handledMemberDetailQuery,
      newGroupDetailQuery,
      handledGroupDetailQuery,
    ],
  );
  return queries;
}

const MemberDetailQueriesContext =
  createQueriesContext<ReturnType<typeof useMemberDetailQueries>>();

const OrgQueriesProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const queries = useMemberDetailQueries();
  return (
    <MemberDetailQueriesContext.Provider queries={queries}>
      {children}
    </MemberDetailQueriesContext.Provider>
  );
};

const memberDetailQueriesContext = {
  Provider: OrgQueriesProvider,
  useData: MemberDetailQueriesContext.useData,
};

export { memberDetailQueriesContext };
