import {
  aFriday,
  aMonday,
  aSaturday,
  aSunday,
  aThursday,
  aTuesday,
  aWednesday,
} from "@zeffiroso/utils/date/dummy";
import {
  WeekdayEnumLike,
  type WeekdayNumberSchema,
} from "@zeffiroso/utils/zod/WeekdayNumberSchema";
import { flow, merge } from "lodash-es";
import { useCallback, useMemo } from "react";
import type { z } from "zod";

import { useDateTimeFormat } from "@/components/DateTimeFormat/hooks/useDateTimeFormat";
import type { DateTimeFormatOptions } from "@/components/DateTimeFormat/types";

const defaultOptions: DateTimeFormatOptions = {
  dateTimeFormatOptions: {
    weekday: "short",
  },
};

const numberToDayMap: Record<z.infer<typeof WeekdayNumberSchema>, Date> = {
  [WeekdayEnumLike.Sunday]: aSunday,
  [WeekdayEnumLike.Monday]: aMonday,
  [WeekdayEnumLike.Tuesday]: aTuesday,
  [WeekdayEnumLike.Wednesday]: aWednesday,
  [WeekdayEnumLike.Thursday]: aThursday,
  [WeekdayEnumLike.Friday]: aFriday,
  [WeekdayEnumLike.Saturday]: aSaturday,
};

/**
 * A hook that returns a function to format a weekday number to a string.
 *
 * ```tsx
 * import { WeekdayEnumLike } from "@zeffiroso/utils/zod/WeekdayNumberSchema";
 *
 * const { format } = useWeekdayDisplay();
 * const formattedWeekday = format(WeekdayEnumLike.Monday); // "Mon"
 * ```
 */
const useWeekdayDisplay = (options: DateTimeFormatOptions = {}) => {
  const mergedOptions = useMemo<NonNullable<DateTimeFormatOptions>>(
    () => merge({}, defaultOptions, options),
    [options],
  );
  const instance = useDateTimeFormat(mergedOptions);
  const format = useCallback(
    function formatWeekday(weekday: z.infer<typeof WeekdayNumberSchema>) {
      return flow(
        () => weekday,
        (num) => numberToDayMap[num],
        instance.format,
      )();
    },
    [instance],
  );

  return {
    instance,
    format,
  };
};

const api = Object.assign(useWeekdayDisplay, {
  numberToDayMap,
  defaultOptions,
});

export { api as useWeekdayDisplay };
