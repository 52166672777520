import { uniq } from "lodash-es";

import { useFeatureFlagLocalStorageStore } from "@/app/featureFlag";
import { testAccounts } from "@/config/featureFlags";

function enableTestAccountFeatureFlag(email: string) {
  const accounts = Array.isArray(testAccounts) ? testAccounts : [testAccounts];
  const featureFlagsToEnable = uniq(
    accounts.flatMap((account) => {
      const emailMatched = Array.isArray(account.email)
        ? account.email.includes(email)
        : typeof account.email === "function"
          ? account.email(email)
          : account.email === email;
      if (!emailMatched) return [];

      return Array.isArray(account.feature)
        ? account.feature
        : [account.feature];
    }),
  );
  useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
    ...current,
    ...Object.fromEntries(featureFlagsToEnable.map((key) => [key, true])),
  }));
}

export { enableTestAccountFeatureFlag };
