import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { RouteTabs } from "@/components/Tabs/RouteTabs";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { Navigate } from "@/router/utils";
import { defineChildRouteObject } from "@/router/utils/defineChildRouteObject";
import { Edit } from "@/routes/Settings/Channels/pages/Edit";
import { List } from "@/routes/Settings/Channels/pages/List";
import { Unification } from "@/routes/Settings/Channels/Unification";

const Tabs: FC = () => {
  const { t } = useTranslation();
  const items = useMemo<ComponentProps<typeof RouteTabs>["items"]>(
    () => [
      {
        key: "/settings/channels/tabs",
        label: t("organization.channelSetting"),
        children: <Outlet />,
      },
      {
        key: "/settings/channels/tabs/unification",
        label: t("settings.tab.unification.label"),
        children: <Outlet />,
      },
    ],
    [t],
  );
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header>{t("organization.channelSetting")}</MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <RouteTabs items={items} />
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
};

const channelsRouteObject = defineChildRouteObject({
  children: [
    {
      path: "edit/:channelId",
      element: <Edit />,
    },
    {
      path: "tabs",
      element: <Tabs />,
      children: [
        {
          index: true,
          element: <List />,
        },
        {
          path: "unification",
          element: <Unification />,
        },
      ],
    },
    {
      index: true,
      element: <Navigate to="/settings/channels/tabs" />,
    },
  ],
});

export { channelsRouteObject };
