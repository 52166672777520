import { crescendoLabBirthDay } from "@chatbotgang/etude/dummy";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { FeatureControlsSchema } from "@zeffiroso/cantata/models";
import { theme } from "@zeffiroso/theme";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { Typography } from "antd";
import { type FC, type ReactNode, useMemo, useState } from "react";
import { objectEntries } from "tsafe";
import type { z } from "zod";
import { createWithEqualityFn } from "zustand/traditional";

import { APP_LOADED_DATE } from "@/appConstant";
import { Button } from "@/components/Button";
import { CountDown } from "@/components/CountDown";
import { Form } from "@/components/Form";
import { Input } from "@/components/Input";
import { PhIcon } from "@/components/PhIcon";
import { RadioGroup } from "@/components/Radio";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { searchNormalize } from "@/router/components/Protected/InOrg/Debug/searchNormalize";

type FeatureControl = z.infer<typeof FeatureControlsSchema>;
type FeatureControlName = keyof FeatureControl;
type FeatureControlValue = FeatureControl[FeatureControlName];

const configUtils = (function iife() {
  const enabled = {
    enabledAt: crescendoLabBirthDay,
  } as const satisfies FeatureControlValue;

  const disabled = {
    enabledAt: null,
  } as const satisfies FeatureControlValue;

  const configUtils = {
    enabled,
    disabled,
  };

  return configUtils;
})();

/**
 * Define the feature control override utils.
 */
function setupFeatureControlOverrideUtils() {
  const useStore = createWithEqualityFn<{
    featureControls: Array<{
      featureControl: FeatureControlName;
      value: FeatureControlValue;
    }>;
  }>()(
    () => ({
      featureControls: [],
    }),
    shallow,
  );
  function clearFeatureControls() {
    useStore.setState({ featureControls: [] });
  }
  function clear() {
    useStore.setState({
      featureControls: [],
    });
  }
  function setFeatureControl(
    featureControl: FeatureControlName,
    enabled: boolean,
  ) {
    useStore.setState((state) => {
      const featureControls = state.featureControls.filter(
        (p) => p.featureControl !== featureControl,
      );
      return {
        featureControls: [
          ...featureControls,
          {
            featureControl,
            value: enabled ? configUtils.enabled : configUtils.disabled,
          },
        ],
      };
    });
  }
  function clearFeatureControl(featureControl: FeatureControlName) {
    useStore.setState((state) => {
      return {
        featureControls: state.featureControls.filter(
          (p) => p.featureControl !== featureControl,
        ),
      };
    });
  }
  const featureControlOverrideUtils = {
    useStore,
    clearFeatureControl,
    clearFeatureControls,
    clear,
    setFeatureControl,
  };
  return featureControlOverrideUtils;
}

const FeatureControlOverrideUtilsContext = createContext<
  ReturnType<typeof setupFeatureControlOverrideUtils>
>({
  name: "FeatureControlOverrideUtilsContext",
});

const useFeatureControlOverrideUtils =
  FeatureControlOverrideUtilsContext.useContext;

/**
 * The provider for feature control override utils.
 *
 * Please make sure this provider will be mounted in a org context to make sure
 * the feature control override is org specific.
 */
const FeatureControlOverrideUtilsProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [featureControlOverrideUtils] = useState(() =>
    setupFeatureControlOverrideUtils(),
  );
  return (
    <FeatureControlOverrideUtilsContext.Provider
      value={featureControlOverrideUtils}
    >
      {children}
    </FeatureControlOverrideUtilsContext.Provider>
  );
};

/**
 * The radio group for feature control.
 */
const FeatureControlRadioGroup: FC<{
  /**
   * The feature control to override.
   */
  featureControl: FeatureControlName;
}> = ({ featureControl }) => {
  const featureControlOverrideUtils = useFeatureControlOverrideUtils();
  const overriddenFeatureControls = featureControlOverrideUtils.useStore(
    (state) => state.featureControls,
  );
  const value = useMemo(() => {
    const overriddenFeatureControl = overriddenFeatureControls.find(
      (p) => p.featureControl === featureControl,
    );
    if (!overriddenFeatureControl) return "undefined";
    const config = overriddenFeatureControl.value;
    return config.enabledAt !== null && APP_LOADED_DATE >= config.enabledAt
      ? "true"
      : "false";
  }, [overriddenFeatureControls, featureControl]);
  const onChange = useHandler((e) => {
    const value: string = e.target.value;
    if (value === "true")
      featureControlOverrideUtils.setFeatureControl(featureControl, true);
    else if (value === "false")
      featureControlOverrideUtils.setFeatureControl(featureControl, false);
    else {
      featureControlOverrideUtils.clearFeatureControl(featureControl);
    }
  });
  return (
    <RadioGroup
      value={value}
      options={[
        { label: "Default", value: "undefined" },
        { label: "Enabled", value: "true" },
        { label: "Disabled", value: "false" },
      ]}
      onChange={onChange}
    />
  );
};

const EnabledMessage: FC<{
  featureFlagKey: FeatureControlName;
}> = ({ featureFlagKey }) => {
  const orgData = orgQueriesContext.useData();
  const enabledAt = orgData.featureControl[featureFlagKey].enabledAt;
  const enabled = useMemo(() => {
    return enabledAt !== null && enabledAt < APP_LOADED_DATE;
  }, [enabledAt]);

  return (
    <>
      {enabledAt === null ? null : (
        <div css={css({ color: theme.colors.neutral007 })}>
          {enabled ? (
            "Enabled automatically"
          ) : (
            <>
              Count down to auto enable: <CountDown targetDate={enabledAt} />
            </>
          )}
        </div>
      )}
    </>
  );
};

const FeatureControlOverride: FC = () => {
  const orgQueriesData = orgQueriesContext.useData();
  const [search, setSearch] = useState("");
  const normalizedSearch = useMemo(() => searchNormalize(search), [search]);
  const filteredFeatureControls = useMemo(() => {
    return objectEntries(orgQueriesData.featureControl).flatMap(
      ([featureControl]) =>
        !normalizedSearch ||
        searchNormalize(featureControl).includes(normalizedSearch)
          ? [featureControl]
          : [],
    );
  }, [orgQueriesData.featureControl, normalizedSearch]);

  return (
    <>
      <Typography.Title level={5}>Feature controls</Typography.Title>
      <Form.Item
        label="Search"
        style={{
          marginBottom: "0.75rem",
        }}
      >
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          allowClear
          prefix={<PhIcon un-i-ph="magnifying-glass" />}
        />
      </Form.Item>
      {filteredFeatureControls.length === 0 ? (
        <Typography.Text type="secondary">No feature found</Typography.Text>
      ) : (
        filteredFeatureControls.map((featureControl) => (
          <div key={featureControl}>
            <Form.Item
              extra={<EnabledMessage featureFlagKey={featureControl} />}
              label={featureControl}
              css={css({
                marginBottom: "0",
                ".ant-form-item-label": { paddingBottom: 0 },
              })}
            >
              <FeatureControlRadioGroup featureControl={featureControl} />
            </Form.Item>
          </div>
        ))
      )}
    </>
  );
};

const FeatureControl: FC = () => {
  const featureControlOverrideUtils = useFeatureControlOverrideUtils();
  return (
    <Form layout="vertical">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography.Title level={4} style={{ flex: 1 }}>
            Feature Control Override
          </Typography.Title>
          <Button onClick={featureControlOverrideUtils.clear}>Reset</Button>
        </div>
        <FeatureControlOverride />
      </div>
    </Form>
  );
};

export {
  FeatureControl,
  FeatureControlOverrideUtilsProvider,
  useFeatureControlOverrideUtils,
};
