import { createContext } from "@chatbotgang/etude/react/createContext";

import type { CantataTypes } from "@/cantata/types";

const MessagesContext = createContext<{
  messages: Array<CantataTypes["MessageDetail"]>;
  /**
   * Is the messages loading?
   * LazyMessage will be paused if it is loading to prevent height changes.
   */
  isLoading: boolean;
  showMoreButton?: boolean;
  rootEl?: HTMLElement;
}>({ name: "Messages" });

const MessageContext = createContext<CantataTypes["MessageDetail"]>({
  name: "Message",
});

function isBotMessage<
  TInput extends Pick<CantataTypes["MessageDetail"], "senderType">,
>(
  message: TInput,
): message is TInput & {
  senderType: "bot";
} {
  return message.senderType === "bot";
}

function isUserMessage<
  TInput extends Pick<CantataTypes["MessageDetail"], "senderType">,
>(message: TInput): message is TInput & { senderType: "user" } {
  return message.senderType === "user";
}

function isMemberMessage<
  TInput extends Pick<CantataTypes["MessageDetail"], "senderType">,
>(message: TInput): message is TInput & { senderType: "member" } {
  return message.senderType === "member";
}

function isSystemMessage(
  message: CantataTypes["MessageDetail"],
): message is CantataTypes["MessageDetail"] & {
  senderType: Exclude<
    CantataTypes["MessageDetail"]["senderType"],
    "user" | "member" | "bot"
  >;
} {
  return (
    message.senderType !== "user" &&
    message.senderType !== "member" &&
    message.senderType !== "bot"
  );
}

const api = {
  MessagesProvider: MessagesContext.Provider,
  useMessages: MessagesContext.useContext,
  MessageProvider: MessageContext.Provider,
  useMessage: MessageContext.useContext,
  isBotMessage,
  isUserMessage,
  isMemberMessage,
  isSystemMessage,
};

export { api as messageUtil };
