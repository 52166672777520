import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { ClassNames, css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo, useState } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { Drawer } from "@/components/Drawer";
import { BarLoading } from "@/components/Loading/BarLoading";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";
import { GroupMemberAvatar } from "@/resources/groupMember/GroupMemberAvatar";
import { GroupMemberName } from "@/resources/groupMember/GroupMemberName";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { Layout } from "@/routes/Chat/ui/Layout";
import { UpdateGroupMemberFromLegato } from "@/routes/Chat/ui/MemberProfilePanel/GroupMembersPanel/UpdateGroupMemberFromLegato";
import { getApiErrorBodyFromUnknownError } from "@/shared/domains/error";
import { cssInheritGap, cssOneLine, defineStyles } from "@/shared/emotion";

import { MemberProfilePanel } from "..";

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
  list: css([
    {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      margin: 0,
      listStyle: "none",
    },
    cssInheritGap,
  ]),
  groupMember: css({
    padding: "2px 0px",
  }),
  avatarNameContainer: css({
    display: "flex",
    gap: "inherit",
    alignItems: "center",
    overflow: "hidden",
  }),
  avatarName: css([
    cssOneLine,
    {
      fontSize: "0.875rem",
      color: theme.colors.neutral009,
    },
  ]),
  disconnected: css({
    color: theme.colors.neutral007,
  }),
  noMember: css({
    color: theme.colors.neutral007,
  }),
  showMore: css({ padding: 0, height: 28 }),
  button: css({
    height: "auto",
    backgroundColor: "transparent",
    padding: 0,
    display: "flex",
    gap: 8,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    boxShadow: "none",
    color: theme.colors.neutral009,
    border: 0,
  }),
});

const NoMember: FC = () => (
  <div css={styles.noMember}>
    <Trans i18nKey="chat.groupProfile.noMember" />
  </div>
);

const InactiveIcon: FC = () => (
  <Tooltip
    title={<Trans i18nKey="chat.groupProfile.member.inactive.tooltip" />}
  >
    <MotifIcon un-i-motif="disconnect" css={styles.disconnected} />
  </Tooltip>
);

const INITIAL_COUNT = 5;
const EXPAND_COUNT = 20;

const GroupMemberItem: FC<{
  member: Pick<
    CantataTypes["GroupMember"],
    "id" | "displayName" | "originalName" | "avatar" | "status"
  > | null;
}> = (props) => {
  const member: NonNullable<typeof props.member> = useMemo(() => {
    if (!props.member)
      return {
        id: Number.NaN,
        displayName: "",
        originalName: "",
        avatar: "",
        status: "inactive",
      };

    return props.member;
  }, [props.member]);

  const setActiveMemberId = memberIdUtils.useSet();
  const openGroupMemberProfile = useHandler(function openGroupMemberProfile() {
    if (!props.member) return;
    setActiveMemberId(member.id);
  });

  return (
    <li css={styles.groupMember}>
      <Button
        disabled={!props.member}
        onClick={openGroupMemberProfile}
        css={styles.button}
      >
        <div css={styles.avatarNameContainer}>
          <GroupMemberAvatar member={member} size={16} />
          <span css={styles.avatarName}>
            <GroupMemberName member={member} />
          </span>
        </div>
        {member.status !== "inactive" ? null : <InactiveIcon />}
      </Button>
    </li>
  );
};

const GroupMemberDrawer: FC = () => {
  const memberId = memberIdUtils.useGet();
  const clearActiveMember = memberIdUtils.useClear();
  const layoutSize = Layout.useLayoutSize();

  if (memberIdUtils.isNonActiveMemberId(memberId)) return null;

  return (
    <ClassNames>
      {({ css }) => {
        const rootClassName = css`
          position: absolute;

          .ant-drawer-content-wrapper {
            box-shadow: none;
          }
        `;

        return (
          <Drawer
            open
            mask={false}
            closable={false}
            styles={{ body: { padding: 0 } }}
            {...(layoutSize !== "sm"
              ? { width: 316 }
              : { rootClassName, width: "100%", height: "100%" })}
          >
            <MemberProfilePanel
              showCloseDrawerButton
              onClose={clearActiveMember}
            />
          </Drawer>
        );
      }}
    </ClassNames>
  );
};

const GroupMembers: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const groupId = memberIdUtils.useGet();
  const [count, setCount] = useState(INITIAL_COUNT);
  const membersQuery = cantata.chatGroup.useListAll(
    {
      params: {
        orgId,
        groupId,
      },
    },
    {
      refetchOnMount: true,
    },
  );

  const membersCount = useMemo(() => {
    if (!membersQuery.isSuccess) return 0;
    return Math.max(membersQuery.data.count, membersQuery.data.members.length);
  }, [
    membersQuery.data?.count,
    membersQuery.data?.members.length,
    membersQuery.isSuccess,
  ]);

  const expandable = useMemo(() => {
    return membersQuery.isSuccess && count < membersCount;
  }, [count, membersCount, membersQuery.isSuccess]);

  const handleExpand = useHandler(function handlerExpand() {
    if (!membersQuery.isSuccess || !expandable) return;
    setCount((prev) => Math.min(prev + EXPAND_COUNT, membersCount));
  });

  const sortedMembers = useMemo(() => {
    if (!membersQuery.isSuccess) return [];

    return membersQuery.data.members.toSorted((a, b) => {
      const aName = a.displayName || a.originalName;
      const bName = b.displayName || b.originalName;
      return aName.localeCompare(bName);
    });
  }, [membersQuery.data?.members, membersQuery.isSuccess]);

  const allMemberInList = useMemo(() => {
    if (!membersQuery.isSuccess) return [];

    const allMemberInList = Array.from(
      {
        /**
         * For certain test data, the array length might exceed the `count` value we obtain.
         * To ensure that no items are truncated and that all items are listed,
         * we need to use the maximum value between the array length and the count value as the length of the final array.
         */
        length: Math.max(
          membersQuery.data.count,
          membersQuery.data.members.length,
        ),
      },
      (_, index) => (index in sortedMembers ? sortedMembers[index] : null),
    );
    return allMemberInList;
  }, [
    membersQuery.data?.count,
    membersQuery.data?.members.length,
    membersQuery.isSuccess,
    sortedMembers,
  ]);

  const membersToRender = useMemo<(CantataTypes["GroupMember"] | null)[]>(
    () => allMemberInList.slice(0, count),
    [allMemberInList, count],
  );

  const noMemberInGroup = useMemo(
    function checkMemberInGroup() {
      if (membersQuery.isLoading) return false;
      if (membersQuery.isError) {
        const errorApiBody = getApiErrorBodyFromUnknownError(
          membersQuery.error,
        );
        return errorApiBody?.name === "REMOTE_LINE_RESOURCE_NOT_FOUND";
      }
      return membersQuery.data.count === 0;
    },
    [
      membersQuery.data?.count,
      membersQuery.error,
      membersQuery.isError,
      membersQuery.isLoading,
    ],
  );

  if (membersQuery.isLoading) return <BarLoading />;

  if (noMemberInGroup) return <NoMember />;

  if (membersQuery.isError)
    return <Alert type="error" message={membersQuery.error.message} />;

  return (
    <div css={styles.root}>
      <ul css={styles.list}>
        <memberIdUtils.Provider>
          {membersToRender.map((member, index) =>
            member === null ? (
              <GroupMemberItem key={`unknown_member-${index}`} member={null} />
            ) : (
              <GroupMemberItem
                member={member}
                key={`group_member-${member.id}`}
              />
            ),
          )}
          <GroupMemberDrawer />
        </memberIdUtils.Provider>
      </ul>
      {!expandable ? null : (
        <div>
          <Button type="link" onClick={handleExpand} css={styles.showMore}>
            <Trans i18nKey="chat.memberProfile.toggle.openedLabel" />
          </Button>
        </div>
      )}
      <UpdateGroupMemberFromLegato />
    </div>
  );
};

export { GroupMembers };
