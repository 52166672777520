import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Form, FormItem } from "@/components/Form";
import { BoldText, SubText } from "@/components/Typography";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { AssigneeField } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/AssigneeField";
import { ConditionsField } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/ConditionsField";
import { NameField } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/NameField";
import { FormProvider } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/FormProvider";
import { InitialValuesProvider } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/InitialValuesProvider";
import { cssColumnFieldRoot } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/style";
import { SubmitButton } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/SubmitButton";
import type { FormValues } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";
import {
  PageInfoProvider,
  usePageInfo,
} from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/usePageInfo";
const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Badge = styled.div`
  width: 80px;
  box-sizing: border-box;
  padding: 2px 8px;
  border-radius: 2px;
  background: ${theme.colors.blue002};
  color: ${theme.colors.blue006};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

const Bar = styled.div`
  min-height: 117px;
  flex: 1;
  border-left: 2px solid ${theme.colors.neutral006};
`;

const noticeBoxWrapper = css`
  max-width: 572px;
`;

const AllMessageHint = memo(function AllMessageHint() {
  const { t } = useTranslation();
  const conditions = Form.useWatch<FormValues["conditions"] | undefined>(
    "conditions",
  );
  return !conditions ||
    conditions.every(
      (condition) => condition.category !== "all-messages",
    ) ? null : (
    <div css={noticeBoxWrapper}>
      <Alert message={t("assignment.allMessageTriggered.desc")} />
    </div>
  );
});

const RuleFormContent = memo(function RuleFormContent() {
  const { t } = useTranslation();
  const pageInfo = usePageInfo();

  const title = useMemo(() => {
    if (pageInfo.isCreating) return t("assignment.editPage.title.create");
    if (pageInfo.isView) return t("assignment.editPage.title.view");
    if (pageInfo.isUpdating) return t("assignment.editPage.title.update");
    pageInfo satisfies never;
    throw new Error(inspectMessage`Unhandled pageInfo: ${pageInfo}`);
  }, [pageInfo, t]);

  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header
        breadcrumbItems={[
          {
            title: t("menu.assignmentRoutingRule"),
            to: "/settings/assignment/tabs/routing-rules",
          },
          {
            title,
          },
        ]}
      >
        {title}
      </MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <MainLayout.Section>
            {pageInfo.isView ? null : (
              <Flex
                css={css`
                  justify-content: flex-end;
                  margin-bottom: 16px;
                  gap: 16px;
                  ${cssColumnFieldRoot}
                `}
              >
                <SubmitButton status="off" />
                <SubmitButton status="on" />
              </Flex>
            )}
            <NameField />
            <Flex
              css={css`
                flex-direction: column;
                gap: 8px;
              `}
            >
              <BoldText>{t("assignment.ruleConditions")}</BoldText>
              <SubText>{t("assignment.ruleConditions.desc")}</SubText>
              <AllMessageHint />
            </Flex>
            <Flex
              css={css`
                flex-direction: column;
                margin-top: 6px;

                & > * {
                  gap: 16px;

                  & > :nth-child(2) {
                    flex: 1;
                  }

                  /* Gap only on right side */
                  &:not(:last-child) > :nth-child(2) {
                    margin-bottom: 16px;
                  }
                }
                ${cssColumnFieldRoot}
              `}
            >
              <Flex>
                <Left>
                  <Badge>{t("common.if")}</Badge>
                  <Bar />
                </Left>
                <ConditionsField />
              </Flex>
              <Flex>
                <Left>
                  <Badge>{t("common.then")}</Badge>
                </Left>
                <AssigneeField />
              </Flex>
            </Flex>
          </MainLayout.Section>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
});

export const RuleForm = memo(function RuleForm() {
  return (
    <PageInfoProvider>
      <InitialValuesProvider>
        <FormProvider>
          <RuleFormContent />
          {/* Use submit button to control the status. The component is required for the formValues. */}
          <FormItem name="status" hidden>
            <input />
          </FormItem>
        </FormProvider>
      </InitialValuesProvider>
    </PageInfoProvider>
  );
});
