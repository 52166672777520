import { number } from "@chatbotgang/etude/pitch-shifter/number";
import { object } from "@chatbotgang/etude/pitch-shifter/object";
import { string } from "@chatbotgang/etude/pitch-shifter/string";
import { parseJson } from "@crescendolab/parse-json";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { secondsToMilliseconds } from "date-fns";
import { debounce } from "lodash-es";
import type { JsonValue } from "type-fest";
import type { PersistOptions } from "zustand/middleware";
import { persist } from "zustand/middleware";
import { createWithEqualityFn } from "zustand/traditional";

import { LOCAL_STORAGE_LAST_USER_EMAIL } from "@/appConstant";

const debounceWaitMs = secondsToMilliseconds(1);

/**
 * @param storageKey The key to use when storing the state in localStorage.
 * @param parser The parser to use when parsing the state from localStorage.
 * @param options Options
 * @param options.forUser Remove if different user is signed in.
 * @param options.rest The options to pass to `zustand/middleware`.
 * @example
 *
 * ```ts
 * const { useStore } = createZustandStorageStore('my-store', object({
 *   bar: string(),
 *   foo: string()
 * }));
 * ```
 *
 * TODO:
 *
 * - [ ] Allow `date` for the parser.
 * - [ ] Remove redundant `.value`. Breaking change.
 */
function createZustandStorageStore<Value extends JsonValue>(
  storageKey: string,
  parser: (v: JsonValue) => Value,
  options: {
    forUser?: boolean;
    storage?: Storage;
  } & Omit<
    PersistOptions<
      {
        value: Value;
        setValue: (value: Value | ((value: Value) => Value)) => void;
        clear: () => void;
      },
      Partial<{
        value: Value;
        setValue: (value: Value | ((value: Value) => Value)) => void;
        clear: () => void;
      }>
    >,
    // We use `storageKey` instead.
    | "name"

    /**
     * We have overridden the `storage` option and integrated it with the
     * `parser`.
     */
    | "storage"

    // deprecated
    | "getStorage"
    | "serialize"
    | "deserialize"
  > = {},
) {
  type Store = {
    value: Value;
    setValue: (value: Value | ((value: Value) => Value)) => void;
    clear: () => void;
  };
  const deserialize = (
    raw: string,
  ): {
    state: {
      value: Value;
    };
    version: number;
  } => {
    const rawObj = parseJson(raw, { fallback: null });
    return object({
      state: object({
        value: parser,
      }),
      version: number(),
    })(rawObj);
  };
  const initialState = parser(null);
  const {
    storage = localStorage,
    forUser = true,
    version = 0,
    ...restZustandPersistOptions
  } = options;
  const useStore = createWithEqualityFn<Store>()(
    persist(
      (set, get) => ({
        value: initialState,
        setValue(value: Value | ((value: Value) => Value)) {
          set({
            value: typeof value === "function" ? value(get().value) : value,
          });
        },
        clear() {
          set({ value: initialState });
        },
      }),
      {
        name: storageKey,
        version,
        ...restZustandPersistOptions,
        storage: {
          getItem: (name) => {
            const str = storage.getItem(name) || "";
            return deserialize(str);
          },
          setItem: (name, newValue) => {
            const str = JSON.stringify(newValue);
            storage.setItem(name, str);
          },
          removeItem: (name) => storage.removeItem(name),
        },
      },
    ),
    shallow,
  );

  if (forUser) {
    // Clear the store if the user changes.
    useLastUserEmailStore.subscribe((current, prev) => {
      if (current.value === prev.value) return;
      useStore.getState().clear();
    });
  }

  /**
   * We debounce the `setValue` to avoid infinite loop.
   * Only the last value will be set.
   */
  const debouncedSetValue = debounce(function setValue(value: Value) {
    useStore.getState().setValue(value);
  }, debounceWaitMs);

  /**
   * @param event The event to listen to.
   */
  function eventHandler(event: StorageEvent) {
    if (event.storageArea !== storage || event.key !== storageKey) return;

    const deserialized = deserialize(event.newValue ?? "");
    // Ignore if the version is different.
    if (deserialized.version !== version) return;
    const {
      state: { value },
    } = deserialized;
    // The format of the value is invalid. Maybe it was set by another version
    // of the app. Ignore it or it will cause an infinite loop.
    if (JSON.stringify({ state: { value }, version }) !== event.newValue)
      return;

    debouncedSetValue(value);
  }

  window.addEventListener("storage", eventHandler);
  /**
   * Must be called when the store is no longer needed.
   */
  function destroy() {
    window.removeEventListener("storage", eventHandler);
  }

  return {
    useStore,
    destroy,
  };
}

/**
 * We place the userId store here to avoid circular imports.
 */
const { useStore: useLastUserEmailStore } = createZustandStorageStore(
  LOCAL_STORAGE_LAST_USER_EMAIL,
  string(),
  {
    forUser: false,
  },
);

export { createZustandStorageStore, useLastUserEmailStore };
