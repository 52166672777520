import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { defineStyles } from "@/shared/emotion";

const TypeNullDisplay = (() => {
  const styles = defineStyles({
    typeNullDisplay: css({
      color: theme.colors.neutral006,
    }),
  });
  /**
   * Display for the assignee type of `null`.
   */
  const TypeNullDisplay: FC = () => {
    const { t } = useTranslation();
    return (
      <div css={styles.typeNullDisplay}>
        {t("assignment.assignee.type.null.display")}
      </div>
    );
  };
  return TypeNullDisplay;
})();

namespace AutoAssignmentRuleAssigneeTypeLabel {
  export interface Props {
    type: CantataTypes["AutoAssignmentRule"]["assignee"]["type"];
  }
}

const AutoAssignmentRuleAssigneeTypeLabel: FC<AutoAssignmentRuleAssigneeTypeLabel.Props> =
  function AutoAssignmentRuleAssigneeTypeLabel({ type }) {
    if (type === "an-agent") return <Trans i18nKey="assignment.anAgent" />;

    if (type === "by-queue") return <Trans i18nKey="assignment.byQueue" />;

    if (type === "a-team") return <Trans i18nKey="assignment.aTeam" />;

    if (type === null) return <TypeNullDisplay />;

    const shouldBeNever: never = type;
    return shouldBeNever;
  };

export { AutoAssignmentRuleAssigneeTypeLabel };
