import { QrcodeOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import type { CantataTypes } from "@/cantata/types";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { TeamAssignmentQrcodeModal } from "@/resources/assignmentQrcode/TeamAssignmentQrcodeModal";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  assignment: css({
    color: theme.colors.neutral005,
  }),
});

const Assignment: FC<{ teamId: CantataTypes["Team"]["id"] }> = ({ teamId }) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [open, toggle] = useSwitch(false);
  const mergeFormDisabled = useMergeFormDisabled();
  const disabled = useMemo(
    () => mergeFormDisabled(open),
    [mergeFormDisabled, open],
  );
  return (
    <>
      <NarrowIconButton
        css={styles.assignment}
        size="16px"
        onClick={toggle.on}
        icon={<QrcodeOutlined />}
        disabled={disabled}
      />
      {open && (
        <TeamAssignmentQrcodeModal
          orgId={orgId}
          teamId={teamId}
          onCancel={toggle.off}
        />
      )}
    </>
  );
};

export { Assignment };
