import useChange from "@react-hook/change";
import { breakpointApi } from "@zeffiroso/theme";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import { pick } from "lodash-es";
import { type FC, type ReactNode, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { mergeRefs } from "react-merge-refs";
import { useLocation } from "react-router-dom";

import { PageErrorBoundary } from "@/internal/PageErrorBoundary";
import { MainLayout } from "@/layout/MainLayout";
import type { OuterProps } from "@/layout/MainLayout/Outer/types";
import { LayoutContext } from "@/layout/SideMenuLayout/Outer/Layout/Context";
import { LayoutLteMobile } from "@/layout/SideMenuLayout/Outer/Layout/LteMobile";

type InnerProps = Omit<OuterProps, "sideMenu">;

const InnerGteTabletLandscape: FC<InnerProps> = (props) => {
  const layoutContextValue = LayoutContext.useContextValue();
  return (
    <MainLayout.Outer
      sideMenu={{
        expanded: layoutContextValue.gteTabletLandscape.sidebar.expanded,
        expand: layoutContextValue.gteTabletLandscape.sidebar.toggle.on,
      }}
      {...props}
    />
  );
};

const InnerLteMobile: FC<InnerProps> = (props) => {
  const layoutState = LayoutLteMobile.utils.useStore((state) =>
    pick(state, ["panelExpanded"]),
  );
  return (
    <MainLayout.Outer
      sideMenu={{
        expanded: layoutState.panelExpanded,
        expand: LayoutLteMobile.utils.panel.expand,
      }}
      {...props}
    />
  );
};

const Inner: FC<InnerProps> = (props) => {
  const isGteTabletLandscape = breakpointApi.useGte("tabletLandscape");
  const Component = useMemo(
    () => (isGteTabletLandscape ? InnerGteTabletLandscape : InnerLteMobile),
    [isGteTabletLandscape],
  );
  return <Component {...props} />;
};

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const portal = usePortal();
  const location = useLocation();
  const localInnerRef = useRef<HTMLDivElement | null>(null);
  useChange(location.pathname, function scrollToTop() {
    if (!localInnerRef.current) return;
    localInnerRef.current.scrollTop = 0;
  });
  const mergedRef = mergeRefs([portal.setOuterEl, localInnerRef]);
  return (
    <>
      <Inner mainRef={mergedRef} />
      {createPortal(
        <PageErrorBoundary>{children}</PageErrorBoundary>,
        portal.innerEl,
      )}
    </>
  );
};

export { Layout };
