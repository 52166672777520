import { define } from "@chatbotgang/etude/util/define";
import { z } from "zod";

const eventsSchema = define<Record<string, z.ZodType>>()({
  testNotification: z.object({
    title: z.string(),
    body: z.string().optional(),
    icon: z.string().optional(),
    silent: z.boolean().optional(),
  }),
  clearBadge: z.void(),
  requestBadgeCount: z.void(),
});

export { eventsSchema };
