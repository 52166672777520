import { dateToStringDeeply } from "@chatbotgang/etude/transform/dateToStringDeeply";
import { define } from "@chatbotgang/etude/util/define";
import {
  USERFLOW_ATTRIBUTE_PREFIX,
  USERFLOW_USER_ID_PREFIX,
} from "@zeffiroso/env";
import { mapKeys, pick } from "lodash-es";
import type { FC } from "react";
import { useEffect } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { userflow } from "@/internal/userflow";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { rootQueriesContext } from "@/queriesContext/rootQueriesContext";
import { UsersCount } from "@/resources/organization/UsersCount";
import { logError } from "@/shared/application/logger/sentry";

const BindUserflow: FC = (function declareBindUserflow() {
  type WithPrefix<T extends string, P extends string> = `${P}_${T}`;
  type InferWithPrefix<T extends string> =
    T extends WithPrefix<infer U, string> ? U : never;
  function addPrefixToAttributes<T extends object, P extends string>(
    attributes: T,
    prefix: P,
  ): {
    [K in WithPrefix<keyof T & string, P>]: T[keyof T & InferWithPrefix<K>];
  } {
    return mapKeys(attributes, (_value, key) => [prefix, key].join("_")) as any;
  }
  const BindUserflow: FC = function BindUserflow() {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const rootQueriesData = rootQueriesContext.useData();
    const orgs = rootQueriesData.organizations;
    const orgQueriesData = orgQueriesContext.useData();
    const orgPlanQuery = cantata.orgPlan.useGetById({
      params: { orgId },
    });
    const usersCountQuery = UsersCount.useQuery({
      orgId,
      excludeInternal: true,
    });
    const me = orgQueriesData.me;
    useEffect(() => {
      if (!userflow) return;
      if (!usersCountQuery.isSuccess) return;
      if (!orgPlanQuery.isSuccess) return;
      const org = orgs.find((org) => org.id === orgId);
      if (!org) return;
      const propertiesToPickMe = define<Array<keyof typeof me>>()([
        "email",
        "name",
        "chatName",
        "mobile",
        "languageCode",
        "roleType",
        "createdAt",
      ]);
      const pickedMe = dateToStringDeeply(
        pick<typeof me, (typeof propertiesToPickMe)[number]>(
          me,
          propertiesToPickMe,
        ),
      );
      const rawAttributes = {
        orgName: org.name,
        orgEnableTwoFactor: org.enableTwoFactor,
        planType: orgPlanQuery.data.type,
        planSeats: orgPlanQuery.data.seatNum,
        seats: usersCountQuery.data,
        ...pickedMe,
      };
      const attributes = {
        // userflow pre-defined attributes
        name: rawAttributes.name,
        email: rawAttributes.email,
        signed_up_at: rawAttributes.createdAt,
        ...addPrefixToAttributes(rawAttributes, USERFLOW_ATTRIBUTE_PREFIX),
      };
      userflow
        .identify([USERFLOW_USER_ID_PREFIX, me.id].join("_"), attributes)
        .catch((e) => {
          const userflowError = new Error("Failed to identify userflow.");
          userflowError.cause = e;
          logError(userflowError);
        });
    }, [
      me,
      orgId,
      orgPlanQuery.data?.seatNum,
      orgPlanQuery.data?.type,
      orgPlanQuery.isSuccess,
      orgs,
      usersCountQuery.data,
      usersCountQuery.isSuccess,
    ]);
    return null;
  };
  return BindUserflow;
})();

export { BindUserflow };
