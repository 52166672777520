import { deburr, flow } from "lodash-es";

/**
 * Normalize the search string.
 */
function searchNormalize(search: string) {
  return flow(
    () => search,
    deburr,
    (search) => search.replaceAll(/[^a-zA-Z]+/g, ""),
    (search) => search.toLowerCase(),
  )();
}

export { searchNormalize };
