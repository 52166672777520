import type { CompareFn } from "@zeffiroso/utils/vanilla/defineCompareFn";
import { isEqual } from "date-fns";
import { useCallback } from "react";

import type { CantataTypes } from "@/cantata/types";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

function useDefaultOrgSorter() {
  const localeCompare = useLocaleCompare();
  const defaultOrgSorter = useCallback<
    CompareFn<Pick<CantataTypes["Org"], "name" | "createdAt" | "id">>
  >(
    (a, b) => {
      if (a.name !== b.name) return localeCompare(a.name, b.name);
      if (!isEqual(a.createdAt, b.createdAt)) {
        return a.createdAt < b.createdAt ? -1 : 1;
      }
      return a.id - b.id;
    },
    [localeCompare],
  );
  return defaultOrgSorter;
}

export { useDefaultOrgSorter };
