import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useChange from "@react-hook/change";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";
import { useState } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { MotifIcon } from "@/components/MotifIcon";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.neutral007,
    gap: 4,
  }),
  icon: css({
    fontSize: "1rem",
  }),
  audio: css({
    display: "none",
  }),
});

/**
 * display a preview audio with a description left to it
 */
const MessageAudioPreview = forwardRef<
  ElementRef<"audio">,
  ComponentProps<"div"> & {
    src: CantataTypes["Message"]["originUrl"];
    audioProps?: Omit<ComponentProps<"audio">, "src">;
  }
>(function MessageAudioPreview({ src, audioProps, ...props }, ref) {
  const [error, setError] = useState<boolean>(false);
  const failedToLoadAudio = useHandler<ComponentProps<"audio">["onError"]>(
    function failedToLoadAudio(...args) {
      setError(true);
      audioProps?.onError?.(...args);
    },
  );

  useChange(src, function resetError() {
    setError(false);
  });

  if (error || !src)
    return (
      <div css={styles.root} {...props}>
        <Trans i18nKey="resource.message.media.error.notAvailable.content" />
      </div>
    );

  return (
    <div css={styles.root} {...props}>
      <Trans i18nKey="chat.replyMessage.audio.desc" />
      <span css={styles.icon}>
        <MotifIcon un-i-motif="video" />
      </span>
      <audio
        {...audioProps}
        ref={ref}
        src={src}
        css={styles.audio}
        onError={failedToLoadAudio}
      />
    </div>
  );
});

assignDisplayName(MessageAudioPreview, "MessageAudioPreview");

export { MessageAudioPreview };
