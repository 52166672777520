import { css } from "@emotion/react";
import type { FC } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { Badge } from "@/components/Badge";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    gap: 4,
    alignItems: "center",
  }),
});

const GroupMemberTitle: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const groupId = memberIdUtils.useGet();
  const query = cantata.chatGroup.useListAll({
    params: {
      orgId,
      groupId,
    },
  });

  return (
    <div css={styles.root}>
      <Trans i18nKey="chat.groupProfile.about" />
      {!query.isSuccess ? null : (
        <Badge $variant="plain">{query.data.count}</Badge>
      )}
    </div>
  );
};

export { GroupMemberTitle };
