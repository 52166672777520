import { define } from "@chatbotgang/etude/util/define";
import { Suspense } from "react";
import type { RouteObject } from "react-router-dom";
import { Outlet } from "react-router-dom";

import { BarLoading } from "@/components/Loading/BarLoading";
import { PageErrorBoundary } from "@/internal/PageErrorBoundary";
import { AnonymousOnly } from "@/router/components/AnonymousOnly";
import { PermissionProtected } from "@/router/components/PermissionProtected";
import { Protected } from "@/router/components/Protected";
import * as commonUtils from "@/router/utils";
import { Activate, Invite, Login } from "@/routes/Auth";
import { Chat } from "@/routes/Chat";
import { insightsObjectRoute } from "@/routes/Insights";
import { QuickTemplate } from "@/routes/QuickTemplate";
import { settingsRouteObject } from "@/routes/Settings";
import { ReactRouterEffect } from "@/shared/hooks/useVanillaLocation";

/**
 * Define all routes in the application.
 */
const routes = define<Array<RouteObject>>()([
  {
    path: "/",
    element: (
      <>
        <Outlet />
        <ReactRouterEffect />
        <commonUtils.RouterBlocker />
      </>
    ),
    children: [
      {
        path: "/login",
        element: (
          <AnonymousOnly>
            <Login />
          </AnonymousOnly>
        ),
      },
      {
        /**
         * Single Sign-On (SSO) login route.
         */
        path: "/login/sso/:domain",
        element: (
          <AnonymousOnly>
            <Login />
          </AnonymousOnly>
        ),
      },
      {
        path: "/invite",
        element: <Invite />,
      },
      {
        path: "/activate",
        element: <Activate />,
      },
      {
        path: "/",
        element: (
          <Protected>
            <PageErrorBoundary>
              <Suspense fallback={<BarLoading />}>
                <PermissionProtected>
                  <Outlet />
                </PermissionProtected>
              </Suspense>
            </PageErrorBoundary>
          </Protected>
        ),
        children: [
          {
            path: "/chat",
            element: <Chat />,
          },
          {
            path: "settings",
            ...settingsRouteObject,
          },
          {
            path: "/quick-templates",
            element: <QuickTemplate />,
          },
          {
            path: "/insights",
            ...insightsObjectRoute,
          },
          {
            path: "*?",
            element: <commonUtils.Navigate replace to="/chat" />,
          },
        ],
      },
    ],
  },
]);

export { routes };
