import { MemberSchema } from "@zeffiroso/cantata/models";
import { Iso8601HighResDateSchema } from "@zeffiroso/utils/zod/Iso8601HighResDateSchema";
import { z } from "zod";

export const MemberListUpdated = z.object({
  type: z.literal("member-list-updated"),
  content: z.object({
    idempotencyKey: z.string(),
    member: MemberSchema,
  }),
  triggerTime: Iso8601HighResDateSchema,
});
