import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import useSwitch from "@react-hook/switch";
import { omit } from "lodash-es";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type ReactNode,
  useMemo,
} from "react";

import { Trans } from "@/app/i18n/Trans";
import { Button } from "@/components/Button";
import { MotifIcon } from "@/components/MotifIcon";
import { defineStyles } from "@/shared/emotion";

const defaultComponent = Button;

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface ShowMoreButtonOwnProps {
  /**
   * If `true`, the component is expanded.
   */
  expanded?: boolean;
  /**
   * The callback fired when the state is changed.
   */
  onChange?: (expanded: boolean) => void;
  /**
   * The expand type of the component.
   */
  expandType?: "showMore" | "showAll";
  /**
   * The tailing icon of the component.
   */
  icon?: ReactNode;
  /**
   * The component used for the root node.
   */
  component?: ElementType;
  /**
   * The content of the component.
   */
  children?: ReactNode;
}

interface ShowMoreButtonTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & ShowMoreButtonOwnProps;
  defaultComponent: RootComponent;
}

type ShowMoreButtonProps<
  RootComponent extends ElementType = ShowMoreButtonTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = ShowMoreButtonOwnProps &
  OverrideProps<
    ShowMoreButtonTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: css({
    paddingInline: 0,
  }),
  content: css({
    display: "flex",
    alignItems: "center",
    gap: 9,
    /* arrow size */
    span: {
      fontSize: 8,
    },
  }),
  arrowIcon: css({
    transition: "rotate 0.3s ease-in-out",
  }),
  expandedArrowIcon: css({
    rotate: "180deg",
  }),
});

/**
 * A button that toggles the collapse state of a component.
 */
const ShowMoreButton: OverridableComponent<ShowMoreButtonTypeMap> = forwardRef(
  function ShowMoreButton(
    {
      expanded,
      expandType = "showMore",
      component: Component = defaultComponent,
      type = "link",
      onChange,
      ...props
    }: ShowMoreButtonProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const [internalExpanded, toggle] = useSwitch(false, expanded, onChange);
    const cssIcon = useMemo(
      () =>
        css([styles.arrowIcon, internalExpanded && styles.expandedArrowIcon]),
      [internalExpanded],
    );
    const restProps = useMemo(() => omit(props, ["icon"]), [props]);
    return (
      <Component
        css={styles.root}
        type={type}
        {...restProps}
        onClick={internalExpanded ? toggle.off : toggle.on}
        ref={ref}
      >
        <div css={styles.content}>
          {"children" in props ? (
            props.children
          ) : internalExpanded ? (
            <Trans i18nKey="chat.memberProfile.toggle.closedLabel" />
          ) : expandType === "showAll" ? (
            <Trans i18nKey="component.button.showMoreButton.showAll.label" />
          ) : (
            <Trans i18nKey="chat.memberProfile.toggle.openedLabel" />
          )}
          {"icon" in props ? (
            props.icon
          ) : (
            <span css={cssIcon}>
              <MotifIcon un-i-motif="arrow_down" />
            </span>
          )}
        </div>
      </Component>
    );
  },
) as OverridableComponent<ShowMoreButtonTypeMap>;

assignDisplayName(ShowMoreButton, "ShowMoreButton");

export { defaultComponent, ShowMoreButton };

export type {
  ShowMoreButtonOwnProps,
  ShowMoreButtonProps,
  ShowMoreButtonTypeMap,
};
