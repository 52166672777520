import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ElementType, ForwardedRef, ReactNode } from "react";

import { Trans } from "@/app/i18n/Trans";
import { MotifIcon } from "@/components/MotifIcon";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface NoDataOwnProps {
  /**
   * The component used for the root node.
   */
  component?: ElementType;
  /**
   * The content of the component.
   */
  children?: ReactNode;
  /**
   * Whether to display the parent container in full height.
   */
  full?: boolean;
}

interface NoDataTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & NoDataOwnProps;
  defaultComponent: RootComponent;
}

type NoDataProps<
  RootComponent extends ElementType = NoDataTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = NoDataOwnProps &
  OverrideProps<
    NoDataTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    color: theme.colors.neutral007,
    alignSelf: "stretch",
    justifySelf: "stretch",
    width: "100%",
  }),
  icon: css({
    fontSize: "2rem",
  }),
  message: css({
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.4285,
  }),
});

/**
 * Component for displaying an empty state.
 */
const NoData: OverridableComponent<NoDataTypeMap> = forwardRef(function Empty(
  {
    component: Component = defaultComponent,
    children = <Trans i18nKey="common.noData" />,
    ...props
  }: NoDataProps,
  ref: ForwardedRef<ElementRef<typeof Component>>,
) {
  return (
    <Component
      css={css([props.full && cssFlexInheritAndFill, styles.root])}
      {...props}
      ref={ref}
    >
      <MotifIcon un-i-motif="menu_list" css={styles.icon} />
      <div css={styles.message}>{children}</div>
    </Component>
  );
}) as OverridableComponent<NoDataTypeMap>;

export { defaultComponent, NoData };

export type { NoDataOwnProps, NoDataProps, NoDataTypeMap };
