import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ElementType, ForwardedRef, ReactNode } from "react";

import { Trans } from "@/app/i18n/Trans";
import { defineStyles } from "@/shared/emotion";

const defaultComponent = "em";

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface UnnamedOwnProps {
  /**
   * The content of the component.
   */
  children?: ReactNode;
  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface UnnamedTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & UnnamedOwnProps;
  defaultComponent: RootComponent;
}

type UnnamedProps<
  RootComponent extends ElementType = UnnamedTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = UnnamedOwnProps &
  OverrideProps<
    UnnamedTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: css({
    color: theme.colors.neutral005,
    fontStyle: "italic",
  }),
});

/**
 * An overridable component.
 */
const Unnamed: OverridableComponent<UnnamedTypeMap> = forwardRef(
  function Unnamed(
    {
      children,
      component: Component = defaultComponent,
      ...props
    }: UnnamedProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    return (
      <Component css={styles.root} {...props} ref={ref}>
        {children ?? <Trans i18nKey="resource.user.unnamed.label" />}
      </Component>
    );
  },
) as OverridableComponent<UnnamedTypeMap>;

assignDisplayName(Unnamed, "Unnamed");

export { defaultComponent, Unnamed };

export type { UnnamedOwnProps, UnnamedProps, UnnamedTypeMap };
