import { BASE_URL } from "@zeffiroso/env";
import urlJoin from "url-join";

import type { ValidPathString } from "@/router/types";

/**
 * Create a full internal URL from a path.
 *
 * @example
 *
 * ```ts
 * // assuming `BASE_URL` is `/foo`
 * createFullInternalUrl('/bar'); // => `https://localhost/foo/bar`
 * ```
 */
function createFullInternalUrl(url: ValidPathString) {
  return urlJoin(window.location.origin, BASE_URL === "/" ? "" : BASE_URL, url);
}

export { createFullInternalUrl };
