import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { useMessage } from "@/components/message";

function useDeleteRule({
  autoAssignmentRuleId,
}: {
  autoAssignmentRuleId: CantataTypes["AutoAssignmentRule"]["id"];
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { t } = useTranslation();
  const message = useMessage();
  return cantata.autoAssignment.useDeleteById(
    {
      params: {
        orgId,
        autoAssignmentRuleId,
      },
    },
    {
      onSuccess: () => {
        message.success(t("common.deleteSuccessfully"));
      },
    },
  );
}

const useSwitchRulePriority = (): {
  isLoading: boolean;
  switchRulePriority: ({
    rulePriority,
  }: {
    rulePriority: Array<number>;
  }) => void;
} => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { t } = useTranslation();
  const message = useMessage();
  const mutation = cantata.autoAssignment.useRePrioritize(
    {
      params: { orgId },
    },
    {
      onSuccess: () => {
        message.success(t("common.updatedSuccessfully"));
      },
    },
  );

  return {
    isLoading: mutation.isLoading,
    switchRulePriority: ({ rulePriority }) => {
      mutation.mutate({
        rulePrioritization: rulePriority,
      });
    },
  };
};

export { useDeleteRule, useSwitchRulePriority };
