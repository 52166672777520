import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createContext, type FC, useContext, useMemo, useState } from "react";
import { create } from "zustand";

import type { CantataTypes } from "@/cantata/types";

type Value = CantataTypes["Member"]["id"];
const nonActiveMemberId: Value = Number.NaN;

function isNonActiveMemberId(memberId: Value) {
  return Object.is(memberId, nonActiveMemberId);
}

function setupMemberIdUtils() {
  const useStore = create<Value>(() => nonActiveMemberId);
  function useMemberId(): Value {
    const value = useStore();
    return value;
  }
  function setMemberId(memberId: Value) {
    useStore.setState(memberId);
  }
  function clear() {
    useStore.setState(nonActiveMemberId);
  }
  return { useMemberId, setMemberId, clear, useStore };
}

/**
 * A stack of member ID utilities. The last item is the innermost, and the first item is the outermost.
 *
 * If `null`, it indicates there is no provider.
 */
const MemberIdUtilsStackContext = createContext<null | Array<
  ReturnType<typeof setupMemberIdUtils>
>>(null);

function useMemberIdUtilsStack() {
  const memberIdUtilsStack = useContext(MemberIdUtilsStackContext);
  return memberIdUtilsStack;
}

function useStrictMemberIdUtilsStack() {
  const memberIdUtilsStack = useMemberIdUtilsStack();
  if (memberIdUtilsStack === null) {
    throw new Error(
      "`memberIdUtils.useStrictStack` must be used inside `memberIdUtils.Provider`",
    );
  }
  return memberIdUtilsStack;
}

function useInnermostMemberIdUtils() {
  const memberIdUtilsStack = useStrictMemberIdUtilsStack();
  const innermost = memberIdUtilsStack[memberIdUtilsStack.length - 1];
  if (!innermost) {
    throw new Error(
      "`memberIdUtils.useInnermost` must be used inside `memberIdUtils.Provider`",
    );
  }
  return memberIdUtilsStack[memberIdUtilsStack.length - 1];
}

function useOutermostMemberIdUtils() {
  const memberIdUtilsStack = useStrictMemberIdUtilsStack();
  const outermost = memberIdUtilsStack[0];
  if (!outermost) {
    throw new Error(
      "`memberIdUtils.useOutermost` must be used inside `memberIdUtils.Provider`",
    );
  }
  return outermost;
}

function useMemberId() {
  const memberIdUtils = useInnermostMemberIdUtils();
  return memberIdUtils.useMemberId();
}

function useClearMemberId() {
  const memberIdUtils = useInnermostMemberIdUtils();
  return memberIdUtils.clear;
}

function useSetMemberId() {
  const memberIdUtils = useInnermostMemberIdUtils();
  return memberIdUtils.setMemberId;
}

/**
 * Get the member ID in the outermost provider.
 */
function useGetOutermostMemberId() {
  const memberIdUtils = useOutermostMemberIdUtils();
  return memberIdUtils.useMemberId();
}

/**
 * Clear the member ID in the outermost provider.
 */
function useClearOutermostMemberId() {
  const memberIdUtils = useOutermostMemberIdUtils();
  return memberIdUtils.clear;
}

/**
 * Set the member ID in the outermost provider.
 */
function useSetOutermostMemberId() {
  const memberIdUtils = useOutermostMemberIdUtils();
  return memberIdUtils.setMemberId;
}

/**
 * A provider for member ID utilities. Can be nested.
 */
const MemberIdUtilsProvider: FC<
  Omit<ComponentProps<typeof MemberIdUtilsStackContext.Provider>, "value">
> = (props) => {
  const prevStack = useContext(MemberIdUtilsStackContext);
  const [memberIdUtils] = useState(setupMemberIdUtils);
  const currentStack: Array<ReturnType<typeof setupMemberIdUtils>> = useMemo(
    () => (!prevStack ? [memberIdUtils] : [...prevStack, memberIdUtils]),
    [prevStack, memberIdUtils],
  );
  return <MemberIdUtilsStackContext.Provider {...props} value={currentStack} />;
};

const memberIdUtils = {
  Provider: MemberIdUtilsProvider,
  useGet: useMemberId,
  useClear: useClearMemberId,
  useSet: useSetMemberId,
  useGetOutermost: useGetOutermostMemberId,
  useClearOutermost: useClearOutermostMemberId,
  useSetOutermost: useSetOutermostMemberId,
  isNonActiveMemberId,
};

export { memberIdUtils };
