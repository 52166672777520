import { CommentOutlined, StopOutlined } from "@ant-design/icons";
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { MemberProcessingStateSchema } from "@zeffiroso/cantata/models";
import { theme } from "@zeffiroso/theme";
import { FixPortalVirtualizerScroll } from "@zeffiroso/utils/react-lib/usePortal";
import { Tabs } from "antd";
import type { ComponentProps, ElementRef } from "react";
import { useCallback, useMemo, useState } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { BarLoading } from "@/components/Loading/BarLoading";
import type { TabItem, TabsProps } from "@/components/Tabs";
import { Layout } from "@/routes/Chat/ui/Layout";
import { SmLayoutTopBar } from "@/routes/Chat/ui/Layout/SmLayoutTopBar";
import { bulkActionMutations } from "@/routes/Chat/ui/MembersPanel/bulkActionMutations";
import { ChannelsQueryProvider } from "@/routes/Chat/ui/MembersPanel/controllers/channelsQueryController";
import {
  MembersControllerProvider,
  useMembersController,
} from "@/routes/Chat/ui/MembersPanel/controllers/membersController";
import {
  MembersQueryControllerProvider,
  QueryMembers,
  useMembersQueryController,
} from "@/routes/Chat/ui/MembersPanel/controllers/membersQueryController";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { SearchControllerProvider } from "@/routes/Chat/ui/MembersPanel/controllers/searchController/Provider";
import {
  SelectionControllerProvider,
  useSelectionController,
} from "@/routes/Chat/ui/MembersPanel/controllers/selectionController";
import { InfoBar } from "@/routes/Chat/ui/MembersPanel/InfoBar";
import { MemberItem } from "@/routes/Chat/ui/MembersPanel/MemberItem";
import { SearchHolder } from "@/routes/Chat/ui/MembersPanel/SearchHolders";
import { SearchPanel } from "@/routes/Chat/ui/MembersPanel/SearchPanel";
import { UnreadBadge } from "@/routes/Chat/ui/MembersPanel/Unread";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";

const BlockTabLabel = memo(function BlockTabLabel() {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <StopOutlined
        css={css`
          .ant-tabs-dropdown & {
            display: none;
          }
        `}
      />
      <span
        css={css`
          .ant-tabs-tab & {
            display: none;
          }
        `}
      >
        <Trans i18nKey="chat.spam" />
      </span>
    </div>
  );
});

const VirtualizerScrollOuter = memo(
  forwardRef<ElementRef<"div">, ComponentProps<"div">>(
    function VirtualizerScrollOuter(props, ref) {
      return (
        <div
          css={css`
            overflow: hidden auto;
            width: 100%;
            height: 100%;
            flex: 1 1 auto;
          `}
          ref={ref}
          {...props}
        />
      );
    },
  ),
);

const EmptyMembersList = memo(function EmptyMembersList() {
  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        color: ${theme.colors.neutral005};
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        .anticon {
          margin-bottom: 16px;
          font-size: 36px;
        }
      `}
    >
      <CommentOutlined />
      <Trans i18nKey="chat.memberPanel.noResult" />
    </div>
  );
});

const MembersListPanel = memo(function MembersListPanel() {
  const [parentEl, setParentEl] = useState<HTMLDivElement | null>(null);
  const isSearching = searchController.useStore(
    searchController.selectors.isSearching,
  );
  const membersController = useMembersController();
  const members = membersController.useMembers();
  const selectionController = useSelectionController();
  const selectionMembers = selectionController.useSelectionMembers();
  const isSelecting = selectionController.useIsSelecting();
  const memberQueryController = useMembersQueryController();
  const { hasNextPage, error } = memberQueryController.useStore((state) => ({
    hasNextPage: state.hasNextPage,
    error: state.error,
  }));

  const renderMembers = useMemo(
    () => (isSelecting ? selectionMembers : members),
    [isSelecting, members, selectionMembers],
  );

  const getVirtualizerItemKey = useCallback<
    NonNullable<Parameters<typeof useVirtualizer>[0]["getItemKey"]>
  >(
    function getVirtualizerItemKey(index) {
      if (index === renderMembers.length) return "loadMore";
      const member = renderMembers[index].id;
      if (!member) return `unknown_${random()}`;
      return `member_${renderMembers[index].id}`;
    },
    [renderMembers],
  );

  const getMemberByVirtualizerItemKey = useCallback(
    function getMemberByVirtualizerItemKey(
      key: ReturnType<typeof getVirtualizerItemKey>,
    ) {
      return renderMembers.find((member) => `member_${member.id}` === key);
    },
    [renderMembers],
  );

  const rowVirtualizer = useVirtualizer({
    // + 1 for bottom of the list
    count: renderMembers.length + 1,
    getItemKey: getVirtualizerItemKey,
    getScrollElement: () => parentEl,
    estimateSize: () => MemberItem.height,
  });

  if (!hasNextPage && renderMembers.length === 0)
    return isSearching ? <SearchNotFound /> : <EmptyMembersList />;

  if (error) return <Alert type="error" message={inspectMessage`${error}`} />;

  return (
    <>
      {!parentEl ? null : (
        <FixPortalVirtualizerScroll
          scrollElement={parentEl}
          virtualizer={rowVirtualizer}
        />
      )}
      <VirtualizerScrollOuter ref={setParentEl}>
        <ul
          css={css`
            position: relative;
            display: block;
            width: 100%;
            height: ${rowVirtualizer.getTotalSize()}px;
          `}
        >
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <li
              key={virtualItem.key}
              style={{
                display: "block",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              {virtualItem.index === renderMembers.length ? (
                <div
                  ref={memberQueryController.loadMoreRef}
                  css={css`
                    ${cssFlexInheritAndFill};
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  {hasNextPage ? (
                    <BarLoading />
                  ) : (
                    <div>
                      <span
                        css={css`
                          color: ${theme.colors.neutral007};
                          font-size: 0.875rem;
                        `}
                      >
                        <Trans i18nKey="chat.memberPanel.memberList.eol" />
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                (() => {
                  const member = getMemberByVirtualizerItemKey(virtualItem.key);
                  if (!member) return;
                  return <MemberItem member={member} />;
                })()
              )}
            </li>
          ))}
        </ul>
      </VirtualizerScrollOuter>
    </>
  );
});

/**
 * override the default style of the antd tabs
 */
const cssMemberTabs = css`
  width: 100%;

  &,
  .ant-tabs-tabpane,
  .ant-tabs-content {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .ant-tabs-tabpane {
    ${cssFlexInheritAndFill};
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-tabs-tab .anticon.anticon-stop {
    margin-right: 0;
  }

  .ant-tabs-ink-bar {
    border-radius: 1px;
  }

  .ant-tabs-tab {
    padding: 0.5rem 0.75rem 0.25rem;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0.5rem;
  }

  .ant-tabs-nav {
    margin: 0 0.75rem;
  }
`;

const DefaultMembersList = memo(function DefaultMembersList() {
  const { processingState } = searchController.useStore(
    (state) => state.filter,
  );
  const handleTabChanged = useHandler<TabsProps["onChange"]>(
    function handleTabChanged(key) {
      const processingState = MemberProcessingStateSchema.parse(key);
      searchController.setState((state) => ({
        filter: {
          ...state.filter,
          processingState,
        },
      }));
    },
  );

  const items = useMemo(
    () =>
      [
        {
          key: "new",
          label: (
            <UnreadBadge processingState="new">
              <Trans i18nKey="chat.memberPanel.pipelineBar.new" />
            </UnreadBadge>
          ),
          children: (
            <>
              <InfoBar />
              <MembersListPanel key="new" />
            </>
          ),
        },
        {
          key: "follow-up",
          label: (
            <UnreadBadge processingState="follow-up">
              <Trans i18nKey="chat.memberPanel.pipelineBar.followUp" />
            </UnreadBadge>
          ),
          children: (
            <>
              <InfoBar />
              <MembersListPanel key="follow-up" />
            </>
          ),
        },
        {
          key: "resolved",
          label: <Trans i18nKey="chat.memberPanel.pipelineBar.resolved" />,
          children: (
            <>
              <InfoBar />
              <MembersListPanel key="resolved" />
            </>
          ),
        },
        {
          key: "blocked",
          label: <BlockTabLabel />,
          children: (
            <>
              <InfoBar />
              <MembersListPanel key="blocked" />
            </>
          ),
        },
      ] satisfies (TabItem & {
        key: CantataTypes["MemberProcessingState"];
      })[],
    [],
  );
  return (
    <Tabs
      activeKey={processingState}
      onChange={handleTabChanged}
      destroyInactiveTabPane
      items={items}
      css={cssMemberTabs}
    />
  );
});

const SearchNotFound = memo(function SearchNotFound() {
  return (
    <SearchHolder>
      <div
        css={css`
          margin-bottom: 8px;
          font-weight: 600;
          text-align: center;
        `}
      >
        <Trans i18nKey="common.noResults" />
      </div>
      <div>
        <Trans i18nKey="common.tryAnotherHint" />
      </div>
    </SearchHolder>
  );
});

const SearchMemberList = memo(function SearchMemberList() {
  return (
    <>
      <InfoBar />
      <MembersListPanel />
    </>
  );
});

const membersPanelStyles = defineStyles({
  root: css([
    cssFlexInheritAndFill,
    {
      flexDirection: "column",
      alignItems: "stretch",
    },
  ]),
});

const MembersPanel = memo(function MembersPanel() {
  const isLteSm = Layout.breakpointHooks.useLteSm();
  const isSearching = searchController.useStore(
    searchController.selectors.isSearching,
  );

  return (
    <div css={membersPanelStyles.root}>
      {!isLteSm ? null : <SmLayoutTopBar />}
      <SearchPanel />
      {isSearching ? <SearchMemberList /> : <DefaultMembersList />}
    </div>
  );
});

const WrappedMembersPanel = memo(function WrappedMembersPanel() {
  const isBulkActionMutating = bulkActionMutations.useIsBulkActionMutating();
  return (
    <DisabledContextProvider disabled={isBulkActionMutating}>
      <SearchControllerProvider>
        <ChannelsQueryProvider>
          <MembersControllerProvider>
            <SelectionControllerProvider>
              <MembersQueryControllerProvider>
                <QueryMembers />
                <MembersPanel />
              </MembersQueryControllerProvider>
            </SelectionControllerProvider>
          </MembersControllerProvider>
        </ChannelsQueryProvider>
      </SearchControllerProvider>
    </DisabledContextProvider>
  );
});

export { WrappedMembersPanel as MembersPanel };
