import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import { type ComponentProps, type ElementRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DatePicker } from "@/components/DatePicker";
import {
  ChannelPlatformSelect,
  type ChannelPlatformSelectProps,
} from "@/resources/channel/ChannelPlatformSelect";
import {
  TeamsSelector,
  type TeamsSelectorProps,
} from "@/resources/team/TeamsSelector";
import { usePageInfoUtil } from "@/routes/Insights/Efficiency/pageInfo";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    gap: "12px",
    flexWrap: "wrap",
  }),
  selector: css({
    minWidth: "200px",
  }),
});

type FiltersRef = ElementRef<"div">;
type FiltersProps = ComponentProps<"div">;

const Filters = forwardRef<FiltersRef, FiltersProps>(
  function Filters(props, ref) {
    const { t } = useTranslation();
    const pageInfoUtil = usePageInfoUtil();

    const range = useMemo<
      ComponentProps<typeof DatePicker.YmdRangePicker>["value"]
    >(
      () => [pageInfoUtil.data.startTime, pageInfoUtil.data.endTime],
      [pageInfoUtil.data.endTime, pageInfoUtil.data.startTime],
    );
    const handleRangeChange = useHandler<
      ComponentProps<typeof DatePicker.YmdRangePicker>["onChange"]
    >(function onChange(range) {
      if (!range || !range[0] || !range[1]) {
        pageInfoUtil.update({
          startTime: undefined,
          endTime: undefined,
        });
        return;
      }
      pageInfoUtil.update({
        startTime: range[0],
        endTime: range[1],
      });
    });

    const teamIds = pageInfoUtil.data.teamIds;
    const handleTeamChange = useHandler<TeamsSelectorProps["onChange"]>(
      function handleTeamChange(value) {
        pageInfoUtil.update({
          teamIds: value,
        });
      },
    );

    const handleChange = useHandler<ChannelPlatformSelectProps["onChange"]>(
      function handleChange({ value, filterBy }) {
        if (filterBy === "channel") {
          pageInfoUtil.update({
            filterBy: "channel",
            channelIds: value,
            channelTypes: undefined,
          });
          return;
        }

        pageInfoUtil.update({
          filterBy: "platform",
          channelIds: undefined,
          channelTypes: value,
        });
      },
    );

    const value = define<ChannelPlatformSelectProps["value"]>(
      pageInfoUtil.data.filterBy === "platform"
        ? { filterBy: "platform", value: pageInfoUtil.data.channelTypes }
        : { filterBy: "channel", value: pageInfoUtil.data.channelIds },
    );

    return (
      <div css={styles.root} ref={ref} {...props}>
        <DatePicker.YmdRangePicker
          value={range}
          onChange={handleRangeChange}
          minDate={pageInfoUtil.dateInfo.dateRangePickerMinDate}
          maxDate={pageInfoUtil.dateInfo.dateRangePickerMaxDate}
        />
        <ChannelPlatformSelect value={value} onChange={handleChange} />
        <TeamsSelector
          allowClear
          css={styles.selector}
          value={teamIds}
          onChange={handleTeamChange}
          placeholder={t("dashboard.teamFilter.placeholder")}
        />
      </div>
    );
  },
);

assignDisplayName(Filters, "Filters");

export { Filters };
export type { FiltersProps, FiltersRef };
