import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { Form } from "antd";
import type { ComponentProps, ComponentType, ElementRef } from "react";

import crescendoLabLogoMask from "@/shared/icons/logos/mask/crescendoLabLogoMask.svg";
import { emotionMedia } from "@/shared/utils/style/emotionMedia";
import loginBg from "@/statics/login-bg.jpeg";

const LeftWrapper = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  background-image: url("${loginBg}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: top;
`;

const RightWrapper = styled(
  forwardRef<ElementRef<"div">, ComponentProps<"div">>(
    ({ children, ...props }, ref) => {
      return (
        <div {...props} ref={ref}>
          <div>{children}</div>
        </div>
      );
    },
  ),
)`
  display: flex;
  height: 100%;
  min-height: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  > * {
    position: relative;
    display: flex;
    overflow: overlay;
    width: 100%;
    min-width: 0;
    min-height: 0;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    padding: max(10%, 5rem) 10%;
  }

  ${emotionMedia(
    String.raw,
    "<=mobile",
    (css) => css`
      > * {
        padding: max(10%, 5rem) 20px;
      }
    `,
  )}
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 0;
  flex: 1;
  flex-direction: raw;
  align-items: stretch;
  justify-content: stretch;
  gap: 0;

  > div {
    flex: 1;
    margin: 0 auto;
  }

  ${LeftWrapper} ~ ${RightWrapper} {
    overflow-y: auto;
  }

  ${emotionMedia(
    String.raw,
    "<=mobile",
    (css) => css`
      ${LeftWrapper} {
        display: none;
      }
    `,
  )}
`;

const MainTitle = (() => {
  const logoClassName = `crescendo-lab-logo-${random()}`;
  const MainTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 40px;
    font-size: 28px;
    font-weight: 500;
    gap: 6px;
    line-height: 32px;
    text-align: center;
    ${`.${logoClassName}`} {
      --size: 70px;

      width: var(--size);
      min-width: var(--size);
      max-width: var(--size);
      height: var(--size);
      min-height: var(--size);
      max-height: var(--size);
      background-color: ${theme.colors.primary};
      mask-image: url(${CSS.escape(crescendoLabLogoMask)});
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
    }
  `;
  const Logo = forwardRef<ElementRef<"div">, ComponentProps<"div">>(
    function Logo({ ...props }, ref) {
      return <div {...props} ref={ref} className={logoClassName} />;
    },
  );
  return Object.assign(MainTitle, { Logo });
})();

const StyledForm = styled(Form)`
  .ant-form-item-control-wrapper {
    flex-grow: 1;
  }

  .ant-col {
    flex-basis: 100%;
    text-align: left;
  }

  .ant-form-explain,
  .ant-form-extra {
    margin-top: 3px;
  }

  .ant-form-item-label > label::after {
    content: "";
  }
` as ComponentType<ComponentProps<typeof Form>> as typeof Form;

export { Container, LeftWrapper, MainTitle, RightWrapper, StyledForm };
