import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { memo } from "@chatbotgang/etude/react/memo";
import { ClassNames } from "@emotion/react";

import type { PopoverProps } from "@/components/Popover";
import { Popover } from "@/components/Popover";

type TriggerProps = PopoverProps;

/**
 * Similar to `Popover` but disable the container styles.
 */
const Trigger = memo(function Trigger(props: TriggerProps) {
  return (
    <ClassNames>
      {({ css }) => (
        <Popover
          overlayClassName={css`
            .ant-popover-inner {
              padding: 0;
              box-shadow: none;
            }
          `}
          {...props}
        />
      )}
    </ClassNames>
  );
});

assignDisplayName(Trigger, "Trigger");

export { Trigger };
export type { TriggerProps };
