import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";

import { Form } from "@/components/Form";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import type { FormValues } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/FormProvider";
import {
  BorderLessCopyInput,
  BorderLessInput,
} from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/Input";

const FormItem = styled(Form.Item)`
  height: auto;
  margin-bottom: auto;
`;

export const TextField = memo<{
  fieldName: keyof Omit<FormValues, "birthday">;
  // Make rules always required to prevent forgetting to pass it.
  rules: NonNullable<Parameters<typeof Form.Item>[0]["rules"]>;
  copyable?: boolean;
}>(function TextField({ fieldName, copyable, rules }) {
  const profile = memberQueriesContext.useMember();
  const form = Form.useFormInstance();

  const onBlur = useHandler(function onBlur() {
    form.submit();
  });

  if (copyable) {
    return (
      <FormItem
        name={fieldName}
        getValueProps={(v) => ({ value: v ?? profile[fieldName] ?? "" })}
        rules={rules}
      >
        <BorderLessCopyInput
          readOnly={false}
          onBlur={onBlur}
          placeholder={profile[fieldName] ?? ""}
        />
      </FormItem>
    );
  }

  return (
    <FormItem
      name={fieldName}
      getValueProps={(v) => ({ value: v ?? profile[fieldName] ?? "" })}
      rules={rules}
    >
      <BorderLessInput onBlur={onBlur} placeholder={profile[fieldName] ?? ""} />
    </FormItem>
  );
});
