import { useCallback } from "react";

import type { CantataTypes } from "@/cantata/types";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

type Channel = Pick<CantataTypes["Channel"], "name" | "status">;

function useSortChannels() {
  const localeCompare = useLocaleCompare();
  const sortChannelCompareFn = useCallback(
    function sortChannelCompareFn(a: Channel, b: Channel) {
      if (a.status === "connected" && b.status !== "connected") return -1;
      if (a.status !== "connected" && b.status === "connected") return 1;
      return localeCompare(a.name, b.name);
    },
    [localeCompare],
  );
  const sortChannels = useCallback(
    function sortChannel<Channels extends Array<Channel>>(
      channels: Channels,
    ): Channels {
      return channels.sort(sortChannelCompareFn);
    },
    [sortChannelCompareFn],
  );
  return sortChannels;
}

export { useSortChannels };
