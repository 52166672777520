import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useChange from "@react-hook/change";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";
import { useState } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.neutral007,
    gap: 4,
    img: {
      width: 24,
      height: 24,
      objectFit: "cover",
      borderRadius: 4,
    },
  }),
});

/**
 * display a preview image with a description left to it
 */
const MessageImagePreview = forwardRef<
  ElementRef<"img">,
  ComponentProps<"div"> & {
    src: CantataTypes["Message"]["previewUrl"];
    imgProps?: Omit<ComponentProps<"img">, "src">;
  }
>(function MessageImagePreview({ src, imgProps, ...props }, ref) {
  const [error, setError] = useState<boolean>(false);
  const failedToLoadImage = useHandler<ComponentProps<"img">["onError"]>(
    function failedToLoadImage(...args) {
      setError(true);
      imgProps?.onError?.(...args);
    },
  );

  useChange(src, function resetError() {
    setError(false);
  });

  if (error || !src)
    return (
      <div css={styles.root} {...props}>
        <Trans i18nKey="resource.message.media.error.notAvailable.content" />
      </div>
    );

  return (
    <div css={styles.root} {...props}>
      <Trans i18nKey="chat.replyMessage.image.desc" />
      <img {...imgProps} ref={ref} src={src} onError={failedToLoadImage} />
    </div>
  );
});

assignDisplayName(MessageImagePreview, "MessageImagePreview");

export { MessageImagePreview };
