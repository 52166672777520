import { makeApi } from "@zodios/core";

import { OrgPlanSchema } from "../models";

const api = makeApi([
  {
    alias: "getById",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/plan",
    response: OrgPlanSchema,
  },
]);

export { api };
