type FakeNotificationType = Pick<
  (typeof window)["Notification"],
  "permission" | "requestPermission"
>;

// This code checks if the browser supports notifications. If it does not, it
// assigns the FakeNotification object to the window.Notification property.
if (!("Notification" in window)) {
  const FakeNotification: FakeNotificationType = {
    permission: "denied",
    async requestPermission() {
      return "denied";
    },
  };

  Object.assign(window, {
    Notification: FakeNotification,
  });
}
