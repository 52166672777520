import { GencSchema } from "@zeffiroso/cantata/models";
import type { z } from "zod";

import { Trans } from "@/app/i18n/Trans";
import { fakeT } from "@/shared/g11n/fakeT";

const gencList = GencSchema.options;

const t = fakeT;

const gencOptions = gencList.map((country) => ({
  filterI18nKey: t(`feature.genc.option.${country}.label`),
  label: <Trans i18nKey={`feature.genc.option.${country}.label`} />,
  value: country,
}));

const isValidGenc = (value: unknown): value is z.infer<typeof GencSchema> =>
  GencSchema.safeParse(value).success;

export { gencList, gencOptions, isValidGenc };
