import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { define } from "@chatbotgang/etude/util/define";
import type { UseQueryOptions } from "@tanstack/react-query";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { type FC, type ReactNode, useCallback, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { useFillYmdRangeUtils } from "@/resources/datetime";
import { usePageInfoUtil } from "@/routes/Insights/Conversation/pageInfo";

type Args = Parameters<
  typeof cantata.dashboardConversation.useNewConversationDetail
>;
type ConfigOptions = Args[0];

const queryOptions = {
  suspense: true,
  useErrorBoundary: true,
} satisfies UseQueryOptions;

const useConfigOptions = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const configOptions = useMemo(
    () =>
      define<ConfigOptions>({
        params: { orgId },
        queries: {
          ...pageInfoUtil.computed.commonQueries,
          interval: pageInfoUtil.data.trendInterval,
        },
      }),
    [orgId, pageInfoUtil],
  );
  return configOptions;
};

const useFillData = () => {
  const pageInfoUtil = usePageInfoUtil();
  const fillYmdRangeUtils = useFillYmdRangeUtils();
  const fillYmdRange = useMemo(
    () => fillYmdRangeUtils[pageInfoUtil.data.trendInterval],
    [fillYmdRangeUtils, pageInfoUtil.data.trendInterval],
  );

  const fillData = useCallback(
    (countMetrics: CantataTypes["DashboardMetrics"][]) =>
      fillYmdRange({
        dateKey: "date",
        data: countMetrics,
        fillValue: {
          value: 0,
        },
        start: pageInfoUtil.data.startTime,
        end: pageInfoUtil.data.endTime,
      }).map(function addDateRange(metric) {
        return {
          ...metric,
          dateRange: [
            metric.date,
            pageInfoUtil.data.trendInterval === "daily"
              ? metric.date
              : pageInfoUtil.data.trendInterval === "weekly"
                ? metric.date.addWeeks(1).addDays(-1)
                : pageInfoUtil.data.trendInterval === "monthly"
                  ? metric.date.addMonths(1).addDays(-1)
                  : (() => {
                      pageInfoUtil.data.trendInterval satisfies never;
                      throw new Error(
                        inspectMessage`Unexpected trendInterval: ${pageInfoUtil.data.trendInterval}`,
                      );
                    })(),
          ],
        };
      }),
    [
      fillYmdRange,
      pageInfoUtil.data.endTime,
      pageInfoUtil.data.startTime,
      pageInfoUtil.data.trendInterval,
    ],
  );
  return fillData;
};

const useNewConversationQuery = () => {
  const configOptions = useConfigOptions();
  const fillData = useFillData();
  return cantata.dashboardConversation.useNewConversationDetail(configOptions, {
    ...queryOptions,
    select(state) {
      return {
        ...state,
        countMetrics: fillData(state.countMetrics),
      };
    },
  });
};

const useHandledConversationQuery = () => {
  const configOptions = useConfigOptions();
  const fillData = useFillData();
  return cantata.dashboardConversation.useHandledConversationDetail(
    configOptions,
    {
      ...queryOptions,
      select(state) {
        return {
          ...state,
          countMetrics: fillData(state.countMetrics),
        };
      },
    },
  );
};

const useResolvedConversationQuery = () => {
  const configOptions = useConfigOptions();
  const fillData = useFillData();
  return cantata.dashboardConversation.useResolvedConversationDetail(
    configOptions,
    {
      ...queryOptions,
      select(state) {
        return {
          ...state,
          countMetrics: fillData(state.countMetrics),
        };
      },
    },
  );
};

const useAssignedConversationQuery = () => {
  const configOptions = useConfigOptions();
  const fillData = useFillData();
  return cantata.dashboardConversation.useAssignedConversationDetail(
    configOptions,
    {
      ...queryOptions,
      select(state) {
        return {
          ...state,
          countMetrics: fillData(state.countMetrics),
        };
      },
    },
  );
};

function useQueries() {
  const newConversationQuery = useNewConversationQuery();
  const handledConversationQuery = useHandledConversationQuery();
  const resolvedConversationQuery = useResolvedConversationQuery();

  const ret = useMemo(
    () => ({
      new: newConversationQuery,
      handled: handledConversationQuery,
      resolved: resolvedConversationQuery,
    }),
    [newConversationQuery, handledConversationQuery, resolvedConversationQuery],
  );
  return ret;
}

function useTeamQueries() {
  const assignedConversationQuery = useAssignedConversationQuery();
  const handledConversationQuery = useHandledConversationQuery();
  const resolvedConversationQuery = useResolvedConversationQuery();

  const ret = useMemo(
    () => ({
      /**
       * We use assigned conversation query for team insights.
       * It's because the team insights are based on the assigned conversations.
       *
       * - [Reference](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek?node-id=2322-25579#838055795)
       */
      new: assignedConversationQuery,
      handled: handledConversationQuery,
      resolved: resolvedConversationQuery,
    }),
    [
      assignedConversationQuery,
      handledConversationQuery,
      resolvedConversationQuery,
    ],
  );
  return ret;
}

const QueriesContext = createQueriesContext<ReturnType<typeof useQueries>>();

const DefaultQueriesProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const queries = useQueries();
  return (
    <QueriesContext.Provider queries={queries}>
      {children}
    </QueriesContext.Provider>
  );
};

const TeamQueriesProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const queries = useTeamQueries();
  return (
    <QueriesContext.Provider queries={queries}>
      {children}
    </QueriesContext.Provider>
  );
};

const QueryProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const pageInfoUtil = usePageInfoUtil();
  const Provider = useMemo(() => {
    return pageInfoUtil.data.teamIds.length > 0
      ? TeamQueriesProvider
      : DefaultQueriesProvider;
  }, [pageInfoUtil.data.teamIds]);

  return <Provider>{children}</Provider>;
};

const queriesContext = {
  Provider: QueryProvider,
  useData: QueriesContext.useData,
};

export { queriesContext };
