import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import { fakeT } from "@/shared/g11n/fakeT";

const t = fakeT;

/**
 * Map of channel types to their localized key.
 */
const map = (function define(): Record<
  CantataTypes["Channel"]["type"],
  string
> {
  return {
    line: t("resource.channel.type.options.line.label"),
    fb: t("resource.channel.type.options.fbMessenger.label"),
    ig: t("resource.channel.type.options.ig.label"),
  };
})();

/**
 * Returns a function that returns the localized channel type.
 *
 * @example
 *
 * ```tsx
 * const getLocaleChannelType = useGetLocaleChannelType();
 * const displayChannelType = useMemo(() => getLocaleChannelType(channel.type), [...]);
 *
 * <div>{displayChannelType}</div>
 * ```
 */
function useGetLocaleChannelType() {
  const { t } = useTranslation();
  const getLocaleChannelType = useCallback(
    function getLocaleChannelType(
      type: CantataTypes["Channel"]["type"],
    ): string {
      if (!map[type])
        throw new Error(inspectMessage`Unknown channel type: ${type}`);
      return t(map[type]);
    },
    [t],
  );
  return getLocaleChannelType;
}

export { useGetLocaleChannelType };
