import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { CompareFn } from "@zeffiroso/utils/vanilla/defineCompareFn";
import type { ElementRef } from "react";
import { useMemo } from "react";

import type { CantataTypes } from "@/cantata/types";
import { Select, type SelectProps } from "@/components/Select";
import { rootQueriesContext } from "@/queriesContext/rootQueriesContext";
import { availableOrgFilter } from "@/resources/organization/availableOrgFilter";
import { OrgAvatar } from "@/resources/organization/OrgAvatar";
import { useDefaultOrgSorter } from "@/resources/organization/useDefaultOrgsSorter";
import { cssOneLine, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    gap: 8,
    flexDirection: "column",
    alignItems: "stretch",
  }),
  orgSelector: css({
    width: "100%",
  }),
  orgSelectorOptionLabel: css({
    display: "flex",
    alignItems: "center",
    gap: 8,
  }),
  orgSelectorAvatar: css({
    height: 24,
    width: 24,
  }),
  orgSelectorName: css(cssOneLine),
});

type Value = CantataTypes["Org"]["id"];

type OrgSelectorProps = Omit<SelectProps<Value>, "options"> & {
  filter?: (org: CantataTypes["Org"]) => boolean;
  sort?: CompareFn<CantataTypes["Org"]>;
};

const OrgSelector = forwardRef<
  ElementRef<typeof Select<Value>>,
  OrgSelectorProps
>(function OrgSelector(props, ref) {
  const rootQueriesData = rootQueriesContext.useData();
  const orgs = rootQueriesData.organizations;
  const defaultOrgSorter = useDefaultOrgSorter();
  const {
    filter = availableOrgFilter,
    sort = defaultOrgSorter,
    ...restProps
  } = props;
  const options = useMemo<
    ComponentProps<typeof Select<Value>>["options"]
  >(() => {
    return orgs
      .filter(filter)
      .toSorted(sort)
      .map((org) => ({
        label: (
          <div css={styles.orgSelectorOptionLabel}>
            <OrgAvatar css={styles.orgSelectorAvatar} orgId={org.id} />
            <div css={styles.orgSelectorName}>{org.name}</div>
          </div>
        ),
        value: org.id,
      }));
  }, [filter, orgs, sort]);
  return <Select<Value> {...restProps} options={options} ref={ref} />;
});

export { OrgSelector };
