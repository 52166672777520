import type { ReactNode } from "react";
import { create } from "zustand";

const useOpenRequirePermissionStore = create<ReactNode>()(() => null);

function openRequirePermission(content: ReactNode) {
  useOpenRequirePermissionStore.setState(content);
}

function closeRequirePermission() {
  useOpenRequirePermissionStore.setState(null);
}

export {
  closeRequirePermission,
  openRequirePermission,
  useOpenRequirePermissionStore,
};
