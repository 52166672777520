import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { isUnifiedMemberType } from "@/resources/member/isUnifiedMemberType";
import { CdpTags } from "@/routes/Chat/ui/MemberProfilePanel/TagPanel/CdpTags";
import { Tags } from "@/routes/Chat/ui/MemberProfilePanel/TagPanel/Tags";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: 12,
  }),
  cdh: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  title: css({
    color: theme.colors.neutral009,
    fontSize: "0.75rem",
    fontWeight: 500,
  }),
});

const TagPanel: FC = () => {
  const { t } = useTranslation();
  const member = memberQueriesContext.useMember();
  const usingUnifiedTags = isUnifiedMemberType(member);
  return (
    <div css={styles.root}>
      <Tags />
      {!usingUnifiedTags ? null : (
        <div css={styles.cdh}>
          <div css={styles.title}>{t("chat.tagPanel.cdpTab.title")}</div>
          <CdpTags />
        </div>
      )}
    </div>
  );
};

export { TagPanel };
