import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useChange from "@react-hook/change";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";
import { useState } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.neutral007,
    gap: 4,
    video: {
      width: 24,
      height: 24,
      objectFit: "cover",
      borderRadius: 4,
      pointerEvents: "none",
    },
  }),
});

/**
 * display a preview video with a description left to it
 */
const MessageVideoPreview = forwardRef<
  ElementRef<"video">,
  ComponentProps<"div"> & {
    src: CantataTypes["Message"]["originUrl"];
    videoProps?: Omit<ComponentProps<"video">, "src">;
  }
>(function MessageVideoPreview({ src, videoProps, ...props }, ref) {
  const [error, setError] = useState<boolean>(false);
  const failedToLoadImage = useHandler<ComponentProps<"video">["onError"]>(
    function failedToLoadImage(...args) {
      setError(true);
      videoProps?.onError?.(...args);
    },
  );

  useChange(src, function resetError() {
    setError(false);
  });

  if (error || !src)
    return (
      <div css={styles.root} {...props}>
        <Trans i18nKey="resource.message.media.error.notAvailable.content" />
      </div>
    );

  return (
    <div css={styles.root} {...props}>
      <Trans i18nKey="chat.replyMessage.video.desc" />
      <video
        {...videoProps}
        ref={ref}
        src={src}
        controls={false}
        onError={failedToLoadImage}
      />
    </div>
  );
});

assignDisplayName(MessageVideoPreview, "MessageVideoPreview");

export { MessageVideoPreview };
