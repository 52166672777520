import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
// eslint-disable-next-line no-restricted-imports -- Antd based utility
import defaultLocale from "antd/es/locale/en_US";
// eslint-disable-next-line no-restricted-imports -- Antd based utility
import type { TableLocale } from "antd/es/table/interface";
import classNames from "classnames";
import { type ElementRef, type ReactNode, type Ref, useMemo } from "react";

import { useConfigContext } from "@/components/antd/useConfigContext";

type ExpandIconRef = ElementRef<"button">;

interface ExpandIconProps<RecordType extends object>
  extends Omit<ComponentProps<"button">, "children"> {
  prefixCls?: string;
  locale?: TableLocale;
  onExpand: (record: RecordType, e: React.MouseEvent<HTMLElement>) => void;
  record: RecordType;
  expanded: boolean;
  expandable: boolean;
}

interface ExpandIconType {
  <RecordType extends object>(
    props: ExpandIconProps<RecordType>,
    ref?: Ref<ExpandIconRef>,
  ): ReactNode;
}

/**
 * Learn from [ant-design/components/table/ExpandIcon.tsx](https://github.com/ant-design/ant-design/blob/502dac1/components/table/ExpandIcon.tsx)
 */
const ExpandIcon: ExpandIconType = forwardRef(function ExpandIcon(
  {
    prefixCls: customizePrefixCls,
    locale: customizeLocale,
    onExpand,
    record,
    expanded,
    expandable,
    ...props
  }: ExpandIconProps<any>,
  ref: Ref<ExpandIconRef>,
) {
  const configContext = useConfigContext();
  const { getPrefixCls, locale: contextLocale = defaultLocale } = configContext;
  const prefixCls = getPrefixCls("table", customizePrefixCls);
  const iconPrefix = `${prefixCls}-row-expand-icon`;
  const locale: TableLocale = useMemo(
    () => ({ ...contextLocale.Table, ...customizeLocale }),
    [contextLocale.Table, customizeLocale],
  );
  return (
    <button
      type="button"
      onClick={(e) => {
        onExpand(record, e);
        e.stopPropagation();
      }}
      className={classNames(iconPrefix, {
        [`${iconPrefix}-spaced`]: !expandable,
        [`${iconPrefix}-expanded`]: expandable && expanded,
        [`${iconPrefix}-collapsed`]: expandable && !expanded,
      })}
      aria-label={expanded ? locale.collapse : locale.expand}
      aria-expanded={expanded}
      ref={ref}
      {...props}
    />
  );
});

assignDisplayName(ExpandIcon, "Table.ExpandIcon");

export { ExpandIcon };
export type { ExpandIconProps, ExpandIconRef };
