import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import useChange from "@react-hook/change";
import { theme } from "@zeffiroso/theme";
import { secondsToMilliseconds } from "date-fns";
import Fuse from "fuse.js";
import { debounce, flow, isEqual, merge, omit } from "lodash-es";
import { useCallback, useId, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "@/components/Input";
import { MotifIcon } from "@/components/MotifIcon";
import { defineStyles } from "@/shared/emotion";

import type { Table } from "..";

const seed = random();
const searchFilteredValue = `searchFilteredValue-${seed}` as const;

const styles = defineStyles({
  inputSearch: css({
    maxWidth: 234,
  }),
});

/**
 * Hook to add search functionality to columns using Fuse.js.
 *
 * If you want to use both search and column filters simultaneously, you should
 * always use this before the `useColumnsFilter` hook to ensure the search
 * functionality works correctly.
 *
 * ```tsx
 * const tableSearchOptions = useMemo<Table.UseTableSearchOptions<RecordType>>(
 *  () => ({
 *    search,
 *    dataSource,
 *    columns,
 *    fuseOptions: {
 *      keys: ["firstName", "lastName", "address"],
 *    }
 * }), [columns, dataSource, search]);
 *
 * const { columns } = useTableSearch(tableSearchOptions);
 *
 * return <Table columns={columns} dataSource={dataSource} />;
 * ```
 */
function useTableSearch<
  TRecordType extends object = object,
  TColumns extends
    Table.ColumnsType<TRecordType> = Table.ColumnsType<TRecordType>,
>({
  dataSource,
  columns,
  fuseOptions,
  searchInputProps,
  onFilter,
  ...restOptions
}: Table.UseTableSearchOptions<TRecordType, TColumns>) {
  const reactId = useId();
  const [componentSeed] = useState(random);
  const columnKey = useMemo(
    () => `table-search-${reactId}-${componentSeed}`,
    [componentSeed, reactId],
  );
  const { t } = useTranslation();
  const [localSearch, setLocalSearch] = useState<
    NonNullable<typeof restOptions.search>
  >(restOptions.search ?? "");
  const mergedSearchOption: NonNullable<typeof restOptions.search> =
    restOptions.search ?? localSearch;
  /**
   * Debounced search.
   */
  const [search, setSearch] = useState(mergedSearchOption);
  const updateSearch = useHandler(function updateSearch() {
    setSearch(mergedSearchOption);
  });
  const [debouncedUpdateSearch] = useState(() =>
    debounce(updateSearch, secondsToMilliseconds(0.3)),
  );
  useChange(mergedSearchOption, debouncedUpdateSearch);
  const onChange = useHandler<ComponentProps<typeof Input>["onChange"]>(
    function onChange(e) {
      setLocalSearch(e.target.value);
      restOptions.onSearchChange?.(e.target.value);
    },
  );
  const fuse = useMemo(
    () => new Fuse(dataSource, fuseOptions),
    [dataSource, fuseOptions],
  );
  const searching = useMemo(() => Boolean(search), [search]);
  const fuseResult = useMemo(() => {
    if (!search) {
      return null;
    }
    return fuse.search(search);
  }, [fuse, search]);
  const toSearchColumn = useCallback<
    (
      input: Table.ColumnsType<TRecordType>[number],
    ) => Table.ColumnsType<TRecordType>[number]
  >(function toSearchColumn(column): Table.ColumnsType<TRecordType>[number] {
    return {
      /**
       * All sorting
       * -related properties should be removed to enable sorting
       * by the search results.
       */
      ...omit(column, ["sortOrder", "sorter"]),
      sortDirections: [null],
      showSorterTooltip: false,
      ...(!("children" in column)
        ? null
        : {
            children: column.children.map(toSearchColumn),
          }),
    };
  }, []);
  const resultColumns = useMemo(
    () =>
      flow(
        () => columns,
        (columns) =>
          !searching
            ? columns
            : columns.map<(typeof columns)[number]>(toSearchColumn),
        (columns) =>
          [
            /**
             * Hidden column to filter and sort the search results.
             */
            {
              key: columnKey,
              hidden: true,
              defaultFilteredValue: [searchFilteredValue],
              filters: [
                {
                  text: searchFilteredValue,
                  value: searchFilteredValue,
                },
              ],
              onFilter: (value, record) => {
                if (value !== searchFilteredValue) {
                  return true;
                }
                if (!searching) return true;
                if (!fuseResult) return false;
                return (
                  onFilter?.(record) ??
                  fuseResult.some(({ item }) => isEqual(item, record))
                );
              },
              sorter: (a, b) => {
                const aIndex =
                  fuseResult?.findIndex(({ item }) => isEqual(item, a)) ?? -1;
                const bIndex =
                  fuseResult?.findIndex(({ item }) => isEqual(item, b)) ?? -1;
                if (aIndex === -1 && bIndex === -1) return 0;
                if (aIndex === -1) return 1;
                if (bIndex === -1) return -1;
                return aIndex - bIndex;
              },
              ...(!searching
                ? null
                : {
                    /**
                     * Enable sorting by the search results.
                     */
                    sortOrder: "ascend",
                  }),
            } satisfies Table.ColumnsType<TRecordType>[number],
            ...columns,
          ] satisfies Table.ColumnsType<TRecordType>,
      )(),
    [columnKey, columns, fuseResult, onFilter, searching, toSearchColumn],
  );
  const defaultProps = useMemo<
    Table.UseTableSearchOptions<TRecordType, TColumns>["searchInputProps"]
  >(
    () => ({
      placeholder: t("component.table.search.placeholder.default"),
      suffix: (
        <MotifIcon
          un-i-motif="magnifier"
          css={css({ color: theme.colors.neutral006 })}
        />
      ),
    }),
    [t],
  );
  const mergedCss = useMemo(
    () => css([styles.inputSearch, searchInputProps?.css]),
    [searchInputProps?.css],
  );
  const searchInput = useMemo(() => {
    const mergedProps = merge({}, defaultProps, searchInputProps);
    return (
      <Input
        {...mergedProps}
        css={mergedCss}
        value={mergedSearchOption}
        onChange={onChange}
      />
    );
  }, [defaultProps, searchInputProps, mergedCss, mergedSearchOption, onChange]);
  const result = useMemo(
    () => ({
      searchInput,
      fuseResult,
      columns: resultColumns,
    }),
    [fuseResult, resultColumns, searchInput],
  );
  return result;
}

export { useTableSearch };
