import type { FC } from "react";

import { memberFieldRules } from "@/resources/member/memberValidator";
import { TextField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/TextField";

const NameField: FC = () => {
  return <TextField fieldName="name" rules={memberFieldRules.name} />;
};

export { NameField };
