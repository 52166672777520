import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { ComponentPropsWithRef } from "react";

export type PaperProps = ComponentPropsWithRef<"div">;

export const Paper = styled.div`
  @layer style-component {
    & {
      border-radius: 4px;
      background: ${theme.colors.white};
      box-shadow: 2px 2px 10px rgb(189 189 189 / 25%);
    }
  }
`;
