import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { useDraggableFloatingElementController } from "@zeffiroso/utils/react-lib/useDraggableFloatingElementController";

import { setLastOpenedAt } from "@/internal/zendesk/lastOpenedAtStorage";
import { zendesk } from "@/internal/zendesk/sdk";
import { useLoadScript } from "@/internal/zendesk/useLoadScript";

function useOpenZendesk() {
  const loadScript = useLoadScript();
  const openZendesk = useHandler<
    Parameters<typeof useDraggableFloatingElementController>[0]["onClick"]
  >(async function handleFabOnClick() {
    setLastOpenedAt(new Date());
    await loadScript();
    zendesk.open();
  });
  return openZendesk;
}

export { useOpenZendesk };
