import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import { useActiveElement } from "@zeffiroso/utils/react/useActiveElement";
import { get, pick } from "lodash-es";
import { type FC, type ReactNode, useCallback, useMemo } from "react";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { HotKeys } from "@/components/Kbd/Hotkeys";
import { Tooltip } from "@/components/Tooltip";
import { ga4Event } from "@/lib/ga4";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { OldEditor } from "@/routes/Chat/ui/ChatPanel/Editor/Old/Editor";
import {
  type ExtendedItem,
  useMenuItems,
} from "@/routes/Chat/ui/ChatPanel/Header/menuItems";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  tooltip: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    lineHeight: "normal",
    gap: 8,
  }),
});

const ProcessingStatus: FC = () => {
  const orgQueriesData = orgQueriesContext.useData();
  const me = orgQueriesData.me;
  const member = memberQueriesContext.useMember();
  const menuItems = useMenuItems();
  const activeElement = useActiveElement();
  const menuItemToIconButton = useCallback(
    function menuItemToIconButton(item: ExtendedItem): ReactNode {
      const buttonProps = pick(item, [
        "icon",
        "onClick",
        "disabled",
      ]) as ComponentProps<typeof NarrowIconButton>;
      const hotkey = item["data-hotkey"];
      const tooltipTitle: ComponentProps<typeof Tooltip>["title"] = () => {
        const label = get(item, "label");
        return (
          <div css={styles.tooltip}>
            {!label ? null : <span>{label}</span>}
            {!hotkey || hotkey.length === 0 ? null : (
              <HotKeys hotkey={hotkey} />
            )}
          </div>
        );
      };
      const hotkeyProps: Partial<ComponentProps<typeof NarrowIconButton>> =
        !hotkey || hotkey.length === 0
          ? {}
          : {
              ...(!activeElement ||
              !activeElement.id ||
              !OldEditor.isEditorId(activeElement.id)
                ? null
                : {
                    /**
                     * Allows hotkeys to be triggered only when an input element
                     * with id is focused.
                     *
                     * This is required to make hotkeys work when the text area
                     * is focused.
                     */
                    hotkeyScope: activeElement.id,
                  }),
              hotkey,
              onEnabledHotkeyFire(e) {
                ga4Event("hotkey", {
                  orgId: me.orgId,
                  orgUserId: me.id,
                  feature: "processingStatus",
                  hotkey,
                  path: e.detail.path.join(" "),
                });
              },
            };
      return (
        <Tooltip key={get(item, "key")} title={tooltipTitle}>
          <NarrowIconButton {...buttonProps} {...hotkeyProps} />
        </Tooltip>
      );
    },
    [activeElement, me.id, me.orgId],
  );
  const result = useMemo<ReactNode>(() => {
    if (member.processingState === "new") {
      return [
        menuItems.setProcessingStatus["follow-up"],
        menuItems.setProcessingStatus.resolved,
      ].map(menuItemToIconButton);
    }
    if (member.processingState === "follow-up") {
      return [
        menuItems.setProcessingStatus["follow-up"],
        menuItems.setProcessingStatus.resolved,
      ].map(menuItemToIconButton);
    }
    if (member.processingState === "resolved") {
      return [
        menuItems.setProcessingStatus["follow-up"],
        menuItems.setProcessingStatus.new,
      ].map(menuItemToIconButton);
    }
    if (member.processingState === "blocked") {
      return [menuItemToIconButton(menuItems.setProcessingStatus.new)];
    }
    if (member.processingState === "none") {
      return [
        /**
         * Nothing user can do with the member if processing state is none.
         */
      ];
    }
    member.processingState satisfies never;
    throw new Error(
      inspectMessage`Unexpected processing state: ${member.processingState}`,
    );
  }, [
    member.processingState,
    menuItemToIconButton,
    menuItems.setProcessingStatus,
  ]);
  return result;
};

export { ProcessingStatus };
