import type { FC } from "react";

import type { CantataTypes } from "@/cantata/types";
import { Unnamed } from "@/resources/user/Unnamed";

type UserNameProps = {
  user: Pick<CantataTypes["User"], "name" | "email">;
};

export const UserName: FC<UserNameProps> = (props) => {
  return props.user.name || props.user.email || <Unnamed />;
};
