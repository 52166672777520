import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type {
  LegatoOnEventType,
  LegatoOnEventTypes,
} from "@zeffiroso/legato/on";
import { useContext, useEffect } from "react";

import type { ContextValue } from "@/legato/internal";
import { Context } from "@/legato/internal";

/**
 * To listen to events from the Legato web socket.
 *
 * @param eventType The event type to listen to.
 * @param callback The callback to call when the event is received.
 *
 * @example
 *
 * ```ts
 * useLegatoEvent('ping', (data) => {
 *   console.log(data);
 * });
 * ```
 */
function useLegatoEvent<EventType extends LegatoOnEventType>(
  eventType: EventType,
  callback: (data: LegatoOnEventTypes[EventType]) => void,
): void {
  const { eventEmitter } = useLegato();
  const optimizedCallback = useHandler<typeof callback>(callback);
  useEffect(() => {
    // FIXME: remove the `any` assertion if possible.
    eventEmitter.on(eventType, optimizedCallback as any);
    return function cleanUp() {
      eventEmitter.off(eventType, optimizedCallback as any);
    };
  }, [eventEmitter, eventType, optimizedCallback]);
}

/**
 * The extended web socket client specific to the Legato web socket.
 */
function useLegato(): ContextValue {
  const contextValue = useContext(Context);
  if (!contextValue) throw new Error("Missing context value");
  return contextValue;
}

export { useLegato, useLegatoEvent };
