import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { NavBar } from "@/layout/base/AuthenticatedAppOuter/GteTabletLanscape/NavBar";
import type { AuthenticatedAppOuterProps } from "@/layout/base/AuthenticatedAppOuter/types";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css([
    cssFlexInheritAndFill,
    {
      flexDirection: "row",
      overflow: "hidden",
      alignItems: "stretch",
      justifyContent: "stretch",
    },
  ]),
  main: css([
    cssFlexInheritAndFill,
    {
      "&,&>*": {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflow: "auto",
        backgroundColor: theme.colors.neutral001,
      },
    },
  ]),
});

const AppLayoutGteTabletLanscape: FC<AuthenticatedAppOuterProps> = ({
  mainRef,
}) => {
  return (
    <div css={styles.root}>
      <NavBar />
      <div css={styles.main} ref={mainRef} />
    </div>
  );
};

export { AppLayoutGteTabletLanscape };
