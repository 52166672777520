import { EyeOutlined } from "@ant-design/icons";
import { memo } from "@chatbotgang/etude/react/memo";
import { useMemo } from "react";

import type { CantataTypes } from "@/cantata/types";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { compileToString } from "@/router/utils/compileTo";

export const ViewLinkButton = memo(function ({
  teamId,
}: {
  teamId: CantataTypes["Team"]["id"];
}) {
  const href = useMemo(
    () =>
      compileToString({
        pathname: "/settings/teams/view/:teamId",
        params: {
          teamId,
        },
      }),
    [teamId],
  );
  return (
    <NarrowIconButton
      data-test={`view-team-button-${teamId}`}
      icon={<EyeOutlined />}
      size="middle"
      iconSize="small"
      href={href}
    />
  );
});
