import { css } from "@emotion/react";
import { MotifIcon } from "@zeffiroso/app/src/components/MotifIcon";
import type { FC, ReactNode } from "react";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Tooltip } from "@/components/Tooltip";

const UnifyKeyLabel: FC<{
  unifyKey: CantataTypes["UnificationKeys"];
  children: ReactNode;
}> = ({ unifyKey, children }) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const unifyScopeSettingQuery = cantata.org.useGetUnifyScopeSetting({
    params: {
      orgId,
    },
  });

  const isUnifyKey = useMemo(
    function isUnifyKey() {
      if (!unifyScopeSettingQuery.isSuccess) return false;

      return unifyScopeSettingQuery.data.unifyKeys.includes(unifyKey);
    },
    [
      unifyKey,
      unifyScopeSettingQuery.data?.unifyKeys,
      unifyScopeSettingQuery.isSuccess,
    ],
  );

  return (
    <>
      {children}
      {!isUnifyKey ? null : (
        <Tooltip
          title={
            <div
              css={css({
                display: "flex",
                fontSize: "0.875rem",
                flexDirection: "column",
                gap: "4px",
              })}
            >
              <h4 css={css({ marginBottom: 0 })}>
                <Trans i18nKey="chat.profile.cdp.unifyKey.tooltip.title" />
              </h4>
              <span>
                <Trans i18nKey="chat.profile.cdp.unifyKey.tooltip.desc" />
              </span>
            </div>
          }
        >
          <span css={css({ fontSize: "1rem" })}>
            <MotifIcon un-i-motif="key" />
          </span>
        </Tooltip>
      )}
    </>
  );
};

export { UnifyKeyLabel };
