import { z } from "zod";

/**
 * Spec: [Notion](https://www.notion.so/cresclab/Profile-unification-6f893d68ddcd481fb74b2db7b34e6aca?pvs=4#c2802603c7214104ac19114bb8a65561)
 */
const MergeStateUpdatedSchema = z.object({
  type: z.literal("merge-state-updated"),
  content: z.object({
    idempotencyKey: z.string(),
    isFinished: z.boolean(),
  }),
});

export { MergeStateUpdatedSchema };
