import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import type { FC } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { ExternalLink } from "@/components/ExternalLink";
import { MotifIcon } from "@/components/MotifIcon";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { AgentTable } from "@/routes/Insights/Teams/AgentTable";
import { ExportAgents } from "@/routes/Insights/Teams/ExportAgents";
import { ExportTeams } from "@/routes/Insights/Teams/ExportTeams";
import { Filters } from "@/routes/Insights/Teams/filters";
import { PageInfoProvider } from "@/routes/Insights/Teams/pageInfo";
import { TeamTable } from "@/routes/Insights/Teams/TeamTable";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: 24,
  }),
  titleWrapper: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  title: css({
    fontSize: "1.25rem",
    fontWeight: 500,
    color: theme.colors.neutral009,
  }),
  head: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 24,
  }),
  note: css({
    color: theme.colors.neutral007,
    fontSize: "0.875rem",
    lineHeight: 1.4285,
  }),
  exports: css({
    display: "flex",
    alignItems: "center",
    gap: 12,
  }),
  section: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
});

const TeamPerformanceHead: FC = () => {
  const { t } = useTranslation();
  return (
    <div css={styles.head}>
      <div css={styles.titleWrapper}>
        <div css={styles.title}>
          <Trans i18nKey="dashboard.teams.teamPerformance.title" />
        </div>
        <div css={styles.note}>
          <Trans i18nKey="dashboard.teams.teamPerformance.desc" />
        </div>
      </div>
      <div css={styles.exports}>
        <ExternalLink
          href={t("dashboard.teams.teamPerformance.learnMore.link")}
          prefixIcon={<MotifIcon un-i-motif="guide_book" />}
        >
          <Trans i18nKey="dashboard.teams.teamPerformance.learnMore.label" />
        </ExternalLink>
        <ExportTeams />
      </div>
    </div>
  );
};

const AgentPerformanceHead: FC = () => {
  return (
    <div css={styles.head}>
      <div css={styles.titleWrapper}>
        <div css={styles.title}>
          <Trans i18nKey="dashboard.teams.agentPerformance.title" />
        </div>
        <div css={styles.note}>
          <Trans i18nKey="dashboard.teams.agentPerformance.desc" />
        </div>
      </div>
      <div css={styles.exports}>
        <ExportAgents />
      </div>
    </div>
  );
};

const Teams: FC = () => {
  return (
    <MainLayout.Paper>
      <MainLayout.Header>
        <Trans i18nKey="page.insights.teams.title" />
      </MainLayout.Header>
      <MainLayout.Body>
        <PageInfoProvider>
          <MainLayout.Sticky>
            <Filters />
          </MainLayout.Sticky>
          <MainLayout.Content>
            <div css={styles.root}>
              <div css={styles.section}>
                <TeamPerformanceHead />
                <TeamTable />
              </div>
              <div css={styles.section}>
                <AgentPerformanceHead />
                <AgentTable />
              </div>
            </div>
          </MainLayout.Content>
        </PageInfoProvider>
      </MainLayout.Body>
    </MainLayout.Paper>
  );
};

const WrappedTeams: FC = () => {
  const portal = usePortal();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Outer mainRef={portal.setOuterEl} />
      {createPortal(<Teams />, portal.innerEl)}
    </SideMenuLayout.Layout>
  );
};

export { WrappedTeams as Teams };
