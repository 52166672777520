import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";

type FormValues = {
  webpushNotificationEnabled: boolean;
  webpushNotificationSoundEnabled: boolean;
  emailNotificationEnabled: boolean;
};

const initialValues: FormValues = {
  webpushNotificationEnabled: false,
  webpushNotificationSoundEnabled: false,
  emailNotificationEnabled: false,
};

function useCurrentQuery() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.notificationSetting.useGetSettings({
    params: {
      orgId,
    },
  });
  return query;
}

export type { FormValues };
export { initialValues, useCurrentQuery };
