import { Heatmap as AntHeatmap } from "@ant-design/plots";
import type { BaseStyleProps, ParsedBaseStyleProps } from "@antv/g-lite";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
import { merge } from "lodash-es";
import { type ElementRef, useMemo } from "react";

import { baseDefaultProps } from "@/components/antdPlots/base/baseDefaultProps";
import { defaultScaleColorRange } from "@/components/antdPlots/Heatmap/constants";
import { HeatmapLegend } from "@/components/antdPlots/Heatmap/HeatmapLegend";
import type { FieldProp, Primitive } from "@/components/antdPlots/types/common";
import type {
  PlotComponentProps,
  TypedComponent,
} from "@/components/antdPlots/types/component";
import { defineStyles } from "@/shared/emotion";

type HeatmapType = TypedComponent<typeof AntHeatmap>;
type HeatmapProps<
  T extends object,
  TXFieldProp extends FieldProp<T, Primitive> = FieldProp<T, Primitive>,
  TYFieldProp extends FieldProp<T, Primitive> = FieldProp<T, Primitive>,
  TStyleProps extends BaseStyleProps = BaseStyleProps,
  TParsedStyleProps extends ParsedBaseStyleProps = ParsedBaseStyleProps,
> = OverrideWith<
  ComponentProps<typeof AntHeatmap>,
  PlotComponentProps<
    T,
    TXFieldProp,
    TYFieldProp,
    TStyleProps,
    TParsedStyleProps
  > & {
    /**
     * Reference: <https://ant-design-charts.antgroup.com/en/options/plots/special/heatmap#mark>
     */
    mark?: "point" | "cell" | "heatmap";
    /**
     *
     */
    style?: {
      /**
       * Learn from example:
       *
       * <https://ant-design-charts.antgroup.com/en/examples/statistics/heatmap/#cell-aggregated>
       */
      inset?: number;
    };
  }
>;
type HeatmapRef = ElementRef<HeatmapType>;

/**
 * The styled Heatmap component based on Ant Design's Heatmap.
 *
 * Spec: [Figma](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek/Insights?m=dev&node-id=2040-44302)
 */
const Heatmap: HeatmapType = forwardRef<HeatmapRef, HeatmapProps<object>>(
  function Heatmap(props, ref) {
    const defaultProps = useMemo<Partial<HeatmapProps<object>>>(() => {
      return {
        mark: "cell",
        axis: {
          x: {
            tickLength: 0,
            labelAutoRotate: false,
          },
          y: {
            tickLength: 0,
          },
        },
        style: {
          inset: 1,
        },
        scale: {
          color: {
            range: defaultScaleColorRange,
          },
        },
      };
    }, []);

    const mergedProps = useMemo<HeatmapProps<object>>(
      () => merge({}, baseDefaultProps, defaultProps, props),
      [defaultProps, props],
    );

    return <AntHeatmap ref={ref} {...mergedProps} />;
  },
) as HeatmapType;

/**
 * Reference: [Figma](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek/Insights?node-id=1149-30391)
 */
const height =
  // heatmap chart height
  204;

const wrapperStyles = defineStyles({
  root: css({
    position: "relative",
    maxHeight: height,
    minHeight: height,
    height,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ">[data-chart-source-type]": {
      width: "100%",
      height: "100%",
    },
  }),
});

type WrapperRef = ElementRef<"div">;
type WrapperProps = ComponentProps<"div">;

/**
 * Wrap the Line component with a fixed height. This can be used to prevent the
 * layout shift when the chart is loading.
 */
const Wrapper = forwardRef<WrapperRef, WrapperProps>(
  function Wrapper(props, ref) {
    return <div css={wrapperStyles.root} ref={ref} {...props} />;
  },
);

assignDisplayName(Heatmap, "Heatmap.Wrapper");

const api = Object.assign(Heatmap, {
  defaultScaleColorRange,
  Wrapper,
  Legend: HeatmapLegend,
});

export { defaultScaleColorRange, api as Heatmap };
export type { HeatmapProps, HeatmapRef };
