import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useMutation } from "@tanstack/react-query";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { downloadFile } from "@zeffiroso/utils/vanilla/downloadFile";
import { type FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantataClient } from "@/cantata";
import { Button } from "@/components/Button";
import { MotifIcon } from "@/components/MotifIcon";
import { usePageInfoUtil } from "@/routes/Insights/Teams/pageInfo";

export const ExportTeams: FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const ymdRange = useMemo(
    () => pageInfoUtil.computed.currentRange,
    [pageInfoUtil],
  );
  /**
   * Abort controller.
   *
   * Abort when the component unmounts.
   */
  const useAbortControllerStore = useUseAbortControllerStore();
  const abortController = useAbortControllerStore();
  /**
   * Make the `abort` function to be stable to prevent unnecessary re-renders.
   */
  const abort = useHandler(function abort() {
    abortController.abort();
  });
  useEffect(
    function abortWhenUnmount() {
      return abort;
    },
    [abort],
  );

  const mutation = useMutation({
    mutationFn: async function getExportLink() {
      /**
       * Get the current abort controller signal and pass it to the API call.
       */
      const signal = abortController.signal;
      const data = await cantataClient.dashboardTeams.exportTeamsReportCsv({
        params: {
          orgId,
        },
        queries: pageInfoUtil.computed.commonQueries,
        signal,
      });
      if (signal.aborted) return;
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      downloadFile(blob, `teams_performace_${ymdRange[0]}-${ymdRange[1]}.csv`);
    },
  });
  const handleExport = useHandler(function handleExport() {
    if (mutation.isLoading) return;

    mutation.mutate();
  });
  return (
    <Button
      ghost
      loading={mutation.isLoading}
      disabled={mutation.isLoading}
      type="primary"
      onClick={handleExport}
      icon={<MotifIcon un-i-motif="arrow_export" />}
    >
      {t("dashboard.teams.team.export.label")}
    </Button>
  );
};
