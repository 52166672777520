import type { UseMutationOptions } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import type {
  Method,
  ZodiosBodyByPath,
  ZodiosEndpointDefinition,
  ZodiosEndpointDefinitions,
  ZodiosError,
  ZodiosErrorByPath,
  ZodiosPathsByMethod,
  ZodiosRequestOptionsByPath,
  ZodiosResponseByPath,
} from "@zodios/core";
import type {
  IfEquals,
  ReadonlyDeep,
  RequiredKeys,
  UndefinedIfNever,
} from "@zodios/core/lib/utils.types";
import type { ZodiosHooksInstance } from "@zodios/react";
import type { AxiosError } from "axios";
import { isAxiosError } from "axios";

import { safeInvalidateQuery } from "./useSafeInvalidateQuery";

/**
 * **Start**
 *
 * These types are copied from `@zodios/react/lib/index.d.ts` because they are
 * not exported.
 */

type UnknownIfNever<T> = IfEquals<T, never, unknown, T>;
type Errors<T> = Error | ZodiosError | AxiosError<T>;

type MutationOptions<
  Api extends ZodiosEndpointDefinition[],
  M extends Method,
  Path extends ZodiosPathsByMethod<Api, M>,
> = Omit<
  UseMutationOptions<
    Awaited<ZodiosResponseByPath<Api, M, Path>>,
    Errors<UnknownIfNever<ZodiosErrorByPath<Api, M, Path, number>>>,
    UndefinedIfNever<ZodiosBodyByPath<Api, M, Path>>
  >,
  "mutationFn"
>;

/**
 * @param apiHooks The zodios hooks instance to inject affected apis.
 * @param affectedApiNames All api names to invalidate.
 */
function injectAffectedApis<Api extends ZodiosEndpointDefinitions>(
  apiHooks: ZodiosHooksInstance<Api>,
  affectedApiNames: Array<string>,
) {
  const thisUseMutation = apiHooks.useMutation;

  const newUseMutation: typeof thisUseMutation = function useMutation<
    M extends Method,
    Path extends ZodiosPathsByMethod<Api, M>,
    TConfig extends ZodiosRequestOptionsByPath<Api, M, Path>,
  >(
    method: M,
    path: Path,
    ...[config, mutationOptions]: RequiredKeys<TConfig> extends never
      ? [
          config?: ReadonlyDeep<TConfig>,
          mutationOptions?: MutationOptions<Api, M, Path>,
        ]
      : [
          config: ReadonlyDeep<TConfig>,
          mutationOptions?: MutationOptions<Api, M, Path>,
        ]
  ) {
    const queryClient = useQueryClient();
    // @ts-expect-error -- FIXME: Type '{ toString?: (() => string) | ((() => string) & (() => string)) | (() => string); toLocaleString?: (() => string) | (() => string); }' is not assignable to type 'ReadonlyDeep<TConfig>'.ts(2322)
    const newConfig: ReadonlyDeep<TConfig> = {
      ...config,
    };

    const invalidateAffectedApis = async () => {
      await Promise.all(
        affectedApiNames.map(
          async (api) =>
            await safeInvalidateQuery(queryClient, {
              queryKey: [
                {
                  api,
                },
              ],
            }),
        ),
      );
    };

    const newMutationOptions: MutationOptions<Api, M, Path> = {
      ...mutationOptions,
      onSuccess: async (...args) => {
        await invalidateAffectedApis();
        if (!mutationOptions?.onSuccess) return;
        return await mutationOptions.onSuccess(...args);
      },
      onError: async (...args) => {
        const [error] = args;
        if (
          isAxiosError(error) &&
          error.response &&
          !(error.response.status === 401 || error.response.status >= 500)
        ) {
          await invalidateAffectedApis();
        }
        if (!mutationOptions?.onError) return;
        return await mutationOptions.onError(...args);
      },
    };
    return thisUseMutation.call(
      apiHooks,
      method,
      path,
      // Cspell:disable-next-line
      // @ts-expect-error -- FIXME: Argument of type '[ReadonlyDeep<TConfig>, MutationOptions<Api, M, Path>]' is not assignable to parameter of type '[...RequiredKeys<Simplify<Simplify<Pick<Partial<PickDefined<{ params: IfEquals<{ [K in PathParamNames<ZodiosPathsByMethod<Api, Method>, never>]: MapSchemaParameters<FilterArrayByValue<FilterArrayByValue<Api, { method: Method; path: ZodiosPathsByMethod<...>; }, []>[number]["parameters"], { ...; }, []>, true, {}> exte...'. Source has 2 element(s) but target allows only 1.ts(2345)
      newConfig,
      newMutationOptions,
    );
  };

  apiHooks.useMutation = newUseMutation.bind(apiHooks);
}

export { injectAffectedApis };
