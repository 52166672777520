import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";

import { CopyInput, Input } from "@/components/Input";
import { getCssStr } from "@/shared/utils/style/getCssStr";

const BorderLessInput = styled(Input)`
  ${getCssStr(
    (css) => css`
      --input-color: ${theme.colors.neutral007};

      &.ant-input-affix-wrapper > input.ant-input {
        color: var(--input-color);
      }

      border-color: transparent;
      color: var(--input-color);
      font-size: 12px;
    `,
  )};
`;
const BorderLessCopyInput = styled(CopyInput)`
  ${getCssStr(
    (css) => css`
      --input-color: ${theme.colors.neutral007};

      &.ant-input-affix-wrapper > input.ant-input {
        color: var(--input-color);
      }

      border-color: transparent;
      color: var(--input-color);
      font-size: 12px;
    `,
  )};
`;

export { BorderLessCopyInput, BorderLessInput };
