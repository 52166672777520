import { makeApi } from "@zodios/core";

import { AssignmentGeneralSettingSchema } from "../models";

const api = makeApi([
  {
    alias: "getSettings",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/assignment/general-setting",
    response: AssignmentGeneralSettingSchema,
  },
  {
    alias: "update",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/assignment/general-setting",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AssignmentGeneralSettingSchema,
      },
    ],
    response: AssignmentGeneralSettingSchema,
  },
]);
export { api };
