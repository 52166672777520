import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
  OrgSchema,
  UserDetailSchema,
  UserMeSchema,
  UserOnDutyStatusSchema,
  UserSchema,
  UserUpdateInviteSchema,
} from "../models";

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/users",
    parameters: [
      {
        type: "Query",
        name: "filter",
        schema: z.literal("my-teams").optional(),
      },
    ],
    response: z.object({
      users: z.array(
        UserSchema.extend({
          isInternalUser: z.boolean(),
        }),
      ),
    }),
  },
  {
    alias: "getById",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/users/:userId",
    response: UserDetailSchema,
  },
  {
    alias: "update",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/users/:userId",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UserDetailSchema.pick({
          roleId: true,
          name: true,
          chatName: true,
          mobile: true,
          avatar: true,
          status: true,
          enableSoundNotification: true,
          enablePopupNotification: true,
          languageCode: true,
          externalUserId: true,
        }),
      },
    ],
    response: UserUpdateInviteSchema,
  },
  {
    alias: "deleteById",
    method: "delete",
    path: "api/v1/orgs/:orgId/organization/users/:userId",
    response: z.void(),
  },
  {
    alias: "updateOnDutyStatus",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/users/:userId/on-duty-status",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          onDutyStatus: UserOnDutyStatusSchema,
        }),
      },
    ],
    response: z.object({
      onDutyStatus: UserOnDutyStatusSchema,
    }),
  },
  {
    alias: "getMe",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/me",
    response: UserMeSchema,
  },
  {
    alias: "updateMe",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/me",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UserMeSchema.pick({
          name: true,
          chatName: true,
          mobile: true,
          avatar: true,
          languageCode: true,
          externalUserId: true,
        }),
      },
    ],
    response: UserMeSchema.pick({
      id: true,
      orgId: true,
      roleId: true,
      roleName: true,
      roleType: true,
      permission: true,
      inviterId: true,
      name: true,
      chatName: true,
      mobile: true,
      email: true,
      avatar: true,
      status: true,
      enableSoundNotification: true,
      enablePopupNotification: true,
      languageCode: true,
      zendeskToken: true,
      createdAt: true,
      updatedAt: true,
    }),
  },
  {
    alias: "updateMyOnDutyStatus",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/me/on-duty-status",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          onDutyStatus: UserOnDutyStatusSchema,
        }),
      },
    ],
    response: z.object({
      onDutyStatus: UserOnDutyStatusSchema,
    }),
  },
  {
    alias: "inviteUser",
    method: "post",
    path: "api/v1/orgs/:orgId/organization/users/invite",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          roleId: z.number().int(),
          email: z.string(),
        }),
      },
    ],
    response: UserUpdateInviteSchema,
  },
  {
    alias: "importWithCsv",
    method: "post",
    path: "api/v1/orgs/:orgId/organization/users/import-csv",
    requestFormat: "form-data",
    parameters: [
      {
        type: "Path",
        name: "orgId",
        schema: OrgSchema.shape.id,
      },
      {
        type: "Body",
        name: "body",
        schema: z.object({
          bulkInviteUserCsv: z.instanceof(File),
        }),
      },
    ],
    response: z.object({
      invitedUserCount: z.number().int(),
      updatedUserCount: z.number().int(),
    }),
  },
]);

export { api };
