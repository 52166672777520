import { FB_CURSOR_BASED_PAGINATION_LIMIT } from "@zeffiroso/env";
import { createFbSdk } from "@zeffiroso/facebook-sdk";

import { logError } from "@/shared/application/logger/sentry";

const fbSdk = createFbSdk({
  logError,
  cursorBasedPaginationLimit: FB_CURSOR_BASED_PAGINATION_LIMIT,
});

export { fbSdk };
