import { useHandler } from "@chatbotgang/etude/react/useHandler";
import useChange from "@react-hook/change";
import type { QueryObserverOptions } from "@tanstack/react-query";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { secondsToMilliseconds } from "date-fns";
import { isEqual, merge, throttle } from "lodash-es";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";

const defaultQueryOptions = {
  /**
   * Slack: [#team-eng-caac](https://chatbotgang.slack.com/archives/C03MSFGNT2A/p1722246613148919?thread_ts=1722245764.998539&cid=C03MSFGNT2A)
   */
  refetchInterval: secondsToMilliseconds(15),
  staleTime: secondsToMilliseconds(15),
} satisfies QueryObserverOptions;

function useProcessingStateResultCountQueries(
  queryOptions?: QueryObserverOptions,
) {
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channelId = searchController.useStore((state) => state.channelId);
  const filter = searchController.useStore((state) => state.filter);
  const assigneeId = useMemo<
    Extract<typeof filter, { assigneeId: any }>["assigneeId"]
  >(
    () =>
      !("assigneeId" in filter)
        ? Number.NaN
        : !filter.assigneeId
          ? Number.NaN
          : filter.assigneeId,
    [filter],
  );

  /**
   * Default to true.
   * Can be disabled for invalidation only when needed.
   */
  const enabledFromQueryOptions = queryOptions?.enabled ?? true;
  /**
   * Make sure no NaN is passed to the query.
   */
  const enabled =
    enabledFromQueryOptions &&
    (filter.assignmentFilter !== "assignee" || Boolean(assigneeId));
  const mergedQueryOptions = useMemo<
    QueryObserverOptions<any, any, any, any>
  >(() => {
    const mergedQueryOptions = merge({}, defaultQueryOptions, queryOptions, {
      enabled,
    } satisfies QueryObserverOptions);
    return mergedQueryOptions;
  }, [enabled, queryOptions]);

  const sharedQueries = useMemo(
    () =>
      ({
        ...(!channelId ? null : { channelId }),
        assignmentFilter: filter.assignmentFilter,
        ...(filter.assignmentFilter === "assignee" ? { assigneeId } : null),
        ...(filter.unread === true ? { unread: true } : null),
        processingState: filter.processingState,
      }) satisfies Parameters<typeof cantata.member.useCount>[0]["queries"],
    [
      channelId,
      filter.assignmentFilter,
      filter.unread,
      filter.processingState,
      assigneeId,
    ],
  );

  const pinnedQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        ...sharedQueries,
        pinned: true,
      },
    },
    mergedQueryOptions,
  );

  const unpinnedQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        ...sharedQueries,
        pinned: false,
      },
    },
    mergedQueryOptions,
  );

  useChange(sharedQueries, function invalidateWhenParamsChange(current, prev) {
    if (isEqual(current, prev)) return;
    pinnedQuery.invalidate();
    unpinnedQuery.invalidate();
  });

  const isSuccess = pinnedQuery.isSuccess && unpinnedQuery.isSuccess;

  const invalidate = useHandler(async function invalidate() {
    return Promise.all([
      safeInvalidateQuery(pinnedQuery.key),
      safeInvalidateQuery(unpinnedQuery.key),
    ]);
  });

  const queries = useMemo(
    () => ({
      pinnedQuery,
      unpinnedQuery,
      isSuccess,
      invalidate,
    }),
    [invalidate, isSuccess, pinnedQuery, unpinnedQuery],
  );
  return queries;
}

function useSearchResultCountQueries(queryOptions?: QueryObserverOptions) {
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const search = searchController.useStore((state) => state.search);
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channelId = searchController.useStore((state) => state.channelId);
  const filter = searchController.useStore((state) => state.filter);

  const assigneeId = useMemo<
    Extract<typeof filter, { assigneeId: any }>["assigneeId"]
  >(
    () =>
      !("assigneeId" in filter)
        ? Number.NaN
        : !filter.assigneeId
          ? Number.NaN
          : filter.assigneeId,
    [filter],
  );

  /**
   * Default to true.
   * Can be disabled for invalidation only when needed.
   */
  const enabledFromQueryOptions = queryOptions?.enabled ?? true;
  /**
   * Make sure no NaN is passed to the query.
   */
  const enabled =
    enabledFromQueryOptions &&
    (filter.assignmentFilter !== "assignee" || Boolean(assigneeId));
  const mergedQueryOptions = useMemo<
    QueryObserverOptions<any, any, any, any>
  >(() => {
    const mergedQueryOptions = merge({}, defaultQueryOptions, queryOptions, {
      enabled,
    } satisfies QueryObserverOptions);
    return mergedQueryOptions;
  }, [enabled, queryOptions]);

  const countQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        ...(!channelId ? null : { channelId }),
        assignmentFilter: filter.assignmentFilter,
        ...(filter.assignmentFilter === "assignee"
          ? { assigneeId: filter.assigneeId }
          : null),
        ...(() => {
          const searchQuery = search.query.toString();
          if (!searchQuery) return {};
          return {
            searchAction: search.action,
            searchQuery,
          };
        })(),
      },
    },
    mergedQueryOptions,
  );

  const invalidate = useHandler(async function invalidate() {
    return safeInvalidateQuery(countQuery.key);
  });

  const queries = useMemo(
    () => ({
      countQuery,
      invalidate,
    }),
    [countQuery, invalidate],
  );
  return queries;
}

/**
 * Invalidate all count queries.
 *
 * This function is throttled to prevent multiple invalidations in a short
 * period of time.
 */
function useInvalidateCountQueries() {
  const processingStateResultCountQueries =
    useProcessingStateResultCountQueries({
      enabled: false,
    });
  const searchResultCountQueries = useSearchResultCountQueries({
    enabled: false,
  });
  const invalidate = useHandler(async function invalidate() {
    return Promise.all([
      processingStateResultCountQueries.invalidate(),
      searchResultCountQueries.invalidate(),
    ]);
  });
  const throttledInvalidate = useMemo(
    () => throttle(invalidate, secondsToMilliseconds(3)),
    [invalidate],
  );
  return throttledInvalidate;
}

export {
  useInvalidateCountQueries,
  useProcessingStateResultCountQueries,
  useSearchResultCountQueries,
};
