import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";

import type { TeamSelectorProps } from "@/resources/team/TeamSelector";
import { TeamSelector as OriginalTeamSelector } from "@/resources/team/TeamSelector";

export const TeamSelector = memo(function TeamSelector({
  value,
  onChange,
  ...props
}: {
  value?: number;
  onChange?: (value: number) => void;
} & Omit<TeamSelectorProps, "value" | "onChange">) {
  return (
    <OriginalTeamSelector
      value={
        value === undefined ? value : Number.isNaN(value) ? undefined : value
      }
      onChange={useHandler((value) => {
        if (!onChange) return;

        if (value === undefined) {
          onChange(Number.NaN);
          return;
        }
        onChange(Number(value));
      })}
      {...props}
    />
  );
});
