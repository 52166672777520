import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";

const cssPrefix = `--rule-form-${random()}-`;
const maxFieldWidthCssVariable = `${cssPrefix}column-field-width-max`;
const minFieldWidthCssVariable = `${cssPrefix}column-field-width-min`;

const cssColumnFieldRoot = css`
  ${maxFieldWidthCssVariable}: 265px;
  ${minFieldWidthCssVariable}: 165px;
`;

const cssColumnField = css`
  width: 100%;
  min-width: var(${minFieldWidthCssVariable});
  max-width: var(${maxFieldWidthCssVariable});
  flex: 1;
`;

export { cssColumnField, cssColumnFieldRoot, maxFieldWidthCssVariable };
