import { createContext } from "@chatbotgang/etude/react/createContext";

import type { CantataTypes } from "@/cantata/types";

const { Provider: ConversationProvider, useContext: useConversation } =
  createContext<CantataTypes["Conversation"] | undefined>({
    name: "Conversation",
  });

export { ConversationProvider, useConversation };
