import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import Linkify from "linkify-react";
import type { FC } from "react";
import { useMemo } from "react";

import type { CantataTypes } from "@/cantata/types";
import { Highlight } from "@/components/Highlight";
import { messageUtil } from "@/routes/Chat/ui/ChatPanel/History/Messages/messageUtil";
import { ReplyMessageById } from "@/routes/Chat/ui/ChatPanel/History/Messages/ReplyMessageById";
import { TextBubble } from "@/routes/Chat/ui/ChatPanel/History/Messages/TextBubble";
import { useSearchMessageFromAMember } from "@/routes/Chat/ui/searchMessageFromMember";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  self: css({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  }),
  replyMessage: css({
    paddingLeft: "5px",
    borderLeft: `3px solid ${theme.colors.neutral003}`,
  }),
  userReplyMessage: css({
    borderLeftColor: theme.colors.blue003,
  }),
  avatarAndName: css({ color: theme.colors.white }),
  message: css({ color: theme.colors.blue003 }),
});

const ReplyContent: FC = () => {
  const message = messageUtil.useMessage();
  const isUserMessage = messageUtil.isUserMessage(message);
  const overrideStyles = useMemo(() => {
    if (!isUserMessage) return undefined;
    return {
      avatarAndName: styles.avatarAndName,
      message: styles.message,
    };
  }, [isUserMessage]);

  return message.replyTo === null ? null : (
    <div
      css={css([
        styles.replyMessage,
        !isUserMessage ? null : styles.userReplyMessage,
      ])}
    >
      <ReplyMessageById
        textMaxVisibleLines={2}
        messageId={message.replyTo}
        overrideStyles={overrideStyles}
      />
    </div>
  );
};

const TextMessage: FC<{
  message: CantataTypes["MessageDetail"];
  isRightSide: boolean;
}> = ({ message }) => {
  const searchMessageFromAMember = useSearchMessageFromAMember();

  const shouldHighlight = useMemo(() => {
    return (
      searchMessageFromAMember.searchText &&
      searchMessageFromAMember.query.isSuccess &&
      /**
       * Only for matched messages from API responses.
       */
      searchMessageFromAMember.query.data.matchedMessages.find(
        (matchedMessage) => matchedMessage.message.id === message.id,
      )
    );
  }, [
    message.id,
    searchMessageFromAMember.query.data?.matchedMessages,
    searchMessageFromAMember.query.isSuccess,
    searchMessageFromAMember.searchText,
  ]);

  return (
    <TextBubble css={styles.self}>
      <ReplyContent />
      <Linkify
        options={{
          attributes: { target: "_blank", rel: "noreferrer noopener" },
        }}
      >
        {shouldHighlight ? (
          <Highlight
            input={message.text}
            match={searchMessageFromAMember.searchText}
          />
        ) : (
          message.text
        )}
      </Linkify>
    </TextBubble>
  );
};

export { TextMessage };
