import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { addDays, startOfDay } from "date-fns/fp";
import { useMemo, useState } from "react";

/**
 * Set up Date-Related Information
 *
 * Use this hook to set up the date-related information for the page.
 *
 * Do not use this hook within components; use it in the page instead. The
 * `startOfToday` might differ between components because they initialize `new
 * Date()` at different times. If you need to use it within components, consider
 * passing the date through context.
 */
function useSetupDateInfo() {
  /**
   * The date when viewing the page.
   */
  const [startOfToday] = useState(() => startOfDay(new Date()));
  const todayYmd = useMemo(() => new Ymd(startOfToday), [startOfToday]);
  const [dateRangePickerMinDate] = useState(() =>
    addDays(
      /**
       * The limit of the date picker is 365 days ago from today.
       *
       * [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1719312121008609?thread_ts=1719311517.730089&cid=C02R6ETJMEY)
       */
      -364,
    )(startOfToday),
  );
  /**
   * Future date is not allowed.
   */
  const [dateRangePickerMaxDate] = useState(() => startOfToday);
  const dateInfo = useMemo(
    () => ({
      startOfToday,
      todayYmd,
      dateRangePickerMinDate,
      dateRangePickerMaxDate,
    }),
    [startOfToday, todayYmd, dateRangePickerMinDate, dateRangePickerMaxDate],
  );
  return dateInfo;
}

export { useSetupDateInfo };
