import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { ReactNode } from "react";

import { SearchHolderIcon } from "@/shared/icons/deprecated/SearchHolderIcon";
import { emotionMedia } from "@/shared/utils/style/emotionMedia";

const cssSearchPlaceholderWrapper = css`
  overflow: auto;
  width: 100%;
  height: 100%;

  & > div {
    margin: 80px auto 0;
    color: ${theme.colors.neutral007};
    font-size: 12px;
    line-height: 16px;

    & > svg {
      display: block;
      margin: 0 auto 55px;
    }

    & > div {
      width: 208px;
      margin: 0 auto;
    }

    ${emotionMedia(
      css,
      "<=mobile",
      (css) => css`
        margin-top: 120px;

        > svg {
          margin-bottom: 30px;
        }
      `,
    )}
  }
`;

export const SearchHolder = memo<{ children: ReactNode }>(
  function SearchHolder({ children }) {
    return (
      <div css={cssSearchPlaceholderWrapper}>
        <div>
          <SearchHolderIcon />
          <div>{children}</div>
        </div>
      </div>
    );
  },
);
