import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { Typography } from "antd";
import { noop } from "lodash-es";
import { type ElementRef, useMemo } from "react";
import type { NavigateFunction } from "react-router-dom";

import { createFullInternalUrl } from "@/app/url/createFullInternalUrl";
import type { ValidPathString } from "@/router/types";
import { useNavigate } from "@/router/utils/navigate";
import { shouldProcessLinkClick } from "@/shared/event/shouldProcessLinkClick";

type LinkProps = Omit<ComponentProps<typeof Typography.Link>, "className"> & {
  to?: ValidPathString;
};

function useNavigateTry(): NavigateFunction {
  try {
    return useNavigate();
  } catch (e) {
    // Prevent error if the component is out of router context
    return noop;
  }
}

/**
 * A wrapper of antd `Typography.Link` that supports both internal and external
 * link.
 *
 * For internal link, use `to` prop to specify the route. It will be converted
 * to href and handle `onClick` by `react-router-dom` `navigate`.
 *
 * For external link, use `href` prop to specify the url. It will be passed to
 * href and add `target="_blank"` and `rel="noopener noreferrer"` automatically
 * if not specified for security reason.
 *
 * For link with only onClick without href, it will add `href="#"` if not
 * specified and prevent default `onClickCapture`.
 */
const Link = forwardRef<ElementRef<typeof Typography.Link>, LinkProps>(
  function Link(props, ref) {
    const navigate = useNavigateTry();
    const overrideProps = useMemo<LinkProps>(
      function computedOverrideProps() {
        const overrideProps: LinkProps = {
          href: props.href,
        };
        // for external link, open in new tab and add rel
        if (props.href) {
          if (!props.target) overrideProps.target = "_blank";
          if (!props.rel) overrideProps.rel = "noopener noreferrer";
        }
        if (props.to) {
          if (!overrideProps.href) {
            // for internal link
            const href = createFullInternalUrl(props.to);
            overrideProps.href = href;
          }
          overrideProps.onClick = function handleClick(...args) {
            const e = args[0];
            props.onClick?.(...args);
            if (e.defaultPrevented) return;
            if (props.to) {
              if (!shouldProcessLinkClick(e, props.target)) return;
              e.preventDefault();
              navigate(props.to);
            }
          };
          // for link with only onClick without href
          if (overrideProps.href === undefined) {
            overrideProps.onClickCapture = function handleClickCapture(
              ...args
            ) {
              const e = args[0];
              e.preventDefault();
              props.onClickCapture?.(...args);
            };
            overrideProps.href = "#";
          }
        }
        // for link with only onClick without href
        if (overrideProps.href === undefined) {
          overrideProps.onClickCapture = function handleClickCapture(...args) {
            const e = args[0];
            e.preventDefault();
            props.onClickCapture?.(...args);
          };
          overrideProps.href = "#";
        }
        return overrideProps;
      },
      [navigate, props],
    );
    return <Typography.Link {...props} ref={ref} {...overrideProps} />;
  },
);

export { Link };
export type { LinkProps };
