import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { useTranslation } from "react-i18next";

const cssTitle = css`
  margin-bottom: 0;
  color: ${theme.colors.neutral010};
  font-size: 1.125rem;
  font-weight: 700;
`;

const Title = memo(function Title() {
  const { t } = useTranslation();
  return (
    <h2 css={cssTitle}>
      {t("organization.chatSettings.userRecognition.title")}
    </h2>
  );
});

export { Title };
