import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { message } from "@/components/message";
import type {
  Action as ActionType,
  BaseAction,
} from "@/layout/DevMode/Action/config";
import { actions } from "@/layout/DevMode/Action/config";

const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: bold;
`;

const Description = styled.p`
  font-size: 1rem;
  font-weight: normal;
`;

const ActionItem = memo(function ActionItem<A extends BaseAction>({
  action,
}: {
  action: ActionType<A>;
}) {
  const execute = useHandler(async function execute() {
    try {
      await action.action();
      message.success(inspectMessage`Execute action ${action.title} success`);
    } catch (error) {
      message.error(
        inspectMessage`Execute action ${action.title} failed: ${error}`,
      );
    }
  });
  return (
    <Flex
      css={css`
        flex-direction: column;
        align-items: flex-start;
      `}
    >
      <Title>{action.title}</Title>
      {!action.description ? null : (
        <Description>{action.description}</Description>
      )}
      <Button type="primary" onClick={execute}>
        Execute
      </Button>
    </Flex>
  );
});

export const Action = memo(function Action() {
  return (
    <Flex
      css={css`
        flex-direction: column;
        gap: 2rem;
      `}
    >
      {actions.map((action, index) => (
        <ActionItem key={`${index}`} action={action} />
      ))}
    </Flex>
  );
});
