import { useMutation } from "@tanstack/react-query";

import { cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";

/**
 * Since `markRead` will be called frequently, we prefer not to use the Cantata
 * SDK's mutation directly to avoid frequent invalidation of the member queries.
 */
function useMarkReadMutation() {
  const mutation = useMutation({
    async mutationFn({
      orgId,
      memberId,
      signal,
    }: {
      orgId: CantataTypes["Org"]["id"];
      memberId: CantataTypes["Member"]["id"];
      signal?: AbortSignal;
    }) {
      cantataClient.member.markRead(
        {
          senderSession: "no use",
        },
        {
          params: {
            orgId,
            memberId,
          },
          signal,
        },
      );
    },
  });
  return mutation;
}

export { useMarkReadMutation };
