import type { ErrorBoundaryProps, FallbackRender } from "@sentry/react";
import { type FC, useCallback } from "react";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import type { ErrorFallbackPageProps } from "@/internal/PageErrorBoundary/ErrorFallbackPage";
import { ErrorFallbackPage } from "@/internal/PageErrorBoundary/ErrorFallbackPage";

// This will expose the props to the component instead of using the `ErrorFallbackPageProps` prop.
type HoistedPropKey = "reloadWindow" | "css";

type PageErrorBoundaryProps = Omit<ErrorBoundaryProps, "fallback"> &
  Pick<ErrorFallbackPageProps, HoistedPropKey> & {
    ErrorFallbackPageProps?: Omit<
      ErrorFallbackPageProps,
      // These props will be passed in `fallback` function.
      | "errorData"
      | "description"
      // Hoisted props will be passed directly to the component.
      | HoistedPropKey
    >;
  };

const PageErrorBoundary: FC<PageErrorBoundaryProps> = ({
  children,
  ErrorFallbackPageProps,
  ...props
}) => {
  const fallback = useCallback<FallbackRender>(
    function fallback(errorData) {
      return (
        <ErrorFallbackPage
          {...ErrorFallbackPageProps}
          errorData={errorData}
          description={({ children }) => (
            <div>
              {children}
              <p>
                <ErrorBoundary.Msg error={errorData.error} />
              </p>
              <ErrorBoundary.EventId eventId={errorData.eventId} />
            </div>
          )}
        />
      );
    },
    [ErrorFallbackPageProps],
  );
  return (
    <ErrorBoundary fallback={fallback} {...props}>
      {children}
    </ErrorBoundary>
  );
};

export { PageErrorBoundary };
