import { css } from "@emotion/react";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import type { FC } from "react";
import { createPortal } from "react-dom";

import { Trans } from "@/app/i18n/Trans";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { Filters } from "@/routes/Insights/Contacts/filters";
import { Overview } from "@/routes/Insights/Contacts/Overview";
import { PageInfoProvider } from "@/routes/Insights/Contacts/pageInfo";
import { Tags } from "@/routes/Insights/Contacts/Tags";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: 24,
  }),
});

const Contact: FC = () => {
  return (
    <MainLayout.Paper>
      <MainLayout.Header>
        <Trans i18nKey="page.insights.contacts.title" />
      </MainLayout.Header>
      <MainLayout.Body>
        <PageInfoProvider>
          <MainLayout.Sticky>
            <Filters />
          </MainLayout.Sticky>
          <MainLayout.Content>
            <div css={styles.root}>
              <Overview />
              <Tags />
            </div>
          </MainLayout.Content>
        </PageInfoProvider>
      </MainLayout.Body>
    </MainLayout.Paper>
  );
};

const WrappedContacts: FC = () => {
  const portal = usePortal();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Outer mainRef={portal.setOuterEl} />
      {createPortal(<Contact />, portal.innerEl)}
    </SideMenuLayout.Layout>
  );
};

export { WrappedContacts as Contacts };
