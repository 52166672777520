import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ElementType, ForwardedRef, ReactNode } from "react";

import { defineStyles } from "@/shared/emotion";
import { emotionMedia } from "@/shared/utils/style/emotionMedia";

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface PaperOwnProps {
  /**
   * The component used for the root node.
   */
  component?: ElementType;
  /**
   * The content of the component.
   */
  children?: ReactNode;
}

interface PaperTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & PaperOwnProps;
  defaultComponent: RootComponent;
}

type PaperProps<
  RootComponent extends ElementType = PaperTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = PaperOwnProps &
  OverrideProps<PaperTypeMap<AdditionalProps, RootComponent>, RootComponent> & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: emotionMedia(
    css,
    ">=tabletLandscape",
    (css) => css`
      border-radius: 20px 0 0 20px;
      background-color: ${theme.colors.white};
      box-shadow: -2px 0 4px 0 rgb(34 59 83 / 5%);
    `,
  ),
});

/**
 * The surface of the paper to be used in the layout.
 *
 * This is only for lg+ screens.
 */
const Paper: OverridableComponent<PaperTypeMap> = forwardRef(function Paper(
  { component: Component = defaultComponent, children, ...props }: PaperProps,
  ref: ForwardedRef<ElementRef<typeof Component>>,
) {
  return (
    <Component css={styles.root} {...props} ref={ref}>
      {children}
    </Component>
  );
}) as OverridableComponent<PaperTypeMap>;

export { defaultComponent, Paper };

export type { PaperOwnProps, PaperProps, PaperTypeMap };
