import { LoadingOutlined, WarningFilled } from "@ant-design/icons";
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Tooltip } from "@/components/Tooltip";
import { ChannelTypeChattingIcon } from "@/resources/channel/ChannelTypeIcon";
import { useConversation } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation/Context";

const iconOptions: ComponentProps<typeof ChannelTypeChattingIcon>["options"] = {
  chatting: true,
};

const ErrorIcon = (function declareErrorIcon() {
  const cssErrorIcon = css({
    color: theme.colors.red006,
  });
  const ErrorIcon = forwardRef<
    ElementRef<typeof WarningFilled>,
    ComponentProps<typeof WarningFilled>
  >(function ErrorIcon(props, ref) {
    return <WarningFilled css={cssErrorIcon} {...props} ref={ref} />;
  });
  return ErrorIcon;
})();

const cssContainer = css({
  fontSize: "1rem",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const ChannelType = memo(function ChannelType() {
  const conversation = useConversation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberQuery = cantata.member.useGetById({
    params: {
      orgId,
      memberId: conversation.memberId,
    },
  });
  const channelId = useMemo(
    function computeMemberId() {
      if (!memberQuery.isSuccess) return Number.NaN;
      return memberQuery.data.channelId;
    },
    [memberQuery.data?.channelId, memberQuery.isSuccess],
  );
  const channelsQuery = cantata.channel.useList(
    {
      params: {
        orgId,
      },
    },
    {
      enabled: Boolean(channelId),
    },
  );
  const channelType = useMemo(
    function computedChannelType() {
      if (!channelsQuery.isSuccess) return null;
      return channelsQuery.data.channels.find(
        (channel) => channel.id === channelId,
      )?.type;
    },
    [channelId, channelsQuery.data?.channels, channelsQuery.isSuccess],
  );
  const icon = useMemo(
    function computeIcon() {
      if (memberQuery.isLoading || channelsQuery.isLoading)
        return <LoadingOutlined />;
      if (memberQuery.isError) {
        return (
          <Tooltip title={memberQuery.error.message}>
            <ErrorIcon />
          </Tooltip>
        );
      }
      if (channelsQuery.isError) {
        return (
          <Tooltip title={channelsQuery.error.message}>
            <ErrorIcon />
          </Tooltip>
        );
      }
      if (!channelType) {
        return (
          <Tooltip title={inspectMessage`Channel ${channelId} not found`}>
            <ErrorIcon />
          </Tooltip>
        );
      }
      return (
        <ChannelTypeChattingIcon
          channelType={channelType}
          options={iconOptions}
        />
      );
    },
    [
      channelId,
      channelType,
      channelsQuery.error?.message,
      channelsQuery.isError,
      channelsQuery.isLoading,
      memberQuery.error?.message,
      memberQuery.isError,
      memberQuery.isLoading,
    ],
  );
  return <div css={cssContainer}>{icon}</div>;
});

export { ChannelType };
