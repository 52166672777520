import { LoadingOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { UserStatusLabel } from "@/resources/user/UserStatus";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  nonActivateStausIfNeeded: css({
    color: theme.colors.neutral005,
  }),
});

/**
 * Display user status label with non-active status in the end.
 *
 * [Figma](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=7229-24628&m=dev)
 */
const UserNonActiveStaus: FC<{
  userId: CantataTypes["User"]["id"];
}> = ({ userId }) => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const usersQuery = cantata.user.useList(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      select: (data) => data.users,
    },
  );
  if (usersQuery.isError) return <ErrorBoundary.Msg error={usersQuery.error} />;
  if (usersQuery.isLoading) return <LoadingOutlined />;
  const user = usersQuery.data.find((user) => user.id === userId);
  const status: CantataTypes["User"]["status"] = !user
    ? "deleted"
    : user.status;
  if (status === "active") return null;
  return (
    <span css={styles.nonActivateStausIfNeeded}>
      {" ("}
      {t(UserStatusLabel.getUserStatusTranslationKey(status))}
      {")"}
    </span>
  );
};

export { UserNonActiveStaus };
