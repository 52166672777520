import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { Fragment, useMemo } from "react";

import { routerUtils } from "@/router/routerUtils";
import type { ValidPathString } from "@/router/types";
import { useMatchCurrentPath } from "@/router/utils/matchPath";

import type { TabItem } from ".";
import { PageTabs } from ".";

type RouteTabItem = TabItem & {
  key: ValidPathString;
};

const defaultComponent = PageTabs;

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface RouteTabsOwnProps {
  /**
   * The component used for the root node.
   */
  component?: typeof PageTabs;
  items: Array<RouteTabItem>;
}

interface RouteTabsTypeMap<
  AdditionalProps = unknown,
  RootComponent extends typeof PageTabs = DefaultComponent,
> {
  props: AdditionalProps & RouteTabsOwnProps;
  defaultComponent: RootComponent;
}

type RouteTabsProps<
  RootComponent extends typeof PageTabs = RouteTabsTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = RouteTabsOwnProps &
  OverrideProps<
    RouteTabsTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: typeof PageTabs;
  };

/**
 * A tabs component that uses the router to determine the active tab.
 */
const RouteTabs: OverridableComponent<RouteTabsTypeMap> = ({
  component: Component = defaultComponent,
  ...props
}: RouteTabsProps) => {
  const navigate = routerUtils.useNavigate();
  const matchCurrentPath = useMatchCurrentPath();
  const checkRoutePermission = routerUtils.useCheckRoutePermission();
  const visitableItems = useMemo(
    () =>
      props.items.flatMap((item) => {
        const authorized = checkRoutePermission(item.key);
        if (!authorized) return [];
        return [item];
      }),
    [props.items, checkRoutePermission],
  );
  const onChange = useHandler<ComponentProps<typeof Component>["onChange"]>(
    function onChange(...args) {
      const key = args[0];
      navigate(key as ValidPathString);
      props.onChange?.(...args);
    },
  );
  const activeKey = useMemo<
    ComponentProps<typeof Component>["activeKey"]
  >(() => {
    if ("activeKey" in props) return props.activeKey;
    return (
      visitableItems.find((item) =>
        matchCurrentPath({
          path: item.key,
        }),
      )?.key ||
      visitableItems.find((item) =>
        matchCurrentPath({
          path: item.key,
          end: false,
        }),
      )?.key
    );
  }, [visitableItems, matchCurrentPath, props]);
  const items = useMemo(() => {
    return visitableItems.map((item) => {
      const active = item.key === activeKey;
      return {
        ...item,
        ...(active
          ? null
          : {
              /**
               * Clear other children is required to prevent rendering multiple
               * `<Outlet />`.
               *
               * Related issue:
               *
               * - [偶發性頁面 double content](https://app.asana.com/0/0/1207473349745331/f)
               */
              children: null,
            }),
      };
    });
  }, [activeKey, visitableItems]);
  /**
   * If there are no items, render nothing.
   */
  if (items.length === 0) return <Fragment />;
  /**
   * If there is only one item, return the children of the item.
   */
  if (items.length === 1) {
    const item = items[0];
    if (!item)
      throw new Error(inspectMessage`Unexpected empty item, items: ${items}`);
    return <>{item.children}</>;
  }
  return (
    <Component
      {...props}
      items={items}
      activeKey={activeKey}
      onChange={onChange}
    />
  );
};

export { defaultComponent, RouteTabs };

export type { RouteTabsOwnProps, RouteTabsProps, RouteTabsTypeMap };
