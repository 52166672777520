import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createWithEqualityFn } from "zustand/traditional";

type Store = {
  location: Readonly<URL>;
};

const useLocationStore = createWithEqualityFn<Store>()(
  () => ({
    location: new URL(window.location.href),
  }),
  shallow,
);

/**
 * Integrate react router with vanilla zustand.
 */
function ReactRouterEffect() {
  const location = useLocation();
  useEffect(() => {
    useLocationStore.setState({ location: new URL(window.location.href) });
  }, [location]);
  return null;
}

/**
 * This is a hook for getting the current location reactively.
 * Integrated with vanilla zustand.
 * Not for react router.
 * DO NOT use this for router navigation.
 *
 * @returns Current location.
 */
function useVanillaLocation(): Readonly<URL> {
  return useLocationStore((state) => state.location);
}

export { ReactRouterEffect, useVanillaLocation };
