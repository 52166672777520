import { BarLoading } from "@/components/Loading/BarLoading";
import { Modal } from "@/components/Modal";

/**
 * This component prevents user interaction with the page while it is being
 * rendered during page loading.
 */
const LockScreenLoading = function LockScreenLoading() {
  return (
    <Modal open footer={null} closable={false}>
      <BarLoading />
    </Modal>
  );
};

export { LockScreenLoading };
