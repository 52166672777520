import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css, keyframes } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import {
  type ElementRef,
  type ElementType,
  type FC,
  type ForwardedRef,
  useMemo,
} from "react";
import { z } from "zod";

import { defineStyles } from "@/shared/emotion";

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

const stateSchema = z.enum(["regular", "success", "warning", "error"]);
type State = z.infer<typeof stateSchema>;

/**
 * List the own props of the component.
 */
interface ProgressOwnProps {
  total: number;
  value: number;
  state?: State;
  loading?: boolean;
  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface ProgressTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & ProgressOwnProps;
  defaultComponent: RootComponent;
}

type ProgressProps<
  RootComponent extends ElementType = ProgressTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = ProgressOwnProps &
  OverrideProps<
    ProgressTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const loadingKeyFrames = keyframes({
  from: {
    translate: "-100%",
  },
  to: {
    translate: "100%",
  },
});

const defaultHeight = 8;

const styles = defineStyles({
  root: css({
    display: "block",
    "--progress-height": `${defaultHeight}px`,
    height: "var(--progress-height)",
    borderRadius: "calc(var(--progress-height) / 2)",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.colors.neutral003,
    flex: 1,
    width: ["fill-available", "available", "stretch"],
    position: "relative",
  }),
  progress: css({
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    overflow: "hidden",
    transition: [
      "right 0.3s ease-in-out",
      "background-color 0.3s ease-in-out",
    ].join(", "),
  }),
  regular: css({
    backgroundColor: theme.colors.primary,
  }),
  success: css({
    backgroundColor: theme.colors.success,
  }),
  warning: css({
    backgroundColor: theme.colors.warning,
  }),
  error: css({
    backgroundColor: theme.colors.error,
  }),
  loading: css({
    position: "absolute",
    inset: 0,
    background: "linear-gradient(90deg, transparent, white)",
    backgroundRepeat: "no-repeat",
    opacity: 0.8,
    animation: `${loadingKeyFrames} 1.5s infinite linear`,
  }),
});

const Loading: FC = () => <div css={styles.loading} />;

/**
 * A component to display a progress bar.
 */
const Progress: OverridableComponent<ProgressTypeMap> = forwardRef(
  function Progress(
    {
      total = 1,
      value = 0,
      state = "regular",
      loading = false,
      component: Component = defaultComponent,
      ...props
    }: ProgressProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const cssProgress = useMemo(
      () => css([styles.progress, styles[state]]),
      [state],
    );
    return (
      <Component css={styles.root} {...props} ref={ref}>
        <div
          style={{ right: `${((total - value) / total) * 100}%` }}
          css={cssProgress}
        >
          {!loading ? null : <Loading />}
        </div>
      </Component>
    );
  },
) as OverridableComponent<ProgressTypeMap>;

const api = Object.assign(Progress, {
  defaultComponent,
  styles,
  stateSchema,
});

export { api as Progress };

export type { ProgressOwnProps, ProgressProps, ProgressTypeMap };
