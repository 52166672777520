import { LoadingOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { Menu } from "antd";
import {
  type ElementRef,
  type FC,
  type ReactNode,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { type FeatureFlagTypes, useGetFeatureFlag } from "@/app/featureFlag";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Drawer, type DrawerProps } from "@/components/Drawer";
import {
  DisabledContextProvider,
  useMergeFormDisabled,
} from "@/components/Form/DisabledContext";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { Modal } from "@/components/Modal";
import { MotifIcon } from "@/components/MotifIcon";
import { Account } from "@/layout/base/AuthenticatedAppOuter/components/AccountPanel/Home/Account";
import { Profile } from "@/layout/base/AuthenticatedAppOuter/components/AccountPanel/Home/Profile";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { OrgAvatar } from "@/resources/organization/OrgAvatar";
import { Dot } from "@/resources/user/onDutyStatus/Dot";
import { Name } from "@/resources/user/onDutyStatus/Name";
import { routerUtils } from "@/router/routerUtils";
import type { BasePath, To, ValidPathString } from "@/router/types";
import { useNavigate } from "@/router/utils";
import { compileToString } from "@/router/utils/compileTo";
import { useLogoutMutation } from "@/shared/application/authenticate";
import { useUserPermission } from "@/shared/application/user";
import type { PermissionName } from "@/shared/domains/role";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  drawer: css({
    "& .ant-drawer-body": {
      paddingBlock: 24,
      paddingInline: 16,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 16,
    },
  }),
  category: css({
    paddingBottom: 4,
    color: theme.colors.neutral007,
    fontSize: "0.75rem",
  }),
  menu: css({
    border: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 4,
    "& .ant-menu-item": {
      padding: 8,
      margin: 0,
      display: "flex",
      alignItems: "center",
      gap: 8,
      color: theme.colors.neutral009,
    },
    "& .ant-menu-item-icon": {
      fontSize: 16,
    },
    "& .ant-menu-title-content": {
      flex: 1,
      margin: 0,
      fontSize: "0.875rem",
      lineHeight: "normal",
    },
  }),
});

type Item = {
  label: ReactNode;
  icon: ReactNode;
  featureFlag?:
    | FeatureFlagTypes["ToggleKey"]
    | Array<FeatureFlagTypes["ToggleKey"]>;
  excludeFeatureFlag?:
    | FeatureFlagTypes["ToggleKey"]
    | Array<FeatureFlagTypes["ToggleKey"]>;
  permission?: PermissionName | Array<PermissionName>;
  excludePermission?: PermissionName | Array<PermissionName>;
  disabled?: boolean;
} & (
  | {
      path:
        | ValidPathString
        | ((
            define: <TPath extends BasePath>(to: To<TPath>) => To<TPath>,
          ) => ReturnType<typeof define>);
    }
  | {
      key: string;
      onClick: () => void;
    }
);

type MenuItem = NonNullable<ComponentProps<typeof Menu>["items"]>[number];

const AccountDrawer: FC<DrawerProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const orgQueriesData = orgQueriesContext.useData();
  const [onDutyStatusDrawerOpened, toggleOnDutyStatusDrawer] = useSwitch(false);
  const logoutMutation = useLogoutMutation();
  const { hasPermission } = useUserPermission();
  const getFeatureFlag = useGetFeatureFlag();
  const checkRoutePermission = routerUtils.useCheckRoutePermission();
  const itemsToMenuItems = useCallback(
    function itemsToMenuItems(items: Array<Item>): Array<MenuItem> {
      return items.flatMap<MenuItem>((item) => {
        if (item.excludeFeatureFlag) {
          if (Array.isArray(item.excludeFeatureFlag)) {
            if (item.excludeFeatureFlag.some((key) => getFeatureFlag(key))) {
              return [];
            }
          } else if (getFeatureFlag(item.excludeFeatureFlag)) {
            return [];
          }
        }
        if (item.excludePermission) {
          if (Array.isArray(item.excludePermission)) {
            if (
              item.excludePermission.some((permission) =>
                hasPermission(permission),
              )
            ) {
              return [];
            }
          } else if (hasPermission(item.excludePermission)) {
            return [];
          }
        }
        if (item.featureFlag) {
          if (Array.isArray(item.featureFlag)) {
            if (!item.featureFlag.every((key) => getFeatureFlag(key))) {
              return [];
            }
          } else if (!getFeatureFlag(item.featureFlag)) {
            return [];
          }
        }
        if (item.permission) {
          if (Array.isArray(item.permission)) {
            if (
              !item.permission.every((permission) => hasPermission(permission))
            ) {
              return [];
            }
          } else if (!hasPermission(item.permission)) {
            return [];
          }
        }
        if ("onClick" in item) return item;
        const targetPath =
          typeof item.path === "string"
            ? item.path
            : compileToString(
                // @ts-expect-error -- Too complex to type for generic
                item.path((v) => v),
              );
        if (!checkRoutePermission(targetPath)) return [];
        return [
          define<MenuItem>({
            key: targetPath,
            icon: item.icon,
            label: item.label,
            disabled: item.disabled,
            onClick: () => {
              navigate(targetPath);
            },
          }),
        ];
      });
    },
    [checkRoutePermission, getFeatureFlag, hasPermission, navigate],
  );
  const items = useMemo<Array<Item>>(
    () => [
      {
        icon: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 16,
            }}
          >
            <Dot status={orgQueriesData.me.onDutyStatus} size={8} />
          </div>
        ),
        label: (
          <ItemWithIcon endIcon={<MotifIcon un-i-motif="arrow_right" />}>
            <Name status={orgQueriesData.me.onDutyStatus} />
          </ItemWithIcon>
        ),
        key: "onDutyStatus",
        onClick: toggleOnDutyStatusDrawer.on,
        disabled: onDutyStatusDrawerOpened,
      },
      {
        icon: <MotifIcon un-i-motif="circle_user" />,
        label: t("glossary.myProfile"),
        path: (to) => to("/settings/profile"),
      },
      {
        icon: <MotifIcon un-i-motif="bell" />,
        label: t("notification.setting.title"),
        path: (to) => to("/settings/notification"),
      },
      {
        icon: <MotifIcon un-i-motif="arrow_exit" />,
        label: t("common.logout"),
        key: "logout",
        onClick() {
          logoutMutation.mutate();
        },
        disabled: logoutMutation.isLoading,
      },
    ],
    [
      logoutMutation,
      onDutyStatusDrawerOpened,
      orgQueriesData.me.onDutyStatus,
      t,
      toggleOnDutyStatusDrawer.on,
    ],
  );
  const menuItems = useMemo(
    () => itemsToMenuItems(items),
    [itemsToMenuItems, items],
  );
  const workspaceItems = useMemo<Array<Item>>(
    () => [
      {
        icon: <MotifIcon un-i-motif="thunder" />,
        label: t("menu.templates"),
        path: (compileToString) => compileToString("/quick-templates"),
      },
      {
        icon: <MotifIcon un-i-motif="user_transfer" />,
        label: t("menu.assignmentSetting"),
        path: (compileToString) =>
          compileToString({
            pathname: "/settings/assignment",
          }),
      },
      {
        icon: <MotifIcon un-i-motif="user_group" />,
        label: t("menu.team"),
        path: (compileToString) => compileToString("/settings/teams"),
      },
    ],
    [t],
  );
  const workspaceMenuItems = useMemo(
    () => itemsToMenuItems(workspaceItems),
    [itemsToMenuItems, workspaceItems],
  );
  return (
    <>
      <Drawer
        {...props}
        css={styles.drawer}
        closable={false}
        width={268}
        open={!onDutyStatusDrawerOpened && props.open}
      >
        <Profile />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Account />
          <Menu css={styles.menu} items={menuItems} />
        </div>
        <div>
          <div css={styles.category}>
            {t("page.settings.sideMenu.category.workspace.title")}
          </div>
          <Menu css={styles.menu} items={workspaceMenuItems} />
        </div>
      </Drawer>
      <OnDutyStatusDrawer
        open={onDutyStatusDrawerOpened}
        onClose={toggleOnDutyStatusDrawer.off}
        close={toggleOnDutyStatusDrawer.off}
      />
      {!logoutMutation.isLoading ? null : <Modal.Loading open />}
    </>
  );
};

const onDutyStatusItemKeys: Array<CantataTypes["User"]["onDutyStatus"]> = [
  "online",
  "away",
  "offline",
];

const OnDutyStatusDrawer: FC<
  DrawerProps & {
    close: () => void;
  }
> = ({ close, ...props }) => {
  const mergeFormDisabled = useMergeFormDisabled();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const mutation = cantata.user.useUpdateMyOnDutyStatus(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess() {
        close();
      },
    },
  );
  const orgQueriesData = orgQueriesContext.useData();
  const onDutyStatus = orgQueriesData.me.onDutyStatus;
  const items = useMemo<ComponentProps<typeof Menu>["items"]>(
    () => [
      {
        key: "",
        icon: <MotifIcon un-i-motif="arrow_left" />,
        onClick() {
          close();
        },
        style: {
          color: theme.colors.neutral009,
          marginBlockEnd: "calc(12px - 4px)",
        },
      },
      ...onDutyStatusItemKeys.map<
        NonNullable<ComponentProps<typeof Menu>["items"]>[number]
      >((key) => {
        const selected = key === onDutyStatus;
        return {
          key,
          icon: <Dot status={key} size={6} />,
          label: (
            <ItemWithIcon
              selected={selected}
              endIcon={mutation.isLoading ? <LoadingOutlined /> : undefined}
            >
              <Name status={key} />
            </ItemWithIcon>
          ),
          style: selected
            ? { backgroundColor: theme.colors.blue001 }
            : undefined,
          onClick: selected
            ? undefined
            : () => {
                mutation.mutate({ onDutyStatus: key });
              },
          disabled: mutation.isLoading,
        };
      }),
    ],
    [close, mutation, onDutyStatus],
  );
  return (
    <DisabledContextProvider disabled={mergeFormDisabled(mutation.isLoading)}>
      <Drawer {...props} closable={false} width={252} css={styles.drawer}>
        <Menu css={styles.menu} items={items} />
      </Drawer>
    </DisabledContextProvider>
  );
};

type EndRef = ElementRef<"span">;
type EndProps = Omit<ComponentProps<"span">, "children">;

const End = forwardRef<EndRef, EndProps>(function End(props, ref) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [accountDrawerOpened, toggleAccountDrawer] = useSwitch(false);
  return (
    <span {...props} ref={ref}>
      <NarrowIconButton
        icon={<OrgAvatar orgId={orgId} />}
        onClick={toggleAccountDrawer.on}
        disabled={accountDrawerOpened}
      />
      <AccountDrawer
        open={accountDrawerOpened}
        onClose={toggleAccountDrawer.off}
      />
    </span>
  );
});

export { End };
