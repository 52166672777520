import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { Markdown } from "@/components/Markdown";
import { Modal } from "@/components/Modal";
import { UserNameById } from "@/resources/user/UserNameById";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  desc: css({
    fontSize: "1rem",
  }),
});

interface Params {
  userId: CantataTypes["User"]["id"];
}

const ParamsContext = createContext<Params>({
  name: "ParamsContext",
});

const Content: FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const params = ParamsContext.useContext();
  const pinnedNewAssignedMembersCountQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        assignmentFilter: "assignee",
        assigneeId: params.userId,
        processingState: "new",
        pinned: true,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: (data) => data.count,
    },
  );
  const pinnedFollowUpAssignedMembersCountQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        assignmentFilter: "assignee",
        assigneeId: params.userId,
        processingState: "follow-up",
        pinned: true,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: (data) => data.count,
    },
  );
  const unpinnedNewAssignedMembersCountQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        assignmentFilter: "assignee",
        assigneeId: params.userId,
        processingState: "new",
        pinned: false,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: (data) => data.count,
    },
  );
  const unpinnedFollowUpAssignedMembersCountQuery = cantata.member.useCount(
    {
      params: {
        orgId,
      },
      queries: {
        assignmentFilter: "assignee",
        assigneeId: params.userId,
        processingState: "follow-up",
        pinned: false,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: (data) => data.count,
    },
  );
  const count = useMemo(
    () =>
      !pinnedNewAssignedMembersCountQuery.isSuccess ||
      !pinnedFollowUpAssignedMembersCountQuery.isSuccess ||
      !unpinnedNewAssignedMembersCountQuery.isSuccess ||
      !unpinnedFollowUpAssignedMembersCountQuery.isSuccess
        ? 0
        : pinnedNewAssignedMembersCountQuery.data +
          pinnedFollowUpAssignedMembersCountQuery.data +
          unpinnedNewAssignedMembersCountQuery.data +
          unpinnedFollowUpAssignedMembersCountQuery.data,
    [
      pinnedFollowUpAssignedMembersCountQuery.data,
      pinnedFollowUpAssignedMembersCountQuery.isSuccess,
      pinnedNewAssignedMembersCountQuery.data,
      pinnedNewAssignedMembersCountQuery.isSuccess,
      unpinnedFollowUpAssignedMembersCountQuery.data,
      unpinnedFollowUpAssignedMembersCountQuery.isSuccess,
      unpinnedNewAssignedMembersCountQuery.data,
      unpinnedNewAssignedMembersCountQuery.isSuccess,
    ],
  );
  return (
    <Markdown
      css={styles.desc}
      markdown={t("resource.user.suspense.modal.description", { count })}
    />
  );
};

const Title: FC = () => {
  const params = ParamsContext.useContext();
  return (
    <Trans
      i18nKey="resource.user.suspense.modal.title"
      components={{
        UserName: <UserNameById userId={params.userId} />,
      }}
    />
  );
};

namespace SuspenseModal {
  export interface Props
    extends Overwrite<
      Omit<ComponentProps<typeof Modal>, "cancelText" | "okText" | "title">,
      Params
    > {}
}

const SuspenseModal: FC<SuspenseModal.Props> = ({ userId, ...props }) => {
  const { t } = useTranslation();
  const contextValue = useMemo(() => ({ userId }), [userId]);
  return (
    <ParamsContext.Provider value={contextValue}>
      <Modal
        {...props}
        cancelText={t("common.cancel")}
        okText={t("common.suspend")}
        okButtonProps={{
          danger: true,
        }}
        title={<Title />}
      >
        <ErrorBoundary.Alert>
          <Content />
        </ErrorBoundary.Alert>
      </Modal>
    </ParamsContext.Provider>
  );
};

export { SuspenseModal };
