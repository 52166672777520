import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";

import { InputTextArea } from "@/components/Input";
import { buildTextMessage } from "@/routes/QuickTemplate/applications/utils";

const TextFieldContainer = styled.div`
  --space-gap: 10px;
`;

const TextFieldWrapper = styled.div`
  width: 362px;
  box-sizing: border-box;
  padding: var(--space-gap);
  border-radius: ${theme.shape.borderRadius};
  margin-bottom: 8px;
  background: ${theme.colors.white};

  input {
    width: 100%;
    resize: vertical;
  }
`;

const TextCounter = styled.div`
  margin-left: var(--space-gap);
  color: ${theme.colors.neutral006};
  font-size: 14px;
  line-height: 24px;
`;

type QuickTemplateTextMessageFormValue = ReturnType<typeof buildTextMessage>;

function TextField({
  value,
  placeholder,
  onChange,
  maxLength,
}: {
  value?: QuickTemplateTextMessageFormValue;
  onChange?: (value: QuickTemplateTextMessageFormValue) => void;
  placeholder?: string;
  maxLength: number;
}) {
  return (
    <TextFieldContainer>
      <TextFieldWrapper>
        <InputTextArea
          autoSize={{ minRows: 10 }}
          placeholder={placeholder}
          value={value?.text}
          onChange={(event) => {
            const draft = value ?? buildTextMessage();
            onChange?.({
              ...draft,
              text: event.target.value,
            });
          }}
        />
      </TextFieldWrapper>
      <TextCounter>
        {value?.text.length ?? 0}/{maxLength}
      </TextCounter>
    </TextFieldContainer>
  );
}

export { TextField };
