/**
 * The only one environment variables entry.
 * Do not use process.env directly.
 * Use these variables instead.
 */
import { integer } from "@chatbotgang/etude/pitch-shifter/integer";
import { nativeEnum } from "@chatbotgang/etude/pitch-shifter/nativeEnum";
import { toString } from "@chatbotgang/etude/pitch-shifter/string";
import urlJoin from "url-join";

const ENV_TEST = import.meta.env["NODE_ENV"] === "test";

const TITLE = toString(import.meta.env["VITE_TITLE"]);

/** CAAC API service host */
const CAAC_API_URL = toString(import.meta.env["VITE_CANTATA_API_HOST"]);
const API_DEFAULT_LIMIT = integer()(import.meta.env["VITE_API_DEFAULT_LIMIT"]);
const API_BULK_ACTION_LIMIT = integer()(
  import.meta.env["VITE_API_BULK_ACTION_LIMIT"],
);
/** CAAC Websocket service host */
const CAAC_WEBSOCKET_HOST = toString(import.meta.env["VITE_LEGATO_WS_HOST"]);
const CAAC_WEBSOCKET_URL = urlJoin(CAAC_WEBSOCKET_HOST, "/api/v1/ws");
/** MAAC forget password URL */
const MAAC_FORGET_PASSWORD_URL = toString(
  import.meta.env["VITE_MAAC_FORGET_PASSWORD_URL"],
);
/** Admin service host */
const ADMIN_CENTER_HOST = toString(import.meta.env["VITE_ADMIN_CENTER_HOST"]);

/** Basic environment variables */
/** is NODE_ENV development */
const DEVELOPMENT_MODE = import.meta.env["NODE_ENV"] === "development";
const BASE_URL = import.meta.env.BASE_URL;

/** Firebase configs */
const FIREBASE_CONTENT_STORAGE_API_KEY = toString(
  import.meta.env["VITE_FIREBASE_CONTENT_STORAGE_API_KEY"],
);
const FIREBASE_CONTENT_STORAGE_APP_ID = toString(
  import.meta.env["VITE_FIREBASE_CONTENT_STORAGE_APP_ID"],
);
const FIREBASE_CONTENT_STORAGE_BUCKET = toString(
  import.meta.env["VITE_FIREBASE_CONTENT_STORAGE_BUCKET"],
);
const FIREBASE_CONTENT_STORAGE_PROJECT_ID = toString(
  import.meta.env["VITE_FIREBASE_CONTENT_STORAGE_PROJECT_ID"],
);
const FIREBASE_CONTENT_STORAGE_IMMORTAL_BUCKET = toString(
  import.meta.env["VITE_FIREBASE_CONTENT_STORAGE_IMMORTAL_BUCKET"],
);

const FIREBASE_CONTENT_STORAGE_SENDER_ID = toString(
  import.meta.env["VITE_FIREBASE_CONTENT_STORAGE_SENDER_ID"],
);
const FIREBASE_CONTENT_STORAGE_VAPID_KEY = toString(
  import.meta.env["VITE_FIREBASE_CONTENT_STORAGE_VAPID_KEY"],
);

const FIREBASE_SHARED_API_KEY = toString(
  import.meta.env["VITE_FIREBASE_SHARED_API_KEY"],
);
const FIREBASE_SHARED_AUTH_DOMAIN = toString(
  import.meta.env["VITE_FIREBASE_SHARED_AUTH_DOMAIN"],
);
const FIREBASE_SHARED_PROJECT_ID = toString(
  import.meta.env["VITE_FIREBASE_SHARED_PROJECT_ID"],
);
const FIREBASE_SHARED_APP_ID = toString(
  import.meta.env["VITE_FIREBASE_SHARED_APP_ID"],
);

const FIREBASE_LOCALE_STORAGE_API_KEY = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_API_KEY"],
);

const FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN"],
);

const FIREBASE_LOCALE_STORAGE_PROJECT_ID = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_PROJECT_ID"],
);

const FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET"],
);

const FIREBASE_LOCALE_STORAGE_APP_ID = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_APP_ID"],
);

const FIREBASE_LOCALE_STORAGE_PATH_PREFIX = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_PATH_PREFIX"],
);

const FB_APP_ID = toString(import.meta.env["VITE_FB_APP_ID"]);
const FB_CURSOR_BASED_PAGINATION_LIMIT = integer()(
  import.meta.env["VITE_FB_CURSOR_BASED_PAGINATION_LIMIT"],
);

enum EnvEnum {
  "jp" = "jp",
  "production" = "production",
  "staging" = "staging",
}

const SITE = nativeEnum(EnvEnum, EnvEnum.staging)(import.meta.env["VITE_SITE"]);

const SENTRY_RELEASE = toString(import.meta.env["VITE_SENTRY_RELEASE"]);
const SENTRY_DSN = toString(import.meta.env["VITE_SENTRY_DSN"]);
const GA4_ID = toString(import.meta.env["VITE_GA4_ID"]);
const GTM_ID = toString(import.meta.env["VITE_GTM_ID"]);
const ZENDESK_KEY = toString(import.meta.env["VITE_ZENDESK_KEY"]);

const USERFLOW_TOKEN = toString(import.meta.env["VITE_USERFLOW_TOKEN"]);
const USERFLOW_USER_ID_PREFIX = toString(
  import.meta.env["VITE_USERFLOW_USER_ID_PREFIX"],
);
const USERFLOW_ATTRIBUTE_PREFIX = toString(
  import.meta.env["VITE_USERFLOW_ATTRIBUTE_PREFIX"],
);

const DEFAULT_QUERY_RETRY_MAX_COUNT = integer()(
  import.meta.env["VITE_DEFAULT_QUERY_RETRY_MAX_COUNT"],
);
const DEFAULT_UPLOAD_CONCURRENCY = integer()(
  import.meta.env["VITE_DEFAULT_UPLOAD_CONCURRENCY"],
);

export {
  ADMIN_CENTER_HOST,
  API_BULK_ACTION_LIMIT,
  API_DEFAULT_LIMIT,
  BASE_URL,
  CAAC_API_URL,
  CAAC_WEBSOCKET_URL,
  DEFAULT_QUERY_RETRY_MAX_COUNT,
  DEFAULT_UPLOAD_CONCURRENCY,
  DEVELOPMENT_MODE,
  ENV_TEST,
  FB_APP_ID,
  FB_CURSOR_BASED_PAGINATION_LIMIT,
  FIREBASE_CONTENT_STORAGE_API_KEY,
  FIREBASE_CONTENT_STORAGE_APP_ID,
  FIREBASE_CONTENT_STORAGE_BUCKET,
  FIREBASE_CONTENT_STORAGE_IMMORTAL_BUCKET,
  FIREBASE_CONTENT_STORAGE_PROJECT_ID,
  FIREBASE_CONTENT_STORAGE_SENDER_ID,
  FIREBASE_CONTENT_STORAGE_VAPID_KEY,
  FIREBASE_LOCALE_STORAGE_API_KEY,
  FIREBASE_LOCALE_STORAGE_APP_ID,
  FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN,
  FIREBASE_LOCALE_STORAGE_PATH_PREFIX,
  FIREBASE_LOCALE_STORAGE_PROJECT_ID,
  FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET,
  FIREBASE_SHARED_API_KEY,
  FIREBASE_SHARED_APP_ID,
  FIREBASE_SHARED_AUTH_DOMAIN,
  FIREBASE_SHARED_PROJECT_ID,
  GA4_ID,
  GTM_ID,
  MAAC_FORGET_PASSWORD_URL,
  SENTRY_DSN,
  SENTRY_RELEASE,
  SITE,
  TITLE,
  USERFLOW_ATTRIBUTE_PREFIX,
  USERFLOW_TOKEN,
  USERFLOW_USER_ID_PREFIX,
  ZENDESK_KEY,
};
