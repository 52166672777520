import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";

import type { CantataTypes } from "@/cantata/types";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { ReplyMessageById } from "@/routes/Chat/ui/ChatPanel/History/Messages/ReplyMessageById";
import { useReplyMessageController } from "@/routes/Chat/ui/replyMessage";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    gap: 4,
    padding: "8px 16px",
    borderRadius: 4,
    background: theme.colors.neutral001,
    justifyContent: "space-between",
  }),
  messageContent: css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "inherit",
    overflow: "hidden",
    justifyContent: "center",
  }),
});

declare namespace Quotation {
  export type Ref = ElementRef<"div">;
  export type Props = ComponentProps<"div"> & {
    messageId: CantataTypes["MessageDetail"]["id"];
  };
}

const Quotation = forwardRef<Quotation.Ref, Quotation.Props>(
  function Quotation(props, ref) {
    const replyMessageController = useReplyMessageController();

    const handleRemoveReplyMessage = useHandler<
      ComponentProps<typeof NarrowIconButton>["onClick"]
    >(() => {
      replyMessageController.removeReplyMessage();
    });

    return (
      <div css={styles.root} ref={ref} {...props}>
        <div css={styles.messageContent}>
          <ReplyMessageById messageId={props.messageId} />
        </div>
        <div>
          <NarrowIconButton
            size={28}
            iconSize={16}
            icon={<MotifIcon un-i-motif="cross" />}
            onClick={handleRemoveReplyMessage}
          />
        </div>
      </div>
    );
  },
);

assignDisplayName(Quotation, "Quotation");

export { Quotation };
