import { css } from "@emotion/react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { Alert } from "@/components/Alert";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { channelQueriesContext } from "@/queriesContext/channelQueriesContext";
import { ChannelNameEdit } from "@/routes/Settings/Channels/pages/Edit/ChannelNameEdit";
import { channelTypeTransMap } from "@/routes/Settings/Channels/pages/Edit/channelTypeTransMap";
import { DisconnectFacebookChannel } from "@/routes/Settings/Channels/pages/Edit/disconnect";
import { usePageInfo } from "@/routes/Settings/Channels/pages/Edit/pageInfo";
import { ReconnectFacebookChannel } from "@/routes/Settings/Channels/pages/Edit/reconnect";
import {
  cssBodyBold,
  cssBodyRegular,
  cssSection,
  cssSectionWrapper,
  cssWrapper,
} from "@/routes/Settings/Channels/pages/Edit/styles";

const EditChannel: FC = () => {
  const channel = channelQueriesContext.useChannel();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 24px;
      `}
    >
      <div css={cssSectionWrapper}>
        <ChannelNameEdit />
        {!(channel.type === "fb" || channel.type === "ig") ? null : (
          <>
            <div css={cssSection}>
              <div css={cssWrapper}>
                <span css={cssBodyBold}>
                  {channelTypeTransMap[channel.type].updateCredentialHeader}
                </span>
                {channel.status !== "token-expired" ? null : (
                  <Alert
                    type="error"
                    message={
                      channelTypeTransMap[channel.type]
                        .updateCredentialExpiredHeader
                    }
                    description={
                      channelTypeTransMap[channel.type]
                        .updateCredentialExpiredDescription
                    }
                  />
                )}
                <span css={cssBodyRegular}>
                  {
                    channelTypeTransMap[channel.type]
                      .updateCredentialDescription
                  }
                </span>
              </div>
              <div>
                <ReconnectFacebookChannel
                  channelId={channel.id}
                  channelType={channel.type}
                  disabled={channel.status === "disconnected"}
                >
                  <Trans i18nKey="resource.channel.update.credential.refresh.button.label" />
                </ReconnectFacebookChannel>
              </div>
            </div>
            <div css={cssSection}>
              <div css={cssWrapper}>
                <span css={cssBodyBold}>
                  <Trans i18nKey="resource.channel.update.connection.header" />
                </span>
                <span css={cssBodyRegular}>
                  {channelTypeTransMap[channel.type].disconnectDescription}
                </span>
              </div>
              <div>
                {channel.status !== "disconnected" ? (
                  <DisconnectFacebookChannel
                    channelId={channel.id}
                    channelType={channel.type}
                  />
                ) : (
                  <ReconnectFacebookChannel
                    channelId={channel.id}
                    channelType={channel.type}
                  >
                    <Trans i18nKey="resource.channel.update.connection.connect.button.label" />
                  </ReconnectFacebookChannel>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const BreadCrumbTitle: FC = () => {
  const channel = channelQueriesContext.useChannel();
  return channelTypeTransMap[channel.type].breadcrumbLabel;
};

const Title: FC = () => {
  const channel = channelQueriesContext.useChannel();
  return channelTypeTransMap[channel.type].header;
};

const WrappedEdit: FC = () => {
  const { t } = useTranslation();
  const pageInfo = usePageInfo();
  return (
    <SideMenuLayout.Layout>
      <ErrorBoundary.Alert>
        <channelQueriesContext.Provider channelId={pageInfo.channelId}>
          <MainLayout.Header
            breadcrumbItems={[
              {
                title: t("resource.channel.breadcrumb.channels.label"),
                to: "/settings/channels",
              },
              {
                title: <BreadCrumbTitle />,
              },
            ]}
          >
            <Title />
          </MainLayout.Header>
          <MainLayout.Body>
            <MainLayout.Content>
              <MainLayout.Section>
                <EditChannel />
              </MainLayout.Section>
            </MainLayout.Content>
          </MainLayout.Body>
        </channelQueriesContext.Provider>
      </ErrorBoundary.Alert>
    </SideMenuLayout.Layout>
  );
};

export { WrappedEdit as Edit };
