import { DownOutlined } from "@ant-design/icons";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { noop } from "lodash-es";
import type { ReactNode } from "react";
import { useMemo } from "react";

import type { CantataTypes } from "@/cantata/types";
import type { SelectProps, SelectRef } from "@/components/Select";
import { Select } from "@/components/Select";
import { ChannelTypeIcon } from "@/resources/channel/ChannelTypeIcon";
import { useSortChannels } from "@/resources/channel/useSortChannels";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  channelSelectBase: css([
    cssFlexInheritAndFill,
    {
      flex: "initial",
      flexFlow: "row nowrap",
      alignItems: "center",
      borderRadius: "0.25rem",
      gap: 8,
      "& .ant-select-selector": {
        padding: "4px 8px",
        "& .ant-select-selection-item": {
          paddingInlineEnd: "calc(12px + 8px)",
        },
      },
      "& .ant-select-selector::after": {
        lineHeight: "1em",
      },

      "&.ant-select-open, &.ant-select-focused, &:hover": {
        ".ant-select-selector": {
          borderColor: theme.colors.blue006,
          boxShadow: `0 0 2px 0 ${theme.colors.blue005}`,
        },
      },

      "&.ant-select-open, &.ant-select-focused": {
        ".ant-select-selector": {
          outline: `${theme.colors.primaryOutline} auto 1px`,
          outlineOffset: "3px",
        },
      },

      "&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input":
        {
          height: "auto",
        },
    },
  ]),
  channelName: css({
    overflow: "hidden",
    color: theme.colors.neutral009,
    textOverflow: "ellipsis",
  }),
  channelLabel: css({
    display: "flex",
    height: "24px",
    alignItems: "center",
    gap: "8px",
    fontSize: "0.875rem",
  }),
  hideBorder: css({
    "& .ant-select-selector": {
      border: "1px solid transparent",
    },
  }),
  downOutlined: css({
    color: theme.colors.neutral006,
    transition: "rotate 0.2s ease-in-out",
    ".ant-select-dropdown &": {
      display: "none",
    },
    ".ant-select-open &": {
      rotate: "-180deg",
    },
  }),
});

type SelectValue = CantataTypes["Channel"]["id"];

const emptyOptionValue = "empty";
const channelOptionValuePrefix = "channel-";
function selectedOptionValue(
  value: CantataTypes["Channel"]["id"],
): `${typeof channelOptionValuePrefix}${CantataTypes["Channel"]["id"]}` {
  return `${channelOptionValuePrefix}${value}`;
}

type InternalSelectValue =
  | typeof emptyOptionValue
  | `${typeof channelOptionValuePrefix}${CantataTypes["Channel"]["id"]}`;

type ChannelSelectProps = {
  channels: Array<CantataTypes["Channel"]>;
  emptyOption?: ReactNode;
  hideBorder?: boolean;
} & Omit<
  SelectProps<SelectValue>,
  "options" | "onSelect" | "onDeSelect" | "defaultValue"
>;

namespace ChannelSelect {
  export type SelectValue = CantataTypes["Channel"]["id"];
  export type Ref = SelectRef;
  export interface Props
    extends Overwrite<
      Omit<SelectProps<SelectValue>, "options" | "onSelect" | "onDeSelect">,
      {
        channels: Array<CantataTypes["Channel"]>;
        emptyOption?: ReactNode;
        hideBorder?: boolean;
      }
    > {}
}

const ChannelSelectInternal = forwardRef<ChannelSelect.Ref, ChannelSelectProps>(
  function ChannelSelect(
    {
      popupMatchSelectWidth = false,
      value,
      onChange,
      channels,
      hideBorder,
      emptyOption,
      ...props
    },
    ref,
  ) {
    const sortChannels = useSortChannels();
    const options = useMemo<SelectProps<SelectValue>["options"]>(
      function memoOptions() {
        const options: SelectProps<SelectValue>["options"] = sortChannels(
          channels,
        ).map((channel) => ({
          label: (
            <div css={styles.channelLabel}>
              <ChannelTypeIcon channel={channel} css={{ fontSize: "24px" }} />
              <span css={styles.channelName}>{channel.name}</span>
            </div>
          ),
          key: selectedOptionValue(channel.id),
          value: selectedOptionValue(channel.id),
        }));
        /**
         * If there is placeholder, we add it to the beginning of the options.
         */
        const placeholder:
          | null
          | NonNullable<SelectProps<SelectValue>["options"]>[number] =
          !emptyOption
            ? null
            : {
                label: (
                  <div css={styles.channelLabel}>
                    <span css={styles.channelName}>{emptyOption}</span>
                  </div>
                ),

                key: emptyOptionValue,
                value: emptyOptionValue,
              };
        return [...(!placeholder ? [] : [placeholder]), ...options];
      },
      [channels, emptyOption, sortChannels],
    );
    const cssMerged = useMemo(
      function mergeCss() {
        return css([
          styles.channelSelectBase,
          !hideBorder ? "" : styles.hideBorder,
        ]);
      },
      [hideBorder],
    );

    const suffixIcon = useMemo<ReactNode>(
      function memoSuffixIcon() {
        return "suffixIcon" in props ? (
          props.suffixIcon
        ) : (
          <DownOutlined css={styles.downOutlined} />
        );
      },
      [props],
    );

    const formatedValue = useMemo<undefined | InternalSelectValue>(
      function formatValue() {
        const formatted = !value
          ? emptyOptionValue
          : selectedOptionValue(value);
        if (formatted === emptyOptionValue && !emptyOption) {
          /**
           * If `emptyOption` is not provided, it should render the placeholder.
           */
          return undefined;
        }
        return formatted;
      },
      [emptyOption, value],
    );

    const changeHandler = useHandler<SelectProps<string>["onChange"]>(
      (v, option) => {
        if (!v || v === emptyOptionValue) {
          onChange?.(Number.NaN, option);
          return;
        }
        const channelId = Number(v.substring(channelOptionValuePrefix.length));
        onChange?.(channelId, option);
      },
    );

    return (
      <Select<InternalSelectValue>
        css={cssMerged}
        {...props}
        options={options}
        value={formatedValue}
        onChange={changeHandler}
        onSelect={changeHandler}
        onDeselect={noop}
        popupMatchSelectWidth={popupMatchSelectWidth}
        suffixIcon={suffixIcon}
        ref={ref}
      />
    );
  },
);

const ChannelSelect = Object.assign(ChannelSelectInternal, {
  emptyOptionValue,
  selectedOptionValue,
});

export { ChannelSelect };
