import { makeApi } from "@zodios/core";
import { z } from "zod";

const api = makeApi([
  {
    alias: "createCompletion",
    method: "post",
    path: "api/v1/orgs/:orgId/chat/ai/completion",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          memberId: z.number().int(),
          message: z.string(),
        }),
      },
    ],
    response: z.object({
      message: z.string(),
    }),
  },
]);

export { api };
