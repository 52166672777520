import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import { css, type CSSObject } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ElementType, ReactNode } from "react";
import { useMemo } from "react";

import { EMPTY_STRING_PLACEHOLDER, RANGE_STRING_JOINER } from "@/appConstant";
import type { YmdRange } from "@/components/DatePicker/utils";
import { MotifIcon } from "@/components/MotifIcon";
import {
  NumberFormat,
  type NumberFormatProps,
} from "@/components/NumberFormat";
import { Tooltip } from "@/components/Tooltip";
import { useFormatDate } from "@/resources/datetime";
import { defineStyles } from "@/shared/emotion";

type TrendSign = "positive" | "negative" | "zero";

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

interface TrendOwnProps {
  /**
   * The trend rate to display.
   *
   * 1 means 100% increase, -1 means 100% decrease.
   */
  trend?: number;

  /**
   * The current value to display.
   */
  valueCurrent?: number;

  /**
   * The previous value to compare against.
   */
  valuePrevious?: number;

  /**
   * The current date range to display.
   */
  rangeCurrent?: YmdRange;
  /**
   * The previous date range to compare against.
   */
  rangePrevious?: YmdRange;

  /**
   * Custom colors for the trend indicator.
   */
  trendColors?: {
    [key in TrendSign]?: CSSObject["color"];
  };

  /**
   * Props to pass to the tooltip number format.
   */
  tooltipNumberFormatProps?: Omit<NumberFormatProps, "value">;

  /**
   * Render function for tooltip values.
   */
  renderTooltipValue?: (value: number) => ReactNode;

  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface TrendTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & TrendOwnProps;
  defaultComponent: RootComponent;
}

type TrendProps<
  RootComponent extends ElementType = TrendTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = TrendOwnProps &
  OverrideProps<TrendTypeMap<AdditionalProps, RootComponent>, RootComponent> & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: css({
    display: "flex",
    alignItems: "center",
    gap: 2,
    whiteSpace: "nowrap",
  }),
  rootHasTooltip: css({
    cursor: "default",
  }),
  tooltipWrap: css({
    display: "grid",
    gridTemplateColumns: "minmax(150px, 2fr) minmax(50px, 1fr)",
    maxWidth: "480px",
    columnGap: 8,
  }),
  tooltipRange: css({
    fontVariantNumeric: "tabular-nums",
    whiteSpace: "nowrap",
  }),
  tooltipValue: css({
    fontWeight: 700,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  }),
});

const useFormatYmdRange = () => {
  const formatDate = useFormatDate();
  return useMemo(
    () => (range: YmdRange) =>
      range
        .map((date) => formatDate(date.localStartOfDay))
        .join(RANGE_STRING_JOINER),
    [formatDate],
  );
};

const DEFAULT_TREND_COLORS = define<TrendProps["trendColors"]>()({
  positive: theme.colors.blue006,
  negative: theme.colors.neutral007,
  zero: theme.colors.neutral007,
});

/**
 * A component to display a trend indicator.
 *
 * This component will render nothing if the trend is zero.
 *
 * The font size of the trend is not defined in the component, so it should be
 * set by the parent component.
 */
const Trend: OverridableComponent<TrendTypeMap> = forwardRef(function Trend(
  {
    trend,
    valueCurrent,
    valuePrevious,
    rangeCurrent,
    rangePrevious,
    trendColors,
    tooltipNumberFormatProps,
    renderTooltipValue,
    component: Component = "div" satisfies DefaultComponent,
    ...props
  }: TrendProps,
  ref: ElementRef<typeof Component>,
) {
  const formatYmdRange = useFormatYmdRange();
  const trendSign = useMemo<TrendSign>(
    function computeTrendSign() {
      if (trend === undefined || trend === 0) return "zero";

      return trend > 0 ? "positive" : "negative";
    },
    [trend],
  );

  const trendContents = useMemo(
    () =>
      !trend ? null : (
        <NumberFormat
          numberFormatPreset="percent"
          numberFormatOptions={{
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 4,
            notation: "compact",
            /** Trends are always rates but we use icons to display positive/negative instead of signs */
            signDisplay: "never",
          }}
          value={trend}
        />
      ),
    [trend],
  );

  const mergeTrendColors = useMemo(
    function mergeTrendColors() {
      return {
        ...DEFAULT_TREND_COLORS,
        ...trendColors,
      };
    },
    [trendColors],
  );

  const trendColor = mergeTrendColors[trendSign];
  const hasTooltip = rangeCurrent && rangePrevious;

  const mergedCss = useMemo<ComponentProps<typeof Component>["css"]>(
    () =>
      css([
        styles.root,
        hasTooltip && styles.rootHasTooltip,
        { color: trendColor },
      ]),
    [hasTooltip, trendColor],
  );

  return !trendContents ? null : (
    <Component css={mergedCss} ref={ref} {...props}>
      {trendSign === "zero" ? null : trendSign === "positive" ? (
        <MotifIcon un-i-motif="arrow_up_2" />
      ) : (
        <MotifIcon un-i-motif="arrow_down_2" />
      )}
      <Tooltip
        title={
          !hasTooltip ? (
            ""
          ) : (
            <div css={styles.tooltipWrap}>
              <div css={styles.tooltipRange}>
                {formatYmdRange(rangeCurrent)}
              </div>
              <div css={styles.tooltipValue}>
                {valueCurrent === undefined ? (
                  EMPTY_STRING_PLACEHOLDER
                ) : renderTooltipValue ? (
                  renderTooltipValue(valueCurrent)
                ) : (
                  <NumberFormat
                    {...tooltipNumberFormatProps}
                    value={valueCurrent}
                  />
                )}
              </div>
              <div css={styles.tooltipRange}>
                {formatYmdRange(rangePrevious)}
              </div>
              <div css={styles.tooltipValue}>
                {valuePrevious === undefined ? (
                  EMPTY_STRING_PLACEHOLDER
                ) : renderTooltipValue ? (
                  renderTooltipValue(valuePrevious)
                ) : (
                  <NumberFormat
                    {...tooltipNumberFormatProps}
                    value={valuePrevious}
                  />
                )}
              </div>
            </div>
          )
        }
        overlayStyle={{ maxWidth: 350 }}
      >
        {trendContents}
      </Tooltip>
    </Component>
  );
}) as OverridableComponent<TrendTypeMap>;

assignDisplayName(Trend, "Trend");

export { Trend };
export type { TrendOwnProps, TrendProps, TrendTypeMap };
