import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const EditOutlined = generateIcon(function EditOutlined() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8047 2.17643C12.6838 1.05252 10.7542 1.05252 9.63331 2.17643L1.33325 10.4986V14.6668H5.5046L13.8047 6.34464C14.9539 5.19232 14.9539 3.32872 13.8047 2.17643ZM4.90885 13.2441H2.7522V11.0818L8.75378 5.07842L10.9104 7.2408L4.90885 13.2441ZM11.9036 6.23075L12.7974 5.33451C13.3933 4.73703 13.3933 3.76967 12.7974 3.17221C12.2157 2.60314 11.2225 2.60314 10.6408 3.17221L9.74692 4.06845L11.9036 6.23075Z"
        fill="currentcolor"
      />
    </svg>
  );
});
