import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { ElementRef } from "react";

import enFirstResponseTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/en/firstResponseTime.svg";
import enResolutionTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/en/resolutionTime.svg";
import enTeamResolutionTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/en/teamResolutionTime.svg";
import enWaitTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/en/waitTime.svg";
import jpFirstResponseTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/jp/firstResponseTime.svg";
import jpResolutionTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/jp/resolutionTime.svg";
import jpTeamResolutionTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/jp/teamResolutionTime.svg";
import jpWaitTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/jp/waitTime.svg";
import thFirstResponseTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/th/firstResponseTime.svg";
import thResolutionTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/th/resolutionTime.svg";
import thTeamResolutionTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/th/teamResolutionTime.svg";
import thWaitTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/th/waitTime.svg";
import zhFirstResponseTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/zh/firstResponseTime.svg";
import zhResolutionTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/zh/resolutionTime.svg";
import zhTeamResolutionTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/zh/teamResolutionTime.svg";
import zhWaitTime from "@/routes/Insights/Efficiency/EfficiencyAverage/Diagram/images/zh/waitTime.svg";
import type { AvailableLanguage } from "@/shared/hooks/formatLangCode";
import { useLanguage } from "@/shared/hooks/useLanguage";

type DiagramType =
  | "firstResponseTime"
  | "resolutionTime"
  | "teamResolutionTime"
  | "waitTime";

const imageLangDiagramTypeMap = {
  "zh-hant": {
    firstResponseTime: zhFirstResponseTime,
    resolutionTime: zhResolutionTime,
    teamResolutionTime: zhTeamResolutionTime,
    waitTime: zhWaitTime,
  },
  en: {
    firstResponseTime: enFirstResponseTime,
    resolutionTime: enResolutionTime,
    teamResolutionTime: enTeamResolutionTime,
    waitTime: enWaitTime,
  },
  ja: {
    firstResponseTime: jpFirstResponseTime,
    resolutionTime: jpResolutionTime,
    teamResolutionTime: jpTeamResolutionTime,
    waitTime: jpWaitTime,
  },
  th: {
    firstResponseTime: thFirstResponseTime,
    resolutionTime: thResolutionTime,
    teamResolutionTime: thTeamResolutionTime,
    waitTime: thWaitTime,
  },
} satisfies Record<AvailableLanguage, Record<DiagramType, string>>;

type DiagramRef = ElementRef<"img">;
type DiagramProps = Omit<ComponentProps<"img">, "src" | "alt"> & {
  type: DiagramType;
};

const Diagram = forwardRef<DiagramRef, DiagramProps>(function Diagram(
  { type, ...props },
  ref,
) {
  const language = useLanguage();
  return (
    <img ref={ref} src={imageLangDiagramTypeMap[language][type]} {...props} />
  );
});

assignDisplayName(Diagram, "Diagram");

export { Diagram };
export type { DiagramProps, DiagramRef };
