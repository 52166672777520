import { memo } from "@chatbotgang/etude/react/memo";
import { useRegisterSW } from "virtual:pwa-register/react";
import { createStore } from "zustand";

import { logError } from "@/shared/application/logger/sentry";

const useSwStatus = createStore<
  | { status: "initial" | "registered"; error: undefined }
  | {
      status: "error";
      error: unknown;
    }
>(() => ({
  status: "initial",
  error: undefined,
}));

/**
 * Register the service worker.
 */
const Sw = memo(function Sw() {
  useRegisterSW({
    onRegistered(_r) {
      useSwStatus.setState({ status: "registered" });
    },
    onRegisterError(error) {
      logError(error);
      useSwStatus.setState({ status: "error", error });
    },
  });
  return null;
});

export { Sw, useSwStatus };
