import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type {
  ComponentProps,
  ComponentPropsWithRef,
} from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { getDisplayName } from "@chatbotgang/etude/react/getDisplayName";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import {
  eventToHotkeyString,
  install,
  type NormalizedHotkeyString,
  normalizeHotkey,
  uninstall,
} from "@github/hotkey";
import { isDisabled } from "@zeffiroso/utils/dom/isDisabled";
import { expandHotkeyToEdges } from "@zeffiroso/utils/github/hotkey/utils";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { secondsToMilliseconds } from "date-fns";
import { derive } from "derive-zustand";
import { flow, isEqual, uniq } from "lodash-es";
import {
  type ElementType,
  type ForwardRefRenderFunction,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { mergeRefs } from "react-merge-refs";
import type { NonNullable } from "ts-toolbelt/out/Union/NonNullable";
import { create } from "zustand";
import { createWithEqualityFn } from "zustand/traditional";

import { logError } from "@/shared/application/logger/sentry";

type HotKeyFireEvent = CustomEvent<{
  /**
   * Hotkeys
   */
  path: Array<string>;
}> & {
  type: "hotkey-fire";
};

type EnabledHotKeyFireEvent = HotKeyFireEvent & {
  target: HTMLElement;
  currentTarget: HTMLElement;
};

type WithHotKeyProps = {
  hotkey?: string;
  hotkeyScope?: string;
  /**
   * `hotkey-fire` event listener from `@github/hotkey`.
   */
  onHotkeyFire?: (e: HotKeyFireEvent) => void;
  /**
   * Similar to `onHotkeyFire` but only fires when hotkeys are enabled.
   * This is useful when we want to integrate the event with GA4.
   */
  onEnabledHotkeyFire?: (e: EnabledHotKeyFireEvent) => void;
};

type WithHotKey<TComponent extends ElementType> = (
  props: ComponentProps<TComponent> & WithHotKeyProps,
  ref?: ComponentPropsWithRef<TComponent>["ref"],
) => ReactNode;

const useEnabledHotkeysStore = create<boolean>()(() => true);
const toggleHotkeys = () => useEnabledHotkeysStore.setState((state) => !state);

const useHotkeyStore = createWithEqualityFn<{
  items: Array<{
    element: HTMLElement;
    hotkey: string;
    hotkeyScope?: string;
  }>;
}>()(
  () => ({
    items: [],
  }),
  shallow,
);

useHotkeyStore.subscribe(function logDuplicateHotkeys(state) {
  if (state.items.length === 0) return;
  const itemsWithEdges = state.items.map((item) => ({
    ...item,
    edges: expandHotkeyToEdges(item.hotkey),
  }));
  const duplicatedEdges: Array<{
    hotkeyScope?: string;
    sequence: Array<NormalizedHotkeyString>;
    duplicatedItems: typeof itemsWithEdges;
  }> = [];
  itemsWithEdges.forEach(function appendDuplicatedItems(item) {
    const hotkeyScope = item.hotkeyScope;
    const sequences = item.edges;
    sequences.forEach(function appendDuplicatedEdges(sequence) {
      if (
        duplicatedEdges.some(
          (duplicatedEdge) =>
            duplicatedEdge.hotkeyScope === hotkeyScope &&
            isEqual(duplicatedEdge.sequence, sequence),
        )
      )
        return;
      const duplicatedItems = itemsWithEdges.filter(
        (otherItem) =>
          hotkeyScope === otherItem.hotkeyScope &&
          otherItem.edges.some((otherSequence) =>
            isEqual(otherSequence, sequence),
          ),
      );
      if (duplicatedItems.length > 1) {
        duplicatedEdges.push({ hotkeyScope, sequence, duplicatedItems });
      }
    });
  });
  if (duplicatedEdges.length === 0) return;
  console.error("Duplicate hotkeys detected.", duplicatedEdges);
  const errorToLog = new Error(
    inspectMessage`Duplicate hotkeys detected. ${duplicatedEdges}`,
  );
  errorToLog.name = "DuplicateHotkeysError";
  logError(errorToLog);
});

/**
 * This HOC adds a `hotkey` prop to the component that will be used to install a
 * hotkey on the element based on
 * [@github/hotkey](https://github.com/github/hotkey).
 */
function withHotKey<TComponent extends ElementType>(Component: TComponent) {
  const name =
    typeof Component === "string" ? Component : getDisplayName(Component);
  const Render: ForwardRefRenderFunction<
    ElementType<TComponent>,
    WithHotKeyProps
  > = (
    { hotkey, hotkeyScope, onHotkeyFire, onEnabledHotkeyFire, ...props },
    ref,
  ) => {
    const hotkeyEnabled = useEnabledHotkeysStore();
    const [element, setElement] = useState<ElementType<TComponent> | null>(
      null,
    );
    /**
     * Prevent useEffect from re-creating the handler on every render.
     */
    const onHotkeyFireHandler = useHandler<NonNullable<typeof onHotkeyFire>>(
      (...args) => onHotkeyFire?.(...args),
    ) as (e: Event) => void;
    const onEnabledHotkeyFireHandler = useHandler<
      NonNullable<typeof onHotkeyFire>
    >((e, ...rest) => {
      if (
        !(e.target instanceof HTMLElement) ||
        !(e.currentTarget instanceof HTMLElement)
      )
        return;
      if (isDisabled(e.target)) return;
      onEnabledHotkeyFire?.(e as EnabledHotKeyFireEvent, ...rest);
    }) as (e: Event) => void;
    useEffect(
      function setupHotKey() {
        if (!element) return;
        if (!hotkey) return;
        if (!hotkeyEnabled) return;
        if (!(element instanceof HTMLElement)) return;
        install(element);
        element.addEventListener("hotkey-fire", onHotkeyFireHandler);
        element.addEventListener("hotkey-fire", onEnabledHotkeyFireHandler);
        useHotkeyStore.setState((state) => ({
          items: [...state.items, { element, hotkey, hotkeyScope }],
        }));
        return function cleanup() {
          useHotkeyStore.setState((state) => ({
            items: state.items.filter((item) => item.element !== element),
          }));
          element.removeEventListener("hotkey-fire", onHotkeyFireHandler);
          element.removeEventListener(
            "hotkey-fire",
            onEnabledHotkeyFireHandler,
          );
          uninstall(element);
        };
      },
      [
        element,
        hotkey,
        hotkeyEnabled,
        hotkeyScope,
        onEnabledHotkeyFireHandler,
        onHotkeyFireHandler,
      ],
    );
    const hotkeyProps = useMemo(
      () =>
        Object.assign(
          {},
          !hotkey ? {} : { "data-hotkey": hotkey },
          !hotkeyScope ? {} : { "data-hotkey-scope": hotkeyScope },
        ),
      [hotkey, hotkeyScope],
    );
    return (
      // @ts-expect-error -- I don't know how to fix this
      <Component
        {...hotkeyProps}
        {...props}
        ref={mergeRefs([ref, setElement])}
      />
    );
  };
  const WithHotKey = forwardRef(Render);
  assignDisplayName(WithHotKey, `withHotKey(${name})`);
  return WithHotKey as WithHotKey<TComponent>;
}

/**
 * Derive the normalized hotkey strings from the hotkey store.
 */
const useNormalizedHotkeyStringsStore = derive<Array<NormalizedHotkeyString>>(
  (get) =>
    flow(
      () =>
        get(useHotkeyStore).items.flatMap<NormalizedHotkeyString>((item) => {
          const hotkey = item.hotkey;
          return expandHotkeyToEdges(hotkey).flatMap<NormalizedHotkeyString>(
            (sequence) =>
              sequence.flatMap<NormalizedHotkeyString>((hotkey) => hotkey),
          );
        }),
      uniq,
    )(),
);

/**
 * Double press `Mod+Shift+Z` to toggle hotkeys.
 */
const toggleHotkeysHotkey = normalizeHotkey("Mod+Shift+Z");
useHotkeyStore.setState((state) => ({
  items: [
    ...state.items,
    {
      element: document.documentElement,
      hotkey: toggleHotkeysHotkey,
    },
  ],
}));

/**
 * Timeout duration to toggle hotkeys.
 */
const toggleHotkeysTimeoutDurationMs = secondsToMilliseconds(1);
let toggleHotkeysTimeout: ReturnType<typeof setTimeout> | null = null;
function clearToggleHotkeysTimeout() {
  if (!toggleHotkeysTimeout) return;
  clearTimeout(toggleHotkeysTimeout);
  toggleHotkeysTimeout = null;
}
function resetToggleHotkeysTimeout() {
  clearToggleHotkeysTimeout();
  toggleHotkeysTimeout = setTimeout(
    clearToggleHotkeysTimeout,
    toggleHotkeysTimeoutDurationMs,
  );
}

window.addEventListener("keydown", function preventDefaultForHotKeys(e) {
  const hotkeyString = eventToHotkeyString(e);
  if (hotkeyString === toggleHotkeysHotkey) {
    if (toggleHotkeysTimeout) {
      clearToggleHotkeysTimeout();
      toggleHotkeys();
      return;
    }
    resetToggleHotkeysTimeout();
  }
  const hotkeys = useNormalizedHotkeyStringsStore.getState();
  const hotkeysEnabled = useEnabledHotkeysStore.getState();
  /**
   * Prevent default for hotkeys.
   */
  if (hotkeysEnabled && hotkeys.includes(hotkeyString)) {
    e.preventDefault();
  }
});

export { withHotKey };
export type { WithHotKey, WithHotKeyProps };
