import { featureFlags } from "@/config/featureFlags";
import type {
  BaseDividerConfig,
  BaseSingleSelectFeatureFlagConfig,
  BaseToggleFeatureFlagConfig,
} from "@/internal/featureFlag/baseTypes";
import type { defineConfig } from "@/internal/featureFlag/defineConfig";

/**
 * Instead of using `featureFlags` for common features, use this approach to
 * simplify type debugging. This is especially helpful when certain properties
 * or types are unused, resulting in them being of type `never`.
 */
const commonConfig = featureFlags as unknown as ReturnType<
  typeof defineConfig<{
    __divider: BaseDividerConfig;
    __toggle: BaseToggleFeatureFlagConfig;
    __singleSelect: BaseSingleSelectFeatureFlagConfig;
  }>
>;

export { commonConfig };
