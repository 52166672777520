import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import type { Overwrite } from "@mui/types";
import type { Arrayable } from "@zeffiroso/utils/type/array/Arrayable";
import type { TooltipProps as AntTooltipProps } from "antd";
// eslint-disable-next-line no-restricted-imports -- Extends from antd.
import { Tooltip as AntTooltip } from "antd";
import { flow, uniq } from "lodash-es";
import { type ElementRef, useMemo } from "react";

import { useIsTouchOnlyLikeDeviceStore } from "@/app/browser/useIsTouchOnlyLikeDeviceStore";

const originalTriggers = define<Array<Tooltip.OriginalTrigger>>()([
  "hover",
  "focus",
  "click",
  "contextMenu",
]);
const externalTriggers = define<Array<string>>()(["info"]);
const triggers = [...originalTriggers, ...externalTriggers];

namespace Tooltip {
  export type ExternalTrigger = (typeof externalTriggers)[number];
  export type OriginalTrigger = Extract<AntTooltipProps["trigger"], string>;
  export type Trigger = (typeof triggers)[number];
  export type Props = Overwrite<
    AntTooltipProps,
    {
      /**
       * The trigger types for the tooltip. If the trigger type is "info", the
       * tooltip will trigger on hover for non-touch devices and on click for touch devices.
       * On touch devices, all trigger types except "click" will be ignored.
       */
      trigger?: Arrayable<Trigger>;
    }
  >;
  export type Ref = ElementRef<typeof AntTooltip>;
}

const TooltipInternal = forwardRef<Tooltip.Ref, Tooltip.Props>(
  function TooltipInternal({ trigger = "hover", ...props }, ref) {
    const isTouchOnlyLikeDevice = useIsTouchOnlyLikeDeviceStore();
    const triggerProps = useMemo<Pick<AntTooltipProps, "trigger">>(() => {
      const triggerArray = Array.isArray(trigger) ? trigger : [trigger];
      const optimizedTrigger = flow(
        () =>
          triggerArray.flatMap<Tooltip.OriginalTrigger>((trigger) => {
            if (trigger === "info") {
              return [isTouchOnlyLikeDevice ? "click" : "hover"];
            }
            if (isTouchOnlyLikeDevice && trigger !== "click") {
              return [];
            }
            return [trigger];
          }),
        (triggers) => uniq(triggers),
      )();
      return {
        trigger: optimizedTrigger,
      };
    }, [isTouchOnlyLikeDevice, trigger]);
    return <AntTooltip {...props} {...triggerProps} ref={ref} />;
  },
);

/**
 * A wrapper around the antd Tooltip component that automatically disables the
 * tooltip when the user is on a touch device by default.
 *
 * There is an additional trigger type called "info" that will only trigger on
 * hover on non-touch devices and on click on touch devices.
 */
const Tooltip = Object.assign(TooltipInternal, {
  originalTriggers,
  externalTriggers,
  triggers,
});

export { Tooltip };
