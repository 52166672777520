import { createContext } from "@chatbotgang/etude/react/createContext";

import type { setupConversationHistoryMessagesController } from "@/routes/Chat/ui/conversationHistoryMessages/setupConversationHistoryMessagesController";

const Context = createContext<
  ReturnType<typeof setupConversationHistoryMessagesController>
>({
  name: "ConversationHistoryMessages",
});

export { Context };
