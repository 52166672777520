import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { NotificationSettings } from "@/routes/Settings/Notification/NotificationSettings";

const Notification: FC = () => {
  const { t } = useTranslation();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header>{t("notification.setting.title")}</MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <MainLayout.Section>
            <NotificationSettings />
          </MainLayout.Section>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
};

export { Notification };
