import { GTM_ID } from "@zeffiroso/env";
import TagManager from "react-gtm-module";

if (import.meta.env.PROD) {
  /**
   * Google Tag Manager setup
   */
  TagManager.initialize({
    gtmId: GTM_ID,
  });
}
