import { number } from "@chatbotgang/etude/pitch-shifter/number";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useActiveOrgIdStore } from "@/activeOrgId/store";

function usePageInfo() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const params = useParams();
  const channelId = (function getChannelId() {
    const param = params["channelId"];
    return !param ? Number.NaN : number()(param);
  })();
  const pageInfo = useMemo(
    () => ({
      orgId,
      channelId,
    }),
    [channelId, orgId],
  );
  return pageInfo;
}

export { usePageInfo };
