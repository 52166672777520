import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useEffect } from "react";

import type { CantataTypes } from "@/cantata/types";
import type { MutationErrorEvents } from "@/lib/react-query/mutationErrorEventEmitter";
import { mutationErrorEventEmitter } from "@/lib/react-query/mutationErrorEventEmitter";
import { useHandleErrorAndShowMessage } from "@/shared/application/error/handleError";
import type { NonGlobalApiError } from "@/shared/domains/error";

/**
 * This is a global error handler for all mutation errors. Do not use this
 * component in subcomponents.
 */
const MutationErrorEmitter = memo(function MutationErrorEmitter() {
  const handleErrorAndShowMessage = useHandleErrorAndShowMessage();
  const handler = useHandler<MutationErrorEvents["error"]>(
    async function handleError(err) {
      handleErrorAndShowMessage(err);
    },
  );
  useEffect(
    function bindMutationErrorEvent() {
      mutationErrorEventEmitter.on("error", handler);
      return function cleanup() {
        mutationErrorEventEmitter.off("error", handler);
      };
    },
    [handler],
  );
  return null;
});

type ErrorName = CantataTypes["Error"]["name"];
type GetErrorObjectByName<CurrentErrorName extends ErrorName> =
  CantataTypes["Error"] & {
    name: CurrentErrorName;
  };

/**
 * To listen any mutation error globally.
 *
 * In most case, you should use `useNonGlobalMutationError` instead.
 *
 * @see {@link useNonGlobalMutationError}
 */
function useMutationError<CurrentErrorName extends ErrorName>(
  errorName: CurrentErrorName,
  handler: (err: GetErrorObjectByName<CurrentErrorName>) => void,
): void {
  const fn = useHandler<MutationErrorEvents["knownError"]>((err) => {
    if (err.name !== errorName) return;
    handler(err as GetErrorObjectByName<CurrentErrorName>);
  });
  useEffect(
    function bindMutationErrorEvent() {
      mutationErrorEventEmitter.on("knownError", fn);
      return function cleanup() {
        mutationErrorEventEmitter.off("knownError", fn);
      };
    },
    [fn],
  );
}

/**
 * To listen a specific mutation error which is not handle globally.
 */
function useNonGlobalMutationError<CurrentErrorName extends NonGlobalApiError>(
  errorName: CurrentErrorName,
  handler: (err: GetErrorObjectByName<CurrentErrorName>) => void,
): void {
  useMutationError(errorName, handler);
}

export { MutationErrorEmitter, useMutationError, useNonGlobalMutationError };
