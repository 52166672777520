import { random } from "@chatbotgang/etude/string/random";
import {
  type QueryKey,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";
import type { QRCodeSegment, QRCodeToDataURLOptions } from "qrcode";
import { toDataURL } from "qrcode";
import type { Tagged } from "type-fest";

type TaggedQrCodeDataUrlQueryKeyBase = string;
type TaggedQrCodeDataUrlQueryKey = Tagged<
  TaggedQrCodeDataUrlQueryKeyBase,
  "qrcode"
>;
const uniqKey =
  `qrcode-${random()}` satisfies TaggedQrCodeDataUrlQueryKeyBase as TaggedQrCodeDataUrlQueryKey;

/**
 * text: string | QRCodeSegment[], options?: QRCodeToDataURLOptions
 */
function generateQrCodeQueryKey({
  text,
  options,
}: {
  text: string | QRCodeSegment[];
  options?: QRCodeToDataURLOptions;
}) {
  return [
    {
      ...{
        [uniqKey]: uniqKey,
      },
      ...{
        qrcodeToDataUrlParams: { text, options },
      },
    },
  ] as const satisfies QueryKey;
}

namespace useQrCodeDataUrlQuery {
  export type QueryFnData = string;
  export type Error = unknown;
  export type QueryKey = ReturnType<typeof generateQrCodeQueryKey>;
  export interface Options<TData = QueryFnData> {
    text: string | QRCodeSegment[];
    options?: QRCodeToDataURLOptions;
    queryOptions?: Omit<
      UseQueryOptions<QueryFnData, Error, TData, QueryKey>,
      "queryKey" | "queryFn" | "initialData"
    >;
  }
}

function useQrCodeDataUrlQuery<TData = useQrCodeDataUrlQuery.QueryFnData>({
  text,
  options,
  queryOptions,
}: useQrCodeDataUrlQuery.Options<TData>) {
  const query = useQuery<
    useQrCodeDataUrlQuery.QueryFnData,
    useQrCodeDataUrlQuery.Error,
    TData,
    useQrCodeDataUrlQuery.QueryKey
  >(
    generateQrCodeQueryKey({ text, options }),
    async ({ queryKey: [{ qrcodeToDataUrlParams }] }) =>
      toDataURL(qrcodeToDataUrlParams.text, qrcodeToDataUrlParams.options),
    queryOptions,
  );
  return query;
}

export { useQrCodeDataUrlQuery };
