import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { type ElementRef, useMemo } from "react";

import { Trans } from "@/app/i18n/Trans";
import { FileList } from "@/components/FileDropZone/FileList";
import { MotifIcon } from "@/components/MotifIcon";
import { defineStyles } from "@/shared/emotion";

const seed = random();

const classNames = {
  primary: `primary-${seed}`,
};

const styles = defineStyles({
  DropZone: css({
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.neutral009,
    fontSize: "0.875rem",
    fontWeight: 500,
    gap: "24px",
    textAlign: "center",
    pointerEvents: "none",
    maxWidth: "100%",
    [`& .${classNames.primary}`]: {
      color: theme.colors.primary,
    },
  }),
  icon: css({
    fontSize: "40px",
  }),
});

const extendedStyles = defineStyles({
  emptyIcon: css([
    styles.icon,
    {
      color: theme.colors.neutral006,
    },
  ]),
});

namespace DropZone {
  export type Ref = ElementRef<"div">;
  export interface OwnProps {
    file?: File | Array<File>;
  }
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const DropZoneInternal = forwardRef<DropZone.Ref, DropZone.Props>(
  function DropZoneInternal({ file, ...props }, ref) {
    const defaultChildren = useMemo(
      () => (
        <>
          <MotifIcon css={extendedStyles.emptyIcon} un-i-motif="file_add" />
          <div>
            <Trans
              i18nKey="component.fileDropZone.action.selectFile.label"
              components={{
                Primary: <span className={classNames.primary} />,
              }}
            />
          </div>
        </>
      ),
      [],
    );
    const filesContent = useMemo(() => {
      const fileArray: Array<File> = Array.isArray(file)
        ? file
        : !file
          ? []
          : [file];
      if (fileArray.length === 0) return null;
      return (
        <>
          <MotifIcon css={styles.icon} un-i-motif="file" />
          <FileList>
            {fileArray.map((file, index) => (
              <FileList.Item key={index} file={file} />
            ))}
          </FileList>
          <div className={classNames.primary}>
            <Trans i18nKey="component.fileDropZone.action.selectAnotherFile.label" />
          </div>
        </>
      );
    }, [file]);
    return (
      <div
        css={styles.DropZone}
        ref={ref}
        {...{ children: filesContent || defaultChildren }}
        {...props}
      />
    );
  },
);

/**
 * The drop area content.
 */
const DropZone = Object.assign(DropZoneInternal, {
  styles,
});

assignDisplayName(DropZone, "DropZone");

export { DropZone };
