import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { flow } from "lodash-es";
import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { Line } from "@/components/antdPlots/Line";
import { tickFilters } from "@/components/antdPlots/utils";
import { Card } from "@/components/Card";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useFormatDate } from "@/resources/datetime";
import { DateIntervalSelect } from "@/routes/Insights/components/DateIntervalSelect";
import { usePageInfoUtil } from "@/routes/Insights/Contacts/pageInfo";
import { memberDetailQueriesContext } from "@/routes/Insights/Contacts/queriesContext/memberDetail";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  name: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral009,
  }),
});

const MemberDetailLineChart: FC = () => {
  const formatDate = useFormatDate();
  const { t } = useTranslation();
  const data = memberDetailQueriesContext.useData();
  const pageInfoUtil = usePageInfoUtil();
  const memberDetailInterval = pageInfoUtil.data.membersInterval;
  const countMetrics = useMemo(() => {
    return flow(
      () => [
        ...data.new.countMetrics.map((item) => ({
          date: item.date,
          value: item.value,
          label: t("dashboard.contacts.contact.newContact.label"),
        })),
        ...data.handled.countMetrics.map((item) => ({
          date: item.date,
          value: item.value,
          label: t("dashboard.contacts.contact.handledContact.label"),
        })),
        ...data.newGroup.countMetrics.map((item) => ({
          date: item.date,
          value: item.value,
          label: t("dashboard.contacts.contact.newGroup.label"),
        })),
        ...data.handledGroup.countMetrics.map((item) => ({
          date: item.date,
          value: item.value,
          label: t("dashboard.contacts.contact.handledGroup.label"),
        })),
      ],
      function addDateRange(data) {
        return data.map((item) => ({
          ...item,

          dateRange: [
            item.date,
            memberDetailInterval === "daily"
              ? item.date
              : memberDetailInterval === "weekly"
                ? item.date.addWeeks(1).addDays(-1)
                : memberDetailInterval === "monthly"
                  ? item.date.addMonths(1).addDays(-1)
                  : (() => {
                      memberDetailInterval satisfies never;
                      throw new Error(
                        inspectMessage`Unexpected memberDetailInterval: ${memberDetailInterval}`,
                      );
                    })(),
          ],
        }));
      },
      Ymd.ymdToDateDeep,
    )();
  }, [
    data.handled.countMetrics,
    data.handledGroup.countMetrics,
    data.new.countMetrics,
    data.newGroup.countMetrics,
    memberDetailInterval,
    t,
  ]);

  return (
    <Line
      data={countMetrics}
      xField={(item) => formatDate(item.date)}
      yField="value"
      colorField="label"
      scale={{
        color: {
          range: [
            theme.colors.blue006,
            theme.colors.blue004,
            theme.colors.green006,
            theme.colors.green004,
          ],
        },
      }}
      axis={{
        y: {
          tickFilter: tickFilters.intergerOnly,
        },
      }}
      tooltip={{
        title: {
          field: "dateRange",
        },
      }}
    />
  );
};

export const MemberDetailLine: FC = () => {
  const pageInfoUtil = usePageInfoUtil();
  const filterIntervalByRange = DateIntervalSelect.useFilterIntervalByRange({
    startTime: pageInfoUtil.data.startTime,
    endTime: pageInfoUtil.data.endTime,
  });
  const handleIntervalChange = useHandler<
    ComponentProps<typeof DateIntervalSelect>["onChange"]
  >(function handleMembersIntervalChanged(value) {
    pageInfoUtil.update({
      membersInterval: value,
    });
  });

  return (
    <Card>
      <div css={styles.name}>
        <Trans
          i18nKey="dashboard.contacts.contact.chart.selectRange"
          components={{
            SelectRange: (
              <DateIntervalSelect
                value={pageInfoUtil.data.membersInterval}
                onChange={handleIntervalChange}
                filter={filterIntervalByRange}
              />
            ),
          }}
        />
      </div>
      <Line.Wrapper>
        <ErrorBoundary.Alert fullSize>
          <memberDetailQueriesContext.Provider>
            <MemberDetailLineChart />
          </memberDetailQueriesContext.Provider>
        </ErrorBoundary.Alert>
      </Line.Wrapper>
    </Card>
  );
};
