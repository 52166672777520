import { MemberSchema } from "@zeffiroso/cantata/models";
import { Iso8601HighResDateSchema } from "@zeffiroso/utils/zod/Iso8601HighResDateSchema";
import { z } from "zod";

/**
 * - Spec: [Notion](https://www.notion.so/cresclab/Real-Time-Web-Notification-7643cb44c55249eea3c1b7f6b27bde7a?pvs=4#487579483486401d840675ccc7307fb9)
 */
export const GroupRejoinedSchema = z.object({
  type: z.literal("group-rejoined"),
  content: z.object({
    idempotencyKey: z.string(),
    groupId: MemberSchema.shape.id,
  }),
  triggerTime: Iso8601HighResDateSchema,
});
