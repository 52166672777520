import type { CantataTypes } from "@/cantata/types";

type AutoAssignmentRuleConditionRequest =
  | {
      category: "all-messages";
      operator: "none";
    }
  | {
      category: "keywords";
      operator: "contain-one-of" | "contain-all-of";
      keywords: Array<Pick<CantataTypes["Keyword"], "id">>;
    }
  | {
      category: "tags";
      operator: "contain-one-of" | "contain-all-of";
      tags: Array<Pick<CantataTypes["Tag"], "id">>;
    };

type AutoAssignmentRuleRequest = {
  name: string;
  status: "on" | "off";
  assignee: Exclude<
    CantataTypes["AutoAssignmentRule"]["assignee"],
    /**
     * Cannot set null to assignee.
     */
    { type: null }
  >;
  conditions: AutoAssignmentRuleConditionRequest[];
};

type FormValues = {
  name: string;
  status: "on" | "off";
  assignee: CantataTypes["AutoAssignmentRule"]["assignee"] | null;
  conditions: ((
    | AutoAssignmentRuleConditionRequest
    | UnselectedAutoAssignmentRuleCondition
  ) & {
    key: string;
  })[];
};

type UnselectedAutoAssignmentRuleCondition = {
  category: null;
};

function allSelectedAutoAssignmentRuleConditions(
  conditions: FormValues["conditions"],
): conditions is (AutoAssignmentRuleConditionRequest & {
  key: string;
})[] {
  return conditions.every((condition) => condition.category !== null);
}

export { allSelectedAutoAssignmentRuleConditions };

export type {
  AutoAssignmentRuleConditionRequest,
  AutoAssignmentRuleRequest,
  FormValues,
};
