/**
 * Legacy.
 *
 * This is for fetch aborting.
 */
function isAbortError(error: unknown): error is DOMException & {
  name: "AbortError";
} {
  return error instanceof DOMException && error.name === "AbortError";
}

export { isAbortError };
