import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { isAxiosError } from "axios";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { Markdown } from "@/components/Markdown";
import { Modal } from "@/components/Modal";
import { ResultList } from "@/components/ResultList";
import { defineStyles } from "@/shared/emotion";
import { DeleteOutlined as DeleteSvg } from "@/shared/icons/common/DeleteOutlined";

const styles = defineStyles({
  userDeleteModal: css({
    "& .ant-modal-body": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 16,
      "&>*": {
        width: "100%",
      },
    },
  }),
  desc: css({
    fontSize: "1rem",
  }),
  affectedAutoAssignmentRules: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 8,
    fontSize: "0.875rem",
    "&>*": {
      width: "100%",
      paddingLeft: 20,
      display: "flex",
      alignItems: "center",
      gap: 12,
      "&::before": {
        content: "''",
        width: 4,
        height: 20,
        backgroundColor: theme.colors.yellow006,
      },
    },
  }),
});

namespace DeleteUser {
  export interface Props {
    userId: CantataTypes["User"]["id"];
  }
}

const DeleteUser: FC<{
  userId: CantataTypes["User"]["id"];
  userName: CantataTypes["User"]["name"];
}> = ({ userId, userName }) => {
  const { t } = useTranslation();
  const [open, toggle] = useSwitch(false);
  const orgId = useActiveOrgIdStore((state) => state.value);

  const deleteMutation = cantata.user.useDeleteById(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      onSuccess: toggle.off,
      onError: (error) => {
        if (isAxiosError(error) && error.status === 404) {
          toggle.off();
        }
      },
    },
  );

  const assignmentRulesQuery = cantata.autoAssignment.useList(
    { params: { orgId } },
    {
      enabled: open,
    },
  );

  const affectedAutoAssignmentRules = useMemo(() => {
    return !assignmentRulesQuery.isSuccess
      ? []
      : assignmentRulesQuery.data.autoAssignmentRules.filter(
          (rule) =>
            rule.assignee.type === "an-agent" &&
            rule.assignee.userId === userId,
        );
  }, [
    assignmentRulesQuery.data?.autoAssignmentRules,
    assignmentRulesQuery.isSuccess,
    userId,
  ]);
  return (
    <>
      <NarrowIconButton
        size="middle"
        data-test="user-delete-icon"
        onClick={toggle.on}
        icon={<DeleteSvg />}
      />
      <Modal
        css={styles.userDeleteModal}
        open={open}
        onCancel={toggle.off}
        cancelText={t("common.cancel")}
        okText={t("common.delete")}
        title={t("organization.deleteUser.modal.title")}
        onOk={() => deleteMutation.mutate(undefined)}
        okButtonProps={{
          danger: true,
          loading: deleteMutation.isLoading,
        }}
      >
        <Markdown
          css={styles.desc}
          markdown={t("organization.quitAssignment.desc", { userName })}
        />
        <div>
          {assignmentRulesQuery.isLoading ? (
            <BarLoadingBlock />
          ) : assignmentRulesQuery.isError ? (
            <Alert
              type="error"
              message={inspectMessage`query error: ${assignmentRulesQuery.error}`}
            />
          ) : affectedAutoAssignmentRules.length === 0 ? null : (
            <ResultList>
              {affectedAutoAssignmentRules.map((rule) => (
                <ResultList.Item key={rule.id} color="warning">
                  {t(
                    "team.listPage.deleteTeam.affectedRoutingRule.list.item.desc",
                    {
                      routingRuleName: rule.name,
                    },
                  )}
                </ResultList.Item>
              ))}
            </ResultList>
          )}
        </div>
      </Modal>
    </>
  );
};

export { DeleteUser };
