import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import type { MutationOptions, UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FB_APP_ID } from "@zeffiroso/env";
import axios from "axios";
import { z } from "zod";
import { createWithEqualityFn } from "zustand/traditional";

const FbSdkErrSchema = z.object({
  error: z.object({
    code: z.number(),
    fbtrace_id: z.string(),
    message: z.string(),
    type: z.string(),
  }),
});

type FbSdkErr = z.infer<typeof FbSdkErrSchema>;

const FbLoginStatusSchema = z.enum([
  "authorization_expired",
  "connected",
  "not_authorized",
  "unknown",
]);

const AuthResponseSchema = z.object({
  accessToken: z.string().optional(),
  data_access_expiration_time: z.number().optional(),
  expiresIn: z.number(),
  signedRequest: z.string().optional(),
  userID: z.string(),
  grantedScopes: z.string().optional(),
  reauthorize_required_in: z.number().optional(),
  code: z.string().optional(),
});

const StatusResponseSchema = z.object({
  status: FbLoginStatusSchema,
  authResponse: AuthResponseSchema.nullable(),
});

const UserSchema = z.object({
  id: z.string(),
  name: z.string(),
  picture: z
    .object({
      data: z.object({
        height: z.number(),
        is_silhouette: z.boolean(),
        url: z.string(),
        width: z.number(),
      }),
    })
    .optional(),
});

const InstagramUserSchema = UserSchema.extend({
  name: z.string().optional(),
  username: z.string(),
  profile_picture_url: z.string().optional(),
});

/**
 * https://developers.facebook.com/docs/graph-api/results#cursors
 */
function CursorBasedPaginationSchema<D extends z.ZodType>(DataSchema: D) {
  return z.object({
    data: z.array(DataSchema),
    paging: z
      .object({
        previous: z.string().optional(),
        next: z.string().optional(),
      })
      .optional(),
  });
}

const AccountSchema = UserSchema.extend({
  access_token: z.string(),
  instagram_business_account: z
    .object({
      id: z.string(),
    })
    .optional(),
});

const useFbSdkLoadedStore = createWithEqualityFn<boolean>()(
  () => "FB" in window,
);
function useFbSdkLoaded() {
  return useFbSdkLoadedStore();
}
window.fbAsyncInit = function fbAsyncInit() {
  useFbSdkLoadedStore.setState(true);
};

/**
 * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/451dc8f/types/facebook-js-sdk/index.d.ts#L94
 */
type Me<TParam extends facebook.UserField> = {
  id: number;
  about?: TParam extends "about" ? string : never | undefined | undefined;
  age_range?: TParam extends "age_range"
    ? facebook.AgeRange
    : never | undefined | undefined;
  birthday?: TParam extends "birthday" ? string : never | undefined | undefined;
  education?: TParam extends "education"
    ? facebook.EducationExperience[]
    : never | undefined | undefined;
  email?: TParam extends "email" ? string : never | undefined | undefined;
  favorite_athletes?: TParam extends "favorite_athletes"
    ? facebook.Experience[]
    : never | undefined | undefined;
  favorite_teams?: TParam extends "favorite_teams"
    ? facebook.Experience[]
    : never | undefined | undefined;
  first_name?: TParam extends "first_name"
    ? string
    : never | undefined | undefined;
  gender?: TParam extends "gender" ? string : never | undefined | undefined;
  hometown?: TParam extends "hometown"
    ? facebook.Page
    : never | undefined | undefined;
  inspirational_people?: TParam extends "inspirational_people"
    ? facebook.Experience[]
    : never | undefined | undefined;
  install_type?: TParam extends "install_type"
    ? unknown
    : never | undefined | undefined;
  is_guest_user?: TParam extends "is_guest_user"
    ? boolean
    : never | undefined | undefined;
  languages?: TParam extends "languages"
    ? facebook.Experience[]
    : never | undefined | undefined;
  last_name?: TParam extends "last_name"
    ? string
    : never | undefined | undefined;
  link?: TParam extends "link" ? string : never | undefined | undefined;
  location?: TParam extends "location"
    ? facebook.Page
    : never | undefined | undefined;
  meeting_for?: TParam extends "meeting_for"
    ? string[]
    : never | undefined | undefined;
  middle_name?: TParam extends "middle_name"
    ? string
    : never | undefined | undefined;
  name?: TParam extends "name" ? string : never | undefined | undefined;
  name_format?: TParam extends "name_format"
    ? string
    : never | undefined | undefined;
  payment_pricepoints?: TParam extends "payment_pricepoints"
    ? facebook.PaymentPricepoints
    : never | undefined | undefined;
  name_political?: TParam extends "political"
    ? string
    : never | undefined | undefined;
  profile_pic?: TParam extends "profile_pic"
    ? string
    : never | undefined | undefined;
  quotes?: TParam extends "quotes" ? string : never | undefined | undefined;
  relationship_status?: TParam extends "relationship_status"
    ? string
    : never | undefined | undefined;
  religion?: TParam extends "religion" ? string : never | undefined | undefined;
  shared_login_upgrade_required_by?: TParam extends "shared_login_upgrade_required_by"
    ? unknown
    : never | undefined | undefined;
  short_name?: TParam extends "short_name"
    ? unknown
    : never | undefined | undefined;
  significant_other?: TParam extends "significant_other"
    ? facebook.User
    : never | undefined | undefined;
  sports?: TParam extends "sports"
    ? facebook.Experience[]
    : never | undefined | undefined;
  supports_donate_button_in_live_video?: TParam extends "supports_donate_button_in_live_video"
    ? boolean
    : never | undefined | undefined;
  token_for_business?: TParam extends "token_for_business"
    ? facebook.VideoUploadLimits
    : never | undefined | undefined;
  video_upload_limits?: TParam extends "video_upload_limits"
    ? string
    : never | undefined | undefined;
  website?: TParam extends "website" ? string : never | undefined | undefined;
};

type Account = {
  access_token: string;
  id: string;
  name: string;
  instagram_business_account?: {
    id: string;
  };
};

function createFbSdk({
  logError,
  cursorBasedPaginationLimit,
}: {
  logError?: (err: Error) => void;
  cursorBasedPaginationLimit?: number;
} = {}) {
  const fbSdk = {
    ...(() => {
      const api = {
        async login({
          loginOptions,
          signal,
        }: {
          loginOptions?: facebook.LoginOptions;
          signal?: AbortSignal;
        }) {
          return new Promise<z.infer<typeof StatusResponseSchema>>(
            (resolve, reject) => {
              if (signal?.aborted) {
                reject(new DOMException("Aborted", "AbortError"));
                return;
              }
              try {
                FB.login((response) => {
                  if (signal?.aborted) {
                    reject(new DOMException("Aborted", "AbortError"));
                    return;
                  }
                  const result = StatusResponseSchema.safeParse(response);
                  if (!result.success) {
                    logError?.(result.error);
                    reject(result.error);
                    return;
                  }
                  resolve(result.data);
                }, loginOptions);
              } catch (error) {
                reject(
                  error instanceof Error
                    ? error
                    : (() => {
                        const err = new Error("Unknown error");
                        err.name = "LoginError";
                        err.cause = error;
                      })(),
                );
              }
            },
          );
        },
        ...(() => {
          /**
           * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/451dc8f/types/facebook-js-sdk/index.d.ts#L81
           */
          async function api<TResponse>(path: string): Promise<TResponse>;
          async function api<TParams extends object, TResponse>(
            path: string,
            params: TParams,
          ): Promise<TResponse>;
          async function api<TParam extends facebook.UserField>(
            path: "/me",
            params: { fields: TParam[] },
          ): Promise<Me<TParam>>;
          async function api<TParams extends object, TResponse>(
            path: string,
            method: "get" | "post" | "delete",
            params: TParams,
          ): Promise<TResponse>;
          async function api(...args: unknown[]): Promise<unknown> {
            return new Promise((resolve, reject) => {
              try {
                (FB.api as (...args: unknown[]) => void)(
                  ...args,
                  (response: unknown) => {
                    resolve(response as unknown);
                  },
                );
              } catch (error) {
                reject(
                  error instanceof Error
                    ? error
                    : (() => {
                        const err = new Error("Unknown error");
                        err.name = "ApiError";
                        err.cause = error;
                      })(),
                );
              }
            });
          }
          async function getMe({ signal }: { signal?: AbortSignal }) {
            signal?.throwIfAborted();
            const res = await api("/me", {
              fields: ["id", "name", "picture"],
            });
            signal?.throwIfAborted();
            const parsedResult = UserSchema.safeParse(res);
            if (!parsedResult.success) {
              logError?.(parsedResult.error);
              throw parsedResult.error;
            }
            return parsedResult.data;
          }
          async function getInstagramUser({
            signal,
            id,
          }: {
            signal?: AbortSignal;
            id: string;
          }) {
            signal?.throwIfAborted();
            const res = await api(`/${id}`, {
              fields: ["id", "name", "username", "profile_picture_url"],
            });
            signal?.throwIfAborted();
            const parsedResult = InstagramUserSchema.safeParse(res);
            if (!parsedResult.success) {
              logError?.(parsedResult.error);
              throw parsedResult.error;
            }
            return parsedResult.data;
          }
          /**
           * https://developers.facebook.com/docs/graph-api/reference/user/accounts/
           */
          async function getAccounts({ signal }: { signal?: AbortSignal }) {
            signal?.throwIfAborted();
            let next: string | undefined;
            async function getPage() {
              signal?.throwIfAborted();
              const res = next
                ? (
                    await axios(next, {
                      ...(!signal ? null : { signal }),
                    })
                  ).data
                : await api("/me/accounts", {
                    fields: [
                      "access_token",
                      "id",
                      "name",
                      "picture",
                      "instagram_business_account",
                    ],
                    ...(!cursorBasedPaginationLimit
                      ? null
                      : {
                          limit: cursorBasedPaginationLimit,
                        }),
                  });
              signal?.throwIfAborted();
              const parsedResult =
                CursorBasedPaginationSchema(AccountSchema).safeParse(res);
              if (!parsedResult.success) {
                logError?.(parsedResult.error);
                throw parsedResult.error;
              }
              return parsedResult.data;
            }
            const accounts: Array<z.infer<typeof AccountSchema>> = [];
            async function fetchPage() {
              const res = await getPage();
              signal?.throwIfAborted();
              res.data.forEach((account) => {
                if (accounts.some((target) => target.id === account.id)) return;
                accounts.push(account);
              });
              next = res.paging?.next;
            }
            await fetchPage();
            while (next) await fetchPage();

            return accounts;
          }
          return { api, getMe, getInstagramUser, getAccounts };
        })(),
        async getLoginStatus({
          signal,
        }: {
          signal?: AbortSignal;
        } = {}) {
          return new Promise<z.infer<typeof StatusResponseSchema>>(
            (resolve, reject) => {
              if (signal?.aborted) {
                reject(new DOMException("Aborted", "AbortError"));
                return;
              }
              try {
                FB.getLoginStatus((response) => {
                  if (signal?.aborted) {
                    reject(new DOMException("Aborted", "AbortError"));
                    return;
                  }
                  const result = StatusResponseSchema.safeParse(response);
                  if (!result.success) {
                    logError?.(result.error);
                    reject(result.error);
                    return;
                  }
                  resolve(result.data);
                });
              } catch (error) {
                reject(
                  error instanceof Error
                    ? error
                    : (() => {
                        const err = new Error("Unknown error");
                        err.name = "GetLoginStatusError";
                        err.cause = error;
                      })(),
                );
              }
            },
          );
        },
        async logout({
          signal,
        }: {
          signal?: AbortSignal;
        } = {}) {
          return new Promise<z.infer<typeof StatusResponseSchema>>(
            (resolve, reject) => {
              if (signal?.aborted) {
                reject(new DOMException("Aborted", "AbortError"));
                return;
              }
              try {
                FB.logout((response) => {
                  if (signal?.aborted) {
                    reject(new DOMException("Aborted", "AbortError"));
                    return;
                  }
                  const result = StatusResponseSchema.safeParse(response);
                  if (!result.success) {
                    logError?.(result.error);
                    reject(result.error);
                    return;
                  }
                  resolve(result.data);
                });
              } catch (error) {
                reject(
                  error instanceof Error
                    ? error
                    : (() => {
                        const err = new Error("Unknown error");
                        err.name = "LogoutError";
                        err.cause = error;
                      })(),
                );
              }
            },
          );
        },
      };

      const seed = random();

      function useInvalidateQueries() {
        const queryClient = useQueryClient();
        const invalidateQueries = useHandler(async () => {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.all,
          });
        });
        return invalidateQueries;
      }

      const queryKeys = {
        all: [{ [`fb-${seed}`]: seed }] as const,
        loginStatus() {
          return [
            {
              ...queryKeys.all[0],
              fbLoginStatus: seed,
            },
          ];
        },
        api({
          accessToken = "",
          url,
          params = {},
        }: {
          accessToken?: string | undefined;
          url: string;
          params?: object | undefined;
        }) {
          return [
            {
              ...queryKeys.all[0],
              accessToken,
              url,
              params,
              api: seed,
            },
          ] as const;
        },
        me({ accessToken = "" }: { accessToken?: string | undefined }) {
          return [
            {
              ...queryKeys.all[0],
              accessToken,
              me: seed,
            },
          ] as const;
        },
        instagramUser({
          accessToken = "",
          id,
        }: {
          accessToken?: string | undefined;
          id: string;
        }) {
          return [
            {
              ...queryKeys.all[0],
              accessToken,
              id,
              instagramUser: seed,
            },
          ] as const;
        },
        accounts({ accessToken = "" }: { accessToken?: string | undefined }) {
          return [
            {
              ...queryKeys.all[0],
              accessToken,
              accounts: seed,
            },
          ] as const;
        },
      };

      function useAuthResponse() {
        const loginStatusQuery = queries.useLoginStatusQuery();
        return (
          (loginStatusQuery.isSuccess && loginStatusQuery.data.authResponse) ||
          null
        );
      }

      const queries = {
        useAuthResponse,
        useLoginStatusQuery() {
          return useQuery({
            queryKey: queryKeys.loginStatus(),
            async queryFn({ signal }) {
              const res = await api.getLoginStatus({
                ...(!signal ? null : { signal }),
              });
              return res;
            },
          });
        },
        useApiQuery: (() => {
          const useApiQuery: {
            <TResponse>(path: string): UseQueryResult<TResponse | FbSdkErr>;
            <TParams extends object, TResponse>(
              path: string,
              params: TParams,
            ): UseQueryResult<TResponse | FbSdkErr>;
            <TParam extends facebook.UserField>(
              path: "/me",
              params: { fields: TParam[] },
            ): UseQueryResult<Me<TParam> | FbSdkErr>;
            <TParams extends object, TResponse>(
              path: string,
              method: "get" | "post" | "delete",
              params: TParams,
            ): UseQueryResult<TResponse | FbSdkErr>;
          } = function useApiQuery<TParams extends object>(
            url: string,
            params?: TParams,
          ) {
            const accessToken = useAuthResponse()?.accessToken;
            return useQuery({
              queryKey: [
                ...queryKeys.api({
                  accessToken,
                  url,
                  params,
                }),
              ],
              queryFn: async () => {
                const res = await (api.api as any)(url, params);
                return res;
              },
              enabled: Boolean(accessToken),
            });
          };
          return useApiQuery;
        })(),
        useMeQuery() {
          const accessToken = useAuthResponse()?.accessToken;
          return useQuery({
            queryKey: [
              ...queryKeys.me({
                accessToken: useAuthResponse()?.accessToken,
              }),
            ],
            async queryFn({ signal }) {
              const res = await api.getMe({
                ...(!signal ? null : { signal }),
              });
              return res;
            },
            enabled: Boolean(accessToken),
          });
        },
        useUserQuery({ id }: { id: string }) {
          const accessToken = useAuthResponse()?.accessToken;
          return useQuery({
            queryKey: [
              ...queryKeys.instagramUser({
                accessToken,
                id,
              }),
            ],
            async queryFn({ signal }) {
              const res = await api.getInstagramUser({
                ...(!signal ? null : { signal }),
                id,
              });
              return res;
            },
            enabled: Boolean(accessToken),
          });
        },
        useAccountsQuery() {
          const accessToken = useAuthResponse()?.accessToken;
          return useQuery({
            queryKey: [
              ...queryKeys.accounts({
                accessToken,
              }),
            ],
            async queryFn({ signal }) {
              const res = await api.getAccounts({
                ...(!signal ? null : { signal }),
              });
              return res;
            },
            enabled: Boolean(accessToken),
          });
        },
      };
      const mutations = {
        useLoginMutation(
          options?: Omit<
            MutationOptions<
              z.infer<typeof StatusResponseSchema>,
              Error,
              Parameters<typeof api.login>[0],
              unknown
            >,
            "mutationFn"
          >,
        ) {
          const invalidateQueries = useInvalidateQueries();
          return useMutation({
            ...options,
            async mutationFn(params) {
              FB.init({
                appId: FB_APP_ID,
                xfbml: true,
                version: "v18.0",
              });
              return await api.login(params);
            },
            async onSettled(...args) {
              invalidateQueries();
              return await options?.onSettled?.(...args);
            },
          });
        },
        useLogoutMutation(
          options?: Omit<
            MutationOptions<
              z.infer<typeof StatusResponseSchema>,
              Error,
              Parameters<typeof api.logout>[0],
              unknown
            >,
            "mutationFn"
          >,
        ) {
          const invalidateQueries = useInvalidateQueries();
          return useMutation({
            ...options,
            async mutationFn(params) {
              return api.logout(params);
            },
            async onSettled(...args) {
              invalidateQueries();
              return await options?.onSettled?.(...args);
            },
          });
        },
      };

      const hooks = {
        queries,
        mutations,
        useFbSdkLoaded,
      };

      return { api, hooks };
    })(),
  };
  return fbSdk;
}

export { AccountSchema, createFbSdk };
export type { Account };
