import { css } from "@emotion/react";
import { secondsToMilliseconds } from "date-fns";

import {
  cssFlexInheritAndFill,
  cssInheritGap,
  defineStyles,
} from "@/shared/emotion";

const styles = defineStyles({
  portal: css({
    ">*": [cssFlexInheritAndFill, cssInheritGap],
  }),
});

const throttleMs = secondsToMilliseconds(0.3);

export { styles, throttleMs };
