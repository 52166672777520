import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { ErrorSchema } from "@zeffiroso/cantata/models";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { getDomainFromEmail } from "@zeffiroso/utils/string/getDomainFromEmail";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import axios from "axios";
import type { ElementRef, FC, ReactNode } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  authSdk,
  useAllowPasswordAuthQuery,
  useFirstSsoProviderQuery,
} from "@/app/auth";
import { Trans } from "@/app/i18n/Trans";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { Form } from "@/components/Form";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { Link } from "@/components/Link";
import { BarLoading } from "@/components/Loading/BarLoading";
import { Modal } from "@/components/Modal";
import { routerUtils } from "@/router/routerUtils";
import { useNavigate } from "@/router/utils";
import { LoginForm, RegistrationForm } from "@/routes/Auth/components/Form";
import { LoginSlogan } from "@/routes/Auth/components/LoginSlogan";
import { SignInConflict } from "@/routes/Auth/components/SignInConflict";
import {
  Container,
  LeftWrapper,
  MainTitle,
  RightWrapper,
} from "@/routes/Auth/components/Styled";
import {
  useActivateExistingUserMutation,
  useActivateNewUserMutation,
  useActivateWithFirebaseAuthMutation,
  useInvitationInfoQuery,
} from "@/shared/application/invite";

const SignInConflictModal: FC<{
  onReAuthenticate: () => void;
}> = ({ onReAuthenticate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goHome = useHandler(function goHome() {
    navigate("/", { replace: true });
  });
  return (
    <Modal.Warning
      open
      title={t("invite.error.mailConflict.title")}
      closable={false}
      onOk={onReAuthenticate}
      okText={t("invite.error.mailConflict.action.reAuthenticate.label")}
      onCancel={goHome}
      cancelText={t("invite.error.mailConflict.action.goToLoginPage.label")}
    >
      {t("invite.signInConflicted.modal.description")}
    </Modal.Warning>
  );
};

const Invite: FC = () => {
  const { t } = useTranslation();
  const navigate = routerUtils.useNavigate();
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
  const clearErrorMessage = useHandler(function clearErrorMessage() {
    setErrorMessage(null);
  });
  const [conflictSignIn, setConflictSignIn] = useState<{
    conflictedSignedIn: {
      email: string;
    };
  } | null>(null);
  const invitationToken = searchParams.get("token") ?? "";
  const loginFormRef = useRef<ElementRef<typeof LoginForm>>(null);

  const invitationInfoQuery = useInvitationInfoQuery(invitationToken);
  const email = invitationInfoQuery.data?.email || "";
  const domain = getDomainFromEmail(email);
  const allowPasswordAuthQuery = useAllowPasswordAuthQuery({
    domain,
  });
  const providerQuery = useFirstSsoProviderQuery({
    domain,
  });

  const mutationOptions = useMemo<
    UseMutationOptions<unknown, unknown, unknown, unknown>
  >(
    () => ({
      onSuccess() {
        navigate("/settings/profile");
      },
      onError(error) {
        axios.isAxiosError(error);
        if (!axios.isAxiosError(error)) return;
        if (error.response?.data) {
          const parsedData = ErrorSchema.safeParse(error.response?.data);
          if (parsedData.success) {
            if (parsedData.data.name === "PARAMETER_WEAK_PASSWORD") {
              loginFormRef.current?.setFields([
                {
                  name: "password",
                  errors: [
                    t("page.invite.error.parameterWeakPassword.content"),
                  ],
                },
              ]);
              return;
            }
          }
        }
        const statusCode = error.response?.status;
        if (!statusCode) return;
        if (statusCode < 400 || statusCode >= 500) return;
        setErrorMessage(<Trans i18nKey="login.wrongEmailOrPassword" />);
      },
      onSettled() {
        safeInvalidateQuery(invitationInfoQuery.key);
      },
    }),
    [invitationInfoQuery.key, navigate, safeInvalidateQuery, t],
  );
  const activateExistingUserMutation = useActivateExistingUserMutation(
    invitationToken,
    mutationOptions,
  );
  const activateNewUserMutation = useActivateNewUserMutation(
    invitationToken,
    mutationOptions,
  );
  const activateWithFirebaseAuthMutation = useActivateWithFirebaseAuthMutation(
    invitationToken,
    mutationOptions,
  );

  const handleLoginSubmit = useHandler(
    ({ email, password }: { email: string; password: string }) => {
      clearErrorMessage();
      activateExistingUserMutation.mutate({ email, password });
    },
  );

  const handleRegisterSubmit = useHandler(function handleRegisterSubmit({
    email,
    password,
    passwordConfirmation,
  }: {
    email: string;
    password: string;
    passwordConfirmation: string;
  }) {
    clearErrorMessage();
    activateNewUserMutation.mutate({ email, password, passwordConfirmation });
  });

  const useAbortControllerStore = useUseAbortControllerStore();
  useEffect(
    function abortWhenUnmount() {
      return function cleanup() {
        useAbortControllerStore.getState().abort();
      };
    },
    [useAbortControllerStore],
  );
  const firebaseSignInMutation = useMutation({
    mutationFn: async () => {
      const signal = useAbortControllerStore.getState().signal;
      signal.throwIfAborted();
      const provider = providerQuery.data;
      if (!provider) {
        setErrorMessage(
          t("login.signInWithDomain.error.providerNotFound.content"),
        );
        return;
      }
      const firebaseSignInResult = await authSdk.signInPopup({
        provider,
        signal,
      });
      signal.throwIfAborted();
      if (!firebaseSignInResult.idToken) throw new Error("No access token");
      if (!firebaseSignInResult.email || firebaseSignInResult.email !== email) {
        setConflictSignIn({
          conflictedSignedIn: {
            email: firebaseSignInResult.email,
          },
        });
        return;
      }
      activateWithFirebaseAuthMutation.mutate({
        idToken: firebaseSignInResult.idToken,
        uid: firebaseSignInResult.uid,
      });
    },
  });

  const handleFirebaseSignIn = useHandler(function handleFirebaseSignIn() {
    clearErrorMessage();
    firebaseSignInMutation.mutate();
  });

  const isLoading =
    activateExistingUserMutation.isLoading ||
    activateNewUserMutation.isLoading ||
    activateWithFirebaseAuthMutation.isLoading ||
    invitationInfoQuery.isLoading ||
    allowPasswordAuthQuery.isLoading ||
    providerQuery.isLoading;

  return (
    <DisabledContextProvider disabled={isLoading}>
      <Container>
        {!invitationInfoQuery.isSuccess ? null : (
          <SignInConflict email={invitationInfoQuery.data.email} />
        )}
        <LeftWrapper>
          <LoginSlogan />
        </LeftWrapper>
        <RightWrapper>
          {invitationInfoQuery.error || !invitationToken ? (
            <Alert type="error" message={t("login.outdatedInvitationLink")} />
          ) : invitationInfoQuery.isLoading ? (
            <BarLoading />
          ) : providerQuery.isLoading ? (
            <BarLoading />
          ) : providerQuery.isError ? (
            <Alert
              type="error"
              message={inspectMessage`providerQuery.error: ${providerQuery.error}`}
            />
          ) : allowPasswordAuthQuery.isLoading ? (
            <BarLoading />
          ) : allowPasswordAuthQuery.isError ? (
            <Alert
              type="error"
              message={inspectMessage`allowPasswordAuthQuery.error: ${allowPasswordAuthQuery.error}`}
            />
          ) : !allowPasswordAuthQuery.data && providerQuery.data ? (
            <Form
              layout="vertical"
              requiredMark={false}
              onFinish={handleFirebaseSignIn}
              disabled={isLoading}
            >
              {!conflictSignIn ? null : (
                <SignInConflictModal onReAuthenticate={handleFirebaseSignIn} />
              )}
              {!errorMessage ? null : (
                <Alert type="error" message={errorMessage} />
              )}
              <h1
                css={css`
                  margin-bottom: 20px;
                `}
              >
                {t("invite.loginWithDomain.title")}
              </h1>
              <p
                css={css`
                  margin-bottom: 48px;
                `}
              >
                {t("invite.loginWithDomain.description", {
                  domain,
                })}
              </p>
              <Button
                type="primary"
                htmlType="submit"
                block
                css={css`
                  margin-bottom: 86px;
                `}
              >
                {t("invite.loginWithDomain.action.signIn.label")}
              </Button>
              <Link
                to="/login"
                css={css`
                  display: block;
                  width: fit-content;
                  margin: auto;
                  text-align: center;
                `}
              >
                {t("invite.loginWithDomain.action.goToLoginPage.label")}
              </Link>
            </Form>
          ) : invitationInfoQuery.data.sources.includes("caac") ? (
            <>
              <MainTitle>
                <MainTitle.Logo />
                <Trans i18nKey="login.title" />
                {errorMessage ? (
                  <Alert type="error" message={errorMessage} />
                ) : null}
              </MainTitle>
              <LoginForm
                ref={loginFormRef}
                errorMessage={errorMessage}
                loading={activateExistingUserMutation.isLoading}
                onSubmit={handleLoginSubmit}
                formValues={{ email: invitationInfoQuery.data.email }}
                disabledFields={["email"]}
              />
            </>
          ) : (
            <>
              {invitationInfoQuery.data.sources.includes("maac") ? (
                <>
                  <MainTitle>
                    <Trans i18nKey="login.title" />
                    {errorMessage ? (
                      <Alert type="error" message={errorMessage} />
                    ) : (
                      <Alert message={t("login.maacUserLogin")} />
                    )}
                  </MainTitle>
                  <LoginForm
                    ref={loginFormRef}
                    errorMessage={errorMessage}
                    loading={isLoading}
                    onSubmit={handleLoginSubmit}
                    formValues={{ email: invitationInfoQuery.data.email }}
                    disabledFields={["email"]}
                  />
                </>
              ) : (
                <>
                  <MainTitle>
                    <Trans i18nKey="login.title.register" />
                    {errorMessage ? (
                      <Alert type="error" message={errorMessage} />
                    ) : (
                      <Alert message={t("login.nonMaacUserRegistration")} />
                    )}
                  </MainTitle>
                  <RegistrationForm
                    ref={loginFormRef}
                    loading={activateNewUserMutation.isLoading}
                    onSubmit={handleRegisterSubmit}
                    formValues={{ email: invitationInfoQuery.data.email }}
                    disabledFields={["email"]}
                  />
                </>
              )}
            </>
          )}
        </RightWrapper>
        {!invitationInfoQuery.isError && invitationToken ? null : (
          <Modal.Warning
            title={t("login.activationFailed")}
            closable={false}
            open
            footer={[
              <Button
                key="confirm"
                type="primary"
                onClick={() => {
                  navigate("/", {
                    replace: true,
                  });
                }}
              >
                {t("common.confirm")}
              </Button>,
            ]}
          >
            {t("login.outdatedInvitationLink")}
          </Modal.Warning>
        )}
      </Container>
    </DisabledContextProvider>
  );
};

export { Invite };
