import { isEqual } from "date-fns";
// eslint-disable-next-line no-restricted-imports -- Inherited from Zustand
import type { shallow as zustandShallow } from "zustand/shallow";

import { Ymd } from "../date/Ymd";

const constructorToEqualFnRecord = new Map<
  Function,
  (a: any, b: any) => boolean
>();
/**
 * Compare URL objects by their `href` property.
 */
constructorToEqualFnRecord.set(URL, (a: URL, b: URL) => a.href === b.href);

/**
 * Compare Date objects with `date-fns`'s `isEqual` function.
 */
constructorToEqualFnRecord.set(Date, isEqual);

/**
 * Compare Ymd objects by their `toString` method (YYYY-MM-DD).
 */
constructorToEqualFnRecord.set(
  Ymd,
  (a: Ymd, b: Ymd) => a.toString() === b.toString(),
);

/**
 * Compare special objects declared in `constructorToEqualFnRecord`.
 * If return value is `undefined`, it means the objects are not special objects.
 */
function compareSpecialObjects(a: any, b: any): true | false | undefined {
  const Constructors = constructorToEqualFnRecord.keys();
  for (const Constructor of Constructors) {
    if (a instanceof Constructor && b instanceof Constructor) {
      const equalFn = constructorToEqualFnRecord.get(Constructor)!;
      return equalFn(a, b);
    }
  }
  return undefined;
}

/**
 * Zustand's shallow comparison does not support comparing dates, thus requiring
 * a custom equality function.
 *
 * - [Bug in shallow when comparing dates
 *   #1064](https://github.com/pmndrs/zustand/issues/1064)
 */
const shallow: typeof zustandShallow = (a, b) => {
  const specialObjectsResult = compareSpecialObjects(a, b);
  if (specialObjectsResult !== undefined) return specialObjectsResult;
  if (a instanceof Object && b instanceof Object) {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) return false;

    for (const key of aKeys) {
      if (!(key in b)) return false;
      // @ts-expect-error -- Key is from aKeys.
      const aValue = a[key];
      // @ts-expect-error -- Key is checked.
      const bValue = b[key];
      const specialObjectsResult = compareSpecialObjects(aValue, bValue);
      if (specialObjectsResult !== undefined) {
        if (!specialObjectsResult) return false;
        // Same special object. Continue to the next key.
        continue;
      }
      if (!Object.is(aValue, bValue)) return false;
      continue;
    }
    return true;
  }

  return Object.is(a, b);
};

export { shallow };
