import { isEmptyObject } from "@chatbotgang/etude/pitch-shifter/emptyObject";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useMemo } from "react";
import { z } from "zod";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { LOCAL_STORAGE_RECENT_ASSIGNEE } from "@/appConstant";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const RecentAssigneeSchema = z.union([
  z.object({
    type: z.literal("user"),
    userId: z.number().int(),
  }),
  z.object({
    type: z.literal("user-in-team"),
    userId: z.number().int(),
    teamId: z.number().int(),
  }),
  z.object({
    type: z.literal("team"),
    teamId: z.number().int(),
  }),
]);

const RecentAssigneeRecordSchema = z.record(
  z.string(),
  z.array(RecentAssigneeSchema),
);

type RecentAssignee = z.infer<typeof RecentAssigneeSchema>;
type RecentAssigneeRecord = z.infer<typeof RecentAssigneeRecordSchema>;

function parseRecentAssignee(input: unknown): RecentAssigneeRecord {
  const entries: [string, unknown][] =
    input instanceof Object ? Object.entries(input) : [];
  const formattedEntries = entries.flatMap(([key, value]) => {
    const parseResult = z.array(RecentAssigneeSchema).safeParse(value);
    if (!parseResult.success) return [];
    return [[key, parseResult.data]];
  });
  return Object.fromEntries(formattedEntries);
}

const { useStore: useRecentStore } = createZustandStorageStore(
  LOCAL_STORAGE_RECENT_ASSIGNEE,
  parseRecentAssignee,
);

const MAX_RECENT_ASSIGNEE_SIZE = 5;

export function useRecentAssignees() {
  const store = useRecentStore();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const recentAssignees = useMemo<Array<RecentAssignee>>(() => {
    const orgIdKey = `${orgId}` as const;
    return !(orgIdKey in store.value)
      ? []
      : isEmptyObject(store.value)
        ? []
        : store.value[orgIdKey];
  }, [orgId, store.value]);
  const setOrganizationValue = useHandler(function setOrganizationValue(
    value:
      | Array<RecentAssignee>
      | ((prev: Array<RecentAssignee>) => Array<RecentAssignee>),
  ) {
    store.setValue((prev) => {
      const draft: Record<`${number}`, Array<RecentAssignee>> = { ...prev };
      const orgIdKey = `${orgId}` as const;
      const prevOrgValue = !(orgIdKey in prev)
        ? []
        : isEmptyObject(prev)
          ? []
          : prev[orgIdKey];
      draft[orgIdKey] =
        typeof value !== "function" ? value : value(prevOrgValue);
      return draft;
    });
  });

  const addRecentAssignee = useHandler(function addRecentAssignee(
    assignee: RecentAssignee,
  ) {
    setOrganizationValue((prev) => {
      const draft = [...prev];
      const index = draft.findIndex(
        (item) =>
          (item.type === "team" &&
            assignee.type === "team" &&
            item.teamId === assignee.teamId) ||
          (item.type === "user" &&
            assignee.type === "user" &&
            item.userId === assignee.userId) ||
          (item.type === "user-in-team" &&
            assignee.type === "user-in-team" &&
            item.userId === assignee.userId &&
            item.teamId === assignee.teamId),
      );
      if (index !== -1) draft.splice(index, 1);

      draft.unshift(assignee);
      while (draft.length > MAX_RECENT_ASSIGNEE_SIZE) draft.pop();

      return draft;
    });
  });

  const ret = useMemo(
    () => ({
      recentAssignees,
      addRecentAssignee,
    }),
    [recentAssignees, addRecentAssignee],
  );

  return ret;
}
