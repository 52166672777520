import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { makeApi, makeParameters } from "@zodios/core";
import { z } from "zod";

import {
  ChannelTypeSchema,
  DashboardAverageSchema,
  DashboardIntervalSchema,
  DashboardMetricsSchema,
} from "../models";

const DashboardDetailSchema = z.object({
  interval: DashboardIntervalSchema,
  timeMetrics: DashboardMetricsSchema.array(),
});

const DashboardQueryParameters = makeParameters([
  {
    type: "Query",
    name: "startTime",
    schema: Ymd.YmdToYmdStringSchema,
  },
  {
    type: "Query",
    name: "endTime",
    schema: Ymd.YmdToYmdStringSchema,
  },
  {
    type: "Query",
    name: "channelId",
    schema: z.number().int().array().optional(),
  },
  {
    type: "Query",
    name: "channelType",
    schema: ChannelTypeSchema.array().optional(),
  },
  {
    type: "Query",
    name: "teamId",
    schema: z.number().int().array().optional(),
  },
  {
    type: "Query",
    name: "interval",
    schema: DashboardIntervalSchema.optional(),
  },
]);

const api = makeApi([
  /**
   * Export efficiency metrics to csv
   *
   * Spec:
   * [Swagger](https://swagger.cresclab.site/?urls.primaryName=cantata-staging#/Dashboard/get_api_v1_orgs__org_id__dashboard_efficiency_export_csv)
   */
  {
    alias: "exportEfficiencyCsv",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/efficiency/export-csv",
    parameters: DashboardQueryParameters,
    response: z.string(),
    responseType: "blob",
  },

  /**
   * Get average first response time
   *
   * Spec:
   * [Swagger](https://swagger.cresclab.site/?urls.primaryName=cantata-staging#/Dashboard/get_api_v1_orgs__org_id__dashboard_efficiency_first_response_time_avg)
   */
  {
    alias: "firstResponseTimeAvg",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/efficiency/first-response-time/avg",
    parameters: DashboardQueryParameters,
    response: DashboardAverageSchema,
  },

  /**
   * Get first response time details
   *
   * Spec:
   * [Swagger](https://swagger.cresclab.site/?urls.primaryName=cantata-staging#/Dashboard/get_api_v1_orgs__org_id__dashboard_efficiency_first_response_time_detail)
   */
  {
    alias: "firstResponseTimeDetail",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/efficiency/first-response-time/detail",
    parameters: DashboardQueryParameters,
    response: DashboardDetailSchema,
  },

  /**
   * Get average time to resolution
   *
   * Spec:
   * [Swagger](https://swagger.cresclab.site/?urls.primaryName=cantata-staging#/Dashboard/get_api_v1_orgs__org_id__dashboard_efficiency_ttr_avg)
   */
  {
    alias: "ttrAvg",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/efficiency/ttr/avg",
    parameters: DashboardQueryParameters,
    response: DashboardAverageSchema,
  },

  /**
   * Get time to resolution details
   *
   * Spec:
   * [Swagger](https://swagger.cresclab.site/?urls.primaryName=cantata-staging#/Dashboard/get_api_v1_orgs__org_id__dashboard_efficiency_ttr_detail)
   */
  {
    alias: "ttrDetail",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/efficiency/ttr/detail",
    parameters: DashboardQueryParameters,
    response: DashboardDetailSchema,
  },

  /**
   * Get average wait time
   *
   * Spec:
   * [Swagger](https://swagger.cresclab.site/?urls.primaryName=cantata-staging#/Dashboard/get_api_v1_orgs__org_id__dashboard_efficiency_wait_time_avg)
   */
  {
    alias: "waitTimeAvg",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/efficiency/wait-time/avg",
    parameters: DashboardQueryParameters,
    response: DashboardAverageSchema,
  },

  /**
   * Get wait time details
   *
   * Spec:
   * [Swagger](https://swagger.cresclab.site/?urls.primaryName=cantata-staging#/Dashboard/get_api_v1_orgs__org_id__dashboard_efficiency_wait_time_detail)
   */
  {
    alias: "waitTimeDetail",
    method: "get",
    path: "api/v1/orgs/:orgId/dashboard/efficiency/wait-time/detail",
    parameters: DashboardQueryParameters,
    response: DashboardDetailSchema,
  },
]);

export { api };
