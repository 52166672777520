import { handleKey } from "@chatbotgang/etude/event/keycode";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type {
  CompositionEventHandler,
  KeyboardEventHandler,
  RefObject,
} from "react";
import { useRef } from "react";

import type { InputRef } from "@/components/Input";
import { useInputComposition } from "@/shared/hooks/useInputComposition";

type EditableElement = HTMLTextAreaElement | HTMLInputElement | HTMLDivElement;

export const useBlurInputOnEnter = (): {
  isComposition: boolean;
  bind: {
    ref: RefObject<InputRef>;
    onCompositionStart: CompositionEventHandler<EditableElement>;
    onCompositionUpdate: CompositionEventHandler<EditableElement>;
    onCompositionEnd: CompositionEventHandler<EditableElement>;
    onKeyDown: KeyboardEventHandler<EditableElement>;
  };
} => {
  const ref = useRef<InputRef>(null);
  const { bind, isComposition } = useInputComposition();

  const onKeyDown = useHandler(
    handleKey({
      Enter: () => {
        if (!isComposition) ref.current?.blur();
      },
    }),
  );

  return {
    bind: {
      ...bind,
      ref,
      onKeyDown,
    },
    isComposition,
  };
};
