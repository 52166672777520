import { addMilliseconds, isAfter } from "date-fns/fp";
import { type FC, useEffect } from "react";

import {
  APP_LOADED_DATE,
  ZENDESK_LAST_OPENED_AT_EXPIRE_TIME,
} from "@/appConstant";
import { getLastOpenedAt } from "@/internal/zendesk/lastOpenedAtStorage";
import { useLoadScript } from "@/internal/zendesk/useLoadScript";

const LoadScriptOnMountIfOpenedRecently: FC = () => {
  const loadScript = useLoadScript();
  useEffect(
    function loadScriptOnMountIfOpenedRecently() {
      const lastOpenedAt = getLastOpenedAt();
      if (!lastOpenedAt) return;
      const expired = isAfter(
        addMilliseconds(ZENDESK_LAST_OPENED_AT_EXPIRE_TIME)(lastOpenedAt),
      )(APP_LOADED_DATE);
      if (expired) return;
      loadScript();
    },
    [loadScript],
  );
  return null;
};

export { LoadScriptOnMountIfOpenedRecently };
