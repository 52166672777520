import type { FC } from "react";

import { memberFieldRules } from "@/resources/member/memberValidator";
import { TextField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/TextField";

const AccountManagerField: FC = () => {
  return (
    <TextField
      fieldName="accountManager"
      rules={memberFieldRules.accountManager}
    />
  );
};

export { AccountManagerField };
