import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { array } from "@chatbotgang/etude/pitch-shifter/array";
import { number } from "@chatbotgang/etude/pitch-shifter/number";
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import PQueue from "p-queue";
import { type FC, useMemo } from "react";
import { useInView } from "react-intersection-observer";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import type { TagItemsProps } from "@/components/Tag/TagItems";
import { TagItems } from "@/components/Tag/TagItems";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { tagValidator } from "@/resources/tag/tagValidator";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { useUserPermission } from "@/shared/application/user";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  emphasisText: css({
    display: "inline-block",
    backgroundColor: theme.colors.yellow005,
  }),
});

const RootUtilsContext = createContext<{
  inViewUtils: ReturnType<typeof useInView>;
}>({
  name: "TagsRootUtils",
});

const CLOSED_VISIBLE_TAG_COUNT = Number.POSITIVE_INFINITY;

const pQueue = new PQueue({ concurrency: 1 });

const UnknownTag: FC<{ tagId: CantataTypes["Tag"]["id"] }> = ({ tagId }) => (
  <span
    css={{
      display: "inline-flex",
      color: theme.colors.red006,
    }}
  >
    {inspectMessage`Unknown tag: ${tagId}`}
  </span>
);

const Tags: FC = () => {
  const rootUtils = RootUtilsContext.useContext();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();

  const highlightTagIds = searchController.useStore<
    Array<CantataTypes["Tag"]["id"]>
  >((state) =>
    state.search.action === "tags"
      ? /**
         * Fix the type due to zustand not really supporting union types.
         */
        array(number())(state.search.query)
      : [],
  );
  const { hasPermission } = useUserPermission();
  const editable = hasPermission("editMemberTag");

  const allTagsQuery = cantata.tag.useList(
    {
      params: {
        orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      enabled: rootUtils.inViewUtils.inView,
    },
  );
  const memberTagsQuery = cantata.memberTag.useGetByMemberId(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      enabled: rootUtils.inViewUtils.inView,
    },
  );

  const addTagMutation = cantata.memberTag.useAdd({
    params: {
      orgId,
    },
  });

  const createTagMutation = cantata.tag.useCreate({
    params: {
      orgId,
    },
  });

  const removeTagMutation = cantata.memberTag.useRemove({
    params: {
      orgId,
    },
  });

  const onAddTag = useHandler<
    NonNullable<TagItemsProps["tagSelectorProps"]>["onAddTag"]
  >(async function onAddTag(tag) {
    return await pQueue.add(async () => {
      if (typeof tag.key !== "number")
        throw new Error(inspectMessage`invalid tag: ${tag}`);
      return addTagMutation.mutateAsync({
        memberId,
        tagId: tag.key,
      });
    });
  });

  const onCreateTag = useHandler<
    NonNullable<TagItemsProps["tagSelectorProps"]>["onCreateTag"]
  >(async function onCreateTag(tagName) {
    await pQueue.add(async () => {
      const result = await createTagMutation.mutateAsync({
        name: tagName,
      });
      await addTagMutation.mutateAsync({
        memberId,
        tagId: result.id,
      });
    });
  });

  const allTags = useMemo<ComponentProps<typeof TagItems>["allTags"]>(
    () =>
      !allTagsQuery.isSuccess
        ? []
        : allTagsQuery.data.tags.map<
            NonNullable<ComponentProps<typeof TagItems>["allTags"]>[number]
          >((tag) => ({
            key: tag.id,
            name: tag.name,
            children: tag.name,
          })),
    [allTagsQuery.data?.tags, allTagsQuery.isSuccess],
  );

  const value = useMemo<ComponentProps<typeof TagItems>["value"]>(
    () =>
      !memberTagsQuery.isSuccess
        ? []
        : memberTagsQuery.data.tagIds.map<
            NonNullable<ComponentProps<typeof TagItems>["value"]>[number]
          >((id) => ({
            key: id,
            children: (function renderChildren() {
              const tag = allTagsQuery.data?.tags.find((tag) => tag.id === id);
              if (!tag) return <UnknownTag tagId={id} />;
              if (highlightTagIds.includes(id))
                return <div css={styles.emphasisText}>{tag.name}</div>;
              return tag.name;
            })(),
            closable: editable,
            onClose: async () => {
              await pQueue.add(async () => {
                await removeTagMutation.mutateAsync({
                  memberId,
                  tagId: id,
                });
              });
            },
          })),
    [
      allTagsQuery.data?.tags,
      editable,
      highlightTagIds,
      memberId,
      memberTagsQuery.data?.tagIds,
      memberTagsQuery.isSuccess,
      removeTagMutation,
    ],
  );

  if (!allTagsQuery.isSuccess || !memberTagsQuery.isSuccess) return null;

  return (
    <TagItems
      allTags={allTags}
      value={value}
      collapsedSize={CLOSED_VISIBLE_TAG_COUNT}
      noData={null}
      tagSelectorProps={
        !editable
          ? undefined
          : {
              onAddTag,
              onCreateTag,
              maxTagNameLength: tagValidator.name.maxLength.value,
            }
      }
    />
  );
};

const ErrorBoundaryWrapped: FC = () => {
  const inViewUtils = useInView();
  const rootUtils = useMemo(() => ({ inViewUtils }), [inViewUtils]);
  return (
    <ErrorBoundary.Alert>
      <RootUtilsContext.Provider value={rootUtils}>
        <div ref={inViewUtils.ref}>
          <Tags />
        </div>
      </RootUtilsContext.Provider>
    </ErrorBoundary.Alert>
  );
};

export { ErrorBoundaryWrapped as Tags };
