import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { TabsProps as AntTabsProps } from "antd";
import { Tabs as AntTabs } from "antd";
import type { FC } from "react";

import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    "--color-default": theme.colors.neutral007,
    "--color-active": theme.colors.blue006,
    "--color-hover": theme.colors.blue005,
    "& .ant-tabs-tab": {
      color: "var(--color-default)",
      "&:hover": {
        color: "var(--color-hover)",
        "& .blocked": {
          color: "var(--color-hover)",
        },
      },
      "& + .ant-tabs-tab": {
        margin: "0 0 0 24px",
      },
    },
    "& .ant-tabs-tab + .ant-tabs-tab": {
      margin: "0 0 0 24px",
    },
    "& .ant-tabs-ink-bar": {
      borderRadius: "2px",
      background: "var(--color-active)",
    },
    [[
      "&.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar",
      "&.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar",
      "&.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar",
      "&.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar",
    ].join(", ")]: {
      height: "3px",
    },
    [[
      "& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn",
      "& .ant-tabs-tab.ant-tabs-tab-active .blocked",
    ].join(", ")]: {
      color: "var(--color-active)",
    },
  }),
  pageTabs: css({
    ".ant-tabs-nav": {
      marginBottom: "24px",
    },
    ".ant-tabs-tab": {
      padding: "12px 0 11px",
    },
    ".ant-tabs-nav::before": {
      display: "none",
    },
    ".ant-tabs-tabpane": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
  }),
});

type TabsProps = AntTabsProps;

type TabItem = Exclude<TabsProps["items"], undefined>[number];

/**
 * This is the shared Tabs component. Basically, it is styled with theme color. You can also wrap this with the custom styles.
 */
const Tabs: FC<TabsProps> = (props) => {
  return <AntTabs {...props} css={styles.root} />;
};

assignDisplayName(Tabs, "Tabs");

type PageTabsProps = TabsProps;

const PageTabs: FC<PageTabsProps> = ({ ...props }) => {
  return <Tabs {...props} css={styles.pageTabs} />;
};

assignDisplayName(PageTabs, "PageTabs");

export { PageTabs, Tabs };
export type { PageTabsProps, TabItem, TabsProps };
