import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { ClassNames, css } from "@emotion/react";
import type { DrawerProps as AntdDrawerProps } from "antd";
import { Drawer as AntdDrawer } from "antd";
import type { ComponentProps, ElementRef, FC } from "react";
import type { SetRequired } from "type-fest";

import { cssFlexInheritAndFill } from "@/shared/emotion";

type DrawerProps = AntdDrawerProps;
const Drawer: FC<DrawerProps> = (props) => {
  return <AntdDrawer {...props} />;
};

type PortalDrawerProps = Omit<
  DrawerProps,
  "forceRender" | "destroyOnClose" | "children"
> & {
  ref?: ComponentProps<"div">["ref"];
};

/**
 * This is a drawer that renders its content as a portal target.
 */
const PortalDrawer = memo(
  forwardRef<ElementRef<"div">, PortalDrawerProps>(
    function PortalDrawer(props, ref) {
      return (
        <Drawer
          {...props}
          /**
           * This is necessary to ensure that the div is rendered when the
           * drawer is open. Otherwise, the portal will be empty, and the
           * content will be attached to the document body.
           */
          forceRender
          destroyOnClose={false}
        >
          <div
            css={css`
              ${cssFlexInheritAndFill}
              /* Portal inner */
              & > * {
                ${cssFlexInheritAndFill}
              }
            `}
            ref={ref}
          />
        </Drawer>
      );
    },
  ),
);

type FullScreenPortalDrawerProps = SetRequired<
  PortalDrawerProps,
  "getContainer"
>;

/**
 * `getContainer` is required to prevent a drawer is hidden behind the
 * content of the page because this might be used with multiple drawers.
 */
const FullPortalDrawer = memo(
  forwardRef<ElementRef<"div">, FullScreenPortalDrawerProps>(
    function FullScreenPortalDrawer(props, ref) {
      return (
        <ClassNames>
          {({ css }) => {
            const rootClassName = css`
              position: absolute;

              .ant-drawer-body {
                padding: 0;
              }

              .ant-drawer-content-wrapper {
                box-shadow: none;
              }
            `;
            return (
              <PortalDrawer
                rootClassName={rootClassName}
                width="100%"
                height="100%"
                {...props}
                ref={ref}
              />
            );
          }}
        </ClassNames>
      );
    },
  ),
);

const Exported = Object.assign(Drawer, {
  Portal: Object.assign(PortalDrawer, {
    Full: FullPortalDrawer,
  }),
});

export { Exported as Drawer };
export type { DrawerProps, FullScreenPortalDrawerProps, PortalDrawerProps };
