import type { FC } from "react";

import { DebugTab, type TabItemWithoutKey } from "@/layout/DevMode/Content";
import { MainLayout } from "@/layout/MainLayout";
import { DirectChatDebugger } from "@/routes/Chat/ui/ChatPanel/Debug/DirectChatDebugger";

const tabItem: TabItemWithoutKey = {
  label: "In Member",
  children: (
    <MainLayout.Section>
      <DirectChatDebugger />
    </MainLayout.Section>
  ),
};

const DebugTools: FC = () => {
  return <DebugTab tab={tabItem} />;
};

export { DebugTools };
