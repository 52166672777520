import type { FormProps } from "@/components/Form";
import { createEasyForm } from "@/components/Form/createEasyForm";

type Assignee =
  | {
      type: "unassigned";
    }
  | {
      type: "user";
      userId: number;
    }
  | {
      type: "team";
      teamId: number;
    }
  | {
      type: "user-in-team";
      teamId: number;
      userId: number;
    };

type Values = {
  assignee: undefined | Assignee;
};

const AssigneeEasyForm = createEasyForm<Values>();
type AssigneeFormProps = FormProps<Values>;
const initialValues: Values = {
  assignee: {
    type: "unassigned",
  },
};

export { AssigneeEasyForm, initialValues };
export type { AssigneeFormProps, Values };
