import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { omit } from "lodash-es";
import type { ElementRef, FC, ReactNode } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { ExternalLink } from "@/components/ExternalLink";
import { Form } from "@/components/Form";
import {
  UserSelector,
  type UserSelectorProps,
} from "@/resources/user/UserSelector";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { Menu } from "@/routes/Chat/ui/SideMenu/components/Menu";
import { Count } from "@/routes/Chat/ui/SideMenu/Count";
import { useUserPermission } from "@/shared/application/user";
import { defineStyles } from "@/shared/emotion";

const Option: FC<{
  label: ReactNode;
  assignmentFilter: CantataTypes["MemberAssignmentFilter"];
}> = ({ label, assignmentFilter }) => {
  const channelId = searchController.useStore((state) => state.channelId);
  const currentAssignmentFilter = searchController.useStore(
    (state) => state.filter.assignmentFilter,
  );
  const active = assignmentFilter === currentAssignmentFilter;

  const onClick = useHandler<ComponentProps<typeof Menu.ButtonItem>["onClick"]>(
    function onClick() {
      searchController.setState((state) =>
        /**
         * Assignee not handled here.
         */
        assignmentFilter === "assignee"
          ? state
          : {
              filter: {
                ...omit(state.filter, "assigneeId"),
                assignmentFilter,
              },
            },
      );
    },
  );

  return (
    <Menu.ButtonItem
      active={active}
      onClick={onClick}
      end={
        assignmentFilter === "assignee" ? null : (
          <Count
            {...(!channelId
              ? null
              : {
                  channelId,
                })}
            assignmentFilter={assignmentFilter}
          />
        )
      }
    >
      {label}
    </Menu.ButtonItem>
  );
};

const NonListAllMembersHelperText = (() => {
  const permissionHelperHintStyles = defineStyles({
    root: css({
      marginTop: 4,
      whiteSpace: "pre-wrap",
      fontSize: "0.75rem",
      color: theme.colors.neutral007,
    }),
  });
  const NonListAllMembersHelperText: FC = () => {
    const { t } = useTranslation();
    return (
      <div css={permissionHelperHintStyles.root}>
        <span>
          <Trans
            i18nKey="chat.memberPanel.filter.description"
            components={{
              externalLink: (
                <ExternalLink
                  href={t("chat.memberPanel.filter.linkToRoleHC")}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </span>
      </div>
    );
  };
  return NonListAllMembersHelperText;
})();

const AssigneeSelector: FC = () => {
  const filter = searchController.useStore((state) => state.filter);
  const handleSelectAssignee = useHandler<UserSelectorProps["onChange"]>(
    function handleSelectAssignee(value) {
      searchController.setState((state) => ({
        filter: {
          ...state.filter,
          assignmentFilter: "assignee",
          assigneeId: value,
        },
      }));
    },
  );
  const { hasPermission } = useUserPermission();
  // list all members only if user has permission to assign all members
  const listAllMembers = hasPermission("manualAssignmentAll");

  const selectRef = useRef<ElementRef<typeof UserSelector>>(null);

  const assigneeId = "assigneeId" in filter ? filter.assigneeId : Number.NaN;

  return (
    <Form.Item
      extra={listAllMembers ? null : <NonListAllMembersHelperText />}
      style={{
        marginBottom: 0,
      }}
    >
      <UserSelector
        style={{
          width: "100%",
        }}
        value={"assigneeId" in filter ? assigneeId : null}
        onChange={handleSelectAssignee}
        myTeams={!listAllMembers}
        ref={selectRef}
      />
    </Form.Item>
  );
};

const Assignee = (() => {
  const styles = defineStyles({
    root: css({
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    }),
    assignTo: css({
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 4,
    }),
    content: css({
      display: "flex",
      flexDirection: "column",
      gap: "inherit",
      paddingInline: 12,
    }),
  });
  const Assignee: FC = () => {
    const { t } = useTranslation();
    const assigneeId: number = searchController.useStore((state) =>
      !("assigneeId" in state.filter) ? Number.NaN : state.filter.assigneeId,
    );
    return (
      <li css={styles.root}>
        <label css={styles.assignTo}>
          <div
            css={css([
              Menu.styles.item,
              !assigneeId ? undefined : Menu.styles.buttonItemButtonActive,
            ])}
          >
            {t("chat.contactList.assignmentFilter.option.agent.label")}
          </div>
          <div css={styles.content}>
            <AssigneeSelector />
          </div>
        </label>
      </li>
    );
  };
  return Assignee;
})();

const Assignment: FC = () => {
  const { t } = useTranslation();
  return (
    <Menu.Root label={t("chat.memberPanel.filter.assignee")}>
      <Menu.List>
        <Option
          assignmentFilter="all"
          label={t("chat.contactList.assignmentFilter.option.all.label")}
        />
        <Option
          assignmentFilter="me"
          label={t(
            "chat.contactList.assignmentFilter.option.assignedToMe.label",
          )}
        />
        <Option
          assignmentFilter="my-teams"
          label={t("chat.contactList.assignmentFilter.option.myTeam.label")}
        />
        <Option
          assignmentFilter="my-teams-pending"
          label={t(
            "chat.contactList.assignmentFilter.option.myTeamNoAgent.label",
          )}
        />
        <Option
          assignmentFilter="unassigned"
          label={t("chat.contactList.assignmentFilter.option.unassigned.label")}
        />
        <Assignee />
      </Menu.List>
    </Menu.Root>
  );
};

export { Assignment };
