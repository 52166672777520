/**
 * The badge count is controlled by the service worker.
 */

import { create } from "zustand";

import { isSupported } from "@/app/messaging/isSupported";
import { emit2Sw } from "@/app/sw/emit2Sw";
import { swMessageEmitter } from "@/app/sw/swMsg";

const badge = {
  async clear() {
    emit2Sw("clearBadge");
  },
};

const useBadgeCountStore = create<number>(() => 0);

(async () => {
  if (!(await isSupported())) return;

  swMessageEmitter.on("badgeCount", (count) => {
    useBadgeCountStore.setState(count);
  });
  // Request current badge count.
  emit2Sw("requestBadgeCount");
})();

function useBadgeCount() {
  const count = useBadgeCountStore((count) => count);
  return count;
}

export { badge, useBadgeCount };
