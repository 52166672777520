import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { useMemo } from "react";
import { useInView } from "react-intersection-observer";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { ShowMoreButton } from "@/components/Button/ShowMoreButton";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { Conversation } from "@/routes/Chat/ui/MemberProfilePanel/Engagement/ConversationHistory/Conversation";
import { cssFlexInheritAndFill } from "@/shared/emotion";

const collapsedSize = 2;

const ConversationHistory = memo(function ConversationHistory() {
  const { ref, inView } = useInView();
  const [showMore, toggleShowMore] = useSwitch(false);
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const query = cantata.conversationHistory.useList(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      enabled: inView,
    },
  );
  const expendedConversations = useMemo(
    () => (!query.isSuccess ? [] : query.data.conversations),
    [query],
  );
  const collapsedConversations = useMemo(
    () => expendedConversations.slice(0, collapsedSize),
    [expendedConversations],
  );
  const content = useMemo(() => {
    if (query.isLoading) return <BarLoadingBlock />;
    if (query.isError)
      return <Alert type="error" message={query.error.message} />;
    const hasMore = query.data.conversations.length > collapsedSize;
    return (
      <div
        css={css([
          cssFlexInheritAndFill,
          css`
            flex-direction: column;
            align-items: flex-start;
          `,
        ])}
      >
        {(showMore ? expendedConversations : collapsedConversations).map(
          (conversation) => (
            <Conversation
              key={conversation.conversationId}
              conversation={conversation}
            />
          ),
        )}
        {!hasMore ? null : (
          <ShowMoreButton expanded={showMore} onChange={toggleShowMore} />
        )}
      </div>
    );
  }, [
    collapsedConversations,
    expendedConversations,
    query.data?.conversations.length,
    query.error?.message,
    query.isError,
    query.isLoading,
    showMore,
    toggleShowMore,
  ]);
  return <div ref={ref}>{content}</div>;
});

export { ConversationHistory };
