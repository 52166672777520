import { isModifiedEvent } from "@/shared/event/isModifiedEvent";
import type { LimitedMouseEvent } from "@/shared/event/LimitedMouseEvent";

/**
 * Clone from [react-router-dom](https://github.com/remix-run/react-router/blob/1193ae5/packages/react-router-dom/dom.ts#L36-L45)
 */
function shouldProcessLinkClick(event: LimitedMouseEvent, target?: string) {
  return (
    event.button === 0 && // Ignore everything but left clicks
    (!target || target === "_self") && // Let browser handle "target=_blank" etc.
    !isModifiedEvent(event) // Ignore clicks with modifier keys
  );
}

export { shouldProcessLinkClick };
