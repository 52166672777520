import { random } from "@chatbotgang/etude/string/random";
import { type MutationKey, useIsMutating } from "@tanstack/react-query";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";

type RemoveFirstParameter<T> = T extends (arg: any, ...rest: infer U) => infer R
  ? (...rest: U) => R
  : never;

const seed = random();
const bulkReassignMutationKey: MutationKey = [
  {
    [`bulkReassign-${seed}`]: `bulkReassign-${seed}`,
  },
];
const bulkResolveMutationKey: MutationKey = [
  {
    [`bulkResolve-${seed}`]: `bulkResolve-${seed}`,
  },
];

const useBulkReassignMutation: RemoveFirstParameter<
  typeof cantata.member.useBulkReassign
> = (mutationOptions) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  return cantata.member.useBulkReassign(
    {
      params: {
        orgId,
      },
    },
    {
      mutationKey: bulkReassignMutationKey,
      ...mutationOptions,
    },
  );
};

const useBulkResolveMutation: RemoveFirstParameter<
  typeof cantata.member.useBulkResolve
> = (mutationOptions) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  return cantata.member.useBulkResolve(
    {
      params: {
        orgId,
      },
    },
    {
      mutationKey: bulkResolveMutationKey,
      ...mutationOptions,
    },
  );
};

function useIsBulkActionMutating() {
  const bulkReassignIsMutatingCounts = useIsMutating(bulkReassignMutationKey);
  const bulkResolveIsMutatingCounts = useIsMutating(bulkResolveMutationKey);
  return bulkReassignIsMutatingCounts + bulkResolveIsMutatingCounts > 0;
}

const bulkActionMutations = {
  useBulkReassignMutation,
  useBulkResolveMutation,
  useIsBulkActionMutating,
};

export { bulkActionMutations };
