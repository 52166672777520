import { SearchOutlined } from "@ant-design/icons";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { createZustandContextStore } from "@zeffiroso/utils/react/createZustandContextStore";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import type { CollapseProps } from "antd";
import { Collapse as AntdCollapse } from "antd";
import type { ReactNode } from "react";
import { useMemo } from "react";

import { Flex } from "@/components/Box";
import type { InputProps } from "@/components/Input";
import { Input } from "@/components/Input";

const Collapse = styled(AntdCollapse)`
  background: none;

  > .ant-collapse-item > .ant-collapse-header {
    padding: 8px;
    border-radius: ${theme.shape.borderRadius};
    color: ${theme.colors.neutral009};

    &:hover {
      background-color: ${theme.colors.neutral001};
    }
  }

  & > .ant-collapse-item {
    border-bottom-width: 0;
  }

  &.ant-collapse-borderless > .ant-collapse-item:last-child,
  &.ant-collapse-borderless
    > .ant-collapse-item:last-child
    .ant-collapse-header {
    border-radius: ${theme.shape.borderRadius};
  }

  > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    vertical-align: -2px;
  }

  .anticon.anticon-right.ant-collapse-arrow {
    color: ${theme.colors.neutral005};
    transform: rotate(-90deg);
  }

  .ant-collapse-item-active .anticon.anticon-right.ant-collapse-arrow {
    transform: rotate(0deg);
  }

  .ant-collapse-header-text {
    flex: 1;
    line-height: 24px;
  }

  .ant-collapse-expand-icon {
    padding-left: 5px;
  }

  .ant-collapse-header.active {
    background: ${theme.colors.neutral001};
  }
`;

interface Store {
  searchText: string;
  setSearchText: (search: string) => void;
}

const { Provider: SideMenuProvider, useStore: useSideMenuStore } =
  createZustandContextStore<Store>()(
    (set) => ({
      searchText: "",
      setSearchText: (searchText) => set(() => ({ searchText })),
    }),
    shallow,
  );

assignDisplayName(SideMenuProvider, "SideMenuProvider");

const SearchInput = memo(function SearchInput() {
  const { searchText, setSearchText } = useSideMenuStore(
    ({ searchText, setSearchText }) => ({
      searchText,
      setSearchText,
    }),
  );

  const handleSearchTextChange = useHandler<InputProps["onChange"]>((e) => {
    setSearchText(e.target.value);
  });

  return (
    <Input
      allowClear
      suffix={<SearchOutlined style={{ color: theme.colors.neutral009 }} />}
      value={searchText}
      onChange={handleSearchTextChange}
    />
  );
});

type SideMenuProps = {
  defaultActiveKey?: CollapseProps["defaultActiveKey"];
  showSearch?: boolean;
  items: {
    key: string;
    label: ReactNode;
    showArrow?: boolean;
    subItems?: {
      key: string;
      label: ReactNode;
      searchText: string;
    }[];
  }[];
};

const Menu = memo<Pick<SideMenuProps, "items" | "defaultActiveKey">>(
  function Menu({ items, defaultActiveKey }) {
    const { searchText } = useSideMenuStore(({ searchText }) => ({
      searchText,
    }));

    const renderItems = useMemo(() => {
      const lowerCaseSearchText = searchText.toLowerCase();
      return (
        searchText !== ""
          ? items
              .map(({ subItems, ...rest }) => ({
                subItems: subItems?.filter((item) =>
                  item.searchText.toLowerCase().includes(lowerCaseSearchText),
                ),
                ...rest,
              }))
              .filter(
                ({ subItems }) => subItems === undefined || subItems.length > 0,
              )
          : items
      ).map(
        ({ key, showArrow, label, subItems }) =>
          ({
            key,
            label,
            showArrow,
            children:
              subItems === undefined ? undefined : (
                <Flex
                  css={css`
                    flex-direction: column;
                  `}
                >
                  {subItems.map(({ key, label }) => (
                    <div key={key}>{label}</div>
                  ))}
                </Flex>
              ),
          }) satisfies NonNullable<CollapseProps["items"]>[number],
      );
    }, [items, searchText]);

    return (
      <Collapse
        defaultActiveKey={defaultActiveKey}
        bordered={false}
        items={renderItems}
      />
    );
  },
);

const SideMenu = memo<SideMenuProps>(function SideMenu({
  showSearch = false,
  defaultActiveKey,
  items,
}) {
  return (
    <SideMenuProvider>
      <Flex
        css={css`
          flex-direction: column;
          gap: 0.5rem;
        `}
      >
        {!showSearch ? null : <SearchInput />}
        <Menu defaultActiveKey={defaultActiveKey} items={items} />
      </Flex>
    </SideMenuProvider>
  );
});

export { SideMenu, useSideMenuStore };
export type { SideMenuProps };
