import { breakpointApi } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";

import { LayoutGteTabletLandscape } from "@/layout/SideMenuLayout/Outer/Layout/GteTabletLandscape";
import { LayoutLteMobile } from "@/layout/SideMenuLayout/Outer/Layout/LteMobile";
import type { LayoutProps } from "@/layout/SideMenuLayout/Outer/Layout/types";

const Layout: FC<LayoutProps> = (props) => {
  const isGteTabletLandscape = breakpointApi.useGte("tabletLandscape");
  const Component = useMemo(
    () => (isGteTabletLandscape ? LayoutGteTabletLandscape : LayoutLteMobile),
    [isGteTabletLandscape],
  );
  return <Component {...props} />;
};

export { Layout };
