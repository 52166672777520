import type { CantataTypes } from "@/cantata/types";
import { fakeT } from "@/shared/g11n/fakeT";

const t = fakeT;

export const organizationPlanTypeTranslationKeyMap: Record<
  CantataTypes["OrgPlan"]["type"],
  string
> = {
  free: t("organization.plan.free"),
  starter: t("organization.plan.starter"),
  professional: t("organization.plan.professional"),
  enterprise: t("organization.plan.enterprise"),
  maac_combo: t("organization.plan.maacCombo"),

  // FIXME: legacy
  trial: t("organization.plan.trial"),
  standard: t("organization.plan.standard"),
};
