import { pathToRegexp } from "path-to-regexp";
import { useCallback } from "react";

import { useGetFeatureFlag } from "@/app/featureFlag";
import { routerPermissionMap } from "@/router/routerPermissionMap";
import type { BasePathName } from "@/router/types";
import { useUserPermission } from "@/shared/application/user";

/**
 * Get permission rule for the path.
 */
function getPermissionRule<P extends string = BasePathName>(path: P) {
  const rule = Object.entries(routerPermissionMap).find(([basepath]) =>
    pathToRegexp(basepath).test(path),
  )?.[1];
  return rule;
}

/**
 * Get a function to check if user has permission to access the page. It will
 * check permission and feature flags.
 */
function useCheckRoutePermission() {
  const { hasPermission } = useUserPermission();
  const getFeatureFlag = useGetFeatureFlag();
  /**
   * Check if user has permission to access the page. It will check permission
   * and feature flags.
   *
   * If path is not found in routerPermissionMap, it will return true.
   *
   * ```tsx
   * const checkPermission = useCheckRoutePermission();
   * const result = useMemo(() => checkPermission("/organization"), [checkPermission]);
   * ```
   */
  const checkPermission = useCallback(
    function checkPermission<P extends string = BasePathName>(path: P) {
      const rule = getPermissionRule(path);
      const permissionArray = rule?.permission ?? [];
      const featureFlagArray = rule?.featureFlags ?? [];
      const excludePermissionArray = rule?.exclude?.permission ?? [];
      const excludeFeatureFlagArray = rule?.exclude?.featureFlags ?? [];
      const permissionOk =
        (excludePermissionArray.length === 0 ||
          excludePermissionArray.every(
            (permission) => !hasPermission(permission),
          )) &&
        (permissionArray.length === 0 ||
          permissionArray.every((permission) => hasPermission(permission)));
      const featureFlagOk =
        (excludeFeatureFlagArray.length === 0 ||
          excludeFeatureFlagArray.every(
            (featureFlag) => !getFeatureFlag(featureFlag),
          )) &&
        (featureFlagArray.length === 0 ||
          featureFlagArray.every((featureFlag) => getFeatureFlag(featureFlag)));
      return permissionOk && featureFlagOk;
    },
    [getFeatureFlag, hasPermission],
  );
  return checkPermission;
}

export { useCheckRoutePermission };
