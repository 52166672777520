import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { BASE_URL } from "@zeffiroso/env";
import { theme } from "@zeffiroso/theme";
import { createUseOnlyOnce } from "@zeffiroso/utils/react/createUseOnlyOnce";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { Zodios } from "@zodios/core";
import { ZodiosHooks } from "@zodios/react";
import { Typography } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { create } from "zustand";
import { createWithEqualityFn } from "zustand/traditional";

import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { logError } from "@/shared/application/logger/sentry";

const api = new Zodios(
  [
    {
      alias: "version",
      method: "get",
      path: "version.json",
      response: z.object({
        version: z.string(),
      }),
    },
  ],
  {
    axiosConfig: {
      baseURL: BASE_URL,
    },
  },
);

// To ensure separation from the Cantata SDK, let's prefix this code with 👻.
const hooks = new ZodiosHooks("👻version", api);

function reload() {
  window.location.reload();
}

const Prompt = memo<{
  onCancel: () => void;
}>(function Prompt({ onCancel }) {
  const { t } = useTranslation();
  return (
    <Flex
      css={css`
        position: fixed;
        z-index: ${theme.zIndices.ultimate};
        right: 40px;
        bottom: 18px;
        width: 296px;
        box-sizing: border-box;
        flex-direction: column;
        padding: 1em 0.5em 0.5em 1.5em;
        border-radius: 4px;
        background: ${theme.colors.white};
        box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
      `}
    >
      <h2>{t("checkUpdate.prompt.title")}</h2>
      <Typography
        css={css`
          margin-top: 1em;
        `}
      >
        {t("checkUpdate.prompt.body")}
      </Typography>
      <Flex
        css={css`
          align-items: center;
          justify-content: flex-end;
          margin-top: 1em;
          gap: 1em;
        `}
      >
        <Button onClick={onCancel}>{t("checkUpdate.prompt.cancel")}</Button>
        <Button type="primary" onClick={reload}>
          {t("checkUpdate.prompt.confirm")}
        </Button>
      </Flex>
    </Flex>
  );
});

const useShouldShowUpdateDialogStore = create<boolean>()(() => false);
const useShowUpdateDialogStore = create<boolean>()(() => false);
function closeUpdateDialog() {
  useShowUpdateDialogStore.setState(false);
}
const useLastIgnoreTimeStore = createWithEqualityFn<{
  lastIgnoreTime: Date;
}>()(
  () => ({
    lastIgnoreTime: new Date(Number.NaN),
  }),
  shallow,
);
useShouldShowUpdateDialogStore.subscribe((shouldShowUpdateDialog) => {
  useShowUpdateDialogStore.setState(shouldShowUpdateDialog);
});
// The showUpdateDialog state will be false when the prompt is ignored.
useShowUpdateDialogStore.subscribe((showUpdateDialog) => {
  if (showUpdateDialog) return;
  useLastIgnoreTimeStore.setState({
    lastIgnoreTime: new Date(),
  });
});

const CheckUpdate = memo(function CheckUpdate() {
  const shouldShowUpdateDialog = useShouldShowUpdateDialogStore();
  const showUpdateDialog = useShowUpdateDialogStore();
  const query = hooks.useVersion(undefined, {
    /**
     * Fetch the version info when the app focused.
     * Don't need to refetch if we already know there is a new version.
     */
    refetchOnWindowFocus: !shouldShowUpdateDialog,
  });
  useEffect(() => {
    if (showUpdateDialog) return;
    if (!query.isSuccess) return;
    if (query.data.version === __VERSION) return;
    useShouldShowUpdateDialogStore.setState(true);
  }, [query.data?.version, query.isSuccess, showUpdateDialog]);
  return !showUpdateDialog ? null : <Prompt onCancel={closeUpdateDialog} />;
});

const { useOnlyOnce } = createUseOnlyOnce({
  onError: logError,
});

const RecoverShowUpdateDialog = memo(function RecoverShowUpdateDialog({
  timeoutDelayMs = 0,
}: {
  timeoutDelayMs?: number;
}) {
  useOnlyOnce();
  const shouldShowUpdateDialog = useShouldShowUpdateDialogStore();
  const showUpdateDialog = useShowUpdateDialogStore();
  const lastIgnoreTime = useLastIgnoreTimeStore(
    ({ lastIgnoreTime }) => lastIgnoreTime,
  );
  useEffect(() => {
    if (!shouldShowUpdateDialog) return;
    if (showUpdateDialog) return;
    function recoverShowUpdateDialog() {
      const now = new Date();
      const diff = now.getTime() - lastIgnoreTime.getTime();
      if (diff < timeoutDelayMs) return;
      useShowUpdateDialogStore.setState(true);
    }
    // Recover the state when the window is focused.
    window.addEventListener("focus", recoverShowUpdateDialog);
    return function cleanup() {
      window.removeEventListener("focus", recoverShowUpdateDialog);
    };
  }, [
    lastIgnoreTime,
    shouldShowUpdateDialog,
    showUpdateDialog,
    timeoutDelayMs,
  ]);
  return null;
});

export { CheckUpdate, RecoverShowUpdateDialog };
