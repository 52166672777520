import { PlayCircleOutlined } from "@ant-design/icons";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { Form } from "antd";
import { extname } from "pathe";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import type { FileInputProps } from "@/components/Input";
import { FileInput } from "@/components/Input";
import { BarLoading } from "@/components/Loading/BarLoading";
import { useMessage } from "@/components/message";
import { ga4Event } from "@/lib/ga4";
import {
  validateVideo,
  validator,
} from "@/resources/attachment/attachmentValidator";
import { uploadToFirebaseStorage } from "@/resources/attachment/uploadToFirebaseStorage";
import { buildVideoMessage } from "@/routes/QuickTemplate/applications/utils";
import { SelectedChannelContext } from "@/routes/QuickTemplate/SelectedChannelContext";
import { FileFieldWrapper } from "@/routes/QuickTemplate/ui/FileFieldWrapper";
import { UploadFileLabel } from "@/routes/QuickTemplate/ui/UploadFileLabel";
import {
  ReplaceUploadHints,
  UploadHints,
} from "@/routes/QuickTemplate/ui/UploadHints";
import { getVideoThumbnail } from "@/shared/application/file/getVideoThumbnail";

const VideoFieldWrapper = styled(FileFieldWrapper)`
  --width: ${theme.shape.mediaFieldSize};
  --height: ${theme.shape.mediaFieldSize};
`;

type QuickTemplateVideoMessageFormValue = ReturnType<typeof buildVideoMessage>;

const VideoField = ({
  value,
  onChange,
}: {
  value?: QuickTemplateVideoMessageFormValue;
  onChange?: (value: QuickTemplateVideoMessageFormValue) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const { status } = Form.Item.useStatus();

  const labelRef = useRef<HTMLLabelElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channel = SelectedChannelContext.useContext();
  const channelId = channel.id;

  const handleFileChange = useHandler<FileInputProps["onChange"]>(
    async function handleFileChange(e) {
      if (e.currentTarget.files === null || e.currentTarget.files.length === 0)
        return;

      const file = e.currentTarget.files[0];
      ga4Event("attachmentUpload", {
        orgId,
        channelId,
        extname: extname(file.name),
        fileSize: file.size,
        feature: "quickTemplate",
      });
      const validateFileError = validateVideo(channel.type, file);
      if (validateFileError) {
        message.error(validateFileError.reactNode);
        return;
      }

      try {
        setIsLoading(true);
        const downloadLink = await uploadToFirebaseStorage({
          feature: "quickTemplate",
          file,
          pathParams: {
            channelId: channel.id,
          },
        });
        const thumbnail = await getVideoThumbnail(file);
        const videoThumbnail = await uploadToFirebaseStorage({
          feature: "quickTemplate",
          file: thumbnail,
          pathParams: {
            channelId: channel.id,
          },
        });
        const draft = value ?? buildVideoMessage();
        onChange?.({
          ...draft,
          previewUrl: videoThumbnail.downloadUrl,
          originUrl: downloadLink.downloadUrl,
        });
        setIsLoading(false);
      } catch (error) {
        message.error(
          t("common.failToFetchModule", { module: t("glossary.image") }),
        );
      }
    },
  );

  const accept = useMemo(
    () =>
      validator[channel.type].rules.video.extnames
        .map((ext) => `.${ext}`)
        .join(","),
    [channel.type],
  );

  return (
    <VideoFieldWrapper $status={status}>
      <UploadFileLabel $imageUrl={value?.previewUrl} ref={labelRef}>
        <FileInput accept={accept} onChange={handleFileChange} />
        {isLoading && <BarLoading />}
      </UploadFileLabel>
      {value?.previewUrl !== "" ? (
        <ReplaceUploadHints
          hintDescription={t("common.replace")}
          onClick={() => labelRef.current?.click()}
        >
          <PlayCircleOutlined />
        </ReplaceUploadHints>
      ) : null}
      {!isLoading && value?.originUrl === "" ? (
        <UploadHints
          hintTitle={t("message.uploadVideo")}
          hintDescription={t("quickTemplate.videoUploadLimit")}
        >
          <PlayCircleOutlined />
        </UploadHints>
      ) : null}
    </VideoFieldWrapper>
  );
};

export { VideoField };
