import type { FC } from "react";

import { memberFieldRules } from "@/resources/member/memberValidator";
import { TextField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/TextField";

const CustomIdField: FC = () => {
  return (
    <TextField
      fieldName="customId"
      copyable
      rules={memberFieldRules.customId}
    />
  );
};

export { CustomIdField };
