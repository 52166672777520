import { memo } from "@chatbotgang/etude/react/memo";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import whiteLogo from "@/statics/login-logo-white.svg";

const SloganLogoInverse = styled.div`
  width: 266px;
  height: 44px;
  margin: 8% 0 0 10%;
  background: url("${whiteLogo}") no-repeat;
  background-size: contain;
`;

const CopyRight = styled.div`
  position: absolute;
  bottom: 4%;
  left: 10%;
  color: white;
`;

const LoginSlogan = memo(function LoginSlogan() {
  const { t } = useTranslation();
  return (
    <>
      <SloganLogoInverse />
      <CopyRight>
        {t("common.copyright", { year: new Date().getFullYear() })}
      </CopyRight>
    </>
  );
});

export { LoginSlogan };
