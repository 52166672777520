import { memo } from "@chatbotgang/etude/react/memo";

import type { CantataTypes } from "@/cantata/types";
import { Select, type SelectProps } from "@/components/Select";
import { MemberGenderLabel } from "@/resources/member/MemberGenderLabel";
import { cssSelect } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/cssSelect";

type MemberGenderSelectProps = SelectProps<
  CantataTypes["MemberDetail"]["gender"]
>;

const MemberGenderSelect = memo(function MemberGenderSelect(
  props: MemberGenderSelectProps,
) {
  return (
    <Select<CantataTypes["MemberDetail"]["gender"]>
      {...props}
      css={cssSelect}
      variant="borderless"
      options={[
        { value: "male", label: <MemberGenderLabel gender="male" /> },
        { value: "female", label: <MemberGenderLabel gender="female" /> },
        { value: "other", label: <MemberGenderLabel gender="other" /> },
        ...(props.value !== "unknown"
          ? []
          : [
              {
                value: "unknown",
                label: <MemberGenderLabel gender="unknown" />,
              },
            ]),
      ]}
    />
  );
});

export { MemberGenderSelect };
