import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { fc } from "@chatbotgang/etude/react/fc";
import { css } from "@emotion/react";
import { mapValues, pick } from "lodash-es";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import type { ModalProps } from "@/components/Modal";
import { Modal } from "@/components/Modal";
import { getAssigneeString } from "@/resources/member/getAssigneeString";
import { conversationHistoryMessages } from "@/resources/message/conversationHistoryMessages";
import { Messages } from "@/routes/Chat/ui/ChatPanel/History/Messages";
import { Context } from "@/routes/Chat/ui/conversationHistoryMessages/Context";
import { JumpToMessageProvider } from "@/routes/Chat/ui/jumpToMessage";
import { SearchMessageFromAMemberProvider } from "@/routes/Chat/ui/searchMessageFromMember";
import { useCheckMemberInMyScope } from "@/routes/Chat/useCheckMemberInMyScope";

type MessagesModalProps = Omit<
  ModalProps,
  "title" | "children" | "limitedHeight" | "footer"
>;

const cssContainer = css`
  position: relative;
  display: flex;
  overflow: auto;
  height: 100%;
  flex-direction: column;
  gap: 12px;
`;

const cssLoading = css`
  display: flex;
  width: 100%;
  height: max(5rem, 50dvh);
  align-items: center;
  justify-content: center;
`;

const cssMessages = css`
  padding: 16px 0;
`;

const MessagesModal = fc<MessagesModalProps>(function MessagesModal(props) {
  const { t } = useTranslation();
  const context = Context.useContext();
  const target = context.useTarget();
  if (!target) throw new Error(inspectMessage`target is ${target}`);
  const orgId = useActiveOrgIdStore((state) => state.value);
  const checkMemberInMyScope = useCheckMemberInMyScope();
  conversationHistoryMessages.useSetup(
    useMemo(
      () => ({
        orgId,
        memberId: target.memberId,
        conversationId: target.conversationId,
      }),
      [orgId, target.conversationId, target.memberId],
    ),
  );
  const memberQuery = cantata.member.useGetById({
    params: {
      orgId,
      memberId: target.memberId,
    },
  });
  const conversationHistoryMessagesController =
    conversationHistoryMessages.useController();
  const state = conversationHistoryMessagesController.useStore((state) => ({
    messages: state.data,
    error: state.error,
    ...mapValues(
      pick(conversationHistoryMessagesController.selectors, [
        "isLoading",
        "canTryPrevious",
        "canTryNext",
      ]),
      (selector) => selector(state),
    ),
  }));
  return (
    <Modal
      {...props}
      title={t("chat.conversationHistoryMessages.modal.title")}
      limitedHeight
      footer={(_, { OkBtn }) => <OkBtn />}
    >
      <div
        css={cssContainer}
        ref={conversationHistoryMessagesController.containerRef}
      >
        {checkMemberInMyScope.isLoading ? (
          <BarLoadingBlock />
        ) : memberQuery.isLoading ? (
          <BarLoadingBlock />
        ) : memberQuery.isError ? (
          <Alert type="error" message={memberQuery.error.message} />
        ) : checkMemberInMyScope.errorDisplay ? (
          checkMemberInMyScope.errorDisplay
        ) : checkMemberInMyScope.exec({ member: memberQuery.data }) ? null : (
          <Alert
            type="primary"
            message={t(
              "chat.conversationHistoryMessages.outOfScope.alert.message",
              {
                name: getAssigneeString(memberQuery.data),
              },
            )}
          />
        )}
        {!state.error ? null : (
          <Alert
            type="error"
            message={inspectMessage`conversationHistoryMessages error: ${state.error}`}
          />
        )}
        <div css={cssMessages}>
          <Messages messages={state.messages} isLoading={state.isLoading} />
          {!state.canTryNext ? null : (
            <div
              css={cssLoading}
              ref={conversationHistoryMessagesController.nextRef}
            >
              <BarLoadingBlock />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
});

const WrappedMessagesModal = fc<MessagesModalProps>(
  function WrappedMessagesModal(props) {
    return (
      <JumpToMessageProvider>
        <SearchMessageFromAMemberProvider>
          <conversationHistoryMessages.Provider>
            <MessagesModal {...props} />
          </conversationHistoryMessages.Provider>
        </SearchMessageFromAMemberProvider>
      </JumpToMessageProvider>
    );
  },
);

export { WrappedMessagesModal as MessagesModal };
