import { constant } from "lodash-es";
import type { FC, ReactNode } from "react";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import type { SelectProps } from "@/components/Select";
import { Select } from "@/components/Select";

const unifyKeyLabelTranslationMap: Record<
  CantataTypes["UnificationKeys"],
  ReactNode
> = {
  display_email: <Trans i18nKey="cdp.unify_key.display_email.label" />,
  custom_id: <Trans i18nKey="cdp.unify_key.custom_id.label" />,
  connect_id: <Trans i18nKey="cdp.unify_key.connect_id.label" />,
  display_mobile: <Trans i18nKey="cdp.unify_key.display_mobile.label" />,
  line_id: <Trans i18nKey="cdp.unify_key.line_id.label" />,
  external_member_id: (
    <Trans i18nKey="cdp.unify_key.external_member_id.label" />
  ),
};

const UnifyKeyLabel: FC<{ unifyKey: CantataTypes["UnificationKeys"] }> = ({
  unifyKey,
}) => {
  return <>{unifyKeyLabelTranslationMap[unifyKey]}</>;
};

type UnifyKeySelectorProps = Omit<
  SelectProps<CantataTypes["UnificationKeys"]>,
  "options"
> & {
  filter?: (key: CantataTypes["UnificationKeys"]) => boolean;
};

const UnifyKeySelector: FC<UnifyKeySelectorProps> = ({
  filter = constant(true),
  ...props
}) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const unifyKeyQuery = cantata.org.useGetUnifyScopeSetting({
    params: {
      orgId,
    },
  });

  const options = useMemo<SelectProps["options"]>(
    function getOptions() {
      if (!unifyKeyQuery.isSuccess) return [];

      return unifyKeyQuery.data.unifyKeys.filter(filter).map((key) => ({
        key,
        label: <UnifyKeyLabel unifyKey={key} />,
        value: key,
      }));
    },
    [filter, unifyKeyQuery.data?.unifyKeys, unifyKeyQuery.isSuccess],
  );

  return unifyKeyQuery.isLoading ? (
    <Select loading disabled />
  ) : unifyKeyQuery.isError ? (
    <Alert type="error" message={unifyKeyQuery.error.message} />
  ) : (
    <Select<CantataTypes["UnificationKeys"]> {...props} options={options} />
  );
};

export { UnifyKeyLabel, UnifyKeySelector, type UnifyKeySelectorProps };
