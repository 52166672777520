import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";

const DeleteTemplateModal = ({
  channelId,
  templateId,
  templateName,
  visible,
  onClose,
  onSuccess,
}: {
  channelId: number;
  templateId: number;
  templateName: string;
  visible: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const deleteTemplateMutation = cantata.quickTemplate.useDeleteById(
    {
      params: {
        orgId,
        channelId,
        quickTemplateId: templateId,
      },
    },
    {
      onSuccess: () => {
        onSuccess?.();
        onClose?.();
      },
    },
  );

  const handleSubmit = () => {
    deleteTemplateMutation.mutate(undefined);
  };

  return (
    <Modal
      title={t("quickTemplate.deleteTemplate.title", { name: templateName })}
      open={visible}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          type="default"
          onClick={(e) => {
            e.stopPropagation();
            onClose?.();
          }}
        >
          {t("common.cancel")}
        </Button>,
        <Button
          key="submit"
          danger={true}
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            handleSubmit();
          }}
          loading={deleteTemplateMutation.isLoading}
        >
          {t("common.delete")}
        </Button>,
      ]}
    >
      {t("quickTemplate.deleteTemplate.desc")}
    </Modal>
  );
};

export { DeleteTemplateModal };
