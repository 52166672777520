import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";

export const UploadFileLabel = styled.label<{ $imageUrl: string | undefined }>`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: ${theme.shape.borderRadius};
  background-color: transparent;
  background-image: ${({ $imageUrl }) =>
    $imageUrl ? `url(${$imageUrl})` : "none"};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  input[type="file"] {
    display: none;
  }
`;
