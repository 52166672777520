import { number } from "@chatbotgang/etude/pitch-shifter/number";
import { object } from "@chatbotgang/etude/pitch-shifter/object";
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { useDraggableFloatingElementController } from "@zeffiroso/utils/react-lib/useDraggableFloatingElementController";
import { FloatButton } from "antd";
import { useMemo } from "react";

import { LOCAL_STORAGE_FEATURE_FLAG_FAB_POSITION } from "@/appConstant";
import { PhIcon } from "@/components/PhIcon";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const positionInLocalStorage = createZustandStorageStore(
  LOCAL_STORAGE_FEATURE_FLAG_FAB_POSITION,
  object({
    right: number({
      defaultValue: 24,
    }),
    bottom: number({
      defaultValue: 96,
    }),
  }),
);

const position = createZustandStorageStore(
  LOCAL_STORAGE_FEATURE_FLAG_FAB_POSITION,
  object({
    right: number({
      defaultValue: positionInLocalStorage.useStore.getState().value.right,
    }),
    bottom: number({
      defaultValue: positionInLocalStorage.useStore.getState().value.bottom,
    }),
  }),
  {
    storage: sessionStorage,
  },
);

position.useStore.subscribe((store) => {
  positionInLocalStorage.useStore.setState({ value: store.value });
});

const Fab = fc<
  Omit<ComponentProps<typeof FloatButton>, "onClick"> & {
    onClick?: () => void;
  }
>(function Fab({ onClick, ...props }) {
  const draggableFloatingElementController =
    useDraggableFloatingElementController({
      positionStore: position.useStore,
      onClick: useHandler(function clickHandler() {
        onClick?.();
      }),
    });
  const cssProp = useMemo(
    () =>
      css([
        css`
          background-color: ${theme.colors.error};

          & > .ant-float-btn-body {
            background-color: inherit;
          }
        `,
        draggableFloatingElementController.css,
      ]),
    [draggableFloatingElementController.css],
  );
  return (
    <FloatButton
      css={cssProp}
      type="primary"
      icon={<PhIcon un-i-ph="bug-beetle" />}
      style={draggableFloatingElementController.style}
      ref={draggableFloatingElementController.targetRef}
      {...props}
    />
  );
});

export { Fab };
