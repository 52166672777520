import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Menu } from "@/routes/Chat/ui/SideMenu/components/Menu";
import { Unread } from "@/routes/Chat/ui/SideMenu/Filters/Unread";

const Filters: FC = () => {
  const { t } = useTranslation();
  return (
    <Menu.Root label={t("chat.memberPanel.filter.title")}>
      <Menu.List>
        <Unread />
      </Menu.List>
    </Menu.Root>
  );
};

export { Filters };
