import type { CantataTypes } from "@/cantata/types";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { useCheckMemberInMyScope } from "@/routes/Chat/useCheckMemberInMyScope";

const useCheckShouldInList = () => {
  const filter = searchController.useStore((state) => state.filter);
  const checkMemberInMyScope = useCheckMemberInMyScope();
  const currentMemberId = memberIdUtils.useGet();

  return ({
    channelId,
    processingState,
    assignmentFilter,
    unread,
    me,
    myTeams,
    member,
  }: {
    channelId?:
      | CantataTypes["Channel"]["id"]
      | typeof searchController.allChannelId;
    processingState: CantataTypes["MemberProcessingState"];
    assignmentFilter: CantataTypes["MemberAssignmentFilter"];
    unread: boolean | null;
    me: CantataTypes["UserMe"];
    myTeams: CantataTypes["Team"][];
    member: Pick<
      CantataTypes["Member"],
      | "id"
      | "channelId"
      | "assignmentRelationship"
      | "processingState"
      | "unreadMessageCount"
    >;
  }): boolean => {
    if (channelId && channelId !== member.channelId) return false;

    const isInMyTeam = myTeams.some(
      (team) =>
        member.assignmentRelationship.team &&
        team.id === member.assignmentRelationship.team.id,
    );
    const isInMyTeamAndNoAgent =
      isInMyTeam && !member.assignmentRelationship.user;
    const isAssignedToMe =
      member.assignmentRelationship.user &&
      member.assignmentRelationship.user.id === me.id;
    const isAssignedToAssignee =
      filter.assignmentFilter === "assignee" &&
      member.assignmentRelationship.user &&
      member.assignmentRelationship.user.id === filter.assigneeId;
    const isUnassigned =
      !member.assignmentRelationship.team &&
      !member.assignmentRelationship.user;
    const shouldInThisList =
      member.processingState === processingState &&
      (unread === null ||
        member.unreadMessageCount > 0 === unread ||
        // Don't filter out the member if in current chat room.
        member.id === currentMemberId) &&
      checkMemberInMyScope.exec({ member }) &&
      (assignmentFilter === "all" ||
        (assignmentFilter === "unassigned" && isUnassigned) ||
        (assignmentFilter === "my-teams" && isInMyTeam) ||
        (assignmentFilter === "my-teams-pending" && isInMyTeamAndNoAgent) ||
        (assignmentFilter === "assignee" && isAssignedToAssignee) ||
        (assignmentFilter === "me" && isAssignedToMe));

    return Boolean(shouldInThisList);
  };
};

export { useCheckShouldInList };
