import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { useCallback, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { hasPermission } from "@/shared/application/user";

type MemberWithAssignmentRelationship = Pick<
  CantataTypes["Member"],
  "assignmentRelationship"
>;

function check({
  me,
  myTeams,
  member,
}: {
  me: CantataTypes["UserMe"];
  myTeams: CantataTypes["Team"][];
  member: MemberWithAssignmentRelationship;
}) {
  return (
    hasPermission({
      user: me,
      permissionName: "unlimitedTeamScope",
    }) ||
    (!member.assignmentRelationship.team &&
      !member.assignmentRelationship.user) ||
    myTeams.some(
      (team) =>
        member.assignmentRelationship.team &&
        team.id === member.assignmentRelationship.team.id,
    ) ||
    Boolean(
      member.assignmentRelationship.user &&
        me.id === member.assignmentRelationship.user.id,
    )
  );
}

/**
 * To determine if the member is within the scope of the user's view.
 */
function useCheckMemberInMyScope() {
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const meQuery = cantata.user.useGetMe({
    params: {
      orgId,
    },
  });
  const myTeamsQuery = cantata.team.useListMine({
    params: {
      orgId,
    },
  });

  const errorDisplay = useMemo(
    () =>
      meQuery.isError ? (
        <Alert type="error" message={meQuery.error.message} />
      ) : myTeamsQuery.isError ? (
        <Alert type="error" message={myTeamsQuery.error.message} />
      ) : undefined,
    [
      meQuery.error?.message,
      meQuery.isError,
      myTeamsQuery.error?.message,
      myTeamsQuery.isError,
    ],
  );

  const invalidateQueries = useHandler(function invalidateQueries() {
    safeInvalidateQuery(meQuery.key);
    safeInvalidateQuery(myTeamsQuery.key);
  });

  const exec = useCallback(
    ({ member }: { member: MemberWithAssignmentRelationship }): boolean => {
      if (!meQuery.isSuccess) return false;
      if (!myTeamsQuery.isSuccess) return false;
      return check({
        me: meQuery.data,
        myTeams: myTeamsQuery.data.teams,
        member,
      });
    },
    [
      meQuery.data,
      meQuery.isSuccess,
      myTeamsQuery.data?.teams,
      myTeamsQuery.isSuccess,
    ],
  );

  const returnValue = useMemo(
    () => ({
      meQuery,
      myTeamsQuery,
      isFetching: meQuery.isFetching || myTeamsQuery.isFetching,
      isLoading: meQuery.isLoading || myTeamsQuery.isLoading,
      isError: meQuery.isError || myTeamsQuery.isError,
      error: meQuery.error || myTeamsQuery.error,
      /**
       * You can use this to display the error message.
       */
      errorDisplay,
      invalidateQueries,
      exec,
    }),
    [errorDisplay, exec, invalidateQueries, meQuery, myTeamsQuery],
  );
  return returnValue;
}

export { useCheckMemberInMyScope };
