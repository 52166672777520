/**
 * Get the previous range of the given range
 */

import { Ymd } from "@zeffiroso/utils/date/Ymd";
import {
  addDays,
  addMonths,
  addQuarters,
  differenceInDays,
  isEqual,
} from "date-fns/fp";
import { flow, merge } from "lodash-es";

type CustomPeriods = Record<"month" | "quarter" | "year", boolean>;

const diffDaysCustomPeriods: CustomPeriods = {
  month: false,
  quarter: false,
  year: false,
};

const allPeriods: CustomPeriods = {
  month: true,
  quarter: true,
  year: true,
};

type Options =
  | {
      method: "diffDays";
    }
  | {
      method: "allPeriods";
    }
  | {
      method: "custom";
      customPeriods: Partial<CustomPeriods>;
    };

/**
 * The arguments of the functions.
 *
 * Notice that this is for the date picker. Always make sure the dates are
 * the start of the day.
 */
type DateRange = [startDate: Date, endDate: Date];

type YmdRange = [startDate: Ymd, endDate: Ymd];

const isMonthly: (...args: DateRange) => boolean = (startDate, endDate) =>
  flow(addMonths(1), addDays(-1), isEqual(endDate))(startDate);

const getPreviousMonthRange: (...args: DateRange) => DateRange = (
  startDate,
  _endDate,
) => [addMonths(-1)(startDate), addDays(-1)(startDate)];
const isQuarterly: (...args: DateRange) => boolean = (startDate, endDate) =>
  flow(addQuarters(1), addDays(-1), isEqual(endDate))(startDate);
const getPreviousQuarterRange: (...args: DateRange) => DateRange = (
  startDate,
  _endDate,
) => [addQuarters(-1)(startDate), addDays(-1)(startDate)];
const isYearly: (...args: DateRange) => boolean = (startDate, endDate) =>
  flow(addQuarters(4), addDays(-1), isEqual(endDate))(startDate);
const getPreviousYearRange: (...args: DateRange) => DateRange = (
  startDate,
  _endDate,
) => [addQuarters(-4)(startDate), addDays(-1)(startDate)];
const getPreviousRange: (
  startDate: DateRange[0],
  endDate: DateRange[1],
  options?: Options,
) => DateRange = (startDate, endDate, options?: Options) => {
  const customPeriods: CustomPeriods =
    !options || !options.method || options.method === "diffDays"
      ? diffDaysCustomPeriods
      : options.method === "allPeriods"
        ? allPeriods
        : merge({}, diffDaysCustomPeriods, options.customPeriods);
  if (customPeriods.month && isMonthly(startDate, endDate))
    return getPreviousMonthRange(startDate, endDate);
  if (customPeriods.quarter && isQuarterly(startDate, endDate))
    return getPreviousQuarterRange(startDate, endDate);
  if (customPeriods.year && isYearly(startDate, endDate))
    return getPreviousYearRange(startDate, endDate);
  const diffInDays = differenceInDays(endDate, startDate);
  const nextEndDate = addDays(-1)(startDate);
  return [addDays(diffInDays)(nextEndDate), nextEndDate];
};

const getPreviousYmdRange: (
  startDate: YmdRange[0],
  endDate: YmdRange[1],
  options?: Options,
) => YmdRange = (startDate, endDate, options) => {
  const [start, end] = getPreviousRange(
    startDate.localStartOfDay,
    endDate.localEndOfDay,
    options,
  );
  return [new Ymd(start), new Ymd(end)];
};

function ymdRangeToRange([start, end]: YmdRange): DateRange {
  return [start.localStartOfDay, end.localEndOfDay];
}

export { getPreviousRange, getPreviousYmdRange, ymdRangeToRange };
export type { DateRange, YmdRange };
