import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import type { CSSProperties } from "react";

const seed = random();
const cssStaticHeightVariableName = `--static-height-${seed}`;
const cssStaticWidthVariableName = `--static-width-${seed}`;
const cssStaticSizeVariableName = `--static-size-${seed}`;

const cssStaticHeight = css`
  height: var(${cssStaticHeightVariableName});
  min-height: var(${cssStaticHeightVariableName});
  max-height: var(${cssStaticHeightVariableName});
`;

const cssStaticWidth = css`
  width: var(${cssStaticWidthVariableName});
  min-width: var(${cssStaticWidthVariableName});
  max-width: var(${cssStaticWidthVariableName});
`;

const cssStaticSize = css([
  cssStaticHeight,
  cssStaticWidth,
  {
    [cssStaticHeightVariableName]: `var(${cssStaticSizeVariableName})`,
    [cssStaticWidthVariableName]: `var(${cssStaticSizeVariableName})`,
  },
]);

function withSizeUnit(size: number | string): string {
  return typeof size === "number" ? `${size}px` : size;
}

function staticHeightStyle(size: number | string): CSSProperties {
  return {
    [cssStaticHeightVariableName]: withSizeUnit(size),
  };
}

function staticWidthStyle(size: number | string): CSSProperties {
  return {
    [cssStaticWidthVariableName]: withSizeUnit(size),
  };
}

function staticSizeStyle(size: number | string): CSSProperties {
  return {
    [cssStaticSizeVariableName]: withSizeUnit(size),
  };
}

/**
 * A shorthand for applying static height and width to an element.
 *
 * @example
 *
 * ```tsx
 * // Square
 * <div css={staticSize.css} style={staticSize.style(100)} />
 *
 * // circle
 * <div
 *   css={
 *     css(
 *       staticSize.css,
 *       css`
 *         border-radius: 50%;
 *       `
 *     )
 *   }
 *   style={staticSize.style(100)}
 * />
 *
 * // static 100px height
 * <div css={staticSize.height.css} style={staticSize.height.style(100)} />
 *
 * // static 100px width
 * <div css={staticSize.width.css} style={staticSize.width.style(100)} />
 *
 * // 100x200
 * <div
 *   css={css(staticSize.height.css, staticSize.width.css)}
 *   style={{
 *     ...staticSize.height.style(200),
 *     ...staticSize.width.style(100)
 *   }}
 * />
 * ```
 */
const staticSize = {
  css: cssStaticSize,
  style: staticSizeStyle,
  height: {
    css: cssStaticHeight,
    style: staticHeightStyle,
  },
  width: {
    css: cssStaticWidth,
    style: staticWidthStyle,
  },
  circle: {
    css: css([
      cssStaticSize,
      css`
        border-radius: 50%;
      `,
    ]),
    style: staticSizeStyle,
  },
};

export { staticSize };
