import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";

export const cssSelect = css`
  .ant-select-selection-item {
    color: ${theme.colors.neutral007};
  }

  &.ant-select-disabled {
    background-color: ${theme.colors.neutral001};
  }

  &.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      font-size: 12px;
    }
  }

  border: 1px solid transparent;
  border-radius: ${theme.shape.borderRadius};

  &:not(.ant-select-disabled):hover {
    border-color: ${theme.colors.primaryHover};
  }
`;
