import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "@/components/Checkbox";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";

const Unread: FC = () => {
  const { t } = useTranslation();
  const filterUnread = searchController.useStore(
    (state) => state.filter.unread,
  );

  const checked = useMemo<ComponentProps<typeof Checkbox>["checked"]>(
    () => filterUnread === true,
    [filterUnread],
  );

  const onChange = useHandler<ComponentProps<typeof Checkbox>["onChange"]>(
    function onChange(e) {
      searchController.setState((state) => ({
        filter: {
          ...state.filter,
          unread: e.target.checked ? true : null,
        },
      }));
    },
  );

  return (
    <Checkbox checked={checked} onChange={onChange}>
      {t("chat.memberPanel.filter.unread.checkbox.label")}
    </Checkbox>
  );
};

export { Unread };
