import { ExclamationOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";

import type { MotifIcon } from "@/components/MotifIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const StyledExclamationIcon = styled(ExclamationOutlined)`
  display: flex;
  width: 110px;
  height: 110px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${theme.colors.yellow002};
  color: ${theme.colors.yellow006};
  font-size: 65px;
  line-height: 120px;
`;
