import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { useMutation } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { downloadFile } from "@zeffiroso/utils/vanilla/downloadFile";
import { type FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantataClient } from "@/cantata";
import { Button } from "@/components/Button";
import { ExternalLink } from "@/components/ExternalLink";
import { MotifIcon } from "@/components/MotifIcon";
import { GroupTotalCount } from "@/routes/Insights/Contacts/Overview/GroupTotalCount";
import { MemberDetailLine } from "@/routes/Insights/Contacts/Overview/MemberDetailLine";
import { MemberTotalCount } from "@/routes/Insights/Contacts/Overview/MemberTotalCount";
import { usePageInfoUtil } from "@/routes/Insights/Contacts/pageInfo";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  title: css({
    fontSize: "1.25rem",
    fontWeight: 500,
    color: theme.colors.neutral009,
  }),
  head: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 24,
  }),
  exports: css({
    display: "flex",
    alignItems: "center",
    gap: 12,
  }),
  counts: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
});

const ExportButton: FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const ymdRange = useMemo(
    () => pageInfoUtil.computed.currentRange,
    [pageInfoUtil],
  );
  /**
   * Abort controller.
   *
   * Abort when the component unmounts.
   */
  const useAbortControllerStore = useUseAbortControllerStore();
  const abortController = useAbortControllerStore();
  /**
   * Make the `abort` function to be stable to prevent unnecessary re-renders.
   */
  const abort = useHandler(function abort() {
    abortController.abort();
  });
  useEffect(
    function abortWhenUnmount() {
      return abort;
    },
    [abort],
  );

  const mutation = useMutation({
    mutationFn: async function getExportLink() {
      /**
       * Get the current abort controller signal and pass it to the API call.
       */
      const signal = abortController.signal;
      const data = await cantataClient.dashboardMember.exportCsv({
        params: {
          orgId,
        },
        queries: pageInfoUtil.computed.commonQueries,
        signal,
      });
      if (signal.aborted) return;
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      downloadFile(blob, `contact_trend_${ymdRange[0]}-${ymdRange[1]}.csv`);
    },
  });
  const handleExport = useHandler(function handleExport() {
    if (mutation.isLoading) return;

    mutation.mutate();
  });
  return (
    <Button
      ghost
      loading={mutation.isLoading}
      disabled={mutation.isLoading}
      type="primary"
      onClick={handleExport}
      icon={<MotifIcon un-i-motif="arrow_export" />}
    >
      {t("dashboard.contacts.export.label")}
    </Button>
  );
};

const Overview: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div css={styles.head}>
        <div css={styles.title}>
          <Trans i18nKey="dashboard.contacts.overview.title" />
        </div>
        <div css={styles.exports}>
          <ExternalLink
            href={t("dashboard.contacts.learnMore.link")}
            prefixIcon={<MotifIcon un-i-motif="guide_book" />}
          >
            <Trans i18nKey="dashboard.contacts.learnMore.label" />
          </ExternalLink>
          <ExportButton />
        </div>
      </div>
      <div css={styles.counts}>
        <MemberTotalCount />
        <GroupTotalCount />
      </div>
      <MemberDetailLine />
    </>
  );
};

export { Overview };
