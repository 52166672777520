import { number } from "@chatbotgang/etude/pitch-shifter/number";
import { object } from "@chatbotgang/etude/pitch-shifter/object";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { type FC, type ReactNode, useMemo } from "react";
import { useParams } from "react-router-dom";

function useSetupPageInfoContext() {
  const params = useParams();
  const formattedParams = useMemo(
    () =>
      object({
        userId: number(),
      })(params),
    [params],
  );
  const pageInfo = useMemo(
    () => ({
      params: formattedParams,
    }),
    [formattedParams],
  );
  return pageInfo;
}

const PageInfoContext = createContext<
  ReturnType<typeof useSetupPageInfoContext>
>({
  name: "PageInfoContext",
});

const PageInfoProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const pageInfo = useSetupPageInfoContext();
  return (
    <PageInfoContext.Provider value={pageInfo}>
      {children}
    </PageInfoContext.Provider>
  );
};

const usePageInfo = () => PageInfoContext.useContext();

const pageInfoUtils = {
  Provider: PageInfoProvider,
  usePageInfo,
};

export { pageInfoUtils };
