import { theme } from "@zeffiroso/theme";

import type { PlotComponentProps } from "@/components/antdPlots/types/component";

/**
 * Use this as the default props for all plot components.
 */
const baseDefaultProps = {
  interaction: {
    tooltip: {
      css: {
        ".g2-tooltip": {
          color: theme.colors.neutral009,
          "font-family": "inherit",
        },
        ".g2-tooltip-title": {
          color: theme.colors.neutral009,
          "font-weight": 500,
        },
        ".g2-tooltip-list-item": {
          color: theme.colors.neutral009,
        },
        ".g2-tooltip-list-item-name-label": {
          color: theme.colors.neutral009,
        },
        ".g2-tooltip-list-item-marker": {
          color: theme.colors.neutral009,
        },
        ".g2-tooltip-list-item-value": {
          color: theme.colors.neutral009,
          "font-weight": 500,
        },
      },
    },
  },
} satisfies PlotComponentProps<object>;

export { baseDefaultProps };
