import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { Navigate } from "@/router/utils";
import { defineChildRouteObject } from "@/router/utils/defineChildRouteObject";
import { assignmentRouteObject } from "@/routes/Settings/Assignment";
import { channelsRouteObject } from "@/routes/Settings/Channels/channelsRouteObject";
import { ChatSettings } from "@/routes/Settings/ChatSettings";
import { Notification } from "@/routes/Settings/Notification";
import { Org } from "@/routes/Settings/Org";
import { Profile } from "@/routes/Settings/Profile";
import { teamsRouteObject } from "@/routes/Settings/Teams/teamsRouteObject";
import { usersRouteObject } from "@/routes/Settings/Users";
import { useSidebarMenuItems } from "@/routes/Settings/useSidebarMenuItems";

function Outer() {
  const { t } = useTranslation();
  const sidebarMenuItems = useSidebarMenuItems();
  return (
    <SideMenuLayout.Outer
      title={t("menu.settings")}
      sidebarMenuItems={sidebarMenuItems}
    >
      <Outlet />
    </SideMenuLayout.Outer>
  );
}

const settingsRouteObject = defineChildRouteObject({
  element: <Outer />,
  children: [
    {
      index: true,
      element: <Navigate replace to="/settings/profile" />,
    },
    {
      path: "profile",
      element: <Profile />,
    },
    {
      path: "notification",
      element: <Notification />,
    },
    {
      path: "users",
      ...usersRouteObject,
    },
    {
      path: "teams",
      ...teamsRouteObject,
    },
    {
      path: "chat-settings",
      element: <ChatSettings />,
    },
    {
      path: "assignment",
      ...assignmentRouteObject,
    },
    {
      path: "org",
      element: <Org />,
    },
    {
      path: "channels",
      ...channelsRouteObject,
    },
  ],
});

export { settingsRouteObject };
