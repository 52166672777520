import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { Form } from "@/components/Form";
import { message } from "@/components/message";
import { Modal } from "@/components/Modal";
import { TeamUsersField } from "@/routes/Settings/Teams/pages/TeamForm/form/fields/TeamUsersField";

type FormValues = {
  userIds: number[];
};

const AddTeamUserModal = memo(function AddTeamUserModal({
  teamId,
  open,
  onClose,
}: {
  teamId: number;
  open: boolean;
  onClose?: () => void;
}) {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [form] = Form.useForm<FormValues>();

  const handleBeforeClose = useHandler(() => {
    form.resetFields();
    onClose?.();
  });

  const { mutate, isLoading } = cantata.team.useAddUsers(
    {
      params: {
        orgId,
        teamId,
      },
    },
    {
      onSuccess() {
        form.resetFields();
        message.success(t("team.editPage.addMembers.success"));
        handleBeforeClose();
      },
      onError() {
        message.error(t("team.editPage.create.failed"));
      },
    },
  );

  const teamUsersQuery = cantata.team.useListUsers({
    params: {
      orgId,
      teamId,
    },
  });

  return (
    <Modal
      title={t("team.editPage.addMember")}
      open={open}
      onCancel={handleBeforeClose}
      footer={[
        <Button
          key="cancel"
          type="default"
          onClick={(e) => {
            e.stopPropagation();
            handleBeforeClose();
          }}
        >
          {t("common.cancel")}
        </Button>,
        <Button
          key="submit"
          data-test="confirm-add-team-member-button"
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            form.validateFields().then(mutate);
          }}
          disabled={isLoading}
          loading={isLoading}
        >
          {t("common.confirm")}
        </Button>,
      ]}
    >
      <Form<FormValues>
        form={form}
        hideRequiredMark
        layout="vertical"
        routerPromptOptions={{ disabled: true }}
        disabled={teamUsersQuery.isLoading}
        initialValues={{ userIds: [] }}
      >
        <Flex
          css={css`
            justify-content: center;
          `}
        >
          <TeamUsersField />
        </Flex>
      </Form>
    </Modal>
  );
});

const AddTeamUser = memo(function AddTeamUser({ teamId }: { teamId: number }) {
  const [open, toggle] = useSwitch(false);

  return (
    <>
      <Button
        type="primary"
        onClick={toggle.on}
        data-test="add-team-member-button"
      >
        <Trans i18nKey="team.editPage.addMember" />
      </Button>
      <AddTeamUserModal teamId={teamId} open={open} onClose={toggle.off} />
    </>
  );
});

export { AddTeamUser };
