import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "@/components/Alert";

const DisconnectAlert: FC = () => {
  const { t } = useTranslation();

  return (
    <Alert
      banner
      type="warning"
      message={t("chat.internetConnectionUnstable")}
    />
  );
};

export { DisconnectAlert };
