import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";

import { Flex } from "@/components/Box";
import { FormItem } from "@/components/Form";
import { ConditionField } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/ConditionsField/ConditionField";
import type { FormValues } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";

const Conditions = memo(function Conditions({
  value,
  onChange,
}: {
  value?: FormValues["conditions"];
  onChange?: (value: FormValues["conditions"]) => void;
}) {
  if (!value || !onChange) return null;

  return (
    <Flex
      css={css`
        align-items: flex-start;
        justify-content: flex-start;
        gap: inherit;
      `}
    >
      {value.map((condition, index) => (
        <ConditionField
          key={condition.key}
          conditions={value}
          setConditions={onChange}
          index={index}
        />
      ))}
    </Flex>
  );
});

export const ConditionsField = memo(function ConditionsField() {
  return (
    <Flex
      css={css`
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: inherit;
      `}
    >
      <FormItem name="conditions" noStyle>
        <Conditions />
      </FormItem>
    </Flex>
  );
});
