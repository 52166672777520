import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";

import { emotionMedia } from "@/shared/utils/style/emotionMedia";

const styles = {
  root: css([
    {
      position: "sticky",
      zIndex: 1,
      bottom: 0,
      paddingInline: 16,
      paddingBlock: 10,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      boxShadow: "0px -2px 4px 0px rgba(34, 59, 83, 0.05)",
      backgroundColor: theme.colors.white,
    },
    emotionMedia(
      css,
      ">=tabletLandscape",
      (css) => css`
        padding-inline: 40px;
      `,
    ),
  ]),
};

type FooterRef = ElementRef<"div">;
type FooterProps = ComponentProps<"div">;

const Footer = forwardRef<FooterRef, FooterProps>(function Footer(props, ref) {
  return <div css={styles.root} ref={ref} {...props} />;
});

export { Footer };
export type { FooterProps, FooterRef };
