import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { fc } from "@chatbotgang/etude/react/fc";
import { memo } from "@chatbotgang/etude/react/memo";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { define } from "@chatbotgang/etude/util/define";
import PQueue from "p-queue";
import type { ReactNode } from "react";
import { useEffect } from "react";

import { cantataClient } from "@/cantata";
import {
  sendingMessagesContext,
  useInitialContext,
  useSendingMessagesController,
} from "@/routes/Chat/ui/ChatPanel/sendingMessages";
import { useHandleErrorAndShowMessage } from "@/shared/application/error/handleError";
import { logError } from "@/shared/application/logger/sentry";

const messageSender = (function messageSender() {
  const Sender = fc(function Sender() {
    const sendingMessagesController = useSendingMessagesController();
    const handleErrorAndShowMessage = useHandleErrorAndShowMessage();
    useEffect(
      function sendMessage() {
        const queue = new PQueue({
          concurrency: 1,
        });
        const abortController = new AbortController();
        const unSub = sendingMessagesController.useStore.subscribe(
          function sendMessage(current, _prev) {
            const newLoadingRequests = current.requests.flatMap<
              (typeof current.requests)[number]
            >((request) => {
              if (request.status !== "pending") return [];
              return [
                {
                  ...request,
                  status: "loading",
                },
              ];
            });
            if (newLoadingRequests.length === 0) return;
            const nextRequests = current.requests.map<
              (typeof current.requests)[number]
            >((request) => {
              if (request.status !== "pending") return request;
              return define<typeof request>({
                ...request,
                messages: request.messages.map((message) => ({
                  ...message,
                  error: undefined,
                })),
                status: "loading",
              });
            });
            sendingMessagesController.useStore.setState({
              requests: nextRequests,
            });
            newLoadingRequests.forEach(async function send(request) {
              const result = await safePromise(() =>
                queue.add(async function send() {
                  const result = await safePromise(() =>
                    cantataClient.message.batchCreate(
                      {
                        messages: request.messages.map(
                          (message) => message.body,
                        ),
                        senderSession: "no use",
                      },
                      {
                        params: request.params,
                        signal: abortController.signal,
                      },
                    ),
                  );
                  if (abortController.signal.aborted) return;
                  if (result.isError) {
                    handleErrorAndShowMessage(result.error);
                    sendingMessagesController.updateRequest(
                      request.sendingMessageId,
                      (request) =>
                        define<typeof request>({
                          ...request,
                          messages: request.messages.map((message) => ({
                            ...message,
                            error:
                              result.error instanceof Error
                                ? result.error
                                : new Error(
                                    inspectMessage`Unknown error: ${result.error}`,
                                  ),
                          })),
                          status: "error",
                        }),
                    );
                    return;
                  }

                  type ResultMessage = (typeof result.data.messages)[number];
                  function findMessageFromResult(
                    uuid: ResultMessage["uuid"],
                  ): undefined | ResultMessage {
                    if (!result.isSuccess) return undefined;
                    return result.data.messages.find(
                      (message) => message.uuid === uuid,
                    );
                  }
                  if (
                    result.data.messages.some(
                      (message) => message.status === "failed",
                    )
                  ) {
                    sendingMessagesController.updateRequest(
                      request.sendingMessageId,
                      (request) =>
                        define<typeof request>({
                          ...request,
                          messages: request.messages.flatMap<
                            (typeof request.messages)[number]
                          >((message) => {
                            const messageFromResult = findMessageFromResult(
                              message.body.uuid,
                            );
                            if (!messageFromResult) {
                              return [
                                {
                                  ...message,
                                  error: new Error(
                                    inspectMessage`Message not found in request: ${message.body.uuid}`,
                                  ),
                                },
                              ];
                            }
                            if (messageFromResult.status === "delivered")
                              return [];
                            return [
                              {
                                ...message,
                                error: messageFromResult.error,
                              },
                            ];
                          }),
                          status: "error",
                        }),
                    );
                    return;
                  }

                  sendingMessagesController.removeRequest(
                    request.sendingMessageId,
                  );
                }),
              );
              if (result.isError) logError(result.error);
            });
          },
        );
        return function cleanup() {
          abortController.abort();
          sendingMessagesController.clearRequests();
          queue.clear();
          unSub();
        };
      },
      [handleErrorAndShowMessage, sendingMessagesController],
    );
    return null;
  });
  return {
    Sender,
  };
})();

const SendingMessagesProvider = memo(function SendingMessagesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const contextValue = useInitialContext();
  return (
    <sendingMessagesContext.Provider value={contextValue}>
      {children}
      <messageSender.Sender />
    </sendingMessagesContext.Provider>
  );
});

export { SendingMessagesProvider };
