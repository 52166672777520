import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { orderBy } from "lodash-es";
import { type FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Heatmap } from "@/components/antdPlots/Heatmap";
import { Card } from "@/components/Card";
import { useHourDisplay } from "@/components/DateTimeFormat/extended/HourDisplay/useHourDisplay";
import { useWeekdayDisplay } from "@/components/DateTimeFormat/extended/WeekdayDisplay/useWeekdayDisplay";
import { NoData } from "@/components/Empty/NoData";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useNumberFormat } from "@/components/NumberFormat/hooks/useNumberFormat";
import { TooltipInfo } from "@/components/TooltipInfo";
import { useOrgDayOrder } from "@/queriesContext/orgQueriesContext";
import { usePageInfoUtil } from "@/routes/Insights/Conversation/pageInfo";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  name: css({
    display: "flex",
    gap: 8,
    fontSize: "0.875rem",
    color: theme.colors.neutral009,
  }),
  tooltip: css({
    fontSize: "1rem",
    color: theme.colors.neutral007,
  }),
});

const useOrderByDayOrder = () => {
  const dayOrder = useOrgDayOrder();
  return useCallback(
    function orderByDayOrder(
      data: Array<CantataTypes["DashboardDayHourCount"]>,
    ) {
      return orderBy(data, [(item) => dayOrder[item.day], "hour"]);
    },
    [dayOrder],
  );
};

const ConversationHeatmap: FC<{
  data: Array<CantataTypes["DashboardDayHourCount"]>;
}> = ({ data }) => {
  const { t } = useTranslation();
  const hourDisplay = useHourDisplay();
  const weeklyDisplay = useWeekdayDisplay();
  const formatNumber = useNumberFormat({
    numberFormatPreset: "count",
  });

  if (data.length === 0) {
    return <NoData />;
  }

  return (
    <Heatmap
      data={data}
      xField="hour"
      yField="day"
      colorField="count"
      axis={{
        x: {
          labelFormatter: (hour) => hourDisplay.format(hour),
        },
        y: {
          labelFormatter: (day) => weeklyDisplay.format(day),
        },
      }}
      tooltip={{
        title: ({ day, hour }) =>
          `${weeklyDisplay.format(day)} ${hourDisplay.format(hour)}`,
        items: [
          ({ count }) => ({
            name: t("dashboard.conversation.distribution.conversation.label"),
            value: formatNumber.numberFormat(count),
          }),
        ],
      }}
    />
  );
};

const AssignedConversationHeatmap: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const orderByOrgDayOrder = useOrderByDayOrder();
  const query = cantata.dashboardConversation.useAssignedConversationHeatMap(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: orderByOrgDayOrder,
    },
  );
  if (!query.isSuccess) return null;

  return <ConversationHeatmap data={query.data} />;
};

const NewConversationHeatmap: FC = () => {
  const pageInfoUtil = usePageInfoUtil();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const orderByOrgDayOrder = useOrderByDayOrder();
  const query = cantata.dashboardConversation.useNewConversationHeatMap(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: orderByOrgDayOrder,
    },
  );
  if (!query.isSuccess) return null;
  return <ConversationHeatmap data={query.data} />;
};

const Wrapper: FC = () => {
  const pageInfoUtil = usePageInfoUtil();
  const hasTeamFilter = pageInfoUtil.data.teamIds.length > 0;
  return (
    <Card>
      <div css={styles.name}>
        <Trans i18nKey="dashboard.conversation.distribution.chart.label" />
        <TooltipInfo
          title={
            <Trans i18nKey="dashboard.conversation.distribution.chart.tooltip.title" />
          }
        />
      </div>
      <Heatmap.Wrapper>
        <ErrorBoundary.Alert fullSize>
          {hasTeamFilter ? (
            <AssignedConversationHeatmap />
          ) : (
            <NewConversationHeatmap />
          )}
        </ErrorBoundary.Alert>
      </Heatmap.Wrapper>
      <Heatmap.Legend
        title={
          <Trans i18nKey="dashboard.conversation.distribution.conversation.label" />
        }
      />
    </Card>
  );
};

export { Wrapper as NewConversationHeatmap };
