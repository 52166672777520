import { makeApi } from "@zodios/core";
import { z } from "zod";

import { CrescendoLabProductSchema, MemberNoteSchema } from "../models";

const api = makeApi([
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Note-optimization-8dcfaba451954ad69d7b1ed88e0494a2?pvs=4#ec45cc7879314e98b182cd01d4e1209e)
     */
    alias: "list",
    method: "get",
    path: "api/v2/orgs/:orgId/chat/members/:source/:memberId/note",
    parameters: [
      {
        type: "Path",
        name: "source",
        schema: CrescendoLabProductSchema,
      },
    ],
    response: z.object({
      notes: MemberNoteSchema.array(),
    }),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Note-optimization-8dcfaba451954ad69d7b1ed88e0494a2?pvs=4#0f329180ac7a4b9dbbc9ca92e1241e1b)
     */
    alias: "create",
    method: "post",
    path: "api/v2/orgs/:orgId/chat/members/:memberId/note",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: MemberNoteSchema.pick({
          content: true,
        }),
      },
    ],
    response: MemberNoteSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Note-optimization-8dcfaba451954ad69d7b1ed88e0494a2?pvs=4#bda83db87d9c4d558f7949dca8d8d263)
     */
    alias: "update",
    method: "put",
    path: "api/v2/orgs/:orgId/chat/members/:source/:memberId/note/:noteId",
    parameters: [
      {
        type: "Path",
        name: "source",
        schema: CrescendoLabProductSchema,
      },
      {
        type: "Body",
        name: "body",
        schema: MemberNoteSchema.pick({
          content: true,
        }),
      },
    ],
    response: MemberNoteSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Note-optimization-8dcfaba451954ad69d7b1ed88e0494a2?pvs=4#0eb27249bf6342e09e51419efefa9a6d)
     */
    alias: "deleteById",
    method: "delete",
    path: "api/v2/orgs/:orgId/chat/members/:source/:memberId/note/:noteId",
    parameters: [
      {
        type: "Path",
        name: "source",
        schema: CrescendoLabProductSchema,
      },
    ],
    response: z.unknown(),
  },
]);

export { api };
