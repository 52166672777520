// feature.member.accountStatus.following.label
// feature.member.accountStatus.blocked.label

import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";

import { Trans } from "@/app/i18n/Trans";
import { Pill } from "@/components/Pill";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";

export const MemberAccountStatus = memo(function MemberAccountStatus() {
  const member = memberQueriesContext.useMember();

  switch (member.status) {
    case "active":
      return (
        <Pill
          css={css`
            background-color: ${theme.colors.green002};
            color: ${theme.colors.green005};
            font-size: 0.75rem;
          `}
        >
          <Trans i18nKey="feature.member.accountStatus.active.label" />
        </Pill>
      );
    case "inactive":
      return (
        <Pill
          css={css`
            background-color: ${theme.colors.red001};
            color: ${theme.colors.red005};
            font-size: 0.75rem;
          `}
        >
          <Trans i18nKey="feature.member.accountStatus.inactive.label" />
        </Pill>
      );
    default:
      member.status satisfies never;
      throw new Error(
        inspectMessage`Unhandled member status: ${member.status}`,
      );
  }
});
