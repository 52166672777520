import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import type { ElementType } from "react";

import type { CantataTypes } from "@/cantata/types";

function defineIconMap<
  IconMap extends Record<CantataTypes["UserOnDutyStatus"], ElementType>,
>(iconMap: IconMap): IconMap {
  return iconMap;
}

export const iconMap = defineIconMap({
  online: CheckCircleFilled,
  offline: ExclamationCircleFilled,
  away: CloseCircleFilled,
} as const);
