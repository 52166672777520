import { omit } from "lodash-es";

import type { CantataTypes } from "@/cantata/types";
import { uuid } from "@/lib/uuid";
import { i18n } from "@/shared/g11n/i18n";

export interface DraggableObj {
  draggableId: string;
}

const assignDraggableId = <T>(obj: T): T & DraggableObj => ({
  ...obj,
  draggableId: uuid().toString(),
});

const omitDraggableId = <T extends DraggableObj>(
  obj: T,
): Omit<T, "draggableId"> => omit(obj, ["draggableId"]);

const buildTextMessage = (): CantataTypes["QuickTemplateText"] & DraggableObj =>
  assignDraggableId({
    type: "text",
    text: "",
    previewUrl: null,
    originUrl: null,
    metadata: null,
  });

const buildImageMessage = (): CantataTypes["QuickTemplateImage"] &
  DraggableObj =>
  assignDraggableId({
    type: "image",
    text: "",
    originUrl: "",
    previewUrl: "",
    metadata: null,
  });

const buildVideoMessage = (): CantataTypes["QuickTemplateVideo"] &
  DraggableObj =>
  assignDraggableId({
    type: "video",
    text: "",
    originUrl: "",
    previewUrl: "",
    metadata: null,
  });

const buildFileMessage = (): CantataTypes["QuickTemplateFile"] & DraggableObj =>
  assignDraggableId({
    type: "file",
    text: "",
    originUrl: "",
    previewUrl: null,
    metadata: {
      filename: "",
      filesizePrefix: i18n.t("chat.fileSize.prefix"),
      filesizeBytes: 0,
      expirationDatePrefix: i18n.t("chat.expireAt.prefix"),
      downloadExpirationDate: new Date(),
    },
  });

export {
  assignDraggableId,
  buildFileMessage,
  buildImageMessage,
  buildTextMessage,
  buildVideoMessage,
  omitDraggableId,
};
