import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import type { ElementRef } from "react";
import { useTranslation } from "react-i18next";

import type { SwitchProps } from "@/components/Switch";
import { Switch } from "@/components/Switch";

type OnOffSwitchProps = SwitchProps;

const OnOffSwitch = memo(
  forwardRef<ElementRef<typeof Switch>, OnOffSwitchProps>(
    function OnOffSwitch(props, ref) {
      const { t } = useTranslation();
      return (
        <Switch
          checkedChildren={t("glossary.switchOn")}
          unCheckedChildren={t("glossary.switchOff")}
          {...props}
          ref={ref}
        />
      );
    },
  ),
) as typeof Switch;

export { OnOffSwitch };
export type { OnOffSwitchProps };
