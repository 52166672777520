import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { MyProfile } from "@/routes/Settings/Profile/MyProfile";

const Profile: FC = () => {
  const { t } = useTranslation();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header>{t("glossary.myProfile")}</MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <MainLayout.Section>
            <MyProfile />
          </MainLayout.Section>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
};

export { Profile };
