import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import PQueue from "p-queue";

async function internalDataUrlToBlob(
  dataUrl: string,
  options?: {
    signal?: AbortSignal;
  },
) {
  options?.signal?.throwIfAborted();
  const res = await fetch(dataUrl, {
    ...(!options?.signal
      ? null
      : {
          signal: options?.signal,
        }),
  });
  options?.signal?.throwIfAborted();
  const blob = await res.blob();
  options?.signal?.throwIfAborted();
  return blob;
}

const pQueue = new PQueue({
  concurrency: 3,
});

/**
 * Convert multiple data URLs to blobs concurrently.
 */
const dataUrlToBlob: typeof internalDataUrlToBlob = async (...args) => {
  const result = await pQueue.add(async () => internalDataUrlToBlob(...args));
  if (!result) {
    throw new Error(
      inspectMessage`Failed to convert data URL to blob, result: ${result}, args: ${args}`,
    );
  }
  return result;
};

export { dataUrlToBlob };
