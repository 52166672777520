import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { memo } from "@chatbotgang/etude/react/memo";
import { useMemo } from "react";

import { DATE_FORMAT_OPTIONS } from "@/appConstant";
import { useGetIntlDateTimeFormatter } from "@/resources/datetime";

/**
 * A component displaying a date.
 */
const DisplayDate = memo<{
  value: Parameters<typeof Intl.DateTimeFormat.prototype.format>[0];
}>(function DisplayDate({ value }) {
  const getIntlDateTimeFormatter = useGetIntlDateTimeFormatter();
  const formatted = useMemo(
    () => getIntlDateTimeFormatter(DATE_FORMAT_OPTIONS).format(value),
    [getIntlDateTimeFormatter, value],
  );
  return <>{formatted}</>;
});

assignDisplayName(DisplayDate, "DisplayDate");

export { DisplayDate };
