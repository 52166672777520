import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import prettyBytes from "pretty-bytes";
import type { FC } from "react";
import { useMemo } from "react";

import type { CantataTypes } from "@/cantata/types";
import { MotifIcon } from "@/components/MotifIcon";
import { useFormatDateTime } from "@/resources/datetime";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  icon: css({
    color: theme.colors.neutral007,
  }),
  nameInfo: css({
    display: "flex",
    gap: 4,
    width: "100%",
    alignItems: "center",
    color: theme.colors.neutral009,
    fontSize: "1rem",
  }),
  name: css({
    fontSize: "0.875rem",
    fontWeight: 500,
  }),
  fileDescription: css({
    color: theme.colors.neutral007,
    fontSize: "0.75rem",
  }),
});

const Attachment: FC<
  ComponentProps<"div"> & {
    metadata: Extract<
      CantataTypes["MessageDetail"]["metadata"],
      { filename: string }
    >;
  }
> = ({ metadata, ...props }) => {
  const formatDateTime = useFormatDateTime();
  const formattedFileSize = useMemo(
    () => prettyBytes(metadata.filesizeBytes),
    [metadata.filesizeBytes],
  );
  const formattedExpirationDate = useMemo(
    () =>
      !metadata.downloadExpirationDate ||
      isInvalidDate(metadata.downloadExpirationDate)
        ? ""
        : formatDateTime(metadata.downloadExpirationDate),
    [formatDateTime, metadata.downloadExpirationDate],
  );
  return (
    <div css={styles.root} {...props}>
      <div css={styles.nameInfo}>
        <MotifIcon un-i-motif="attachment" css={styles.icon} />
        <div css={styles.name}>{metadata.filename}</div>
      </div>
      <div css={styles.fileDescription}>
        <div>
          {metadata.filesizePrefix}: {formattedFileSize}
        </div>
        <div>
          {metadata.expirationDatePrefix}: {formattedExpirationDate}
        </div>
      </div>
    </div>
  );
};

export { Attachment };
