import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { secondsToMilliseconds } from "date-fns/fp";
import { throttle } from "lodash-es";
import { type FC, Fragment, Suspense, useCallback, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useLegatoEvent } from "@/legato";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";

const useGetListAllQueryKey = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  return useCallback(
    (groupId: CantataTypes["Member"]["id"]) => {
      return cantata.chatGroup.getKeyByAlias("listAll", {
        params: {
          orgId,
          groupId,
        },
      });
    },
    [orgId],
  );
};

const useThrottledInvalidateListAllMembers = () => {
  const getQueryKey = useGetListAllQueryKey();
  const safeInvalidateQuery = useSafeInvalidateQuery();

  const invalidate = useHandler(function invalidate(groupId: number) {
    const queryKey = getQueryKey(groupId);
    return safeInvalidateQuery(queryKey);
  });

  return useMemo(
    /**
     * Limit the frequency of invalidations to prevent excessive
     * invalidation of the member list, reducing backend overload.
     */
    () => throttle(invalidate, secondsToMilliseconds(5)),
    [invalidate],
  );
};

const UpdateGroupMemberFromLegato: FC = () => {
  const invalidate = useThrottledInvalidateListAllMembers();
  useLegatoEvent(
    "group-member-list-updated",
    async function updateGroupMember(event) {
      invalidate(event.content.groupId);
    },
  );
  return null;
};

const UpdateRejoinedGroup: FC = () => {
  const invalidate = useThrottledInvalidateListAllMembers();
  useLegatoEvent("group-rejoined", function invalidateGroupMembers(data) {
    invalidate(data.content.groupId);
  });
  return null;
};

const Wrapper: FC = () => {
  return (
    <ErrorBoundary fallback={() => <Fragment />}>
      <Suspense fallback={null}>
        <memberQueriesContext.Provider>
          <UpdateGroupMemberFromLegato />
          <UpdateRejoinedGroup />
        </memberQueriesContext.Provider>
      </Suspense>
    </ErrorBoundary>
  );
};

export { Wrapper as UpdateGroupMemberFromLegato };
