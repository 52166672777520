import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
// eslint-disable-next-line no-restricted-imports -- Antd based utility
import { ConfigContext } from "antd/es/config-provider";
import { useContext } from "react";

function useConfigContext() {
  const configContext = useContext(ConfigContext);
  if (!configContext) {
    throw new Error(inspectMessage`configContext is falsy: ${configContext}`);
  }
  return configContext;
}

export { useConfigContext };
