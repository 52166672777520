import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import type { AuthProvider } from "firebase/auth";
import { getAuth, signInWithPopup, signOut } from "firebase/auth";

import { sharedFirebaseApp } from "./firebaseApp";

const auth = getAuth(sharedFirebaseApp);

type Options = {
  logError?: (error: unknown) => void;
};

function generateAuthSdk(options: Options) {
  async function getIdToken(): Promise<string> {
    return (await auth.currentUser?.getIdToken()) || "";
  }

  function getEmail(): string {
    return auth.currentUser?.email || "";
  }

  /**
   * This is presently necessary for BE due to some alignment issues.
   *
   * [Slack:
   * #proj-sso](https://chatbotgang.slack.com/archives/C06F4CL5CCS/p1708673916918249?thread_ts=1708570803.711169&cid=C06F4CL5CCS)
   * @deprecated
   */
  function getUid(): string {
    return auth.currentUser?.uid || "";
  }

  async function signIn<P extends Promise<any>>(params: {
    promise: P;
    signal?: AbortSignal;
  }) {
    try {
      params.signal?.throwIfAborted();
      const result = await safePromise(() => params.promise);
      params.signal?.throwIfAborted();
      const idToken = await getIdToken();
      params.signal?.throwIfAborted();
      /**
       * @deprecated
       */
      const uid = getUid();
      const email = getEmail();
      await trySignOut();
      if (result.isError) throw result.error;
      return {
        email,
        idToken,
        uid,
      };
    } catch (e) {
      if (!params.signal?.aborted) {
        options?.logError?.(e);
        await trySignOut();
      }
      throw e;
    }
  }

  async function trySignOut() {
    try {
      await signOut(auth);
    } catch (e) {
      // Do nothing.
    }
  }

  async function signInPopup(params: {
    provider: AuthProvider;
    signal?: AbortSignal;
  }) {
    return signIn({
      promise: signInWithPopup(auth, params.provider),
      ...(!params.signal ? null : { signal: params.signal }),
    });
  }

  const sdk = {
    signInPopup,
  };
  return sdk;
}

export { generateAuthSdk };
