import { LoadingOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { Menu } from "antd";
import { type FC, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import {
  DisabledContextProvider,
  useMergeFormDisabled,
} from "@/components/Form/DisabledContext";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { MotifIcon } from "@/components/MotifIcon";
import { switchAccountPanelPage } from "@/layout/base/AuthenticatedAppOuter/components/AccountPanel/useAccountPaneStore";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { Dot } from "@/resources/user/onDutyStatus/Dot";
import { Name } from "@/resources/user/onDutyStatus/Name";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    [["&:before", "&:after"].join(", ")]: {
      display: "none",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    paddingBlock: 8,
    paddingInline: 0,
    gap: 4,
    "& .ant-menu-item": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      padding: "8px 16px",
      margin: 0,
      width: "auto",
      borderRadius: 0,
    },
    "& .ant-menu-title-content": {
      marginInlineStart: 0,
      flex: 1,
    },
  }),
});

const itemKeys: Array<CantataTypes["User"]["onDutyStatus"]> = [
  "online",
  "away",
  "offline",
];

const OnDutyStatus: FC = () => {
  const mergeFormDisabled = useMergeFormDisabled();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const mutation = cantata.user.useUpdateMyOnDutyStatus(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess() {
        switchAccountPanelPage("home");
      },
    },
  );
  const orgQueriesData = orgQueriesContext.useData();
  const onDutyStatus = orgQueriesData.me.onDutyStatus;
  const items = useMemo<ComponentProps<typeof Menu>["items"]>(
    () => [
      {
        key: "",
        icon: <MotifIcon un-i-motif="arrow_left" />,
        onClick() {
          switchAccountPanelPage("home");
        },
        style: {
          color: theme.colors.neutral009,
          marginBlockEnd: "calc(12px - 4px)",
        },
      },
      ...itemKeys.map<
        NonNullable<ComponentProps<typeof Menu>["items"]>[number]
      >((key) => {
        const selected = key === onDutyStatus;
        return {
          key,
          icon: <Dot status={key} size={6} />,
          label: (
            <ItemWithIcon
              selected={selected}
              endIcon={mutation.isLoading ? <LoadingOutlined /> : undefined}
            >
              <Name status={key} />
            </ItemWithIcon>
          ),
          style: selected
            ? { backgroundColor: theme.colors.blue001 }
            : undefined,
          onClick: selected
            ? undefined
            : () => {
                mutation.mutate({ onDutyStatus: key });
              },
          disabled: mutation.isLoading,
        };
      }),
    ],
    [mutation, onDutyStatus],
  );
  return (
    <DisabledContextProvider disabled={mergeFormDisabled(mutation.isLoading)}>
      <Menu css={styles.root} items={items} />
    </DisabledContextProvider>
  );
};

export { OnDutyStatus };
