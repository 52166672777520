import { GA4_ID } from "@zeffiroso/env";
import ReactGA4 from "react-ga4";

if (import.meta.env.PROD) {
  /**
   * Google Analytics setup
   * We followed the instruction here: https://support.google.com/analytics/answer/9744165?hl=en
   */
  ReactGA4.initialize([
    {
      trackingId: GA4_ID,
    },
  ]);
}
