import { FileAddOutlined, FolderAddOutlined } from "@ant-design/icons";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { delay, isEmpty } from "lodash-es";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import type { ButtonProps } from "@/components/Button";
import type { NarrowIconButtonProps } from "@/components/Button/NarrowIconButton";
import {
  classNameNarrowIconButton,
  NarrowIconButton,
} from "@/components/Button/NarrowIconButton";
import { Highlight } from "@/components/Highlight";
import type { InputRef } from "@/components/Input";
import { Tooltip } from "@/components/Tooltip";
import { TooltipInfo } from "@/components/TooltipInfo";
import { CATEGORY_NAME_LENGTH_LIMIT } from "@/routes/QuickTemplate/constants";
import { SelectedChannelContext } from "@/routes/QuickTemplate/SelectedChannelContext";
import { CategoryNameInput } from "@/routes/QuickTemplate/ui/CategoryNameInput";
import { DeleteCategoryModal } from "@/routes/QuickTemplate/ui/DeleteCategoryModal";
import { DeleteTemplateModal } from "@/routes/QuickTemplate/ui/DeleteTemplateModal";
import type { SideMenuProps } from "@/routes/QuickTemplate/ui/SideMenu";
import { SideMenu, useSideMenuStore } from "@/routes/QuickTemplate/ui/SideMenu";
import { handleNonGlobalApiError } from "@/shared/domains/error";
import { cssOneLine } from "@/shared/emotion";
import { DeleteOutlined } from "@/shared/icons/common/DeleteOutlined";
import { EditOutlined } from "@/shared/icons/common/EditOutlined";

//#region styles
const CategoryContainer = styled.div<{ $active?: boolean }>`
  display: grid;
  gap: 8px;
  grid-template: auto / auto 24px 24px;

  ${`.${classNameNarrowIconButton}`} {
    color: ${theme.colors.neutral005};
    ${(props) =>
      !props.$active &&
      css`
        display: none;
      `};
  }

  &:hover {
    border-radius: ${theme.shape.borderRadius};
    background-color: ${theme.colors.neutral001};

    ${`.${classNameNarrowIconButton}`} {
      display: flex;
      color: ${theme.colors.neutral006};
    }
  }
`;

const CategoryNameWrapper = styled.div`
  display: flex;

  /* This is required for text ellipsis in the child element */
  overflow: hidden;
  align-items: center;
  gap: 8px;
`;

const Ellipsis = styled.div`
  ${cssOneLine}
`;

const CategoryTemplateCount = styled.span`
  flex: 1 0 auto;
`;

const TemplateContainer = styled.div<{ $active?: boolean }>`
  display: flex;
  min-height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 41px;
  color: ${theme.colors.neutral009};
  cursor: pointer;

  --active-color: ${theme.colors.neutral001};

  ${(props) =>
    props.$active &&
    css`
      background: var(--active-color);
    `};

  ${`.${classNameNarrowIconButton}`} {
    color: ${theme.colors.neutral005};

    ${(props) =>
      !props.$active &&
      css`
        display: none;
      `};
  }

  &:hover {
    border-radius: ${theme.shape.borderRadius};
    background-color: ${theme.colors.neutral001};

    ${`.${classNameNarrowIconButton}`} {
      display: flex;
    }
  }
`;

const CreateCategoryContainer = styled.div`
  padding: 4px 8px 4px 41px;
  border-radius: ${theme.shape.borderRadius};
  background-color: ${theme.colors.neutral001};
`;

const AddCategoryContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`;

const ControlsButtons = styled.div`
  display: flex;
  color: ${theme.colors.neutral005};
  gap: 16px;

  ${`.${classNameNarrowIconButton}`}:hover {
    color: ${theme.colors.neutral006};
  }
`;

const CategoryText = styled.span`
  color: ${theme.colors.neutral010};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .anticon {
    color: ${theme.colors.neutral007};
    font-size: 13px;
  }
`;
//#endregion

const NameEllipsis = memo<{ name: string }>(function NameEllipsis({ name }) {
  const searchText = useSideMenuStore(({ searchText }) => searchText);

  return (
    <Ellipsis>
      <Tooltip title={name}>
        <Highlight input={name} match={searchText} />
      </Tooltip>
    </Ellipsis>
  );
});

const DeleteTemplate = memo<{
  channelId: number;
  templateId: number;
  templateName: string;
  onDelete: () => void;
}>(function DeleteTemplate({ channelId, templateId, templateName, onDelete }) {
  const [visible, toggle] = useSwitch(false);
  return (
    <>
      <NarrowIconButton
        onClick={toggle.on}
        iconSize="24px"
        icon={<DeleteOutlined />}
      />
      <DeleteTemplateModal
        channelId={channelId}
        templateId={templateId}
        templateName={templateName}
        visible={visible}
        onClose={toggle.off}
        onSuccess={onDelete}
      />
    </>
  );
});

const DeleteCategoryButton = memo(function DeleteCategoryButton({
  categoryId,
  categoryName,
  channelId,
}: {
  categoryId: number;
  categoryName: string;
  channelId: number;
}) {
  const [open, toggle] = useSwitch(false);
  return (
    <>
      <NarrowIconButton
        onClick={toggle.on}
        size="24px"
        iconSize="24px"
        icon={<DeleteOutlined />}
      />
      <DeleteCategoryModal
        visible={open}
        categoryId={categoryId}
        categoryName={categoryName}
        channelId={channelId}
        onClose={toggle.off}
      />
    </>
  );
});

export function SideFoldPanel({
  activeCategoryId,
  activeTemplateId,
  quickTemplates,
  onCreateClick,
  onCategoryClick,
  onTemplateClick,
  onDeleteTemplate,
}: {
  activeCategoryId: number | null;
  activeTemplateId: number | null;
  quickTemplates: Array<CantataTypes["QuickTemplate"]>;
  onCreateClick?: ButtonProps["onClick"];
  onDeleteTemplate: () => void;
  onCategoryClick?: (
    categoryId: number | null,
    isEmptyCategory: boolean,
  ) => void;
  onTemplateClick?: (categoryId: number | null, templateId: number) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channel = SelectedChannelContext.useContext();
  const channelId = channel.id;
  const createCategoryInputRef = useRef<InputRef>(null);
  const editCategoryInputRef = useRef<InputRef>(null);

  const [createCategoryVisible, setCreateCategoryVisible] = useState(false);
  const [createCategoryName, setCreateCategoryName] = useState("");
  const [createCategoryNameError, setCreateCategoryNameError] = useState("");

  const [editCategoryId, setEditCategoryId] = useState<number | null>(null);
  const [originalEditCategoryName, setOriginalEditCategoryName] = useState("");
  const [editCategoryNameError, setEditCategoryNameError] = useState("");
  const [editCategoryName, setEditCategoryName] = useState("");

  const [uncategorizedTemplates, ...categorizedTemplates] = quickTemplates;
  const [defaultActiveKey] = useState(() => [
    "uncategorized",
    ...categorizedTemplates.map(({ category: { id } }) => `category_${id}`),
  ]);

  const showCreateCategoryInput = useHandler<NarrowIconButtonProps["onClick"]>(
    function showCreateCategoryInput() {
      setCreateCategoryVisible(true);
      delay(() => createCategoryInputRef.current?.focus(), 60);
    },
  );

  const showEditCategoryInput = useHandler<
    (
      category: CantataTypes["QuickTemplateCategory"],
    ) => NarrowIconButtonProps["onClick"]
  >(function showEditCategoryInput(category) {
    return function handleClick(event) {
      event.stopPropagation();

      setEditCategoryId(category.id);
      setEditCategoryName(category.name);
      setOriginalEditCategoryName(category.name);
      delay(() => editCategoryInputRef.current?.focus(), 60);
    };
  });
  const resetCreateCategoryInput = useHandler(
    function resetCreateCategoryInput() {
      setCreateCategoryVisible(false);
      setCreateCategoryName("");
      setCreateCategoryNameError("");
    },
  );

  const resetEditCategoryInput = useHandler(function resetEditCategoryInput() {
    setEditCategoryId(null);
    setEditCategoryName("");
    setOriginalEditCategoryName("");
    setEditCategoryNameError("");
  });

  const createCategoryMutation = cantata.quickTemplate.useCreateCategory(
    {
      params: {
        orgId,
        channelId,
      },
    },
    {
      onSuccess: resetCreateCategoryInput,
      // FIXME: handle error
      onError: async (error) => {
        await handleNonGlobalApiError(error, {
          QUICK_TEMPLATE_CATEGORY_ALREADY_EXISTED: () =>
            setCreateCategoryNameError(
              t("quickTemplate.categoryAlreadyExisted"),
            ),
        });
      },
    },
  );

  const updateCategoryMutation = cantata.quickTemplate.useUpdateCategory(
    {
      params: {
        orgId,
        channelId,
        quickTemplateCategoryId: editCategoryId ?? Number.NaN,
      },
    },
    {
      onSuccess: resetEditCategoryInput,
    },
  );

  const handleCreateCategoryName = useHandler(function handleCreateCategoryName(
    draftName: string,
  ) {
    if (createCategoryMutation.isLoading) return;

    if (draftName.length >= CATEGORY_NAME_LENGTH_LIMIT) {
      setCreateCategoryNameError(
        t("validation.maxCharLength", { count: CATEGORY_NAME_LENGTH_LIMIT }),
      );
      return;
    }

    createCategoryMutation.mutate({ name: draftName });
  });

  const handleUpdateCategoryName = useHandler(function handleUpdateCategoryName(
    draftName: string,
  ) {
    if (editCategoryId === null) {
      resetEditCategoryInput();
      return;
    }
    if (updateCategoryMutation.isLoading) return;

    if (draftName.length >= CATEGORY_NAME_LENGTH_LIMIT) {
      setEditCategoryNameError(
        t("validation.maxCharLength", { count: CATEGORY_NAME_LENGTH_LIMIT }),
      );
      return;
    }
    updateCategoryMutation.mutate({
      name: draftName,
    });
  });

  return (
    <>
      <AddCategoryContainer>
        <TooltipWrapper>
          <CategoryText>{t("quickTemplate.category")}</CategoryText>
          <TooltipInfo title={t("quickTemplate.createLimit")} />
        </TooltipWrapper>
        <ControlsButtons>
          <NarrowIconButton
            onClick={showCreateCategoryInput}
            icon={<FolderAddOutlined />}
            tooltipProps={{ title: t("quickTemplate.createCategory") }}
          />
          <NarrowIconButton
            onClick={onCreateClick}
            iconSize="20px"
            tooltipProps={{ title: t("quickTemplate.createTemplates") }}
            icon={<FileAddOutlined />}
          />
        </ControlsButtons>
      </AddCategoryContainer>
      <SideMenu
        showSearch
        defaultActiveKey={defaultActiveKey}
        items={[
          {
            key: "uncategorized",
            label: (
              <div
                onClick={() =>
                  onCategoryClick?.(
                    null,
                    isEmpty(uncategorizedTemplates.templates),
                  )
                }
              >
                <NameEllipsis
                  name={t("quickTemplate.uncategorized", {
                    count: uncategorizedTemplates.templates.length,
                  })}
                />
              </div>
            ),
            subItems: uncategorizedTemplates.templates.map((template) => ({
              key: `template_${template.id}`,
              label: (
                <TemplateContainer
                  $active={
                    activeTemplateId === template.id && !createCategoryVisible
                  }
                  key={`template_${template.id}`}
                  onClick={() =>
                    onTemplateClick?.(
                      uncategorizedTemplates.category.id,
                      template.id,
                    )
                  }
                >
                  <NameEllipsis name={template.name} />
                  <DeleteTemplate
                    channelId={channelId}
                    templateId={template.id}
                    templateName={template.name}
                    onDelete={onDeleteTemplate}
                  />
                </TemplateContainer>
              ),
              searchText: [
                t("quickTemplate.uncategorized.label"),
                template.name,
              ].join(
                /**
                 * Since it's not possible to type `\n` in the search box, we utilize
                 * it as a separator to align the search box with the option label.
                 */
                "\n",
              ),
            })),
          },
          ...(!createCategoryVisible
            ? []
            : [
                {
                  key: "createCategory",
                  showArrow: false,
                  label: (
                    <CreateCategoryContainer>
                      <CategoryNameInput
                        ref={createCategoryInputRef}
                        value={createCategoryName}
                        error={createCategoryNameError}
                        placeholder={t(
                          "quickTemplate.createCategoryPlaceholder",
                        )}
                        onChange={setCreateCategoryName}
                        onCancel={resetCreateCategoryInput}
                        onFinish={handleCreateCategoryName}
                      />
                    </CreateCategoryContainer>
                  ),
                } satisfies SideMenuProps["items"][number],
              ]),
          ...categorizedTemplates.map(
            (quickTemplate) =>
              ({
                key: `category_${quickTemplate.category.id}`,
                label:
                  editCategoryId === quickTemplate.category.id ? (
                    <CategoryNameInput
                      ref={editCategoryInputRef}
                      value={editCategoryName}
                      originalValue={originalEditCategoryName}
                      error={editCategoryNameError}
                      placeholder={t("quickTemplate.createCategoryPlaceholder")}
                      onCancel={resetEditCategoryInput}
                      onChange={setEditCategoryName}
                      onFinish={handleUpdateCategoryName}
                    />
                  ) : (
                    <CategoryContainer
                      $active={
                        quickTemplate.category.id === activeCategoryId &&
                        activeTemplateId === null &&
                        !createCategoryVisible
                      }
                      onClick={() =>
                        onCategoryClick?.(
                          quickTemplate.category.id,
                          isEmpty(quickTemplate.templates),
                        )
                      }
                    >
                      <CategoryNameWrapper>
                        <NameEllipsis
                          name={quickTemplate.category.name ?? ""}
                        />
                        <CategoryTemplateCount>
                          ({quickTemplate.templates.length})
                        </CategoryTemplateCount>
                      </CategoryNameWrapper>
                      {quickTemplate.category.id !== null && (
                        <>
                          <NarrowIconButton
                            onClick={showEditCategoryInput(
                              quickTemplate.category,
                            )}
                            icon={<EditOutlined />}
                          />
                          <DeleteCategoryButton
                            channelId={channelId}
                            categoryId={quickTemplate.category.id}
                            categoryName={quickTemplate.category.name}
                          />
                        </>
                      )}
                    </CategoryContainer>
                  ),
                subItems: quickTemplate.templates.map((template) => ({
                  key: `template_${template.id}`,
                  label: (
                    <TemplateContainer
                      $active={
                        activeTemplateId === template.id &&
                        !createCategoryVisible
                      }
                      key={`template_${template.id}`}
                      onClick={() =>
                        onTemplateClick?.(
                          quickTemplate.category.id,
                          template.id,
                        )
                      }
                    >
                      <NameEllipsis name={template.name} />
                      <DeleteTemplate
                        channelId={channelId}
                        templateId={template.id}
                        templateName={template.name}
                        onDelete={onDeleteTemplate}
                      />
                    </TemplateContainer>
                  ),
                  searchText: [quickTemplate.category.name, template.name].join(
                    /**
                     * Since it's not possible to type `\n` in the search box, we utilize
                     * it as a separator to align the search box with the option label.
                     */
                    "\n",
                  ),
                })),
              }) satisfies SideMenuProps["items"][number],
          ),
        ]}
      />
    </>
  );
}
