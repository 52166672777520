import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC, ReactNode } from "react";

import type { CantataTypes } from "@/cantata/types";
import { MotifIcon } from "@/components/MotifIcon";

type ProcessingState = CantataTypes["Conversation"]["processingState"];

const processingStateIconMap = define<Record<ProcessingState, ReactNode>>()({
  new: (
    <MotifIcon
      un-i-motif="bubble_chat"
      css={css({
        color: theme.colors.neutral009,
      })}
    />
  ),
  "follow-up": (
    <MotifIcon
      un-i-motif="flag_filled"
      css={css({
        color: theme.colors.yellow006,
      })}
    />
  ),
  resolved: (
    <MotifIcon
      un-i-motif="check"
      css={css({
        color: theme.colors.green006,
      })}
    />
  ),
  blocked: (
    <MotifIcon
      un-i-motif="circle_block"
      css={css({
        color: theme.colors.red006,
      })}
    />
  ),
  none: null,
});

const ProcessingStateIcon: FC<{ processingState: ProcessingState }> = ({
  processingState,
}) => {
  return processingStateIconMap[processingState];
};

export { ProcessingStateIcon };
