import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { z } from "zod";

class Sw2ClientZodError extends Error {
  override name = "Sw2ClientZodError";
  override cause: z.ZodError;
  constructor({ data, cause }: { data: unknown; cause: z.ZodError }) {
    super(inspectMessage`Error from data: ${data}`);
    this.cause = cause;
  }
}

export { Sw2ClientZodError };
