import { PlayCircleFilled } from "@ant-design/icons";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { transparentize } from "polished";
import type { MouseEventHandler } from "react";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

//#region style
const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 90%;
  border-radius: ${theme.shape.borderRadius};
`;

const Video = styled.video`
  width: 100%;
`;

const VideoCover = styled.img`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
`;

const VideoMask = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${transparentize(0.5, theme.colors.neutral010)};
`;

const Message = styled.span`
  font-size: 13px;
`;

const PlayButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.neutral001};
  cursor: pointer;
  gap: 8px;

  .anticon {
    --size: 14px;

    width: var(--size);
    height: var(--size);
    padding: 0;
    font-size: var(--size);
  }
`;
//#endregion

const PlayButton = ({
  onClick,
}: {
  onClick?: MouseEventHandler<HTMLDivElement>;
}) => {
  const { t } = useTranslation();

  return (
    <PlayButtonWrapper onClick={onClick}>
      <PlayCircleFilled />
      <Message>{t("common.play")}</Message>
    </PlayButtonWrapper>
  );
};

const VideoPreview = ({
  src,
  previewUrl,
}: {
  src: string;
  previewUrl: string;
}): JSX.Element | null => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);

  const handlePlayClick = useCallback(async () => {
    await videoRef.current?.play();
    setIsPlaying(true);
  }, []);

  return (
    <VideoWrapper>
      <Video
        style={{ visibility: isPlaying ? "visible" : "hidden" }}
        ref={videoRef}
        src={src}
        controls={isPlaying}
        onEnded={() => {
          setIsPlaying(false);
          setIsEnded(true);
        }}
      />
      {!isPlaying && !isEnded && previewUrl !== "" && (
        <VideoCover src={previewUrl} alt="preview" />
      )}
      {!isPlaying && (
        <VideoMask>
          <PlayButton onClick={handlePlayClick} />
        </VideoMask>
      )}
    </VideoWrapper>
  );
};

export { VideoPreview };
