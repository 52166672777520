// eslint-disable-next-line no-restricted-imports -- Non-exported from `antd`.
import AntDisabledContext, {
  DisabledContextProvider as AntDisabledContextProvider,
} from "antd/es/config-provider/DisabledContext";
import { useCallback, useContext } from "react";

const DisabledContextProvider = AntDisabledContextProvider;
const DisabledContext = AntDisabledContext;

function useDisabled() {
  return useContext(DisabledContext);
}

/**
 *
 * A hook return a function to merge form disabled state with the given disabled state.
 *
 * @example
 *
 * ```tsx
 * const mergeFormDisabled = useMergeFormDisabled();
 * return <input disabled={mergeFormDisabled(localDisabled)} />;
 * ```
 *
 * | `ignoreIfFalse` | Context Value | Input       | Result  |
 * | --------------- | ------------- | ----------- | ------- |
 * | `true`          | `false`       | `undefined` | `false` |
 * | `true`          | `false`       | `false`     | `false` |
 * | `true`          | `false`       | `true`      | `true`  |
 * | `true`          | `true`        | `undefined` | `true`  |
 * | `true`          | `true`        | `false`     | `true`  |
 * | `true`          | `true`        | `true`      | `true`  |
 * | `false`         | `false`       | `undefined` | `false` |
 * | `false`         | `false`       | `false`     | `false` |
 * | `false`         | `false`       | `true`      | `true`  |
 * | `false`         | `true`        | `undefined` | `true`  |
 * | `false`         | `true`        | `false`     | `false` |
 * | `false`         | `true`        | `true`      | `true`  |
 *
 * 💡 Tips: You can get the form context value by `mergeFormDisabled(false)`.
 */
function useMergeFormDisabled() {
  const formDisabled = useDisabled();
  /**
   * @param disabled - The disabled state to merge.
   * @param ignoreIfFalse - If set to `true`, `false` will be ignored. If you want to force enable the input, set it to `false`. The default value is `true`.
   */
  const mergeFormDisabled = useCallback(
    (disabled: boolean | undefined, ignoreIfFalse = true) => {
      if (disabled === true) return true;

      if (disabled === false && !ignoreIfFalse) return false;

      return formDisabled;
    },
    [formDisabled],
  );
  return mergeFormDisabled;
}

export {
  DisabledContext,
  DisabledContextProvider,
  useDisabled,
  useMergeFormDisabled,
};
