import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { random } from "@chatbotgang/etude/string/random";
import { parseJson } from "@crescendolab/parse-json";
import { get, set } from "lodash-es";
import type { Promisable } from "type-fest";

import {
  LOCAL_STORAGE_AUTH_TOKEN_KEY,
  LOCAL_STORAGE_REMEMBER_DEVICE_TOKEN_KEY,
} from "@/appConstant";

type BaseAction = {
  title: string;
  description?: string;
  action: () => Promisable<void>;
};

type Action<A extends BaseAction = BaseAction> = Readonly<A>;

function defineActions<Actions extends Readonly<Array<Action>>>(
  actions: Actions,
): Actions {
  return actions;
}

const actions = defineActions([
  {
    title: "Random token",
    description: "Emulate token expired, refresh the page to see the effect.",
    action() {
      const storageValue = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
      if (!storageValue) {
        throw new Error(
          inspectMessage`No token found in localStorage, storageValue: ${storageValue}`,
        );
      }
      const json = parseJson(storageValue, { fallback: null });
      if (!json || !(json instanceof Object)) {
        throw new Error(
          inspectMessage`JSON.parse failed, storageValue: ${storageValue}`,
        );
      }
      const token = get(json, ["state", "value"]);
      if (!token || typeof token !== "string") {
        throw new Error(
          inspectMessage`Invalid token found in localStorage, storageValue: ${storageValue}`,
        );
      }
      set(json, ["state", "value"], random());
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, JSON.stringify(json));
    },
  },
  {
    title: "Random remember device token",
    description: "Emulate remember device token expired, 2FA will be required.",
    action() {
      const storageValue = localStorage.getItem(
        LOCAL_STORAGE_REMEMBER_DEVICE_TOKEN_KEY,
      );
      if (!storageValue) {
        throw new Error(
          inspectMessage`No remember device token found in localStorage, storageValue: ${storageValue}`,
        );
      }
      const json = parseJson(storageValue, { fallback: null });
      if (!json || !(json instanceof Object)) {
        throw new Error(
          inspectMessage`JSON.parse failed, storageValue: ${storageValue}`,
        );
      }
      const token = get(json, ["state", "value"]);
      if (!token || typeof token !== "string") {
        throw new Error(
          inspectMessage`Invalid token found in localStorage, storageValue: ${storageValue}`,
        );
      }
      set(json, ["state", "value"], random());
      localStorage.setItem(
        LOCAL_STORAGE_REMEMBER_DEVICE_TOKEN_KEY,
        JSON.stringify(json),
      );
    },
  },
] as const);

export type { Action, BaseAction };

export { actions };
