import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";

const MessagesEditorContainer = styled.div`
  position: relative;
  width: 442px;
  height: auto;
  min-height: 700px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 14px 13px 0 rgb(44 56 72 / 20%);
`;
const MessagesEditorHeader = styled.div`
  position: relative;
  min-height: 56px;
  box-sizing: border-box;
  padding: 18px 40px;
  border-radius: 8px 8px 0 0;
  background-color: ${theme.colors.neutral002};
`;
const MessagesEditorBody = styled.div`
  overflow: hidden;
  height: auto;
  min-height: calc(700px - 56px);
  box-sizing: border-box;
  padding: 24px;
  border-radius: 0 0 8px 8px;
  background-color: ${theme.colors.neutral001};
`;
const ModuleSelectorContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: 16px 21.5px;
  gap: 24px;
`;

export {
  MessagesEditorBody,
  MessagesEditorContainer,
  MessagesEditorHeader,
  ModuleSelectorContainer,
};
