import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import classNames from "classnames";
import { type ElementRef, useMemo } from "react";

import { emotionMedia } from "@/shared/utils/style/emotionMedia";

const seed = random();

const classNamePrefix = `MainLayoutBody-${seed}-` as const;
const stickyClassName = `${classNamePrefix}sticky` as const;
const contentClassName = `${classNamePrefix}content` as const;
const sectionClassName = `${classNamePrefix}section` as const;
const groupClassName = `${classNamePrefix}group` as const;
const itemClassName = `${classNamePrefix}item` as const;

/**
 * This can prevent the content from being layered in front of elements with
 * absolute or sticky positioning.
 */
const cssResetZIndex = css({
  zIndex: 0,
});

const cssStretchColumnFlex = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
});

/**
 * - Spacing: [Figma](https://www.figma.com/design/k0XwP83RAV16nVdyFx7crb/Omnichannel-inbox?m=dev&node-id=9566-36529)
 */
const styles = {
  root: css([
    cssResetZIndex,
    {
      "--main-layout-padding-inline": "16px",
      "--main-layout-padding-block": "24px",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    },
    emotionMedia(
      css,
      ">=tabletLandscape",
      (css) => css`
        --main-layout-padding-inline: 40px;
      `,
    ),
  ]),
  contentWrapper: css([cssResetZIndex, cssStretchColumnFlex]),
  content: css([
    cssResetZIndex,
    cssStretchColumnFlex,
    {
      paddingInline: "var(--main-layout-padding-inline)",
      paddingBlock: "var(--main-layout-padding-block)",
      gap: 48,
    },
  ]),
  section: css([
    cssResetZIndex,
    cssStretchColumnFlex,
    {
      gap: 24,
    },
  ]),
  group: css([
    cssResetZIndex,
    cssStretchColumnFlex,
    {
      gap: 16,
    },
  ]),
  item: css([
    cssResetZIndex,
    cssStretchColumnFlex,
    {
      gap: 8,
    },
  ]),
  sticky: css([
    cssResetZIndex,
    {
      paddingBlock: 12,
      paddingInline: "var(--main-layout-padding-inline)",
    },
    emotionMedia(
      css,
      ">=tabletLandscape",
      (css) => css`
        position: sticky;
        z-index: 1;
        top: 0;
        background-color: white;
        box-shadow: 0 2px 4px 0 rgb(34 59 83 / 5%);
      `,
    ),
    emotionMedia(
      css,
      "<tabletLandscape",
      (css) => css`
        padding-top: var(--main-layout-padding-block);
        padding-bottom: 0;
      `,
    ),
  ]),
};

type BodyRef = ElementRef<"div">;
type BodyProps = ComponentProps<"div">;

const Body = forwardRef<BodyRef, BodyProps>(function Body(props, ref) {
  return <div css={styles.root} ref={ref} {...props} />;
});

type ContentWrapperRef = ElementRef<"div">;
type ContentWrapperProps = ComponentProps<"div">;

const ContentWrapper = forwardRef<ContentWrapperRef, ContentWrapperProps>(
  function ContentWrapper(props, ref) {
    return <div css={styles.contentWrapper} ref={ref} {...props} />;
  },
);

type ContentRef = ElementRef<"div">;
type ContentProps = ComponentProps<"div">;

const Content = forwardRef<ContentRef, ContentProps>(
  function Content(props, ref) {
    const mergedClassName = useMemo(
      () => classNames(props.className, contentClassName),
      [props.className],
    );
    return (
      <div
        css={styles.content}
        ref={ref}
        {...props}
        className={mergedClassName}
      />
    );
  },
);

type SectionRef = ElementRef<"section">;
type SectionProps = ComponentProps<"section">;

const Section = forwardRef<SectionRef, SectionProps>(
  function Section(props, ref) {
    const mergedClassName = useMemo(
      () => classNames(props.className, sectionClassName),
      [props.className],
    );
    return (
      <section
        css={styles.section}
        ref={ref}
        {...props}
        className={mergedClassName}
      />
    );
  },
);

type GroupRef = ElementRef<"div">;
type GroupProps = ComponentProps<"div">;

const Group = forwardRef<GroupRef, GroupProps>(function Group(props, ref) {
  const mergedClassName = useMemo(
    () => classNames(props.className, groupClassName),
    [props.className],
  );
  return (
    <div css={styles.group} ref={ref} {...props} className={mergedClassName} />
  );
});

type ItemRef = ElementRef<"div">;
type ItemProps = ComponentProps<"div">;

const Item = forwardRef<ItemRef, ItemProps>(function Item(props, ref) {
  const mergedClassName = useMemo(
    () => classNames(props.className, itemClassName),
    [props.className],
  );
  return (
    <div css={styles.item} ref={ref} {...props} className={mergedClassName} />
  );
});

type StickyProps = ComponentProps<"div">;
type StickyRef = ElementRef<"div">;

/**
 * A sticky container that sticks to the top of the viewport when scrolled past.
 *
 * Notice sticky only works for GteTabletLandscape.
 */
const Sticky = forwardRef<StickyRef, StickyProps>(function Sticky(props, ref) {
  const mergedClassName = useMemo(
    () => classNames(props.className, stickyClassName),
    [props.className],
  );
  return (
    <div css={styles.sticky} {...props} className={mergedClassName} ref={ref} />
  );
});

export { Body, Content, ContentWrapper, Group, Item, Section, Sticky };
export type {
  BodyProps,
  BodyRef,
  ContentProps,
  ContentRef,
  ContentWrapperProps,
  ContentWrapperRef,
  GroupProps,
  GroupRef,
  ItemProps,
  ItemRef,
  SectionProps,
  SectionRef,
  StickyProps,
  StickyRef,
};
