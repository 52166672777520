import type { Value } from "@chatbotgang/etude/event/keycode";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { isApple, isWindows } from "@zeffiroso/utils/vanilla/ua";
import type { ElementRef, ElementType, ForwardedRef, ReactNode } from "react";

import { Kbd } from "@/components/Kbd";
import { MotifIcon } from "@/components/MotifIcon";

const defaultComponent = Kbd;

type DefaultComponent = typeof defaultComponent;

const KeyMacCmd = <MotifIcon un-i-motif="key_mac_cmd" />;
const KeyMacShift = <MotifIcon un-i-motif="key_mac_shift" />;
const KeyMacOpt = <MotifIcon un-i-motif="key_mac_opt" />;
const KeyMacCtrl = <MotifIcon un-i-motif="key_mac_ctrl" />;
const KeyMacEnter = <MotifIcon un-i-motif="key_mac_enter" />;
const KeyMacBack = <MotifIcon un-i-motif="key_mac_back" />;
const KeyShift = <MotifIcon un-i-motif="key_shift" />;
const KeyEnter = <MotifIcon un-i-motif="key_enter" />;
const KeyCtrl = <MotifIcon un-i-motif="key_control" />;
const KeyF = <MotifIcon un-i-motif="key_f" />;
const KeyS = <MotifIcon un-i-motif="key_s" />;
const KeyO = <MotifIcon un-i-motif="key_o" />;

/**
 * List the own props of the component.
 */
interface KeyOwnProps {
  /**
   * The component used for the root node.
   */
  component?: ElementType;
  /**
   * The key to display.
   */
  keyValue: Value;
}

interface KeyTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & KeyOwnProps;
  defaultComponent: RootComponent;
}

type KeyProps<
  RootComponent extends ElementType = KeyTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = KeyOwnProps &
  OverrideProps<KeyTypeMap<AdditionalProps, RootComponent>, RootComponent> & {
    component?: ElementType;
  };

function generateKeyMap(platform: "apple" | "windows" | "other") {
  const isApple = platform === "apple";
  const isWindows = platform === "windows";
  const keyMap = define<Partial<Record<Value, ReactNode>>>()({
    Meta: isApple ? KeyMacCmd : isWindows ? "⊞" : "Meta",
    Alt: isApple ? KeyMacOpt : "Alt",
    Control: isApple ? KeyMacCtrl : KeyCtrl,
    Shift: isApple ? KeyMacShift : KeyShift,
    ArrowUp: "↑",
    ArrowDown: "↓",
    ArrowLeft: "←",
    ArrowRight: "→",
    " ": isApple ? "␣" : "Space",
    Enter: isApple ? KeyMacEnter : KeyEnter,
    Tab: "⇥",
    Backspace: KeyMacBack,
    Escape: "Esc",
    Delete: isApple ? "⌦" : "Del",
    CapsLock: "⇪",
    Insert: "Ins",
    PrintScreen: "PrtSc",
    ScrollLock: "ScrLk",
    NumLock: "NumLk",
    Cancel: "⎖",
    Clear: "⌧",
    ContextMenu: "⎗",
    F: KeyF,
    O: KeyO,
    S: KeyS,
  });
  return keyMap;
}

const keyMap = generateKeyMap(
  isApple ? "apple" : isWindows ? "windows" : "other",
);

function isInKeyMap(key: Value): key is Value & keyof typeof keyMap {
  return key in keyMap;
}

/**
 * An overridable component.
 */
const Key: OverridableComponent<KeyTypeMap> = forwardRef(function Key(
  { component: Component = defaultComponent, keyValue, ...props }: KeyProps,
  ref: ForwardedRef<ElementRef<typeof Component>>,
) {
  return (
    <Component {...props} ref={ref}>
      {isInKeyMap(keyValue) ? keyMap[keyValue] : keyValue}
    </Component>
  );
}) as OverridableComponent<KeyTypeMap>;

export { defaultComponent, generateKeyMap, isInKeyMap, Key, keyMap };

export type { KeyOwnProps, KeyProps, KeyTypeMap };
