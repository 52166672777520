import { merge } from "lodash-es";
import { useMemo } from "react";

import type { DateTimeFormatOptions } from "@/components/DateTimeFormat/types";
import { useLanguage } from "@/shared/hooks/useLanguage";

const defaultOptions: Intl.DateTimeFormatOptions = {};

/**
 * Basic `Intl.DateTimeFormat` hook.
 *
 * ```tsx
 * const dateTimeFormat = useDateTimeFormat();
 * const formattedDate = dateTimeFormat.format(new Date());
 * ```
 */
const useDateTimeFormat = (options: DateTimeFormatOptions = {}) => {
  const lang = useLanguage();
  const locales = useMemo<NonNullable<DateTimeFormatOptions["locales"]>>(
    () => options.locales || lang,
    [lang, options],
  );
  const mergedOptions = useMemo<
    NonNullable<DateTimeFormatOptions["dateTimeFormatOptions"]>
  >(
    () => merge({}, defaultOptions, options.dateTimeFormatOptions),
    [options.dateTimeFormatOptions],
  );
  const DateTimeFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locales, mergedOptions);
  }, [locales, mergedOptions]);

  return DateTimeFormat;
};

export { useDateTimeFormat };
