import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import type { AnyFunction } from "@chatbotgang/etude/function/AnyFunction";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import type { ErrorBoundaryProps, FallbackRender } from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";
import type { QueryErrorResetBoundaryProps } from "@tanstack/react-query";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
import { secondsToMilliseconds } from "date-fns";
import { noop } from "lodash-es";
import {
  type ElementRef,
  type FC,
  type ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { Alert } from "@/components/Alert";
import { CopyInput } from "@/components/Input";
import { Link } from "@/components/Link";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { Modal, type ModalProps } from "@/components/Modal";
import { useGetErrorMessage } from "@/shared/application/error/handleError";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";
import { useLastUserEmailStore } from "@/shared/utils/createZustandStorageStore";

type ZeffirosoErrorBoundaryProps = ErrorBoundaryProps;

type ErrorData = Parameters<FallbackRender>[0];

const ErrorMsg: FC<{ error: unknown }> = ({ error }) => {
  const getErrorMessage = useGetErrorMessage();
  const display = useMemo(
    () => getErrorMessage(error),
    [error, getErrorMessage],
  );
  return display;
};

/**
 * Integrated:
 *
 * - Sentry
 * - React Query
 */
const ZeffirosoErrorBoundary = forwardRef<
  ElementRef<typeof ErrorBoundary>,
  ErrorBoundaryProps
>(function ZeffirosoErrorBoundary(props, ref) {
  const beforeCapture = useCallback<
    NonNullable<ErrorBoundaryProps["beforeCapture"]>
  >(
    function beforeCapture(...args) {
      const scope = args[0];
      const orgId = useActiveOrgIdStore.getState().value;
      if (orgId) {
        scope.setTag("lastOrgId", orgId);
      }
      if (useLastUserEmailStore.getState().value) {
        scope.setTag("lastUserEmail", useLastUserEmailStore.getState().value);
      }
      return props.beforeCapture?.(...args);
    },
    [props],
  );
  const children = useCallback<
    Extract<QueryErrorResetBoundaryProps["children"], AnyFunction>
  >(
    function children(query) {
      return (
        <ErrorBoundary
          {...props}
          beforeCapture={beforeCapture}
          onReset={(...args) => {
            query.reset();
            props.onReset?.(...args);
          }}
          ref={ref}
        />
      );
    },
    [beforeCapture, props, ref],
  );
  return <QueryErrorResetBoundary>{children}</QueryErrorResetBoundary>;
});

const cssEventId = defineStyles({
  root: css`
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    background-color: ${theme.colors.neutral002};
    font-size: 0.75rem;
    gap: 8px;
    white-space: nowrap;
  `,
  label: css`
    user-select: none;
  `,
  input: css`
    flex: 1;
    padding: 0;
    border-color: transparent;
    background-color: transparent;
    color: inherit;
  `,
});

type EventIdProps = ComponentProps<"label"> & {
  eventId: ErrorData["eventId"];
};

const EventId = forwardRef<ElementRef<"label">, EventIdProps>(function EventId(
  { eventId, ...props },
  ref,
) {
  return (
    <label css={cssEventId.root} {...props} ref={ref}>
      <div css={cssEventId.label}>Event ID: </div>
      <CopyInput css={cssEventId.input} value={eventId} />
    </label>
  );
});

const errorBoundaryAlertStyles = defineStyles({
  alertMessage: css`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  fullSizeAlert: css([
    cssFlexInheritAndFill,
    {
      minWidth: Alert.defaultMinWidth,
      overflow: "auto",
      alignItems: "flex-start",
      "& .ant-alert-content": {
        justifyContent: "center",
      },
    },
  ]),
  fullSizeSuspense: css([
    cssFlexInheritAndFill,
    css`
      align-items: center;
      justify-content: center;
    `,
  ]),
  delayEnableLink: css({
    color: "inherit",
  }),
});

const DelayEnableLink: FC<ComponentProps<typeof Link> & { delay: number }> = ({
  delay,
  ...props
}) => {
  const [enabled, toggleEnabled] = useSwitch(false);
  const enable = useHandler(toggleEnabled.on);

  useEffect(
    function countDown() {
      const timeout = setTimeout(enable, delay);
      return function cleanup() {
        clearTimeout(timeout);
      };
    },
    [delay, enable],
  );
  return (
    <Link
      css={errorBoundaryAlertStyles.delayEnableLink}
      {...props}
      disabled={!enabled}
    />
  );
};

const getAlertErrorFallbackRender: (
  props?: Pick<
    ComponentProps<typeof ErrorBoundaryAlert>,
    "className" | "fullSize"
  >,
) => Extract<
  ComponentProps<typeof ZeffirosoErrorBoundary>["fallback"],
  AnyFunction
> = ({ className, fullSize } = {}) =>
  function fallback(errorData) {
    return (
      <Alert
        className={className}
        css={css(fullSize && errorBoundaryAlertStyles.fullSizeAlert)}
        type="error"
        message={
          <div css={errorBoundaryAlertStyles.alertMessage}>
            <ErrorMsg error={errorData.error} />
            <CopyInput value={errorData.eventId} />
          </div>
        }
        action={
          <DelayEnableLink
            onClick={errorData.resetError}
            delay={secondsToMilliseconds(3)}
          >
            <Trans i18nKey="errorBoundary.action.reload" />
          </DelayEnableLink>
        }
      />
    );
  };

function renderChildren(children: ReactNode | (() => ReactNode)) {
  return children instanceof Function ? children() : children;
}

const ErrorBoundaryAlert = forwardRef<
  ElementRef<typeof ErrorBoundary>,
  Omit<ErrorBoundaryProps, "fallback"> & {
    className?: string;
    fullSize?: boolean;
  }
>(function ErrorBoundaryAlert(
  { children, className, fullSize, ...props },
  ref,
) {
  return (
    <ZeffirosoErrorBoundary
      {...props}
      ref={ref}
      fallback={getAlertErrorFallbackRender({ className, fullSize })}
    >
      <Suspense
        fallback={
          <BarLoadingBlock
            css={css(fullSize && errorBoundaryAlertStyles.fullSizeSuspense)}
            className={className}
          />
        }
      >
        {renderChildren(children)}
      </Suspense>
    </ZeffirosoErrorBoundary>
  );
});

const forceModalProps = define<ModalProps>()({
  open: true,
  footer: null,
  onOk: noop,
});

const ErrorBoundaryModal = forwardRef<
  ElementRef<typeof ErrorBoundary>,
  Omit<ErrorBoundaryProps, "fallback"> & {
    ModalProps?: Partial<
      Omit<ModalProps, "children" | "closable" | keyof typeof forceModalProps>
    >;
  }
>(function ErrorBoundaryModal({ children, ModalProps, ...props }, ref) {
  const commonModalProps = useMemo<ModalProps>(
    () => ({
      ...(ModalProps?.onCancel
        ? {
            /**
             * If onCancel is provided, make the modal closable by default.
             */
            closable: true,
          }
        : null),
      ...ModalProps,
      ...forceModalProps,
    }),
    [ModalProps],
  );
  return (
    <ErrorBoundary
      {...props}
      ref={ref}
      fallback={(errorData) => (
        <Modal {...commonModalProps}>
          {getAlertErrorFallbackRender()(errorData)}
        </Modal>
      )}
    >
      <Suspense fallback={<Modal.Loading {...commonModalProps} />}>
        {renderChildren(children)}
      </Suspense>
    </ErrorBoundary>
  );
});

/**
 * Zeffiroso Essentials ErrorBoundary.
 *
 * ErrorBoundary.useEventId() returns the eventId of the error.
 */
const Api = Object.assign(ZeffirosoErrorBoundary, {
  Alert: Object.assign(ErrorBoundaryAlert, {
    getErrorFallbackRender: getAlertErrorFallbackRender,
  }),
  Modal: ErrorBoundaryModal,
  EventId,
  Msg: ErrorMsg,
});

export { Api as ErrorBoundary };
export type { ZeffirosoErrorBoundaryProps as ErrorBoundaryProps, ErrorData };
