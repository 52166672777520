import { fc } from "@chatbotgang/etude/react/fc";
import { merge } from "lodash-es";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "@/components/Alert";
import { BarLoading } from "@/components/Loading/BarLoading";
import type { ModalProps } from "@/components/Modal";
import { Modal } from "@/components/Modal";
import { getAssigneeString } from "@/resources/member/getAssigneeString";
import type { OutOfScope } from "@/routes/Chat/ui/OutOfScope";

import { conversationHistoryMessages } from ".";

/**
 * TODO: Explore the possibility of merging with OutOfScope.
 *
 * @see {@link OutOfScope}
 */
const OutOfScopeModal = fc<Omit<ModalProps, "title" | "children">>(
  function OutOfScopeModal(props) {
    const { t } = useTranslation();
    const conversationHistoryMessagesController =
      conversationHistoryMessages.useController();
    const memberQuery = conversationHistoryMessagesController.useMemberQuery();
    const name = useMemo(() => {
      if (!memberQuery.isSuccess) return "";
      return getAssigneeString({
        assignmentRelationship: memberQuery.data.assignmentRelationship,
      });
    }, [memberQuery.data?.assignmentRelationship, memberQuery.isSuccess]);
    const mergedProps = useMemo(() => {
      const defaultProps = {
        cancelButtonProps: {
          style: {
            display: "none",
          },
        },
      };
      return merge({}, defaultProps, props);
    }, [props]);
    return (
      <Modal title={t("chat.cannotOpenConversation.title")} {...mergedProps}>
        {memberQuery.isLoading ? (
          <BarLoading />
        ) : memberQuery.isError ? (
          <Alert type="error" message={memberQuery.error.message} />
        ) : (
          t("chat.cannotOpenConversation.desc", {
            name,
          })
        )}
      </Modal>
    );
  },
);

export { OutOfScopeModal };
