import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { secondsToMilliseconds } from "date-fns";
import { type FC, useEffect, useMemo, useState } from "react";

import type { ButtonProps } from "@/components/Button";
import { Button } from "@/components/Button";

type ReloadButtonProps = ButtonProps;

const readyDelay = secondsToMilliseconds(1);

/**
 * Can only click once.
 * setTimeout to prevent continuous clicks.
 */
const ReloadButton: FC<ReloadButtonProps> = ({
  disabled,
  onClick,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setReady(true);
    }, readyDelay);
    return function cleanUp() {
      clearTimeout(timeout);
    };
  }, []);
  const isDisabled = useMemo(() => {
    return disabled || clicked || !ready;
  }, [clicked, disabled, ready]);
  const clickHandler = useHandler<ButtonProps["onClick"]>((e) => {
    setClicked(true);
    onClick?.(e);
  });
  return (
    <Button
      {...props}
      disabled={isDisabled}
      loading={isDisabled}
      onClick={clickHandler}
    />
  );
};

export { ReloadButton };
