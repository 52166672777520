import { object } from "@chatbotgang/etude/pitch-shifter/object";
import { string } from "@chatbotgang/etude/pitch-shifter/string";

import { LOCAL_STORAGE_LOGIN_AUTH_PROVIDER } from "@/appConstant";
import { createZustandStorageStore } from "@/shared/utils/createZustandStorageStore";

const parser = (value: unknown) =>
  !value
    ? null
    : object({
        providerId: string(),
        domain: string(),
      })(value);

type Value = ReturnType<typeof parser>;
const storageStore = createZustandStorageStore(
  LOCAL_STORAGE_LOGIN_AUTH_PROVIDER,
  parser,
);
function setValue(value: NonNullable<Value>) {
  storageStore.useStore.setState({ value });
}

function getValue() {
  return storageStore.useStore.getState().value;
}

function clearValue() {
  storageStore.useStore.getState().clear();
}

/**
 * Storage store for the value of the authentication provider, including the
 * provider ID and domain.
 */
const authProviderStore = {
  setValue,
  getValue,
  clearValue,
  useStore: storageStore.useStore,
};

export { authProviderStore };
