import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import escapeStringRegexp from "escape-string-regexp";
import type {
  ComponentProps,
  ComponentType,
  ElementRef,
  ReactNode,
} from "react";
import { useMemo } from "react";

const DefaultEmphasize = styled.mark`
  all: unset;
  background-color: ${theme.colors.yellow005};
`;

function highlight(
  input: string,
  match: string = "",
  Emphasize: ComponentType<{
    children: ReactNode;
  }> = DefaultEmphasize,
) {
  if (!match) return <>{input}</>;
  const parts = input.split(new RegExp(`(${escapeStringRegexp(match)})`, "gi"));
  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === match.toLowerCase() ? (
          <Emphasize key={i}>{part}</Emphasize>
        ) : (
          part
        ),
      )}
    </>
  );
}

type HighlightProps = Omit<ComponentProps<"span">, "children"> & {
  input: string;
  match?: string;
  Emphasize?: ComponentType<{
    children: ReactNode;
  }>;
};

const Highlight = Object.assign(
  memo(
    forwardRef<ElementRef<"span">, HighlightProps>(function Highlight(
      { input, match, Emphasize, ...props },
      ref,
    ) {
      const highlighted = useMemo(
        () => highlight(input, match, Emphasize),
        [Emphasize, input, match],
      );
      return (
        <span ref={ref} {...props}>
          {highlighted}
        </span>
      );
    }),
  ),
  {
    highlight,
    DefaultEmphasize,
  },
);

assignDisplayName(Highlight, "Highlight");

export { Highlight };
