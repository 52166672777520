import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";

import { defineConfigs } from "@/internal/sentryDebug/defineConfigs";

type OrgLevel = {
  orgId: number;
  userId: number;
};

// type ChannelLevel = OrgLevel & {
//   channelId: number;
// };

// type MemberLevel = ChannelLevel & {
//   memberId: number;
// };

const configs = defineConfigs({
  disconnectForWhile: defineConfigs.defineError<
    OrgLevel & {
      delay: number;
    }
  >({
    enabled: false,
    message: (params) => inspectMessage`Disconnecting for a while: ${params}`,
  }),
  disconnectSeveralTimes: defineConfigs.defineError<
    OrgLevel & {
      times: Array<Date>;
    }
  >({
    enabled: false,
    message: (params) => inspectMessage`Disconnecting several times: ${params}`,
  }),
  logoutDueToUnauthenticated: defineConfigs.defineError<
    Pick<OrgLevel, "orgId"> & {
      token: string;
      email: string;
      reason: string;
    }
  >({
    enabled: false,
    message: (params) =>
      inspectMessage`Logout due to unauthenticated: ${params}`,
  }),
});

export { configs };
