import type { FC } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { BarLoading } from "@/components/Loading/BarLoading";

export const ProfileUnificationWarning: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const scopesQuery = cantata.org.useGetUnifyScopeSetting({
    params: {
      orgId,
    },
  });

  if (scopesQuery.isLoading) return <BarLoading />;

  if (scopesQuery.isError)
    return <Alert type="error" message={scopesQuery.error.message} />;

  if (scopesQuery.data.orgs.length <= 1 || scopesQuery.data.isCdpConnected)
    return null;

  const scopes = scopesQuery.data.orgs
    .map((scope) => `${scope.source.toUpperCase()} ${scope.name}`)
    .join(", ");

  return (
    <Alert
      type="warning"
      message={
        <Trans
          i18nKey="feature.org.profileUnificationWarning"
          values={{
            scopes,
          }}
        />
      }
    />
  );
};
