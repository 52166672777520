import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import useSwitch from "@react-hook/switch";
import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "@/components/Modal";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { mutationsController } from "@/routes/Chat/ui/ChatPanel/Header/mutationsController";

const BlockMemberModal: FC = () => {
  const [modalVisible, toggleModalVisible] = useSwitchBlockModal();
  const { t } = useTranslation();
  const member = memberQueriesContext.useMember();

  const mutations = mutationsController.useContext();

  const toggleBlockedOrNew = useHandler(async () => {
    const result = await safePromise(async () =>
      mutations.updateProcessingStateMutation.mutateAsync({
        processingState:
          member.processingState === "blocked" ? "new" : "blocked",
        senderSession: "no use",
      }),
    );
    if (result.isSuccess) {
      toggleModalVisible.off();
    }
  });

  const isMemberBlocked = member.processingState === "blocked";

  return (
    <Modal
      title={
        isMemberBlocked
          ? t("chat.unblockModal.title")
          : t("chat.blockModal.title")
      }
      open={modalVisible}
      confirmLoading={mutations.isLoading}
      onOk={toggleBlockedOrNew}
      okText={t("common.confirm")}
      onCancel={toggleModalVisible.off}
      cancelText={t("common.cancel")}
    >
      {isMemberBlocked
        ? t("chat.unblockModal.content")
        : t("chat.blockModal.content")}
    </Modal>
  );
};

const Context = createContext<ReturnType<typeof useSwitch>>({
  name: "BlockMemberModalContext",
});

function useSwitchBlockModal() {
  return Context.useContext();
}

const plugins = {
  useSwitch: useSwitchBlockModal,
};

const Provider = (({ children }) => {
  const contextValue = useSwitch(false);
  return (
    <Context.Provider value={contextValue}>
      {children}
      <BlockMemberModal />
    </Context.Provider>
  );
}) satisfies FC<{ children: ReactNode }> as FC<{ children: ReactNode }> &
  typeof plugins;

Object.assign(Provider, plugins);

export { Provider as BlockMemberModalProvider };
