import type { DefaultRoleType } from "@/shared/domains/role";

export const defaultRoleTypeTranslationKeyMap: Record<DefaultRoleType, string> =
  {
    owner: "glossary.owner",
    primary_admin: "glossary.primaryAdmin",
    admin: "glossary.admin",
    primary_agent: "glossary.primaryAgent",
    agent: "glossary.agent",
  };
