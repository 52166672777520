import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 *
 * @see https://www.figma.com/file/o61i5symd25csJUyfXETzv/Design-foundation?type=design&node-id=141-2420&mode=dev
 */
export const MoveHorizontalIcon = generateIcon(function MoveHorizontalIcon() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">
      <path d="M12.8987 13.0002L18.4483 13.0002V14.7988C18.4483 14.9653 18.6233 15.0597 18.7408 14.957L21.9306 12.1592C22.0231 12.0787 22.0231 11.9233 21.9306 11.8456L18.7408 9.04786C18.6233 8.94517 18.4483 9.03676 18.4483 9.20607V11.0018L12.8987 11.0018L11.0988 10.9991L5.54915 10.9991V9.20052C5.54915 9.03398 5.37416 8.93962 5.25667 9.04231L2.06937 11.84C1.97688 11.9205 1.97688 12.076 2.06937 12.1537L5.25667 14.9514C5.37416 15.0541 5.54915 14.9625 5.54915 14.7932V13.0002L11.0988 13.0002H12.8987Z" />
    </svg>
  );
});
