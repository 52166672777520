import { crescendoLabBirthDay } from "@chatbotgang/etude/dummy";
import { add, startOfWeek } from "date-fns/fp";

const aSunday = startOfWeek(crescendoLabBirthDay);
const aMonday = add({ days: 1 }, aSunday);
const aTuesday = add({ days: 2 }, aSunday);
const aWednesday = add({ days: 3 }, aSunday);
const aThursday = add({ days: 4 }, aSunday);
const aFriday = add({ days: 5 }, aSunday);
const aSaturday = add({ days: 6 }, aSunday);

export {
  aFriday,
  aMonday,
  aSaturday,
  aSunday,
  aThursday,
  aTuesday,
  aWednesday,
};
